import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Drawer, Tag, Button, Tooltip, Collapse, Row, Col, Spin, Descriptions, PageHeader } from "antd";

import { withRouter } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import SelectRoots from "../../subscribersRoots/components/SelectUsers";

import ManageAppModal2 from "../components/ManageAppModal2";
import BucketContainer from "../../bucket/containers/Buckets";
import ExcelExportSimple from "../components/AppsExcelExportSimple";
import ExcelExport from "../components/AppsExcelExport";

import CreateAppEvent from "../components/CreateAppEvent";

import ManageUserModal from "../../subscribersUsers/components/ManageUserModal";
import ManageBucketModal from "../../bucket/components/ManageBucketModal";

// HELPERS
import showGlobalNotification from "../../../helpers/showGlobalNotification";

// REDUX
import { setAppRedux } from "../../../redux/app";

// REQUEST
import { getApp, archivedApp, insituApp, getAppsByPlanId } from "../requests";

import { saveGuidePlan } from "../../subscribersPlans/requests";

// HOOKS
import useModal from "../../shared/hooks/useModal";

// CONSTANTS
import { globals, numbers, eventStatusFormatWord, dateFormat4, icons } from "../../../constants/globals";

const { Panel } = Collapse;
const Swal = require("sweetalert2");

const Apps = ({
  handleCreateAppEditEvent,
  handleClosePlanByGuide,
  handleUploadPlanFile,
  isEmbed = false,
  userId = null,
  planId = null,
  reload,
  history,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const planStoreRedux = useSelector((store) => store.plan);
  const userStoreRedux = useSelector((store) => store.user);
  const sessionStoreRedux = useSelector((store) => store.session);
  const { role } = sessionStoreRedux.currentSession;

  const [page, setPage] = useState(globals.PAGE_MAIN);
  const [subpage, setSubpage] = useState(numbers.ONE);
  const [selectedApp, setSelectedApp] = useState({});
  const [visible, setVisible] = useState(false);
  const [openCreateAppEvent, setOpenCreateAppEvent] = useState(false);

  const [appId, setAppId] = useState(null);
  const [isOpenManageAppsModal, openManageAppsModal, closeManageAppsModal] = useModal();
  const [isOpenManageBucketModal, openManageBucketModal, closeManageBucketModal] = useModal();

  const [isOpenManageUsersModal, openManageUsersModal, closeManageUsersModal] = useModal();
  const [selectedUser, setSelectedUser] = useState({});
  const [userId_, setUserId_] = useState(userId);
  const [details, setDetails] = useState(false);
  const [apps, setApps] = useState({});
  const [pack, setPack] = useState({});
  const [plan, setPlan] = useState({});
  const [guideId, setGuideId] = useState(null);

  const loadPlan = () => {
    getAppsByPlanId(planId, {
      onSuccess: (res) => {
        setPlan(res.plan);
        setPack(res.plan.packId);
        setApps(res.result);
      },
      onError: () => {},
    });
  };

  const refetch = () => {
    loadPlan();
  };

  const callback = (key) => {
    console.log(key);
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setUserId_(user._id);
    openManageUsersModal();
  };

  const [initialValues] = useState({
    name: "",
    duration: "",
    category: "",
    maxLimit: 1,
    minLimit: 1,
    observation: "",
    active: true,
    tag: "",
    avatar: "",
    public: false,
    startdate: "2020/01/01",
    closuredate: "2020/01/01",
  });

  const handleEdit = (app) => {
    setSelectedApp(app);
    setAppId(app._id);
    openManageAppsModal();
  };

  const handleDetails = (app) => {
    setSelectedApp({});
    getApp(app._id, {
      onSuccess: (response) => {
        setAppId(app._id);
        setSelectedApp(response.result);
        setAppRedux(response.result)(dispatch);
        setPage(globals.PAGE_ONE);
        setSubpage(numbers.ONE);
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
    //
  };

  const handleArchivedConfirm = (app) => {
    archivedApp(
      {
        app,
      },
      {
        onSuccess: () => {
          loadPlan();
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleArchived = (app) => {
    setAppId(app._id);
    confirmAlert({
      title: `Eliminar el app de ${app.userId.firstname}`,
      message: `${t("AreYouWantDelete")} ${app.code}?`,
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleArchivedConfirm(app),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleInsituConfirm = (app) => {
    insituApp(
      {
        app,
      },
      {
        onSuccess: (result) => {
          loadPlan();
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleInsitu = (app) => {
    setAppId(app._id);
    confirmAlert({
      title: app.insitu ? "Desea QUITAR la asistencia" : "Desea ASIGNAR la asistencia",
      message: t("AreYouSureWantToContinue"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleInsituConfirm(app),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleGuide = (value) => {
    setGuideId(value);

    if (plan?._id) {
      saveGuidePlan(
        {
          planId: plan?._id,
          guideId: value,
        },
        {
          onSuccess: (result) => {
            if (result.result.guideId._id) loadPlan();
            Swal.fire(t("common_done"), "El Guide ha sido actualizado", "success");
          },
          onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
          onFinally: () => {},
        }
      );
    }
  };

  const [initialExecute, setInitialExecute] = useState(false);
  useEffect(() => setTimeout(() => setInitialExecute(true), 2000), []);

  useEffect(() => {
    if (page !== globals.PAGE_MAIN) setAppRedux(selectedApp)(dispatch);
  }, [selectedApp]);

  useEffect(() => {
    if (planId) loadPlan();
  }, [planId, reload]);

  useEffect(() => {
    if (initialExecute && page === globals.PAGE_ONE) handleDetails(selectedApp);
  }, [userStoreRedux.reload]);

  const onClose = () => {
    setVisible(false);
    setOpenCreateAppEvent(false);
    loadPlan();
  };

  const handleMoveAppEvent = (app) => {
    setSelectedApp(app);
    setOpenCreateAppEvent(true);
  };

  return (
    <>
      {plan._id ? (
        <div className={isEmbed ? "" : "container-subpages"}>
          <PageHeader
            className="site-page-header icons-events-back s"
            onBack={() => {
              if (role === "TOURGUIDE") history.push(`/tourguide/`);
              if (role === "SUPERADMIN") history.push(`/activities/`);
            }}
            title={<p>{pack.name}</p>}
          >
            <Descriptions size="small" column={1}>
              <Descriptions.Item>
                <h2>
                  {icons.calendar} {plan.name}{" "}
                  {apps.length > 0 && <ExcelExportSimple apps={apps} guide={plan?.guideId} />}{" "}
                  {apps.length > 0 && role !== "TOURGUIDE" && (
                    <>
                      <ExcelExport apps={apps} />
                    </>
                  )}
                </h2>
              </Descriptions.Item>
            </Descriptions>
            <Descriptions size="small" column={{ xxl: 6, xl: 6, lg: 6, md: 2, sm: 2, xs: 2 }}>
              <Descriptions.Item label="Tickets">{plan?.totalApps}</Descriptions.Item>
              <Descriptions.Item label="Capacidad">{plan?.maxLimit}</Descriptions.Item>
              <Descriptions.Item label="Disponibilidad">{plan?.availability}</Descriptions.Item>
              <Descriptions.Item label="Guia">{plan?.guideId?.firstname}</Descriptions.Item>

              <Descriptions.Item>
                <>
                  {plan?.status === "close" && (
                    <Tooltip title={`Finalizado por ${plan?.closeBy}  ${dateFormat4(plan?.closeAt)}  `}>
                      <Tag color="volcano">Finalizado</Tag>
                    </Tooltip>
                  )}
                  {plan?.closureEmailDate && (
                    <Tag color="green">
                      <Tooltip title={dateFormat4(plan?.closureEmailDate)}>{icons.email} &nbsp; Fotos enviadas</Tooltip>
                    </Tag>
                  )}
                </>
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
          <Collapse accordion>
            <Panel header={<h2>Abrir acciones</h2>} key="1">
              <>
                <Row>
                  {apps.length > 0 && (
                    <Col span={12} xs={24} sm={12} md={12} lg={4} xl={3} className="center">
                      <Button
                        className="ant-btn secondary primary large big-mobile "
                        style={{ width: "90%" }}
                        onClick={() => loadPlan()}
                      >
                        {icons.reload} Refrescar
                      </Button>
                    </Col>
                  )}
                  {plan && (
                    <Col span={12} xs={24} sm={12} md={12} lg={5} xl={5} className="center">
                      <Button
                        style={{ width: "90%" }}
                        className="ant-btn secondary primary  large big-mobile "
                        onClick={() => handleCreateAppEditEvent(plan._id)}
                      >
                        {icons.calendar} Editar evento2
                      </Button>
                    </Col>
                  )}
                  <Col span={12} xs={24} sm={12} md={12} lg={5} xl={5} className="center">
                    {" "}
                    <Button
                      className="ant-btn secondary primary large big-mobile"
                      style={{ width: "90%" }}
                      onClick={() => setVisible(true)}
                    >
                      {icons.plus_circle} Agregar contacto
                    </Button>{" "}
                  </Col>

                  {apps.length > 0 && (
                    <Col span={12} xs={24} sm={12} md={12} lg={5} xl={5} className="center">
                      <Button
                        className="ant-btn secondary info large big-mobile "
                        style={{ width: "90%" }}
                        onClick={() => handleUploadPlanFile()}
                      >
                        {icons.foto} Subir foto
                      </Button>
                    </Col>
                  )}
                  {apps.length > 0 && apps[0].planId.status === "open" && (
                    <Col span={12} xs={24} sm={12} md={12} lg={5} xl={5} className="center">
                      <Button
                        style={{ width: "90%" }}
                        className="ant-btn secondary error large big-mobile"
                        onClick={() => handleClosePlanByGuide(plan._id)}
                      >
                        {icons.close} {"  "} Finalizar{" "}
                      </Button>
                    </Col>
                  )}
                </Row>
              </>
            </Panel>
          </Collapse>

          <Row className="box-info">
            {plan?.guideId && plan?.guideId?._id && (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div style={{ padding: 7, borderRadius: 5, marginBottom: 10, background: "#fff" }}>
                  <b>
                    Tour Guide: {plan?.guideId?.firstname} {plan?.guideId?.lastname || ""}
                  </b>
                  <SelectRoots
                    reload={() => {}}
                    currentValue={guideId}
                    defaultValue={guideId}
                    returnData={handleGuide}
                    typePack="SUBSCRIPTION"
                    title="Cambiar a"
                  />
                </div>
              </Col>
            )}
          </Row>

          <Collapse accordion defaultActiveKey={["1"]} onChange={callback}>
            {apps.length > 0 &&
              apps.map((item, index) => (
                <Panel
                  header={
                    <h2 style={{ margin: 0, position: "relative", top: 10 }}>
                      <span style={{ position: "relative", top: " -8px" }}>
                        {item.insitu ? (
                          <Button className="ant-btn secondary primary  " onClick={() => handleInsitu(item)}>
                            <Tooltip placement="top" title="Quitar asistencia">
                              {icons.check}
                            </Tooltip>
                          </Button>
                        ) : (
                          <Button className="ant-btn secondary error   " onClick={() => handleInsitu(item)}>
                            <Tooltip placement="top" title="Asignar asistencia">
                              {icons.triste}
                            </Tooltip>
                          </Button>
                        )}{" "}
                      </span>
                      <span style={{ fontSize: 26, position: "absolute", left: "40px", top: "-25px" }}>
                        {eventStatusFormatWord(item?.status)}
                      </span>
                      {item.userId.firstname} {item.userId.lastname || ""} x <b>{item.quantity}</b>
                    </h2>
                  }
                  key={item._id}
                >
                  <div className="box-info">
                    <Row gutter={16}>
                      <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                        <h3>
                          {icons.phone} {item.userId.phone || ""}
                          &nbsp;&nbsp;&nbsp;
                          {icons.email}{" "}
                          <a style={{ fontSize: 16 }} target="blank" href={`mailto:${item.userId.email}`}>
                            {item.userId.email}
                          </a>
                          &nbsp;&nbsp;&nbsp;
                          {icons.qr} {item.refererRootId?.firstname || ""} {item.refererRootId?.lastname || ""}
                          &nbsp;&nbsp;&nbsp; Obs: {item.userId?.observation || ""}
                        </h3>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      {String(item.userId?.phone).length > 9 && (
                        <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                          <Button
                            style={{ width: "100%" }}
                            onClick={() => window.open(`https://wa.me/${item.userId?.phone}`)}
                            className="ant-btn secondary info "
                          >
                            {icons.phone} Enviar Whatsapp
                          </Button>
                        </Col>
                      )}

                      {item.packId.isFree && (
                        <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                          <Button
                            style={{ width: "100%" }}
                            onClick={() => handleEdit(item)}
                            className="ant-btn secondary info "
                          >
                            {icons.plus_circle} Agregar tickets
                          </Button>
                        </Col>
                      )}
                      <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                        <Button
                          style={{ width: "100%" }}
                          onClick={() => handleEditUser(item.userId)}
                          className="ant-btn secondary info "
                        >
                          {icons.edit} Editar contacto
                        </Button>
                      </Col>
                      <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                        <Button
                          style={{ width: "100%" }}
                          onClick={() => handleArchived(item)}
                          className="ant-btn secondary error fill"
                        >
                          {icons.delete} Eliminar aplicación
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Panel>
              ))}
          </Collapse>

          <BucketContainer isEmbed planId={planId} />
        </div>
      ) : (
        <Spin tip="Cargando aplicaciones..." />
      )}

      <ManageAppModal2
        refetch={refetch}
        appId={appId}
        setAppId={setAppId}
        closeModal={closeManageAppsModal}
        openModal={isOpenManageAppsModal}
        t={t}
        cluuf="Agrega tickets y descripciones al app"
      />

      <ManageUserModal
        refetch={refetch}
        userId={userId_}
        setUserId={setUserId_}
        closeModal={closeManageUsersModal}
        openModal={isOpenManageUsersModal}
        initialValues={initialValues}
        selectedUser={selectedUser}
        setSelectedUserMain={setSelectedUser}
        page={page}
        t={t}
        cluuf="Edita el contacto"
      />

      <ManageBucketModal
        entityProp="User"
        typeProp="Gallery"
        entityId={appId}
        openModal={isOpenManageBucketModal}
        closeModal={closeManageBucketModal}
      />

      <Drawer
        title="Agregar contacto"
        width="90%"
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <CreateAppEvent
          onClose={onClose}
          refetch={refetch}
          planId_={planId}
          selectedApp={{ userId: "123456789", startDate: null, closureDate: null }}
        />
      </Drawer>

      <Drawer title="Mover contacto a otro evento" width="90%" onClose={onClose} visible={openCreateAppEvent}>
        <CreateAppEvent
          onClose={onClose}
          refetch={refetch}
          userSelected={selectedApp.userId}
          selectedApp={selectedApp}
        />
      </Drawer>
    </>
  );
};

export default withRouter(Apps);
