import { useState } from "react";

import { Table, Space, Tooltip, Button, Typography, Tag } from "antd";
import { useSelector } from "react-redux";

import { TagTwoTone, TagFilled } from "@ant-design/icons";

import { withRouter } from "react-router-dom";

import BaseTable from "../../shared/components/BasicTable";

import {
  isRoleAdmin,
  icons,
  formatSuccess,
  isRoleAnalist,
  tagAppStatus,
  colorsSystem,
} from "../../../constants/globals";

import ActionsTable from "./ActionsTable";

const { Link } = Typography;

const moment = require("moment");

const PlansTable = ({
  selectedUser,
  setSelectedUser,
  setPage,
  history,
  plans,
  refetch,
  initialValues,
  handleDetails,
  ctaHandleDetails,
  handleEdit,
  userId,
  handleArchivedPlan,
  handleAddImage,
  t,
  handleActivePlan,
  packData,
  isEmbed,
  handleResume,
  root,
  handleConsolidate,
  handleClosePlanByGuide,
  isMinimal = false,
  ...restProps
}) => {
  const sessionStoreRedux = useSelector((store) => store.session);
  const instanceStoreRedux = useSelector((store) => store.instance);
  const { role } = sessionStoreRedux.currentSession;

  const handleDetails2 = (data) => {
    history.push(`/event/${data._id}`);
  };

  const [loadingDetails, setLoadingDetails] = useState(null);
  const handleLoading = (data) => setLoadingDetails(data._id);

  const filterPlans = (plans_) => {
    const data = [];
    plans_.forEach((element) => {
      if (moment().diff(moment(element.closuredate, "YYYY-MM-DD"), "days") < 0) {
        data.push({ ...element, bgcolor: "orange" });
      } else if (moment().diff(moment(element.closuredate, "YYYY-MM-DD"), "days") === 0) {
        data.push({ ...element, bgcolor: "green" });
      } else {
        data.push({ ...element, bgcolor: "#dd2527" });
      }
    });
    return data;
  };

  return (
    <>
      {!isMinimal && (
        <BaseTable {...restProps} dataSource={plans} refetch={refetch} scroll={{ y: 450 }}>
          <Table.Column
            align="center"
            title={t("plan_openIt")}
            dataIndex="actions"
            key="actions"
            width="70px"
            render={(_, data) => (
              <div>
                <Space size="small">
                  {isRoleAdmin(role) && (
                    <Button
                      size="small"
                      className="btn-ant secondary primary"
                      onClick={() => {
                        handleLoading(data);
                        handleDetails(data);
                      }}
                    >
                      {loadingDetails === data._id ? <img alt="" src={icons.loading} width="15" /> : t("plan_openIt")}
                    </Button>
                  )}
                  {isRoleAnalist(role) && (
                    <Tooltip title="Muestra un resumen de esa aplicación">
                      <Button size="small" className="ant-btn secondary" onClick={() => handleResume(data)}>
                        {t("plan_openIt")}
                      </Button>
                    </Tooltip>
                  )}
                </Space>
              </div>
            )}
          />

          <Table.Column
            title={t("plan_closeIt")}
            dataIndex="_id"
            key="name"
            width="120px"
            align="center"
            render={(_, data) => (
              <>
                {data.status === "open" && data.isTemporal && (
                  <Tooltip title={t("plan_closeIt")}>
                    <Button
                      size="small"
                      className="ant-btn secondary primary"
                      onClick={() => handleClosePlanByGuide(data)}
                    >
                      Finalizar
                    </Button>
                  </Tooltip>
                )}
                {data.status === "close" && data.isTemporal && (
                  <Tooltip title={`Finalizado por ${data.closeBy} ${data.closeAt}`}>
                    <small>
                      {" "}
                      {icons.close} {data.closeBy}
                    </small>
                  </Tooltip>
                )}
              </>
            )}
          />

          <Table.Column
            title={t("plan_status")}
            dataIndex="_id"
            key="name"
            align="center"
            width="100px"
            render={(_, data) => <>{tagAppStatus(data.status)}</>}
          />

          <Table.Column
            align="center"
            style={{ fontSize: 14 }}
            title={t("apps")}
            dataIndex="progress"
            key="progress"
            sorter
            width="70px"
            render={(_, row) => <h2>{formatSuccess(row.progress + row.starting + row.closed)}</h2>}
            responsive={["lg"]}
          />

          <Table.Column
            title={t("service")}
            dataIndex="_id"
            key="name"
            render={(_, data) => (
              <h3>
                {data.packId.name}{" "}
                {data?.pending > 0 && (
                  <Tag color="volcano">
                    <small>
                      <span style={{ fontSize: 16 }}>{data?.pending}</span> apps pendientes{" "}
                    </small>
                  </Tag>
                )}
              </h3>
            )}
          />
          <Table.Column
            title={t("plan")}
            dataIndex="_id"
            key="name"
            render={(_, data) => (
              <h3>
                {data.name}
                {data.closuredate && moment().diff(moment(data.closuredate, "YYYY/MM/DD"), "days") > 0 && (
                  <Tag color="volcano">
                    {icons.pending} Evento caducado hace {moment().diff(moment(data.closuredate, "YYYY/MM/DD"), "days")}{" "}
                    días
                  </Tag>
                )}
              </h3>
            )}
          />

          <Table.Column title="Form Id" dataIndex="_id" key="_id" />

          <Table.Column
            width="150px"
            title={t("actions")}
            dataIndex="active"
            key="active"
            responsive={["lg"]}
            align="center"
            render={(_, data) => (
              <div>
                {data?.packId && (
                  <ActionsTable
                    t={t}
                    row={data}
                    root={root}
                    refetch={refetch}
                    instanceStoreRedux={instanceStoreRedux}
                    handleArchivedPlan={handleArchivedPlan}
                  />
                )}
              </div>
            )}
          />
        </BaseTable>
      )}

      {isMinimal && (
        <>
          <hr />
          <Space>
            <Link
              className="ant-btn"
              style={{ background: colorsSystem.red, color: colorsSystem.white }}
              href="./home/31"
            >
              {icons.back} Vista Admin
            </Link>
          </Space>{" "}
          <hr />
          <div style={{ padding: 20, fontWeight: "bold", fontSize: 16 }}>
            <TagTwoTone twoToneColor="#dd2527" /> Eventos pasados | <TagTwoTone twoToneColor="green" /> Eventos
            presentes| <TagTwoTone twoToneColor="orange" /> Eventos futuros
          </div>
          <BaseTable {...restProps} dataSource={filterPlans(plans)} refetch={refetch} scroll={{ x: 600 }}>
            <Table.Column
              title={t("plan")}
              dataIndex="_id"
              key="name"
              width="350px"
              render={(_, data) => (
                <div style={{ borderLeft: `20px solid ${data.bgcolor}`, paddingLeft: 10, paddingTop: 10 }}>
                  <h2 className="special-font ">
                    <span className="bold">
                      {icons.guest} {data.totalApps} x {data.packId.name}{" "}
                      {moment().diff(moment(data.closuredate, "YYYY-MM-DD"), "days")}
                      <br />
                    </span>
                    <span>
                      {icons.calendar} {data.name} <br />
                    </span>
                  </h2>

                  <Button className="ant-btn secondary primary" size="medium" onClick={() => handleDetails2(data)}>
                    Ver más Detalles
                  </Button>
                  <hr />
                </div>
              )}
            />
          </BaseTable>
        </>
      )}
    </>
  );
};

export default withRouter(PlansTable);
