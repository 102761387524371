import { Table, Space, Tooltip, Button } from "antd";
import { CheckCircleTwoTone, EditOutlined, DeleteOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import BaseTable from "../../shared/components/BasicTable";
import { constantsColor } from "../../../constants/colors";

const ServicesTable = ({
  selectedService,
  setSelectedService,
  setPage,
  history,
  services,
  refetch,
  initialValues,
  handleDetails,
  handleEdit,
  serviceId,
  handleArchived,
  t,
  handleInactive,
  ...restProps
}) => (
  <>
    <BaseTable {...restProps} dataSource={services} refetch={refetch} scroll={{ y: 450 }}>
      <Table.Column
        fixed="left"
        width="150px"
        align="center"
        title={t("actions")}
        dataIndex="actions"
        key="actions"
        render={(_, data) => (
          <div className="">
            <Space size="small">
              <Button
                size="small"
                className="btn-ant secondary primary"
                onClick={() => {
                  handleDetails(data);
                }}
              >
                Abrir
              </Button>
              <a href="#!" onClick={() => handleDetails(data)} role="button" tabIndex={0}>
                <Tooltip placement="top" title={`${t("clickTo")} ${t("moreDetails")}`}>
                  <userOutlined />
                </Tooltip>
              </a>
              <a href="#!" onClick={() => handleEdit(data)} role="button" tabIndex={0}>
                <Tooltip placement="top" title={`${t("clickTo")} ${t("edit")}`}>
                  <EditOutlined />{" "}
                </Tooltip>
              </a>
              <a href="#!" onClick={() => handleArchived({ service: data, t, refetch })} role="button" tabIndex={0}>
                <Tooltip placement="top" title={`${t("clickTo")} ${t("delete")}`}>
                  <DeleteOutlined />
                </Tooltip>
              </a>
              <a href="#!" onClick={() => handleInactive({ service: data, t, refetch })} role="button" tabIndex={0}>
                {data.active ? (
                  <Tooltip placement="top" title={`${t("clickToChange")} ${t("serviceOnHold")}`}>
                    <CheckCircleTwoTone twoToneColor={constantsColor.success} />
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title={`${t("clickToChange")} ${t("serviceCompleted")}`}>
                    <ClockCircleOutlined className="danger" style={{ color: constantsColor.warning }} />
                  </Tooltip>
                )}
              </a>
            </Space>
          </div>
        )}
      />

      <Table.Column
        title={t("description")}
        dataIndex="description"
        key="description"
        render={(text, row) => <>{row.description}</>}
      />

      <Table.Column
        title={t("supplier")}
        dataIndex="supplier"
        key="supplier"
        render={(text, row) => <>{row.supplierId?.fullname}</>}
      />

      <Table.Column title={t("price")} dataIndex="price" key="price" render={(text, row) => <>{row.price}</>} />
    </BaseTable>
  </>
);

export default withRouter(ServicesTable);
