/* eslint-disable no-unused-expressions */
import { PageHeader, Button, Descriptions, Divider } from "antd";
import { withRouter } from "react-router-dom";
import { icons, dateFormat6, moneyFormat, priceFormat } from "../../../constants/globals";

const Analist = ({ user = {}, app = {}, closeUserSection, handleCheckin, handleUpdateAvatar, t, handleUpdateUser }) => (
  <PageHeader
    ghost={false}
    avatar={{ src: user?.avatar }}
    onBack={closeUserSection}
    style={{ marginTop: 10 }}
    title={`${user?.firstname} ${user?.lastname}`}
    subTitle={`${app?.packId.name} ${app?.planId.name}`}
    extra={[
      <Button className="third" onClick={() => handleCheckin(app)} key="2">
        {icons.plus_circle} Asistencía
      </Button>,
      <Button className="third" onClick={() => handleUpdateUser(app)} key="5">
        {icons.plus_circle} Pagos
      </Button>,
      <Button className="third" onClick={() => handleUpdateUser(app)} key="6">
        {icons.plus_circle} Invitado
      </Button>,
      <Button className="third" onClick={() => handleUpdateAvatar(app)} key="3">
        {icons.edit} Foto
      </Button>,
      <Button className="third" onClick={() => handleUpdateUser(app)} key="4">
        {icons.edit} Perfil
      </Button>,
      <Button onClick={closeUserSection} key="1">
        {icons.close}
      </Button>,
    ]}
  >
    {" "}
  </PageHeader>
);

export default withRouter(Analist);
