import { Tabs } from "antd";
import { SolutionOutlined, ScheduleOutlined, PictureOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

const TabMenu = ({ setSubpage, t, numbers }) => {
  const callback = (key) => {
    setSubpage(key);
  };

  return (
    <Tabs defaultActiveKey={numbers.THREE} onChange={callback}>
      <TabPane
        tab={
          <>
            <SolutionOutlined />
            {t("plans")}
          </>
        }
        key={numbers.THREE}
      />
      <TabPane
        tab={
          <>
            <ScheduleOutlined />
            {t("calendar")}
          </>
        }
        key={numbers.ONE}
      />
      <TabPane
        tab={
          <>
            <PictureOutlined />
            {t("imageGallery")}
          </>
        }
        key={numbers.TWO}
      />
    </Tabs>
  );
};

export default TabMenu;
