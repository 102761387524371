import { PageHeader } from "antd";

const Header = ({ primaryAction, t }) => (
  <>
    <PageHeader
      avatar={{ src: "https://cluuftracks.herokuapp.com/bot.gif" }}
      className="site-page-header-responsive"
      title={t("page")}
      extra={[primaryAction && primaryAction]}
    />
  </>
);

export default Header;
