import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import ActionBar from "../../shared/components/ActionBar";
import PacksTable from "../components/PacksTable";
import SearchPacksForm from "../components/SearchPacksForm";
import ActionsUsers from "../components/ActionsPacks";
import ManagePackModal from "../components/ManagePackModal";
import ManagePackWebsite from "../components/PackWebsite";
import ManageEventPackModal from "../components/ManageEventPackModal";
import ManageSubscriptionPackModal from "../components/ManageSubscriptionPackModal";

import AddUserButton from "../components/AddPackButton";
import PackHeader from "../components/PackHeader";
import PacksHeader from "../components/PacksHeader";
import PacksCard from "../components/PacksCard";
import TabMenu from "../components/Tabs";

import ManagePlanSubscriptionModal from "../../subscribersPlans/components/ManagePlanSubscriptionModal";

import ManageBucketModal from "../../bucket/components/ManageBucketModal";

// HELPERS
import showGlobalNotification from "../../../helpers/showGlobalNotification";

// REQUEST
import { getPack, archivedPack, activePack, duplicatePack } from "../requests";

// REDUX
import { setPackRedux } from "../../../redux/pack";

// HOOKS
import useModal from "../../shared/hooks/useModal";
import usePacks from "../hooks/usePacks";

// CONSTANTS
import { globals, numbers, icons } from "../../../constants/globals";

// CONTAINER
import BucketContainer from "../../bucket/containers/Buckets";
import PlansContainer from "../../subscribersPlans/containers/Plans";

import { setModePack, getModePack } from "../../../helpers/defaultOptions";

const Packs = ({ isEmbed = false, history, PackId = null, pageNum = null }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { packs, filters, setFilters, pagination, setPagination, loading, refetch } = usePacks();
  const [isOpenManagePlanModal, openManagePlanModal, closeManagePlanModal] = useModal();

  const commonProps = { filters, setFilters };
  const [page, setPage] = useState(globals.PAGE_MAIN);
  const [subpage, setSubpage] = useState(numbers.ONE);
  const [selectedPack, setSelectedPack] = useState({});
  const [packId, setPackId] = useState(null);
  const [newPlan, setNewPlan] = useState(false);
  const [isOpenManagePacksModal, openManagePacksModal, closeManagePacksModal] = useModal();

  const [reloadApp] = useState(0);

  const [isOpenManagePlansModal, openNewPlanModal] = useModal();
  const [containerMode, setContainerMode] = useState("table");
  const [isOpenManageBucketModal, openManageBucketModal, closeManageBucketModal] = useModal();
  const [isOpenManageBucketModalGallery, openManageBucketModalGallery, closeManageBucketModalGallery] = useModal();
  const [isOpenManageBucketModalAvatar, openManageBucketModalAvatar, closeManageBucketModalAvatar] = useModal();

  const [reload, setReload] = useState(0);
  const [imageName, setImageName] = useState(true);

  const [isOpenManageEventPacksModal, openManageEventPacksModal, closeManageEventPacksModal] = useModal();
  const [isOpenManageSubscriptionPacksModal, openManageSubscriptionPacksModal, closeManageSubscriptionPacksModal] =
    useModal();

  const [goback, setGoback] = useState(false);
  useEffect(() => {
    setPackRedux(selectedPack)(dispatch);
  }, [selectedPack]);
  useEffect(() => {
    // setContainerMode(getModePack());
    setContainerMode("table");
  }, []);

  const refreshEntity = (_id, { onSuccess = {}, onError = {} }) => {
    getPack(_id, {
      onSuccess: (response) => {
        setPackRedux(response.result)(dispatch);
        setSelectedPack(response.result);
        setPackId(_id);
        if (response.result) onSuccess(response.result);
        else onError();
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
  };

  const refreshEntity2 = (_id, { onSuccess = {}, onError = {} }) => {
    getPack(_id, {
      onSuccess: (response) => {
        setPackRedux(response.result)(dispatch);
        setSelectedPack(response.result);
        if (response.result) onSuccess(response.result);
        else onError();
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
  };

  const handleDuplicate = (pack) => {
    if (pack._id) {
      setSelectedPack(pack);
      refreshEntity2(pack._id, {
        onSuccess: (result) => {
          confirmAlert({
            title: `${t("duplicate")} ${pack.name}`,
            message: `Esta seguro que desea Duplicar este registro?`,
            buttons: [
              {
                label: t("yes"),
                onClick: () => {
                  duplicatePack(
                    {
                      ...result,
                      packId: result._id,
                      plansDuplicate: true,
                      name: `${pack.name}-duplicate-${Math.floor(Math.random() * 100)}`,
                    },
                    {
                      onSuccess: () => {
                        showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
                      },
                      onError: () => {
                        showGlobalNotification("error", t("error"), t("error"));
                      },
                      onFinally: () => {
                        refetch();
                      },
                    }
                  );
                },
              },
              {
                label: t("no"),
                onClick: () => {},
              },
            ],
          });
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  };

  const handleNew = () => {
    setPackId(null);
    openManagePacksModal();
  };

  const handleNewPlanSubscription = (pack) => {
    setPackId(pack._id);
    setSelectedPack(pack);
    openManagePlanModal();
  };

  const handleEdit = (pack) => {
    setSelectedPack(pack);
    setPackId(pack._id);
    openManagePacksModal();
  };

  const handleEditEvent = (pack) => {
    setSelectedPack(pack);
    setPackId(pack._id);
    openManageEventPacksModal();
  };

  const handleEditSubscription = (pack) => {
    setSelectedPack(pack);
    setPackId(pack._id);
    openManageSubscriptionPacksModal();
  };

  const handleDetails = (pack) => {
    setPage(globals.PAGE_ONE);
    setSubpage(numbers.THREE);
    setPackId(pack._id);
    refreshEntity(pack._id, {
      onSuccess: () => {},
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
  };

  const handleArchivedConfirm = (pack) => {
    archivedPack(
      {
        pack,
      },
      {
        onSuccess: () => {
          refetch();
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleArchived = (pack) => {
    setPackId(pack._id);
    confirmAlert({
      title: `Eliminar ${pack.name}`,
      message: `Esta seguro que desea eliminar el ${pack.name}?`,
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleArchivedConfirm(pack),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleActiveConfirm = (pack) => {
    activePack(
      {
        pack,
      },
      {
        onSuccess: () => {
          refetch();
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleActive = (pack) => {
    setPackId(pack._id);
    confirmAlert({
      title: pack.active ? "Desactivar el Paquete" : "Activar el Paquete",
      message: "Esta seguro que desea continuar?",
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleActiveConfirm(pack),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleNewPlan = (pack) => {
    setPackId(pack._id);
    setPackRedux(pack);
    setNewPlan(true);
  };

  const handleNewLinkModal = () => {
    openManageBucketModal();
  };

  const handleUploadGallery = (name) => {
    setImageName(name);
    openManageBucketModalGallery();
  };

  const handleGoto = ({ number, entity }) => {
    history.push(`/home/${number}/${entity.id}`);
  };

  const modeDetails = (entity) => {
    if (isEmbed) {
      handleGoto({ number: pageNum, entity });
    } else {
      handleDetails(entity);
    }
  };

  const resetFilters = () => {
    setFilters({ ...filters, all: "" });
    refetch();
  };

  useEffect(() => {
    if (selectedPack && selectedPack._id) {
      console.log("refrescando pack");
      refreshEntity(selectedPack._id, {
        onSuccess: () => {},
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  }, [reload]);

  useEffect(() => {
    refetch();
  }, [filters]);

  useEffect(() => {
    if (PackId) {
      handleDetails({ _id: PackId });
      setGoback(true);
    }
  }, []);

  useEffect(() => {
    if (page === globals.PAGE_MAIN) {
      setPackId(null);
      setSelectedPack({});
    }
  }, [page]);

  return (
    <>
      {page === globals.PAGE_MAIN && (
        <div>
          <PacksHeader
            title={
              <h4 className="bold special-font big-font">
                {icons.services} {t("common_services")}
              </h4>
            }
            t={t}
            primaryAction={
              <>
                <AddUserButton
                  refetch={refetch}
                  isOpenManagePacksModal={isOpenManagePacksModal}
                  handleNew={handleNew}
                  setContainerMode={setContainerMode}
                  containerMode={containerMode}
                  setModePack={setModePack}
                  t={t}
                />

                <Button className="ant-btn secondary primary large" onClick={() => openManageEventPacksModal()} t={t}>
                  {icons.plus_circle} Evento
                </Button>

                <Button
                  className="ant-btn secondary primary large"
                  onClick={() => openManageSubscriptionPacksModal()}
                  t={t}
                >
                  {icons.plus_circle} Subscripciones
                </Button>
              </>
            }
          />
          <section className="body-page">
            <div className="container-subpages">
              <ActionBar t={t} {...commonProps} options={{ actions: ActionsUsers }}>
                <SearchPacksForm
                  resetFilters={resetFilters}
                  t={t}
                  refetch={refetch}
                  filters={filters}
                  setFilters={setFilters}
                />
              </ActionBar>
              {containerMode === "table" && (
                <PacksTable
                  {...commonProps}
                  packs={packs}
                  pagination={pagination}
                  setPagination={setPagination}
                  loading={loading}
                  refetch={refetch}
                  setPage={setPage}
                  setSelectedPack={setSelectedPack}
                  selectedPack={selectedPack}
                  handleDetails={handleDetails}
                  handleEdit={handleEdit}
                  details={modeDetails}
                  handleArchived={handleArchived}
                  handleActive={handleActive}
                  handleDuplicate={handleDuplicate}
                  t={t}
                />
              )}
              {containerMode === "cards" && (
                <PacksCard
                  {...commonProps}
                  packs={packs}
                  pagination={pagination}
                  setPagination={setPagination}
                  loading={loading}
                  refetch={refetch}
                  // initialValues={initialValues}
                  setPage={setPage}
                  setSelectedPack={setSelectedPack}
                  selectedBucket={selectedPack}
                  handleDetails={handleDetails}
                  handleEdit={handleEdit}
                  details={modeDetails}
                  handleArchived={handleArchived}
                />
              )}
            </div>
          </section>
        </div>
      )}
      {page === globals.PAGE_ONE && (
        <>
          <PackHeader
            setPage={setPage}
            handleNewPlanSubscription={handleNewPlanSubscription}
            selectedPack={selectedPack}
            handleDetails={handleDetails}
            handleEdit={handleEdit}
            setSubpage={setSubpage}
            handleArchived={handleArchived}
            openNewPlanModal={openNewPlanModal}
            handleNewPlan={handleNewPlan}
            handleNewLinkModal={handleNewLinkModal}
            handleEditEvent={handleEditEvent}
            handleEditSubscription={handleEditSubscription}
            t={t}
          />

          <div className="container-subpages">
            <TabMenu setSubpage={setSubpage} t={t} numbers={numbers} />

            {subpage === numbers.TWO && selectedPack && selectedPack._id && packId && (
              <div className="subcontainer-wrapper">
                <BucketContainer isEmbed="true" packId={packId} />
              </div>
            )}
            {subpage === numbers.THREE && selectedPack && selectedPack._id && packId && (
              <>
                <div className="subcontainer-wrapper">
                  <PlansContainer
                    refresh={reload}
                    packData={selectedPack}
                    isEmbed="true"
                    packId={packId}
                    reload={reloadApp}
                  />
                </div>
              </>
            )}

            {subpage === numbers.FOUR && selectedPack && selectedPack._id && packId && (
              <>
                <div className="subcontainer-wrapper">
                  {selectedPack && (
                    <ManagePackWebsite
                      packData={selectedPack}
                      isEmbed="true"
                      packId={selectedPack._id}
                      reload={reloadApp}
                      handleUploadGallery={handleUploadGallery}
                      setReload_={setReload}
                      refetch={refetch}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
      <ManagePackModal
        t={t}
        refetch={refetch}
        packId={packId}
        setPackId={setPackId}
        closeModal={closeManagePacksModal}
        openModal={isOpenManagePacksModal}
      />

      <ManageEventPackModal
        t={t}
        refetch={refetch}
        packId={packId}
        setPackId={setPackId}
        closeModal={closeManageEventPacksModal}
        openModal={isOpenManageEventPacksModal}
      />

      <ManageSubscriptionPackModal
        t={t}
        refetch={refetch}
        packId={packId}
        setPackId={setPackId}
        closeModal={closeManageSubscriptionPacksModal}
        openModal={isOpenManageSubscriptionPacksModal}
      />

      {packId && (
        <ManagePlanSubscriptionModal
          refetch={refetch}
          closeModal={closeManagePlanModal}
          openModal={isOpenManagePlanModal}
          packId={packId}
          setReloadMain={setReload}
          t={t}
        />
      )}

      <ManageBucketModal
        entityProp="Pack"
        typeProp="Gallery"
        entityId={packId}
        openModal={isOpenManageBucketModal}
        closeModal={closeManageBucketModal}
      />
      <ManageBucketModal
        entityProp="Pack"
        typeProp="Gallery"
        nameProp={imageName}
        entityId={packId}
        openModal={isOpenManageBucketModalGallery}
        closeModal={closeManageBucketModalGallery}
        setReload_={setReload}
      />
      <ManageBucketModal
        entityProp="Pack"
        typeProp="Avatar"
        nameProp={imageName}
        entityId={packId}
        openModal={isOpenManageBucketModalAvatar}
        closeModal={closeManageBucketModalAvatar}
        setReload_={setReload}
      />
    </>
  );
};

export default withRouter(Packs);
