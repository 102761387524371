import { Table, Tooltip, Button } from "antd";

import { birthdate, dateFormat4, isPhoneContact, isAgeContact, icons } from "../../../constants/globals";

const moment = require("moment");

const nearClosed = (data) => {
  if (data.closureDateEstimated) {
    if (moment().diff(moment(data.closureDateEstimated), "days") > -7) {
      return true;
    }
    return false;
  }
  return false;
};

const TableApps = ({ data, handleUpdateUser, handleOpenUser }) => {
  const columns = [
    {
      title: "Asistio?",
      dataIndex: "insitu",
      render: (_, row) => <>{row?.insitu ? icons.check_enabled : icons.check_disabled}</>,
    },
    {
      title: "nombre completo",
      dataIndex: "_id",
      key: "_id",
      sorter: true,
      render: (_, row) => (
        <>
          <span style={{ fontSize: 14, textTransform: "uppercase" }}>
            <Button size="small" className="ant-btn secondary info" onClick={() => handleOpenUser(row)} tabIndex={0}>
              <Tooltip placement="top" title="Abrir aplicación">
                Abrir
              </Tooltip>
            </Button>
            {"  "}
            <Button
              size="small"
              className="ant-btn secondary primary"
              onClick={() => handleUpdateUser(row)}
              role="button"
              tabIndex={0}
            >
              <Tooltip placement="top" title="Editar">
                {icons.edit}
              </Tooltip>
            </Button>
            {"  "}
            <b>
              {row.userId.firstname} {row.userId.lastname || ""}
            </b>
            {row.userId.birthdate ? `(${birthdate(row.userId.birthdate)})` : ""}
          </span>
        </>
      ),
    },

    {
      title: "Genero",
      dataIndex: "code",
      render: (_, row) => (
        <>
          <Tooltip title={row.userId.phone}>{isPhoneContact(row.userId.phone || "")}</Tooltip>
          {"  "}
          <Tooltip title={row.userId.birthdate}>{isAgeContact(row.userId.birthdate || "")}</Tooltip>
          {"  "}
        </>
      ),
    },
    {
      title: "Plan",
      dataIndex: "code",
      render: (_, row) => (
        <>
          {row?.packId?.name} {row?.planId?.name}
        </>
      ),
    },
  ];

  return <Table pagination={false} columns={columns} dataSource={data} />;
};

export default TableApps;
