import { customFetch, FetchJson } from "../../helpers/fetch";
import { apiRoutes } from "../../constants/routes";
import { Excel } from "../../helpers/excelExport";
import { dateFormat4, excelFilename, birthdate } from "../../constants/globals";

const moment = require("moment");

const saveService = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { serviceId } = props;
  const method = serviceId ? "PUT" : "POST";
  const path = serviceId ? `${apiRoutes.SERVICES.SAVE_DATA}/${serviceId}` : `${apiRoutes.SERVICES.SAVE_DATA}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const getService = async (serviceId = 0, { onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.SERVICES.GET_DATA}/${serviceId}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const deleteService = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  try {
    const request = await customFetch(apiRoutes.CATEGORIES.DELETE_CATEGORY, {
      method: "POST",
      body: new URLSearchParams(params),
    });

    const response = await request.json();

    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const archivedService = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id } = params.service;
  const method = "PUT";
  const path = `${apiRoutes.SERVICES.ARCHIVED}/${_id}`;

  try {
    const request = await FetchJson(path, method, { archived: true });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const activeService = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id, active } = params.service;
  const method = "PUT";
  const path = `${apiRoutes.SERVICES.ACTIVE}/${_id}`;

  try {
    const request = await FetchJson(path, method, { active: !active });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const getServicesBySupplier = async ({ type = "", supplierId = null }, { onSuccess, onError } = {}) => {
  try {
    let path = `${apiRoutes.SERVICES.ALL}/${supplierId}`;
    if (!supplierId) {
      path = `${apiRoutes.SERVICES.ALL}`;
    }

    const request = await FetchJson(path, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const updateService = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const method = "PUT";
  const path = `${apiRoutes.SERVICES.UPDATE_BY_ANALIST}`;

  try {
    const request = await FetchJson(path, method, params);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const getServices = async ({ onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.SERVICES.ALL}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const searchServices = async (text, { onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.SERVICES.SEARCH}?text=${text}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const exportExcelServices = (data) => {
  getServices({
    onSuccess: (result) => {
      let row = "";
      let title = "";
      const filename = `Listado de Contactos ${excelFilename(moment.now())}`;
      // eslint-disable-next-line prefer-const
      let contador = 0;

      const head = `
      <tr>
      <td colspan="5"><h3>${title}</h3></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td style="width:250px" ><b>Nombre Completo</b></td>
      <td><b>Email</b></td>
      <td><b>Tipo de Documento</b></td>
      <td><b>Documento</b></td>
      <td><b>Teléfono</b></td>
      <td><b>Recomendado por</b></td>
      <td><b>Fecha de Nacimiento</b></td>
      <td><b>Edad</b></td>
      <td><b>Direcció de Habitación</b></td>
      <td><b>Interes</b></td>
      <td><b>Fecha Registro</b></td>
      </tr>`;

      result.result.forEach((item) => {
        title = `${parseInt(contador) + 1} Contactos`;
        row = `${row} <tr>
        <td>${item.firstname} ${item.lastname || ""}</td>
        <td>${item.email}</td>
        <td>${item.documentType || ""}</td>
        <td style="text-align:left">${item.document || ""}</td>
        <td>${item.phone || ""}</td>
        <td>${item.medium || ""}</td>
        <td>${item.birthdate || ""}</td>
        <td>${birthdate(item.birthdate) || ""}</td>
        <td>${item.address || ""}</td>
        <td>${item.interests[0] || ""} ${item.interests[1] || ""}</td>
        <td>${dateFormat4(item.creartedAt)}</td>
       </tr>`;
      });
      Excel(`${head}${row}`, filename);
    },
    onError: (err) => console.log(err),
  });

  /*
  

  const head = `
  <tr>
  <td colspan="5"><h3>${title}</h3></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  </tr>
  <tr>
  <td style="width:250px" ><b>Nombre Completo</b></td>
  <td><b>Email</b></td>
  <td><b>Tipo de Documento</b></td>
  <td><b>Documento</b></td>
  <td><b>Observación</b></td>
  <td><b>Teléfono</b></td>
  <td><b>Fecha registro</b></td>
  <td><b>Modalidad de pago</b></td>
  <td><b>Fecha de nacimiento</b></td>
  <td><b>Activo?</b></td>
  <td><b>Interes</b></td>
  <td><b>Rol</b></td>
  <td><b>Referido por</b></td>
  </tr>`;

  Excel(`${head}${row}`, filename);
  */
};

export {
  getService,
  getServices,
  saveService,
  deleteService,
  archivedService,
  activeService,
  searchServices,
  exportExcelServices,
  updateService,
  getServicesBySupplier,
};
