import { useState, useEffect } from "react";
import { apiRoutes } from "../../../constants/routes";
import useFetch from "../../shared/hooks/useFetch";
import { getUserId } from "../../../utils/localStorage";

const useSuppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [filters, setFilters] = useState({
    role: "",
    active: "",
    email: "",
    nombre: "",
    firstname: "",
    lastname: "",
    sord: "desc",
    sidx: "_id",
    rootId: getUserId(),
  });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100, total: 0 });
  const { data, loading, refetch } = useFetch(
    apiRoutes.SUPPLIERS.LOAD_GRID,
    "POST",
    new URLSearchParams({ ...filters, page: pagination.current, rows: pagination.pageSize })
  );

  useEffect(() => {
    if (data) {
      setSuppliers(data?.rows);
      setPagination({ ...pagination, current: parseInt(data?.page), total: parseInt(data?.records) });
    }
  }, [data]);

  return { suppliers, filters, setFilters, pagination, setPagination, loading, refetch };
};

export default useSuppliers;
