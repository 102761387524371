import { useState, useEffect } from "react";
import { Form, Row, Col, Input, Checkbox, DatePicker, Space, Button, Select, InputNumber } from "antd";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { confirmAlert } from "react-confirm-alert";

import { withRouter } from "react-router-dom";
import { icons, durationFormat } from "../../../constants/globals";

import { updateSubscriptionApp } from "../requests";

import SelectPacks from "../../subscribers/components/SelectPacks";
import SelectPlans from "../../subscribersPlans/components/SelectPlans";

const moment = require("moment");
const Swal = require("sweetalert2");

const defaultValues = {
  firstname: "",
  lastname: "",
  document: "",
  documentType: "",
  paymentMode: "none",
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  paymentReference: "",
  phone: "+57",
  alergies: "",
  bodylesson: "",
  address: "",
  profession: "",
  medium: "",
  city: "",
  description: "",
  packId: null,
  planId: null,
};

const UpdateApp = ({ selectedApp, onClose, refetch }) => {
  const { t } = useTranslation();
  const instanceStoreRedux = useSelector((store) => store.instance);

  const [setting, setSetting] = useState({});
  const [app, setApp] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [form] = Form.useForm();

  const [packId, setPackId] = useState(null);
  const [planId, setPlanId] = useState(null);

  const [plan, setPlan] = useState({});
  const [reload, setReload] = useState(0);
  const [userExist, setUserExist] = useState(false);
  const [userCheckin, setUserCheckin] = useState(true);
  const [userVaccine, setUserVaccine] = useState(true);
  const [userGift, setUserGift] = useState(false);
  const [userPrivacy, setUserPrivacy] = useState(true);
  const [packInfo, setPackInfo] = useState({});
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onValuesChange = (values) => {
    setData({ ...data, ...values });
  };

  const onChangeVaccine = (e) => {
    setUserVaccine(e.target.checked);
  };

  const onChangeGift = (e) => {
    setUserGift(e.target.checked);
  };

  const handleStartdate = (values) => {
    setData({
      ...data,
      closureDate: moment(values).add(selectedApp.planId.duration, durationFormat(selectedApp.planId.durationMode)),
      startDate: values,
      startdate: moment(values).format("YYYY-MM-DD"),
      closuredate: moment(values)
        .add(selectedApp.planId.duration, durationFormat(selectedApp.planId.durationMode))
        .format("YYYY-MM-DD"),
    });
  };

  const onFinish = () => {
    setData({ ...data, app: selectedApp, active: true, userExist, userCheckin, userVaccine, userGift, userPrivacy });

    if (data.packId === undefined || data.planId === undefined) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor validé Paquete y Plan",
      });
      return;
    }

    if (data.startDate === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor validé la fecha de inicio",
      });
      return;
    }

    setLoadingSubmit(true);
    updateSubscriptionApp(
      {
        ...data,
        app: selectedApp,
        active: true,
        isUserExist: true,
        isUserCheckin: userCheckin,
        isWelcomeGift: userGift,
        appId: selectedApp._id,
        packId: selectedApp.packId._id,
        planId: selectedApp.planId._id,
      },
      {
        onSuccess: () => {
          Swal.fire({
            icon: "success",
            title: "Operación Exitosa",
          });

          setData(defaultValues);
          refetch();
          onClose();
        },
        onError: () => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        },
        onFinally: () => {
          setLoadingSubmit(false);
        },
      }
    );
  };

  const handleSubmit = () => {
    confirmAlert({
      title: "Confirmación",
      message: `Esta seguro que desea continuar?`,
      buttons: [
        {
          label: "Continuar",
          onClick: () => form.submit(),
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const handlePack = (value) => {
    setPackId(value);
    setPlanId(null);
    setData({
      ...data,
      packId: value,
      startDate: moment(selectedApp?.startDate, "YYYY/MM/DD"),
      closureDate: null,
    });
  };

  const handlePlan = (value) => {
    setPlanId(value);
    setData({
      ...data,
      planId: value,
      startDate: null,
      closureDate: null,
    });
  };

  useEffect(() => {
    setPlan({});
    setData({
      ...data,
      packId,
    });
  }, [packId]);

  useEffect(() => {
    /*  setData({
      ...data,
      plan,
      startDate: null,
      closureDate: null,
    }); */
  }, [plan]);

  useEffect(() => {
    if (selectedApp && selectedApp._id) {
      setPackId(selectedApp.packId._id);
      setPlanId(selectedApp.planId._id);
      setData({
        ...data,
        packId: selectedApp.packId._id,
        planId: selectedApp.planId._id,
        startDate: moment(selectedApp.startDate, "YYYY-MM-DD"),
        closureDate: moment(selectedApp.closureDate, "YYYY-MM-DD"),
      });
    }
  }, [selectedApp]);

  useEffect(() => {
    setReload(Math.floor(Math.random() * 100000));
    setSetting(instanceStoreRedux.currentInstance);
    setApp(selectedApp);
    setLoading(false);
  }, []);

  // selectedApp?.planId?.duration

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  return (
    <>
      {!loading && (
        <>
          <Row>
            <Col span={22} offset={1}>
              <Form
                form={form}
                name="manage-category-form-modal "
                className="manage-modal minimal-form"
                onFinish={onFinish}
                labelCol={{ span: 24 }}
                onValuesChange={onValuesChange}
                initialValues={defaultValues}
              >
                <Row gutter={16} className="box-info">
                  <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="packId2" tooltip="" label="Seleccione un programa">
                      <SelectPacks
                        reload={reload}
                        currentValue={packId}
                        defaultValue={packId}
                        returnData={handlePack}
                        typePack="SUBSCRIPTION"
                        setPackInfo={setPackInfo}
                        kind="subscription"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="planId" tooltip="" label="Seleccione un plan">
                      {packId && packId?.length > 5 && (
                        <SelectPlans
                          handleSelected={setPlan}
                          reload={reload}
                          currentValue={planId}
                          defaultValue={planId}
                          returnData={handlePlan}
                          type="SUBSCRIPTION"
                          packId={packId}
                          disabled
                        />
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item name="startDate" label={<>{icons.calendar} &nbsp; Desde</>}>
                      <DatePicker format="YYYY/MM/DD" onChange={handleStartdate} />
                    </Form.Item>
                  </Col>

                  <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item name="closureDate" label={<>{icons.calendar} &nbsp; Hasta</>}>
                      <DatePicker className="color-red" format="YYYY/MM/DD" disabled />
                    </Form.Item>
                  </Col>

                  {/*   <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item name="refererId" tooltip="" label={t("app_refererBy")}>
                      <SelectUsers
                        reload={reload}
                        currentValue={refererId}
                        defaultValue={refererId}
                        returnData={handleReferer}
                        typePack="SUBSCRIPTION"
                      />
                    </Form.Item>
                      </Col> */}

                  <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item name="description" label="Alguna observación importante">
                      <Input value={app.description} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} className="box-warning">
                  <Col span={24} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Checkbox checked={userGift} onChange={onChangeGift}>
                      Ha recibido el <b>Kit de bienvenida</b>
                    </Checkbox>
                  </Col>
                  <Col span={24} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Checkbox checked={userVaccine} onChange={onChangeVaccine}>
                      Sí,completó su esquema de vacunación.
                    </Checkbox>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24} style={{ paddingTop: 30 }}>
                    <hr />
                    <Space>
                      <Button
                        className="ant-btn secondary primary large"
                        loading={loadingSubmit}
                        key="1"
                        onClick={handleSubmit}
                        size="large"
                        style={{ float: "left" }}
                      >
                        Actualizar
                      </Button>
                      <Button
                        key="2"
                        onClick={onClose}
                        className="ant-btn secondary error large fill"
                        size="large"
                        style={{ float: "left" }}
                      >
                        Cerrar
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default withRouter(UpdateApp);
