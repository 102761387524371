import { useState, useEffect } from "react";
import { Form, Row, Col, Input, Checkbox, DatePicker, Space, Button, Select } from "antd";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { confirmAlert } from "react-confirm-alert";

import { withRouter } from "react-router-dom";
import { icons, durationFormat } from "../../../constants/globals";

import { saveAppByUser } from "../requests";
import { handleDetailsPlan } from "../../subscribersPlans/functions";

import SelectPacks from "../../subscribers/components/SelectPacks";
import SelectPlans from "../../subscribersPlans/components/SelectPlans";
import SelectUsers from "../../subscribersUsers/components/SelectUsers";

const moment = require("moment");
const Swal = require("sweetalert2");

const defaultValues = {
  firstname: "",
  lastname: "",
  document: "",
  documentType: "",
  paymentMode: "none",
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  paymentReference: "",
  phone: "+57",
  alergies: "",
  bodylesson: "",
  address: "",
  profession: "",
  medium: "",
  city: "",
  description: "",
  packId: null,
  planId: null,
};

const CreateApp = ({ selectedApp, onClose, refetch, planId_, userSelected = {} }) => {
  const { t } = useTranslation();
  const instanceStoreRedux = useSelector((store) => store.instance);

  const [setting, setSetting] = useState({});
  const [app, setApp] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [form] = Form.useForm();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [userId, setUserId] = useState(null);
  const [packId, setPackId] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [planExist, setPlanExist] = useState(false);

  const [plan, setPlan] = useState({});
  const [planinfo, setPlaninfo] = useState({});
  const [planinfo2, setPlaninfo2] = useState({});

  const [reload, setReload] = useState(0);

  const [reloadSeletedPlan, setReloadSeletedPlan] = useState(0);

  const [userExist, setUserExist] = useState(false);
  const [userCheckin, setUserCheckin] = useState(true);
  const [userVaccine, setUserVaccine] = useState(true);
  const [userGift, setUserGift] = useState(false);
  const [userPrivacy, setUserPrivacy] = useState(true);
  const [packInfo, setPackInfo] = useState({});
  const [reloadPlan, setReloadPlan] = useState(false);

  const onValuesChange = (values) => {
    setData({ ...data, ...values });
  };

  const onChangeUserExist = (e) => {
    setUserExist(e.target.checked);
    if (!e.target.checked) setUserId(null);
  };

  const onChangeVaccine = (e) => {
    setUserVaccine(e.target.checked);
  };

  const onChangePrivacy = (e) => {
    setUserPrivacy(e.target.checked);
  };

  const onFinish = () => {
    setData({ ...data, app: selectedApp, active: true, userExist, userCheckin, userVaccine, userGift, userPrivacy });

    let params = {};

    if (!userExist) {
      if (data.firstname === undefined || data.firstname === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Por favor validé los Nombres y Apellidos",
        });
        return;
      }
      if (data.email === undefined || data.email === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Por favor validé Email y Teléfono",
        });
        return;
      }
    }

    if (String(planId_).length > 12) {
      console.log();
    } else if (data.packId === undefined || data.planId === undefined) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor validé Paquete y Plan",
      });
      return;
    }

    if (!userVaccine) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor valide el esquema de vacunación!",
      });
      return;
    }

    if (String(planId_).length > 12 && planinfo._id) {
      params = {
        ...data,
        app: selectedApp,
        active: true,
        isUserExist: userExist,
        isUserCheckin: userCheckin,
        isVaccine: userVaccine,
        isWelcomeGift: userGift,
        isPrivacyPolicy: userPrivacy,
        startdate: planinfo.startdate,
        closuredate: planinfo.closuredate,
        kind: planinfo.kind,
        planId: planId_,
        plan: planinfo,
      };
    } else {
      params = {
        ...data,
        app: selectedApp,
        active: true,
        isUserExist: userExist,
        isUserCheckin: userCheckin,
        isVaccine: userVaccine,
        isWelcomeGift: userGift,
        isPrivacyPolicy: userPrivacy,
        startdate: planinfo2.startdate,
        closuredate: planinfo2.closuredate,
        kind: planinfo2.kind,
        planId: planinfo2._id,
      };
    }

    setLoadingSubmit(true);
    saveAppByUser(params, {
      onSuccess: () => {
        Swal.fire({
          icon: "success",
          title: "Operación Exitosa",
        });
        setData(defaultValues);
        setLoadingSubmit(false);
        refetch();
        onClose();
      },
      onError: () => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      },
      onFinally: () => {
        setLoading(false);
      },
    });
  };

  const handleSubmit = () => {
    confirmAlert({
      title: "Confirmación",
      message: `Esta seguro que desea proceder con el registro?`,
      buttons: [
        {
          label: "Si, Continuar!",
          onClick: () => form.submit(),
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const handlePack = (value) => {
    setPackId(value);
    setPlanId(null);
    setReloadSeletedPlan(Math.floor(Math.random() * 100000));
    setReloadPlan(true);
    setTimeout(() => setReloadPlan(false), 1000);
    setData({
      ...data,
      packId: value,
      startDate: moment(selectedApp?.startDate, "YYYY/MM/DD"),
      closureDate: null,
    });
  };

  const handlePlan = (value) => {
    setPlanId(value);

    setData({
      ...data,
      planId: value,
      startDate: null,
      closureDate: null,
    });
  };

  const handleUser = (value) => {
    setUserId(value);
    setData({
      ...data,
      userId: value,
      startDate: moment(selectedApp?.startDate, "YYYY/MM/DD"),
      closureDate: moment(plan?.startDate).add(plan.duration, durationFormat(plan.durationMode)),
    });
  };

  useEffect(() => {
    setPlan({});
    setData({
      ...data,
      planId: "null",
      packId,
    });
  }, [packId]);

  useEffect(() => {
    setPlaninfo2(plan);
    setData({
      ...data,
      plan,
      startDate: null,
      closureDate: null,
    });
  }, [plan]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  useEffect(() => {
    console.log(packInfo);
  }, [packInfo]);

  useEffect(() => {
    if (userSelected._id) {
      setUserExist(true);
      setUserId(userSelected._id);
      setData({
        ...data,
        userId: userSelected._id,
        appId: selectedApp._id,
        userMove: true,
      });
    }
  }, [userSelected]);

  useEffect(() => {
    setReload(Math.floor(Math.random() * 100000));
    setSetting(instanceStoreRedux.currentInstance);
    setApp(selectedApp);
    setLoading(false);
    setLoadingSubmit(false);
  }, []);

  useEffect(() => {
    if (String(planId_).length > 7) {
      handleDetailsPlan(
        { planId: planId_, t },
        {
          onSuccess: (result) => {
            console.log(result);
            if (result.ok) {
              setPlanId(planId_);
              setPlan(result.result);
              setPlanExist(true);
              setPlaninfo(result.result);
              setPackId(result.result.packId);
              setData({
                ...data,
                packId: result.result.packId,
                planId: planId_,
              });
            } else {
              setPlanId(null);
              setPlanExist(false);
            }
          },
        }
      );
    }
  }, [planId_]);
  // selectedApp?.planId?.duration

  return (
    <>
      {!loading && (
        <>
          <Row>
            <Col span={22} offset={1}>
              <Form
                form={form}
                name="manage-category-form-modal "
                className="manage-modal minimal-form"
                onFinish={onFinish}
                labelCol={{ span: 24 }}
                onValuesChange={onValuesChange}
                initialValues={defaultValues}
              >
                {!userSelected._id && (
                  <Row gutter={15}>
                    <Col span={24}>
                      <Checkbox className="box-info" onChange={onChangeUserExist}>
                        <b>{icons.guest} El contacto ya se encuentra registrado.</b>
                      </Checkbox>
                    </Col>
                  </Row>
                )}
                {!userExist && (
                  <Row gutter={15} className="box-primary">
                    <Col span={24} xs={24} sm={24} md={5} lg={5} xl={5}>
                      <Form.Item
                        name="firstname"
                        label={t("contact_firstname")}
                        rules={[{ required: true, message: "Es obligatorio el nombre" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={5} lg={5} xl={5}>
                      <Form.Item name="lastname" label={t("contact_lastname")}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={5} lg={5} xl={5}>
                      <Form.Item
                        name="email"
                        label={t("contact_email")}
                        rules={[{ required: true, message: "Es obligatorio el correo electronico!" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={5} lg={5} xl={5}>
                      <Form.Item name="documentType" label={t("contact_documentType")}>
                        <Select placeholder={t("contact_documentType")}>
                          <Select.Option value="Cédula de Ciudadanía">Cédula de Ciudadanía</Select.Option>
                          <Select.Option value="Tarjeta de Identidad">Tarjeta de Identidad</Select.Option>
                          <Select.Option value="Cédula de Extranjería">Cédula de Extranjería</Select.Option>
                          <Select.Option value="Pasaporte">Pasaporte</Select.Option>
                          <Select.Option value="Permiso Especial (PEP)">Permiso Especial (PEP)</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item name="document" label={t("contact_document")}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item name="phone" label={t("contact_phone")} extra="Ejemplo: +573187366637">
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item name="birthdate" label={t("contact_birthday")} extra="Ejemplo: 2000/12/25">
                        <DatePicker format="YYYY/MM/DD" />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item name="genre" label={<>{t("contact_genre")}</>}>
                        <Select className="select-form-modal">
                          <Select.Option value="Femenino">{t("genre_female")}</Select.Option>
                          <Select.Option value="Masculino">{t("genre_male")}</Select.Option>
                          <Select.Option value="No Especifica">{t("genre_none")}</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item name="address" label={t("contact_address")}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item help="Amistad, Red Social, Tv, Radio" name="medium" label="Como nos conoció?">
                        <Select className="select-form-modal">
                          <Select.Option value="Instagram">Instagram</Select.Option>
                          <Select.Option value="Facebook">Facebook</Select.Option>
                          <Select.Option value="Blog">Blog</Select.Option>
                          <Select.Option value="Amistad">Amistad</Select.Option>
                          <Select.Option value="Google">Google</Select.Option>
                          <Select.Option value="Youtube">Youtube</Select.Option>
                          <Select.Option value="Fachada">Fachada</Select.Option>
                          <Select.Option value="Folleto Publicitario">Folleto Publicitario</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                {userExist && !userSelected._id && (
                  <Row gutter={15} className="box-info">
                    <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item name="packId3" tooltip="" label="Seleccione el contacto">
                        <SelectUsers
                          reload={reloadSeletedPlan}
                          currentValue={userId}
                          defaultValue={userId}
                          returnData={handleUser}
                          typePack="SUBSCRIPTION"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {!planExist && (
                  <Row gutter={16} className="box-info">
                    <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item
                        name="packId2"
                        tooltip=""
                        label={
                          <>
                            {icons.icon_events}
                            &nbsp; Seleccione una actividad
                          </>
                        }
                      >
                        <SelectPacks
                          reload={reload}
                          currentValue={packId}
                          defaultValue={packId}
                          returnData={handlePack}
                          typePack="SUBSCRIPTION"
                          setPackInfo={setPackInfo}
                          kind="event"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item
                        name="planId"
                        tooltip=""
                        label={
                          <>
                            {icons.calendar}
                            &nbsp; Seleccione una fecha
                          </>
                        }
                        extra="** No muestra fechas del pasado!"
                      >
                        {!reloadPlan && String(packId).length > 10 && (
                          <SelectPlans
                            handleSelected={setPlan}
                            reload={reload}
                            currentValue={planId}
                            defaultValue={planId}
                            returnData={handlePlan}
                            type="SUBSCRIPTION"
                            packId={packId}
                            title="Seleccione"
                            isPast="false"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row gutter={16} className="box-info">
                  {plan?._id && !plan?.isFree && (
                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item
                        name="paymentMode"
                        label={
                          <>
                            {icons.dolar}&nbsp;{t("payment_method")}
                          </>
                        }
                      >
                        <Select className="select-form-modal">
                          <Select.Option value="none">{t("payment_none")}</Select.Option>
                          <Select.Option value="cash">{t("payment_cash")}</Select.Option>
                          <Select.Option value="debitcard">{t("payment_debitcard")}</Select.Option>
                          <Select.Option value="creditcard">{t("payment_creditcard")}</Select.Option>
                          <Select.Option value="transfer">{t("payment_transfer")}</Select.Option>
                          <Select.Option value="deposit">{t("payment_deposit")}</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  )}

                  {plan?._id && !plan?.isFree && (
                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item
                        name="paymentReference"
                        label="Número de referencia"
                        extra="** Este campo es opcional!"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <Row gutter={16} className="box-warning">
                  <Col span={24} xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Checkbox checked={userVaccine} onChange={onChangeVaccine}>
                      Sí,completó su esquema de vacunación.
                    </Checkbox>
                  </Col>
                  <Col span={24} xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Checkbox checked={userPrivacy} onChange={onChangePrivacy}>
                      Acepta los <b>Términos y Condiciones</b>
                    </Checkbox>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <hr />
                    <Space>
                      <Button
                        loading={loadingSubmit}
                        key="1"
                        onClick={handleSubmit}
                        className="ant-btn secondary primary large"
                        size="large"
                        style={{ float: "left" }}
                      >
                        Registrar
                      </Button>
                      <Button
                        key="2"
                        onClick={onClose}
                        className="ant-btn secondary error large"
                        size="large"
                        style={{ float: "left" }}
                      >
                        Cerrar
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <span className="code">9854675786</span>
        </>
      )}
    </>
  );
};
export default withRouter(CreateApp);
