import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { loadBranding, getApp } from "../features/login/request";
import { getUser } from "../features/subscribersUsers/requests";

import ActivateApp from "../features/subscribersApps/components/ActivateApp";
import RenovateApp from "../features/subscribersApps/components/RenovateApp";

import DuplicateApp from "../features/subscribersApps/components/DuplicateApp";
import CreateApp from "../features/subscribersApps/components/CreateApp";

import ResetPasswordUser from "../features/subscribersUsers/components/ResetPasswordUser";
import CheckinUser from "../features/subscribersUsers/components/CheckinUser";
import EventsContainer from "../features/events/containers/EventAnalist";

import "../styles/expressPage.css";

const moment = require("moment");

const AnalistExpress = ({ match }) => {
  const { t } = useTranslation();

  const [setting, setSetting] = useState({});
  const [app, setApp] = useState({});
  const [user, setUser] = useState({});

  const [planId, setPlanId] = useState(null);
  const [packId, setPackId] = useState(null);

  const [userId, setUserId] = useState(null);

  const [loading, setLoading] = useState(true);

  const [active, setActive] = useState(false);
  const [instanceId, setInstanceId] = useState("");

  useEffect(() => {
    loadBranding(match.params.alias, {
      onSuccess: (response) => {
        setSetting(response.result);
        setInstanceId(response.result._id);

        if (match.params.mode === "status") {
          getApp(
            { appId: match.params.id, instanceId: response.result._id },
            {
              onSuccess: (response2) => {
                if (response2.result.active) {
                  setActive(true);
                  setApp(response2.result);
                  setLoading(false);
                }
              },
              onError: () => {},
            }
          );
        }

        if (match.params.mode === "create2") {
          getUser(match.params.id, {
            onSuccess: (response2) => {
              if (response2.user.active) {
                setActive(true);
                setUser(response2.user);
                setLoading(false);
              }
            },
            onError: () => {},
          });
        }

        if (match.params.mode === "create") {
          setActive(true);
          setUser({});
          setLoading(false);
        }

        if (match.params.mode === "duplicate") {
          getApp(
            { appId: match.params.id, instanceId: response.result._id },
            {
              onSuccess: (response2) => {
                if (response2.result._id) {
                  setActive(true);
                  setApp(response2.result);
                  setLoading(false);
                }
              },
              onError: () => {},
            }
          );
        }

        if (match.params.mode === "renovate") {
          getApp(
            { appId: match.params.id, instanceId: response.result._id },
            {
              onSuccess: (response2) => {
                if (response2.result._id) {
                  setPlanId(response2.result.planId._id);
                  setPackId(response2.result.packId._id);
                  setActive(true);
                  setApp(response2.result);
                  setLoading(false);
                }
              },
              onError: () => {},
            }
          );
        }

        if (match.params.mode === "checkin") {
          getApp(
            { appId: match.params.id },
            {
              onSuccess: (response2) => {
                if (response2.result.active) {
                  setActive(true);
                  setApp(response2.result);
                  setLoading(false);
                }
              },
              onError: () => {},
            }
          );
        }

        if (match.params.mode === "onHoldCheckin") {
          setLoading(false);
          setActive(true);
        }

        if (match.params.mode === "resetPassword") {
          setLoading(false);
          setActive(true);
        }
      },
      onError: () => {},
    });
  }, [match.params.alias]);

  return (
    <section className="wrapper">
      {match.params.mode === "checkin" && active && !loading && <CheckinUser selectedApp={app} />}
      {match.params.mode === "status" && active && app._id && !loading && <ActivateApp selectedApp={app} />}
      {match.params.mode === "create" && active && !loading && (
        <CreateApp selectedApp={{ userId: user, startDate: moment.now(), closureDate: null }} />
      )}

      {match.params.mode === "duplicate" && !loading && <DuplicateApp selectedApp={app} />}
      {match.params.mode === "renovate" && !loading && (
        <RenovateApp packId_={packId} planId_={planId} selectedApp={app} />
      )}
      {match.params.mode === "resetPassword" && active && !loading && <ResetPasswordUser currentInstance={setting} />}
      {match.params.mode === "onHoldCheckin" && active && !loading && (
        <div style={{ margin: "auto", paddingTop: 40, textAlign: "center" }}>
          <a
            href="/logout"
            style={{
              fontSize: 18,
              border: "1px solid #5854EF",
              color: "#5854EF",
              padding: "4px 20px",
              borderRadius: 7,
            }}
          >
            <b>CERRAR SESIÓN</b>
          </a>
          <EventsContainer modeView="simple" userId={userId || null} tab={1} category="USER_CHECKIN" />
        </div>
      )}
    </section>
  );
};
export default withRouter(AnalistExpress);
