import { useState } from "react";
import { Table, Space, Tooltip, Button, Avatar } from "antd";
import { useSelector } from "react-redux";

import { CheckCircleTwoTone, DeleteOutlined, TagTwoTone } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import BaseTable from "../../shared/components/BasicTable";
import { constantsColor } from "../../../constants/colors";
import {
  dateFormat6,
  isRoleAdmin,
  icons,
  openLandingTourGuide,
  formatProgressApp,
  formatEndingApp,
  priceFormat,
} from "../../../constants/globals";

const AppsTable = ({
  isEmbed,
  selectedUser,
  setSelectedUser,
  setPage,
  history,
  apps,
  refetch,
  handleDetails,
  handleGoto,
  handleEdit,
  userId,
  handleArchived,
  handleActive,
  handleAddTrans,
  modeDetails,
  refreshPlan,
  handleResume,
  t,
  handleCheckActions,
  handleUploadPlanFile,
  isMinimal = false,
  handleClosePlanByGuide,
  handleInsitu,
  handleCheckActionsArchived,
  ...restProps
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState(false);

  const sessionStoreRedux = useSelector((store) => store.session);
  const instanceStoreRedux = useSelector((store) => store.instance);
  const planStoreRedux = useSelector((store) => store.plan);

  const { role } = sessionStoreRedux.currentSession;

  const onSelectChange = (selectedRowKeys1) => {
    setSelectedRowKeys(selectedRowKeys1);
  };

  const [loadingDetails, setLoadingDetails] = useState(null);
  const handleLoading = (data) => setLoadingDetails(data._id);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      {selectedRowKeys.length > 0 && isRoleAdmin(role) && (
        <div className="section-check-options">
          <Space>
            {!isMinimal && (
              <>
                <Button
                  size="large"
                  key="3"
                  className="ant-btn secondary error"
                  onClick={() => {
                    handleCheckActions(
                      {
                        archived_: true,
                        selectedRowKeys,
                        planId_: apps[0].planId._id,
                        active_: null, // para que las convierta en false
                      },
                      {
                        onSuccess: () => {
                          setSelectedRowKeys([]);
                        },
                      }
                    );
                  }}
                >
                  {icons.delete} {t("delete")}
                </Button>
              </>
            )}
          </Space>
        </div>
      )}

      <BaseTable {...restProps} rowSelection={rowSelection} dataSource={apps} refetch={refetch} scroll={{ y: 450 }}>
        <Table.Column
          dataIndex="_id"
          key="actions"
          align="center"
          width="100px"
          render={(_, data) => (
            <div>
              <Space size="small">
                {isRoleAdmin(role) && (
                  <Button
                    size="small"
                    className="btn-ant secondary primary"
                    onClick={() => {
                      handleLoading(data);
                      handleDetails(data);
                    }}
                  >
                    {loadingDetails === data._id ? <img alt="" src={icons.loading} width="15" /> : t("plan_openIt")}
                  </Button>
                )}
              </Space>
            </div>
          )}
        />

        <Table.Column
          align="center"
          style={{ fontSize: 14 }}
          title={t("common_quantity")}
          dataIndex="quantity"
          key="quantity"
          sorter
          width="70px"
          render={(_, row) => <h2>{row.quantity}</h2>}
          responsive={["lg"]}
        />

        <Table.Column
          title={t("products_listProducts")}
          dataIndex="_id"
          key="name"
          width="200px"
          render={(_, row) => (
            <span style={{ fontSize: 10, position: "relative", top: -3, left: 20 }}>{row?.packId?.name} </span>
          )}
        />

        <Table.Column
          title={t("amount")}
          dataIndex="amount"
          width="120px"
          key="amount"
          render={(_, row) => <>{priceFormat(row.amount)}</>}
        />

        <Table.Column
          title={t("contacts")}
          dataIndex="userId"
          width="200px"
          key="name"
          sorter
          render={(_, row) => (
            <>
              <span style={{ textTransform: "uppercase" }}>
                <b>
                  {_?.firstname} {_?.lastname}
                </b>
              </span>
            </>
          )}
        />

        <Table.Column
          title={t("createdAt")}
          dataIndex="createdAt"
          width="140px"
          key="createdAt"
          render={(_, row) => (
            <>
              {row.fecha} {row.hora}
            </>
          )}
        />

        <Table.Column
          title={t("actions")}
          dataIndex="actions"
          width="50px"
          key="actions"
          align="center"
          render={(_, data) => (
            <div className="">
              <Space size="small">
                {isRoleAdmin(role) && (
                  <a href="#!" onClick={() => handleArchived(data)} role="button" tabIndex={0}>
                    <Tooltip placement="top" title={`${t("clickTo")} ${t("delete")}`}>
                      <DeleteOutlined />
                    </Tooltip>{" "}
                  </a>
                )}
              </Space>
            </div>
          )}
        />
      </BaseTable>
    </>
  );
};

export default withRouter(AppsTable);
