import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import EventsTable from "../components/EventsTable";

// HELPERS
import showGlobalNotification from "../../../helpers/showGlobalNotification";

// REDUX
import { setBucketRedux } from "../../../redux/bucket";

import { handleArchivedEvent, handleDuplicateEvent } from "../functions";

// REQUEST
import { getEvent } from "../requests";

// HOOKS
import useEvents from "../hooks/useEvents";

// CONSTANTS
import { globals } from "../../../constants/globals";

const Events = ({
  isEmbed = false,
  packId = null,
  planId = null,
  userId = null,
  appId = null,
  transId = null,
  page_ = globals.PAGE_MAIN,
  category = null,
  type = null,
  filters_,
  reload,
  modeView,
  tab,
}) => {
  const { t } = useTranslation();
  const instanceStoreRedux = useSelector((store) => store.instance);

  const { events, filters, setFilters, pagination, setPagination, loading, refetch } = useEvents(
    packId,
    planId,
    userId,
    appId,
    transId,
    category,
    type
  );
  const [eventId, setEventId] = useState(null);

  const commonProps = { filters, setFilters };
  const [page, setPage] = useState(page_);
  const [selectedEvent, setSelectedEvent] = useState({});

  const [initialValues] = useState({
    mode: "Efectivo",
    fullname: "",
    email: "",
    phone: "",
    amount: 0,
    pending: 0,
    total: 0,
    appId: "",
  });

  const handleDetails = (event) => {
    getEvent(event._id, {
      onSuccess: (response) => {
        setBucketRedux(response.result);
        //  openDetailsBucketModal();
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
  };

  const handleArchived = (event) => {
    setEventId(event._id);
    handleArchivedEvent({ event, t, refetch });
  };

  const handleDuplicate = (event) => {
    setEventId(event._id);
    handleDuplicateEvent({ event, t, refetch });
  };

  useEffect(() => {
    setFilters({ ...filters, ...filters_ });
    // refetch();
  }, [tab]);

  return (
    <>
      {page === globals.PAGE_MAIN && (
        <div className={modeView === "simple" && "simple-checkin"}>
          {modeView === "simple" && (
            <img className="logo" width="220px" alt="logo" src={instanceStoreRedux.currentInstance.logo} />
          )}

          <EventsTable
            {...commonProps}
            events={events}
            pagination={pagination}
            setPagination={setPagination}
            loading={loading}
            refetch={refetch}
            initialValues={initialValues}
            setPage={setPage}
            setSelectedEvent={setSelectedEvent}
            selectedEvent={selectedEvent}
            handleDetails={handleDetails}
            details={handleDetails}
            modeView={modeView}
            handleArchived={handleArchived}
            handleDuplicate={handleDuplicate}
            t={t}
          />
        </div>
      )}
    </>
  );
};

export default Events;
