import { PageHeader, Button } from "antd";

const Header = ({ primaryAction, t }) => (
  <>
    <PageHeader
      avatar={{ src: "https://cluuftracks.herokuapp.com/bot.gif" }}
      className="site-page-header-responsive"
      title="Usuarios del Sistema"
      subTitle=""
      extra={[primaryAction && primaryAction]}
    />
  </>
);

export default Header;
