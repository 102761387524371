import { PageHeader } from "antd";

const Header = ({ t, primaryAction }) => (
  <>
    <PageHeader
      className="site-page-header-responsive"
      title={<h2 className="bold special-font big-font">{t("setting")}</h2>}
      subTitle="..."
      extra={[primaryAction && primaryAction]}
    />
  </>
);

export default Header;
