import { useState, useEffect } from "react";
import { Form, Row, Col, DatePicker, TimePicker, Space, Button, InputNumber, Input } from "antd";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { confirmAlert } from "react-confirm-alert";

import { withRouter } from "react-router-dom";
import { icons, durationFormat, priceFormat } from "../../../constants/globals";

import { newEventPlan } from "../../subscribersPlans/requests";
import SelectRoots from "../../subscribersRoots/components/SelectUsers";
import SelectPacks from "../../subscribers/components/SelectPacks";

const moment = require("moment");
const Swal = require("sweetalert2");

const defaultValues = {
  firstname: "",
  lastname: "",
  document: "",
  documentType: "",
  paymentMode: "none",
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  paymentReference: "",
  phone: "+57",
  alergies: "",
  bodylesson: "",
  address: "",
  profession: "",
  medium: "",
  city: "",
  description: "",
  packId: null,
  planId: null,
};

const CreateApp = ({ selectedApp, onClose, refetch, planId_ = null }) => {
  const { t } = useTranslation();
  const instanceStoreRedux = useSelector((store) => store.instance);

  const [setting, setSetting] = useState({});
  const [app, setApp] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [form] = Form.useForm();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [userId, setUserId] = useState(null);
  const [packId, setPackId] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [maxLimit, setMaxLimit] = useState(null);

  const [plan, setPlan] = useState({});
  const [reload, setReload] = useState(0);
  const [userExist, setUserExist] = useState(false);
  const [userCheckin, setUserCheckin] = useState(true);
  const [userVaccine, setUserVaccine] = useState(true);
  const [userGift, setUserGift] = useState(false);
  const [userPrivacy, setUserPrivacy] = useState(true);
  const [packInfo, setPackInfo] = useState({});
  const [guideId, setGuideId] = useState(null);

  const onValuesChange = (values) => {
    if (values.maxLimit) {
      setMaxLimit(values.maxLimit);
    }
    setData({ ...data, ...values });
  };

  const onFinish = () => {
    setData({ ...data, app: selectedApp, active: true, maxLimit });

    if (data.packId === undefined) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor validé Paquete y Plan",
      });
      return;
    }

    setLoadingSubmit(true);
    newEventPlan(
      {
        ...data,
        app: selectedApp,
        active: true,
        isUserExist: userExist,
        isUserCheckin: userCheckin,
        isVaccine: userVaccine,
        isWelcomeGift: userGift,
        isPrivacyPolicy: userPrivacy,
        startdate: moment(data.startDate).format("YYYY-MM-DD"),
        closuredate: moment(data.closureDate).format("YYYY-MM-DD"),
        startDate: moment(data.startDate).format("YYYY-MM-DD"),
        date: moment(data.startDate).format("YYYY-MM-DD"),
        time: moment(data.departureTime).format("HH:mm"),
        closureDate: moment(data.closureDate).format("YYYY-MM-DD"),
        departureTime: moment(data.departureTime).format("HH:mm"),
        kind: packInfo.kind,
        maxLimit: data.maxLimit,
        guideId,
      },
      {
        onSuccess: () => {
          Swal.fire({
            icon: "success",
            title: "Operación Exitosa",
          });

          setData(defaultValues);
          refetch();
          onClose();
        },
        onError: () => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        },
        onFinally: () => {
          setLoading(false);
        },
      }
    );
  };

  const handleSubmit = async () => {
    confirmAlert({
      title: "Confirmación",
      message: `Esta seguro que desea continuar?`,
      buttons: [
        {
          label: "Continuar",
          onClick: () => form.submit(),
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const handlePack = (value) => {
    setPackId(value);
    setPlanId(null);
    setData({
      ...data,
      packId: value,
      startDate: moment(selectedApp?.startDate, "YYYY/MM/DD"),
      closureDate: null,
      price: packInfo?.isFree ? "Sin Costo" : priceFormat(packInfo?.price),
    });
  };

  const handleStartdate = (values) => {
    setData({
      ...data,
      closureDate: moment(values).add(packInfo.duration, durationFormat(packInfo.durationMode)),
      startDate: values,
      startdate: moment(values).format("YYYY-MM-DD"),
      closuredate: moment(values).add(packInfo.duration, durationFormat(packInfo.durationMode)).format("YYYY-MM-DD"),
    });
  };

  const handleGuide = (value) => {
    setGuideId(value);
    setData({
      ...data,
      guideId: value,
    });
  };

  useEffect(() => {
    setPlan({});
    setData({
      ...data,
      packId,
    });
  }, [packId]);

  useEffect(() => {
    setData({
      ...data,
      plan,
      startDate: null,
      closureDate: null,
    });
  }, [plan]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  useEffect(() => {
    setMaxLimit(parseInt(packInfo?.maxLimit));
    setData({
      ...data,
      price: packInfo?.price,
      maxLimit: packInfo?.maxLimit,
    });
  }, [packInfo]);

  useEffect(() => {
    setReload(Math.floor(Math.random() * 100000));
    setSetting(instanceStoreRedux.currentInstance);
    setApp(selectedApp);
    setLoading(false);
  }, []);

  return (
    <>
      {!loading && (
        <>
          <Row>
            <Col span={22} offset={1}>
              <Form
                form={form}
                name="manage-category-form-modal "
                className="manage-modal minimal-form"
                onFinish={onFinish}
                labelCol={{ span: 24 }}
                onValuesChange={onValuesChange}
                initialValues={defaultValues}
              >
                <Row gutter={16} className="box-info">
                  <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item name="packId2" tooltip="" label="Seleccione un actividad">
                      <SelectPacks
                        reload={reload}
                        currentValue={packId}
                        defaultValue={packId}
                        returnData={handlePack}
                        typePack="SUBSCRIPTION"
                        setPackInfo={setPackInfo}
                        kind="event"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {packId && (
                  <Row gutter={16} className="box-info">
                    <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item name="startDate" label={<>{icons.calendar} &nbsp; Fecha de inicio:</>}>
                        <DatePicker format="YYYY/MM/DD" onChange={handleStartdate} />
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item name="departureTime" label={<>{icons.clock} &nbsp; Hora de inicio:</>}>
                        <TimePicker format="HH:mm" minuteStep={15} secondStep={60} />
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item name="maxLimit" label="Maximo de contactos">
                        <InputNumber min={1} max={100} defaultValue={12} value={packInfo?.maxLimit} />
                      </Form.Item>
                    </Col>

                    {packInfo._id && (
                      <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                        <Form.Item name="price" label="Precio por persona" extra="Pesos colombianos">
                          <InputNumber
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                )}

                {packInfo._id && packInfo.guideId && (
                  <Row gutter={15} style={{ paddingBottom: 10 }}>
                    <Col span={24} xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item name="packId3" tooltip="" label={<>Seleccione un Guia</>}>
                        <SelectRoots
                          reload={reload}
                          currentValue={packInfo.guideId._id || packInfo.guideId}
                          defaultValue={packInfo.guideId._id || packInfo.guideId}
                          returnData={handleGuide}
                          typePack="SUBSCRIPTION"
                          title="Seleccione"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row gutter={16}>
                  <Col span={24} style={{ paddingTop: 50 }}>
                    <hr />
                    <Space>
                      <Button
                        loading={loadingSubmit}
                        key="1"
                        onClick={handleSubmit}
                        className="ant-btn secondary primary large"
                        size="large"
                        style={{ float: "left" }}
                      >
                        Registrar
                      </Button>
                      <Button
                        key="2"
                        onClick={onClose}
                        className="ant-btn secondary error large"
                        size="large"
                        style={{ float: "left" }}
                      >
                        Cerrar
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default withRouter(CreateApp);
