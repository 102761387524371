import { setLocalStorage } from "../utils/localStorage";

const dataInicial = {
  request: false,
  reload: Math.floor(Math.random() * 100000000),
  currentExpense: {},
};

const CURRENT_EXPENSE = "CURRENT_EXPENSE";

const RELOAD = "RELOAD";

export default function expenseReducer(state = dataInicial, action) {
  switch (action.type) {
    case CURRENT_EXPENSE:
      return { ...state, currentExpense: action.payload };

    case RELOAD:
      return { ...state, reload: Math.floor(Math.random() * 100000000) };

    default:
      return { ...state };
  }
}

export const setExpense = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: CURRENT_EXPENSE,
    payload,
  });
};

export const setExpenseRedux = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: CURRENT_EXPENSE,
    payload,
  });
};

export const reloadRedux = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: RELOAD,
    payload,
  });
};
