import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";

import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";

import { Form, Row, Col, Input, Spin, Button, Image, Switch, Collapse, Select, InputNumber } from "antd";
import { savePack } from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";
import RichTextComponent from "../../contentsPlansv2/components/RichTextComponent";

const { Panel } = Collapse;

const defaultValues = {
  name: "",
  email: "",
  phone: "",
  facebook: "",
  instagram: "",
  youtube: "",
  active: true,
  color: "",
  cover: "",
  background: "",
  imageEmail: "",
  description: "",
  video: "",
  owner: "",
  ownerPhone: "",
  ownerEmail: "",
  redirectTo: "",
  whatsappRedirect: "",
  urlRedirect: "",
  isDefaultColor: true,
};
const { TextArea } = Input;

const PackWebsiteForm = ({ packData = {}, handleUploadGallery, refetch }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const sessionStoreRedux = useSelector((store) => store.session);

  const [selectedPack, setSelectedPack] = useState({ description: "hola mundo", ...packData });
  const [form] = Form.useForm();
  const [loaderForm, setLoaderForm] = useState(false);
  const [redirectTo, setRedirectTo] = useState(packData.redirectTo || "none");

  useEffect(() => {
    form.setFieldsValue(selectedPack);
  }, [form, selectedPack]);

  const onFinish = (values) => {
    const data = { ...selectedPack, ...values };
    console.log(data);
    savePack(
      {
        ...data,
        packId: packData._id,
        rootId: sessionStoreRedux.currentSession_id,
      },
      {
        onSuccess: (response) => {
          refetch();
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const getHTML = debounce((values) => {
    setSelectedPack({ ...selectedPack, description: values });
  }, 500);

  const handleFinishConfirm = (values) => {
    confirmAlert({
      title: t("update"),
      message: `Esta seguro que desea continuar ?`,
      buttons: [
        {
          label: t("yes"),
          onClick: () => onFinish(values),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const convertCSVToArray = (value) => {
    if (value?.include) {
      setSelectedPack({ ...selectedPack, include: value.include.split(",") });
    }
    if (value?.exclude) {
      setSelectedPack({ ...selectedPack, exclude: value.exclude.split(",") });
    }
    if (value?.itineraries) {
      setSelectedPack({ ...selectedPack, itineraries: value.itineraries.split(",") });
    }
    if (value?.departureTime) {
      setSelectedPack({ ...selectedPack, departureTime: value.departureTime.split(",") });
    }

    if (value?.recomendations) {
      setSelectedPack({ ...selectedPack, recomendations: value.recomendations.split(",") });
    }
  };

  const onValuesChange = (values) => {
    if (values.redirectTo) setRedirectTo(values.redirectTo);

    if (values?.include || values?.exclude || values?.itineraries || values?.departureTime || values?.recomendations) {
      convertCSVToArray(values);
    } else {
      setSelectedPack({ ...selectedPack, ...values });
    }
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  const handleDefaultColor = (checked) => {
    setSelectedPack({ ...selectedPack, isDefaultColor: checked });
  };

  return (
    <Form
      form={form}
      name="manage-category-form-modal"
      initialValues={defaultValues}
      className="manage-modal"
      onFinish={handleFinishConfirm}
      labelCol={{ span: 24 }}
      onValuesChange={onValuesChange}
    >
      <div className="layout-form-modal entity-small" style={{ height: 800 }}>
        {loaderForm ? (
          <>
            <Spin tip={t("loading")} />
          </>
        ) : (
          <>
            <Collapse accordion>
              <Panel header={<h2 className="special-font semibold color-primary">Información General</h2>} key="1">
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={8} lg={16} xl={16}>
                    <Form.Item name="title" label={t("pack_title")}>
                      <Input value={selectedPack?.title} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                    <Form.Item name="category" label="Categoria">
                      <Input value={selectedPack?.category} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                    <Form.Item name="externalPrice" label="Precio al publico">
                      <InputNumber
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        value={selectedPack?.externalPrice}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item name="excerpt" label={t("excerpt")}>
                      <TextArea style={{ minHeight: 70 }} placeholder="" value={selectedPack?.excerpt} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Button
                      type="ant-btn secondary primary large"
                      style={{ float: "right" }}
                      size="large"
                      onClick={handleSubmit}
                    >
                      <i className="icofont-email" /> {t("save")}
                    </Button>
                  </Col>
                </Row>
              </Panel>

              <Panel header={<h2 className="special-font semibold color-primary">Redirección</h2>} key="2">
                <Row gutter={24}>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Item name="redirectTo" label="Redireccionar a un:">
                      <Select className="select-form-modal">
                        <Select.Option value="none">Sin redirección</Select.Option>
                        <Select.Option value="whatsappRedirect">Whatsapp</Select.Option>
                        <Select.Option value="urlRedirect">Página web</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  {redirectTo === "whatsappRedirect" && (
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        name="whatsappRedirect"
                        label="Whatsapp"
                        extra="Ingrese el numero whatsapp con codigo de area. ejemplo +573176777787"
                      >
                        <Input value={selectedPack?.whatsappRedirect} />
                      </Form.Item>
                    </Col>
                  )}

                  {redirectTo === "urlRedirect" && (
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        name="urlRedirect"
                        label="Dirección Web"
                        extra="Ingrese la url donde quiere redirigir el formulario.  Ejemplo: https://midestino.com"
                      >
                        <Input value={selectedPack?.urlRedirect} />
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={24}>
                    <Button
                      type="ant-btn secondary primary large"
                      style={{ float: "right" }}
                      size="large"
                      onClick={handleSubmit}
                    >
                      <i className="icofont-email" /> {t("save")}
                    </Button>
                  </Col>
                </Row>
              </Panel>

              <Panel header={<h2 className="special-font semibold color-primary">Responsable del Tour</h2>} key="4">
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="owner" label="Nombres y Apellidos">
                      <Input value={selectedPack?.owner} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="ownerEmail" label="Correo Electronico">
                      <Input value={selectedPack?.ownerEmail} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="ownerPhone" label="Teléfono de contacto">
                      <Input value={selectedPack?.ownerPhone} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Button
                      type="ant-btn secondary primary large"
                      style={{ float: "right" }}
                      size="large"
                      onClick={handleSubmit}
                    >
                      <i className="icofont-email" /> {t("save")}
                    </Button>
                  </Col>
                </Row>
              </Panel>

              <Panel
                header={<h2 className="special-font semibold color-primary">Descripción amplia del servicio</h2>}
                key="5"
              >
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item name="description">
                      <RichTextComponent setHTML={selectedPack?.description} getHTML={getHTML} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Button
                      type="ant-btn secondary primary large"
                      style={{ float: "right" }}
                      size="large"
                      onClick={handleSubmit}
                    >
                      <i className="icofont-email" /> {t("save")}
                    </Button>
                  </Col>
                </Row>
              </Panel>

              <Panel
                header={
                  <h2 className="special-font semibold color-primary">
                    Horario de salida, punto de encuentro, itinerario y ubicación del evento
                  </h2>
                }
                key="8"
              >
                <Row gutter={24} className="box-info">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="departureTime"
                      label="Horas de salidas (Separadas por comas)"
                      extra="Ejemplo: 20:00,5:30,01:15"
                    >
                      <Input value={selectedPack?.departureTime} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="itineraries" label={t("pack_itineraries")}>
                      <Input value={selectedPack?.itineraries} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="iframeMap"
                      tooltip="Ingresar el codigo <Iframe> proporcionado por google map"
                      label="Ubicación del evento."
                      extra="Insertar el codigo HTML de Google map"
                    >
                      <TextArea style={{ minHeight: 70 }} placeholder="" value={selectedPack?.iframeMap} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="meetingPoint"
                      tooltip="Ingresar el codigo HTML <Iframe> proporcionado por google map"
                      label="Punto de encuentro"
                      extra="Insertar el codigo HTML de Google map"
                    >
                      <TextArea style={{ minHeight: 70 }} placeholder="" value={selectedPack?.meetingPoint} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Button
                      type="ant-btn secondary primary large"
                      style={{ float: "right" }}
                      size="large"
                      onClick={handleSubmit}
                    >
                      <i className="icofont-email" /> {t("save")}
                    </Button>
                  </Col>
                </Row>
              </Panel>

              <Panel
                header={
                  <h2 className="special-font semibold color-primary">
                    Recomendaciones, opciones que SI incluye y opciones que NO incluye
                  </h2>
                }
                key="3"
              >
                <Row gutter={24} className="box-info">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item name="recomendations" label={t("pack_recomendations")}>
                      <Input value={selectedPack?.recomendations} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item name="include" label={t("pack_include")}>
                      <Input value={selectedPack?.include} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item name="exclude" label={t("pack_exclude")}>
                      <Input value={selectedPack?.exclude} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Button
                      type="ant-btn secondary primary large"
                      style={{ float: "right" }}
                      size="large"
                      onClick={handleSubmit}
                    >
                      <i className="icofont-email" /> {t("save")}
                    </Button>
                  </Col>
                </Row>
              </Panel>

              <Panel header={<h2 className="special-font semibold color-primary">Colores del formulario</h2>} key="8">
                <Row gutter={24} className="box-info">
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name="isDefaultColor"
                      labelAlign="right"
                      label={t("pack_isDefaultColor")}
                      tooltip="Solo usar los colores predefinidos"
                    >
                      <Switch checked={!!selectedPack?.isDefaultColor} onChange={handleDefaultColor} />
                    </Form.Item>
                  </Col>

                  {!selectedPack.isDefaultColor && (
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item name="backgroundForm" label={t("pack_colorForm")}>
                        <Input type="color" value={selectedPack?.backgroundForm} />
                      </Form.Item>
                    </Col>
                  )}
                  {!selectedPack.isDefaultColor && (
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item name="colorBtnForm" label={t("pack_colorBtnForm")}>
                        <Input type="color" value={selectedPack?.colorBtnForm} />
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={24}>
                    <Button
                      type="ant-btn secondary primary large"
                      style={{ float: "right" }}
                      size="large"
                      onClick={handleSubmit}
                    >
                      <i className="icofont-email" /> {t("save")}
                    </Button>
                  </Col>
                </Row>
              </Panel>

              <Panel
                header={<h2 className="special-font semibold color-primary">Configuración del formulario</h2>}
                key="5"
              >
                <Row gutter={24} className="box-info">
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      extra="Por ejemplo: Submit, Ingresar, Unete a nosotros"
                      name="labelBtnForm"
                      label="Titulo del boton de submit"
                    >
                      <Input value={selectedPack?.labelBtnForm} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="video" label="URL del Video">
                      <Input value={selectedPack?.video} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      extra="Por ejemplo: Seleccione un plan, Seleccione el tour, Seleccione un horario"
                      name="titleSelectPlan"
                      label="Titulo del dropdown de seleccion del plan"
                    >
                      <Input value={selectedPack?.titleSelectPlan} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="privacyPolicy" label={t("pack_privacyPolicyUrl")}>
                      <Input value={selectedPack?.privacyPolicy} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Button
                      type="ant-btn secondary primary large"
                      style={{ float: "right" }}
                      size="large"
                      onClick={handleSubmit}
                    >
                      <i className="icofont-email" /> {t("save")}
                    </Button>
                  </Col>
                </Row>
              </Panel>

              <Panel header={<h2 className="special-font semibold color-primary">Galería de imagenes</h2>} key="9">
                <Row gutter={24}>
                  <Col span={12}>
                    <div className="image-pack-container">
                      <Button
                        style={{ float: "left" }}
                        className="ant-btn secondary primary"
                        onClick={() => handleUploadGallery("avatar")}
                      >
                        Imagen principal
                      </Button>
                      <br /> <br />
                      <hr />
                      <Image
                        width={400}
                        src={
                          packData.avatar || "https://laboratoriodesuenos.com/wp-content/uploads/2020/02/default.jpg"
                        }
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="image-pack-container">
                      <Button
                        style={{ float: "left" }}
                        className="ant-btn secondary primary"
                        onClick={() => handleUploadGallery(1)}
                      >
                        Cargar Imagen #1
                      </Button>{" "}
                      <br /> <br />
                      <hr />
                      <Image
                        width={400}
                        src={
                          packData.gallery1 || "https://laboratoriodesuenos.com/wp-content/uploads/2020/02/default.jpg"
                        }
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="image-pack-container">
                      <Button
                        style={{ float: "left" }}
                        className="ant-btn secondary primary"
                        onClick={() => handleUploadGallery(2)}
                      >
                        Cargar Imagen #2
                      </Button>{" "}
                      <br /> <br />
                      <hr />
                      <Image
                        width={400}
                        src={
                          packData.gallery2 || "https://laboratoriodesuenos.com/wp-content/uploads/2020/02/default.jpg"
                        }
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="image-pack-container">
                      <Button
                        style={{ float: "left" }}
                        className="ant-btn secondary primary"
                        onClick={() => handleUploadGallery(3)}
                      >
                        Cargar Imagen #3
                      </Button>{" "}
                      <br /> <br />
                      <hr />
                      <Image
                        width={400}
                        src={
                          packData.gallery3 || "https://laboratoriodesuenos.com/wp-content/uploads/2020/02/default.jpg"
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </>
        )}
      </div>
    </Form>
  );
};

PackWebsiteForm.defaultProps = {
  setInstanceId: () => {},
};

export default PackWebsiteForm;
