import { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Tooltip } from "antd";

import moment from "moment";
import { messages } from "../../../helpers/calendar-messages-es";

import "react-big-calendar/lib/css/react-big-calendar.css";

import "moment/locale/es";

const localizer = momentLocalizer(moment);
moment.locale("es");

const CalendarEvent = ({ event }) => {
  const { images, pack, apps } = event;
  return (
    <Tooltip title={`${images} Fotografías`}>
      <div>
        <b>{apps}</b> x {pack}
      </div>
    </Tooltip>
  );
};

const MyCalendar = ({ setCalendar, eventos, setSelectedPlan }) => {
  const [lastView, setLastView] = useState(localStorage.getItem("lastView") || "month");

  const [eventsList, setEventsList] = useState([]);

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.color || "#4FB601",
      opacity: 0.8,
      color: "#FFFFFF",
      display: "block",
    };

    return {
      style,
    };
  };

  useEffect(() => {
    if (eventos.length > 0) {
      setEventsList(
        eventos.map((item) => ({
          _id: item._id,
          name: item.name,
          minLimit: 1,
          maxLimit: 10,
          start: moment(`${item.departureDate} ${item.departureTime}`, "YYYY-MM-DD HH:mm").toDate(),
          end: moment(`${item.departureDate} ${item.departureTime}`, "YYYY-MM-DD HH:mm").add(1, "hours").toDate(),
          color: item.packId.color,
          pack: item.packId.name,
          apps: item.totalApps,
          hora: item.departureTime,
          images: item.images || 0,
        }))
      );
    }
  }, [eventos]);

  const onDoubleClickEvent = (e) => {
    setSelectedPlan(e);
  };

  const onSelectEvent = (e) => {
    console.log(e);
    setCalendar(e);
  };

  const onSelectSlot = (e) => {
    setCalendar(e);
  };

  const onViewChange = (e) => {
    localStorage.setItem("lastView", e);
    setLastView(e);
  };

  return (
    <div className="calendar-screen">
      <Calendar
        localizer={localizer}
        events={eventsList}
        startAccessor="start"
        endAccessor="end"
        messages={messages}
        eventPropGetter={eventStyleGetter}
        components={{ event: CalendarEvent }}
        onDoubleClickEvent={onDoubleClickEvent}
        onSelectEvent={onSelectEvent}
        onView={onViewChange}
        view={lastView}
        selectable="true"
        onSelectSlot={onSelectSlot}
      />
    </div>
  );
};

export default MyCalendar;
