import { useState } from "react";

import { Table, Space, Tooltip, Button, Typography, Tag } from "antd";
import { useSelector } from "react-redux";

import { TagTwoTone } from "@ant-design/icons";

import { withRouter } from "react-router-dom";

import BaseTable from "../../shared/components/BasicTable";

import {
  moneyFormat,
  isRoleAdmin,
  icons,
  formatSuccess,
  isRoleAnalist,
  tagAppStatus,
  colorsSystem,
} from "../../../constants/globals";

import ActionsTable from "./ActionsTable";

const { Link } = Typography;

const moment = require("moment");

const PlansTable = ({
  selectedUser,
  setSelectedUser,
  setPage,
  history,
  plans,
  refetch,
  initialValues,
  handleDetails,
  ctaHandleDetails,
  handleEdit,
  userId,
  handleArchivedPlan,
  handleAddImage,
  t,
  handleActivePlan,
  packData,
  isEmbed,
  handleResume,
  root,
  handleConsolidate,
  handleClosePlanByGuide,
  isMinimal = false,
  ...restProps
}) => {
  const sessionStoreRedux = useSelector((store) => store.session);
  const instanceStoreRedux = useSelector((store) => store.instance);
  const { role } = sessionStoreRedux.currentSession;

  const [loadingDetails, setLoadingDetails] = useState(null);
  const handleLoading = (data) => setLoadingDetails(data._id);

  return (
    <>
      {!isMinimal && (
        <BaseTable {...restProps} dataSource={plans} refetch={refetch} scroll={{ y: 450 }}>
          <Table.Column
            align="center"
            title={t("plan_openIt")}
            dataIndex="actions"
            key="actions"
            width="70px"
            render={(_, data) => (
              <div>
                <Space size="small">
                  {isRoleAdmin(role) && (
                    <Button
                      size="small"
                      className="btn-ant secondary primary"
                      onClick={() => {
                        handleLoading(data);
                        handleDetails(data);
                      }}
                    >
                      {loadingDetails === data._id ? <img alt="" src={icons.loading} width="15" /> : t("plan_openIt")}
                    </Button>
                  )}
                  {isRoleAnalist(role) && (
                    <Tooltip title="Muestra un resumen de esa aplicación">
                      <Button size="small" className="ant-btn secondary" onClick={() => handleResume(data)}>
                        {t("plan_openIt")}
                      </Button>
                    </Tooltip>
                  )}
                </Space>
              </div>
            )}
          />

          <Table.Column
            align="center"
            style={{ fontSize: 14 }}
            title={t("apps")}
            dataIndex="progress"
            key="progress"
            sorter
            width="70px"
            render={(_, row) => <h2>{formatSuccess(row.progress + row.starting + row.closed)}</h2>}
            responsive={["lg"]}
          />

          <Table.Column
            title={t("service")}
            dataIndex="_id"
            key="name"
            render={(_, data) => (
              <h3 style={{ textTransform: "uppercase" }}>
                <small>{data.packId.name}</small>
                <br />
                {data.name}
                {data?.pending > 0 && (
                  <Tag color="volcano">
                    <small>
                      <span style={{ fontSize: 16 }}>{data?.pending}</span> apps pendientes{" "}
                    </small>
                  </Tag>
                )}
              </h3>
            )}
          />

          <Table.Column
            width="150px"
            title={t("actions")}
            dataIndex="active"
            key="active"
            responsive={["lg"]}
            align="center"
            render={(_, data) => (
              <div>
                {data?.packId && (
                  <ActionsTable
                    t={t}
                    row={data}
                    root={root}
                    refetch={refetch}
                    instanceStoreRedux={instanceStoreRedux}
                    handleArchivedPlan={handleArchivedPlan}
                  />
                )}
              </div>
            )}
          />
        </BaseTable>
      )}

      {isMinimal && (
        <>
          <hr />
          <Space>
            <Link
              className="ant-btn"
              style={{ background: colorsSystem.red, color: colorsSystem.white }}
              href="./home/31"
            >
              {icons.back} Vista Admin
            </Link>
          </Space>
          <hr />
          <BaseTable {...restProps} dataSource={plans} refetch={refetch} scroll={{ x: 600 }}>
            <Table.Column
              title={t("plan")}
              dataIndex="_id"
              key="name"
              width="350px"
              render={(_, data) => (
                <>
                  <h2>{data.totalApps} Tickets</h2>
                  <h3>
                    <TagTwoTone twoToneColor={data.color} /> {"  "} {data.name} <br />
                    <small>Paquete:</small> {data.packId.name} <br />
                    <small>Costo: </small> {moneyFormat(data.price)}
                  </h3>

                  <Button className="ant-btn secondary success" size="medium" onClick={() => handleDetails(data)}>
                    Ver más Detalles
                  </Button>
                </>
              )}
            />
          </BaseTable>
        </>
      )}
    </>
  );
};

export default withRouter(PlansTable);
