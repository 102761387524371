import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Row, Col, Input, InputNumber, Spin } from "antd";
import { saveService, getService } from "../requests";
import { globals, icons } from "../../../constants/globals";

import showGlobalNotification from "../../../helpers/showGlobalNotification";
import BaseModal from "../../shared/components/Modal/BaseModal";
import { setServiceRedux, reloadRedux as reloadServiceRedux } from "../../../redux/service";
import SelectSupplier from "../../subscribersSuppliers/components/SelectSupplier";

const defaultValues = {
  description: "",
  category: "",
  active: true,
  public: false,
  price: 0,
  supplierId: null,
};

const ManageServiceModal = ({
  supplierId,
  openModal,
  closeModal,
  refetch,
  t,
  setServiceId,
  page,
  setReload_ = null,
}) => {
  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);
  const [form] = Form.useForm();
  const [selectedService, setSelectedService] = useState(defaultValues);
  const [loaderForm, setLoaderForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [reload, setReload] = useState(0);

  const handleReset = () => {
    setSelectedService(defaultValues);
    setReload(Math.floor(Math.random() * 100000));
  };

  useEffect(() => {
    if (openModal) {
      if (supplierId) {
        setMode("edit");
      } else {
        setMode("new");
      }
    } else {
      handleReset();
      setMode(null);
      if (page !== globals.PAGE_ONE) setServiceId(null);
    }
  }, [openModal]);

  useEffect(() => {
    if (mode === "new") {
      handleReset();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      setLoaderForm(true);
      getService(supplierId, {
        onSuccess: (response) => {
          setServiceRedux(response.result)(dispatch);
          setSelectedService({
            ...response.result,
          });
          setLoaderForm(false);
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  }, [mode]);

  useEffect(() => {
    form.setFieldsValue(selectedService);
  }, [form, selectedService]);

  const handleCloseModal = () => {
    refetch();
    closeModal();
  };

  const onFinish = (values) => {
    const data = { ...selectedService, ...values };

    saveService(
      {
        ...data,
        rootId: sessionStoreRedux.currentSession_id,
      },
      {
        onSuccess: (response) => {
          reloadServiceRedux()(dispatch);
          setServiceRedux(response.usuario)(dispatch);
          if (setReload_) setReload_(Math.floor(Math.random() * 100000));
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => handleCloseModal(),
      }
    );
  };

  const callback = (key) => {
    console.log(key);
  };

  const onValuesChange = (values) => {
    setSelectedService({ ...selectedService, ...values });
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  const handleSupplier = (value) => {
    console.log({ ...selectedService, supplierId: value });
    setSelectedService({ ...selectedService, supplierId: value });
  };

  return (
    <BaseModal
      isOpenModal={openModal}
      closeModal={handleCloseModal}
      onCancel={handleCloseModal}
      onConfirm={() => handleSubmit()}
      title={supplierId ? `${t("edit")} ${t("service")}` : `${t("new")} ${t("service")}`}
      width={1000}
      confirmText={t("save")}
      isCloseModal
      top={10}
      icon={icons.edit}
    >
      <Form
        form={form}
        name="manage-category-form-modal"
        initialValues={defaultValues}
        className="manage-modal"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        onValuesChange={onValuesChange}
      >
        <div className="layout-form-modal entity-450 entity-small">
          {loaderForm ? (
            <>
              <Spin tip={t("loading")} />
            </>
          ) : (
            <>
              <Row gutter={16}>
                <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="packId2" tooltip="" label="Seleccione un proveedor">
                    <SelectSupplier
                      reload={reload}
                      currentValue={supplierId}
                      defaultValue={supplierId}
                      returnData={handleSupplier}
                      typePack="SUBSCRIPTION"
                      kind="subscription"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="description"
                    tooltip="description"
                    label="Descripción"
                    rules={[
                      {
                        required: true,
                        message: `Por favor ingrese description`,
                      },
                    ]}
                  >
                    <Input placeholder="" value={selectedService.fullname} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                  <Form.Item label="Costo" name="price">
                    <InputNumber
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
              </Row>{" "}
              <Row gutter={16}>
                <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="observation" label={t("observation")}>
                    <Input value={selectedService.observation} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Form>
    </BaseModal>
  );
};

ManageServiceModal.defaultProps = {
  setServiceId: () => {},
};

export default ManageServiceModal;
