import { Descriptions, Tooltip, Tag, Spin, Divider } from "antd";

import { useSelector } from "react-redux";

import { globals, dateFormat4, priceFormat, icons, urlLandingPage } from "../../../constants/globals";

const AppMoreDetails = ({ selectedApp, t }) => {
  const sessionStoreRedux = useSelector((store) => store.session);
  const instanceStoreRedux = useSelector((store) => store.instance);

  const renderContent = (column = 3) => (
    <div className=" margin-bottom-10">
      <Divider orientation="left">
        Aplicación: {icons.number} {selectedApp?.correlative}
      </Divider>

      <Descriptions size="small" column={4}>
        <Descriptions.Item label={t("plan_isProgress")}>
          {selectedApp?.isProgress ? icons.check_enabled : icons.close}
        </Descriptions.Item>
        <Descriptions.Item label={t("plan_isPending")}>
          {selectedApp?.isPending ? icons.check_enabled : icons.close}
        </Descriptions.Item>
        <Descriptions.Item label={t("plan_isStarting")}>
          {selectedApp?.isStarting ? icons.check_enabled : icons.close}
        </Descriptions.Item>
        <Descriptions.Item label={t("plan_isEnding")}>
          {selectedApp?.isEnding ? icons.check_enabled : icons.close}
        </Descriptions.Item>
        <Descriptions.Item label={t("plan_isClosed")}>
          {selectedApp?.isClosed ? icons.check_enabled : icons.close}
        </Descriptions.Item>
        {selectedApp?.planId?.isTemporal && (
          <Descriptions.Item label={t("startdate")}>{selectedApp?.startdate}</Descriptions.Item>
        )}
        {selectedApp?.planId?.isTemporal && (
          <Descriptions.Item label={t("departureTime")}>{selectedApp?.departureTime}</Descriptions.Item>
        )}
        {selectedApp?.planId?.isTemporal && (
          <Descriptions.Item label={t("Limits")}>
            Min: {selectedApp?.planId?.minLimit} | Max: {selectedApp?.maxLimit}
          </Descriptions.Item>
        )}
        <Descriptions.Item label={t("pack")}>{selectedApp?.packId?.name || ""}</Descriptions.Item>
        <Descriptions.Item label={t("price")}>
          {!selectedApp?.planId?.isFree ? priceFormat(selectedApp?.price) : t("free")}
        </Descriptions.Item>
        <Descriptions.Item label={t("duration")}>
          {selectedApp?.planId?.duration} {selectedApp?.planId?.durationMode}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("code")}>{selectedApp?.code}</Descriptions.Item>
        <Descriptions.Item label={t("guests")}>
          {selectedApp.guestQuantity} / {selectedApp.guestLimit}
        </Descriptions.Item>
        <Descriptions.Item label={t("price")}>{priceFormat(selectedApp.planId?.price)}</Descriptions.Item>
        <Descriptions.Item label={t("serial")}>{selectedApp?.correlative}</Descriptions.Item>
      </Descriptions>

      <Divider orientation="left">
        Contacto: {selectedApp.userId?.firstname} {selectedApp.userId?.lastname}
      </Divider>
      <Descriptions size="small" column={3}>
        <Descriptions.Item label={t("Contact")}>
          {selectedApp.userId?.firstname} {selectedApp.userId?.lastname}
        </Descriptions.Item>
        <Descriptions.Item label={t("email")}>{selectedApp.userId?.email}</Descriptions.Item>
        <Descriptions.Item label={t("documentType")}>{selectedApp.userId?.documentType}</Descriptions.Item>
        <Descriptions.Item label={t("document")}>{selectedApp.userId?.document}</Descriptions.Item>
        <Descriptions.Item label={t("phone")}>{selectedApp.userId?.phone}</Descriptions.Item>
        <Descriptions.Item label={t("birthdate")}>{dateFormat4(selectedApp.userId?.birthdate)}</Descriptions.Item>
        <Descriptions.Item label={t("address")}>{selectedApp.userId?.address}</Descriptions.Item>
        <Descriptions.Item label={t("region")}>{selectedApp.userId?.region}</Descriptions.Item>
        <Descriptions.Item label={t("city")}>{selectedApp.userId?.city}</Descriptions.Item>
      </Descriptions>
    </div>
  );

  const Content = ({ children }) => (
    <div className="content">
      <div className="main">{children}</div>
    </div>
  );

  return <>{selectedApp ? <Content>{renderContent()}</Content> : <Spin />}</>;
};

export default AppMoreDetails;
