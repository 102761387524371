import { confirmAlert } from "react-confirm-alert";

import { archivedUser, activeUser, exportExcelUsers, getUser, checkinUser, updateUser, searchUsers } from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";
import { setUser, setUserRedux } from "../../../redux/user";

export const handleActiveConfirm = ({ user, t, refetch }) => {
  activeUser(
    {
      user,
    },
    {
      onSuccess: () => {
        refetch();
        showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleActive = ({ user, t, refetch }) => {
  confirmAlert({
    title: user.active ? `${t("desactivate")} ${t("user")}` : `${t("activate")} ${t("user")}`,
    message: t("AreYouSureWantToContinue"),
    buttons: [
      {
        label: t("yes"),
        onClick: () => handleActiveConfirm({ user, t, refetch }),
      },
      {
        label: t("no"),
        onClick: () => {},
      },
    ],
  });
};

export const handleArchivedConfirm = ({ user, t, refetch }) => {
  archivedUser(
    {
      user,
    },
    {
      onSuccess: () => {
        refetch();
        showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleExportExcel = (users) => {
  exportExcelUsers(users);
};

export const handleArchived = ({ user, t, refetch }) => {
  confirmAlert({
    title: user.active ? `${t("desactivate")} ${t("user")}` : `${t("activate")} ${t("user")}`,
    message: t("AreYouSureWantToContinue"),
    buttons: [
      {
        label: t("yes"),
        onClick: () => handleArchivedConfirm({ user, t, refetch }),
      },
      {
        label: t("no"),
        onClick: () => {},
      },
    ],
  });
};

export const handleDetails = ({ dispatch, user }, { onSuccess = {} }) => {
  getUser(user._id, {
    onSuccess: (response) => {
      setUserRedux(response.user)(dispatch);
      onSuccess(response.user);
    },
    onError: () => showGlobalNotification("error", "Error", "error"),
  });
};

export const handleCheckinUser = ({ userId, message, t, appId }, { onSuccess = {}, onFinally = {} }) => {
  checkinUser(
    {
      message,
      appId,
      userId,
    },
    {
      onSuccess: (result) => {
        onSuccess(result);
      },
      onError: () => showGlobalNotification("error", "errorTitle", t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleUpdateUser = ({ userId, params, t }, { onSuccess = {}, onFinally = {} }) => {
  updateUser(
    {
      ...params,
      userId,
    },
    {
      onSuccess: (result) => {
        onSuccess(result);
      },
      onError: () => showGlobalNotification("error", "errorTitle", t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleSearchUsers = ({ text, t }, { onSuccess = {}, onFinally = {} }) => {
  searchUsers(text, {
    onSuccess: (result) => {
      onSuccess(result);
    },
    onError: () => showGlobalNotification("error", "errorTitle", "errorDescription"),
    onFinally: () => {},
  });
};
