import { useState, useEffect } from "react";
import { Form, Row, Col, Input, Switch, DatePicker, PageHeader, Descriptions, Button, Select } from "antd";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { confirmAlert } from "react-confirm-alert";

import { withRouter } from "react-router-dom";
import { STATUS_APP, durationFormat } from "../../../constants/globals";
import { newAppByApp } from "../requests";
import SelectPacks from "../../subscribers/components/SelectPacks";
import SelectPlans from "../../subscribersPlans/components/SelectPlans";

const moment = require("moment");
const Swal = require("sweetalert2");

const DuplicateApp = ({ selectedApp }) => {
  const { t } = useTranslation();
  const instanceStoreRedux = useSelector((store) => store.instance);

  const [setting, setSetting] = useState({});
  const [user, setUser] = useState({});
  const [app, setApp] = useState({});
  const [packId, setPackId] = useState({});
  const [planId, setPlanId] = useState({});
  const [plan, setPlan] = useState({});
  const [reload, setReload] = useState(0);

  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  const onValuesChange = (values) => {
    setUser({ ...user, ...values });
    form.setFieldsValue({ ...user, ...values });
    if (values.startDate) {
      setUser({
        ...user,
        ...values,
        startDate: moment(values.startDate).format("YYYY/MM/DD"),
        closureDate: moment(values.startDate).add(app.planId.duration, "M"),
      });
    }
  };

  const handleStartdate = (values) => {
    setUser({
      ...user,
      closureDate: moment(values).add(plan.duration, durationFormat(plan.durationMode)),
      startDate: values,
    });
  };

  const onFinish = () => {
    let params = { app, plan, user };
    if (params.active) params = { ...params, status: STATUS_APP.OPEN };
    else params = { ...params, status: STATUS_APP.CANCEL };

    newAppByApp(params, {
      onSuccess: () => {
        Swal.fire({
          icon: "success",
          title: "Operación Exitosa",
        });
        setTimeout(() => window.close(), 3000);
      },
      onError: () => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="">Why do I have this issue?</a>',
        });
      },
      onFinally: () => {},
    });
  };

  const handleSubmit = () => {
    confirmAlert({
      title: "Confirmación",
      message: `Esta seguro que desea continuar?`,
      buttons: [
        {
          label: "Continuar",
          onClick: () => form.submit(),
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const handleDesactivate = () => {
    if (user.description === undefined || String(user.description).length < 7) {
      Swal.fire({
        icon: "info",
        title: "Importante",
        text: "Por favor justifique en el campo observación el motivo de la cancelación de esta aplicación",
      });
    } else {
      confirmAlert({
        title: "Desactivar aplicación",
        message: `Esta seguro que desea continuar?`,
        buttons: [
          {
            label: "Continuar",
            onClick: () => {
              setUser({ ...user, active: false, status: STATUS_APP.CANCEL });
              form.submit();
            },
          },
          {
            label: "Cancelar",
            onClick: () => {},
          },
        ],
      });
    }
  };

  const handlePack = (value) => {
    setPackId(value);
    setPlanId(null);
  };

  const handlePlan = (value) => {
    setPlanId(value);
  };

  useEffect(() => {
    setPlan({});
  }, [packId]);

  useEffect(() => {
    if (user.startDate) {
      setUser({
        ...user,
        closureDate: moment(user.startDate).add(plan.duration, durationFormat(plan.durationMode)),
      });
    }
  }, [plan]);

  useEffect(() => {
    form.setFieldsValue(user);
  }, [form, user]);

  useEffect(() => {
    setReload(Math.floor(Math.random() * 100000));

    setSetting(instanceStoreRedux.currentInstance);
    if (selectedApp?.planId) {
      setApp(selectedApp);
      setUser({
        ...selectedApp.userId,
        startDate: moment(selectedApp?.startDate, "YYYY/MM/DD") || moment.now(),
        closureDate: moment(selectedApp?.closureDate, "YYYY/MM/DD").add(1, "M"),
      });
      setLoading(false);
    }
  }, [selectedApp]);
  // selectedApp?.planId?.duration
  return (
    <>
      {!loading && (
        <Row>
          <Col span={18} offset={3}>
            <Form
              form={form}
              name="manage-category-form-modal"
              className="manage-modal"
              onFinish={onFinish}
              labelCol={{ span: 24 }}
              onValuesChange={onValuesChange}
            >
              <img
                src={setting.logo}
                alt="logo"
                style={{ padding: "20px 0px", width: "120px", margin: "auto", display: "block" }}
              />

              <PageHeader
                avatar={{ src: user.avatar }}
                className="site-page-header-responsive"
                title={`${app?.packId.name} ${app?.planId.name}`}
                subTitle={app?.code}
                extra={[
                  <Button key="2" className="secondary" onClick={handleDesactivate}>
                    <span style={{ color: "red" }}> Desactivar aplicación </span>
                  </Button>,
                  <Button key="1" size="large" onClick={handleSubmit}>
                    Activación
                  </Button>,
                ]}
              >
                <Descriptions size="small" column={2}>
                  <Descriptions.Item label="Contacto">
                    {user.firstname} {user.lastname || ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="Telefono">{user.phone}</Descriptions.Item>
                  <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
                </Descriptions>
              </PageHeader>
              {/* <Comment
                className="commment"
                avatar={<Avatar src="https://cluuftracks.herokuapp.com/bot.gif" alt="Han Solo" />}
                content={
                  <p>
                    We supply a series of design principles, practical patterns and high quality design resources
                    (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.
                  </p>
                }
              /> */}

              <Row gutter={15} style={{ paddingBottom: 10 }}>
                <Col span={12}>
                  <Form.Item name="packId2" tooltip="" label={t("pack")}>
                    <SelectPacks
                      reload={reload}
                      currentValue=""
                      defaultValue=""
                      returnData={handlePack}
                      typePack="SUBSCRIPTION"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="planId" tooltip="" label={t("plan")}>
                    {packId.length > 5 && (
                      <SelectPlans
                        handleSelected={setPlan}
                        reload={reload}
                        currentValue={planId}
                        defaultValue={planId}
                        returnData={handlePlan}
                        type="SUBSCRIPTION"
                        packId={packId}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {plan._id && (
                <Row gutter={16}>
                  <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item name="paymentMode" label="Metodo de pago">
                      <Select className="select-form-modal">
                        <Select.Option value="none">Sin pago</Select.Option>
                        <Select.Option value="cash">Efectivo</Select.Option>
                        <Select.Option value="debitcard">Débito</Select.Option>
                        <Select.Option value="creditcard">Crédito</Select.Option>
                        <Select.Option value="transfer">Transferencía</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item name="paymentReference" label="Número de referencia">
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item name="startDate" label="Fecha de inicio">
                      <DatePicker format="YYYY/MM/DD" onChange={handleStartdate} />
                    </Form.Item>
                  </Col>

                  <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item name="closureDate" label="Fecha de finalización">
                      <DatePicker className="color-red" format="YYYY/MM/DD" disabled />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    name="firstname"
                    label="Nombre"
                    rules={[{ required: true, message: "Please enter Nombre" }]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="lastname" label="Apellido">
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="documentType" label="Tipo de Documento">
                    <Select placeholder="Tipo de Documento">
                      <Select.Option value="Cédula de Ciudadanía">Cédula de Ciudadanía</Select.Option>
                      <Select.Option value="Tarjeta de Identidad">Tarjeta de Identidad</Select.Option>
                      <Select.Option value="Cédula de Extranjería">Cédula de Extranjería</Select.Option>
                      <Select.Option value="Pasaporte">Pasaporte</Select.Option>
                      <Select.Option value="Permiso Especial (PEP)">Permiso Especial (PEP)</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="document" label="Número de Documento">
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="email" label="Email" rules={[{ required: true, message: "Please enter Email" }]}>
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="phone"
                    label="Teléfono"
                    rules={[{ required: true, message: "Please enter Teléfono" }]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={10} xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="description" label="Alguna observación importante">
                    <Input value={app.description} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
};
export default withRouter(DuplicateApp);
