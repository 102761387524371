import { useState } from "react";

import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";
import DateSelector from "../../subscribersDashboard/components/dateSelector";
import { icons } from "../../../constants/globals";

const FiltersTable = ({ setFilters, filters }) => {
  const [close, setClose] = useState(false);
  const [progress, setProgress] = useState(false);
  const [block, setBlock] = useState(false);
  const [modeSearch, setModeSearch] = useState("null");

  const handleCloseSelected = () => {
    setBlock(true);
    setModeSearch("ok");
    setClose(true);
    setProgress(false);
    setFilters({ ...filters, kind: "event", status: "close", orderDate: "desc", desde: null, hasta: null });
    setTimeout(() => {
      setBlock(false);
      setModeSearch("null");
    }, [3000]);
  };

  const handleProgress = () => {
    setBlock(true);
    setModeSearch("ok");
    setProgress(true);
    setClose(false);
    setFilters({ ...filters, kind: "event", status: "progress", orderDate: "asc", desde: null, hasta: null });
    setTimeout(() => {
      setBlock(false);
      setModeSearch("null");
    }, [5000]);
  };

  return (
    <>
      <span style={{ marginLeft: "-80px" }}>
        <Space>
          {icons.search} Mostrar actividades:{" "}
          <Button
            disabled={block}
            loading={block}
            className="tag-button-table"
            size="small"
            type={progress === true ? "primary" : "default"}
            onClick={() => handleProgress()}
          >
            <b>En progreso</b>
          </Button>
          <Button
            loading={block}
            disabled={block}
            className="tag-button-table"
            size="small"
            type={close === true ? "primary" : "default"}
            onClick={() => handleCloseSelected()}
          >
            <b>Finalizadas</b>
          </Button>
          &nbsp;&nbsp;&nbsp;
          {icons.calendar}{" "}
          <DateSelector reload={modeSearch} filter={filters} setFilter={setFilters} setModeSearch={setModeSearch} />
        </Space>
      </span>
    </>
  );
};

export default FiltersTable;
