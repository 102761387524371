import { Table, Space, Tooltip, Avatar } from "antd";
import {
  CheckCircleTwoTone,
  EditOutlined,
  DeleteOutlined,
  BarsOutlined,
  BlockOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import BaseTable from "../../shared/components/BasicTable";
import { dateFormat2, icons } from "../../../constants/globals";

const PacksTable = ({
  selectedUser,
  setSelectedUser,
  setPage,
  history,
  packs,
  refetch,
  handleDetails,
  handleEdit,
  userId,
  handleArchived,
  handleActive,
  handleDuplicate,
  handleReload,
  handleSyncPlans,
  handleAirTable,
  t,
  ...restProps
}) => (
  <>
    <BaseTable {...restProps} dataSource={packs} refetch={refetch} scroll={{ x: 1200 }}>
      <Table.Column
        width="140px"
        title={t("actions")}
        dataIndex="actions"
        key="actions"
        render={(_, data) => (
          <Space size="small">
            <a href="#!" onClick={() => handleDetails(data)} role="button" tabIndex={0}>
              <Tooltip placement="top" title="Haga click para INGRESAR">
                <BarsOutlined />
              </Tooltip>
            </a>
            <a href="#!" onClick={() => handleDuplicate(data)} role="button" tabIndex={0}>
              <Tooltip placement="top" title="Haga click para DUPLICAR">
                <BlockOutlined />
              </Tooltip>
            </a>
            <a href="#!" onClick={() => handleEdit(data)} role="button" tabIndex={0}>
              <Tooltip placement="top" title="Haga click para EDITAR">
                <EditOutlined />{" "}
              </Tooltip>
            </a>
            <a href="#!" onClick={() => handleArchived(data)} role="button" tabIndex={0}>
              <Tooltip placement="top" title="Haga click para ELIMINAR">
                <DeleteOutlined />
              </Tooltip>
            </a>
            <a href="#!" onClick={() => handleReload(data)} role="button" tabIndex={0}>
              <Tooltip placement="top" title="Haga click para Refrescar">
                <ReloadOutlined />
              </Tooltip>
            </a>

            <a href="#!" onClick={() => handleSyncPlans(data)} role="button" tabIndex={0}>
              <Tooltip placement="top" title="Haga click para Refrescar">
                {icons.internet}
              </Tooltip>
            </a>

            <a href="#!" onClick={() => handleActive(data)} role="button" tabIndex={0}>
              {data.active ? (
                <Tooltip placement="top" title="Haga click para DESACTIVAR">
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="Haga click para ACTIVAR">
                  <CheckCircleTwoTone twoToneColor="#FF0A50" />
                </Tooltip>
              )}
            </a>
          </Space>
        )}
      />
      <Table.Column
        sorter
        title="AI Sync"
        dataIndex="airtableSync"
        width="90px"
        align="center"
        key="airtableSync"
        render={(_, row) => (
          <Space>
            <a href="#!" onClick={() => handleAirTable(row)} role="button" tabIndex={0}>
              {row.airtableSync ? (
                <Tooltip placement="top" title="Haga click para Quitar la sincronización con Airtable">
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="Haga click para Activar la sincronización con Airtable">
                  <CheckCircleTwoTone twoToneColor="#FF0A50" />
                </Tooltip>
              )}
            </a>
          </Space>
        )}
      />
      <Table.Column
        sorter
        title={t("category")}
        align="center"
        dataIndex="category"
        key="category"
        render={(_) => <span style={{ fontSize: 16, fontWeight: 600 }}>{_}</span>}
        width="150px"
      />
      <Table.Column
        title={t("name")}
        dataIndex="name"
        key="name"
        sorter
        render={(text, row) => (
          <h3>
            <Avatar size="small" style={{ backgroundColor: row.color }}>
              {row.plans || "1"}
            </Avatar>
            {"  "}
            {row.name} {row.position || ""}
          </h3>
        )}
      />
      <Table.Column
        title={t("precode")}
        dataIndex="precode"
        key="precode"
        align="center"
        render={(text, row) => <code>{row.precode}</code>}
      />{" "}
      <Table.Column
        title={t("plans")}
        dataIndex="plans"
        key="plans"
        align="center"
        render={(text, row) => <code>{row.plans || 0}</code>}
      />
      <Table.Column
        title={t("position")}
        dataIndex="position"
        key="position"
        width="50px"
        align="center"
        render={(text, row) => row.position}
      />
      <Table.Column
        title={t("updatedAt")}
        dataIndex="updatedAt"
        key="updatedAt"
        align="center"
        render={(text, row) => dateFormat2(row.lastUpdatedAt)}
      />
    </BaseTable>
  </>
);

export default withRouter(PacksTable);
