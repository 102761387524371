import { Statistic, Row, Col, PageHeader } from "antd";
import { LoginOutlined, LogoutOutlined, PauseCircleOutlined } from "@ant-design/icons";

const Box = ({ icon, metrics, title = "", subtitle }) => (
  <div className="container-subpages">
    <PageHeader
      className="site-page-header"
      title={
        <>
          {icon} {"  "} {title}
        </>
      }
      subTitle="Pesos Colombianos"
    >
      <Row justify="start">
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <Statistic
            title="Cantidad"
            value={metrics?.createTrans?.data?.length || 0}
            prefix={<LoginOutlined style={{ color: "green" }} />}
          />
        </Col>

        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
          <Statistic
            title="Efectivo"
            value={metrics?.createTrans?.cash || 0}
            prefix={<LogoutOutlined style={{ color: "green" }} />}
          />
        </Col>
        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
          <Statistic
            title="Credito"
            value={metrics?.createTrans?.credit || 0}
            prefix={<LogoutOutlined style={{ color: "green" }} />}
          />
        </Col>
        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
          <Statistic
            title="Transferencía"
            value={metrics?.createTrans?.transfer || 0}
            prefix={<LogoutOutlined style={{ color: "green" }} />}
          />
        </Col>
        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
          <Statistic
            title="Total"
            value={metrics?.createTrans?.total || 0}
            prefix={<LogoutOutlined style={{ color: "green" }} />}
          />
        </Col>
      </Row>
    </PageHeader>
  </div>
);

export default Box;
