import { PageHeader, Button, Tag, Descriptions, Tooltip, Spin, Divider } from "antd";
import { EditOutlined, CalendarOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";
import { globals, dateFormat4, priceFormat } from "../../../constants/globals";

const Header = ({ setPage, t, handleEditUser }) => {
  const { currentApp } = useSelector((store) => store.app);

  const renderContent = () => (
    <>
      <div className="container-subpages">
        <Descriptions size="small" column={4}>
          <Descriptions.Item label={t("pack")}>
            <>{currentApp.packId?.name}</>
          </Descriptions.Item>
          <Descriptions.Item label={t("plan")}>
            <>{currentApp.planId?.name}</>
          </Descriptions.Item>
          <Descriptions.Item label={t("from")}>
            <>
              <CalendarOutlined /> &nbsp; {dateFormat4(currentApp.startDate)}
            </>
          </Descriptions.Item>
          <Descriptions.Item label={t("to")}>
            <>
              <CalendarOutlined /> &nbsp; {dateFormat4(currentApp.closureDate)}
            </>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions size="small" column={4}>
          <Descriptions.Item label={t("code")}>{currentApp?.code}</Descriptions.Item>
          <Descriptions.Item label={t("duration")}>
            {currentApp?.planId?.duration} {currentApp?.planId?.durationMode}
          </Descriptions.Item>
          <Descriptions.Item label={t("guests")}>
            {currentApp.guestQuantity} / {currentApp.guestLimit}
          </Descriptions.Item>
          <Descriptions.Item label={t("price")}>{priceFormat(currentApp.planId?.price)}</Descriptions.Item>
          <Descriptions.Item label={t("serial")}>{currentApp?.correlative}</Descriptions.Item>

          <Descriptions.Item label={t("status")}>
            {" "}
            <Tag color={currentApp?.status === "open" ? "green" : "volcano"} key={t("pending")}>
              {currentApp?.status}
            </Tag>
          </Descriptions.Item>

          <Descriptions.Item label={t("active")}> {currentApp?.active ? t("active") : t("inactive")}</Descriptions.Item>
        </Descriptions>

        <Divider orientation="left">Acerca del Contacto</Divider>
        <Descriptions size="small" column={3}>
          <Descriptions.Item label={t("Contact")}>
            {currentApp.userId?.firstname} {currentApp.userId?.lastname}
          </Descriptions.Item>
          <Descriptions.Item label={t("email")}>{currentApp.userId?.email}</Descriptions.Item>
          <Descriptions.Item label={t("documentType")}>{currentApp.userId?.documentType}</Descriptions.Item>
          <Descriptions.Item label={t("document")}>{currentApp.userId?.document}</Descriptions.Item>
          <Descriptions.Item label={t("phone")}>{currentApp.userId?.phone}</Descriptions.Item>
          <Descriptions.Item label={t("birthdate")}>{dateFormat4(currentApp.userId?.birthdate)}</Descriptions.Item>
          <Descriptions.Item label={t("address")}>{currentApp.userId?.address}</Descriptions.Item>
          <Descriptions.Item label={t("region")}>{currentApp.userId?.region}</Descriptions.Item>
          <Descriptions.Item label={t("city")}>{currentApp.userId?.city}</Descriptions.Item>
        </Descriptions>
      </div>
    </>
  );

  const extraContent = <></>;

  const Content = ({ children, extra }) => (
    <div className="content">
      <div className="main">{children}</div>
      <div className="extra">{extra}</div>
    </div>
  );

  const goBack = (app) => {
    setPage(globals.PAGE_MAIN);
  };

  return (
    <>
      {currentApp._id ? (
        <PageHeader
          avatar={{ src: currentApp?.userId?.avatar || "https://cluuftracks.herokuapp.com/bot.gif" }}
          className="site-page-header-responsive"
          onBack={() => goBack(currentApp)}
          title={`${currentApp?.code}`}
          subTitle={
            <>
              <Tag color={currentApp?.status === "open" ? "green" : "volcano"} key={t("pending")}>
                {currentApp?.status}
              </Tag>
            </>
          }
          extra={[
            <Tooltip placement="topLeft" title="Prompt Text">
              <Button
                className="ant-btn secondary primary"
                key="1"
                icon={<EditOutlined />}
                size="small"
                onClick={() => handleEditUser(currentApp?.userId)}
              >
                {t("edit")} {t("contact")}
              </Button>
            </Tooltip>,
          ]}
        >
          <Content extra={extraContent}>{renderContent()}</Content>
        </PageHeader>
      ) : (
        <Spin className="spin-global" />
      )}
    </>
  );
};

export default Header;
