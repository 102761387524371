import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Row, Col, Input, Spin, message, InputNumber } from "antd";

import { saveApp, getApp } from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";
import BaseModal from "../../shared/components/Modal/BaseModal";
import { setAppRedux } from "../../../redux/app";

const moment = require("moment");

const { TextArea } = Input;

const defaultValues = {
  planId: null,
  userId: null,
  observation: "",
  active: true,
  price: 0,
  tag: "",
  avatar: "",
  public: false,
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  quantity: 1,
  amount: 0,
};

const ManageAppModal = ({ appId, setAppId, openModal, closeModal, refetch, t }) => {
  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);

  const [selectedApp, setSelectedApp] = useState(defaultValues);
  const [form] = Form.useForm();
  const [loaderForm, setLoaderForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [reload, setReload] = useState(0);

  const handleReset = () => {
    setSelectedApp(defaultValues);
    setReload(Math.floor(Math.random() * 100000));
  };

  useEffect(() => {
    if (openModal) {
      if (appId) {
        setMode("edit");
      } else {
        setMode("new");
      }
    } else {
      handleReset();
      setMode(null);
      setAppId(null);
    }
  }, [openModal]);

  useEffect(() => {
    if (mode === "new") {
      handleReset();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      setLoaderForm(true);
      getApp(appId, {
        onSuccess: (response) => {
          setAppRedux(response.result)(dispatch);
          setSelectedApp({
            ...response.result,
            planId: response.result.planId._id,
            userId: response.result.userId._id,
            user: response.result.userId,
          });

          setLoaderForm(false);
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  }, [mode]);

  useEffect(() => {
    form.setFieldsValue(selectedApp);
  }, [form, selectedApp]);

  const handleCloseModal = () => {
    refetch();
    closeModal();
  };

  const onFinish = (values) => {
    const data = { ...selectedApp, ...values };

    if (!data.planId) {
      message.error("Seleccione un Plan");
    } else if (!data.userId) {
      message.error("Seleccione un Cliente");
    } else {
      saveApp(
        {
          ...data,
          appId: appId || "",
          rootId: sessionStoreRedux.currentSession_id,
        },
        {
          onSuccess: (response) => {
            setSelectedApp(response.result);
            showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
          },
          onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
          onFinally: () => handleCloseModal(),
        }
      );
    }
  };

  const onValuesChange = (values) => {
    console.log(values);
    setSelectedApp({ ...selectedApp, ...values });
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  return (
    <BaseModal
      isOpenModal={openModal}
      closeModal={handleCloseModal}
      onCancel={handleCloseModal}
      onConfirm={() => handleSubmit()}
      title={appId ? `${t("edit")} ${t("application")}` : `${t("new")} ${t("application")}`}
      width={700}
      confirmText={t("save")}
      isCloseModal
      top={20}
    >
      <span className="code">34672727</span>
      <Form
        form={form}
        name="manage-category-form-modal"
        initialValues={defaultValues}
        className="manage-modal"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        onValuesChange={onValuesChange}
      >
        <div className="layout-form-modal entity-300 entity-small">
          {loaderForm ? (
            <>
              <Spin tip={t("loading")} />
            </>
          ) : (
            <>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="quantity" label="Tickets">
                    <InputNumber min={1} max={100} defaultValue={1} value={selectedApp?.quantity} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="observation" label={t("description")}>
                    <TextArea showCount="true" onResize="false" value={selectedApp?.observation} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Form>
    </BaseModal>
  );
};

ManageAppModal.defaultProps = {
  setAppId: () => {},
};

export default ManageAppModal;
