import { PageHeader, Button, Descriptions, Typography, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, AmazonOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";
import { globals, numbers, birthdate, icons } from "../../../constants/globals";

const { Link } = Typography;

const Header = ({ setPage, handleEdit, handleDetails, handleUploadImage, setSubpage, extradata, t }) => {
  const { currentUser } = useSelector((store) => store.user);

  const renderContent = (column = 3) => (
    <div className="container-subpages">
      <Descriptions size="small" column={column}>
        <Descriptions.Item label={t("firstname")}>{currentUser.firstname}</Descriptions.Item>
        <Descriptions.Item label={t("lastname")}>{currentUser.lastname}</Descriptions.Item>
        <Descriptions.Item label={t("yearsold")}>{birthdate(currentUser.birthdate)}</Descriptions.Item>
        <Descriptions.Item label={t("birthday")}>{currentUser.birthdate}</Descriptions.Item>
        <Descriptions.Item label={t("documentType")}>{currentUser.documentType}</Descriptions.Item>
        <Descriptions.Item label={t("document")}>{currentUser.document}</Descriptions.Item>
        <Descriptions.Item label={t("email")}>{currentUser.email}</Descriptions.Item>
        <Descriptions.Item label={t("phone")}>{currentUser.phone}</Descriptions.Item>
        <Descriptions.Item label={t("medium")}>{currentUser.medium}</Descriptions.Item>
        <Descriptions.Item label={t("address")}>{currentUser.address}</Descriptions.Item>
        <Descriptions.Item label={t("city")}>{currentUser.city || extradata.city}</Descriptions.Item>
        <Descriptions.Item label={t("startdate")}>{currentUser.startdate}</Descriptions.Item>
        <Descriptions.Item label={t("enddate")}>{currentUser.closuredate}</Descriptions.Item>
        <Descriptions.Item label={t("createdAt")}>{currentUser.creartedAt}</Descriptions.Item>

        <Descriptions.Item label={t("Referrer By")}>
          {currentUser.refererId ? (
            <Link href="#!" onClick={() => handleDetails({ _id: currentUser.refererId._id })}>
              {currentUser.refererId.firstname} {currentUser.refererId.lastname}
            </Link>
          ) : (
            "--"
          )}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );

  const extraContent = <></>;

  const Content = ({ children, extra }) => (
    <div className="content">
      <div className="main">{children}</div>
      <div className="extra">{extra}</div>
    </div>
  );

  return (
    <>
      {currentUser._id ? (
        <PageHeader
          avatar={{ src: currentUser.avatar || "https://cluuftracks.herokuapp.com/bot.gif" }}
          className="site-page-header-responsive"
          onBack={() => setPage(globals.PAGE_MAIN)}
          title={`${currentUser.firstname} ${currentUser.lastname || ""}`}
          subTitle="..."
          extra={[
            <Tooltip placement="topLeft" title="Adjuntar archivo al contacto">
              <Button
                className="secondary"
                onClick={() => handleUploadImage("Gallery")}
                shape="square"
                icon={icons.upload}
                size="medium"
                type="primary"
              >
                {t("uploadFiles")}
              </Button>
            </Tooltip>,
            <Tooltip placement="topLeft" title="Actualizar Foto de Perfil">
              <Button
                className="secondary"
                onClick={() => handleUploadImage("Avatar")}
                shape="square"
                icon={icons.foto}
                size="medium"
                type="primary"
              >
                {t("photo")}
              </Button>
            </Tooltip>,
            <Tooltip placement="topLeft" title={t("edit")}>
              <Button size="medium" key={numbers.ONE} onClick={() => handleEdit(currentUser)}>
                {icons.edit} {t("edit")}
              </Button>{" "}
            </Tooltip>,
          ]}
        >
          <Content extra={extraContent}>{renderContent()}</Content>
        </PageHeader>
      ) : (
        t("loading")
      )}
    </>
  );
};

export default Header;
