import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Input, Select, Spin, Switch, InputNumber, message } from "antd";
import { savePlan, getPlan } from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";
import BaseModal from "../../shared/components/Modal/BaseModal";
import SelectPacks from "../../contentsv2/components/SelectPacks";
import { setPlanRedux } from "../../../redux/plan";
import { packs } from "../../../constants/globals";
import RichTextComponent from "./RichTextComponent";

const moment = require("moment");

const { TextArea } = Input;

const defaultValues = {
  name: "",
  departurePlace: "",
  minLimit: 1,
  maxLimit: 1,
  category: "",
  duration: "",
  active: true,
  public: false,
  price: 0,
  packId: null,
  type: packs.CONTENT,
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  code: "",
  body: "",
  description: "",
};

const ManagePlanModal = ({ planId, openModal, closeModal, refetch, setPlanId, typePack, packId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);

  const [selectedPlan, setSelectedPlan] = useState(defaultValues);
  const [form] = Form.useForm();
  const [loaderForm, setLoaderForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [reload, setReload] = useState(0);

  const handleReset = () => {
    setSelectedPlan(defaultValues);
    setReload(Math.floor(Math.random() * 100000));
  };

  useEffect(() => {
    if (openModal) {
      if (planId) {
        setMode("edit");
      } else {
        setMode("new");
      }
    } else {
      handleReset();
      setMode(null);
      setPlanId(null);
    }
  }, [openModal]);

  useEffect(() => {
    if (mode === "new") {
      handleReset();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      setLoaderForm(true);
      getPlan(planId, {
        onSuccess: (response) => {
          setPlanRedux(response.result)(dispatch);

          setSelectedPlan({
            ...response.result,
            startdate: moment(response.result.startdate, "YYYY/MM/DD") || moment("2020/12/12", "YYYY/MM/DD"),
            closuredate: moment(response.result.closuredate, "YYYY/MM/DD") || moment("2020/12/12", "YYYY/MM/DD"),
            body: response.result.body,
          });

          setLoaderForm(false);
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  }, [mode]);

  useEffect(() => {
    form.setFieldsValue(selectedPlan);
  }, [form, selectedPlan]);

  const handleCloseModal = () => {
    handleReset();
    refetch();
    closeModal();
  };

  const onFinish = (values) => {
    const data = { ...selectedPlan, ...values };
    if (!data.packId) {
      message.error("This is an error message");
    } else {
      savePlan(
        {
          ...data,
          planId,
          rootId: sessionStoreRedux.currentSession_id,
        },
        {
          onSuccess: (response) => {
            setPlanRedux(response.result)(dispatch);
            showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
            handleReset();
          },
          onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
          onFinally: () => handleCloseModal(),
        }
      );
    }
  };

  const onValuesChange = debounce((values) => {
    if (values.aspectRatio) {
      setSelectedPlan({ ...selectedPlan, description: values.aspectRatio });
    } else {
      setSelectedPlan({ ...selectedPlan, ...values });
    }
  }, 500);

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  const handleActive = (checked) => {
    setSelectedPlan({ ...selectedPlan, active: checked });
  };

  const handlePublic = (checked) => {
    setSelectedPlan({ ...selectedPlan, public: checked });
  };

  const handlePack = (value) => {
    setSelectedPlan({ ...selectedPlan, packId: value });
  };

  const getHTML = debounce((values) => {
    console.log(values, "get html");
    setSelectedPlan({ ...selectedPlan, body: values });
  }, 500);

  return (
    <BaseModal
      isOpenModal={openModal}
      closeModal={handleCloseModal}
      onCancel={handleCloseModal}
      onConfirm={() => handleSubmit()}
      title={planId ? `${t("edit")} ${t("content")}` : `${t("new")} ${t("content")}`}
      width={1000}
      confirmText={t("save")}
      isCloseModal
      top={70}
    >
      <Form
        form={form}
        name="manage-category-form-modal"
        initialValues={defaultValues}
        className="manage-modal"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        onValuesChange={onValuesChange}
      >
        <div className="layout-form-modal entity-400 entity-small">
          {loaderForm ? (
            <>
              <Spin tip={t("loading")} />
            </>
          ) : (
            <>
              <Row gutter={15} style={{ paddingBottom: 10 }}>
                <Col span={12}>
                  <Form.Item name="packId2" tooltip="" label={t("pack")}>
                    <SelectPacks
                      reload={reload}
                      currentValue={selectedPlan?.packId}
                      defaultValue={selectedPlan?.packId}
                      returnData={handlePack}
                      typePack={typePack}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="category" label={t("category")}>
                    <Select>
                      <Select.Option value="LIST">LIST</Select.Option>
                      <Select.Option value="TEXT">TEXT</Select.Option>
                      <Select.Option value="HTML">HTML</Select.Option>
                      <Select.Option value="IMAGE">IMAGE</Select.Option>
                      <Select.Option value="EMAIL">EMAIL</Select.Option>
                      <Select.Option value="COLOR">COLOR</Select.Option>
                      <Select.Option value="LINK">LINK</Select.Option>
                      <Select.Option value="BACKGROUND">BACKGROUND</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="language" label={t("language")}>
                    <Select>
                      <Select.Option value="ES">Español</Select.Option>
                      <Select.Option value="EN">Ingles</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={15}>
                <Col span={10}>
                  <Form.Item
                    name="name"
                    tooltip=""
                    label={t("plan")}
                    rules={[
                      {
                        required: true,
                        message: `Por favor ingrese el nombre`,
                      },
                    ]}
                  >
                    <Input placeholder="" value={selectedPlan?.name} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="code" label={`${t("code")} [ ${selectedPlan?.pack?.precode} ]`}>
                    <Input value={selectedPlan?.code} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="active" labelAlign="right" label={t("active")}>
                    <Switch checked={!!selectedPlan?.active} onChange={handleActive} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="public" labelAlign="right" label={t("public")}>
                    <Switch checked={!!selectedPlan?.public} onChange={handlePublic} />
                  </Form.Item>
                </Col>
              </Row>

              {selectedPlan?.category === "COLOR" && (
                <Row gutter={16}>
                  <Col span={4}>
                    <Form.Item name="body" label={t("Seleccion un color")}>
                      <Input type="color" value={selectedPlan?.body} />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              {(selectedPlan?.category === "TEXT" || selectedPlan?.category === "LIST") && (
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="body" label={t("separatedByComma")}>
                      <TextArea showCount="true" onResize="false" value={selectedPlan?.body} />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              {(selectedPlan?.category === "HTML" || selectedPlan?.category === "EMAIL") && (
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="body" label={t("body")}>
                      <RichTextComponent setHTML={selectedPlan?.body} getHTML={getHTML} />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              {(selectedPlan?.category === "IMAGE" || selectedPlan?.category === "BACKGROUND") && (
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="aspectRatio" label={t("aspectRatio")}>
                      <Select>
                        <Select.Option value="Relación de Aspecto [16:9]  Medidas [1600px x 900 px]">
                          Relación de Aspecto [16:9] Medidas [1600px x 900px]
                        </Select.Option>
                        <Select.Option value=" Relación de Aspecto [16:9]  Medidas [2000px x 1125 px]">
                          Relación de Aspecto [16:9] Medidas [2000px x 1125 px]
                        </Select.Option>
                        <Select.Option value=" Relación de Aspecto [4:3] Medidas [1024px x 768px]">
                          Relación de Aspecto [4:3] Medidas [1024px x 768px]
                        </Select.Option>
                        <Select.Option value="  Relación de Aspecto [4:3] Medidas [1280px x 1024px]">
                          Relación de Aspecto [4:3] Medidas [1280px x 1024px]
                        </Select.Option>
                        <Select.Option value="  Relación de Aspecto [4:3] Medidas [1000px x 750px]">
                          Relación de Aspecto [4:3] Medidas [1000px x 750px]
                        </Select.Option>
                        <Select.Option value="  Relación de Aspecto [1:1] Medidas [800px x 800px]">
                          Relación de Aspecto [1:1] Medidas [800px x 800px]
                        </Select.Option>
                        <Select.Option value=" Relación de Aspecto [1:1] Medidas [400px x 400px]">
                          Relación de Aspecto [1:1] Medidas [400px x 400px]
                        </Select.Option>
                        <Select.Option value=" Relación de Aspecto [1:2] Medidas [400px x 800px]">
                          Relación de Aspecto [1:2] Medidas [400px x 800px]
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name="description" label={t("description")}>
                      <TextArea showCount="true" onResize="false" value={selectedPlan?.description} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      </Form>
    </BaseModal>
  );
};

ManagePlanModal.defaultProps = {
  setPlanId: () => {},
};

export default ManagePlanModal;
