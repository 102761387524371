import { useState, useEffect } from "react";

import { Form, Button, Col, Row, Input, Radio, Tooltip } from "antd";

import { handleSearchUsers } from "../functions/index";
import { STATUS_APP, icons, isModuleValid, colorKind, colorsSystem } from "../../../constants/globals";

const defaultValues = {
  all: "",
  status: "all",
  planes: "",
};

const FormUpdateUser = ({ user, t, reload, refetch, setFilters, filters, handleRecalculateApps }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const [isFilter, setIsFilter] = useState(false);

  const handleRefresh = () => {
    handleRecalculateApps();
    form.setFieldsValue(defaultValues);
    setFilters({
      ...filters,
      searchByUsers: false,
      status: null,
      planId: null,
      isProgress: "",
      isStarting: "",
      isPending: "",
      isClosed: true,
      sord: "desc",
      kind: "subscription",
    });
    refetch();
    setIsFilter(false);
  };

  const onFinishByLifecicle = (lifecicle) => {
    setFilters({ ...filters, ...data, lifecicle });
    refetch();
  };

  const onFinishByStatus = (status) => {
    if (status === STATUS_APP.ALL) {
      handleRefresh();
    } else {
      setFilters({ ...filters, ...data, status });
    }

    refetch();
  };

  const onFinishByName = () => {
    if (String(data.all).length > 1 && typeof data.all !== "undefined") {
      handleSearchUsers(
        { text: data.all, t },
        {
          onSuccess: (res) => {
            const usersId = [];
            res.result.forEach((item) => {
              usersId.push(item._id);
            });

            setIsFilter(true);
            setFilters({
              ...filters,
              ...data,
              searchByUsers: true,
              usersId,
              isProgress: "",
              isStarting: "",
              isPending: "",
              isCanceled: "",
              isClosed: "",
              kind: "",
            });
            refetch();
          },
          onError: () => {},
        }
      );
    } else {
      handleRefresh();
    }
  };

  const onValuesChange = (values) => {
    setData({ ...setData, ...values });
    if (values.status) onFinishByStatus(values.status);
    if (values.lifecicle) onFinishByLifecicle(values.lifecicle);

    if (values.all) {
      if (String(values.all).length > 2) {
        //  onFinishByName();
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue(user);
  }, [reload]);

  useEffect(() => {
    form.setFieldsValue(user);
  }, [form, setData]);

  useEffect(() => {}, [user]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [form, setData]);

  return (
    <Form
      layout="vertical"
      form={form}
      name="manage-category-form-modal"
      initialValues={defaultValues}
      className="manage-modal"
      labelCol={{ span: 24 }}
      onValuesChange={onValuesChange}
    >
      <Row gutter={24}>
        <Col md={8} lg={8} xs={24} sm={24}>
          <Form.Item
            tooltip="Buscar por coincidencia con nombres, apellidos, documento y Email"
            style={{ fontWeight: "600" }}
            name="all"
            label={
              <>
                <h3 className="semibold special-font">{icons.search}&nbsp;Buscar contacto &nbsp;</h3>
                <Tooltip title="Excluir todos los Filtros">
                  <Button className="secondary" size="small" onClick={() => handleRefresh()}>
                    Reset filtros {icons.close}
                  </Button>
                </Tooltip>
              </>
            }
          >
            <Input
              style={{
                fontWeight: "800",
                background: "#fbffcb",
                border: "1px solid",
                fontSize: 28,
                letterSpacing: 1,
                color: "#000",
              }}
            />
          </Form.Item>
        </Col>
        <Col md={4} lg={4} xs={24} sm={24}>
          <br /> <br /> <br />
          <Button
            style={{ marginTop: "-5px" }}
            size="large"
            className="ant-btn secondary primary large"
            onClick={onFinishByName}
          >
            Buscar
          </Button>
        </Col>

        {isFilter && (
          <Col span={24} style={{ textAlign: "center", marginTop: 10 }}>
            <Button className="ant-btn secondary error large" onClick={() => handleRefresh()}>
              {icons.close} Quitar Filtros{" "}
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default FormUpdateUser;
