import { PageHeader, Button, Descriptions, Tooltip } from "antd";

import { useSelector } from "react-redux";
import { globals, numbers, icons } from "../../../constants/globals";

const Header = ({ setPage, handleEdit, handleUploadImage, t }) => {
  const { currentSupplier } = useSelector((store) => store.supplier);

  const renderContent = (column = 3) => (
    <div className="container-subpages">
      <Descriptions size="small" column={column}>
        <Descriptions.Item label={t("fullname")}>{currentSupplier.fullname}</Descriptions.Item>
        <Descriptions.Item label={t("documentType")}>{currentSupplier.documentType}</Descriptions.Item>
        <Descriptions.Item label={t("document")}>{currentSupplier.document}</Descriptions.Item>
        <Descriptions.Item label={t("email")}>{currentSupplier.email}</Descriptions.Item>
        <Descriptions.Item label={t("phone")}>{currentSupplier.phone}</Descriptions.Item>
        <Descriptions.Item label={t("medium")}>{currentSupplier.medium}</Descriptions.Item>
        <Descriptions.Item label={t("address")}>{currentSupplier.address}</Descriptions.Item>
        <Descriptions.Item label={t("createdAt")}>{currentSupplier.creartedAt}</Descriptions.Item>
      </Descriptions>
    </div>
  );

  const extraContent = <></>;

  const Content = ({ children, extra }) => (
    <div className="content">
      <div className="main">{children}</div>
      <div className="extra">{extra}</div>
    </div>
  );

  return (
    <>
      {currentSupplier._id ? (
        <PageHeader
          className="site-page-header-responsive"
          onBack={() => setPage(globals.PAGE_MAIN)}
          title={currentSupplier.fullname}
          subTitle="..."
          extra={[
            <Tooltip placement="topLeft" title="Adjuntar archivo al contacto">
              <Button
                className="secondary"
                onClick={() => handleUploadImage("Gallery")}
                shape="square"
                icon={icons.upload}
                size="medium"
                type="primary"
              >
                {t("uploadFiles")}
              </Button>
            </Tooltip>,
            <Tooltip placement="topLeft" title="Actualizar Foto de Perfil">
              <Button
                className="secondary"
                onClick={() => handleUploadImage("Avatar")}
                shape="square"
                icon={icons.foto}
                size="medium"
                type="primary"
              >
                {t("photo")}
              </Button>
            </Tooltip>,
            <Tooltip placement="topLeft" title={t("edit")}>
              <Button size="medium" key={numbers.ONE} onClick={() => handleEdit(currentSupplier)}>
                {icons.edit} {t("edit")}
              </Button>{" "}
            </Tooltip>,
          ]}
        >
          <Content extra={extraContent}>{renderContent()}</Content>
        </PageHeader>
      ) : (
        t("loading")
      )}
    </>
  );
};

export default Header;
