import { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Checkbox,
  DatePicker,
  InputNumber,
  Button,
  Select,
  TimePicker,
  Space,
  Descriptions,
} from "antd";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { confirmAlert } from "react-confirm-alert";

import { withRouter } from "react-router-dom";
import { icons, durationFormat, priceFormat, durationFormat2 } from "../../../constants/globals";

import { saveAppByUser } from "../requests";

import SelectPacks from "../../subscribers/components/SelectPacks";
import SelectUsers from "../../subscribersUsers/components/SelectUsers";

const moment = require("moment");
const Swal = require("sweetalert2");

const defaultValues = {
  firstname: "",
  lastname: "",
  document: "",
  documentType: "",
  paymentMode: "none",
  startDate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  paymentReference: "",
  phone: "+57",
  quantity: "1",
  packId: null,
  planId: null,
};

const dateFormat = "YYYY/MM/DD";

const CreateAppRental = ({ selectedApp, onClose, refetch }) => {
  const { t } = useTranslation();
  const instanceStoreRedux = useSelector((store) => store.instance);

  const [setting, setSetting] = useState({});
  const [app, setApp] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [form] = Form.useForm();

  const [userId, setUserId] = useState(null);
  const [packId, setPackId] = useState(null);
  const [planId, setPlanId] = useState(null);

  const [plan, setPlan] = useState({});
  const [reload, setReload] = useState(0);
  const [userExist, setUserExist] = useState(false);
  const [userCheckin, setUserCheckin] = useState(true);
  const [userVaccine, setUserVaccine] = useState(true);
  const [userGift, setUserGift] = useState(false);
  const [userPrivacy, setUserPrivacy] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [paymentData, setPaymentData] = useState({});

  const [packInfo, setPackInfo] = useState({});

  const onValuesChange = (values) => {
    if (values.quantity)
      setPaymentData({
        total: values.quantity * packInfo.price,
        amount: values.quantity * packInfo.price,
        pending: 0,
      });
    if (data.amount)
      setPaymentData({
        amount: data.amount,
        total: data.quantity * packInfo.price,
        pending: data.quantity * packInfo.price - data.amount,
      });

    setData({ ...data, ...values });
  };

  const onChangeUserExist = (e) => {
    setUserExist(e.target.checked);
    if (!e.target.checked) setUserId(null);
  };

  const handleStartdate = (values) => {
    setData({
      ...data,
      closureDate: moment(values).add(plan.duration, durationFormat(plan.durationMode)),
      startDate: values,
      startdate: moment(values).format("YYYY-MM-DD"),
      closuredate: moment(values).add(plan.duration, durationFormat(plan.durationMode)).format("YYYY-MM-DD"),
    });
  };

  const onFinish = () => {
    setData({ ...data, app: selectedApp, active: true, userExist, userCheckin, userVaccine, userGift, userPrivacy });

    if (!userExist) {
      if (
        data.firstname === undefined ||
        data.firstname === "" ||
        data.lastname === "" ||
        data.lastname === undefined
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Por favor validé los Nombres y Apellidos",
        });
        return;
      }
      if (data.phone === undefined || data.email === undefined || data.email === "" || data.phone === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Por favor validé Email y Teléfono",
        });
        return;
      }

      if (
        data.documentType === undefined ||
        data.document === undefined ||
        data.document === "" ||
        data.documentType === ""
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Por favor validé el Documento",
        });
        return;
      }
    }

    if (!packInfo._id || typeof data.packId === "undefined" || data.packId === "null" || data.packId === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor validé el servicio a alquilar",
      });
      return;
    }

    if (data.startDate === null || data.startDate === "" || typeof data.startDate === "undefined") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor validé la fecha de alquiler",
      });
      return;
    }

    if (data.departureTime === null || data.departureTime === "" || typeof data.departureTime === "undefined") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor validé la hora de inicio",
      });
      return;
    }

    setLoadingSubmit(true);

    saveAppByUser(
      {
        ...data,
        app: selectedApp,
        active: true,
        isUserExist: userExist,
        isUserCheckin: false,
        isVaccine: false,
        isWelcomeGift: false,
        isPrivacyPolicy: false,
        kind: "rental",
        planId: null,
        startDate: moment(data.startDate).format("YYYY-MM-DD"),
        startdate: moment(data.startDate).format("YYYY-MM-DD"),
        departureTime: moment(data.departureTime).format("HH:mm"),
      },
      {
        onSuccess: () => {
          Swal.fire({
            icon: "success",
            title: "Operación Exitosa",
          });

          setData(defaultValues);
          refetch();
          onClose();
        },
        onError: () => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        },
        onFinally: () => {
          setLoadingSubmit(false);
        },
      }
    );
  };

  const handleSubmit = () => {
    confirmAlert({
      title: "Confirmación",
      message: `Esta seguro que desea continuar?`,
      buttons: [
        {
          label: "Continuar",
          onClick: () => form.submit(),
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const handlePack = (value) => {
    if (value === null) setPackInfo({});

    setPackId(value);
    setPlanId(null);
    setPaymentData({ total: data.quantity * (packInfo.price || 0) });
    setData({
      ...data,
      packId: value,
      amount: packInfo.price || 0,
      quantity: "1",
      startDate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
    });
  };

  const handleUser = (value) => {
    setUserId(value);
    setData({
      ...data,
      userId: value,
    });
  };

  useEffect(() => {
    setPlan({});
    setData({
      ...data,
      packId,
    });
  }, [packId]);

  useEffect(() => {
    if (data.quantity) setPaymentData({ total: data.quantity * packInfo.price });

    setData({
      ...data,
      amount: data.quantity * packInfo.price,
    });
  }, [packInfo]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  useEffect(() => {
    setReload(Math.floor(Math.random() * 100000));
    setSetting(instanceStoreRedux.currentInstance);
    setApp(selectedApp);
    setLoading(false);
  }, []);
  return (
    <>
      {!loading && (
        <>
          <Row>
            <Col span={22} offset={1}>
              <Form
                form={form}
                name="manage-category-form-modal "
                className="manage-modal minimal-form"
                onFinish={onFinish}
                labelCol={{ span: 24 }}
                onValuesChange={onValuesChange}
                initialValues={defaultValues}
              >
                <Row gutter={15}>
                  <Col span={24}>
                    <Checkbox className="box-warning" onChange={onChangeUserExist}>
                      <b>{icons.guest} El contacto ya se encuentra registrado.</b>
                    </Checkbox>
                  </Col>
                </Row>
                {!userExist && (
                  <Row gutter={15} className="box-primary">
                    <Col span={24} xs={24} sm={24} md={5} lg={5} xl={5}>
                      <Form.Item
                        name="firstname"
                        label={t("contact_firstname")}
                        rules={[{ required: true, message: "Es obligatorio el nombre" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={5} lg={5} xl={5}>
                      <Form.Item name="lastname" label={t("contact_lastname")}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={5} lg={5} xl={5}>
                      <Form.Item
                        name="email"
                        label={t("contact_email")}
                        rules={[{ required: true, message: "Es obligatorio el correo electronico!" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={5} lg={5} xl={5}>
                      <Form.Item name="documentType" label={t("contact_documentType")}>
                        <Select placeholder={t("contact_documentType")}>
                          <Select.Option value="Cédula de Ciudadanía">Cédula de Ciudadanía</Select.Option>
                          <Select.Option value="Tarjeta de Identidad">Tarjeta de Identidad</Select.Option>
                          <Select.Option value="Cédula de Extranjería">Cédula de Extranjería</Select.Option>
                          <Select.Option value="Pasaporte">Pasaporte</Select.Option>
                          <Select.Option value="Permiso Especial (PEP)">Permiso Especial (PEP)</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item name="document" label={t("contact_document")}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item name="phone" label={t("contact_phone")} extra="Ejemplo: +573187366637">
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item name="birthday" label={t("contact_birthday")} extra="Ejemplo: 2000/12/25">
                        <DatePicker format="YYYY/MM/DD" />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item name="genre" label={<>{t("contact_genre")}</>}>
                        <Select className="select-form-modal">
                          <Select.Option value="Femenino">{t("genre_female")}</Select.Option>
                          <Select.Option value="Masculino">{t("genre_male")}</Select.Option>
                          <Select.Option value="No Especifica">{t("genre_none")}</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item name="address" label={t("contact_address")}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item help="Amistad, Red Social, Tv, Radio" name="medium" label="Como nos conoció?">
                        <Select className="select-form-modal">
                          <Select.Option value="Instagram">Instagram</Select.Option>
                          <Select.Option value="Facebook">Facebook</Select.Option>
                          <Select.Option value="Blog">Blog</Select.Option>
                          <Select.Option value="Amistad">Amistad</Select.Option>
                          <Select.Option value="Google">Google</Select.Option>
                          <Select.Option value="Youtube">Youtube</Select.Option>
                          <Select.Option value="Fachada">Fachada</Select.Option>
                          <Select.Option value="Folleto Publicitario">Folleto Publicitario</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                {userExist && (
                  <Row gutter={15} style={{ paddingBottom: 10 }}>
                    <Col span={24} xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="packId3"
                        tooltip=""
                        label={
                          <>
                            {icons.guest} &nbsp; {t("contact")}
                          </>
                        }
                      >
                        <SelectUsers
                          reload={reload}
                          currentValue={userId}
                          defaultValue={userId}
                          returnData={handleUser}
                          typePack="SUBSCRIPTION"
                          title="Seleccione"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Row gutter={16} className="box-info">
                  <Col span={24} xs={24} sm={24} md={9} lg={9} xl={9}>
                    <Form.Item
                      name="packId2"
                      tooltip=""
                      label={
                        <>
                          {icons.card} &nbsp; {t("rental_service")}
                        </>
                      }
                    >
                      <SelectPacks
                        reload={reload}
                        currentValue={packId}
                        defaultValue={packId}
                        returnData={handlePack}
                        typePack="SUBSCRIPTION"
                        setPackInfo={setPackInfo}
                        kind="rental"
                        title="Seleccione"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item
                      name="quantity"
                      label={
                        <>
                          {icons.number} &nbsp; {durationFormat2(packInfo.durationMode) || ""}
                        </>
                      }
                    >
                      <InputNumber min="1" max="1000" />
                    </Form.Item>
                  </Col>

                  <Col span={24} xs={24} sm={24} md={5} lg={5} xl={5}>
                    <Form.Item
                      name="startDate"
                      label={
                        <>
                          {icons.calendar} &nbsp; {t("rental_departureDate")}
                        </>
                      }
                    >
                      <DatePicker format="YYYY/MM/DD" onChange={handleStartdate} />
                    </Form.Item>
                  </Col>

                  <Col span={24} xs={24} sm={24} md={5} lg={5} xl={5}>
                    <Form.Item
                      name="departureTime"
                      label={
                        <>
                          {icons.clock} &nbsp; {t("rental_departureTime")}
                        </>
                      }
                    >
                      <TimePicker format="HH:mm" minuteStep={15} secondStep={60} />
                    </Form.Item>
                  </Col>

                  <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item
                      name="paymentMode"
                      label={
                        <>
                          {icons.dolar}&nbsp;{t("payment_method")}
                        </>
                      }
                    >
                      <Select className="select-form-modal">
                        <Select.Option value="none">{t("payment_none")}</Select.Option>
                        <Select.Option value="cash">{t("payment_cash")}</Select.Option>
                        <Select.Option value="debitcard">{t("payment_debitcard")}</Select.Option>
                        <Select.Option value="creditcard">{t("payment_creditcard")}</Select.Option>
                        <Select.Option value="transfer">{t("payment_transfer")}</Select.Option>
                        <Select.Option value="deposit">{t("payment_deposit")}</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {data.paymentMode === "deposit" && (
                    <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item name="amount" label="Monto a pagar">
                        <InputNumber
                          style={{ fontSize: 16, fontWeight: "bold", background: "yellow" }}
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item name="paymentReference" label="Número de referencia">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                {data.quantity > 0 && packInfo.price && (
                  <Row gutter={16} className="box-info">
                    <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: 10 }}>
                      <Descriptions title="Información sobre el alquiler" column="3" bordered>
                        <Descriptions.Item label="Producto">{packInfo.name}</Descriptions.Item>
                        <Descriptions.Item label="Cantidad">
                          {data.quantity} {durationFormat2(packInfo.durationMode)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Precio">{priceFormat(packInfo.price)}</Descriptions.Item>
                        {data.paymentMode === "deposit" && (
                          <Descriptions.Item label="Resta" style={{ color: "red" }}>
                            {priceFormat(paymentData.pending) || 0}
                          </Descriptions.Item>
                        )}
                        <Descriptions.Item label="Impuesto">0%</Descriptions.Item>

                        <Descriptions.Item label="Total a pagar:">{priceFormat(paymentData.total)}</Descriptions.Item>
                      </Descriptions>
                    </Col>
                  </Row>
                )}
                <Row gutter={16}>
                  <Col span={24} style={{ paddingTop: 30 }}>
                    <hr />
                    <Space>
                      <Button
                        className="ant-btn secondary primary large"
                        key="1"
                        onClick={handleSubmit}
                        size="large"
                        style={{ float: "left" }}
                        loading={loadingSubmit}
                      >
                        Registrar
                      </Button>
                      <Button
                        key="2"
                        onClick={onClose}
                        className="ant-btn secondary error large"
                        size="large"
                        style={{ float: "left" }}
                      >
                        Cerrar
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default withRouter(CreateAppRental);
