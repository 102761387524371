import { Button } from "antd";

import { colorKind, icons } from "../../../constants/globals";

const CardServices = ({ title, subtitle, kind, handleOpen }) => {
  console.log("");
  return (
    <div
      className="card-service"
      style={{
        background: colorKind(kind),
      }}
    >
      <Button
        style={{ float: "right" }}
        className="ant-btn secondary white"
        size="small"
        onClick={() => {
          handleOpen();
        }}
      >
        {icons.plus_circle} Crear
      </Button>
      <h3 className="special-font" style={{ color: "#fff" }}>
        {title}
      </h3>
      <p className="special-font medium-font semibold">{subtitle}</p>
    </div>
  );
};

export default CardServices;
