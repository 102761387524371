import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Space, Button } from "antd";

import { withRouter } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

// COMPONENTS
import PlansTable from "../components/PlansTable";
import PlansCard from "../components/PlansCard";

import ManagePlanModal from "../components/ManagePlanModal";
import AddPrimaryButton from "../components/AddPlanButton";
import PlanHeader from "../components/PlanHeader";
import PlansHeader from "../components/PlansHeader";
import TabMenu from "../components/tabs";
import ActionBar from "../../shared/components/ActionBar";
import FiltersTable from "../components/FiltersTable";
import SearchPlansForm from "../components/SearchPlansForm";
import PlansDetails from "../components/PlansDetails";
import PlanMoreDetails from "../components/PlanMoreDetails";

import ManageBucketModal from "../../bucket/components/ManageBucketModal";

// HELPERS

import {
  handleDetailsPlan,
  handleArchivedPlan,
  handleActivePlan,
  handleClosePlan,
  handleCloseAllPlan,
} from "../functions";

// REDUX
import { setPlan, setPlanRedux, reloadPlanRedux } from "../../../redux/plan";

import ManagePlanEventModal from "../components/ManagePlanEventModal";

// REQUEST

// HOOKS
import usePlans from "../hooks/usePlans";
import useModal from "../../shared/hooks/useModal";

// CONTAINERS
import TransContainer from "../../subscribersTrans/containers/Trans";
import AppsContainer from "../../subscribersApps/containers/Apps";
import BucketContainer from "../../bucket/containers/Buckets";

// CONSTANTS
import { globals, numbers, packs, uploadType } from "../../../constants/globals";

const typePack = packs.SUBSCRIPTION;

const Plans = ({
  PlanId = null,
  isEmbed = false,
  isAnalistView = false,
  isExternalUser = false,
  appId = null,
  packId = null,
  userId = null,
  packData = {},
  isMinimal = false,
  history,
  refresh = "null",
  view = globals.TABLE,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const planStoreRedux = useSelector((store) => store.plan);
  const sessionStoreRedux = useSelector((store) => store.session);
  const [reload, setReload] = useState(0);

  const [goback, setGoback] = useState(false);

  const [isOpenManagePlanEventModal, openManagePlanEventModal, closeManagePlanEventModal] = useModal();

  const [isOpenManageBucketModal, openManageBucketModal, closeManageBucketModal] = useModal();

  const { plans, filters, setFilters, pagination, setPagination, loading, refetch } = usePlans(
    appId,
    packId,
    userId,
    typePack
  );

  const commonProps = { filters, setFilters };
  const [page, setPage] = useState(globals.PAGE_MAIN);
  const [subpage, setSubpage] = useState(numbers.TWO);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [containerMode, setContainerMode] = useState(view);
  const [type, setType] = useState(typePack);
  const [details, setDetails] = useState(false);

  const [planId, setPlanId] = useState(null);
  const [isOpenManagePlanModal, openManagePlanModal, closeManagePlanModal] = useModal();
  const [typeUploadplan, setTypeUploadPlan] = useState(uploadType.GALLERY);

  const [initialExecute, setInitialExecute] = useState(false);

  const [initialValues] = useState({
    name: "",
    duration: "",
    category: "",
    maxLimit: 1,
    minLimit: 1,
    observation: "",
    active: true,
    tag: "",
    price: "",
    avatar: "",
    public: false,
    startdate: "2020/01/01",
    closuredate: "2020/01/01",
  });

  const handleEdit = (plan) => {
    setSelectedPlan(plan);
    setPlanId(plan._id);
    setType(plan.type);
    setPlanRedux(plan)(dispatch);
    openManagePlanModal();
  };

  const handleConsolidate = () => {
    console.log("wueno");
    /*
    handleConsolidatePlan(
      { plan, t },
      {
        onSuccess: () => {
          refetch();
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
      }
    );
    */
  };

  const handleUploadPlanFile = () => {
    // if (plan._id) setPlanId(plan._id);
    setReload(Math.floor(Math.random() * 100000));
    setPlanId(planId);
    openManageBucketModal();
  };

  const handleClosePlanByGuide = (plan) => {
    setReload(Math.floor(Math.random() * 100000));
    if (plan._id) setPlanId(plan._id);
    else setPlanId(planId);
    handleClosePlan(
      { plan: { _id: plan._id || planId }, t, refetch },
      {
        onSuccess: () => reloadPlanRedux()(dispatch),
      }
    );
  };

  const handleGoto = ({ number, plan, id = null }) => {
    if (id) history.push(`/home/${number}/${id}`);
    else history.push(`/home/${number}/${plan._id}`);
  };

  // double click for more details
  const handleDetails = (plan) => {
    if (plan._id) {
      handleDetailsPlan(
        { planId: plan._id, t },
        {
          onSuccess: (response) => {
            setPlanId(plan._id);
            setPage(globals.PAGE_ONE);
            // setSubpage(numbers.TWO);
            setSelectedPlan(response.result);
            setPlanRedux(response.result)(dispatch);
          },
        }
      );
    }
  };

  const modeDetails = (plan) => {
    if (isEmbed) {
      handleGoto({ number: 22, plan });
    } else {
      handleDetails(plan);
    }
  };

  const handleResume = (plan) => {
    setSelectedPlan(plan);
    setDetails(true);
  };

  const resetFilters = () => {
    setFilters({ ...filters, status: "progress", isExternalUser });
    refetch();
  };

  useEffect(() => {
    if (initialExecute) {
      refetch();
    }
  }, [filters]);

  useEffect(() => setTimeout(() => setInitialExecute(true), 6000), []);

  useEffect(() => {
    resetFilters();
    setContainerMode(view || globals.TABLE);
    if (selectedPlan) {
      setPlan(selectedPlan)(dispatch);
      setPlanId(selectedPlan._id);
    }
  }, []);

  useEffect(() => {
    // if (selectedPlan) setPlanRedux(selectedPlan)(dispatch);
  }, [selectedPlan]);

  useEffect(() => {
    if (refresh && refresh !== "null") {
      refetch();
    }
  }, [refresh]);

  useEffect(() => {
    if (PlanId) handleDetails({ _id: PlanId });
  }, [PlanId]);

  useEffect(() => {
    if (planStoreRedux.currentPlan._id) {
      // console.log("236");
      //  handleDetails(planStoreRedux.currentPlan);
    }
  }, [planStoreRedux.reloadPlan]);

  useEffect(() => {
    if (page === globals.PAGE_MAIN) {
      setPlanId(null);
      setSelectedPlan({});
    }
  }, [page]);

  return (
    <>
      {page === globals.PAGE_MAIN && (
        <div>
          {!isEmbed && !isAnalistView && (
            <PlansHeader
              t={t}
              primaryAction={
                <Space>
                  <AddPrimaryButton
                    openManagePlanModal={openManagePlanModal}
                    isOpenManagePlanModal={isOpenManagePlanModal}
                    setContainerMode={setContainerMode}
                    containerMode={containerMode}
                    t={t}
                  />
                  <Button onClick={() => openManagePlanEventModal()} size="large" className="ant-btn secondary success">
                    Evento
                  </Button>
                </Space>
              }
            />
          )}

          {!isAnalistView && (
            <div className={isEmbed ? "" : "container-subpages"}>
              {!isMinimal && (
                <ActionBar
                  containerMode={containerMode}
                  setContainerMode={setContainerMode}
                  isMinimal={isMinimal}
                  t={t}
                  {...commonProps}
                  options={{ actions: FiltersTable }}
                >
                  <SearchPlansForm
                    resetFilters={resetFilters}
                    t={t}
                    refetch={refetch}
                    filters={filters}
                    setFilters={setFilters}
                  />
                </ActionBar>
              )}

              {containerMode === globals.TABLE && (
                <PlansTable
                  {...commonProps}
                  plans={plans}
                  pagination={pagination}
                  setPagination={setPagination}
                  loading={loading}
                  refetch={refetch}
                  initialValues={initialValues}
                  setPage={setPage}
                  setSelectedPlan={setSelectedPlan}
                  selectedPlan={selectedPlan}
                  handleDetails={handleDetails}
                  handleEdit={handleEdit}
                  details={!isEmbed ? modeDetails : () => {}}
                  handleArchivedPlan={handleArchivedPlan}
                  handleActivePlan={handleActivePlan}
                  packData={packData}
                  isEmbed={isEmbed}
                  handleResume={handleResume}
                  root={sessionStoreRedux.currentSession}
                  handleConsolidate={handleConsolidate}
                  isMinimal={isMinimal}
                  handleClosePlanByGuide={handleClosePlanByGuide}
                  t={t}
                />
              )}

              {containerMode === globals.CARD && (
                <PlansCard
                  {...commonProps}
                  plans={plans}
                  pagination={pagination}
                  setPagination={setPagination}
                  loading={loading}
                  refetch={refetch}
                  initialValues={initialValues}
                  setPage={setPage}
                  setSelectedPlan={setSelectedPlan}
                  selectedPlan={selectedPlan}
                  handleDetails={handleDetails}
                  handleEdit={handleEdit}
                  details={!isEmbed ? modeDetails : () => {}}
                  handleArchivedPlan={handleArchivedPlan}
                  handleActivePlan={handleActivePlan}
                  packData={packData}
                  isEmbed={isEmbed}
                  handleResume={handleResume}
                  root={sessionStoreRedux.currentSession}
                  handleConsolidate={handleConsolidate}
                  isMinimal={isMinimal}
                  handleClosePlanByGuide={handleClosePlanByGuide}
                  t={t}
                />
              )}

              {details && <PlansDetails t={t} open={details} setOpen={setDetails} />}
            </div>
          )}
        </div>
      )}
      {page === globals.PAGE_ONE && (
        <>
          {selectedPlan?._id && !isMinimal && !isAnalistView && (
            <PlanHeader
              refetch={refetch}
              setPage={setPage}
              selectedPlan={selectedPlan}
              handleDetails={handleDetails}
              handleEdit={handleEdit}
              setSubpage={setSubpage}
              handleArchivedPlan={handleArchivedPlan}
              handleActivePlan={handleActivePlan}
              isGoback={goback}
              handleGoto={handleGoto}
              handleClosePlanByGuide={handleClosePlanByGuide}
              handleUploadPlanFile={handleUploadPlanFile}
              isAnalistView={isAnalistView}
              t={t}
            />
          )}

          {selectedPlan?._id && isMinimal && !isExternalUser && (
            <Button className="ant-btn secondary primary" size="small" onClick={() => setPage(globals.PAGE_MAIN)}>
              Volver atrás
            </Button>
          )}
          <div className="container-subpages" style={{ marginTop: 10 }}>
            <TabMenu
              isMinimal={isMinimal}
              selectedPlan={selectedPlan}
              setSubpage={setSubpage}
              t={t}
              numbers={numbers}
            />

            {subpage === numbers.TWO && planId && (
              <div className="subcontainer-wrapper">
                <AppsContainer
                  isEmbed
                  planId={planId}
                  handleUploadPlanFile={handleUploadPlanFile}
                  handleClosePlanByGuide={handleClosePlanByGuide}
                  isMinimal={isMinimal}
                />
              </div>
            )}

            {subpage === numbers.ONE && planId && !isMinimal && (
              <div className="subcontainer-wrapper">
                <TransContainer isEmbed planId={planId} isMinimal={isMinimal} />
              </div>
            )}

            {subpage === numbers.FOUR && planId && !isMinimal && (
              <div className="subcontainer-wrapper">
                <PlanMoreDetails
                  t={t}
                  selectedPlan={selectedPlan}
                  planHeader={
                    isAnalistView ? (
                      <PlanHeader
                        refetch={refetch}
                        setPage={setPage}
                        selectedPlan={selectedPlan}
                        handleDetails={handleDetails}
                        handleEdit={handleEdit}
                        setSubpage={setSubpage}
                        handleArchivedPlan={handleArchivedPlan}
                        handleActivePlan={handleActivePlan}
                        isGoback={goback}
                        handleGoto={handleGoto}
                        handleClosePlanByGuide={handleClosePlanByGuide}
                        handleUploadPlanFile={handleUploadPlanFile}
                        isAnalistView={isAnalistView}
                        t={t}
                      />
                    ) : (
                      <></>
                    )
                  }
                />
              </div>
            )}

            {subpage === numbers.THREE && planId && (
              <div className="subcontainer-wrapper">
                <BucketContainer isEmbed planId={planId} />
              </div>
            )}
          </div>
        </>
      )}
      <ManagePlanModal
        refetch={refetch}
        planId={planId}
        setPlanId={setPlanId}
        closeModal={closeManagePlanModal}
        openModal={isOpenManagePlanModal}
        typePack={typePack}
        t={t}
      />
      <ManagePlanEventModal
        refetch={refetch}
        planId={planId}
        setPlanId={setPlanId}
        closeModal={closeManagePlanEventModal}
        openModal={isOpenManagePlanEventModal}
        typePack={typePack}
        t={t}
      />
      <ManageBucketModal
        entityProp="Plan"
        typeProp={typeUploadplan}
        entityId={planId}
        openModal={isOpenManageBucketModal}
        closeModal={closeManageBucketModal}
      />
    </>
  );
};

export default withRouter(Plans);
