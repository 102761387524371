/* eslint-disable no-sequences */
import { useState, useEffect } from "react";

import { withRouter } from "react-router-dom";

import ManagePlanFormExternal from "../components/ManagePlanFormExternal";
import ManageBucketModal from "../../bucket/components/ManageBucketModal";

// REQUEST
import { getPlanByCode } from "../requests";

// HOOKS
import useModal from "../../shared/hooks/useModal";

// CONTAINERS

// CONSTANTS
import { packs } from "../../../constants/globals";

const typePack = packs.CONTENT;

const Swal = require("sweetalert2");

const Plans = ({ PlanId = null }) => {
  const [isOpenManageBucketModal, openManageBucketModal, closeManageBucketModal] = useModal();

  const [selectedPlan, setSelectedPlan] = useState({});

  const [planId, setPlanId] = useState(null);

  const handleBucketkModal = (plan) => {
    setPlanId(plan._id);
    openManageBucketModal();
  };

  useEffect(() => {
    getPlanByCode(PlanId, {
      onSuccess: (result) => {
        setSelectedPlan(result.result);
        setPlanId(result.result._id);
        if (result.result.category === "BACKGROUND" || result.result.category === "IMAGE")
          handleBucketkModal(result.result);
      },
      onError: () => {},
    });
  }, [PlanId]);

  return (
    <>
      <>
        {selectedPlan.category === "TEXT" && (
          <div style={{ width: 800, height: 700, margin: "auto" }}>
            <ManagePlanFormExternal planId={planId} setPlanId={setPlanId} typePack={typePack} />
          </div>
        )}

        {selectedPlan.category === "LIST" && (
          <div style={{ width: 800, height: 700, margin: "auto" }}>
            <ManagePlanFormExternal planId={planId} setPlanId={setPlanId} typePack={typePack} />
          </div>
        )}

        {selectedPlan.category === "LINK" && (
          <div style={{ width: 800, height: 700, margin: "auto" }}>
            <ManagePlanFormExternal planId={planId} setPlanId={setPlanId} typePack={typePack} />
          </div>
        )}

        {selectedPlan.category === "COLOR" && (
          <div style={{ width: 800, height: 700, margin: "auto" }}>
            <ManagePlanFormExternal planId={planId} setPlanId={setPlanId} typePack={typePack} />
          </div>
        )}

        {selectedPlan.category === "HTML" && (
          <div style={{ width: 1200, height: 800, margin: "auto" }}>
            <ManagePlanFormExternal
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              planId={planId}
              setPlanId={setPlanId}
            />
          </div>
        )}
      </>

      <ManageBucketModal
        entityProp="Plancontent"
        typeProp="Gallery"
        entityId={planId}
        openModal={isOpenManageBucketModal}
        closeModal={closeManageBucketModal}
        expressMode
      />
    </>
  );
};

export default withRouter(Plans);
