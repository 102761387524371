import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";

import ActionBar from "../../shared/components/ActionBar";
import BucketsTable from "../components/BucketsTable";
import BucketsCard from "../components/BucketsCard";
import BucketsOverwriteCard from "../components/BucketsOverwriteCard";

import SearchBucketsForm from "../components/SearchBucketForm";
import ActionsUsers from "../components/ActionsBucket";
import ManageBucketModal from "../components/ManageBucketModal";
import AddUserButton from "../components/AddBucketButton";
import BucketsHeader from "../components/BucketsHeader";
import ManageBucketModal2 from "../components/ManageBucketModal2";
import ManageOverwriteBucketModal from "../components/ManageOverwriteBucketModal";

// HELPERS
import showGlobalNotification from "../../../helpers/showGlobalNotification";

// REDUX
import { setBucket, setBucketRedux } from "../../../redux/bucket";

// REQUEST
import { getBucket, archivedBucket, activeBucket } from "../requests";

// HOOKS
import useBuckets from "../hooks/useBuckets";
import useModal from "../../shared/hooks/useModal";

// CONSTANTS
import { globals } from "../../../constants/globals";

const Buckets = ({
  isEmbed = false,
  packId = null,
  planId = null,
  userId = null,
  entity = null,
  entityId = null,
  root2Id = null,
  setReloadBucket = {},
  page_ = globals.PAGE_MAIN,
  viewType = "cards",
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { buckets, filters, setFilters, pagination, setPagination, loading, refetch } = useBuckets(
    packId,
    planId,
    userId,
    root2Id
  );

  const commonProps = { filters, setFilters };
  const [page, setPage] = useState(page_);
  const [selectedBucket, setSelectedBucket] = useState({});
  const [containerMode, setContainerMode] = useState(viewType);
  const [filename, setFilename] = useState("");

  const [bucketId, setBucketId] = useState(null);
  const [isOpenManageBucketsModal, openManageBucketsModal, closeManageBucketsModal] = useModal();
  const [isOpenManageBucketModal2, openManageBucketModal2, closeManageBucketModal2] = useModal();

  const [isOpenManageBucketModal, openManageBucketModal, closeManageBucketModal] = useModal();
  const [isOpenManageOverwriteBucketModal, openManageOverwriteBucketModal, closeManageOverwriteBucketModal] =
    useModal();

  const [isOpenDetailsBucketModal, openDetailsBucketModal, closeDetailsBucketModal] = useModal();

  const [initialValues] = useState({
    mode: "Efectivo",
    fullname: "",
    email: "",
    phone: "",
    amount: 0,
    pending: 0,
    total: 0,
    appId: "",
  });

  useEffect(() => {
    setBucket(selectedBucket)(dispatch);
  }, [selectedBucket]);

  const handleEdit = (bucket) => {
    setSelectedBucket(bucket);
    setBucketId(bucket._id);
    openManageBucketModal2();
  };

  const handleOverwrite = (bucket) => {
    setFilename(bucket.image);
    openManageOverwriteBucketModal();
  };

  const handleDetails = (bucket) => {
    getBucket(bucket._id, {
      onSuccess: (response) => {
        setBucketRedux(response.result);
        setSelectedBucket(response.result);
        openDetailsBucketModal();
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
  };

  const handleArchivedConfirm = (bucket) => {
    archivedBucket(
      {
        bucket,
      },
      {
        onSuccess: () => {
          refetch();
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleArchived = (bucket) => {
    setBucketId(bucket._id);
    confirmAlert({
      title: `Eliminar ${bucket.image}`,
      message: `Esta seguro que desea eliminar ?`,
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleArchivedConfirm(bucket),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleReverseConfirm = (bucket) => {
    activeBucket(
      {
        bucket,
      },
      {
        onSuccess: () => {
          refetch();
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleReverse = (bucket) => {
    setBucketId(bucket._id);
    confirmAlert({
      title: "Desea reversar el Pago",
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleReverseConfirm(bucket),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const formatFile = (file) => {
    console.log(file.contenType);

    if (file.contentType === "application/pdf") {
      return file.title.slice(0, 80);
    }

    if (file.contentType === "image/png") {
      return <img alt={file.contenType} src={`${file.hostname}${file.image}`} width="50" />;
    }

    if (file.contentType === "image/jpeg") {
      return <img alt={file.contentType} src={`${file.hostname}${file.image}`} width="50" />;
    }

    if (file.contentType === "image/jpg") {
      return <img alt={file.contentType} src={`${file.hostname}${file.image}`} width="50" />;
    }
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <>
      {page === globals.PAGE_EXPRESS && (
        <BucketsOverwriteCard
          {...commonProps}
          buckets={buckets}
          pagination={pagination}
          setPagination={setPagination}
          loading={loading}
          refetch={refetch}
          initialValues={initialValues}
          setPage={setPage}
          setSelectedBucket={setSelectedBucket}
          selectedBucket={selectedBucket}
          handleDetails={handleDetails}
          handleEdit={handleEdit}
          details={handleDetails}
          handleArchived={handleArchived}
          handleReverse={handleReverse}
          openDetailsBucketModal={openDetailsBucketModal}
          handleOverwrite={handleOverwrite}
        />
      )}

      {page === globals.PAGE_MAIN && (
        <div>
          {!isEmbed && (
            <BucketsHeader
              t={t}
              primaryAction={
                <AddUserButton
                  isOpenManageBucketsModal={isOpenManageBucketsModal}
                  openManageBucketsModal={openManageBucketsModal}
                  containerMode={containerMode}
                  setContainerMode={setContainerMode}
                />
              }
            />
          )}

          <ActionBar t={t} {...commonProps}>
            <SearchBucketsForm filters={filters} setFilters={setFilters} refetch={refetch} t={t} />
          </ActionBar>

          {containerMode === "table" && (
            <BucketsTable
              {...commonProps}
              buckets={buckets}
              pagination={pagination}
              setPagination={setPagination}
              loading={loading}
              refetch={refetch}
              initialValues={initialValues}
              setPage={setPage}
              setSelectedBucket={setSelectedBucket}
              selectedBucket={selectedBucket}
              handleDetails={handleDetails}
              handleEdit={handleEdit}
              details={handleDetails}
              handleArchived={handleArchived}
              handleReverse={handleReverse}
              openDetailsBucketModal={openDetailsBucketModal}
              t={t}
              handleOverwrite={handleOverwrite}
              formatFile={formatFile}
            />
          )}
          {containerMode === "cards" && (
            <>
              <BucketsCard
                {...commonProps}
                buckets={buckets}
                pagination={pagination}
                setPagination={setPagination}
                loading={loading}
                refetch={refetch}
                initialValues={initialValues}
                setPage={setPage}
                setSelectedBucket={setSelectedBucket}
                selectedBucket={selectedBucket}
                handleDetails={handleDetails}
                handleEdit={handleEdit}
                details={handleDetails}
                handleArchived={handleArchived}
                handleReverse={handleReverse}
                openDetailsBucketModal={openDetailsBucketModal}
                handleOverwrite={handleOverwrite}
                formatFile={formatFile}
              />{" "}
            </>
          )}
        </div>
      )}

      <ManageBucketModal
        refetch={refetch}
        bucketId={bucketId}
        setBucketId={setBucketId}
        closeModal={closeManageBucketsModal}
        openModal={isOpenManageBucketsModal}
        t={t}
      />

      <ManageBucketModal2
        bucketId={bucketId}
        refetch={refetch}
        openModal={isOpenManageBucketModal2}
        closeModal={closeManageBucketModal2}
        setBucketId={setBucketId}
      />

      <ManageBucketModal
        entityProp={entity}
        typeProp="Gallery"
        entityId={entityId}
        openModal={isOpenManageBucketModal}
        closeModal={closeManageBucketModal}
      />

      <ManageOverwriteBucketModal
        entityProp={entity}
        typeProp="Gallery"
        entityId={entityId}
        openModal={isOpenManageOverwriteBucketModal}
        closeModal={closeManageOverwriteBucketModal}
        filename={filename}
        setReloadBucket={setReloadBucket}
        setBucketId={setBucketId}
      />
    </>
  );
};

export default Buckets;
