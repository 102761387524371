import { Table, Space, Avatar, Tooltip } from "antd";
import { CheckCircleTwoTone, EditOutlined, DeleteOutlined, UserOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import BaseTable from "../../shared/components/BasicTable";
import { constantsColor } from "../../../constants/colors";
import { birthdate } from "../../../constants/globals";

const UsersTable = ({
  selectedUser,
  setSelectedUser,
  setPage,
  history,
  users,
  refetch,
  initialValues,
  handleDetails,
  handleEdit,
  userId,
  handleArchived,
  t,
  handleInactive,
  ...restProps
}) => (
  <>
    <BaseTable {...restProps} dataSource={users} refetch={refetch} scroll={{ y: 450 }}>
      <Table.Column
        fixed="left"
        width="120px"
        align="center"
        title={t("actions")}
        dataIndex="actions"
        key="actions"
        render={(_, data) => (
          <div className="">
            <Space size="small">
              <a href="#!" onClick={() => handleDetails(data)} role="button" tabIndex={0}>
                <Tooltip placement="top" title={`${t("clickTo")} ${t("moreDetails")}`}>
                  <UserOutlined />
                </Tooltip>
              </a>
              <a href="#!" onClick={() => handleEdit(data)} role="button" tabIndex={0}>
                <Tooltip placement="top" title={`${t("clickTo")} ${t("edit")}`}>
                  <EditOutlined />{" "}
                </Tooltip>
              </a>
              <a href="#!" onClick={() => handleArchived({ user: data, t, refetch })} role="button" tabIndex={0}>
                <Tooltip placement="top" title={`${t("clickTo")} ${t("delete")}`}>
                  <DeleteOutlined />
                </Tooltip>
              </a>
              <a href="#!" onClick={() => handleInactive({ user: data, t, refetch })} role="button" tabIndex={0}>
                {data.active ? (
                  <Tooltip placement="top" title={`${t("clickToChange")} ${t("userOnHold")}`}>
                    <CheckCircleTwoTone twoToneColor={constantsColor.success} />
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title={`${t("clickToChange")} ${t("userCompleted")}`}>
                    <ClockCircleOutlined className="danger" style={{ color: constantsColor.warning }} />
                  </Tooltip>
                )}
              </a>
            </Space>
          </div>
        )}
      />
      <Table.Column
        title={t("photo")}
        dataIndex="firstname"
        key="firstname"
        sorter
        fixed="left"
        width="50px"
        responsive={["lg"]}
        render={(text, row) => <Avatar size="small" src={row.avatar} icon={<UserOutlined />} />}
      />

      <Table.Column
        title={t("fullname")}
        dataIndex="firstname"
        key="firstname"
        sorter
        fixed="left"
        width="300px"
        render={(text, row) => (
          <h5 style={{ textTransform: "uppercase" }}>{`${row.firstname} ${row.lastname || ""}`}</h5>
        )}
      />

      <Table.Column
        title={t("documentType")}
        dataIndex="documentType"
        key="documentType"
        width="150px"
        render={(text, row) => <>{row.documentType}</>}
        responsive={["lg"]}
      />

      <Table.Column
        title={t("document")}
        dataIndex="document"
        key="document"
        width="120px"
        render={(text, row) => <>{row.document}</>}
        responsive={["lg"]}
      />

      <Table.Column
        title={t("yearsold")}
        dataIndex="birthdate"
        key="birthdate"
        width="50px"
        align="center"
        render={(text, row) => <>{birthdate(row.birthdate)}</>}
        responsive={["lg"]}
      />

      <Table.Column
        title={t("email")}
        dataIndex="email"
        key="email"
        sorter
        render={(text, row) => <>{row.email}</>}
        responsive={["lg"]}
      />
      <Table.Column
        title={t("phone")}
        dataIndex="phone"
        key="phone"
        render={(text, row) => <>{row.phone}</>}
        responsive={["lg"]}
      />
    </BaseTable>
  </>
);

export default withRouter(UsersTable);
