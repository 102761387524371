import { useEffect, useState } from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";

import { getCategoryPacks } from "../requests";

const { Option } = Select;

const SelectCategoryPack = ({
  defaultValue,
  returnData,
  currentValue,
  reload,
  single = false,
  setPackInfo = null,
  kind = null,
  title = null,
  disabled = false,
}) => {
  const [data, setData] = useState(null);
  const [value, setValue] = useState(currentValue);
  const [loading, setLoading] = useState(false);
  const { currentPack: PackRedux } = useSelector((store) => store.pack);

  useEffect(() => {
    setLoading(true);
    getCategoryPacks({
      onSuccess: (res) => {
        setData(res.result);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    setValue(null);
  }, [reload]);

  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  useEffect(() => {
    if (PackRedux?._id) {
      setValue(PackRedux._id);
    }
  }, [PackRedux]);

  const handleChange = (value__) => {
    data.forEach((element) => {
      if (element._id === value__) {
        if (setPackInfo) setPackInfo(element);
      }
    });

    setValue(value__);
    returnData(value__);
  };

  return (
    <Select
      showSearch
      onChange={handleChange}
      value={value}
      loading={loading}
      disabled={disabled}
      defaultValue={defaultValue}
      style={{ width: 220 }}
      placeholder="Search to Select"
      optionFilterProp="children"
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
    >
      <Option key="0" value={null}>
        {title || "Seleccione un paquete"}
      </Option>
      {data &&
        data?.map((item) => (
          <Option key={item._id} value={item._id} style={{ fontSize: 10, textTransform: "uppercase" }}>
            {item.name}
          </Option>
        ))}
    </Select>
  );
};

export default SelectCategoryPack;
