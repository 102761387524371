/* eslint-disable no-unused-expressions */
import { PageHeader, Button, Tooltip, Space, Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import { icons, uploadType } from "../../../constants/globals";

const Analist = ({
  user = {},
  app = {},
  closeUserSection,
  handleCheckin,
  handleUpdateUserFile,
  handleGuest,
  t,
  handleUpdateUser,
  handleActivateApp,
  handleDesactivateApp,
  handleRenovateApp,
  handleArchivedConfirm,
  handleUpdateUserExtra,
  handleUpdateSubscriptionApp,
}) => (
  <PageHeader
    ghost={false}
    onBack={closeUserSection}
    style={{ marginTop: 10 }}
    title={
      <h2 className="special-font bold medium-font">
        {app?.code} {app?.packId?.name}
      </h2>
    }
    extra={[
      <Space>
        {app.isPending && (
          <Button
            size="medium"
            className="ant-btn secondary primary  large"
            onClick={() => handleActivateApp(app)}
            key="5"
            large
          >
            CONFIRMAR
          </Button>
        )}

        {(app.packId.kind === "subscription" || app.packId.kind === "event" || app.packId.kind === "rental") && (
          <Button
            size="large"
            className="ant-btn secondary error large fill"
            onClick={() => handleDesactivateApp(app)}
            key="6"
          >
            CANCELAR
          </Button>
        )}

        {!app.isPending && !app.isCanceled && (app.packId.kind === "subscription" || app.packId.kind === "rental") && (
          <Button
            size="large"
            className="ant-btn secondary primary large"
            onClick={() => handleRenovateApp(app)}
            key="7"
          >
            Renovación
          </Button>
        )}
      </Space>,
    ]}
  >
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <Row gutter={(24, 24)}>
        {app.packId.kind === "subscription" && (
          <Col xs={12} sm={12} md={8} lg={6} xl={4} className="center">
            <Tooltip title="Registrar Asistencía">
              <Button className="ant-btn secondary primary " onClick={() => handleCheckin(app)} key="2">
                {icons.rayo} Registrar asistencia
              </Button>
            </Tooltip>{" "}
          </Col>
        )}

        {app.packId.kind === "subscription" && (
          <Col xs={12} sm={12} md={8} lg={6} xl={4} className="center">
            <Tooltip title="Registrar un invitado">
              <Button className="ant-btn secondary primary " onClick={() => handleGuest(app)} key="6">
                {icons.guest} Agregar un invitado
              </Button>
            </Tooltip>{" "}
          </Col>
        )}

        {/*
        {(app.packId.kind === "rental" || app.packId.kind === "subscription") && (
          <Col xs={12} sm={12} md={8} lg={3} xl={3} className="center">
            <Tooltip title="Actualizar Foto">
              <Button
                className="ant-btn secondary info "
                onClick={() => handleUpdateUserFile({ app, type: uploadType.AVATAR })}
                key="3"
              >
                {icons.foto} Foto perfil
              </Button>
            </Tooltip>
          </Col>
        )} */}

        {(app.packId.kind === "rental" || app.packId.kind === "subscription") && (
          <Col xs={12} sm={12} md={8} lg={3} xl={3} className="center">
            {" "}
            <Tooltip title="Adjuntar un archivo (Imagen, Pdf, Excel)">
              <Button
                className="ant-btn secondary info "
                onClick={() => handleUpdateUserFile({ app, type: uploadType.GALLERY })}
                key="7"
              >
                Adjuntar archivo
              </Button>{" "}
            </Tooltip>
          </Col>
        )}

        <Col xs={12} sm={12} md={8} lg={6} xl={4} className="center">
          <Tooltip title="Editar datos del usuario">
            <Button size="medium" className="ant-btn secondary primary " onClick={() => handleUpdateUser(app)} key="4">
              {icons.edit} Editar contacto
            </Button>
          </Tooltip>
        </Col>

        {app.packId.kind === "subscription" && (
          <Col xs={12} sm={12} md={8} lg={6} xl={4} className="center">
            <Tooltip title="Editar datos del usuario">
              <Button
                size="medium"
                className="ant-btn secondary primary "
                onClick={() => handleUpdateSubscriptionApp(app)}
                key="4"
              >
                {icons.edit} Editar aplicación
              </Button>
            </Tooltip>
          </Col>
        )}
        {/*
        {app.packId.kind === "subscription" && (
          <Col xs={12} sm={12} md={8} lg={6} xl={4} className="center">
            <Tooltip title="Editar datos del usuario">
              <Button
                size="medium"
                className="ant-btn secondary primary"
                onClick={() => handleUpdateUserExtra(app)}
                key="5"
              >
                {icons.edit} Datos extras
              </Button>
            </Tooltip>
          </Col>
        )}
        */}
        <Col xs={12} sm={12} md={8} lg={6} xl={4} className="center">
          <Tooltip title="Eliminar aplicación">
            <Button
              size="medium"
              className="ant-btn secondary error "
              onClick={() => handleArchivedConfirm(app)}
              key="6"
            >
              {icons.edit} Eliminar esta aplicación
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </div>
  </PageHeader>
);

export default withRouter(Analist);
