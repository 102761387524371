import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Row, Col, Input, Select, Spin, DatePicker, Tabs, Divider, Button } from "antd";
import { saveUser, getUser } from "../requests";
import { saveApp } from "../../subscribersApps/requests";
import { globals, icons } from "../../../constants/globals";

import showGlobalNotification from "../../../helpers/showGlobalNotification";
import BaseModal from "../../shared/components/Modal/BaseModal";
import { setUserRedux, reloadRedux as reloadUserRedux } from "../../../redux/user";

const moment = require("moment");

const { TabPane } = Tabs;

const { Option } = Select;

const defaultValues = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  address: "",
  birthdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  departurePlace: "",
  category: "",
  duration: "",
  active: true,
  public: false,
  price: 0,
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD HH:mm"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD HH:mm"),
  planId: null,
  refererId: null,
  medium: "",
};

const config = {
  rules: [
    {
      type: "object",
      required: true,
      message: "Please select time!",
    },
  ],
};

const ManageUserModal = ({ userId, openModal, closeModal, refetch, t, setUserId, page, setReload_ = null }) => {
  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);
  const [form] = Form.useForm();
  const [selectedUser, setSelectedUser] = useState(defaultValues);
  const [loaderForm, setLoaderForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [reload, setReload] = useState(0);

  const handleReset = () => {
    setSelectedUser(defaultValues);
    setReload(Math.floor(Math.random() * 100000));
  };

  useEffect(() => {
    if (openModal) {
      if (userId) {
        setMode("edit");
      } else {
        setMode("new");
      }
    } else {
      handleReset();
      setMode(null);
      if (page !== globals.PAGE_ONE) setUserId(null);
    }
  }, [openModal]);

  useEffect(() => {
    if (mode === "new") {
      handleReset();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      setLoaderForm(true);
      getUser(userId, {
        onSuccess: (response) => {
          setUserRedux(response.user)(dispatch);
          setSelectedUser({
            ...response.user,
            startdate: moment(response.user.startdate, "YYYY/MM/DD") || moment("2020/12/12", "YYYY/MM/DD"),
            closuredate: moment(response.user.closuredate, "YYYY/MM/DD") || moment("2020/12/12", "YYYY/MM/DD"),
            birthdate: moment(response.user.birthdate, "YYYY/MM/DD") || moment("2020/12/12", "YYYY/MM/DD"),
            password: "",
          });
          setLoaderForm(false);
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  }, [mode]);

  useEffect(() => {
    form.setFieldsValue(selectedUser);
  }, [form, selectedUser]);

  const handleCloseModal = () => {
    refetch();
    closeModal();
  };

  const onFinish = (values) => {
    const data = { ...selectedUser, ...values };
    saveUser(
      {
        ...data,
        userId,
        rootId: sessionStoreRedux.currentSession_id,
      },
      {
        onSuccess: (response) => {
          reloadUserRedux()(dispatch);
          setUserRedux(response.usuario)(dispatch);
          if (setReload_) setReload_(Math.floor(Math.random() * 100000));

          if (data.planId) {
            saveApp(
              {
                ...data,
                userId,
                rootId: sessionStoreRedux.currentSession_id,
              },
              {
                onSuccess: () => {
                  showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
                },
                onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
                onFinally: () => {
                  handleCloseModal();
                },
              }
            );
          }
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => handleCloseModal(),
      }
    );
  };

  const callback = (key) => {
    console.log(key);
  };

  const onValuesChange = (values) => {
    setSelectedUser({ ...selectedUser, ...values });
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  return (
    <BaseModal
      isOpenModal={openModal}
      closeModal={handleCloseModal}
      onCancel={handleCloseModal}
      onConfirm={() => handleSubmit()}
      title={userId ? `${t("edit")} ${t("contact")}` : `${t("new")} ${t("contact")}`}
      width={1000}
      confirmText={t("save")}
      isCloseModal
      top={10}
      icon={icons.edit}
    >
      <span className="code">9765556987</span>
      <Form
        form={form}
        name="manage-category-form-modal"
        initialValues={defaultValues}
        className="manage-modal"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        onValuesChange={onValuesChange}
      >
        <div className="layout-form-modal entity-450 entity-small">
          {loaderForm ? (
            <>
              <Spin tip={t("loading")} />
            </>
          ) : (
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="Datos Generales" key="1">
                <>
                  <Row gutter={16}>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="firstname"
                        tooltip="Nombres"
                        label="Nombre"
                        rules={[
                          {
                            required: true,
                            message: `Por favor ingrese los Nombres`,
                          },
                        ]}
                      >
                        <Input placeholder="" value={selectedUser.firstname} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="lastname"
                        label="Apellidos"
                        rules={[
                          {
                            required: true,
                            message: `Por favor ingrese los Apellidos`,
                          },
                        ]}
                      >
                        <Input value={selectedUser.lastname} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="email"
                        label={t("email")}
                        rules={[{ required: true, message: `Por favor ingrese el email` }]}
                      >
                        <Input value={selectedUser.email} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item
                        tooltip="Es importante mantener el formato Ejemplo: +573186855543, para el correcto envio de los mensajes de texto"
                        help="Formato: +573186855543"
                        name="phone"
                        label={t("phone")}
                      >
                        <Input style={{ width: "100%" }} value={selectedUser.phone} />
                      </Form.Item>
                    </Col>

                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item name="documentType" label="Documento">
                        <Select className="select-form-modal">
                          <Option value="Cédula de Ciudadanía">Cédula de Ciudadanía</Option>
                          <Option value="Tarjeta de Identidad">Tarjeta de Identidad</Option>
                          <Option value="Cédula de Extranjería">Cédula de Extranjería</Option>
                          <Option value="Pasaporte">Pasaporte</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item name="document" label="ID">
                        <Input value={selectedUser.document} />
                      </Form.Item>
                    </Col>
                  </Row>{" "}
                </>
              </TabPane>

              <TabPane tab="Informacion adicional" key="2">
                <Row gutter={16}>
                  <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item name="observation" label={t("observation")}>
                      <Input value={selectedUser.observation} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item name="birthdate" label={t("birthdate")} {...config}>
                      <DatePicker format="YYYY/MM/DD" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                    <Form.Item help="Amistad, Red Social, Tv, Radio" name="medium" label="Como nos conoció?">
                      <Select className="select-form-modal">
                        <Option value="Instagram">Instagram</Option>
                        <Option value="Facebook">Facebook</Option>
                        <Option value="Blog">Blog</Option>
                        <Option value="Amistad">Amistad</Option>
                        <Option value="Google">Google</Option>
                        <Option value="Youtube">Youtube</Option>
                        <Option value="Fachada">Fachada</Option>
                        <Option value="Folleto Publicitario">Folleto Publicitario</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item name="address" label={t("address")}>
                      <Input value={selectedUser.address} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item name="city" label={t("city")}>
                      <Input value={selectedUser.city} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item name="region" label={t("region")}>
                      <Input value={selectedUser.region} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item name="country" label={t("country")}>
                      <Input value={selectedUser.country} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item name="bodylesson" label="Alguna lesión en el cuerpo">
                      <Input autocomplete="off" placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item name="alergies" label="Alguna alergía">
                      <Input autocomplete="off" placeholder="" />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tab="Cambiar Password" key="3">
                <Divider orientation="left">Información Adicional</Divider>
                <Row gutter={16}>
                  <Col span={8} xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Form.Item help="Sino desea actualizar, dejar el campo vacio" name="password" label={t("password")}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          )}
        </div>
      </Form>
    </BaseModal>
  );
};

ManageUserModal.defaultProps = {
  setUserId: () => {},
};

export default ManageUserModal;
