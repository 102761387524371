/* eslint-disable no-unused-expressions */
/* eslint-disable no-else-return */
import { Tag } from "antd";

import {
  SendOutlined,
  CameraOutlined,
  RightSquareFilled,
  SettingOutlined,
  FontColorsOutlined,
  FormatPainterOutlined,
  CheckCircleOutlined,
  Html5Outlined,
  SmileOutlined,
  UnorderedListOutlined,
  WarningOutlined,
  LikeOutlined,
  FieldTimeOutlined,
  CloseCircleOutlined,
  PieChartOutlined,
  RotateRightOutlined,
  ReloadOutlined,
  CheckCircleTwoTone,
  EditOutlined,
  DeleteOutlined,
  BarsOutlined,
  LinkOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  QrcodeOutlined,
  PlusCircleOutlined,
  DollarCircleOutlined,
  PaperClipOutlined,
  SolutionOutlined,
  SearchOutlined,
  ArrowLeftOutlined,
  IdcardOutlined,
  FieldNumberOutlined,
  QuestionCircleOutlined,
  FullscreenOutlined,
  UserAddOutlined,
  ThunderboltOutlined,
  SubnodeOutlined,
  EyeOutlined,
  MailOutlined,
  HomeOutlined,
  StopOutlined,
  GlobalOutlined,
  LogoutOutlined,
  FireFilled,
  PhoneOutlined,
  AppstoreFilled,
  RightCircleFilled,
  PhoneTwoTone,
  CalendarTwoTone,
  CopyOutlined,
  FrownOutlined,
} from "@ant-design/icons";

import bot from "../assets/robot.png";
import LoadingIcon from "../assets/loading.gif";
import productsIcon from "../assets/productos.png";
import activitiesIcon from "../assets/actividades.png";
import rentalsIcon from "../assets/alquileres.png";
import planesIcon from "../assets/planes.png";

export const colors = Object.freeze({
  success: "#52c41a",
  warning: "#E01E5A",
  error: "#eb2f96",
  info: "#eb2f96",
  default: "#eb2f96",
});

export const colorsSystem = Object.freeze({
  black: "#1d1d1d",
  purple: "#611f69",
  white: "#f6efe8",
  yellow: "#fea93c",
  red: "#b33536",
  blue: "#2692c9",
  gray: "#EDF2F5",
});

const moment = require("moment");

moment.locale("en", {
  meridiem: Function,
});

export const colorKind = (value) => {
  if (value === "subscription") return "linear-gradient(112.081deg, #512696 9.69792%, #171a2a 96.2917%)";
  else if (value === "rental") return "linear-gradient(112.081deg, #233266 6.49074%, #25356c 96.2917%)";
  else if (value === "event") return "linear-gradient(112.081deg, #188482 6.49074%, #043838 96.2917%)";
  else if (value === "product") return "linear-gradient(112.081deg, #0e70aa 5.73611%, #08507c 96.2917%)";
  else return value;
};

export const gradientCard = () =>
  "linear-gradient(109.53407787792617deg, rgba(68, 96, 189,1) 26.730902777777775%,rgba(68, 96, 189,1) 27.468171296296294%,rgba(29, 168, 166,1) 70.96701388888889%);";

export const colorNetoKind = (value) => {
  if (value === "subscription") return "#4e2495";
  else if (value === "rental") return "#5274E8";
  else if (value === "event") return "#199896";
  else if (value === "product") return "#32A8E7";
  else return value;
};

export const icons = Object.freeze({
  open: <RightSquareFilled />,
  setting: <SettingOutlined />,
  pending: <FieldTimeOutlined />,
  cancel: <StopOutlined />,
  home: <HomeOutlined />,
  reload: <ReloadOutlined />,
  check: <CheckCircleOutlined />,
  check_enabled: <CheckCircleTwoTone twoToneColor={colors.success} />,
  check_disabled: <CheckCircleTwoTone twoToneColor={colors.default} />,
  icon_plans: <FireFilled style={{ color: colorNetoKind("subscription") }} />,
  icon_events: <FireFilled style={{ color: colorNetoKind("event") }} />,
  icon_products: <FireFilled style={{ color: colorNetoKind("product") }} />,
  icon_rental: <FireFilled style={{ color: colorNetoKind("rental") }} />,
  edit: <EditOutlined />,
  delete: <DeleteOutlined />,
  showDetails: <BarsOutlined />,
  link: <LinkOutlined />,
  clock: <ClockCircleOutlined />,
  calendar: <CalendarOutlined />,
  arrowUp: <ArrowUpOutlined />,
  arrowDown: <ArrowDownOutlined />,
  qr: <QrcodeOutlined />,
  plus_circle: <PlusCircleOutlined />,
  send: <SendOutlined />,
  dolar: <DollarCircleOutlined />,
  graph: <PieChartOutlined />,
  triste: <FrownOutlined />,
  feliz: <SmileOutlined />,
  upload: <PaperClipOutlined />,
  apps: <SolutionOutlined />,
  close: <CloseCircleOutlined />,
  search: <SearchOutlined />,
  back: <ArrowLeftOutlined />,
  color: <FormatPainterOutlined />,
  foto: <CameraOutlined />,
  card: <IdcardOutlined />,
  number: <FieldNumberOutlined />,
  question: <QuestionCircleOutlined />,
  enterDetails: <FullscreenOutlined />,
  phone: <PhoneOutlined />,
  guest: <UserAddOutlined />,
  rayo: <ThunderboltOutlined />,
  renovate: <SubnodeOutlined />,
  eyes: <EyeOutlined />,
  email: <MailOutlined />,
  like: <LikeOutlined />,
  internet: <GlobalOutlined />,
  logout: <LogoutOutlined />,
  services: <AppstoreFilled />,
  goto: <RightCircleFilled />,
  PhoneTwoTone_enabled: <PhoneTwoTone twoToneColor="green" />,
  PhoneTwoTone_disabled: <PhoneTwoTone twoToneColor={colorsSystem.red} />,
  CalendarTwoTone_enabled: <CalendarTwoTone twoToneColor="green" />,
  CalendarTwoTone_disabled: <CalendarTwoTone twoToneColor={colorsSystem.red} />,
  loading: LoadingIcon,
  productsIcon,
  activitiesIcon,
  rentalsIcon,
  duplicate: <CopyOutlined />,
  planesIcon,
});

export const images = Object.freeze({
  icon_app: bot,
  icon_plan: bot,
  icon_trans: bot,
  icon_user: bot,
});

export const globals = Object.freeze({
  // BACKEND: "http://localhost:2001",
  MASTER_INSTANCE_KEY: "$32pSHTFvb$10$qeqQmVzBz9.ZeYe2wrh2cTWbjzBz9.ZeYe2wrh2b$10$qeqnKuu",
  MASTER_INSTANCE_ID: "61149dbb3290d3ff15c6461f",
  // BACKEND: "https://cluufweb-backend.herokuapp.com",
  BACKEND: "https://my-app-3ocg5.ondigitalocean.app",
  CLUUFNET: "https://d28850bg6enx1h.cloudfront.net",
  CLUUF: "cluuf",
  PAGE_ONE: "one",
  PAGE_TWO: "two",
  PAGE_MAIN: "main",
  PAGE_EXPRESS: "express",
  TABLE: "table",
  CARD: "cards",
  CALENDAR: "calendar",
  ROLES: {
    USER: "USER",
    ADMIN: "ADMIN",
    SUPERADMIN: "SUPERADMIN",
    SUPERVACA: "SUPERVACA",
  },
});

export const routes = Object.freeze({
  BUCKET: `${globals.BACKEND}/uploads`,
  API_BUCKET: `${globals.BACKEND}/bucket`,
  API_OVERWRITEBUCKET: `${globals.BACKEND}/bucketOverwrite`,
  API_UPLOADIMAGECONTENT: `${globals.BACKEND}/bucketUploadImageContent`,
});

export const uploadType = Object.freeze({
  AVATAR: "Avatar",
  GALLERY: "Gallery",
});

export const packs = Object.freeze({
  LINK: "LINK",
  TEXT: "TEXT",
  IMAGE: "IMAGE",
  HTML: "HTML",
  SERVICE: "SERVICE",
  SUBSCRIPTION: "SUBSCRIPTION",
  EVENT: "EVENT",
  TOUR: "TOUR",
  CONTENT: "CONTENT",
  COMPANY: "COMPANY",
});

export const packClasification = Object.freeze({
  EVENT: {
    EVENT: "event",
    TOUR: "tour",
    WORKSHOP: "workshop",
    MEETING: "meeting",
    PARTY: "party",
    CONFERENCE: "conference",
  },
  RENTAL: {
    SPACERENTAL: "spacerental",
    CARRENTAL: "carrental",
    CLOTHESRENTAL: "clothesrental",
  },
  SUBSCRIPTION: {
    FORM: "form",
    TRAINING: "training",
    COWORK: "cowork",
    GYM: "gym",
    YOGA: "yoga",
    SPORT: "sport",
    DANCE: "dance",
    LANGUAGE: "language",
    MEDITATION: "meditation",
  },
  PRODUCT: {
    FOOD: "food",
    CLOTHES: "clothes",
    ACCESSORY: "accessory",
    DRINKS: "drinks",
  },
});

export const STATUS_TRANS = Object.freeze({
  PENDING: "Por Confirmar",
  DONE: "Confirmado",
  CANCELED: "Anulado",
});

export const PAYMENT_TYPE = Object.freeze({
  CASH: "Efectivo",
  TRANSFER: "Transferencía",
  DEBITCARD: "Tarjeta de Débito",
  CREDITCARD: "Tarjeta de Crédito",
  NEQUI: "Nequi",
  WOMPI: "Wompi",
  OTHER: "otro",
});

export const STATUS_APP = Object.freeze({
  PENDING: "Por Confirmar",
  DONE: "Confirmado",
  CANCELED: "Anulado",
  CLOSE: "close",
  OPEN: "open",
  INWAIT: "inwait",
  CANCEL: "cancel",
  ALL: "all",
});

export const STATUS_APP_BY_DATE = Object.freeze({
  ISPENDING: "isPending",
  ISPROGRESS: "isProgress",
  ISCANCELED: "isCanceled",
  ISCLOSED: "isClosed",
  ISSTARTING: "isStarting",
  ISENDING: "isEnding",
});

export const STATUS_PLAN = Object.freeze({
  PENDING: "Por Confirmar",
  DONE: "Confirmado",
  CANCELED: "Anulado",
});

export const numbers = Object.freeze({
  ZERO: "0",
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR: "4",
  FIVE: "5",
  SIX: "6",
  SEVEN: "7",
  EIGHT: "8",
  NINE: "9",
  TEN: "10",
  ELEVEN: "11",
  TWELVE: "12",
  THIRTEEN: "13",
  FOURTEEN: "14",
  FIVETEEN: "15",
  SIXTEEN: "16",
});

export const logs = Object.freeze({
  WEBSITE_UPDATED: "WEBSITE UPDATED",
});

export const dateFormatYYYYMMDD = (value) => moment(value, "YYYY-MM-DD").format("dddd, DD/MM/YYYY");
export const priceFormat = (text) => `$ ${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
export const dateFormat = (date) => moment(date).format("L");
export const dateFormat1 = (date) => moment(date).format("LLL");
export const dateFormat2 = (date) => moment(date).format("LL");
export const dateFormat4 = (date) => moment(date).format(" dddd MMMM Do YYYY"); // July 2nd 2021, 3:19:33 am
export const timeFormat3 = (date) => moment(date, "HH:mm").format("h:mm a"); // July 2nd 2021, 3:19:33 am
export const dateFormat5 = (date) => moment(date).format(" dddd D/MM/YYYY"); // July 2nd 2021, 3:19:33 am
export const dateFormat6 = (date) => moment(date).format(" D/MM/YYYY"); // July 2nd 2021, 3:19:33 am
export const dateFormat7 = (date) => moment(date).format(" D/MM/YYYY h:mm a"); // July 2nd 2021, 3:19:33 am
export const dateFormat8 = (date) => moment(date).format("llll");
export const excelFilename = (date) => moment(date).format(" D-MM-YYYY-h:mm"); // July 2nd 2021, 3:19:33 am

export const horaFormat = (hora) => {
  if (hora) {
    return moment(hora, "HH:mm a").format("HH:mm a");
  }

  return "";
}; // July 2nd 2021, 3:19:33 am

export const isCheckinToday = (item) => {
  if (item.checkins.length > 0) {
    if (moment().diff(moment(String(item.checkins[0]).substr(0, 10), "DD/MM/YYYY"), "days") === 0) {
      return "Hoy tuvo asistencia";
    }
  }
  return "";
};

export const cutWords = (text, longitud = 27) => {
  if (String(text).length > 25) {
    return `${String(text).substr(0, longitud)}...`;
  } else {
    return text;
  }
};

export const durationFormat = (duration) => {
  if (duration === "MONTHS") return "M";
  if (duration === "DAYS") return "d";
  if (duration === "HOURS") return "h";
};

export const durationFormat2 = (duration) => {
  if (duration === "MONTHS") return "Meses";
  if (duration === "DAYS") return "Días";
  if (duration === "HOURS") return "Horas";
};

export const kindFormat = (value) => {
  if (value === "subscription") return "Subscripción";
  else if (value === "event") return "Actividad";
  else if (value === "rental") return "Alquiler";
  else if (value === "product") return "Venta";
  else return value;
};

export const datesRangesFormat = (ranges) => {
  if (ranges === "today") return "Hoy";
  if (ranges === "thisweek") return "Esta Semana";
  if (ranges === "yesterday") return "Ayer";
  if (ranges === "thismonth") return "Este Mes";
  if (ranges === "lastmonth") return "Mes pasado";
};

export const paymentFormat = (value) => {
  if (value === "creditcard") return "Tarjeta de Crédito";
  else if (value === "debitcard") return "Tarjeta de Débito";
  else if (value === "cash") return "Efectivo";
  else if (value === "transfer") return "Transferencía";
  else if (value === "wompi") return "Wompi";
  else if (value === "nequi") return "Nequi";
  else return value;
};

export const eventStatusFormat = (value) => {
  if (value === "open") return icons.check;
  else if (value === "close") return icons.renovate;
  else if (value === "cancel") return icons.back;
  else if (value === "inwait") return icons.clock;
  else return value;
};

export const eventStatusFormatWord = (value) => {
  if (value === "open") return <Tag color="green">Confirmado</Tag>;
  else if (value === "close") return <Tag color="green">Finalizo</Tag>;
  else if (value === "cancel") return <Tag color="gray">Cancelada</Tag>;
  else if (value === "inwait") return <Tag color="volcano">Pendiente por confirmar</Tag>;
  else return value;
};

export const formatTypePayment = (value) => {
  if (value === "inbound") return "Ingreso";
  else if (value === "outbound") return "Egreso";
  else return value;
};

export const eventCategoryFormat = (value) => {
  if (value === "app_renew") return "Renovación de Aplicación";
  else if (value === "status_changed") return "Cambio de Estatus de Aplicación";
  else if (value === "USER_CHECKIN") return "Asistencía";
  else if (value === "GUEST_CHECKIN") return "Invitación";
  else return value;
};

export const birthdate = (date) => {
  const nacimiento = moment(date);
  const hoy = moment();
  const anios = hoy.diff(nacimiento, "years");
  return anios;
};

export const isFutureDate = (date) => {
  if (moment(date).format("YYYY/MM/DD") >= moment().format("YYYY/MM/DD")) {
    return true;
  }
  return false;
};

export const isRangeDate = (desde, hasta) => {
  if (
    moment(desde).format("YYYY/MM/DD") <= moment().format("YYYY/MM/DD") &&
    moment(hasta).format("YYYY/MM/DD") >= moment().format("YYYY/MM/DD")
  ) {
    return true;
  }
  return false;
};

export const iconCategory = (category) => {
  if (category === "IMAGE") return <CameraOutlined />;
  else if (category === "TEXT") return <FontColorsOutlined />;
  else if (category === "COLOR") return <FormatPainterOutlined />;
  else if (category === "HTML") return <Html5Outlined />;
  else if (category === "LIST") return <UnorderedListOutlined />;
  return "*";
};

export const tagAppStatus = (category) => {
  if (category === STATUS_APP.OPEN) return <Tag color="green">Abierta</Tag>;
  else if (category === STATUS_APP.CANCEL) return <Tag color="red">Cancelada</Tag>;
  else if (category === STATUS_APP.CLOSE) return <Tag color="red">Finalizada</Tag>;
  else if (category === STATUS_APP.INWAIT) return <Tag color="blue">En Espera</Tag>;
  return "*";
};

export const tagTransStatus = (category) => {
  if (category === STATUS_APP.OPEN) return <Tag color="green">Abierta</Tag>;
  else if (category === STATUS_APP.CANCEL) return <Tag color="red">Cancelada</Tag>;
  else if (category === STATUS_APP.CLOSE) return <Tag>Finalizada</Tag>;
  else if (category === STATUS_APP.INWAIT) return <Tag color="blue">En Espera</Tag>;
  return "*";
};

export const formatProgressApp = (appData) => {
  if (appData.kind === "product") return `${appData?.quantity} x ${priceFormat(appData.amount)}`;
  if (appData.isPending) return <Tag color="red">Pendiente</Tag>;
  else if (appData.isProgress) return <Tag color="green">En Progreso</Tag>;
  else if (appData.isStarting) return <Tag color="blue">Comienza a Futuro</Tag>;
  else if (appData.isClosed) return <Tag color="blue">Finalizada</Tag>;
  else if (appData.isCanceled) return <Tag color="blue">Cancelada</Tag>;
};

export const formatEndingApp = (appData) => {
  if (appData.isEnding) return <Tag color="volcano">Por finalizar</Tag>;
  return "";
};

export const formatStartingApp = (appData) => {
  if (appData.isStarting) <Tag color="green">Por Comenzar</Tag>;
  return "";
};

export const formaterStatusApp = (app) => {
  if (app.isPending) return "Pendiente por Confirmación";
  if (app.isProgress) return "En progreso";
  if (app.isClosed) return "Finalizada";
  if (app.isCancel) return "Anulada";
  if (app.isStarting) return "Comienza a futuro";
};

export const formaterStatusTrans = (transPending) => {
  if (transPending) return "Pendiente por pago";
  return "Pagado";
};

export const formaterFreePlan = (planFree) => {
  if (planFree) return "Servicio gratuito";
  return "Servicio pago";
};

export const formaterModeTrans = (transMode) => {
  if (transMode === "cash") return "Efectivo";
  if (transMode === "card") return "Tarjeta de crédito";
  if (transMode === "deposit") return "Deposito";
  if (transMode === "debitcard") return "Tarjeta de débito";
  if (transMode === "creditcard") return "Tarjeta de crédito";
  if (transMode === "transfer") return "Transferencía";
};

export const clasificationFormat = (value) => {
  if (value === "tour") return "Tour";
  if (value === "gym") return "Gimnasio";
  if (value === "coworking") return "Cowork";
  if (value === "spacerental") return "Alquiler de espacio";
  if (value === "accessory") return "Venta de accesorios";
  if (value === "carrental") return "Alquiler de Vehiculo";
  if (value === "workshop") return "Sesiones";
  if (value === "form") return "Formulario Web";
  return value;
};

export const formatSuccess = (number) => {
  if (number > 0) return <span style={{ color: "#575757" }}>{number}</span>;
  return number;
};

export const formatWarning = (number) => {
  if (number > 0) return <span style={{ color: "volcano" }}>{number}</span>;
  return number;
};

export const validateImage = (img = "test") => {
  const imageparts = String(img).split("/");

  if (img.indexOf("lospatioshb") > -1) {
    return img;
  }

  if (img.indexOf("public") > -1) {
    console.log(`${routes.BUCKET}/${imageparts[3]}/${imageparts[4]}`, img);
    return `${routes.BUCKET}/${imageparts[3]}/${imageparts[4]}`;
  } else if (img.indexOf("picture") > -1) {
    return img;
  }

  if (img.length > 1) {
    //  const instanceId = localStorage.getItem("instanceId");

    return `${routes.BUCKET}/${imageparts[3]}/${imageparts[4]}`;
  }
  return "https://autoclinicaelchino.com/wp-content/uploads/2019/01/default-image.png";
};

export const validateImageBucket = (img = "") => {
  if (img.indexOf("picture") > -1) {
    return `https://cluuf.s3.amazonaws.com/${img}`;
  }

  return "https://autoclinicaelchino.com/wp-content/uploads/2019/01/default-image.png";
};

export const isRoleAdmin = (role) => {
  if (role === "SUPERADMIN") return true;
  return false;
};

export const isRoleAnalist = (role) => {
  if (role === "ADMIN") return true;
  return false;
};

export const isModuleValid = (moduleName = "", instanceStoreRedux) => {
  if (instanceStoreRedux) {
    if (instanceStoreRedux && instanceStoreRedux?.currentInstance.modules !== undefined) {
      const value = instanceStoreRedux?.currentInstance.modules.find((x) => x === moduleName);
      if (value !== undefined) return true;
      return false;
    }
  } else {
    return false;
  }
};

export const modules = Object.freeze({
  EVENT: "event",
  RENTAL: "rental",
  PRODUCT: "product",
  SUBSCRIPTION: "subscription",
  SERVICE: "service",
  WEBCONTENT: "webcontent",
  INVENTARY: "inventary",
  BLOG: "blog",
  ANALYTICSADVANCED: "analyticsAdvanced",
  SCHEDULE: "schedule",
  CAJA: "caja",
  TOUR: "tour",
  CONTACT: "contact",
  COMPANY: "company",
});

export const moneyFormat = (text) => `$ ${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

export const jsonFormatConsolidateApp = ({ data, isEmbed = false, appData = {} }) => {
  if (!isEmbed) return "";

  if (!(appData && appData?._id)) {
    if (data && data?.consolidatedApps) {
      return JSON.parse(data?.consolidatedApps);
    }
  }

  return false;
};

export const jsonFormatConsolidateTrans = ({ data, isEmbed = false, appData = {} }) => {
  if (!isEmbed) return "";

  if (!(appData && appData?._id)) {
    if (data && data?.consolidatedTrans) {
      return JSON.parse(data?.consolidatedTrans);
    }
  }
  return false;
};

export const consolidateAppValue = ({ data }) => {
  if (data && data?.consolidatedApps) {
    return JSON.parse(data?.consolidatedApps);
  }

  return false;
};

export const consolidateApp = (data) => {
  if (data && data?.consolidatedApps) {
    return JSON.parse(data?.consolidatedApps);
  }
  return false;
};

export const consolidateTrans = (data) => {
  if (data && data?.consolidatedTrans) {
    return JSON.parse(data?.consolidatedTrans);
  }
  return false;
};

export const urlLandingPage = ({
  alias,
  packId,
  planId,
  rootEmail,
  campaign = "0",
  refererUserId = "0",
  refererAppId = "0",
}) =>
  `${globals.CLUUFNET}/app_s.html?q=${packId}&agency=${alias}&type=suscriber&p=${planId}&fclt=${rootEmail}&utmc=${campaign}&raId=${refererAppId}&ruId=${refererUserId}`;

export const urlLandingPageActivate = ({
  alias,
  packId,
  planId,
  rootEmail,
  campaign = "0",
  refererUserId = "0",
  refererAppId = "0",
}) =>
  `${globals.CLUUFNET}/app_s1.html?q=${packId}&agency=${alias}&type=suscriber&p=${planId}&fclt=${rootEmail}&utmc=${campaign}&raId=${refererAppId}&ruId=${refererUserId}`;

export const qrCodePage = ({ alias, planId, mode = "local", refererUserId = 0, refererAppId = 0, campaign = "QR" }) =>
  `/qr/${alias}/${planId}/${mode}/${campaign}/${refererUserId}/${refererAppId}`;

export const calculateExpirationPlan = (closureDate) => {
  const total = moment(closureDate).diff(moment.now(), "days");
  if (total < 1) return <span style={{ color: "red", fontWeight: "bold" }}>Vencido</span>;
  if (total <= 10) return <span style={{ color: "red", fontWeight: "bold" }}>{total} Días Restantes</span>;
  return "";
};

export const calculateExpirationPlan2 = (closureDate) => moment(closureDate).diff(moment.now(), "days");

export const avartarURL = () => "https://my-app-3ocg5.ondigitalocean.app/avatarSuccess.jpeg";

export const actionsUsers = {
  CHECKIN: {
    number: "1",
    title: "Registro de asistencía",
  },
  GUEST: {
    number: "2",
    title: "Registro de invitados",
  },
  UPDATEUSER: {
    number: "3",
    title: "Actualización de datos",
  },
  RENOVATE: {
    number: "10",
    title: "Renovación aplicación",
  },
  PAYMENT: {
    number: "5",
    title: "Registrar Pago1",
  },
  CREATE_APP: {
    number: "6",
    title: "Subscribir un contacto a un plan",
  },
  UPDATE_APP_SUBSCRIPTION: {
    number: "17",
    title: "Editar la actualizacion de una Aplicación",
  },
  ACTIVATE_APP: {
    number: "7",
    title: "Activar Aplicación",
  },
  DUPLICAR_APP: {
    number: "8",
    title: "Nueva Aplicación",
  },
  SINGLE_APP: {
    number: "9",
    title: "Nueva Acción",
  },
  ACTIVATE_EXPRESS_APP: {
    number: "11",
    title: "Activación rapida de la aplicación",
  },
  CREATE_APP_PRODUCT: {
    number: "12",
    title: "Vender un producto a un contacto",
  },
  CREATE_APP_RENTAL: {
    number: "14",
    title: "Alquiler",
  },
  CREATE_APP_EVENT: {
    number: "13",
    title: "Agregar un contacto a una actividad",
  },
  CREATE_APP_NEW_EVENT: {
    number: "15",
    title: "Crear una actividad",
  },
  EDIT_EVENT: {
    number: "16",
    title: "Editar una actividad",
  },
  CLOSE_APP_WEB: {
    number: "18",
    title: "Solventación del servicio",
  },
};

export const lifecicleApps = {
  TO_BE_STARDED: "to-be-started",
  RECENTLY_STARTED: "recently-started",
  STARTED: "started",
  TO_BE_EXPIRED: "to-be-expired",
  EXPIRED: "expired",
  CANCELED: "canceled",
  ALL: "null",
};

export const lifecicleFormat = (name) => {
  if (lifecicleApps.TO_BE_STARDED === name) return "Proxima a comenzar";
  else if (lifecicleApps.RECENTLY_STARTED === name) return "Inicio Reciente";
  else if (lifecicleApps.STARTED === name) return "";
  else if (lifecicleApps.CANCELED === name) return "Cancelada";
  else if (lifecicleApps.TO_BE_EXPIRED === name) return "Proxima a vencer";
  else if (lifecicleApps.EXPIRED === name) return "vencida";
};

export const lifecicleIcon = (name) => {
  if (lifecicleApps.TO_BE_STARDED === name) return <FieldTimeOutlined />;
  else if (lifecicleApps.RECENTLY_STARTED === name) return <LikeOutlined />;
  else if (lifecicleApps.CANCELED === name) return <CloseCircleOutlined />;
  else if (lifecicleApps.TO_BE_EXPIRED === name) return <WarningOutlined />;
  else if (lifecicleApps.EXPIRED === name) return <RotateRightOutlined />;
};

export const openLandingTourGuide = ({ plan, planId, packId, refererUserId = "0", currentInstance = {} }) =>
  `${globals.CLUUFNET}/app_s3.html?h=${plan.departureTime}&f=${plan.departureDate}&redirect=true&q=${packId}&agency=${currentInstance.alias}&type=suscriber&p=${planId}&fclt=TourGuide&utmc=TourGuide&raId=null&ruId=${refererUserId}`;

export const openListinPagesPack = ({ currentInstance = {}, currentSession }) =>
  `${globals.CLUUFNET}/app_s0.html?agency=${currentInstance.alias}&type=suscriber&fclt=${currentInstance.alias}&utmc=referer&raId=null&ruId=${currentSession._id}`;

export const openLandingPagePackByReferers = ({ refererUserId = "0", currentInstance = {} }) =>
  `${globals.CLUUFNET}/app_s0.html?agency=${currentInstance.alias}&type=suscriber&fclt=${currentInstance.alias}&utmc=referer&raId=null&ruId=${refererUserId}`;

export const openLandingPagePackByReferersWithoutConfirmation = ({ refererUserId = "0", currentInstance = {} }) =>
  `${globals.CLUUFNET}/app_s0.html?agency=${currentInstance.alias}&type=suscriber&fclt=${currentInstance.alias}&utmc=referer&raId=null&ruId=${refererUserId}&pnd=${refererUserId}`;

export const openQRPackByReferers = ({ logo, title, referer, avatar, refererUserId, alias }) =>
  `${globals.CLUUFNET}/qr.html?agency=${referer}&title=${title}&referer=${refererUserId}&logo=${logo}&avatar=${avatar}&alias=${alias}`;
//

export const openInvoiceWeb = ({ trans, alias }) => {
  if (trans?.appId?._id)
    return `${globals.CLUUFNET}/invoice.html?agency=${alias}&type=suscriber&fclt=${alias}&utmc=invoice&raId=null&ruId=null&app=${trans?.appId?._id}&`;
};

export const isPhoneContact = (value) => {
  if (value.length >= 12) {
    return icons.PhoneTwoTone_enabled;
  }
  return icons.PhoneTwoTone_disabled;
};

export const isAgeContact = (value) => {
  const total = moment().diff(moment(value, "YYYY-MM-DD"), "years");
  if (total >= 7) {
    return icons.CalendarTwoTone_enabled;
  }
  return icons.CalendarTwoTone_disabled;
};

export const validStateApp = (item, compare) => {
  const desde = moment(item.startDate, "YYYY-MM-DD");
  const hasta = moment(item.closureDate, "YYYY-MM-DD");
  const hoy = moment.now();
  const inicio = desde.diff(hoy, "days");
  const fin = hasta.diff(hoy, "days");

  if (item.kind === modules.SUBSCRIPTION) {
    if (item.status === STATUS_APP.INWAIT) {
      return STATUS_APP_BY_DATE.ISPENDING;
    } else if (item.status === STATUS_APP.CANCEL) {
      return STATUS_APP_BY_DATE.ISCANCELED;
    } else if (item.status === STATUS_APP.CLOSE) {
      return STATUS_APP_BY_DATE.ISCLOSED;
    } else if (inicio <= 0 && fin >= 0 && item.status === STATUS_APP.OPEN) {
      return STATUS_APP_BY_DATE.ISPROGRESS;
    } else if (inicio < 0 && fin > 0 && fin < 6 && item.status === STATUS_APP.OPEN) {
      return STATUS_APP_BY_DATE.ISENDING;
    } else if (fin > 0 && inicio > -6 && item.status === STATUS_APP.OPEN) {
      return STATUS_APP_BY_DATE.ISSTARTING;
    }
  }

  if (item.kind === modules.PRODUCT) {
    if (item.status === STATUS_APP.CANCEL) {
      return STATUS_APP_BY_DATE.ISCANCELED;
    }
  }

  if (item.kind === modules.RENTAL) {
    if (item.status === STATUS_APP.INWAIT) {
      return STATUS_APP_BY_DATE.ISPENDING;
    } else if (item.status === STATUS_APP.CANCEL) {
      return STATUS_APP_BY_DATE.ISCANCELED;
    } else if (item.status === STATUS_APP.CLOSE) {
      return STATUS_APP_BY_DATE.ISCLOSED;
    } else if (inicio <= 0 && fin >= 0 && item.status === STATUS_APP.OPEN) {
      return STATUS_APP_BY_DATE.ISPROGRESS;
    } else if (inicio < 0 && fin > 0 && fin < 1 && item.status === STATUS_APP.OPEN) {
      return STATUS_APP_BY_DATE.ISENDING;
    } else if (fin > 0 && inicio > -1 && item.status === STATUS_APP.OPEN) {
      return STATUS_APP_BY_DATE.ISSTARTING;
    }
  }

  if (item.kind === modules.EVENT) {
    if (item.status === STATUS_APP.INWAIT) {
      return STATUS_APP_BY_DATE.ISPENDING;
    } else if (item.status === STATUS_APP.CANCEL) {
      return STATUS_APP_BY_DATE.ISCANCELED;
    } else if (item.status === STATUS_APP.CLOSE) {
      return STATUS_APP_BY_DATE.ISCLOSED;
    } else if (inicio <= 0 && fin >= 0 && item.status === STATUS_APP.OPEN) {
      return STATUS_APP_BY_DATE.ISPROGRESS;
    } else if (fin > 0 && inicio > -1 && item.status === STATUS_APP.OPEN) {
      return STATUS_APP_BY_DATE.ISSTARTING;
    }
  }
};

export const openLandingPageToEdit = ({
  packId,
  planId,
  rootEmail,
  campaign = "0",
  refererUserId = "0",
  refererAppId = "0",
  currentInstance = {},
}) =>
  `${globals.CLUUFNET}/app_s.html?q=${packId}&agency=${
    currentInstance.alias
  }&type=suscriber&p=${planId}&fclt=${rootEmail}&utmc=${campaign}&raId=${refererAppId}&ruId=${refererUserId}&pin=${
    currentInstance.pin
  }&login=true&token=${JSON.parse(localStorage.getItem("cluuf-settings")).token}`;

export const LandingPageValidadoCompleto = ({ packId, alias, refererId }) =>
  `${globals.CLUUFNET}/app_s3.html?q=${packId}&agency=${alias}&type=suscriber&fclt=web&utmc=referer&raId=null&ruId=${refererId}`;

export const LandingPageValidadoCompletoConPlanes = ({ packId, alias, refererId }) =>
  `${globals.CLUUFNET}/app_s5.html?q=${packId}&agency=${alias}&type=suscriber&fclt=web&utmc=referer&raId=null&ruId=${refererId}`;

export const LandingPageNoValidadoCompleto = ({ packId, alias }) =>
  `${globals.CLUUFNET}/app_s3.html?q=${packId}&agency=${alias}&type=suscriber&fclt=web&utmc=referer&raId=null&ruId=null`;

export const QRLandingPageValidadoCompletoConPlanes = ({ packId, alias, refererId, logo, avatar }) =>
  `${globals.CLUUFNET}/qr1.html?q=${packId}&agency=${alias}&type=suscriber&fclt=web&utmc=referer&raId=null&ruId=${refererId}&mode=plans&logo=${logo}&avatar=${avatar}&alias=${alias}`;

export const QRLandingPageValidadoCompleto = ({ packId, alias, refererId, logo, avatar }) =>
  `${globals.CLUUFNET}/qr1.html?q=${packId}&agency=${alias}&type=suscriber&fclt=web&utmc=referer&raId=null&ruId=${refererId}&mode=calendar&logo=${logo}&avatar=${avatar}&alias=${alias}`;

export const orderDates = (ajaxinfos) => {
  const array = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const ajaxinfo of ajaxinfos) {
    const myobject = {};
    myobject.departureDate = moment(ajaxinfo.departureDate, "YYYY-MM-DD"); // don't call format
    myobject.name = ajaxinfo.name;
    myobject.observation = ajaxinfo.observation;
    myobject.departureTime = ajaxinfo.departureTime;
    myobject._id = ajaxinfo._id;
    myobject.price = ajaxinfo.price;
    myobject.isFree = ajaxinfo.isFree;

    const SpecialTo = moment(ajaxinfo.departureDate, "YYYY-MM-DD");
    if (moment().diff(SpecialTo, "days") <= 0) {
      array.push(myobject);
    }
  }

  array.sort(
    (left, right) => left.departureDate.diff(right.departureDate) // No more need to convert strings to dates
  );

  return array;
};

export const handleGoToWebsite = ({ instanceStoreRedux, getSettings }) => {
  if (getSettings().token && instanceStoreRedux.currentInstance) {
    window.open(
      `${instanceStoreRedux.currentInstance.website}?pin=${getSettings().pin}&login=true&token=${
        JSON.parse(localStorage.getItem("cluuf-settings")).token
      }`
    );
  } else {
    alert("Cluuf Settings Error");
  }
};
