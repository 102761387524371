import { customFetch, FetchJson } from "../../helpers/fetch";
import { apiRoutes } from "../../constants/routes";

const savePack = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { packId } = props;
  const method = packId ? "PUT" : "POST";
  const path = packId ? `${apiRoutes.PACKSCONTENT.SAVE_DATA}/${packId}` : `${apiRoutes.PACKSCONTENT.SAVE_DATA}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const duplicatePack = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { packId } = props;

  const method = "POST";
  const path = `${apiRoutes.PACKSCONTENT.DUPLICATE}/${packId}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const savePackContent = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { packId } = props;
  const method = packId ? "PUT" : "POST";
  const path = packId
    ? `${apiRoutes.PACKSCONTENT.SAVE_DATA_CONTENT}/${packId}`
    : `${apiRoutes.PACKSCONTENT.SAVE_DATA_CONTENT}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const getPack = async (packId = 0, { onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.PACKSCONTENT.GET_DATA}/${packId}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getPacks = async ({ onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.PACKSCONTENT.ALL}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getCategoryPacks = async ({ onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.PACKSCONTENT.CATEGORIES}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const deletePack = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  try {
    const request = await customFetch(apiRoutes.CATEGORIES.DELETE_CATEGORY, {
      method: "POST",
      body: new URLSearchParams(params),
    });

    const response = await request.json();

    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const archivedPack = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id } = params.pack;
  const method = "PUT";
  const path = `${apiRoutes.PACKSCONTENT.ARCHIVED}/${_id}`;

  try {
    const request = await FetchJson(path, method, { archived: true });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const syncAIPlansByPack = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id, active } = params.pack;
  const method = "PUT";
  const path = `${apiRoutes.PACKSCONTENT.AIRTABLE_SYNC_PLANS}/${_id}`;

  try {
    const request = await FetchJson(path, method, { active: !active });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const activePack = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id, active } = params.pack;
  const method = "PUT";
  const path = `${apiRoutes.PACKSCONTENT.ACTIVE}/${_id}`;

  try {
    const request = await FetchJson(path, method, { active: !active });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const airtableSyncPack = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id, airtableSync } = params.pack;
  const method = "PUT";
  const path = `${apiRoutes.PACKSCONTENT.AISYNC}/${_id}`;

  try {
    const request = await FetchJson(path, method, { airtableSync: !airtableSync });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const reloadPack = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id } = params.pack;
  const method = "PUT";
  const path = `${apiRoutes.PACKSCONTENT.RELOAD}/${_id}`;

  try {
    const request = await FetchJson(path, method, { active: true });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const saveAssist = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { userId } = props;
  const method = "POST";
  const path = `${apiRoutes.ASSIST.SAVE}/${userId}`;

  try {
    const request = await FetchJson(path, method, props);
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const updateWebsite = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { url } = props;
  const path = url;
  const path2 = `${apiRoutes.LOGS.ROOT}`;

  try {
    if (path) window.open(path);
    const request = await FetchJson(path2, "POST", props);
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const updateJsonS3File = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { instanceId } = props;
  const path = `${apiRoutes.PLANS.UPDATE_JSON_S3_WEBSITE}?instanceId=${instanceId}`;

  try {
    const request = await FetchJson(path, "GET", props);
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    onError();
  } finally {
    onFinally();
  }
};

export {
  updateWebsite,
  getPack,
  getPacks,
  savePack,
  deletePack,
  archivedPack,
  activePack,
  saveAssist,
  savePackContent,
  updateJsonS3File,
  duplicatePack,
  reloadPack,
  airtableSyncPack,
  syncAIPlansByPack,
  getCategoryPacks,
};
