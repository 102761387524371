import { Layout, Menu, List, Avatar, Space, Typography } from "antd";

import {
  UserOutlined,
  SettingOutlined,
  InboxOutlined,
  CloudUploadOutlined,
  DollarCircleOutlined,
  PieChartOutlined,
  FundViewOutlined,
  BarChartOutlined,
  TagOutlined,
  RocketOutlined,
} from "@ant-design/icons";

import { useSelector } from "react-redux";
import { colorsSystem, icons } from "../../../../constants/globals";

const { Link } = Typography;
const { Sider } = Layout;
const { SubMenu } = Menu;

const Head = ({
  menu,
  handleMenuSelected,
  role,
  handleGoToWebsite,
  instanceStoreRedux,
  handleGoToPremiumData,
  handleLogout,
  modules,
  globals,
  isModuleValid,
}) => {
  const { currentSession } = useSelector((store) => store.session);
  const data = [
    {
      title: currentSession.firstname,
    },
  ];

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <div className="logo">
        <h2 className="logo-cluuf">Cluuf</h2>
        <p className="company-name">{instanceStoreRedux.currentInstance.name}</p>
        <List
          style={{ padding: 10, color: "#FFFFFF" }}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar className="profile-photo" src={currentSession.avatar} />}
                title={
                  <>
                    <p style={{ color: "#FFFFFF" }}>
                      <span style={{ color: colorsSystem.yellow }}> {item.title} </span> <br />
                      <Space>
                        <small>
                          <a
                            style={{
                              fontSize: 16,
                              color: "#FFFFFF",
                              position: "relative",
                              top: 7,
                            }}
                            href="#!"
                            onClick={() => handleMenuSelected(11)}
                          >
                            Mi perfil
                          </a>
                        </small>
                        <small>
                          <a
                            style={{
                              fontSize: 16,
                              color: "#FFFFFF",
                              position: "relative",
                              top: 7,
                            }}
                            href="#!"
                            onClick={() => handleLogout(instanceStoreRedux.currentInstance.alias)}
                          >
                            Salir
                          </a>
                        </small>
                      </Space>
                    </p>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </div>
      <div style={{ marginLeft: 15, marginBottom: 30 }}>
        <Link
          onClick={() => {
            document.location = "/analist";
          }}
          style={{
            fontSize: 19,
            background: colorsSystem.blue,
            color: colorsSystem.white,
            padding: 5,
            borderRadius: 4,
          }}
        >
          Analista &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{icons.goto}
        </Link>
        <br /> <br />
        {isModuleValid("event", instanceStoreRedux) && (
          <Link
            onClick={() => {
              document.location = "/tourGuide";
            }}
            style={{
              fontSize: 19,
              background: colorsSystem.red,
              color: colorsSystem.white,
              padding: 5,
              borderRadius: 4,
            }}
          >
            Tour guide {icons.goto}
          </Link>
        )}
      </div>
      {role === globals.ROLES.SUPERADMIN && (
        <>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={[menu]}>
            <>
              <Menu.Item onClick={() => handleMenuSelected(31)} key="40" icon={<TagOutlined />}>
                Dashboards
              </Menu.Item>

              <Menu.Item onClick={() => handleMenuSelected(17)} key="32" icon={icons.services}>
                Servicios
              </Menu.Item>

              {isModuleValid("plan", instanceStoreRedux) && (
                <Menu.Item onClick={() => handleMenuSelected(21)} key="43" icon={icons.icon_plans}>
                  Subscripciones
                </Menu.Item>
              )}

              {isModuleValid("event", instanceStoreRedux) && (
                <Menu.Item onClick={() => handleMenuSelected(19)} key="41" icon={icons.icon_events}>
                  Actividades
                </Menu.Item>
              )}

              {isModuleValid("product", instanceStoreRedux) && (
                <Menu.Item onClick={() => handleMenuSelected(32)} key="46" icon={icons.icon_products}>
                  Ventas
                </Menu.Item>
              )}

              {isModuleValid("rental", instanceStoreRedux) && (
                <Menu.Item onClick={() => handleMenuSelected(35)} key="47" icon={icons.icon_rental}>
                  Alquileres
                </Menu.Item>
              )}

              <Menu.Item onClick={() => handleMenuSelected(38)} key="37" icon={<DollarCircleOutlined />}>
                Pagos
              </Menu.Item>

              <Menu.Item onClick={() => handleMenuSelected(30)} key="38" icon={<UserOutlined />}>
                Clientes
              </Menu.Item>

              <SubMenu key="sub9" icon={<SettingOutlined />} title="APLICACIONES">
                <Menu.Item onClick={() => handleMenuSelected(34)} key="48">
                  Subscripciones
                </Menu.Item>

                <Menu.Item onClick={() => handleMenuSelected(33)} key="49">
                  Eventos
                </Menu.Item>

                <Menu.Item onClick={() => handleMenuSelected(32)} key="50">
                  Productos
                </Menu.Item>

                <Menu.Item onClick={() => handleMenuSelected(35)} key="51">
                  Rental
                </Menu.Item>
              </SubMenu>

              <SubMenu key="sub6" icon={<SettingOutlined />} title="PROVEEDORES">
                <Menu.Item onClick={() => handleMenuSelected(36)} key="36">
                  <small> Proveedores</small>
                </Menu.Item>

                <Menu.Item onClick={() => handleMenuSelected(37)} key="37">
                  <small> Pro-Servicios</small>
                </Menu.Item>
              </SubMenu>
            </>

            {/*
            <SubMenu key="sub10" icon={<SettingOutlined />} title="APLICACIONES">
              <Menu.Item onClick={() => handleMenuSelected(34)} key="48" icon={<RocketOutlined />}>
                Subscripciones
              </Menu.Item>

              <Menu.Item onClick={() => handleMenuSelected(33)} key="47" icon={<RocketOutlined />}>
                Eventos
              </Menu.Item>
            </SubMenu>
             */}

            {isModuleValid(modules.WEBCONTENT) && (
              <SubMenu key="sub2" icon={<SettingOutlined />} title="CONTENIDO WEB">
                <Menu.Item onClick={() => handleMenuSelected(40)} key="40">
                  <small> Secciones</small>
                </Menu.Item>
                <Menu.Item onClick={() => handleMenuSelected(41)} key="41">
                  <small> Elementos</small>
                </Menu.Item>
              </SubMenu>
            )}

            {isModuleValid(modules.ANALYTICSADVANCED) && (
              <>
                <SubMenu key="sub3" icon={<SettingOutlined />} title="ANALYTICA WEB">
                  <Menu.Item onClick={() => handleGoToWebsite()}>
                    <small>Editar Web</small>
                  </Menu.Item>
                  <Menu.Item onClick={() => handleGoToPremiumData()}>
                    <small>Cluuf Analytics</small>
                  </Menu.Item>
                  <Menu.Item onClick={() => handleMenuSelected(13)} key="13">
                    <small> Web Metrics</small>
                  </Menu.Item>
                </SubMenu>
              </>
            )}
            <br />
            <br />
            <SubMenu key="sub4" icon={<SettingOutlined />} title="SISTEMA">
              <Menu.Item onClick={() => handleMenuSelected(39)} key="49">
                <small>Usuarios</small>
              </Menu.Item>
              <Menu.Item onClick={() => handleMenuSelected(10)} key="10">
                <small>Configuración</small>
              </Menu.Item>
              <Menu.Item onClick={() => handleMenuSelected(3)} key="3">
                <small>Maestro</small>
              </Menu.Item>
              <Menu.Item onClick={() => handleMenuSelected(28)} key="28">
                <small>Logs</small>
              </Menu.Item>
              <Menu.Item onClick={() => handleMenuSelected(12)} key="33">
                <small>Bucket</small>
              </Menu.Item>
              <Menu.Item onClick={() => handleMenuSelected(8)} key="8">
                <small>Invitados</small>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </>
      )}
    </Sider>
  );
};

export default Head;
