import { Table, Tooltip } from "antd";

import { withRouter } from "react-router-dom";
import BaseTable from "../../shared/components/BasicTable";

const PlansTable = ({ data }) => {
  console.log(data);
  return (
    <>
      <BaseTable pagination="false" dataSource={data}>
        <Table.Column
          fixed="left"
          width="50px"
          render={(value, row, key) => (
            <h5 style={{ textAlign: "center" }}>
              <b>{key + 1}</b>
            </h5>
          )}
        />
        <Table.Column
          title="Contacto"
          dataIndex="value"
          key="value"
          render={(text, row) => (
            <p style={{ textTransform: "uppercase", marginBottom: 0 }}>
              {`${row?.userId?.firstname} ${row?.userId?.lastname || ""}`}{" "}
              <Tooltip title="Asistencías">
                <sup> ({row?.checkins?.length}) </sup>
              </Tooltip>
            </p>
          )}
        />
        <Table.Column
          title="Plan"
          dataIndex="planId"
          key="planId"
          render={(text, row) => <small>{`${row?.packId?.name} ${row?.planId?.name}`}</small>}
        />
        <Table.Column
          title="Desde"
          dataIndex="startDate"
          key="startDate"
          render={(text, row) => <h5>{row?.startDate}</h5>}
        />
        <Table.Column
          title="Hasta"
          dataIndex="closureDate"
          key="closureDate"
          render={(text, row) => <h5>{row?.closureDate}</h5>}
        />
      </BaseTable>
    </>
  );
};

export default withRouter(PlansTable);
