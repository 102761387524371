import { useState, useEffect } from "react";
import { Form, Row, Col, Input, Switch, DatePicker, PageHeader, Descriptions, Button, Select } from "antd";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { confirmAlert } from "react-confirm-alert";

import { withRouter } from "react-router-dom";
import { STATUS_APP, durationFormat } from "../../../constants/globals";
import { renovateAppByApp } from "../requests";
import SelectPacks from "../../subscribers/components/SelectPacks";
import SelectPlans from "../../subscribersPlans/components/SelectPlans";

const moment = require("moment");
const Swal = require("sweetalert2");

const RenovateApp = ({ selectedApp, planId_ = null, packId_ = null, refetch, onClose }) => {
  const { t } = useTranslation();
  const instanceStoreRedux = useSelector((store) => store.instance);

  const [setting, setSetting] = useState({});
  const [user, setUser] = useState({});
  const [app, setApp] = useState({});
  const [packId, setPackId] = useState(packId_);
  const [planId, setPlanId] = useState(planId_);
  const [plan, setPlan] = useState({});
  const [reload, setReload] = useState(0);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const [form] = Form.useForm();

  const onValuesChange = (values) => {
    setData({ ...data, ...values });
  };

  const handleStartdate = (values) => {
    setData({
      ...data,
      closureDate: moment(values).add(plan.duration, durationFormat(plan.durationMode)),
      startDate: values,
    });
    form.setFieldsValue(data);
  };

  const onFinish = () => {
    setData({ ...data, planId, packId });
    setLoadingSubmit(true);
    renovateAppByApp(data, {
      onSuccess: () => {
        Swal.fire({
          icon: "success",
          title: "Operación Exitosa",
        });
        refetch();
        onClose();
      },
      onError: () => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="">Why do I have this issue?</a>',
        });
      },
      onFinally: () => {
        setLoadingSubmit(false);
      },
    });
  };

  const handleSubmit = () => {
    confirmAlert({
      title: "Confirmación",
      message: `Esta seguro que desea continuar?`,
      buttons: [
        {
          label: "Continuar",
          onClick: () => form.submit(),
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const handlePack = (value) => {
    setPackId(value);
    setPlanId(null);
    setData({
      ...data,
      packId: value,
      startDate: moment(selectedApp?.closureDate, "YYYY/MM/DD"),
      closureDate: null,
    });
  };

  const handlePlan = (value) => {
    setPlanId(value);
    setData({
      ...data,
      planId: value,
      startDate: null,
      closureDate: null,
    });
  };

  useEffect(() => {
    setPlan({});
  }, [packId]);

  useEffect(() => {
    form.setFieldsValue(user);
  }, [form, user]);

  useEffect(() => {
    setReload(Math.floor(Math.random() * 100000));
    setSetting(instanceStoreRedux.currentInstance);
    if (selectedApp?.planId) {
      setApp(selectedApp);
      setUser(selectedApp.userId);
      setPlan(selectedApp.planId);
      setData({
        planId,
        packId,
        appId: selectedApp._id,
        phone: selectedApp.userId.phone,
        startDate: moment(selectedApp?.closureDate, "YYYY/MM/DD"),
        closureDate: moment(selectedApp?.closureDate).add(
          selectedApp.planId.duration,
          durationFormat(selectedApp.planId.durationMode)
        ),
        description: "",
      });

      setLoading(false);
    }
  }, [selectedApp]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  return (
    <>
      <Row>
        <Col span={18} offset={3}>
          <Form
            form={form}
            name="manage-category-form-modal"
            className="manage-modal"
            onFinish={onFinish}
            labelCol={{ span: 24 }}
            onValuesChange={onValuesChange}
          >
            <PageHeader
              avatar={{ src: user.avatar }}
              className="site-page-header-responsive"
              title={`${app?.packId?.name} ${app?.planId?.name}`}
              extra={[
                <Button key="2" className="ant-btn secondary error large" size="large" onClick={onClose}>
                  Cerrar
                </Button>,
                <Button
                  loading={loadingSubmit}
                  className="ant-btn secondary primary large"
                  key="1"
                  size="large"
                  onClick={handleSubmit}
                >
                  {`Renovar Aplicación ${app?.correlative}`}
                </Button>,
              ]}
            >
              <Descriptions size="small" column={3}>
                <Descriptions.Item label="Aplicación #">{app?.code}</Descriptions.Item>
                <Descriptions.Item label="Contacto">
                  {user?.firstname} {user?.lastname || ""}
                </Descriptions.Item>
                <Descriptions.Item label="Email">{user?.email}</Descriptions.Item>
              </Descriptions>
            </PageHeader>

            <div style={{ padding: 10, background: "#f5f5f5" }}>
              <Row gutter={15} style={{ paddingBottom: 10 }}>
                <Col span={12}>
                  <Form.Item name="packId2" tooltip="" label={t("pack")}>
                    {packId_ && app.kind && (
                      <SelectPacks
                        reload={reload}
                        currentValue={packId_}
                        defaultValue={packId_}
                        returnData={handlePack}
                        typePack="SUBSCRIPTION"
                        kind={app?.kind}
                        title="Seleccione"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="planId" tooltip="" label={t("plan")}>
                    {packId.length > 5 && (
                      <SelectPlans
                        handleSelected={setPlan}
                        reload={reload}
                        currentValue={planId}
                        defaultValue={planId}
                        returnData={handlePlan}
                        type="SUBSCRIPTION"
                        packId={packId}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item
                    rules={[{ required: true, message: "Campo obligatorio" }]}
                    name="paymentMode"
                    label="Método de pago"
                  >
                    <Select className="select-form-modal">
                      <Select.Option value="none">Sin pago</Select.Option>
                      <Select.Option value="cash">Efectivo</Select.Option>
                      <Select.Option value="debitcard">Débito</Select.Option>
                      <Select.Option value="creditcard">Crédito</Select.Option>
                      <Select.Option value="transfer">Transferencía</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item name="paymentReference" label="Número de referencia">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item
                    rules={[{ required: true, message: "Campo obligatorio" }]}
                    name="startDate"
                    label="Fecha de inicio"
                  >
                    <DatePicker format="YYYY/MM/DD" onChange={handleStartdate} />
                  </Form.Item>
                </Col>

                <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item
                    rules={[{ required: true, message: "Campo obligatorio" }]}
                    name="closureDate"
                    label="Fecha de finalización"
                  >
                    <DatePicker className="color-red" format="YYYY/MM/DD" disabled />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default withRouter(RenovateApp);
