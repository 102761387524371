import { setLocalStorage } from "../utils/localStorage";

const dataInicial = {
  request: false,
  reload: Math.floor(Math.random() * 100000000),
  currentService: {},
};

const CURRENT_SERVICE = "CURRENT_SERVICE";

const RELOAD = "RELOAD";

export default function serviceReducer(state = dataInicial, action) {
  switch (action.type) {
    case CURRENT_SERVICE:
      return { ...state, currentService: action.payload };

    case RELOAD:
      return { ...state, reload: Math.floor(Math.random() * 100000000) };

    default:
      return { ...state };
  }
}

export const setService = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: CURRENT_SERVICE,
    payload,
  });
};

export const setServiceRedux = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: CURRENT_SERVICE,
    payload,
  });
};

export const reloadRedux = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: RELOAD,
    payload,
  });
};
