import { PlusOutlined } from "@ant-design/icons";
import PrimaryButton from "../../shared/components/PrimaryButton";
import useShortcut from "../../shared/hooks/useShortcut";

const AddPackButton = ({ isOpenManagePacksModal, handleNew, t }) => {
  useShortcut(["n"], handleNew, [isOpenManagePacksModal]);

  return (
    <>
      <PrimaryButton onClick={handleNew} type="primary" size="large" icon={<PlusOutlined />}>
        {t("subscription")}
      </PrimaryButton>
    </>
  );
};

export default AddPackButton;
