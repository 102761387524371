import { Tabs } from "antd";
import { icons } from "../../../constants/globals";

const { TabPane } = Tabs;

const TabMenu = ({ setSubpage, t, isMinimal, numbers, selectedPlan }) => {
  const callback = (key) => {
    setSubpage(key);
  };

  return (
    <Tabs defaultActiveKey={numbers.TWO} onChange={callback}>
      {!isMinimal && (
        <TabPane
          tab={
            <span>
              {icons.enterDetails}
              {t("moreDetails")}
            </span>
          }
          key={numbers.FOUR}
        />
      )}
      <TabPane
        tab={
          <span>
            {icons.apps}
            {t("apps")}
          </span>
        }
        key={numbers.TWO}
      />{" "}
      {!selectedPlan?.isFree && !isMinimal && (
        <TabPane
          tab={
            <span>
              {icons.dolar}
              {t("payments")}
            </span>
          }
          key={numbers.ONE}
        />
      )}
      <TabPane
        tab={
          <span>
            {icons.upload}
            {t("attachedFiles")}
          </span>
        }
        key={numbers.THREE}
      />
      <TabPane
        tab={
          <span>
            {icons.upload}
            Proveedores
          </span>
        }
        key={numbers.FIVE}
      />
    </Tabs>
  );
};

export default TabMenu;
