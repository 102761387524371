import {
  CloseCircleOutlined,
  SearchOutlined,
  IdcardOutlined,
  TableOutlined,
  ReloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Col, Row, Space, Tooltip } from "antd";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import globalStyles from "../../../shared/css/global.module.css";
import useShortcut from "../../../shared/hooks/useShortcut";
import styles from "./ActionBar.module.css";

const ActionBar = ({ isEmbed, children, options, filters, setContainerMode, containerMode, setFilters, handleNew }) => {
  const { t } = useTranslation();
  const [searching, setSearching] = useState(false);
  const handleSearch = () => setSearching(!searching);

  useShortcut(["s"], () => setSearching(true), [searching]);

  const reset = () => {
    setFilters({ ...filters, all: "", searchByUsers: false });
  };

  return (
    <>
      {searching && children ? (
        <div className={`${globalStyles["padding-xs"]} ${styles["action-bar-content"]}`}>
          <Row justify="space-between" align="middle">
            <Col span={24}>{children}</Col>
          </Row>
          <CloseCircleOutlined onClick={handleSearch} className={styles["action-bar-close-close-button"]} />
        </div>
      ) : null}
      <Row className={`${globalStyles["padding-xs"]} ${styles["action-bar"]}`} justify="space-around" align="middle">
        <Col span={18}>
          {options && options?.actions && (
            <options.actions
              setFilters={setFilters}
              filters={filters}
              isEmbed={isEmbed}
              handleNew={options.handleNew}
            />
          )}
        </Col>
        <Col span={6} className={globalStyles["text-align-right"]}>
          <Space>
            <Button className="btn-icon-only" icon={<PlusOutlined />} size="large" onClick={options.handleNew}>
              {t["common.search"]}
            </Button>
            {children && !searching ? (
              <>
                <Button className="btn-icon-only" icon={<SearchOutlined />} size="large" onClick={handleSearch}>
                  {t["common.search"]}
                </Button>
                <Button className="btn-icon-only" icon={<ReloadOutlined />} size="large" onClick={reset}>
                  {t["common.search"]}
                </Button>
              </>
            ) : null}
            {containerMode === "table" && (
              <Tooltip title="verRegistroComoTablas">
                <Button
                  onClick={() => setContainerMode("cards")}
                  shape="square"
                  icon={<IdcardOutlined />}
                  size="large"
                />
              </Tooltip>
            )}
            {containerMode === "cards" && (
              <Tooltip title="verRegistroComoTarjetas">
                <Button
                  onClick={() => setContainerMode("table")}
                  shape="square"
                  icon={<TableOutlined />}
                  size="large"
                />
              </Tooltip>
            )}
          </Space>
        </Col>
      </Row>
    </>
  );
};

ActionBar.propTypes = {
  isEmbed: PropTypes.bool,
  children: PropTypes.node,
  options: PropTypes.object,
  filters: PropTypes.object,
  setContainerMode: PropTypes.func,
  containerMode: PropTypes.string,
  setFilters: PropTypes.func,
};

export default ActionBar;
