import { Tabs } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

const TabMenu = ({ setSubpage, t, numbers }) => {
  const callback = (key) => {
    setSubpage(key);
  };

  return (
    <Tabs defaultActiveKey={numbers.THREE} onChange={callback}>
      <TabPane
        tab={
          <span>
            <PaperClipOutlined /> {t("upload")}
          </span>
        }
        key={numbers.THREE}
      />
    </Tabs>
  );
};

export default TabMenu;
