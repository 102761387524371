/* eslint-disable no-unused-expressions */
import { useState, useEffect } from "react";
import { Descriptions, Divider, Spin } from "antd";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  dateFormat6,
  priceFormat,
  birthdate,
  formaterModeTrans,
  eventStatusFormatWord,
} from "../../../constants/globals";

import { getUser } from "../../subscribersUsers/requests";

const GeneralUser = ({ user = {}, app = {}, reload }) => {
  const transStoreRedux = useSelector((store) => store.trans);

  const [data, setData] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (app.userId) {
      getUser(app.userId._id, {
        onSuccess: (result) => {
          setData(result.user);
          setRefresh(true);
        },
        onError: () => {},
      });
    }
  }, [reload]);

  useEffect(() => {
    setRefresh(false);
  }, [reload]);

  return refresh ? (
    app.packId && app._id && (
      <>
        <Divider orientation="left">Acerca del Pago</Divider>
        <Descriptions size="small" column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} style={{ marginTop: 8 }}>
          <Descriptions.Item label="Estatus">{eventStatusFormatWord(app?.transId?.status)}</Descriptions.Item>
          <Descriptions.Item label="Modalidad">{formaterModeTrans(app?.transId?.mode) || "--------"}</Descriptions.Item>
          <Descriptions.Item label="Referencia">{app?.transId?.reference || "--------"}</Descriptions.Item>
          <Descriptions.Item label="Fecha de pago">{dateFormat6(app?.paymentDate)}</Descriptions.Item>
          <Descriptions.Item label="Monto en deuda">{priceFormat(app?.amount)}</Descriptions.Item>
          <Descriptions.Item label="Monto Total">{priceFormat(app?.total)}</Descriptions.Item>
          <Descriptions.Item label="Codigo">{app?.transId?.code}</Descriptions.Item>
          <Descriptions.Item label="Cupón">{app?.cupon || "--------"}</Descriptions.Item>
        </Descriptions>
        <Divider orientation="left">Acerca de la Aplicación </Divider>
        <Descriptions size="small" column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} style={{ marginTop: 8 }}>
          <Descriptions.Item label="Nombre plan">{app?.planId.name}</Descriptions.Item>
          <Descriptions.Item label="Codigo">{app?.code}</Descriptions.Item>
          {app.packId.kind === "subscription" && (
            <Descriptions.Item label="Fecha de inicio">{dateFormat6(app?.startDate)}</Descriptions.Item>
          )}
          {app.packId.kind === "subscription" && (
            <Descriptions.Item label="Fecha de finalización">{dateFormat6(app?.closureDate)}</Descriptions.Item>
          )}

          {app.packId.kind === "subscription" && (
            <Descriptions.Item label="Cantidad de Asistencias">{app?.checkins.length}</Descriptions.Item>
          )}

          {app.packId.kind === "subscription" && (
            <Descriptions.Item label="Invitaciones">
              {app?.guestQuantity} / {app?.guestLimit && app?.guestLimit < 1000 ? app?.guestLimit : "Sin limites"}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Campaña">{app.campaign}</Descriptions.Item>
          <Descriptions.Item label="Facilitador">{app.facilitator}</Descriptions.Item>
        </Descriptions>
        <Divider orientation="left">Acerca de {data?.firstname}</Divider>
        <Descriptions size="small" column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} style={{ marginTop: 8 }}>
          <Descriptions.Item label="Correlativo">{app?.correlative}</Descriptions.Item>
          <Descriptions.Item label="Nombres">{data?.firstname}</Descriptions.Item>
          <Descriptions.Item label="Apellidos">{data?.lastname}</Descriptions.Item>
          <Descriptions.Item label="Email">{data?.email}</Descriptions.Item>
          <Descriptions.Item label="Tipo de documento"> {data?.documentType}</Descriptions.Item>
          <Descriptions.Item label="Documento"> {data?.document}</Descriptions.Item>
          <Descriptions.Item label="Fecha de Nacimiento"> {dateFormat6(data?.birthdate)}</Descriptions.Item>
          <Descriptions.Item label="Edad"> {birthdate(data?.birthdate)}</Descriptions.Item>

          <Descriptions.Item label="Teléfono"> {data?.phone}</Descriptions.Item>
          <Descriptions.Item label="Teléfono Opc."> {data?.secondaryphone}</Descriptions.Item>
          <Descriptions.Item label="Ocupación"> {data?.profession}</Descriptions.Item>
          <Descriptions.Item label="Género"> {data?.genre}</Descriptions.Item>
          <Descriptions.Item label="¿Como nos conoció?"> {data?.medium}</Descriptions.Item>
        </Descriptions>
        <Descriptions size="small" column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} style={{ marginTop: 8 }}>
          <Descriptions.Item label="Dirección">
            {user?.address}, {user?.city}
          </Descriptions.Item>
          <Descriptions.Item label="Alguna Alergía o Medicamento">{user?.alergies}</Descriptions.Item>
          <Descriptions.Item label="Alguna Lesión">{user?.bodylesson}</Descriptions.Item>
          <Descriptions.Item label="Alguna Observación">{user?.observation}</Descriptions.Item>
        </Descriptions>
        <br /> <br />
      </>
    )
  ) : (
    <Spin />
  );
};

export default withRouter(GeneralUser);
