import { useState } from "react";

import { Row, Col, Card, Tag, Space, Button, Tooltip, Radio } from "antd";
import { withRouter } from "react-router-dom";

import moment from "moment";

import {
  dateFormat6,
  STATUS_APP,
  icons,
  colorsSystem,
  cutWords,
  priceFormat,
  clasificationFormat,
  validStateApp,
  STATUS_APP_BY_DATE,
  modules,
  colorNetoKind,
} from "../../../constants/globals";

const Cards = ({ apps, handleCheckin, handleOpenUser, handleDesactivateApp, handleCloseAppWeb, height = "500px" }) => {
  const [filterApp, setFilterApp] = useState("close");

  const style_ = (item) => {
    const vars__ = { width: 260, marginTop: 30, marginBottom: 15, height: 350 };

    if (item.status === "cancel") return { ...vars__, border: `7px solid ${colorsSystem.black}` };
    // if (item.isPending) return { ...vars__, border: `1px solid ${colorsSystem.red}` };

    return vars__;
  };

  const onChangeFilterTab = (e) => e.target && e.target.value && setFilterApp(e.target.value);

  const status__ = (app) => {
    const result = validStateApp(app);
    return (
      <div style={{ textAlign: "center", marginTop: 10 }}>
        {result === STATUS_APP_BY_DATE.ISCLOSED && (
          <Tag style={{ fontSize: 14, fontWeight: 800 }} color={colorsSystem.blue}>
            Finalizada
          </Tag>
        )}

        {result === STATUS_APP_BY_DATE.ISPENDING && (
          <Tag style={{ fontSize: 14, fontWeight: 800 }} color="volcano">
            {icons.clock} En Espera
          </Tag>
        )}

        {result === STATUS_APP_BY_DATE.ISPROGRESS && (
          <Tag style={{ fontSize: 12, fontWeight: 800 }} color="green">
            En progreso
          </Tag>
        )}

        {result === STATUS_APP_BY_DATE.ISCANCELED && <Tag color="error">Cancelada</Tag>}

        <div style={{ textAlign: "left", marginTop: "-7px", marginLeft: 10 }}>
          <Space>
            {app?.status !== STATUS_APP.INWAIT && (
              <Button className="ant-btn secondary primary" size="small" onClick={() => handleOpenUser(app)}>
                ABRIR
              </Button>
            )}
          </Space>
        </div>
      </div>
    );
  };

  const filterApps = (data) => {
    const res = [];
    if (filterApp === "close") {
      data?.forEach((element) => {
        if (
          (element.status === "close" || element.status === "inwait" || element.status === "open") &&
          element.packId.clasification === "form"
        ) {
          res.push(element);
        }
      });
    } else if (filterApp === "all") {
      data?.forEach((element) => {
        if (element.packId.clasification === "form") {
          res.push(element);
        }
      });
    }
    return res;

    /*
    if (filterApp === "close") {
      data?.forEach((element) => {
        if (element.status === "close" && element.isClosed === true && element.packId.clasification === "form") {
          res.push(element);
        }
      });
    } else if (filterApp === "cancel") {
      data?.forEach((element) => {
        if (element.status === "cancel" && element.isCanceled === true && element.packId.clasification === "form") {
          res.push(element);
        }
      });
    } else if (filterApp === "open") {
      data?.forEach((element) => {
        if (
          (element.status === "open" || element.status === "inwait") &&
          element.isProgress === true &&
          element.packId.clasification === "form"
        ) {
          res.push(element);
        }
      });
    } else if (filterApp === "all") {
      data?.forEach((element) => {
        if (element.packId.clasification === "form") {
          res.push(element);
        }
      });
    }
    return res;
    */
  };

  const borderColor = (item) => (item?.planId?.isMarker ? item.planId.color : colorNetoKind(item.packId.kind));
  const cartas = () =>
    apps &&
    apps.length > 0 &&
    filterApps(apps).map((item, index) => (
      <Col className="gutter-row" span={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Card className="usermagico shadow" hoverable style={style_(item)}>
          <sup style={{ position: "absolute", top: "-14px", color: "gray", left: "0px", fontSize: 10 }}>
            {item?.code} | {item?.correlative}
          </sup>
          <h3
            style={{
              background: "#1b203e",
              padding: 2,
              lineHeight: "16px",
              fontSize: 20,
              fontWeight: "600",
              color: "#fff",
              paddingTop: 7,
              paddingLeft: 10,
              paddingBottom: 7,
              textAlign: "left",
              borderLeft: `20px solid ${borderColor(item)}`,
            }}
          >
            <small style={{ fontSize: 14, fontWeight: "600" }}>
              {clasificationFormat(item?.packId?.clasification)}
            </small>
            <br />
            <small style={{ fontSize: 18, letterSpacing: 1, fontWeight: "600" }}>{item?.packId?.name}</small>

            <br />
            {item?.kind === "subscription" && (
              <small className="special-font" style={{ fontSize: 14, fontWeight: "600" }}>
                {cutWords(item?.planId?.name)}
                <br />
              </small>
            )}
          </h3>
          <p
            style={{
              fontSize: 13,
              paddingLeft: 10,
              color: "#3a3a3a",
              margin: 0,
              marginTop: -5,
              marginBottom: 5,
              textTransform: "uppercase",
            }}
          >
            {item?.kind === modules.EVENT && (
              <>
                <div style={{ height: 50, textAlign: "center" }}>
                  <span className="special-font apps-card-username">
                    {cutWords(`${item?.userId.firstname} ${item?.userId.lastname}`, 35)}
                  </span>
                </div>
                <hr />
                <small>
                  {item?.refererRootId?._id
                    ? `by ${item.refererRootId?.firstname} ${item.refererRootId?.lastname || ""}`
                    : "by Website"}{" "}
                </small>
                <br />
                {!item?.planId?.isFree && (
                  <>
                    Costo: {priceFormat(item.total)} <br />
                    {item.isPayment ? (
                      <b>{icons.check_enabled} Pagada</b>
                    ) : (
                      <>
                        <Tag color={colorsSystem.red}>En Deuda: {priceFormat(item.amount)}</Tag>
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {item?.kind === modules.SUBSCRIPTION && (
              <>
                {icons.calendar} {dateFormat6(item.startDate)}
                <div style={{ height: 30, textAlign: "center" }}>
                  <span className="special-font apps-card-username">
                    {cutWords(`${item?.userId.firstname} ${item?.userId.lastname}`, 35)}
                  </span>
                </div>
              </>
            )}
          </p>

          {status__(item)}

          {item?.packId?.clasification === "form" && (
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <p
                className="box-warning"
                style={{ lineHeight: "14px", fontSize: "11px", overflow: "scroll", height: 100 }}
              >
                {String(item?.comments).length > 2 && (
                  <span>
                    <b>
                      {item?.closeBy} {dateFormat6(item?.closeAt)}:{" "}
                    </b>{" "}
                    {item?.comments} <hr />
                  </span>
                )}
                <b>
                  {icons.clock} {item?.hora}:
                </b>{" "}
                {item?.observation}
              </p>

              {item?.status === "inwait" && (
                <Space>
                  <Tooltip title="Solventación del ticket">
                    <Button className="ant-btn secondary primary " size="small" onClick={() => handleCloseAppWeb(item)}>
                      {icons.check} CERRAR
                    </Button>
                  </Tooltip>

                  <Tooltip title="Anulación del ticket">
                    <Button className="ant-btn secondary error" size="small" onClick={() => handleDesactivateApp(item)}>
                      {icons.cancel} CANCELAR
                    </Button>
                  </Tooltip>
                </Space>
              )}
              <br />
            </div>
          )}
        </Card>
      </Col>
    ));

  return (
    <>
      <Row gutter={32}>
        <Col span={24}>
          <Radio.Group defaultValue={filterApp} onChange={onChangeFilterTab}>
            <Radio.Button style={{ background: "#fff", color: "#000" }} value="all">
              {icons.icon_plans} Ver todo
            </Radio.Button>
            <Radio.Button style={{ background: "#fff", color: "#000" }} value="close">
              {icons.pending} En espera o Finalizadas
            </Radio.Button>
            <Radio.Button style={{ background: "#fff", color: "#000" }} value="cancel">
              {icons.cancel} Canceladas
            </Radio.Button>
          </Radio.Group>
        </Col>

        {cartas()}
        {apps && apps?.length === 0 && (
          <p style={{ textAlign: "center", marginTop: 30, width: "100%" }}>No se encontraron usuarios</p>
        )}
      </Row>
    </>
  );
};

export default withRouter(Cards);
