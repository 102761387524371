import { useState, useEffect } from "react";
import { Row, Col, message, Card, Spin } from "antd";
import { useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";
import Loginform from "../components/userForm";
import showGlobalNotification from "../../../helpers/showGlobalNotification";

import "../user.css";

import { loadBranding, loginuser } from "../request";
import { setInstanceRedux } from "../../../redux/instance";

const Login = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const [alias, setAlias] = useState(props.match.params.alias);
  const [setting, setSetting] = useState({});
  const dispatch = useDispatch();

  // eslint-disable-next-line react/destructuring-assignment
  const [goto, setGoto] = useState(props.match.params.goto);

  useEffect(() => {
    localStorage.removeItem("keypublic");
    localStorage.removeItem("cluuf-settings");
    localStorage.removeItem("instanceId");

    loadBranding(alias, {
      onSuccess: (response) => {
        setSetting(response.result);
        if (response.result.keypublic) {
          localStorage.setItem("keypublic", response.result.keypublic);
          localStorage.setItem("instanceId", response.result._id);

          setInstanceRedux(response.result)(dispatch);
        }
      },
      onError: () => showGlobalNotification("error", "Error", "error"),
    });
  }, [alias]);

  useEffect(() => {
    localStorage.setItem("goto", goto);
  }, [goto]);

  return (
    <>
      {setting?._id ? (
        <Row>
          <Col span={24} style={{ backgroundImage: `url(${setting.background})`, backgroundSize: "cover" }}>
            <div className="containerLogin">
              <Card
                cover={
                  <img alt="example" style={{ width: 100, margin: "auto", textAlign: "center" }} src={setting.logo} />
                }
                className="item"
                bordered={false}
                style={{ width: 350, paddingTop: 25 }}
              >
                <Loginform setting={setting} login={loginuser} router={props} message={message} />
              </Card>
            </div>
          </Col>
        </Row>
      ) : (
        <p style={{ margin: "auto", maxWidth: 100, paddingTop: 200 }}>
          <Spin tip="Cargando ..." />
        </p>
      )}
    </>
  );
};

export default withRouter(Login);
