import { PageHeader } from "antd";
import { images } from "../../../constants/globals";

const Header = ({ primaryAction, title, icon = null }) => (
  <>
    <PageHeader className="site-page-header-responsive" title={title || ""} extra={[primaryAction && primaryAction]} />
  </>
);

export default Header;
