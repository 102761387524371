import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import { confirmAlert } from "react-confirm-alert";

import { Form, Row, Col, Input, Spin, Button, Checkbox, Switch } from "antd";
import { saveInstance, getInstance } from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";
import { setInstanceRedux } from "../../../redux/instance";

const defaultValues = {
  name: "",
  email: "",
  phone: "",
  facebook: "",
  instagram: "",
  youtube: "",
  active: true,
  color: "",
  cover: "",
  background: "",
  airtableSync: false,
  cront: false,
  whatsapp: false,
  airtableKey: "",
  airtableBase: "",
  bucket: "",
};

const CheckboxGroup = Checkbox.Group;
const defaultCheckedList = ["Apple", "Orange"];

const ManageInstanceForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const sessionStoreRedux = useSelector((store) => store.session);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const [selectedInstance, setSelectedInstance] = useState(defaultValues);
  const [form] = Form.useForm();
  const [loaderForm, setLoaderForm] = useState(false);

  useEffect(() => {
    setLoaderForm(true);
    getInstance(true, {
      onSuccess: (response) => {
        setInstanceRedux(response.result)(dispatch);
        setSelectedInstance({
          ...response.result,
        });
        setLoaderForm(false);
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
  }, []);

  useEffect(() => {
    form.setFieldsValue(selectedInstance);
  }, [form, selectedInstance]);

  const onFinish = (values) => {
    const data = { ...selectedInstance, ...values };

    saveInstance(
      {
        ...data,
        rootId: sessionStoreRedux.currentSession_id,
      },
      {
        onSuccess: (response) => {
          setInstanceRedux(response.result)(dispatch);
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleFinishConfirm = (values) => {
    confirmAlert({
      title: t("update"),
      message: `Esta seguro que desea continuar ?`,
      buttons: [
        {
          label: t("yes"),
          onClick: () => onFinish(values),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const onValuesChange = debounce((values) => {
    setSelectedInstance({ ...selectedInstance, ...values });
  }, 500);

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  const handleHasAnalytics = (checked) => {
    setSelectedInstance({ ...selectedInstance, hasAnalytics: checked });
  };

  const handleAirtableSync = (checked) => {
    setSelectedInstance({ ...selectedInstance, airtableSync: checked });
  };

  const handleWhatsappSync = (checked) => {
    setSelectedInstance({ ...selectedInstance, whatsappSync: checked });
  };

  const handleCront = (checked) => {
    setSelectedInstance({ ...selectedInstance, cront: checked });
  };

  return (
    <Form
      form={form}
      name="manage-category-form-modal"
      initialValues={defaultValues}
      className="manage-modal"
      onFinish={handleFinishConfirm}
      labelCol={{ span: 24 }}
      onValuesChange={onValuesChange}
    >
      <div className="layout-form-modal entity-650 entity-small" style={{ height: 800 }}>
        {loaderForm ? (
          <>
            <Spin tip={t("loading")} />
          </>
        ) : (
          <>
            <Row>
              <Col span={24}>
                <Form.Item>
                  <Button
                    style={{ float: "right" }}
                    className="ant-btn secondary primary large"
                    size="large"
                    onClick={handleSubmit}
                  >
                    {t("updateInformation")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item
                  name="hasAnalytics"
                  labelAlign="right"
                  label={t("hasAnalytics")}
                  tooltip="Activa el contenido para que se visualice en el website"
                >
                  <Switch checked={!!selectedInstance?.hasAnalytics} onChange={handleHasAnalytics} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item initialValue={checkedList} name="modules" label="Modulos del Sistema">
                  <CheckboxGroup style={{ width: "100%" }} defaultValue={checkedList}>
                    <Row gutter={15}>
                      <Col span={4}>
                        <Checkbox value="event">Eventos</Checkbox>
                      </Col>
                      <Col span={4}>
                        <Checkbox value="product">Venta de Productos</Checkbox>
                      </Col>
                      <Col span={4}>
                        <Checkbox value="rental">Alquiler</Checkbox>
                      </Col>
                      <Col span={4}>
                        <Checkbox value="plan">Subscripciones</Checkbox>
                      </Col>
                    </Row>
                    <hr />
                    <Row gutter={16} style={{ marginTop: 20 }}>
                      <Col span={24}>Modulos extras</Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={4}>
                        <Checkbox value="webcontent">Web Content</Checkbox>
                      </Col>
                      <Col span={4}>
                        <Checkbox value="blog">Blog</Checkbox>
                      </Col>

                      <Col span={4}>
                        <Checkbox value="contact">Contacts</Checkbox>
                      </Col>
                      <Col span={4}>
                        <Checkbox value="schedule">Schedule</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="analyticsAdvanced">Analytics Advanced</Checkbox>
                      </Col>
                    </Row>
                  </CheckboxGroup>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="hostname" label={t("hostname")}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="website" label={t("website")}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item name="keypublic" label={t("keypublic")}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="airtableSync" label="Airtable Sincronizacion">
                  <Switch checked={!!selectedInstance?.airtableSync} onChange={handleAirtableSync} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="airtableKey" label="Airtable Key">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="airtableBase" label="Airtable Base">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="whatsappSync" label="Sincronización Whatsapp">
                  <Switch checked={!!selectedInstance?.whatsappSync} onChange={handleWhatsappSync} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="whatsapp" label="Whatsapp">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="cront" label="Activacion del Cront">
                  <Switch checked={!!selectedInstance?.cront} onChange={handleCront} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="logo" label={t("logo")}>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="logowhite" label={t("logowhite")}>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="background"
                  label={t("background")}
                  rules={[{ required: true, message: `Por favor ingrese el background` }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={4}>
                <Form.Item name="bucket" label="Nombre del Bucket en S3">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={20}>
                <Form.Item name="slackhook" label="Webhook para notificaciones">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="endpointCluuf" label={t("endpointCluuf")}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="phoneextra" label={t("phoneextra")}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <h4>Pushover Integrations Credentials</h4>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="pushovertoken" label={t("PushOver token")}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="pushoveruser" label={t("PushOverUser")}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Form>
  );
};

ManageInstanceForm.defaultProps = {
  setInstanceId: () => {},
};

export default ManageInstanceForm;
