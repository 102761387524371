import { Table, Space } from "antd";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import BaseTable from "../../shared/components/BasicTable";

import { eventCategoryFormat, isRoleAdmin, icons } from "../../../constants/globals";

const EventsTable = ({
  selectedUser,
  setSelectedUser,
  setPage,
  history,
  events,
  refetch,
  initialValues,
  handleDetails,
  handleEdit,
  userId,
  handleArchived,
  openDetailsBucketModal,
  handleDuplicate,
  t,
  ...restProps
}) => {
  const sessionStoreRedux = useSelector((store) => store.session);
  const { role } = sessionStoreRedux.currentSession;

  return (
    <>
      <BaseTable {...restProps} dataSource={events} refetch={refetch} scroll={{ y: 450 }}>
        <Table.Column
          title={t("category")}
          dataIndex="category"
          width="120px"
          key="category"
          render={(text) => eventCategoryFormat(text)}
        />
        <Table.Column
          title={t("user")}
          dataIndex="userId"
          key="usuario"
          render={(value) => (
            <span style={{ textTransform: "uppercase" }}>
              {value?.firstname || ""} {value?.lastname || ""}
            </span>
          )}
        />
        <Table.Column title={t("date")} dataIndex="fecha" key="fecha" render={(row) => row} />
        <Table.Column title={t("time")} dataIndex="hora" key="hora" render={(row) => row} />
        <Table.Column width="450px" title={t("description")} dataIndex="description" key="description" />

        <Table.Column
          width="60px"
          title={t("actions")}
          dataIndex="actions"
          key="actions"
          align="center"
          render={(_, data) => (
            <Space size="small">
              {isRoleAdmin(role) && (
                <a href="#!" onClick={() => handleArchived(data)} role="button" tabIndex={0}>
                  {icons.delete}
                </a>
              )}

              <a href="#!" onClick={() => handleDuplicate(data)} role="button" tabIndex={0}>
                {icons.duplicate}
              </a>
            </Space>
          )}
        />
      </BaseTable>
    </>
  );
};

export default withRouter(EventsTable);
