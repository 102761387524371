import { Bar } from "@ant-design/charts";

const DemoBar = ({ data }) => {
  const config = {
    data,
    xField: "value",
    yField: "type",
    seriesField: "type",
    legend: {
      position: "top-left",
    },
  };
  return <Bar {...config} />;
};

export default DemoBar;
