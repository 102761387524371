import { FetchJson } from "../../helpers/fetch";
import { apiRoutes } from "../../constants/routes";
import { Excel } from "../../helpers/excelExport";
import { birthdate, dateFormat4, priceFormat, dateFormat6 } from "../../constants/globals";

const saveApp = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { appId } = props;
  const method = appId ? "PUT" : "POST";
  const path = appId ? `${apiRoutes.APPS.SAVE_DATA}/${appId}` : `${apiRoutes.APPS.SAVE_DATA}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const closeWebApp = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { appId } = props;
  const method = "PUT";
  const path = `${apiRoutes.APPS.CLOSED_WEB_APP}/${appId}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const getApp = async (appId = 0, { onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.APPS.GET_DATA}/${appId}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getAppsByPlanId = async (planId = 0, { onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.APPS.APPS_BY_PLANID}/${planId}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getAppCountPending = async ({ onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.APPS.COUNT_PENDING}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getApps = async ({ onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.APPS.ALL}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getAppsByDate = async (params = {}, { onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.APPS.FILTERSBYDATE}`, "POST", params);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const archivedApp = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id } = params.app;
  const method = "PUT";
  const path = `${apiRoutes.APPS.ARCHIVED}/${_id}`;

  try {
    const request = await FetchJson(path, method, { archived: true });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const activeApp = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id, active } = params.app;
  const method = "PUT";
  const path = `${apiRoutes.APPS.ACTIVE}/${_id}`;

  try {
    const request = await FetchJson(path, method, { active: !active });
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const closedApp = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const method = "PUT";
  const path = `${apiRoutes.APPS.CLOSED_APP}`;

  try {
    const request = await FetchJson(path, method, params);
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const insituApp = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id, insitu } = params.app;
  const method = "PUT";
  const path = `${apiRoutes.APPS.INSITU}/${_id}`;

  try {
    const request = await FetchJson(path, method, { insitu: !insitu });
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const recalculateApp = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id } = params.app;
  const method = "POST";
  const path = `${apiRoutes.APPS.RECALCULATE}`;

  try {
    const request = await FetchJson(path, method, { appId: _id });
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const evaluateAppsByInstance = async (params = {}, { onSuccess } = {}) => {
  const method = "POST";
  const path = `${apiRoutes.APPS.RECALCULATE_ALL}`;

  try {
    const request = await FetchJson(path, method, {});
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
  }
};

const recalculateApps = async ({ onSuccess = {}, onError = {}, onFinally = {} }) => {
  const method = "POST";
  const path = `${apiRoutes.APPS.RECALCULATE_ALL}`;
  try {
    const request = await FetchJson(path, method, {});
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const saveAppFromUser = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const method = "POST";
  const path = `${apiRoutes.APPS.SAVE_DATA}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const statusApp = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const method = "POST";
  const path = `${apiRoutes.APPS.STATUS}/${params._id}`;

  try {
    const request = await FetchJson(path, method, params);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const excelReportApp = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const method = "POST";
  const path = `${apiRoutes.APPS.EXCEL_REPORT}`;

  try {
    const request = await FetchJson(path, method, params);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const exportExcel = (data1, { onSuccess = {} }) => {
  let row = "";
  let title = "";
  let filename = "";
  // eslint-disable-next-line prefer-const
  let contador = 0;

  data1.forEach((item) => {
    filename = `${parseInt(contador) + 1} Aplicaciones para ${item.planId.name}`;
    title = item.planId.name;

    row = `${row} <tr>
        <td>${item.code || ""}</td>
        <td>${item.appId.status}</td>
        <td>${item.packId.name}</td>
        <td>${item.planId.name}</td>
        <td>${dateFormat6(item.startDate)}</td>
        <td>${dateFormat6(item.closureDate)}</td>
        <td>${item.userId.firstname}</td>
        <td>${item.userId.lastname || ""}</td>
        <td>${item.userId.email}</td>
        <td>${item.userId.documentType || ""}</td>
        <td style="text-align:left">${item.userId.document || ""}</td>
        <td>${!item.planId.isFree ? priceFormat(item.planId.price) : 0}</td>
        <td>${item.paymentMode || ""}</td>
        <td>${item.cupon || ""}</td>
        <td>${priceFormat(item.amount || "")}</td>
        <td>${dateFormat4(item.creartedAt)}</td>
        <td>${birthdate(item.userId.birthdate)}</td>
        <td>${item.userId.address || ""}</td>
        <td>${item.userId.medium || ""}</td>
        <td>${item.observation || ""}</td>
       </tr>`;
  });

  const head = `
      <tr>
      <td colspan="5"><h3>${title}</h3></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td><b>Codigo</b></td>
      <td><b>Paquete</b></td>
      <td><b>Plan</b></td>
      <td><b>Desde</b></td>
      <td><b>Hasta</b></td>
      <td><b>Nombres</b></td>
      <td><b>Apellidos</b></td>
      <td><b>Email</b></td>
      <td><b>Tipo de Documento</b></td>
      <td><b>Documento</b></td>
      <td><b>Costo Plan</b></td>
      <td><b>Modalidad de pago</b></td>
      <td><b>Cupón</b></td>
      <td><b>Monto a Pagar</b></td>
      <td><b>Fecha registro</b></td>
      <td><b>Edad</b></td>
      <td><b>Dirección de Habitación</b></td>
      <td><b>¿Como nos conoció?</b></td>
      <td><b>Observación</b></td>
    
      </tr>`;

  Excel(`${head}${row}`, filename);
};

const exportExcelApps = ({ planId = null, packId = null, apps = null }) => {
  exportExcel(apps, {
    onSuccess: () => {},
  });
};

const duplicateApp = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { appId } = props;
  const method = "POST";
  const path = `${apiRoutes.APPS.DUPLICATE}/${appId}`;

  try {
    const request = await FetchJson(path, method, props);
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError(error);
  } finally {
    onFinally();
  }
};

const newAppByApp = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const method = "PUT";
  const path = `${apiRoutes.APPS.NEW_APP_BY_APP}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const renovateAppByApp = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const method = "PUT";
  const path = `${apiRoutes.APPS.RENOVATE_APP_BY_APP}`;

  try {
    const request = await FetchJson(path, method, props);
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const saveAppByUser = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const method = "PUT";
  const path = `${apiRoutes.APPS.NEW_APP_BY_USER}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const updateSubscriptionApp = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const method = "PUT";
  const path = `${apiRoutes.APPS.EDIT_SUBSCRIPTION_APP}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const saveSingleAppByUser = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const method = "PUT";
  const path = `${apiRoutes.APPS.NEW_SINGLE_APP_BY_USER}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

export {
  recalculateApp,
  recalculateApps,
  duplicateApp,
  exportExcelApps,
  getApp,
  saveApp,
  archivedApp,
  activeApp,
  saveAppFromUser,
  statusApp,
  getApps,
  newAppByApp,
  renovateAppByApp,
  saveAppByUser,
  saveSingleAppByUser,
  insituApp,
  evaluateAppsByInstance,
  excelReportApp,
  getAppCountPending,
  closedApp,
  getAppsByPlanId,
  updateSubscriptionApp,
  closeWebApp,
  getAppsByDate,
};
