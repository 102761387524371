import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function App({ setHTML = "", getHTML = {}, setCountHTML, html_ = "", fix = false }) {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [editorContentHtml, setEditorContentHtml] = useState();
  const [count, setCount] = useState(0);

  const html = setHTML;
  const contentBlock = htmlToDraft(html || "pedrooo");

  useEffect(() => {
    setEditorContentHtml(stateToHTML(editorState.getCurrentContent()));

    //  esto evita que al iniciar por primera vez envie valores vacios ejemplo <p></p>
    if (count < 2) getHTML(setHTML);
    if (count > 1) getHTML(editorContentHtml);
    setCount(count + 1);
  }, [editorState]);

  useEffect(() => {
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks)));
  }, []);

  return (
    <div>
      <div style={{ border: "1px solid #fcfcfc", background: "#fcfcfc", padding: "10px", minHeight: "200px" }}>
        <Editor editorState={editorState} onEditorStateChange={setEditorState} />
      </div>
    </div>
  );
}
