/* eslint-disable valid-typeof */
/* eslint-disable no-unused-expressions */
import { useState, useEffect } from "react";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import HeaderComponent from "../features/shared/components/Header";
import FooterComponent from "../features/shared/components/Footer";
import InstanceContainer from "../features/instance/containers/Instance";
import ProfileContainer from "../features/profile/containers/Profile";
import BucketContainer from "../features/bucket/containers/Buckets";
import MetricsContainer from "../features/metrics/containers/Metrics";
import EventsContainer from "../features/events/containers/Event";

import Contentv2Container from "../features/contentsv2/containers/Packs";
import ContentPlansv2Container from "../features/contentsPlansv2/containers/Plans";
import ContentPlansUploadEmailContainer from "../features/contentsPlansv2/containers/PlansUploadImage";

import DashboardsContainer from "../features/subscribersDashboard/containers/Dashboard";

import SubscriptionsContainer from "../features/subscribers/containers/Packs";
import SubscriptionsPlansContainer from "../features/subscribersPlans/containers/Plans";
import SubscriptionsPlansEventContainer from "../features/subscribersPlans/containers/PlansEvent";
import SubscriptionsPlansRentalContainer from "../features/subscribersPlans/containers/PlansRental";

import SubscriptionsPlansSubscriptionContainer from "../features/subscribersPlans/containers/PlansSubscription";

import SubscriptionsAppsContainer from "../features/subscribersApps/containers/Apps";
import SubscriptionsAppsRentalContainer from "../features/subscribersApps/containers/AppsRental";
import SubscriptionsUsersContainer from "../features/subscribersUsers/containers/Users";
import SubscriptionsTransContainer from "../features/subscribersTrans/containers/Trans";
import SubscriptionsRootsContainer from "../features/subscribersRoots/containers/Users";
import SubscriptionsAppsProductContainer from "../features/subscribersApps/containers/AppsProduct";
import SubscriptionsAppsEventContainer from "../features/subscribersApps/containers/AppsEvent";
import SubscriptionsAppsSubscriptionContainer from "../features/subscribersApps/containers/AppsSubscription";
import SubscriptionsSuppliersContainer from "../features/subscribersSuppliers/containers/index";
import SubscriptionsServicesContainer from "../features/subscribersServices/containers/index";
import SubscriptionsExpensesContainer from "../features/subscribersExpense/containers/index";

import MasterContainer from "../features/master/containers/Master";

import { getUser, isLocalStorage } from "../utils/localStorage";
import { validateSessionUser, validateSessionRoot } from "../features/subscribersUsers/requests";
import { setSession } from "../redux/session";

import { setDefaultMenu } from "../helpers/defaultMenu";
import { getSettings } from "../helpers/settings";

import { setCookies } from "../helpers/cookies";

import { spaRoutes } from "../constants/routes";
import { modules, globals, isModuleValid } from "../constants/globals";
import { loadBranding } from "../features/login/request";

const { Header, Content } = Layout;

const Home = ({ match, history }) => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState("3");
  const sessionStoreRedux = useSelector((store) => store.session);
  const instanceStoreRedux = useSelector((store) => store.instance);

  const [isPublic, setIsPublic] = useState(false);

  const handleMenuSelected = (number) => {
    history.push(`${spaRoutes.HOME}/${number}`);
  };

  const handleGoToWebsite = () => {
    if (getSettings().token && instanceStoreRedux.currentInstance) {
      window.open(
        `${instanceStoreRedux.currentInstance.website}?pin=${getSettings().pin}&login=true&token=${
          JSON.parse(localStorage.getItem("cluuf-settings")).token
        }`
      );
    } else {
      alert("Cluuf Settings Error");
    }
  };

  const handleLogout = (alias) => {
    setCookies("pclf", null);
    setCookies("uclf", null);
    setCookies("iclf", null);
    document.location = `../../login/${alias}`;
  };

  const isModuleValid2 = (moduleName) => {
    if (instanceStoreRedux) {
      if (instanceStoreRedux && instanceStoreRedux?.currentInstance.modules !== undefined) {
        const value = instanceStoreRedux?.currentInstance.modules.find((x) => x === moduleName);
        if (value !== undefined) return true;
        return false;
      }
    } else {
      return false;
    }
  };

  const handleGoToPremiumData = () => {
    if (getSettings().token && instanceStoreRedux.currentInstance) {
      window.open(
        `https://cluuftracks.herokuapp.com/admin/production/?page=resume&title=Resumen%20General&token-cluuf=${
          getSettings().token
        }&keypublic=${instanceStoreRedux.currentInstance.keypublic}&excludePage=${
          instanceStoreRedux.currentInstance.alias
        }`
      );
    } else {
      alert("Cluuf Settings Error");
    }
  };

  useEffect(() => {
    if (match.params.express === "express" && match.params.alias) {
      setIsPublic(true);
    }

    if (sessionStoreRedux?.currentSession && match.params.express === "express" && match.params.alias) {
      loadBranding(match.params.alias, {
        onSuccess: (response) => {
          if (response.ok) {
            console.log("aquiiiiii");
            setMenu(42);
          } else {
            document.location = "https://google.com";
          }
        },
        onError: () => {},
      });
    }

    global.session = getUser();

    if (global.session) {
      if (isLocalStorage()) {
        localStorage.setItem("alias", instanceStoreRedux.currentInstance.alias);

        if (localStorage.getItem("goto") === "analytics") {
          localStorage.removeItem("goto");
          document.location = `https://cluuftracks.herokuapp.com/admin/production/?page=resume&title=Resumen%20General&token-cluuf=${
            getSettings().token
          }&keypublic=${instanceStoreRedux.currentInstance.keypublic}&excludePage=${
            instanceStoreRedux.currentInstance.alias
          }`;
        }

        if (global.session.role === "CHECKIN") {
          document.location = `/userext/${instanceStoreRedux.currentInstance.alias}/${instanceStoreRedux.currentInstance.keypublic}/onHoldCheckin`;
        }

        if (global.session.role === "SUPERADMIN") {
          if (localStorage.getItem("goto") === "cluufnet") {
            if (global.session) {
              document.location = `https://s3.us-east-2.amazonaws.com/www.cluuf.net/gma.html?atm=a&agency=${
                instanceStoreRedux.currentInstance.alias
              }&user=${global.session._id}&token=${getSettings().token}&keypublic=${
                instanceStoreRedux.currentInstance.keypublic
              }&excludePage=${instanceStoreRedux.currentInstance.alias}`;
            }
          }
        }

        if (global.session?.role) {
          if (global.session.role === globals.ROLES.USER) {
            validateSessionUser({
              onSuccess: (data) => {
                if (data.ok) {
                  setSession(data.result)(dispatch);
                }
              },
            });
          }
          if (global.session.role === globals.ROLES.SUPERADMIN) {
            validateSessionRoot({
              onSuccess: (data) => {
                if (data.ok) {
                  setSession(data.result)(dispatch);
                }
              },
            });
          }

          if (global.session.role === globals.ROLES.SUPERVACA) {
            validateSessionRoot({
              onSuccess: (data) => {
                if (data.ok) {
                  setSession(data.result)(dispatch);
                }
              },
            });
          }
        } else if (!sessionStoreRedux?.currentSession?._id) {
          document.location = `../../../../login/${match.params.alias}`;
        }
      }
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !isPublic && setMenu(parseInt(match.params.type));
  }, [match.params.type]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !isPublic && setMenu(parseInt(match.params.type));

    if (typeof match.params.type === "undefined") {
      setMenu(31);
    }
  }, []);

  return (
    <Layout>
      {sessionStoreRedux?.currentSession?._id && !isPublic && (
        <HeaderComponent
          setDefaultMenu={setDefaultMenu}
          role={sessionStoreRedux.currentSession.role}
          menu={menu}
          handleMenuSelected={handleMenuSelected}
          handleGoToWebsite={handleGoToWebsite}
          handleGoToPremiumData={handleGoToPremiumData}
          instanceStoreRedux={instanceStoreRedux}
          handleLogout={handleLogout}
          isModuleValid={isModuleValid2}
          modules={modules}
          globals={globals}
        />
      )}
      <Layout>
        {!isPublic && (
          <Header
            role={sessionStoreRedux.currentSession.role}
            className="site-layout-sub-header-background"
            style={{ padding: 0 }}
          />
        )}
        {sessionStoreRedux.currentSession.role === globals.ROLES.SUPERADMIN && (
          <Content style={{ margin: "10px 16px 0" }}>
            <div className="site-layout-background" style={{ minHeight: 800 }}>
              {menu === 11 && <ProfileContainer />}
              {menu === 31 && <DashboardsContainer pageNum="31" AppId={match.params.id || null} />}

              {menu === 18 && <SubscriptionsPlansContainer pageNum="18" />}
              {menu === 19 && <SubscriptionsPlansEventContainer pageNum="19" view="table" />}
              {menu === 23 && <SubscriptionsPlansRentalContainer pageNum="23" />}

              {menu === 21 && <SubscriptionsPlansSubscriptionContainer pageNum="21" AppId={match.params.id || null} />}

              {menu === 29 && <SubscriptionsAppsContainer pageNum="29" AppId={match.params.id || null} />}
              {menu === 32 && <SubscriptionsAppsProductContainer pageNum="29" AppId={match.params.id || null} />}
              {menu === 33 && <SubscriptionsAppsEventContainer pageNum="29" AppId={match.params.id || null} />}
              {menu === 34 && <SubscriptionsAppsSubscriptionContainer pageNum="39" AppId={match.params.id || null} />}
              {menu === 35 && <SubscriptionsAppsRentalContainer pageNum="35" AppId={match.params.id || null} />}

              {menu === 36 && <SubscriptionsSuppliersContainer pageNum="36" SupplierId={match.params.id || null} />}
              {menu === 37 && <SubscriptionsServicesContainer pageNum="37" SupplierId={match.params.id || null} />}

              {/* menu === 25 && <SubscriptionsExpensesContainer pageNum="25" SupplierId={match.params.id || null} /> */}

              {menu === 38 && <SubscriptionsTransContainer pageNum="38" TransId={match.params.id || null} />}
              {menu === 30 && <SubscriptionsUsersContainer pageNum="30" AppId={match.params.id || null} />}
              {menu === 17 && <SubscriptionsContainer />}
              {menu === 39 && <SubscriptionsRootsContainer pageNum="39" RootId={match.params.id || null} />}
              {menu === 40 && <Contentv2Container pageNum="40" PackId={match.params.id || null} />}
              {menu === 41 && <ContentPlansv2Container pageNum="41" PlanId={match.params.id || null} />}
              {menu === 42 && <ContentPlansUploadEmailContainer pageNum="42" PlanId={match.params.id || null} />}

              {menu === 13 && <MetricsContainer />}
              {menu === 10 && <InstanceContainer />}
              {menu === 3 && <MasterContainer />}
              {menu === 28 && <EventsContainer />}
              {menu === 12 && <BucketContainer />}
            </div>
          </Content>
        )}

        <FooterComponent />
      </Layout>
    </Layout>
  );
};

export default withRouter(Home);
