import { Table, Tooltip } from "antd";

import { withRouter } from "react-router-dom";
import BaseTable from "../../shared/components/BasicTable";

const PlansTable = ({ data }) => {
  console.log(data);
  return (
    <>
      <BaseTable pagination="false" dataSource={data}>
        <Table.Column
          title="Contacto"
          dataIndex="value"
          key="value"
          render={(text, row) => (
            <p style={{ textTransform: "uppercase", marginBottom: 0 }}>
              {`${row?.userId?.firstname} ${row?.userId?.lastname || ""}`}{" "}
              <Tooltip title="Asistencías">
                <sup> ({row?.checkins?.length}) </sup>
              </Tooltip>
            </p>
          )}
        />
      </BaseTable>
    </>
  );
};

export default withRouter(PlansTable);
