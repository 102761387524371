import { useEffect, useState } from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";

import { getSuppliers } from "../requests";

const { Option } = Select;

const SelectSuppliers = ({ defaultValue, returnData, currentValue, reload, title }) => {
  const [data, setData] = useState(null);
  const [value, setValue] = useState(currentValue);
  const { currentSupplier: SupplierRedux } = useSelector((store) => store.supplier);

  useEffect(() => {
    getSuppliers({
      onSuccess: (res) => {
        setData(res.result);
      },
      onError: () => {},
    });
  }, []);

  useEffect(() => {
    setValue(null);
  }, [reload]);

  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  useEffect(() => {
    if (SupplierRedux?._id) {
      setValue(SupplierRedux._id);
    }
  }, [SupplierRedux]);

  const handleChange = (value__) => {
    setValue(value__);
    returnData(value__);
  };
  return (
    <Select
      showSearch
      onChange={handleChange}
      value={value}
      defaultValue={defaultValue}
      style={{ width: "100%" }}
      placeholder="Search to Select"
      optionFilterProp="children"
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
    >
      <Option key="0" value={null}>
        {title || "Seleccione"}
      </Option>
      {data &&
        data?.map((item) => (
          <Option key={item._id} value={item._id}>
            <span style={{ textTransform: "uppercase" }}>{` ${item.fullname}`}</span>
          </Option>
        ))}
    </Select>
  );
};

export default SelectSuppliers;
