import { Drawer } from "antd";
import AddPaymentAnalist from "./addPaymentAnalist";

const UpdatePayment = ({ t, open, setOpen, reload, selectedTrans, handleOpenApp, isEmbed }) => {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer title="Detalles del Pago" width={600} onClose={onClose} visible={open} bodyStyle={{ paddingBottom: 80 }}>
        <AddPaymentAnalist
          onClose={onClose}
          t={t}
          reload={reload}
          userSelected={selectedTrans.userId}
          transSelected={selectedTrans}
          appSelected={selectedTrans.appId}
          appId={selectedTrans.appId._id || null}
          packId={selectedTrans?.packId._id || null}
          planId={selectedTrans?.planId._id || null}
          transId={selectedTrans._id}
          userId={selectedTrans?.userId._id || null}
          handleOpenApp={handleOpenApp}
          isEmbed={isEmbed}
        />
      </Drawer>
    </>
  );
};

export default UpdatePayment;
