import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { withRouter } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";

// COMPONENTS
import PlansTable from "../components/PlansTable";
import ManagePlanModal from "../components/ManagePlanModal";
import AddPrimaryButton from "../components/AddPlanButton";
import PlanHeader from "../components/PlanHeader";
import PlansHeader from "../components/PlansHeader";
import PlansCard from "../components/PlansCard";
import TabMenu from "../components/tabs";
import ActionBar from "../components/ActionBar";
import FiltersTable from "../components/FiltersTable";
import SearchPlansForm from "../components/SearchPlansForm";
import ManagePlanForm from "../components/ManagePlanForm";
import SelectPacks from "../../contentsv2/components/SelectPacks";
import SelectCategoryPack from "../../contentsv2/components/SelectCategoryPack";

import ManagePlanFormExternal from "../components/ManagePlanFormExternal";
import BucketContainer from "../../bucket/containers/Buckets";
import ManageBucketModal from "../../bucket/components/ManageBucketModal";

// HELPERS
import showGlobalNotification from "../../../helpers/showGlobalNotification";

// REDUX
import { setPlan, setPlanRedux } from "../../../redux/plan";

// REQUEST
import {
  getPlan,
  archivedPlan,
  activePlan,
  refreshJsonPlanToS3,
  savePlan,
  getPlanByCode,
  duplicatePlansByCategoryPack,
} from "../requests";

// HOOKS
import usePlans from "../hooks/usePlans";
import useModal from "../../shared/hooks/useModal";

// CONTAINERS

// CONSTANTS
import { globals, numbers, packs } from "../../../constants/globals";

const typePack = packs.CONTENT;

const Swal = require("sweetalert2");

const Plans = ({
  PlanId = null,
  isEmbed = false,
  appId = null,
  packId = null,
  userId = null,
  packData = {},
  history,
  match,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const planStoreRedux = useSelector((store) => store.plan);
  const sessionStoreRedux = useSelector((store) => store.session);

  const [goback, setGoback] = useState(false);

  const { plans, filters, setFilters, pagination, setPagination, loading, refetch } = usePlans(
    appId,
    packId,
    userId,
    typePack
  );

  const [isOpenManageBucketModal, openManageBucketModal, closeManageBucketModal] = useModal();

  const commonProps = { filters, setFilters };
  const [page, setPage] = useState();
  const [subpage, setSubpage] = useState(numbers.THREE);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [containerMode, setContainerMode] = useState(globals.TABLE);
  const [type, setType] = useState(typePack);
  const [refresh, setRefresh] = useState(true);

  const [planId, setPlanId] = useState(null);
  const [isExpress, setExpress] = useState(false);

  const [isOpenManagePlanModal, openManagePlanModal, closeManagePlanModal] = useModal();

  const [initialValues] = useState({
    name: "",
    duration: "",
    category: "",
    maxLimit: 1,
    minLimit: 1,
    observation: "",
    active: true,
    tag: "",
    price: "",
    avatar: "",
    public: false,
    startdate: "2020/01/01",
    closuredate: "2020/01/01",
  });

  const refreshPlan = (planIdTemp, { onSuccess = {}, onError = {} }) => {
    getPlan(planIdTemp, {
      onSuccess: (response) => {
        setSelectedPlan(response.result);
        setPlanRedux(response.result);
        if (response.result) {
          setPlanId(response.result._id);
          onSuccess(response.result);
        } else onError();
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
  };

  const getPlanExpress = (planIdTemp, { onSuccess = {}, onError = {} }) => {
    getPlanByCode(planIdTemp, {
      onSuccess: (response) => {
        setSelectedPlan(response.result);
        setPlanRedux(response.result);
        if (response.result) {
          setPlanId(response.result._id);
          onSuccess(response.result);
        } else onError();
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
  };

  const handleEdit = (plan) => {
    setSelectedPlan(plan);
    setPlanId(plan._id);
    setType(plan.type);
    openManagePlanModal();
  };

  const handleBucketkModal = (plan) => {
    setPlanId(plan._id);
    openManageBucketModal();
  };

  const refreshByPacks = (data) => {
    setFilters({ ...filters, packId: data });
    refetch();
  };

  useEffect(() => {
    if (match.params.id && match.params.express === "express" && match.params.type) {
      getPlanExpress(match.params.id, {
        onSuccess: () => {
          setPage(globals.PAGE_EXPRESS);
          setExpress(true);
        },
      });
    } else {
      setPage(globals.PAGE_MAIN);
    }
    setRefresh(true);
  }, []);

  useEffect(() => {
    if (selectedPlan) {
      setPlan(selectedPlan)(dispatch);
      setPlanId(selectedPlan._id);
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (page === globals.PAGE_MAIN) {
      setSelectedPlan({});
      setPlanId(null);
    }
  }, [page]);

  const handleArchivedConfirm = (plan) => {
    archivedPlan(
      {
        plan,
      },
      {
        onSuccess: () => {
          refreshPlan(plan._id, {
            onSuccess: () => {
              refetch();
            },
            onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
          });
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleUploadJsonPlanToS3 = () => {
    refreshJsonPlanToS3(
      {},
      {
        onSuccess: () => {
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleArchived = (plan) => {
    setPlanId(plan._id);
    confirmAlert({
      title: `${t("delete")} ${plan.name}`,
      message: `Esta seguro que desea eliminar el setting de ${plan.name}?`,
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleArchivedConfirm(plan),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleActiveConfirm = (plan) => {
    activePlan(
      {
        plan,
      },
      {
        onSuccess: () => {
          refreshPlan(plan._id, {
            onSuccess: () => {
              refetch();
            },
            onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
          });
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleGoto = ({ number, plan, id = null }) => {
    if (id) history.push(`/home/27/${id}`);
    else history.push(`/home/27/${plan._id}`);
  };

  const handleActive = (plan) => {
    setPlanId(plan._id);
    confirmAlert({
      title: plan.active ? "Desactivar el Paquete" : "Activar el Paquete",
      message: "Esta seguro que desea continuar?",
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleActiveConfirm(plan),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  // double click for more details
  const handleDetails = (plan) => {
    if (plan._id) {
      setPage(globals.PAGE_ONE);
      setSubpage(numbers.THREE);
      setSelectedPlan(plan);
      refreshPlan(plan._id, {
        onSuccess: () => {},
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  };

  const modeDetails = (plan) => {
    if (isEmbed) {
      handleGoto({ number: 22, plan });
    } else {
      handleDetails(plan);
    }

    //  handleDetails(plan);
  };

  const resetFilters = () => {
    setFilters({ ...filters, all: "" });
    refetch();
  };

  const handleCategory = (res) => {};

  const handleCategoryInfo = (res) => {
    if (String(res._id).length > 11) setFilters({ ...filters, categoryPack: res.name });
  };

  const handleSavePlan = (data) => {
    savePlan(
      {
        ...data,
        planId,
        rootId: sessionStoreRedux.currentSession_id,
      },
      {
        onSuccess: (response) => {
          refetch();
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleDuplicate = (plan) => {
    if (plan._id) {
      setSelectedPlan(plan);
      refreshPlan(plan._id, {
        onSuccess: (result) => {
          confirmAlert({
            title: `${t("duplicate")} ${plan.name}`,
            message: `Esta seguro que desea Duplicar este registro?`,
            buttons: [
              {
                label: t("yes"),
                onClick: () => {
                  handleSavePlan({
                    ...result,
                    planId: false,
                    name: `${plan.name}-duplicate-${Math.floor(Math.random() * 100)}`,
                  });
                },
              },
              {
                label: t("no"),
                onClick: () => {},
              },
            ],
          });
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  };

  const handleDuplicateCategoryPack = () => {
    let text;
    confirmAlert({
      title: `Duplicar pack`,
      message: `Esta seguro que desea Duplicar esta categoria?`,
      buttons: [
        {
          label: t("yes"),
          onClick: () => {
            Swal.fire({
              title: "Submit your Github username",
              input: "text",
              inputAttributes: {
                autocapitalize: "off",
              },
              showCancelButton: true,
              confirmButtonText: "Look up",
              showLoaderOnConfirm: true,
              preConfirm: (login) => {
                text = login;
              },
            }).then((result) => {
              if (result.isConfirmed) {
                if (String(filters.categoryPack).length > 4) {
                  duplicatePlansByCategoryPack(
                    { newCategory: text, category: filters.categoryPack },
                    {
                      onSuccess: () => {},
                      onError: () => {},
                      onFinally: () => {},
                    }
                  );
                }
              }
            });
            /*
            handleSavePlan({
              ...result,
              planId: false,
              name: `${plan.name}-duplicate-${Math.floor(Math.random() * 100)}`,
            });
            */
          },
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleNew = () => {
    openManagePlanModal();
  };

  const formatBody = ({ category, content }) => {
    if (category === "IMAGE" || category === "BACKGROUND") {
      return <img alt={content} src={content} width="50" />;
    }

    if (category === "TEXT" || category === "LINK" || category === "HTML") {
      return content.slice(0, 80);
    }

    if (category === "LIST") {
      return content;
    }

    if (category === "EMAIL") {
      return content.slice(0, 80);
    }

    if (category === "COLOR") {
      return `<span style="color:${content}; font-weight:bold; font-size:18px">${content}</span>`;
    }
  };

  useEffect(() => {
    //  debounce(() => refetch(), 500);
    refetch();
  }, [filters]);

  return (
    <>
      {page === globals.PAGE_EXPRESS && (
        <>
          {selectedPlan.category === "TEXT" && (
            <div style={{ width: 800, height: 700, margin: "auto" }}>
              <ManagePlanFormExternal planId={planId} setPlanId={setPlanId} typePack={typePack} refetch={refetch} />
            </div>
          )}

          {selectedPlan.category === "LIST" && (
            <div style={{ width: 800, height: 700, margin: "auto" }}>
              <ManagePlanFormExternal planId={planId} setPlanId={setPlanId} typePack={typePack} refetch={refetch} />
            </div>
          )}

          {selectedPlan.category === "LINK" && (
            <div style={{ width: 800, height: 700, margin: "auto" }}>
              <ManagePlanFormExternal planId={planId} setPlanId={setPlanId} typePack={typePack} refetch={refetch} />
            </div>
          )}

          {selectedPlan.category === "COLOR" && (
            <div style={{ width: 800, height: 700, margin: "auto" }}>
              <ManagePlanFormExternal planId={planId} setPlanId={setPlanId} typePack={typePack} refetch={refetch} />
            </div>
          )}

          {selectedPlan.category === "HTML" && (
            <div style={{ width: 800, height: 700, margin: "auto" }}>
              <ManagePlanFormExternal planId={planId} setPlanId={setPlanId} typePack={typePack} refetch={refetch} />
            </div>
          )}

          {(selectedPlan.category === "IMAGE" || selectedPlan.category === "BACKGROUND") && (
            <div style={{ width: 400, margin: "auto" }}>
              <p style={{ textAlign: "center", padding: "20px 4px 10px" }}>
                <img
                  alt="bot"
                  src="https://cluuftracks.herokuapp.com/bot2.gif"
                  width="70px"
                  style={{ marginTop: "10px" }}
                />

                <h4 style={{ paddingTop: 10 }}>CLUUF CONTENT MANAGER</h4>
              </p>

              <p
                style={{
                  background: "#e2ffd4",
                  border: "1px solid #9ec58a",
                  padding: 10,
                  textAlign: "center",
                  marginBottom: 20,
                  borderRadius: 7,
                  marginTop: 10,
                }}
              >
                {selectedPlan.description}
              </p>

              <p style={{ textAlign: "center" }}>
                <BucketContainer page_={globals.PAGE_EXPRESS} isEmbed planId={planId} />
              </p>
            </div>
          )}
        </>
      )}
      {page === globals.PAGE_MAIN && (
        <div>
          {!isEmbed && (
            <PlansHeader
              primaryAction={
                <>
                  <SelectCategoryPack
                    setPackInfo={handleCategoryInfo}
                    returnData={handleCategory}
                    title="Seleccione Categoria"
                  />
                  <AddPrimaryButton
                    SelectPacks={SelectPacks}
                    refreshByPacks={refreshByPacks}
                    openManagePlanModal={openManagePlanModal}
                    isOpenManagePlanModal={isOpenManagePlanModal}
                    setContainerMode={setContainerMode}
                    containerMode={containerMode}
                    handleBucketkModal={handleBucketkModal}
                    uploadJsonPlanToS3={handleUploadJsonPlanToS3}
                    packId={packId}
                    t={t}
                    handleDuplicateCategoryPack={handleDuplicateCategoryPack}
                    filters={filters}
                  />
                </>
              }
            />
          )}
          <div className={isEmbed ? "" : "container-subpages"}>
            <ActionBar t={t} {...commonProps} handleNew={handleNew} options={{ actions: FiltersTable, handleNew }}>
              <SearchPlansForm
                resetFilters={resetFilters}
                t={t}
                refetch={refetch}
                filters={filters}
                setFilters={setFilters}
                handleNew={handleNew}
              />
            </ActionBar>

            {containerMode === globals.TABLE && (
              <PlansTable
                {...commonProps}
                plans={plans}
                handleDuplicate={handleDuplicate}
                pagination={pagination}
                setPagination={setPagination}
                loading={loading}
                refetch={refetch}
                initialValues={initialValues}
                setPage={setPage}
                setSelectedPlan={setSelectedPlan}
                selectedPlan={selectedPlan}
                handleDetails={handleDetails}
                handleEdit={handleEdit}
                details={modeDetails}
                handleArchived={handleArchived}
                handleActive={handleActive}
                packData={packData}
                isEmbed={isEmbed}
                formatBody={formatBody}
                handleBucketkModal={handleBucketkModal}
                t={t}
              />
            )}
            {containerMode === globals.CARD && (
              <PlansCard
                {...commonProps}
                plans={plans}
                pagination={pagination}
                setPagination={setPagination}
                loading={loading}
                refetch={refetch}
                setPage={setPage}
                setSelectedPack={setSelectedPlan}
                selectedBucket={selectedPlan}
                handleDetails={handleDetails}
                handleEdit={handleEdit}
                details={handleDetails}
                handleArchived={handleArchived}
                packData={packData}
              />
            )}
          </div>

          {isExpress && (
            <div className={isEmbed ? "" : "container-subpages"}>
              <ManagePlanForm
                planId={planId}
                setPlanId={setPlanId}
                closeModal={closeManagePlanModal}
                openModal={isOpenManagePlanModal}
                typePack={typePack}
                refetch={refetch}
              />
            </div>
          )}
        </div>
      )}
      {page === globals.PAGE_ONE && (
        <>
          <PlanHeader
            refetch={refetch}
            setPage={setPage}
            selectedPlan={selectedPlan}
            handleDetails={handleDetails}
            handleEdit={handleEdit}
            setSubpage={setSubpage}
            handleArchived={handleArchived}
            refreshPlan={refreshPlan}
            handleBucketkModal={handleBucketkModal}
            isGoback={goback}
            handleGoto={handleGoto}
            t={t}
          />

          <div className="container-subpages">
            <TabMenu setSubpage={setSubpage} t={t} numbers={numbers} />

            {subpage === numbers.THREE && (
              <div className="subcontainer-wrapper">
                {planId && <BucketContainer entity="Plan" isEmbed planId={planId} entityId={planId} />}
              </div>
            )}
          </div>
        </>
      )}
      <ManagePlanModal
        refetch={refetch}
        planId={planId}
        setPlanId={setPlanId}
        closeModal={closeManagePlanModal}
        openModal={isOpenManagePlanModal}
        typePack={typePack}
        packId={packId}
        t={t}
      />
      <ManageBucketModal
        entityProp="Plancontent"
        typeProp="Gallery"
        entityId={planId}
        openModal={isOpenManageBucketModal}
        closeModal={closeManageBucketModal}
      />
    </>
  );
};

export default withRouter(Plans);
