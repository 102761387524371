import { Select } from "antd";
import { filter } from "lodash";

const { Option } = Select;

const SelectTypeApp = ({ setFilters, defaultValue, filters }) => {
  const onValuesChange = (values) => {
    setFilters({ ...filters, type: values });
  };
  return (
    <>
      <Select defaultValue={defaultValue} style={{ width: 200 }} onChange={onValuesChange}>
        <Option value="SUBSCRIPTION">SUBSCRIPTION</Option>
        <Option value="SERVICE">SERVICE</Option>
      </Select>
    </>
  );
};

export default SelectTypeApp;
