import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import ActionBar from "../../shared/components/ActionBar";
import TranssTable from "../components/TranssTable";
import SearchTranssForm from "../components/SearchTransForm";
import FiltersTable from "../components/FiltersTable";
import ManageTransModal2 from "../components/ManageTransModal2";
import DetailsTransModal from "../components/DetailsTransModal";
import AddUserButton from "../components/AddTransButton";
import TranssHeader from "../components/TranssHeader";
import ExcelExport from "../components/TransExcelExport";

import UpdatePayment from "../components/UpdatePayment";

// HELPERS
import showGlobalNotification from "../../../helpers/showGlobalNotification";

// REDUX
import { setTrans, setTransRedux } from "../../../redux/trans";

// REQUEST
import { getTrans, sendInvoiceTrans } from "../requests";
import { handleArchivedTrans } from "../functions";

// HOOKS
import useTranss from "../hooks/useTranss";
import useModal from "../../shared/hooks/useModal";

// CONSTANTS
import { globals, icons } from "../../../constants/globals";

import { spaRoutes } from "../../../constants/routes";

const Transs = ({ isEmbed = false, appId = null, planId = null, userId = null, isMinimal = false, history }) => {
  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);
  const transStoreRedux = useSelector((store) => store.trans);

  const { t } = useTranslation();

  const { transs, filters, setFilters, pagination, setPagination, loading, refetch } = useTranss(appId, planId, userId);

  const commonProps = { filters, setFilters };
  const [page, setPage] = useState(globals.PAGE_MAIN);
  const [selectedTrans, setSelectedTrans] = useState({});

  const [transId, setTransId] = useState(null);
  const [isOpenManageTranssModal, openManageTranssModal, closeManageTranssModal] = useModal();

  const [isOpenDetailsTransModal, openDetailsTransModal, closeDetailsTransModal] = useModal();
  const [details, setDetails] = useState(false);
  const [reload, setReload] = useState(0);

  const [initialValues] = useState({
    mode: "Efectivo",
    fullname: "",
    email: "",
    phone: "",
    amount: 0,
    pending: 0,
    total: 0,
    appId: "",
  });

  useEffect(() => {
    setTrans(selectedTrans)(dispatch);
  }, [selectedTrans]);

  useEffect(() => {
    refetch();
  }, [transStoreRedux]);

  const handleReload = () => setReload(Math.floor(Math.random() * 100000));

  const handleEdit = (trans) => {
    handleReload();
    setSelectedTrans(trans);
    setTransId(trans._id);
    openManageTranssModal();
  };

  const handleDetails = (trans) => {
    handleReload();
    getTrans(trans._id, {
      onSuccess: (response) => {
        setTransRedux(response.result);
        setSelectedTrans(response.result);
        openDetailsTransModal();
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
  };

  const handleUpdateTrans = (trans) => {
    handleReload();
    getTrans(trans._id, {
      onSuccess: (response) => {
        setTransRedux(response.result);
        setSelectedTrans(response.result);
        setDetails(true);
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
  };

  const handleSendInvoiceConfirm = (trans) => {
    sendInvoiceTrans(
      {
        trans,
      },
      {
        onSuccess: () => {
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleSendInvoice = (trans) => {
    confirmAlert({
      title: `Enviar Comprobante a ${trans.email}`,
      message: `Esta seguro que desea enviar el comprobante de pago ${trans.code}?`,
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleSendInvoiceConfirm(trans),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleArchived = (trans) => {
    setTransId(trans._id);
    handleArchivedTrans({ trans, t, refetch });
  };

  const handleOpenApp = (appId_) => {
    history.push(`${spaRoutes.ANALIST}/${appId_}`);
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <>
      {page === globals.PAGE_MAIN && (
        <div>
          {!isEmbed && (
            <TranssHeader
              t={t}
              title={
                <h4 className="bold special-font big-font">
                  {icons.dolar} {t("payments")}
                </h4>
              }
              primaryAction={
                <AddUserButton
                  isOpenManageTranssModal={isOpenManageTranssModal}
                  openManageTranssModal={openManageTranssModal}
                />
              }
            />
          )}
          <div className={isEmbed ? "" : "container-subpages"}>
            <ActionBar
              handleExportExcel
              ExcelButton={<>{transs.length > 0 && <ExcelExport transs={transs} />}</>}
              isMinimal={isMinimal}
              isEmbed={isEmbed}
              {...commonProps}
              options={{ actions: FiltersTable }}
            >
              <SearchTranssForm {...commonProps} refetch={refetch} />
            </ActionBar>
            <TranssTable
              {...commonProps}
              transs={transs}
              pagination={pagination}
              setPagination={setPagination}
              loading={loading}
              refetch={refetch}
              initialValues={initialValues}
              setPage={setPage}
              setSelectedTrans={setSelectedTrans}
              selectedTrans={selectedTrans}
              handleDetails={handleDetails}
              handleEdit={handleEdit}
              details={handleDetails}
              handleArchived={handleArchived}
              openDetailsTransModal={openDetailsTransModal}
              handleUpdateTrans={handleUpdateTrans}
              t={t}
              isEmbed={isEmbed}
              isMinimal={isMinimal}
              handleSendInvoice={handleSendInvoice}
            />
          </div>
          {details && (
            <UpdatePayment
              handleOpenApp={handleOpenApp}
              t={t}
              open={details}
              setOpen={setDetails}
              reload={reload}
              selectedTrans={selectedTrans}
              isEmbed={isEmbed}
            />
          )}
        </div>
      )}

      <ManageTransModal2
        refetch={refetch}
        transId={transId}
        setTransId={setTransId}
        closeModal={closeManageTranssModal}
        openModal={isOpenManageTranssModal}
        t={t}
      />
      <DetailsTransModal
        refetch={refetch}
        transId={transId}
        setTransId={setTransId}
        closeModal={closeDetailsTransModal}
        openModal={isOpenDetailsTransModal}
        initialValues={initialValues}
        transs={transs}
        selectedTransMain={selectedTrans}
        setSelectedTransMain={setSelectedTrans}
        setTrans={setTrans}
        getTrans={getTrans}
        session={sessionStoreRedux.currentSession}
        handleSendInvoice={handleSendInvoice}
        t={t}
      />
    </>
  );
};

// comentario

export default withRouter(Transs);
