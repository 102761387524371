import moment from "moment";
import { Table } from "antd";
import { withRouter } from "react-router-dom";

const columnsActions = [
  {
    title: "Events",
    dataIndex: "event",
    key: "event",
    render: (event) => {
      if (event === "s") return "Scroll";
      if (event === "c") return "Click";
      if (event === "f") return "Form Submit";
      if (event === "d") return "Download Form";
    },
  },
  {
    title: "Reference",
    dataIndex: "code",
    key: "code",
    render: (value, row) => (row.event === "s" ? row.href : row.code),
  },
  {
    title: "Url",
    dataIndex: "url",
    key: "url",
  },
  {
    title: "Date",
    dataIndex: "created",
    key: "created",
    render: (created) => moment(created).format("LLLL"),
  },
];

const TracksTable = ({ tracksWebData }) => (
  <>
    <Table pagination={false} columns={columnsActions} dataSource={tracksWebData} />
  </>
);

export default withRouter(TracksTable);
