import { Select } from "antd";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const BucketCards = ({ items, title, width = "150", setValue, name }) => {
  const { t } = useTranslation();

  const handleChange = (xxx) => {
    setValue(xxx, name);
  };

  return (
    <>
      <small>{title}</small>
      <br />
      <Select allowClear defaultValue="" onChange={handleChange} style={{ width: `${width}px` }}>
        {items.map((xx) => (
          <Option value={xx.name}>{xx.name}</Option>
        ))}
      </Select>
    </>
  );
};

export default withRouter(BucketCards);
