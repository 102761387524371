import { confirmAlert } from "react-confirm-alert";

import {
  archivedSupplier,
  activeSupplier,
  exportExcelSuppliers,
  getSupplier,
  checkinSupplier,
  guestSupplier,
  updateSupplier,
  searchSuppliers,
} from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";
import { setSupplierRedux } from "../../../redux/supplier";

import { colorsSystem } from "../../../constants/globals";

const Swal = require("sweetalert2");

export const handleActiveConfirm = ({ supplier, t, refetch }) => {
  activeSupplier(
    {
      supplier,
    },
    {
      onSuccess: () => {
        refetch();
        showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleActive = ({ supplier, t, refetch }) => {
  confirmAlert({
    title: supplier.active ? `${t("desactivate")} ${t("supplier")}` : `${t("activate")} ${t("supplier")}`,
    message: t("AreYouSureWantToContinue"),
    buttons: [
      {
        label: t("yes"),
        onClick: () => handleActiveConfirm({ supplier, t, refetch }),
      },
      {
        label: t("no"),
        onClick: () => {},
      },
    ],
  });
};

export const handleExportExcelSuppliers = (suppliers) => {
  exportExcelSuppliers(suppliers);
};

export const handleArchivedConfirm = ({ supplier, t, refetch }) => {
  archivedSupplier(
    {
      supplier,
    },
    {
      onSuccess: () => {
        refetch();
        showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleArchived = ({ supplier, t, refetch }) => {
  confirmAlert({
    title: supplier.active ? `${t("desactivate")} ${t("supplier")}` : `${t("activate")} ${t("supplier")}`,
    message: t("AreYouSureWantToContinue"),
    buttons: [
      {
        label: t("yes"),
        onClick: () => handleArchivedConfirm({ supplier, t, refetch }),
      },
      {
        label: t("no"),
        onClick: () => {},
      },
    ],
  });
};

export const handleDetails = ({ supplier }, { onSuccess = {} }) => {
  getSupplier(supplier._id, {
    onSuccess: (response) => {
      onSuccess(response.result);
    },
    onError: () => showGlobalNotification("error", "Error", "error"),
  });
};

export const handleCheckinSupplier = (
  { supplierId, message, t, appId, setBlockActions = null },
  { onSuccess = {}, onFinally = {} }
) => {
  Swal.fire({
    title: message,
    html: "<b>Desea continuar?</b>",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: colorsSystem.red,
    cancelButtonColor: colorsSystem.blue,
    confirmButtonText: t("common_yesIWantContinueCheckin"),
    cancelButtonText: t("common_cancel"),
  }).then((result) => {
    if (result.isConfirmed) {
      checkinSupplier(
        {
          message,
          appId,
          supplierId,
        },
        {
          onSuccess: (result2) => {
            onSuccess(result2);
          },
          onError: () => showGlobalNotification("error", "errorTitle", t("errorDescription")),
          onFinally: () => {},
        }
      );
    } else {
      setBlockActions(false);
    }
  });
};

export const handleUpdateSupplier = ({ supplierId, params, t }, { onSuccess = {}, onFinally = {} }) => {
  updateSupplier(
    {
      ...params,
      supplierId,
    },
    {
      onSuccess: (result) => {
        onSuccess(result);
      },
      onError: () => showGlobalNotification("error", "errorTitle", t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleSearchSuppliers = ({ text, t }, { onSuccess = {}, onFinally = {} }) => {
  searchSuppliers(text, {
    onSuccess: (result) => {
      onSuccess(result);
    },
    onError: () => showGlobalNotification("error", "errorTitle", "errorDescription"),
    onFinally: () => {},
  });
};
