import { Statistic, Row, Col, PageHeader } from "antd";
import { ClockCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const Box = ({ icon, metrics, title = "", subtitle }) => (
  // console.log(metrics);
  <div className="container-subpages">
    <PageHeader
      className="site-page-header"
      title={
        <>
          {icon} {"  "} {title}
        </>
      }
      subTitle={subtitle}
    >
      <Row justify="start">
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Statistic
            title="Registros"
            value={metrics?.createApps?.data?.length || 0}
            prefix={<ClockCircleOutlined style={{ color: "green" }} />}
          />
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Statistic
            title="Activaciones"
            value={metrics?.activateApps?.data?.length || 0}
            prefix={<CheckCircleOutlined style={{ color: "green" }} />}
          />
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Statistic
            title="Finalizaciones"
            value={metrics?.closureApps?.data?.length || 0}
            prefix={<CloseCircleOutlined style={{ color: "green" }} />}
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Statistic
            title="Renovaciones"
            value={metrics?.renovateApps?.data?.length || 0}
            prefix={<ClockCircleOutlined style={{ color: "green" }} />}
          />
        </Col>
      </Row>
    </PageHeader>
  </div>
);
export default Box;
