import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Input, Select, Spin, Switch, InputNumber, message, Steps, Button } from "antd";
import { savePlan, getPlan } from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";
import BaseModal from "../../shared/components/Modal/BaseModal";
import SelectPacks from "../../subscribers/components/SelectPacks";
import { setPlanRedux, reloadPlanRedux } from "../../../redux/plan";
import { packs } from "../../../constants/globals";

const moment = require("moment");

const { TextArea } = Input;
const { Option } = Select;

const defaultValues = {
  name: "",
  departurePlace: "",
  minLimit: 1,
  maxLimit: 1,
  category: "",
  duration: "",
  active: true,
  public: false,
  price: 0,
  packId: null,
  type: packs.SUBSCRIPTION,
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  quantityguest: null,
  durationMode: "",
  unlimited: true,
  isCarnet: false,
  isPortalWeb: false,
  color: "#000",
};

const { Step } = Steps;

const ManagePlanEventModal = ({ planId, openModal, closeModal, refetch, setPlanId, typePack }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);

  const [selectedPlan, setSelectedPlan] = useState(defaultValues);
  const [form] = Form.useForm();
  const [loaderForm, setLoaderForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [reload, setReload] = useState(0);

  const handleReset = () => {
    setSelectedPlan(defaultValues);
    setReload(Math.floor(Math.random() * 100000));
  };

  useEffect(() => {
    if (openModal) {
      if (planId) {
        setMode("edit");
      } else {
        setMode("new");
      }
    } else {
      handleReset();
      setMode(null);
      setPlanId(null);
    }
  }, [openModal]);

  useEffect(() => {
    if (mode === "new") {
      handleReset();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      setLoaderForm(true);
      getPlan(planId, {
        onSuccess: (response) => {
          setPlanRedux(response.result)(dispatch);
          setSelectedPlan({
            ...response.result,
            startdate: moment(response.result.startdate, "YYYY/MM/DD") || moment("2020/12/12", "YYYY/MM/DD"),
            closuredate: moment(response.result.closuredate, "YYYY/MM/DD") || moment("2020/12/12", "YYYY/MM/DD"),
          });
          setLoaderForm(false);
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  }, [mode]);

  useEffect(() => {
    form.setFieldsValue(selectedPlan);
  }, [form, selectedPlan]);

  const handleCloseModal = () => {
    refetch();
    closeModal();
  };

  const onFinish = (values) => {
    const data = { ...selectedPlan, ...values };
    if (!data.packId) {
      message.error("This is an error message");
    } else {
      savePlan(
        {
          ...data,
          planId,
          rootId: sessionStoreRedux.currentSession_id,
        },
        {
          onSuccess: (response) => {
            setPlanRedux(response.result)(dispatch);
            reloadPlanRedux()(dispatch);
            showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
          },
          onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
          onFinally: () => handleCloseModal(),
        }
      );
    }
  };

  const onValuesChange = debounce((values) => {
    setSelectedPlan({ ...selectedPlan, ...values });
  }, 500);

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  const handleActive = (checked) => {
    setSelectedPlan({ ...selectedPlan, active: checked });
  };

  const handleUnlimited = (checked) => {
    setSelectedPlan({ ...selectedPlan, unlimited: checked });
  };

  const handlePortalWeb = (checked) => {
    setSelectedPlan({ ...selectedPlan, isPortalWeb: checked });
  };

  const handleFree = (checked) => {
    setSelectedPlan({ ...selectedPlan, isFree: checked });
  };

  const handleCarnet = (checked) => {
    setSelectedPlan({ ...selectedPlan, isCarnet: checked });
  };

  const handlePublic = (checked) => {
    setSelectedPlan({ ...selectedPlan, public: checked });
  };

  const handleStrictMode = (checked) => {
    setSelectedPlan({ ...selectedPlan, isStrictMode: checked });
  };

  const handleTemporal = (checked) => {
    setSelectedPlan({ ...selectedPlan, isTemporal: checked });
  };

  const handlePack = (value) => {
    setSelectedPlan({ ...selectedPlan, packId: value });
  };
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: "Second",
      content: (
        <Row gutter={15} style={{ paddingBottom: 10 }}>
          <Col span={24}>
            <Form.Item name="packId2" tooltip="" label={t("pack")}>
              <SelectPacks
                reload={reload}
                currentValue={selectedPlan?.pack?._id}
                defaultValue={selectedPlan?.pack?._id}
                returnData={handlePack}
                typePack={typePack}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="name"
              tooltip=""
              label={t("plan")}
              rules={[
                {
                  required: true,
                  message: `Por favor ingrese el nombre`,
                },
              ]}
            >
              <Input placeholder="" value={selectedPlan?.name} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="observation" label={t("description")}>
              <TextArea showCount="true" onResize="false" value={selectedPlan?.observation} />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      title: "Last",
      content: (
        <Row gutter={15}>
          <Col span={5}>
            <Form.Item name="durationMode" label={t("durationMode")}>
              <Select className="select-form-modal">
                <Option value="HOURS">Horas</Option>
                <Option value="DAYS">días</Option>
                <Option value="MONTHS">Meses</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="duration" label={t("duration")}>
              <InputNumber value={selectedPlan?.duration} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="color" label={t("color")}>
              <Input type="color" value={selectedPlan?.color} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="minLimit" label={t("minimum")}>
              <InputNumber min={1} max={20} defaultValue={1} value={selectedPlan?.minLimit} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="maxLimit" label={t("maximum")}>
              <InputNumber min={1} max={20} defaultValue={1} value={selectedPlan?.maxLimit} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="quantityguest" label={t("quantityguest")}>
              <InputNumber min={1} max={100} defaultValue={100} value={selectedPlan?.quantityguest} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="price" label={t("price")}>
              <InputNumber
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                value={selectedPlan?.price}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      title: "First",
      content: (
        <Row>
          <Col span={4}>
            <Form.Item name="active" label={t("active")}>
              <Switch checked={!!selectedPlan?.active} onChange={handleActive} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="public" label={t("public")}>
              <Switch checked={!!selectedPlan?.public} onChange={handlePublic} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="unlimited" label={t("unlimited")}>
              <Switch checked={!!selectedPlan?.unlimited} onChange={handleUnlimited} />
            </Form.Item>
          </Col>{" "}
          <Col span={4}>
            <Form.Item name="isCarnet" label={t("isCarnet")}>
              <Switch checked={!!selectedPlan?.isCarnet} onChange={handleCarnet} />
            </Form.Item>
          </Col>{" "}
          <Col span={4}>
            <Form.Item name="isPortalWeb" label={t("isPortalWeb")}>
              <Switch checked={!!selectedPlan?.isPortalWeb} onChange={handlePortalWeb} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="isTemporal" label={t("plan_isTemporal")}>
              <Switch checked={!!selectedPlan?.isTemporal} onChange={handleTemporal} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="isFree" label={t("plan_isFree")}>
              <Switch checked={!!selectedPlan?.isFree} onChange={handleFree} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="isStrictMode" label={t("isStrictMode")}>
              <Switch checked={!!selectedPlan?.isStrictMode} onChange={handleStrictMode} />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <BaseModal
      isOpenModal={openModal}
      closeModal={handleCloseModal}
      onCancel={handleCloseModal}
      onConfirm={() => handleSubmit()}
      title={planId ? `${t("edit")} ${t("plan")}` : `${t("plan")}`}
      width={1200}
      confirmText={t("save")}
      isCloseModal
      top={10}
    >
      <Form
        form={form}
        name="manage-category-form-modal"
        initialValues={defaultValues}
        className="manage-modal"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        onValuesChange={onValuesChange}
      >
        <div className="layout-form-modal entity-400 entity-small">
          {loaderForm ? (
            <>
              <Spin tip={t("loading")} />
            </>
          ) : (
            <>
              <Steps current={current}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <div className="steps-content">{steps[current].content}</div>
              <div className="steps-action">
                {current > 0 && (
                  <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                    Previous
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button type="primary" onClick={() => message.success("Processing complete!")}>
                    Done
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button type="primary" onClick={() => next()}>
                    Next
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </Form>
    </BaseModal>
  );
};

ManagePlanEventModal.defaultProps = {
  setPlanId: () => {},
};

export default ManagePlanEventModal;
