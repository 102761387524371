import { setLocalStorage } from "../utils/localStorage";

const dataInicial = {
  request: false,
  reload: Math.floor(Math.random() * 100000000),
  currentUser: {},
};

const CURRENT_USER = "CURRENT_USER";

const RELOAD = "RELOAD";

export default function userReducer(state = dataInicial, action) {
  switch (action.type) {
    case CURRENT_USER:
      return { ...state, currentUser: action.payload };

    case RELOAD:
      return { ...state, reload: Math.floor(Math.random() * 100000000) };

    default:
      return { ...state };
  }
}

export const setUser = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: CURRENT_USER,
    payload,
  });
};

export const setUserRedux = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: CURRENT_USER,
    payload,
  });
};

export const reloadRedux = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: RELOAD,
    payload,
  });
};
