import { Tabs } from "antd";
import { SolutionOutlined, DollarCircleOutlined, PaperClipOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

const TabMenu = ({ setSubpage, t, numbers }) => {
  const callback = (key) => {
    setSubpage(key);
  };

  return (
    <Tabs defaultActiveKey={numbers.ONE} onChange={callback}>
      <TabPane
        tab={
          <span>
            <DollarCircleOutlined />
            {t("payment")}
          </span>
        }
        key={numbers.ONE}
      />{" "}
      <TabPane
        tab={
          <span>
            <SolutionOutlined />
            {t("event")}
          </span>
        }
        key={numbers.TWO}
      />{" "}
      <TabPane
        tab={
          <span>
            <PaperClipOutlined />
            {t("moreDetails")}
          </span>
        }
        key={numbers.FOUR}
      />
    </Tabs>
  );
};

export default TabMenu;
