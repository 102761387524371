/* eslint-disable no-unused-expressions */
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";

import { useTranslation } from "react-i18next";

import { withRouter } from "react-router-dom";
import { handleActive, handleArchived, handleDetails } from "../functions/index";

// COMPONENTS
import ExpenseTable from "../components/ExpensesTable";

import ManageExpenseModal from "../components/ManageExpenseModal";

import AddExpenseButton from "../components/AddExpenseButton";
import ExpensesHeader from "../components/ExpensesHeader";

// HELPERS

// REDUX
import { setExpense } from "../../../redux/expense";

// REQUEST
import { getExpense } from "../requests";

// HOOKS
import useExpenses from "../hooks/useExpense";
import useModal from "../../shared/hooks/useModal";

// CONSTANTS
import { globals, numbers, icons } from "../../../constants/globals";

const Expenses = ({ match, planX, planId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { expenses, filters, setFilters, pagination, setPagination, loading, refetch } = useExpenses({
    planId,
  });

  const commonProps = { filters, setFilters };
  const [page, setPage] = useState(globals.PAGE_MAIN);

  const [refresh, setRefresh] = useState(true);

  const [subpage, setSubpage] = useState(numbers.FIVE);
  const [selectedExpense, setSelectedExpense] = useState({});

  const [expenseId, setExpenseId] = useState(null);

  const [isOpenManageExpensesModal, openManageExpensesModal, closeManageExpensesModal] = useModal();

  const [initialValues, setInitialValues] = useState({
    observation: "",
    description: "",
    category: "",
    type: "Normal",
    active: "",
    supplier: null,
  });

  const handleEdit = (expense) => {
    setSelectedExpense(expense);
    setExpenseId(expense._id);
    openManageExpensesModal();
  };

  const expenseDetails = (expense) => {
    setExpenseId(expense._id);
    setPage(globals.PAGE_ONE);
    handleDetails(
      { expense },
      {
        onSuccess: (result) => {
          setExpense(result)(dispatch);
          setSelectedExpense(result);
        },
      }
    );
  };

  useEffect(() => {
    if (match.params.id) {
      handleDetails({ _id: match.params.id });
    }
    setRefresh(true);
  }, []);

  useEffect(() => {
    refetch();
  }, [filters]);

  useEffect(() => {
    setExpense(selectedExpense)(dispatch);

    if (selectedExpense._id) {
      setExpenseId(selectedExpense._id);
      setSubpage(numbers.FIVE);
    }
  }, [selectedExpense]);

  useEffect(() => {
    debounce(() => refetch(), 500);
  }, [filters]);

  return (
    <>
      {page === globals.PAGE_MAIN && (
        <div>
          <ExpensesHeader
            title={<h4 className="bold special-font big-font">{icons.dolar} Calculos de costos</h4>}
            primaryAction={
              <AddExpenseButton
                openManageExpensesModal={openManageExpensesModal}
                refetch={refetch}
                initialValues={initialValues}
                t={t}
              />
            }
          />
          <div className="container-subpages">
            <ExpenseTable
              {...commonProps}
              expenses={expenses}
              pagination={pagination}
              setPagination={setPagination}
              loading={loading}
              refetch={refetch}
              initialValues={initialValues}
              setPage={setPage}
              setSelectedExpense={setSelectedExpense}
              selectedExpense={selectedExpense}
              handleDetails={expenseDetails}
              handleEdit={handleEdit}
              handleArchived={handleArchived}
              handleInactive={handleActive}
              details={expenseDetails}
              t={t}
            />
          </div>
        </div>
      )}

      <ManageExpenseModal
        refetch={refetch}
        expenseId={expenseId}
        closeModal={closeManageExpensesModal}
        openModal={isOpenManageExpensesModal}
        initialValues={initialValues}
        planId={planId}
        planX={planX}
        selectedExpense={selectedExpense}
        setSelectedExpenseMain={setSelectedExpense}
        setExpense={setExpense}
        getUser={getExpense}
        page={page}
        t={t}
      />
    </>
  );
};

export default withRouter(Expenses);
