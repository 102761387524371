import { useState, useEffect } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { useDispatch } from "react-redux";
import { spaRoutes } from "../../../constants/routes";
import useAlert from "../../shared/hooks/useAlert";

import { save } from "../../../redux/root";

import { setCookies, getCookies, setPasswordCookie } from "../../../helpers/cookies";

const Login = ({ login, router, setting }) => {
  const dispatch = useDispatch();
  const [renderAlert, openAlert] = useAlert();
  const [isSubmit, setIsSubmit] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const params = { ...values, instanceId: setting._id };

    if (values.email === "") {
      alert("email vacio");
      return false;
    }

    if (values.password === "") {
      alert("password vacio");
      return false;
    }

    if (params.remember === true) {
      setCookies("uclf", params.email);
      setPasswordCookie("pclf", params.password);
      setCookies("iclf", params.instanceId);
    } else {
      setCookies("uclf", "null");
      setCookies("pclf", "null");
      setCookies("iclf", "null");
    }

    setIsSubmit(true);
    login(params, {
      onFail: (response) => {
        openAlert({ message: response.message, type: "error" });
        form.resetFields();
      },
      onSuccess: (response) => {
        save(response)(dispatch);

        if (response.user.role === "ADMIN") {
          if (response.user.redirectTo && response.user.redirectTo && response.user.redirectTo === "analist")
            router.history.push(spaRoutes.ANALIST);
          if (response.user.redirectTo && response.user.redirectTo === "analistWeb")
            router.history.push(spaRoutes.ANALISTWEB);
          else router.history.push(spaRoutes.ACTIVITIES);
        } else if (response.user.role === "TOURGUIDE") {
          router.history.push(spaRoutes.TOURGUIDE);
        } else {
          if (response.user.redirectTo && response.user.redirectTo === "analist")
            router.history.push(spaRoutes.MODULO_APLICACIONES);
          if (response.user.redirectTo && response.user.redirectTo === "analistWeb")
            router.history.push(spaRoutes.ANALISTWEB);
          else router.history.push(spaRoutes.ANALIST);
        }
      },
      onFinish: () => setIsSubmit(false),
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (localStorage.getItem("logout")) {
      setCookies("pclf", null);
      setCookies("uclf", null);
      setCookies("iclf", null);
      localStorage.removeItem("keypublic");
      localStorage.removeItem("cluuf-settings");
      localStorage.removeItem("instanceId");
      localStorage.removeItem("goto");
      localStorage.getItem("logout");
    } else if (getCookies("uclf") && getCookies("uclf") !== "null") {
      onFinish({
        email: getCookies("uclf"),
        password: getCookies("pclf"),
        remember: true,
      });
    }
  }, []);

  return (
    <>
      <Form
        form={form}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Form.Item name="email">
          <Input style={{ fontSize: 32, height: 60, fontWeight: 800, padding: 10 }} placeholder="Usuario" />
        </Form.Item>
        <br />
        <Form.Item name="password">
          <Input.Password
            style={{ fontSize: "40px !important", height: 60, fontWeight: 800, padding: 10 }}
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            style={{
              display: "block",
              margin: "auto",
              padding: "10px 20px",
              height: 60,
              fontSize: 28,
              fontWeight: 800,
            }}
            type="primary"
            className="ant-btn secondary primary large"
            htmlType="submit"
            loading={isSubmit}
            size="large"
          >
            Iniciar sesión
          </Button>
        </Form.Item>
      </Form>
      {renderAlert()}
    </>
  );
};

export default Login;
