import { useState, useEffect } from "react";
import { Typography } from "antd";

const { Link } = Typography;

const CardLinkPlans = ({ title, handleOpen }) => {
  console.log("");
  return (
    <div style={{ fontSize: 18, marginBottom: 40 }}>
      <Link style={{ fontSize: 16, marginBottom: 20 }} onClick={handleOpen}>
        {title}
      </Link>
    </div>
  );
};

export default CardLinkPlans;
