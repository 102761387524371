import { Form, Row, Col, Input, Button } from "antd";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";

const SearchPacksForm = ({ filters, setFilters, refetch, searchUsers, planId }) => {
  const initialValues = { firstname: "", lastname: "", email: "", active: "", document: "", type: "" };
  const [form] = Form.useForm();

  const onFinishForm = (values) => {
    searchUsers(values.all, {
      onSuccess: (res) => {
        const usersId = [];
        res.result.forEach((item) => {
          usersId.push(item._id);
        });

        setFilters({ ...filters, ...values, searchByUsers: true, usersId, planId });
        refetch();
      },
      onError: () => {},
    });
  };

  const onValuesChange = debounce((values) => {
    // setFilters({ ...filters, ...values });
  }, 500);

  return (
    <Form
      size="small"
      layout="vertical"
      form={form}
      name="search-categories-form"
      initialValues={initialValues}
      onFinish={onFinishForm}
      onValuesChange={onValuesChange}
    >
      <Row gutter={24}>
        <Col span={6}>{}</Col>
        <Col span={10}>
          <Form.Item name="all">
            <Input
              className="input-filters-form"
              type="text"
              defaultValue=""
              placeholder="Ingrese el el email, nombres, teléfono o documento"
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Button type="default" size="large" htmlType="submit">
            Buscar
          </Button>
        </Col>
        <Col span={6}>{}</Col>
      </Row>
    </Form>
  );
};

SearchPacksForm.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func.isRequired,
  refetch: PropTypes.func,
};

export default SearchPacksForm;
