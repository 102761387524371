const appPath =
  process.env.NODE_ENV === "development" ? "http://localhost:2001" : "https://my-app-3ocg5.ondigitalocean.app";
const backendPath =
  process.env.NODE_ENV === "development" ? "http://localhost:2001" : "https://my-app-3ocg5.ondigitalocean.app";
const backendTracks = "https://cluuftracks.herokuapp.com";

export const spaRoutes = Object.freeze({
  CATEGORIES: `${appPath}/spa/categories.do`,
  PRODUCTS: `${appPath}/spa/products.do`,
  LOGIN2: `/login`,
  LOGIN: `/login/:alias`,
  LOGOUT: `/logout/:alias`,
  LOGOUT_USER: `/logout/user`,
  LOGINGOTO: `/login/:alias/:goto`,
  LOGINUSER: `/user/:alias`,
  USERGOTO: `/user/:alias/:goto`,
  SIGNIN: `/signin/:alias`,
  HOME: `/home`,
  ANALIST: `/analist`,
  ACTIVITIES: `/activities`,
  EVENT: `/event/:planId`,
  ANALIST_APPID: `/analist/:appId`,
  ANALIST_PLANID: `/analist/:plan/:planId`,
  ANALIST_TABS: `/analist/:tab/:subtab/:optional`,
  ANALISTWEB: `/analistWeb`,
  ANALISTV2: `/analistV2`,
  MODULO_ACTIVIDADES: `/activities-module`,
  MODULO_APLICACIONES: `/applications-module`,
  TOURGUIDE: `/tourGuide`,
  HOMETYPE: `/home/:type`,
  HOMETYPEID: `/home/:type/:id`,
  HOMETYPEIDEXPRESS: `/home/:type/:id/:express/:alias`,
  HOMEUSER: `/user`,
  ANSWER: `/answer`,
  CLUUFWEB: `/cluufweb`,
  QRCODE: `/qrcode/:alias/:id`,
  CARNET: `/carnet/:alias/:id`,
  QRPLAN: `/qr/:alias/:id/:mode/:campaing/:refererUserId/:refererAppId`,
  USEREXTERNAL: `/userext/:alias/:id/:mode`,
  REPORT: `/report`,
});

export const staticRoutes = Object.freeze({
  SETUP: {
    COMPANY_PRODUCT: `${appPath}/companyProduct/admin.do`,
    CATEGORIES: `${appPath}/spa/categories.do`,
    ORGANIZE_CATEGORIES: `${appPath}/category/admin.do`,
    PRODUCT_PACK_REMINDER: `${appPath}/productPackReminder/admin.do`,
    PRODUCT_ALIAS: `${appPath}/companyProductAlias/admin.do`,
    HARD_GOOD_PRODUCTS: `${appPath}/hard-good/product/manage.do`,
    DATA_SHEET: `${appPath}/product-data-sheet/summary.do`,
    PRODUCTS: `${appPath}/spa/products.do`,
  },
});

export const apiRoutes = Object.freeze({
  CATEGORIES: {
    LOAD_GRID: `${appPath}/category/load-grid.do`,
    DOWNLOAD_EXCEL_FILE: `${appPath}/category/admin.do?action=exportToExcelCategory`,
    GET_DATA: `${appPath}/category/getCategoryData.do`,
    SAVE_DATA: `${appPath}/category/saveCategoryData.do`,
    GET_ORIGIN_REGIONS: `${appPath}/origin-region/autocomplete.do`,
    PROCESS_MAPPING: `${appPath}/category/process-mapping.do`,
    DELETE_CATEGORY: `${appPath}/category/validateBeforeDeleteCategory.do`,
  },
  PRODUCTS: {
    LOAD_GRID: `${appPath}/komet-codes/product/load-grid.do`,
    DOWNLOAD_EXCEL_FILE: `${appPath}/companyProduct/admin.do?action=exportToExcelProducts`,
    LOAD_KOMET_CODE_PRODUCTS_AUTOCOMPLETE: `${appPath}/komet-codes/product/autocomplete.do`,
    PROCESS_MAPPING: `${appPath}/komet-codes/product/process-mapping.do`,
  },
  LOGIN: {
    ROOT: `${backendPath}/rootLogin`,
    USER: `${backendPath}/userLogin`,
  },
  INSTANCE: {
    BY_ALIAS: `${backendPath}/instanceByAlias`,
    SAVE_DATA: `${backendPath}/instance`,
    GET_DATA: `${backendPath}/instance`,
  },
  INSTANCES: {
    SAVE_DATA: `${backendPath}/instances`,
    GET_DATA: `${backendPath}/instances`,
    LOAD_GRID: `${backendPath}/instancesLoadGrid`,
  },
  USERS: {
    BY_ALIAS: `${backendPath}/userByAlias`,
    LOAD_GRID: `${backendPath}/userLoadGrid`,
    GET_DATA: `${backendPath}/usuarioDetails`,
    SAVE_DATA: `${backendPath}/user`,
    SAVE_EXTRA: `${backendPath}/userExtra`,
    GET_USERSEXTRA: `${backendPath}/usersExtra`,
    GET_USEREXTRA: `${backendPath}/userExtra`,
    ARCHIVED: `${backendPath}/userArchived`,
    ACTIVE: `${backendPath}/userActive`,
    VALIDATE: `${backendPath}/userValidate`,
    ALL: `${backendPath}/userAll`,
    SEARCH: `${backendPath}/user`,
    METRICS: `${backendPath}/userMetrics`,
    SAVE_USER_WELCOME: `${backendPath}/subscriber_activate_app`,
    CHECKIN: `${backendPath}/userCheckin`,
    GUEST: `${backendPath}/userGuest`,
    UPDATE_BY_ANALIST: `${backendPath}/userUpdate`,
    RESET_PASSWORD: `${backendPath}/userResetPassword`,
    BY_APPID: `${backendPath}/userByAppId`,
    METRICS2: `${backendPath}/usuarioMetrics`,
  },
  SUPPLIERS: {
    BY_ALIAS: `${backendPath}/supplierByAlias`,
    LOAD_GRID: `${backendPath}/supplierLoadGrid`,
    GET_DATA: `${backendPath}/supplierDetails`,
    SAVE_DATA: `${backendPath}/supplier`,
    SAVE_EXTRA: `${backendPath}/supplierExtra`,
    GET_USERSEXTRA: `${backendPath}/suppliersExtra`,
    GET_USEREXTRA: `${backendPath}/supplierExtra`,
    ARCHIVED: `${backendPath}/supplierArchived`,
    ACTIVE: `${backendPath}/supplierActive`,
    VALIDATE: `${backendPath}/supplierValidate`,
    ALL: `${backendPath}/supplierAll`,
    SEARCH: `${backendPath}/supplier`,
    METRICS: `${backendPath}/supplierMetrics`,
    SAVE_USER_WELCOME: `${backendPath}/subscriber_activate_app`,
    CHECKIN: `${backendPath}/supplierCheckin`,
    GUEST: `${backendPath}/supplierGuest`,
    UPDATE_BY_ANALIST: `${backendPath}/supplierUpdate`,
    RESET_PASSWORD: `${backendPath}/supplierResetPassword`,
    BY_APPID: `${backendPath}/supplierByAppId`,
  },
  SERVICES: {
    BY_ALIAS: `${backendPath}/serviceByAlias`,
    LOAD_GRID: `${backendPath}/serviceLoadGrid`,
    GET_DATA: `${backendPath}/serviceDetails`,
    SAVE_DATA: `${backendPath}/service`,
    SAVE_EXTRA: `${backendPath}/serviceExtra`,
    GET_USERSEXTRA: `${backendPath}/servicesExtra`,
    GET_USEREXTRA: `${backendPath}/serviceExtra`,
    ARCHIVED: `${backendPath}/serviceArchived`,
    ACTIVE: `${backendPath}/serviceActive`,
    VALIDATE: `${backendPath}/serviceValidate`,
    ALL: `${backendPath}/serviceAll`,
    SEARCH: `${backendPath}/service`,
    METRICS: `${backendPath}/serviceMetrics`,
    SAVE_USER_WELCOME: `${backendPath}/subscriber_activate_app`,
    CHECKIN: `${backendPath}/serviceCheckin`,
    GUEST: `${backendPath}/serviceGuest`,
    UPDATE_BY_ANALIST: `${backendPath}/serviceUpdate`,
    RESET_PASSWORD: `${backendPath}/serviceResetPassword`,
    BY_APPID: `${backendPath}/serviceByAppId`,
  },
  EXPENSE: {
    BY_ALIAS: `${backendPath}/expenseByAlias`,
    LOAD_GRID: `${backendPath}/expenseLoadGrid`,
    GET_DATA: `${backendPath}/expenseDetails`,
    SAVE_DATA: `${backendPath}/expense`,
    SAVE_EXTRA: `${backendPath}/expenseExtra`,
    GET_USERSEXTRA: `${backendPath}/expensesExtra`,
    GET_USEREXTRA: `${backendPath}/expenseExtra`,
    ARCHIVED: `${backendPath}/expenseArchived`,
    ACTIVE: `${backendPath}/expenseActive`,
    VALIDATE: `${backendPath}/expenseValidate`,
    ALL: `${backendPath}/expenseAll`,
    SEARCH: `${backendPath}/expense`,
    METRICS: `${backendPath}/expenseMetrics`,
    SAVE_USER_WELCOME: `${backendPath}/subscriber_activate_app`,
    CHECKIN: `${backendPath}/expenseCheckin`,
    GUEST: `${backendPath}/expenseGuest`,
    UPDATE_BY_ANALIST: `${backendPath}/expenseUpdate`,
    RESET_PASSWORD: `${backendPath}/expenseResetPassword`,
    BY_APPID: `${backendPath}/expenseByAppId`,
  },
  GROUPS: {
    LOAD_GRID: `${backendPath}/groupLoadGrid`,
    GET_DATA: `${backendPath}/groupDetails`,
    SAVE_DATA: `${backendPath}/group`,
    ARCHIVED: `${backendPath}/groupArchived`,
    ACTIVE: `${backendPath}/groupActive`,
    SEARCH: `${backendPath}/group`,
    ALL: `${backendPath}/groupAll`,
  },
  ROOTLOG: {
    LOAD_GRID: `${backendPath}/rootlogLoadGrid`,
  },
  TRACKS: {
    GET_PAGES_VIEW: `${backendTracks}/searchbyAllPages`,
    GET_ACTIONS: `${backendTracks}/searchAction`,
    GET_PAGES_VIEW_BY_CONTACT: `${backendTracks}/searchbyAllPagesByUserFlow`,
    GET_ACTIONS_BY_CONTACT: `${backendTracks}/searchActionsByContact`,
    GET_RESUME_PAGEVIEWS_BY_CONTACT: `${backendTracks}/searchbyAllPagesByUser`,
    GET_RESUME_TRACKS_BY_CONTACT: `${backendTracks}/searchResumeTracksByContact`,
  },
  PROFILE: {
    GET_DATA: `${backendPath}/profile`,
    SAVE_DATA: `${backendPath}/profile`,
    SAVE_PASSWORD: `${backendPath}/profilePassword`,
  },
  GUESTS: {
    LOAD_GRID: `${backendPath}/guestLoadGrid`,
    GET_DATA: `${backendPath}/guestDetails`,
    SAVE_DATA: `${backendPath}/guest`,
    ARCHIVED: `${backendPath}/guestArchived`,
    ACTIVE: `${backendPath}/guestActive`,
    VALIDATE: `${backendPath}/guestValidate`,
  },
  PACKS: {
    LOAD_GRID: `${backendPath}/packLoadGrid`,
    ALL: `${backendPath}/packAll`,
    SINGLES: `${backendPath}/packSingles`,
    KIND: `${backendPath}/packKind`,
    FILTERS: `${backendPath}/packFilters`,
    GET_DATA: `${backendPath}/pack`,
    SAVE_DATA: `${backendPath}/pack`,
    SAVE_DATA_CONTENT: `${backendPath}/packContent`,
    ARCHIVED: `${backendPath}/packArchived`,
    ACTIVE: `${backendPath}/packActive`,
    DUPLICATE: `${backendPath}/packDuplicate`,
    METRICS: `${backendPath}/packMetrics`,
    RELOAD: `${backendPath}/packReload`,
    REPORT: `${backendPath}/packReport`,
  },
  APPS: {
    LOAD_GRID: `${backendPath}/appLoadGrid`,
    LOAD_GRID_BY_USER: `${backendPath}/appLoadGridByUser`,
    GET_DATA: `${backendPath}/app`,
    SAVE_DATA: `${backendPath}/app`,
    ARCHIVED: `${backendPath}/appArchived`,
    ACTIVE: `${backendPath}/appActive`,
    INSITU: `${backendPath}/appInsitu`,
    STATUS: `${backendPath}/appStatus`,
    ALL: `${backendPath}/appAll`,
    FILTERSBYDATE: `${backendPath}/appsByFilters`,
    METRICS: `${backendPath}/appMetrics`,
    APPS_BY_PLANID: `${backendPath}/appsByPlanId`,
    CURRENT_ACTIVES: `${backendPath}/appCurrentActive`,
    GET_DATA_SUSCRIBER_EX: `${backendPath}/subscription_get_app`,
    DUPLICATE: `${backendPath}/subscription_duplicate_app`,
    NEW_APP_BY_APP: `${backendPath}/subscription_activate_app_by_duplicate`,
    NEW_APP_BY_USER: `${backendPath}/subscriber_new_app_by_user`,
    EDIT_SUBSCRIPTION_APP: `${backendPath}/subscriber_edit_subscription_app`,
    NEW_SINGLE_APP_BY_USER: `${backendPath}/subscriber_new_single_app_by_user`,
    RENOVATE_APP_BY_APP: `${backendPath}/subscription_renovate_app_by_app`,
    RECALCULATE_ALL: `${backendPath}/appsEvaluateByInstance`,
    RECALCULATE: `${backendPath}/subscription_recalculate_lifecicle_app`,
    EXCEL_REPORT: `${backendPath}/subscriber_download_excel`,
    COUNT_PENDING: `${backendPath}/appCountPending`,
    CLOSED_APP: `${backendPath}/subscriber_closed_app`,
    CLOSED_WEB_APP: `${backendPath}/subscriber_closed_web_app`,
  },
  CALENDARS: {
    LOAD_GRID: `${backendPath}/calendarLoadGrid`,
    LOAD_GRID_BY_USER: `${backendPath}/calendarLoadGridByUser`,
    GET_DATA: `${backendPath}/calendar`,
    SAVE_DATA: `${backendPath}/calendar`,
    ARCHIVED: `${backendPath}/calendarArchived`,
    ACTIVE: `${backendPath}/calendarActive`,
    STATUS: `${backendPath}/calendarStatus`,
    ALL: `${backendPath}/calendarAll`,
  },
  PLANS: {
    LOAD_GRID: `${backendPath}/planLoadGrid`,
    GET_DATA: `${backendPath}/plan`,
    GET_DATA_PENDING: `${backendPath}/planAllPending`,
    GET_DATA_BY_CODE: `${backendPath}/planByCode`,
    SAVE_DATA: `${backendPath}/plan`,
    SAVE_GUIDE: `${backendPath}/planGuideUpdate`,
    ARCHIVED: `${backendPath}/planArchived`,
    ACTIVE: `${backendPath}/planActive`,
    ALL: `${backendPath}/planAll`,
    SAVE_DATA_CONTENT: `${backendPath}/planContent`,
    UPDATE_JSON_S3_WEBSITE: `${backendPath}/planWebsite`,
    REFRESH_JSON_S3_WEBSITE: `${backendPath}/uploadJsonPlanToS3`,
    DUPLICATE: `${backendPath}/planDuplicate`,
    METRICS: `${backendPath}/planMetrics`,
    RELOAD: `${backendPath}/planReload`,
    BY_ALIAS: `${backendPath}/planByAlias`,
    CONSOLIDATE: `${backendPath}/planConsolidate`,
    CLOSE: `${backendPath}/subscriber_close_plan`,
    NEW_EVENT_PLAN: `${backendPath}/subscriber_new_plan_by_user`,
    EDIT_EVENT_PLAN: `${backendPath}/subscriber_edit_plan_by_user`,
    SEND_CLOSURE_EMAIL: `${backendPath}/subscriber_send_closureEmail_plan`,
  },
  TRANS: {
    LOAD_GRID: `${backendPath}/transLoadGrid`,
    GET_DATA: `${backendPath}/trans`,
    SAVE_DATA: `${backendPath}/trans`,
    ARCHIVED: `${backendPath}/transArchived`,
    ACTIVE: `${backendPath}/transActive`,
    INVOICE: `${backendPath}/transSendInvoice`,
    METRICS: `${backendPath}/transMetrics`,
    EXCEL_REPORT: `${backendPath}/subscriber_download_excel_trans`,
  },
  BUCKET: {
    LOAD_GRID: `${backendPath}/bucketLoadGrid`,
    GET_DATA: `${backendPath}/bucket`,
    GET_DATA_BY_PLAN: `${backendPath}/bucketByPlan`,
    SAVE_DATA: `${backendPath}/bucket`,
    ARCHIVED: `${backendPath}/bucketArchived`,
    ACTIVE: `${backendPath}/bucketActive`,
    METRICS: `${backendPath}/bucketMetrics`,
  },
  EVENT: {
    LOAD_GRID: `${backendPath}/eventLoadGrid`,
    GET_DATA: `${backendPath}/event`,
    SAVE_DATA: `${backendPath}/event`,
    ARCHIVED: `${backendPath}/eventArchived`,
    ACTIVE: `${backendPath}/eventActive`,
    METRICS: `${backendPath}/eventMetrics`,
    DUPLICATE: `${backendPath}/eventDuplicate`,
  },
  ROOT: {
    VALIDATE: `${backendPath}/rootValidate`,
    BY_ALIAS: `${backendPath}/rootByAlias`,
    LOAD_GRID: `${backendPath}/rootLoadGrid`,
    GET_DATA: `${backendPath}/rootDetails`,
    SAVE_DATA: `${backendPath}/root`,
    ARCHIVED: `${backendPath}/rootArchived`,
    ACTIVE: `${backendPath}/rootActive`,
    ALL: `${backendPath}/rootAll`,
    GUIDE_ALL: `${backendPath}/guideAll`,
    SEARCH: `${backendPath}/root`,
    METRICS: `${backendPath}/rootMetrics`,
    CHECKIN: `${backendPath}/rootCheckin`,
    UPDATE_BY_ANALIST: `${backendPath}/rootUpdate`,
  },
  ASSIST: {
    LOAD_GRID: `${backendPath}/assistsByUser`,
    SAVE: `${backendPath}/assist`,
  },
  MASTER: {
    LOAD_GRID: `${backendPath}/masterLoadGrig`,
    SAVE: `${backendPath}/master`,
    GET_DATA: `${backendPath}/master`,
    ARCHIVED: `${backendPath}/masterArchived`,
    ACTIVE: `${backendPath}/masterActive`,
  },
  LOGS: {
    ROOT: `${backendPath}/rootlog`,
  },
  PACKSCONTENT: {
    LOAD_GRID: `${backendPath}/packcontentLoadGrid`,
    ALL: `${backendPath}/packcontentAll`,
    FILTERS: `${backendPath}/packcontentFilters`,
    GET_DATA: `${backendPath}/packcontent`,
    SAVE_DATA: `${backendPath}/packcontent`,
    SAVE_DATA_CONTENT: `${backendPath}/packcontentContent`,
    ARCHIVED: `${backendPath}/packcontentArchived`,
    AIRTABLE_SYNC_PLANS: `${backendPath}/packContentSyncAirtable`,
    ACTIVE: `${backendPath}/packcontentActive`,
    AISYNC: `${backendPath}/packcontentAirtableSync`,
    DUPLICATE: `${backendPath}/packcontentDuplicate`,
    METRICS: `${backendPath}/packcontentMetrics`,
    RELOAD: `${backendPath}/packcontentReload`,
    CATEGORIES: `${backendPath}/packCategory`,
  },
  PLANSCONTENT: {
    LOAD_GRID: `${backendPath}/plancontentLoadGrid`,
    GET_DATA: `${backendPath}/plancontent`,
    GET_DATA_BY_CODE: `${backendPath}/plancontentByCode`,
    SAVE_DATA: `${backendPath}/plancontent`,
    ARCHIVED: `${backendPath}/plancontentArchived`,
    ACTIVE: `${backendPath}/plancontentActive`,
    ALL: `${backendPath}/plancontentAll`,
    SAVE_DATA_CONTENT: `${backendPath}/plancontentContent`,
    UPDATE_JSON_S3_WEBSITE: `${backendPath}/plancontentWebsite`,
    REFRESH_JSON_S3_WEBSITE: `${backendPath}/uploadJsonPlanToS3`,
    DUPLICATE: `${backendPath}/plancontentDuplicate`,
    METRICS: `${backendPath}/plancontentMetrics`,
    RELOAD: `${backendPath}/plancontentReload`,
    BY_ALIAS: `${backendPath}/plancontentByAlias`,
    CONSOLIDATE: `${backendPath}/plancontentConsolidate`,
    DUPLICATE_ALL_BY_CATEGORY_PACK: `${backendPath}/packcontentDuplicateByCategory`,
  },
});

export const setting = Object.freeze({
  LOCAL_STORAGE: `cluuf-settings`,
});
