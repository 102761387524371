import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.less";
import { spaRoutes } from "./constants/routes";
import Login from "./pages/Login";
import UserLogin from "./pages/UserLogin";

import Signin from "./pages/Signin";
import Home from "./pages/Home";
import HomeUser from "./pages/HomeUser";
import Qrcode from "./pages/Qrcode";
import QrcodePlan from "./pages/QrcodePlan";
import Analist from "./pages/Analist";

import ModuloAplicaciones from "./pages/moduloAplicaciones";

import Activities from "./pages/Activities";
import Event from "./pages/Event";

import AnalistWeb from "./pages/AnalistWeb";
import AnalistV2 from "./pages/AnalistV2";
import TourGuide from "./pages/TourGuide";
import Report from "./pages/Report";
import Logout from "./pages/logout";

import UserStatusExpress from "./pages/UserStatusExpress";

import useLoading from "./features/shared/hooks/useLoading";
import { loadInstanceInfo } from "./features/login/request";
import { setInstanceRedux } from "./redux/instance";

import global from "./state/global";

export default function CluufApp() {
  const dispatch = useDispatch();

  const { loader, showLoading, hideLoading } = useLoading();
  global.loader = {
    loader,
    showLoading,
    hideLoading,
  };

  useEffect(() => {
    if (localStorage.getItem("instanceId")) {
      loadInstanceInfo({
        onSuccess: (response) => {
          setInstanceRedux(response.result)(dispatch);
        },
        onError: () => {},
      });
    }
  }, []);

  return (
    <div className="cluuf-app">
      <Router>
        <Switch>
          <Route exact path={spaRoutes.LOGIN} component={Login} />
          <Route exact path={spaRoutes.LOGINUSER} component={UserLogin} />
          <Route exact path={spaRoutes.LOGINGOTO} component={Login} />
          <Route exact path={spaRoutes.USERGOTO} component={UserLogin} />
          <Route exact path={spaRoutes.SIGNIN} component={Signin} />
          <Route exact path={spaRoutes.HOME} component={Home} />
          <Route exact path={spaRoutes.HOMETYPE} component={Home} />
          <Route exact path={spaRoutes.HOMETYPEID} component={Home} />
          <Route exact path={spaRoutes.HOMEUSER} component={HomeUser} />
          <Route exact path={spaRoutes.HOMETYPEIDEXPRESS} component={Home} />
          <Route exact path={spaRoutes.CARNET} component={Qrcode} />
          <Route exact path={spaRoutes.QRPLAN} component={QrcodePlan} />
          <Route exact path={spaRoutes.USEREXTERNAL} component={UserStatusExpress} />
          <Route exact path={spaRoutes.ANALIST} component={Analist} />
          <Route exact path={spaRoutes.ACTIVITIES} component={Activities} />
          <Route exact path={spaRoutes.EVENT} component={Event} />
          <Route exact path={spaRoutes.MODULO_APLICACIONES} component={ModuloAplicaciones} />
          <Route exact path={spaRoutes.ANALISTV2} component={AnalistV2} />
          <Route exact path={spaRoutes.ANALIST_APPID} component={Analist} />
          <Route exact path={spaRoutes.ANALIST_PLANID} component={Analist} />
          <Route exact path={spaRoutes.ANALIST_TABS} component={Analist} />
          <Route exact path={spaRoutes.TOURGUIDE} component={TourGuide} />
          <Route exact path={spaRoutes.REPORT} component={Report} />
          <Route exact path={spaRoutes.LOGOUT} component={Logout} />
          <Route exact path={spaRoutes.ANALISTWEB} component={AnalistWeb} />
        </Switch>
      </Router>
      {loader}
    </div>
  );
}
