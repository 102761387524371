import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Form, Button, Col, Row, Input, Space, Select, Checkbox, InputNumber } from "antd";

import { handleAddTrans, handleAddPendingTrans } from "../functions/index";
import showGlobalNotification from "../../../helpers/showGlobalNotification";
import { setTransRedux } from "../../../redux/trans";

const defaultValues = {
  notifyByEmail: false,
  amount: "0",
  discount: "0",
  reference: "",
  type: "cash",
  cupon: "",
  document: "",
  DocumentType: "",
  email: "",
  lastname: "",
  pending: "0",
};
const { Option } = Select;

const AddPaymentAnalist = ({
  onClose,
  t,
  reload,
  transSelected = {},
  userSelected = {},
  appId = null,
  userId = null,
  planId = null,
  packId = null,
  handleOpenApp,
  isEmbed,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const dispatch = useDispatch();

  const onChangeNotifyEmail = (notifyByEmail) => {
    setData({ ...data, notifyByEmail: notifyByEmail.target.value });
  };

  const handleReset = () => {
    setData(defaultValues);
    setIsUpdate(false);
    form.setFieldsValue(defaultValues);
  };

  const handleClose = () => {
    handleReset();
    onClose();
  };

  const onFinish = (values) => {
    let params = {
      ...data,
      ...values,
      userId,
      appId,
      packId,
      planId,
      status: 0,
    };

    if (parseInt(params.pending) > 0) {
      params = { ...params, isPartial: true };
    }

    if (transSelected._id) {
      params = {
        ...params,
        transId: transSelected._id,
        status: "close",
        isPending: false,
        total: parseFloat(transSelected.total),
      };
    }

    setLoadingSubmit(true);
    handleAddTrans(
      { params, t },
      {
        onSuccess: () => {
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
          if (parseInt(params.pending) > 0) {
            handleAddPendingTrans(
              { params, t },
              {
                onSuccess: () => {
                  setTransRedux({})(dispatch);
                  showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
                  handleClose();
                  setLoadingSubmit(false);
                },
              }
            );
          } else {
            handleClose();
            setTransRedux({})(dispatch);
            setLoadingSubmit(false);
            if (isEmbed) handleOpenApp(params.appId);
          }
        },
        onError: (err) => {
          console.log(err);
        },
      }
    );
  };

  const onValuesChange = (values) => {
    if (parseFloat(values.amount) < 0) values.amount = 0;

    if (values.amount) {
      setData({ ...data, ...values, pending: parseFloat(transSelected.pending) - parseFloat(values.amount) });
      form.setFieldsValue({
        ...data,
        ...values,
        pending: parseFloat(transSelected.pending) - parseFloat(values.amount),
      });
    } else {
      setData({ ...data, ...values });
      form.setFieldsValue({ ...data, ...values });
    }
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  useEffect(() => {
    handleReset();
    form.setFieldsValue({ ...defaultValues, ...userSelected });
    if (transSelected?._id && transSelected.isPending && transSelected.status === "open") {
      form.setFieldsValue({ ...defaultValues, ...userSelected, amount: transSelected.pending, pending: "0" });
      setIsUpdate(true);
    }
  }, [reload]);

  useEffect(() => {
    form.setFieldsValue(userSelected);
  }, [form, setData]);

  useEffect(() => {
    setData({
      ...userSelected,
      // birthdate: moment(user.birthdate, "YYYY/MM/DD") || moment("2020/12/12", "YYYY/MM/DD"),
    });
  }, [userSelected]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [form, setData]);

  useEffect(() => {
    form.setFieldsValue({ amount: transSelected.pending });
  }, []);

  return (
    <Form
      layout="vertical"
      form={form}
      name="manage-category-form-modal"
      initialValues={defaultValues}
      className="manage-modal"
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      onValuesChange={onValuesChange}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label={t("trans_paymentMode")}
            name="mode"
            rules={[{ required: true, message: t("common_thisFieldIsRequired") }]}
          >
            <Select name="amount" placeholder={t("trans_paymentMode")}>
              <Option value="cash">{t("payment_cash")}</Option>
              <Option value="debitcard">{t("payment_debitcard")}</Option>
              <Option value="creditcard">{t("payment_creditcard")}</Option>
              <Option value="transfer">{t("payment_transfer")}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="reference" label={t("trans_referenceNumber")}>
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="amount" label={t("trans_amountTopay")}>
            <InputNumber
              disabled
              style={{ fontSize: 22, color: "#000", fontWeight: "bold", background: "yellow" }}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="cupon" label={t("trans_coupon")}>
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="discount" label={t("trans_discount")}>
            <Input placeholder="" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name="description" label={t("trans_paymentDescription")}>
            <Input placeholder="" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="firstname"
            label={t("contact_firstname")}
            rules={[{ required: true, message: t("common_thisFieldIsRequired") }]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lastname"
            label={t("contact_lastname")}
            rules={[{ required: true, message: t("common_thisFieldIsRequired") }]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="email"
            label={t("contact_email")}
            rules={[{ required: true, message: t("common_thisFieldIsRequired") }]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="phone" label={t("contact_phone")}>
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="documentType"
            label={t("contact_documentType")}
            rules={[{ required: true, message: t("common_thisFieldIsRequired") }]}
          >
            <Select placeholder={t("contact_documentType")}>
              <Option value={t("document_cedulaCiudadania")}>{t("document_cedulaCiudadania")}</Option>
              <Option value={t("document_identificationCard")}>{t("document_identificationCard")}</Option>
              <Option value={t("document_cedulaExtranjeria")}>{t("document_cedulaExtranjeria")}</Option>
              <Option value={t("document_passport")}>{t("document_passport")}</Option>
              <Option value={t("document_pep")}>{t("document_pep")}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="document" label={t("contact_document")}>
            <Input placeholder="" />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="end">
        <Col>
          <Space>
            <Button className="ant-btn secondary error large" size="large" onClick={handleClose}>
              {t("common_cancel")}
            </Button>
            <Button
              className="ant-btn secondary primary large"
              size="large"
              loading={loadingSubmit}
              onClick={handleSubmit}
              type="primary"
            >
              {t("trans_doPayment")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default AddPaymentAnalist;
