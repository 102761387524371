import { FetchJson } from "../../helpers/fetch";
import { apiRoutes } from "../../constants/routes";

const getPackMetrics = async (params = {}, { onSuccess, onError } = {}) => {
  try {
    const searchParams = new URLSearchParams(params);
    const request = await FetchJson(`${apiRoutes.PACKS.METRICS}?${searchParams.toString()}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getUserMetrics = async (params = {}, { onSuccess, onError } = {}) => {
  try {
    const searchParams = new URLSearchParams(params);
    const request = await FetchJson(`${apiRoutes.USERS.METRICS}?${searchParams.toString()}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getPlanMetrics = async (params = {}, { onSuccess, onError } = {}) => {
  try {
    const searchParams = new URLSearchParams(params);
    const request = await FetchJson(`${apiRoutes.PLANS.METRICS}?${searchParams.toString()}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getAppMetrics = async (params = {}, { onSuccess, onError } = {}) => {
  try {
    const searchParams = new URLSearchParams(params);
    const request = await FetchJson(`${apiRoutes.APPS.METRICS}?${searchParams.toString()}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getCurrentAppActives = async (params = {}, { onSuccess, onError } = {}) => {
  try {
    const searchParams = new URLSearchParams(params);
    const request = await FetchJson(`${apiRoutes.APPS.CURRENT_ACTIVES}?${searchParams.toString()}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getTransMetrics = async (params = {}, { onSuccess, onError } = {}) => {
  try {
    const searchParams = new URLSearchParams(params);
    const request = await FetchJson(`${apiRoutes.TRANS.METRICS}?${searchParams.toString()}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getEventMetrics = async (params = {}, { onSuccess, onError } = {}) => {
  try {
    const searchParams = new URLSearchParams(params);
    const request = await FetchJson(`${apiRoutes.EVENT.METRICS}?${searchParams.toString()}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getUsersMetrics2 = async (params = {}, { onSuccess, onError } = {}) => {
  try {
    const searchParams = new URLSearchParams(params);
    const request = await FetchJson(`${apiRoutes.USERS.METRICS2}?${searchParams.toString()}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

export {
  getEventMetrics,
  getCurrentAppActives,
  getTransMetrics,
  getAppMetrics,
  getPackMetrics,
  getUserMetrics,
  getPlanMetrics,
  getUsersMetrics2,
};
