/* eslint-disable no-nested-ternary */
import { PageHeader, Button, Statistic, Tooltip, Spin, Space } from "antd";

import { useSelector } from "react-redux";

import { globals, priceFormat, icons, urlLandingPage, images, tagAppStatus } from "../../../constants/globals";
import { setPlan } from "../../../redux/plan";

const Header = ({
  setPage,
  handleEdit,
  openDeleteModal,
  t,
  refetch,
  isGoback,
  handleGoto,
  selectedPlan,
  handleClosePlanByGuide,
  handleUploadPlanFile,
  handleArchivedPlan,
  isAnalistView,
}) => {
  const sessionStoreRedux = useSelector((store) => store.session);
  const instanceStoreRedux = useSelector((store) => store.instance);
  // validate extract Json Data

  const renderContent = (column = 3) => <></>;

  const goBack = (plan) => {
    if (isGoback) {
      handleGoto({ number: 21, id: plan.packId });
    } else {
      setPage(globals.PAGE_MAIN);
    }
  };

  const extraContent = (
    <>
      <div
        style={{
          display: "flex",
          width: "max-content",
          justifyContent: "flex-end",
        }}
      >
        <div className="container-statistic">
          {selectedPlan?.isTemporal && (
            <Statistic
              title={t("plan_status")}
              value={selectedPlan?.status}
              style={{
                marginRight: 70,
              }}
            />
          )}

          {!selectedPlan?.unlimited && (
            <Statistic
              title={t("plan_availability")}
              value={selectedPlan?.availability}
              style={{
                marginRight: 70,
              }}
            />
          )}
          <Statistic
            title={t("plan_isProgress")}
            value={selectedPlan?.progress}
            style={{
              marginRight: 70,
            }}
          />

          <Statistic
            title={t("plan_isPending")}
            value={selectedPlan?.pending}
            style={{
              marginRight: 70,
            }}
          />

          <Statistic
            title={t("plan_isStarting")}
            value={selectedPlan?.starting}
            style={{
              marginRight: 70,
            }}
          />

          <Statistic
            title={t("plan_isEnding")}
            value={selectedPlan?.ending}
            style={{
              marginRight: 70,
            }}
          />

          <Statistic
            title={t("plan_isClosed")}
            value={selectedPlan?.closed}
            style={{
              marginRight: 70,
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "max-content",
          justifyContent: "flex-end",
          marginTop: 10,
        }}
      >
        {!selectedPlan?.isFree && (
          <div className="container-statistic">
            <Statistic
              title={t("porPagar")}
              value={priceFormat(selectedPlan?.pendingTrans)}
              style={{
                marginRight: 70,
              }}
            />

            <Statistic
              title={t("pagada")}
              value={priceFormat(selectedPlan?.totalAmountApps)}
              style={{
                marginRight: 70,
              }}
            />

            <Statistic
              title={t("total")}
              value={priceFormat(selectedPlan?.totalTrans)}
              style={{
                marginRight: 70,
              }}
            />
          </div>
        )}

        {!selectedPlan?.isFree && (
          <div className="container-statistic">
            <Statistic
              title={t("trans_card")}
              value={priceFormat(selectedPlan.totalCardAmount)}
              style={{
                marginRight: 30,
              }}
            />

            <Statistic title={t("trans_cash")} value={priceFormat(selectedPlan.totalCashAmount)} style={{}} />
          </div>
        )}

        {!selectedPlan?.isFree && (
          <div className="container-statistic">
            <Statistic
              title={t("inbound")}
              value={priceFormat(selectedPlan.inboundTransTotal)}
              style={{
                marginRight: 30,
              }}
            />
          </div>
        )}
      </div>
    </>
  );

  const Content = ({ children, extra }) => (
    <div className="content">
      <div className="main">{children}</div>
      <div className="extra">{extra}</div>
    </div>
  );

  return (
    <>
      {selectedPlan ? (
        !isAnalistView ? (
          <PageHeader
            className="site-page-header-responsive"
            onBack={() => {
              refetch();
              goBack(selectedPlan);
              setPage(globals.PAGE_MAIN);
            }}
            title={`${selectedPlan?.name} `}
            subTitle={selectedPlan?.pack?.name}
            tags={tagAppStatus(selectedPlan.status)}
            extra={[
              selectedPlan?.isTemporal && (
                <Tooltip placement="topLeft" title={t("goToLandingPage")}>
                  <Button
                    className="ant-btn secondary info"
                    key="4"
                    icon={icons.upload}
                    size="medium"
                    onClick={() => handleUploadPlanFile(selectedPlan)}
                  >
                    {" "}
                  </Button>
                </Tooltip>
              ),
              <Tooltip placement="topLeft" title={t("goToLandingPage")}>
                <a
                  key="3"
                  icon={icons.edit}
                  className="ant-btn secondary "
                  target="blank"
                  href={urlLandingPage({
                    alias: instanceStoreRedux?.currentInstance.alias,
                    packId: selectedPlan?.pack?._id,
                    planId: selectedPlan?._id,
                    rootEmail: sessionStoreRedux?.currentSession.email,
                    campaign: "Local",
                    refererAppId: null,
                    refererUserId: null,
                  })}
                >
                  {icons.link}
                </a>
              </Tooltip>,

              !isAnalistView && (
                <Tooltip placement="topLeft" title={t("edit")}>
                  <Button
                    className="ant-btn secondary primary"
                    key="1"
                    icon={icons.edit}
                    size="medium"
                    onClick={() => handleEdit(selectedPlan)}
                  />
                </Tooltip>
              ),
              !isAnalistView && (
                <Tooltip placement="topLeft" title={t("delete")}>
                  <Button
                    className="ant-btn secondary error fill"
                    icon={icons.delete}
                    key="2"
                    onClick={() => handleArchivedPlan({ plan: selectedPlan, t, refetch })}
                  >
                    Eliminar
                  </Button>
                </Tooltip>
              ),
            ]}
          >
            <Content extra={extraContent}>{renderContent()}</Content>
          </PageHeader>
        ) : (
          <Content extra={extraContent}>{renderContent()}</Content>
        )
      ) : (
        <Spin />
      )}
    </>
  );
};

export default Header;
