import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Row, Col, Input, InputNumber, Spin, Switch } from "antd";
import { saveExpense, getExpense } from "../requests";
import { getPlan } from "../../subscribersPlans/requests";

import { globals, icons, priceFormat } from "../../../constants/globals";

import showGlobalNotification from "../../../helpers/showGlobalNotification";
import BaseModal from "../../shared/components/Modal/BaseModal";
import { setExpenseRedux, reloadRedux as reloadExpenseRedux } from "../../../redux/expense";
import SelectSupplier from "../../subscribersSuppliers/components/SelectSupplier";
import SelectService from "../../subscribersServices/components/SelectService";

const defaultValues = {
  description: "",
  category: "",
  active: true,
  public: false,
  price: 0,
  supplierId: null,
};

const ManageExpenseModal = ({
  openModal,
  planId,
  closeModal,
  refetch,
  t,
  setExpenseId,
  expenseId = null,
  page,
  setReload_ = null,
}) => {
  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);
  const [form] = Form.useForm();
  const [selectedExpense, setSelectedExpense] = useState(defaultValues);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [selectedService, setSelectedService] = useState({});

  const [loaderForm, setLoaderForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [reload, setReload] = useState(0);

  const [supplierId, setSupplierId] = useState(null);
  const [serviceId, setServiceId] = useState(null);

  const handleReset = () => {
    setSelectedExpense(defaultValues);
    setReload(Math.floor(Math.random() * 100000));
  };

  useEffect(() => {
    if (openModal) {
      if (expenseId) {
        setMode("edit");
      } else {
        setMode("new");
      }
    } else {
      handleReset();
      setMode(null);
      if (page !== globals.PAGE_ONE) setExpenseId(null);
    }
  }, [openModal]);

  useEffect(() => {
    if (mode === "new") {
      handleReset();
      setSelectedExpense({ ...defaultValues });
      getPlan(planId, {
        onSuccess: (response) => {
          setSelectedPlan({
            ...response.result,
          });
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      setLoaderForm(true);
      getExpense(expenseId, {
        onSuccess: (response) => {
          setExpenseRedux(response.result)(dispatch);
          setSelectedExpense({
            ...response.result,
          });
          setLoaderForm(false);
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  }, [mode]);

  useEffect(() => {
    form.setFieldsValue(selectedExpense);
  }, [form, selectedExpense]);

  useEffect(() => {
    setSelectedExpense({ ...selectedExpense, price: selectedService.price || 0 });
  }, [selectedService]);

  const handleCloseModal = () => {
    refetch();
    closeModal();
  };

  const onFinish = (values) => {
    const data = { ...selectedExpense, ...values, planId: selectedPlan };

    saveExpense(
      {
        ...data,
        rootId: sessionStoreRedux.currentSession_id,
      },
      {
        onSuccess: (response) => {
          reloadExpenseRedux()(dispatch);
          setExpenseRedux(response.usuario)(dispatch);
          if (setReload_) setReload_(Math.floor(Math.random() * 100000));
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => handleCloseModal(),
      }
    );
  };

  const callback = (key) => {
    console.log(key);
  };

  const onValuesChange = (values) => {
    setSelectedExpense({ ...selectedExpense, ...values });
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  const handleSupplier = (value) => {
    setSupplierId(value);
    setServiceId(null);
    setSelectedExpense({ ...selectedExpense, supplierId: value });
  };

  const handleService = (value) => {
    setServiceId(value);
    setSelectedExpense({ ...selectedExpense, serviceId: value });
  };

  const handleByApps = (checked) => {
    setSelectedExpense({ ...selectedExpense, isByApps: checked });
  };

  return (
    <BaseModal
      isOpenModal={openModal}
      closeModal={handleCloseModal}
      onCancel={handleCloseModal}
      onConfirm={() => handleSubmit()}
      title={expenseId ? `${t("edit")} ${t("expense")}` : `${t("new")} ${t("expense")}`}
      width={1000}
      confirmText={t("save")}
      isCloseModal
      top={10}
      icon={icons.edit}
    >
      <Form
        form={form}
        name="manage-category-form-modal"
        initialValues={defaultValues}
        className="manage-modal"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        onValuesChange={onValuesChange}
      >
        <div className="layout-form-modal entity-450 entity-small">
          {loaderForm ? (
            <>
              <Spin tip={t("loading")} />
            </>
          ) : (
            <>
              <Row gutter={16} className="box-info">
                <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="serviceId3" tooltip="" label="Seleccione un proveedor">
                    <SelectSupplier
                      reload={reload}
                      currentValue={supplierId}
                      defaultValue={supplierId}
                      returnData={handleSupplier}
                      typePack="SUBSCRIPTION"
                      kind="subscription"
                    />
                  </Form.Item>
                </Col>

                {supplierId !== null && (
                  <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item name="serviceId2" tooltip="" label="Seleccione un servicio">
                      <SelectService
                        reload={reload}
                        currentValue={serviceId}
                        defaultValue={serviceId}
                        returnData={handleService}
                        typePack="SUBSCRIPTION"
                        kind="subscription"
                        supplierId={supplierId}
                        setInfo={setSelectedService}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              {serviceId !== null && (
                <Row gutter={16} className="box-info">
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item label="Costo unitario del servicio" name="price">
                      <InputNumber
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name="active"
                      labelAlign="right"
                      extra={
                        <>
                          {" "}
                          {icons.check_enabled} Actualmente hay {selectedPlan.openApps} aplicaciones confirmadas
                        </>
                      }
                      label="Costo por cada aplicación?"
                    >
                      <Switch checked={!!selectedExpense?.isByApps} onChange={handleByApps} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <h2>{icons.dolar} Costo Total</h2>
                    {selectedExpense?.isByApps ? (
                      <>
                        <h3>
                          {selectedPlan.openApps} x {priceFormat(selectedExpense.price)} ={" "}
                          {priceFormat(parseFloat(selectedPlan.openApps) * parseFloat(selectedExpense.price))}
                        </h3>
                      </>
                    ) : (
                      <h3>{priceFormat(selectedExpense.price)}</h3>
                    )}
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      </Form>
    </BaseModal>
  );
};

ManageExpenseModal.defaultProps = {
  setExpenseId: () => {},
};

export default ManageExpenseModal;
