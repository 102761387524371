import { confirmAlert } from "react-confirm-alert";

import { saveTrans, archivedTrans } from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";

import { priceFormat, colorsSystem } from "../../../constants/globals";

const Swal = require("sweetalert2");

export const handleConfirmAddTrans = ({ params, t }, { onSuccess = {} }) => {
  saveTrans(
    {
      ...params,
    },
    {
      onSuccess: () => onSuccess(),
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleAddPendingTrans = ({ params, t }, { onSuccess = {} }) => {
  saveTrans(
    {
      ...params,
      isPending: true,
      transId: null,
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleAddTrans = ({ params, t, refetch }, { onSuccess = {} }) => {
  confirmAlert({
    title: "",
    message: t("AreYouSureWantToContinue"),
    buttons: [
      {
        label: t("yes"),
        onClick: () =>
          handleConfirmAddTrans(
            { params, t, refetch },
            {
              onSuccess: () => {
                console.log("okok");
                onSuccess();
              },
            }
          ),
      },
      {
        label: t("no"),
        onClick: () => {},
      },
    ],
  });
};

export const handleConfirmUpdateTrans = ({ params, t }, { onSuccess = {} }) => {
  saveTrans(
    {
      transId: params.transId,
    },
    {
      onSuccess: () => onSuccess(),
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleUpdateTrans = ({ params, t, refetch }, { onSuccess = {} }) => {
  confirmAlert({
    title: "",
    message: t("AreYouSureWantToContinue"),
    buttons: [
      {
        label: t("yes"),
        onClick: () =>
          handleConfirmUpdateTrans(
            { params, t, refetch },
            {
              onSuccess: () => {
                onSuccess();
              },
            }
          ),
      },
      {
        label: t("no"),
        onClick: () => {},
      },
    ],
  });
};

export const handleArchivedConfirm = ({ trans, t, refetch }) => {
  archivedTrans(
    {
      trans,
    },
    {
      onSuccess: () => {
        refetch();
        Swal.fire(t("common_done"), t("common_trans_confirmed"), "success");
      },
      onError: () =>
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        }),
      onFinally: () => {},
    }
  );
};

export const handleArchivedTrans = ({ trans, t, refetch }) => {
  Swal.fire({
    title: t("common_areYouSure"),
    html: `${t("common_cantRevert")} <br> <b>${t("code")}:</b> ${trans?.code} <br> <b>${t("user")}:</b> ${
      trans?.userId?.firstname
    } ${trans?.userId?.lastname} 
    <br> <b>${t("service")}:</b> ${trans?.packId?.name} ${trans?.planId?.name} 
    <br> <b>${t("amount")}:</b> ${priceFormat(trans?.total)}`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: colorsSystem.red,
    cancelButtonColor: colorsSystem.blue,
    confirmButtonText: t("common_yesIWantContinueDelete"),
    cancelButtonText: t("common_cancel"),
  }).then((result) => {
    if (result.isConfirmed) {
      handleArchivedConfirm({ trans, t, refetch });
    }
  });
};
