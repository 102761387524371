/* eslint-disable no-unused-expressions */
/* eslint-disable react/display-name */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DatePicker, Space, Row, Col, Tabs, Table, PageHeader, Descriptions } from "antd";
import moment from "moment";

import MetricsCard from "../components/MetricsCard";

import MetricsSelect from "../components/MetricsSelect";
import MetricHeader from "../components/MetricHeader";

import { getViewPage, getActions, getViewsByContact, getActionsByContact } from "../requests";

const { RangePicker } = DatePicker;

const { TabPane } = Tabs;

const columns = [
  {
    title: "Url",
    dataIndex: "url",
    key: "url",
  },
  {
    title: "Device",
    dataIndex: "device",
    key: "device",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (date) => moment(date).format("LLLL"),
  },
  {
    title: "Width",
    dataIndex: "width",
    key: "width",
  },
  {
    title: "Language",
    dataIndex: "language",
    key: "language",
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",
  },
  {
    title: "Speed",
    dataIndex: "downlink",
    key: "downlink",
  },
];

const columnsActions = [
  {
    title: "Events",
    dataIndex: "event",
    key: "event",
    render: (event) => (event === "c" ? "Click" : "Scroll"),
  },
  {
    title: "Reference",
    dataIndex: "code",
    key: "code",
    render: (value, row) => (row.event === "s" ? row.href : row.code),
  },
  {
    title: "Date",
    dataIndex: "created",
    key: "created",
    render: (created) => moment(created).format("LLLL"),
  },
];

const Metrics = () => {
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState([]);
  const [referer, setReferer] = useState([]);
  const [country, setCountry] = useState([]);
  const [language, setLanguage] = useState([]);
  const [city, setCity] = useState([]);
  const [region, setRegion] = useState([]);
  const [users, setUsers] = useState([]);

  const [ctas, setCtas] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [dataUserActions, setDataUserActions] = useState([]);

  const [countrySelect, setCountrySelect] = useState([]);
  const [citySelect, setCitySelect] = useState([]);
  const [regionSelect, setRegionSelect] = useState([]);
  const [pageSelect, setPageSelect] = useState([]);

  const [filter, setFilter] = useState({
    country: "",
    city: "",
    page: "",
    referer: "",
    hasta: moment().format("YYYY-MM-DD"),
    desde: moment().subtract(7, "days").format("YYYY-MM-DD"),
  });

  const [keytab, setKeytab] = useState(1);

  useEffect(() => {
    getViewPage(filter, {
      onSuccess: (result) => {
        const xxx = [];
        Object.entries(result.url).forEach(([key, value]) => {
          xxx.push({ name: key, count: value });
        });

        xxx.sort(function (a, b) {
          if (a.count < b.count) {
            return 1;
          }
          if (a.count > b.count) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        setItems(xxx);

        const referidosArray = [];
        Object.entries(result.referrer).forEach(([key, value]) => {
          if (key !== "" && key.indexOf("lospatios") < 0) {
            referidosArray.push({ name: key, count: value });
          }
        });
        referidosArray.sort(function (a, b) {
          if (a.count < b.count) {
            return 1;
          }
          if (a.count > b.count) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        setReferer(referidosArray);

        const countryArray = [];
        Object.entries(result.country).forEach(([key, value]) => {
          countryArray.push({ name: key, count: value });
        });

        countryArray.sort(function (a, b) {
          if (a.count < b.count) {
            return 1;
          }
          if (a.count > b.count) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        setCountry(countryArray);

        // City
        const cityArray = [];
        Object.entries(result.city).forEach(([key, value]) => {
          cityArray.length < 5 && cityArray.push({ name: key, count: value });
        });

        cityArray.sort(function (a, b) {
          if (a.count < b.count) {
            return 1;
          }
          if (a.count > b.count) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        setCity(cityArray);

        // Region
        const regionArray = [];
        Object.entries(result.region).forEach(([key, value]) => {
          regionArray.push({ name: key, count: value });
        });

        regionArray.sort(function (a, b) {
          if (a.count < b.count) {
            return 1;
          }
          if (a.count > b.count) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        setRegion(regionArray);

        const languageArray = [];
        Object.entries(result.language).forEach(([key, value]) => {
          languageArray.push({ name: key, count: value });
        });

        languageArray.sort(function (a, b) {
          if (a.count < b.count) {
            return 1;
          }
          if (a.count > b.count) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        setLanguage(languageArray);

        const userArray = [];
        Object.entries(result.user).forEach(([key, value]) => {
          userArray.push({ name: key, count: value });
        });

        userArray.sort(function (a, b) {
          if (a.count < b.count) {
            return 1;
          }
          if (a.count > b.count) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        setUsers(userArray);
      },
      onError: () => {},
    });

    getActions(filter, {
      onSuccess: (result) => {
        const actx = [];
        Object.entries(result.codigo).forEach(([key, value]) => {
          actx.push({ name: key, count: value });
        });

        actx.sort(function (a, b) {
          if (a.count < b.count) {
            return 1;
          }
          if (a.count > b.count) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        setCtas(actx);
      },
      onError: () => {},
    });
  }, [filter]);

  useEffect(() => {
    if (countrySelect.length < 1) {
      setCountrySelect(country);
    }
  }, [country]);

  useEffect(() => {
    if (regionSelect.length < 1) {
      setRegionSelect(region);
    }
  }, [region]);

  useEffect(() => {
    if (pageSelect.length < 1) {
      setPageSelect(items);
    }
  }, [items]);

  useEffect(() => {
    if (citySelect.length < 1) {
      setCitySelect(city);
    }
  }, [city]);

  function callback(key) {
    setKeytab(key);
  }

  const onChangeDate = (res) => {
    if (res !== null) {
      setFilter({ ...filter, desde: moment(res[0]).format("YYYY-MM-DD"), hasta: moment(res[1]).format("YYYY-MM-DD") });
    } else {
      setFilter({
        ...filter,
        hasta: moment().format("YYYY-MM-DD"),
        desde: moment().subtract(7, "days").format("YYYY-MM-DD"),
      });
    }
  };

  const valor = (value, name) => {
    setFilter({ ...filter, [name]: value });
  };

  const consultar = (user) => {
    getViewsByContact(
      { user },
      {
        onSuccess: (result) => {
          setDataUser(result.data);
        },
        onError: (error) => {},
      }
    );

    getActionsByContact(
      { contact: user },
      {
        onSuccess: (result) => {
          setDataUserActions(result.action);
        },
        onError: (error) => {},
      }
    );
  };

  return (
    <>
      <section style={{ marginBottom: 10 }}>
        <MetricHeader t={t} />
      </section>
      <Row style={{ paddingBottom: 10 }}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div style={{ width: "95%" }}>
            <small>Range of Date</small>
            <RangePicker
              defaultValue={[moment(moment().subtract(7, "days"), "YYYY-MM-DD"), moment(moment(), "YYYY-MM-DD")]}
              onChange={onChangeDate}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
          <Space direction="horizontal" size={12}>
            <MetricsSelect items={countrySelect} title="Country" setValue={valor} name="country" />
            <MetricsSelect items={regionSelect} title="Region" setValue={valor} name="region" />
            <MetricsSelect items={citySelect} title="City" setValue={valor} name="city" />
            <MetricsSelect items={pageSelect} width="300" title="Pages" setValue={valor} name="page" />
          </Space>
        </Col>
      </Row>

      <Tabs defaultActiveKey="1">
        <TabPane tab="Vistas General" key="1">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <MetricsCard title="Paises" items={country} />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <MetricsCard title="Idioma" items={language} styleClass="purple" />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <MetricsCard title="Ciudad" items={city} />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <MetricsCard title="Region" items={region} styleClass="purple" />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <MetricsCard title="Paginas Vistas" items={items} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <MetricsCard title="Referidos" items={referer} styleClass="purple" />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Eventos" key="2">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <MetricsCard title="Ctas" items={ctas} />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              .
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              1
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Contacts" key="3">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={6} lg={12} xl={12}>
              <MetricsCard title="Users" items={users} limit="20" consultar={consultar} />
            </Col>
            <Col xs={24} sm={24} md={18} lg={12} xl={12}>
              {dataUser && dataUser.length > 0 && (
                <PageHeader
                  avatar={{ src: dataUser[0].flag }}
                  className="site-page-header"
                  title={dataUser[dataUser.length - 1].ip}
                  subTitle={dataUser[dataUser.length - 1].user}
                >
                  <Descriptions size="small" column={3}>
                    <Descriptions.Item label="Country">{dataUser[dataUser.length - 1].country}</Descriptions.Item>
                    <Descriptions.Item label="Region">{dataUser[dataUser.length - 1].region}</Descriptions.Item>
                    <Descriptions.Item label="City">{dataUser[dataUser.length - 1].city}</Descriptions.Item>
                    <Descriptions.Item label="Device">{dataUser[dataUser.length - 1].device}</Descriptions.Item>
                    <Descriptions.Item label="Language">{dataUser[dataUser.length - 1].language}</Descriptions.Item>
                    <Descriptions.Item label="Width Screen">{dataUser[dataUser.length - 1].width}</Descriptions.Item>
                    <Descriptions.Item label="Speed">{dataUser[dataUser.length - 1].downlink}</Descriptions.Item>
                    <Descriptions.Item label="Created">
                      {moment(dataUser[dataUser.length - 1].date).format("L")}
                    </Descriptions.Item>
                  </Descriptions>
                </PageHeader>
              )}
              <Tabs defaultActiveKey="1">
                <TabPane tab="Paginas Vistas" key="20">
                  {dataUser && dataUser.length > 0 && (
                    <Table pagination={false} scroll={{ x: 1200 }} columns={columns} dataSource={dataUser} />
                  )}
                </TabPane>
                <TabPane tab="Eventos realizados por el usuario" key="21">
                  {dataUserActions && dataUserActions.length > 0 && (
                    <Table pagination={false} columns={columnsActions} dataSource={dataUserActions} />
                  )}
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </>
  );
};

export default Metrics;
