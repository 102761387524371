import { PlusOutlined } from "@ant-design/icons";
import PrimaryButton from "../../shared/components/PrimaryButton";
import ManageSupplierModal from "./ManageServiceModal";
import useModal from "../../shared/hooks/useModal";
import useShortcut from "../../shared/hooks/useShortcut";

const AddSupplierButton = ({ refetch, initialValues, t }) => {
  const [isOpenManageUsersModal, openManageUsersModal, closeManageUsersModal] = useModal();

  useShortcut(["n"], openManageUsersModal, [isOpenManageUsersModal]);

  return (
    <>
      <PrimaryButton onClick={openManageUsersModal} type="primary" size="large" icon={<PlusOutlined />}>
        Nuevo proveedor
      </PrimaryButton>
      <ManageSupplierModal
        openModal={isOpenManageUsersModal}
        closeModal={closeManageUsersModal}
        initialValues={initialValues}
        refetch={refetch}
        t={t}
      />
    </>
  );
};

export default AddSupplierButton;
