import { Menu, Dropdown, Button, message, Space, Tooltip } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";

import { icons } from "../../../constants/globals";

const MenuTable = ({ t, row, instanceStoreRedux, root, handleArchivedPlan, refetch }) => {
  const handleMenuClick = (e) => {
    console.log("click", e);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="3">
        <Button
          size="small"
          className="ant-btn secondary error"
          onClick={() => handleArchivedPlan({ plan: row, t, refetch })}
        >
          {icons.delete} {t("delete")}
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Space wrap>
      <Dropdown overlay={menu}>
        <Button className="secondary" size="small">
          Acciones <DownOutlined />
        </Button>
      </Dropdown>
    </Space>
  );
};

export default MenuTable;
