import { useState, useEffect } from "react";
import { Row, Col } from "antd";

import { withRouter } from "react-router-dom";
import { dateFormat4, urlLandingPage } from "../constants/globals";
import { loadBranding, loadPlanBranding } from "../features/login/request";

const QrcodePlan = ({ match }) => {
  const [setting, setSetting] = useState({});
  const [plan, setPlan] = useState({});
  const [active, setActive] = useState(false);

  useEffect(() => {
    loadBranding(match.params.alias, {
      onSuccess: (response) => {
        setSetting(response.result);
        loadPlanBranding(match.params.id, {
          onSuccess: (response2) => {
            setPlan(response2.result);
            setActive(true);
          },
          onError: () => {},
        });
      },
      onError: () => {},
    });
  }, [match.params.alias]);

  return (
    <>
      {" "}
      <>
        <div className="qr-section-external" style={{ backgroundColor: setting.color, height: "800px" }}>
          {active && (
            <div className="wrapper">
              <img src={setting.logo} alt="logo" style={{ padding: "20px 0px" }} />
              <small style={{ color: "#000" }}>{setting.website}</small>
              <Row>
                <Col>
                  <h1>{plan.name}</h1>
                </Col>
              </Row>
              <div className="qr-wrapper"> </div>
              <Row>
                <Col>
                  <p>
                    Vence: {dateFormat4(plan.closureDate)} <br />
                  </p>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </>
    </>
  );
};
export default withRouter(QrcodePlan);
