import { PageHeader } from "antd";

const Header = ({ t, primaryAction }) => (
  <>
    <PageHeader
      avatar={{ src: "https://cluuftracks.herokuapp.com/bot.gif" }}
      className="site-page-header-responsive"
      onBack={() => {}}
      title={t("profile")}
      subTitle="..."
      extra={[primaryAction && primaryAction]}
    />
  </>
);

export default Header;
