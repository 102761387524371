import { useState, useEffect } from "react";
import { Form, Row, Col, Input, Divider, DatePicker, PageHeader, Descriptions, Button, Select } from "antd";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { confirmAlert } from "react-confirm-alert";

import { withRouter } from "react-router-dom";
import { icons, dateFormat4, priceFormat, STATUS_APP, durationFormat } from "../../../constants/globals";
import { saveUserUpdate } from "../../login/request";

import SelectPacks from "../../subscribers/components/SelectPacks";
import SelectPlans from "../../subscribersPlans/components/SelectPlans";

const moment = require("moment");
const Swal = require("sweetalert2");

const defaultValues = {
  firstname: "",
  lastname: "",
  document: "",
  documentType: "",
  paymentMode: "none",
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  paymentReference: "",
  phone: "+57",
  alergies: "",
  bodylesson: "",
  address: "",
  profession: "",
  medium: "",
  city: "",
  description: "",
};

const ActivateApp = ({ selectedApp, refetch, onClose, isDesactivate = false }) => {
  const { t } = useTranslation();
  const instanceStoreRedux = useSelector((store) => store.instance);

  const [setting, setSetting] = useState({});
  const [user, setUser] = useState({});
  const [app, setApp] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [form] = Form.useForm();

  const [packId, setPackId] = useState(selectedApp.packId._id);
  const [planId, setPlanId] = useState(selectedApp.planId._id);
  const [plan, setPlan] = useState({});
  const [reload, setReload] = useState(0);

  const onValuesChange = (values) => {
    setData({ ...data, ...values });
  };

  const handleStartdate = (values) => {
    setData({
      ...data,
      closureDate: moment(values).add(plan.duration, durationFormat(plan.durationMode)),
      startDate: values,
      startdate: moment(values).format("YYYY-MM-DD"),
      closuredate: moment(values).add(plan.duration, durationFormat(plan.durationMode)).format("YYYY-MM-DD"),
    });

    console.log({
      mode: durationFormat(plan.durationMode),
      duration: plan.duration,
      closureDate: moment(values).add(plan.duration, durationFormat(plan.durationMode)),
      startDate: values,
      startdate: moment(values).format("YYYY-MM-DD"),
      closuredate: moment(values).add(plan.duration, durationFormat(plan.durationMode)).format("YYYY-MM-DD"),
    });
  };

  const onFinish = () => {
    setData({ ...data, app: selectedApp });

    if (data.active) setData({ ...data, status: STATUS_APP.OPEN });
    else setData({ ...data, status: STATUS_APP.CANCEL });

    saveUserUpdate(data, {
      onSuccess: () => {
        Swal.fire({
          icon: "success",
          title: "Operación Exitosa",
        });
        refetch();
        onClose();
      },
      onError: () => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      },
      onFinally: () => {},
    });
  };

  const handleSubmit = () => {
    confirmAlert({
      title: "Confirmación",
      message: `Esta seguro que desea continuar?`,
      buttons: [
        {
          label: "Continuar",
          onClick: () => form.submit(),
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const handleDesactivate = () => {
    if (data.description === undefined || String(data.description).length < 5) {
      Swal.fire({
        icon: "info",
        title: "Importante",
        text: "Por favor justifique en el campo observación el motivo de la cancelación de esta aplicación",
      });
    } else {
      confirmAlert({
        title: "Desactivar aplicación",
        message: `Esta seguro que desea continuar?`,
        buttons: [
          {
            label: "Continuar",
            onClick: () => {
              setData({ ...data, active: false, status: STATUS_APP.CANCEL });
              form.submit();
            },
          },
          {
            label: "Cancelar",
            onClick: () => {},
          },
        ],
      });
    }
  };

  const handlePack = (value) => {
    setPackId(value);
    setPlanId(null);
    setData({
      ...data,
      packId: value,
      startDate: moment(selectedApp?.startDate, "YYYY/MM/DD"),
      closureDate: null,
    });
  };

  const handlePlan = (value) => {
    setPlanId(value);
    setData({
      ...data,
      planId: value,
      startDate: moment(selectedApp?.startDate, "YYYY/MM/DD"),
      closureDate: moment(plan?.startDate).add(plan.duration, durationFormat(plan.durationMode)),
    });
  };

  useEffect(() => {
    setPlan({});
    setData({
      ...data,
      packId,
    });
  }, [packId]);

  useEffect(() => {
    setData({
      ...data,
      startDate: moment(selectedApp?.startDate),
      closureDate: moment(selectedApp?.startDate).add(plan.duration, durationFormat(plan.durationMode)),
      startdate: moment(selectedApp?.startDate).format("YYYY-MM-DD"),
      closuredate: moment(selectedApp?.startDate)
        .add(plan.duration, durationFormat(plan.durationMode))
        .format("YYYY-MM-DD"),
    });
  }, [plan]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  useEffect(() => {
    setReload(Math.floor(Math.random() * 100000));
    setSetting(instanceStoreRedux.currentInstance);
    setApp(selectedApp);
    if (selectedApp?.planId) {
      setData({
        appId: selectedApp?._id,
        userId: selectedApp?.userId._id,
        firstname: selectedApp?.userId.firstname,
        lastname: selectedApp?.userId.lastname,
        address: selectedApp?.userId.address,
        document: selectedApp?.userId.document,
        documentType: selectedApp?.userId.documentType,
        city: selectedApp?.userId.city,
        phone: selectedApp?.userId.phone,
        email: selectedApp?.userId.email,
        packId: selectedApp?.packId._id,
        planId: selectedApp?.planId._id,
        active: true,
        paymentMode: "none",
        startDate: moment(selectedApp?.startDate, "YYYY/MM/DD"),
        closureDate: moment(selectedApp?.startDate).add(
          selectedApp.planId.duration,
          durationFormat(selectedApp.planId.durationMode)
        ),
      });
      setPlan(selectedApp?.planId);
      setLoading(false);
    }
  }, []);
  // selectedApp?.planId?.duration

  return (
    <>
      {!loading && (
        <Row>
          <Col span={22} offset={1}>
            <Form
              form={form}
              name="manage-category-form-modal "
              className="manage-modal minimal-form"
              onFinish={onFinish}
              labelCol={{ span: 24 }}
              onValuesChange={onValuesChange}
              initialValues={defaultValues}
            >
              <PageHeader
                avatar={{ src: user.avatar }}
                className="site-page-header-responsive"
                title={`${app?.packId.name} ${app?.planId.name}`}
                subTitle={`Duración:${app.planId.duration} ${app.planId.durationMode}`}
                extra={[
                  <Button key="3" className="secondary" onClick={onClose}>
                    <span> Cerrar ventana </span>
                  </Button>,
                  <>
                    {isDesactivate && (
                      <Button key="1" size="large" onClick={handleDesactivate}>
                        Confirmación de la Cancelación
                      </Button>
                    )}
                    {!isDesactivate && (
                      <Button key="2" size="large" onClick={handleSubmit}>
                        Activar Aplicación
                      </Button>
                    )}
                  </>,
                ]}
              >
                <Descriptions size="small" column={3}>
                  <Descriptions.Item label="Costo (USD COP)">{priceFormat(app.planId.price)}</Descriptions.Item>
                  <Descriptions.Item label="Creación de la solicitud">{dateFormat4(app.creartedAt)}</Descriptions.Item>
                  <Descriptions.Item label="Referido por"> Website </Descriptions.Item>
                </Descriptions>
              </PageHeader>

              {isDesactivate && (
                <>
                  <Row gutter={15} style={{ paddingBottom: 10 }}>
                    <Col span={10}>
                      <Form.Item name="description" label="Motivo de la cancelación">
                        <Input value={app.description} />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              {!isDesactivate && (
                <>
                  <Divider orientation="left">Datos de la Subscripción</Divider>
                  <Row gutter={15} style={{ paddingBottom: 10 }}>
                    <Col span={6}>
                      <Form.Item name="packId2" tooltip="" label={t("pack")}>
                        <SelectPacks
                          reload={reload}
                          currentValue={packId}
                          defaultValue={packId}
                          returnData={handlePack}
                          typePack="SUBSCRIPTION"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="planId" tooltip="" label={t("plan")}>
                        {packId.length > 5 && (
                          <SelectPlans
                            handleSelected={setPlan}
                            reload={reload}
                            currentValue={planId}
                            defaultValue={planId}
                            returnData={handlePlan}
                            type="SUBSCRIPTION"
                            packId={packId}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item name="startDate" label={<>{icons.calendar} &nbsp; Fecha de inicio desde</>}>
                        <DatePicker format="YYYY/MM/DD" onChange={handleStartdate} />
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item name="closureDate" label={<>{icons.calendar} &nbsp; Hasta</>}>
                        <DatePicker className="color-red" format="YYYY/MM/DD" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider orientation="left">Acerca del Pago</Divider>
                  <Row gutter={16}>
                    <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item name="paymentMode" label={<>{icons.dolar}&nbsp; Método de pago</>}>
                        <Select className="select-form-modal">
                          <Select.Option value="none">Sin pago</Select.Option>
                          <Select.Option value="cash">Efectivo</Select.Option>
                          <Select.Option value="debitcard">Tarjeta de Débito</Select.Option>
                          <Select.Option value="creditcard">Tarjeta de Crédito</Select.Option>
                          <Select.Option value="transfer">Transferencía</Select.Option>
                          <Select.Option value="nequi">Nequi</Select.Option>
                          <Select.Option value="wompi">Wompit</Select.Option>
                          <Select.Option value="other">Otra</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item name="paymentReference" label="Número de referencia">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider orientation="left">Datos Personales</Divider>
                  <Row gutter={16}>
                    <Col span={6}>
                      <Form.Item
                        name="firstname"
                        label="Nombres"
                        rules={[{ required: true, message: "Please enter Nombre" }]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="lastname" label="Apellidos">
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="documentType" label="Tipo de Documento">
                        <Select placeholder="Tipo de Documento">
                          <Select.Option value="Pasaporte">Pasaporte</Select.Option>
                          <Select.Option value="Tarjeta de Identidad">Tarjeta de Identidad</Select.Option>
                          <Select.Option value="Cédula de Ciudadanía">Cédula de Ciudadanía</Select.Option>
                          <Select.Option value="Cédula de Extranjería">Cédula de Extranjería</Select.Option>
                          <Select.Option value="Permiso Especial (PEP)">Permiso Especial (PEP)</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="document" label="Número de Documento">
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="email"
                        label={<>{icons.phone}&nbsp; Correo Eletrónico</>}
                        rules={[{ required: true, message: "Please enter Email" }]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name="phone"
                        label="Teléfono"
                        rules={[{ required: true, message: "Por favor ingrese el  Teléfono" }]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="birthday" label="Nacimiento [YYYY/MM/DD]">
                        <DatePicker placeholder="YYYY/MM/DD" format="YYYY/MM/DD" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="profession" label="Ocupación">
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="genre" label="genre">
                        <Select placeholder="Género">
                          <Select.Option value="Femenino">Femenino</Select.Option>
                          <Select.Option value="Masculino">Masculino</Select.Option>
                          <Select.Option value="No Especifica">No Especifica</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider orientation="left">Dirección de habitación</Divider>
                  <Row gutter={24}>
                    <Col span={10}>
                      <Form.Item name="address" label="Dirección de Habitación">
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="city" label="Ciudad">
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider orientation="left">Datos adicionales</Divider>
                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item name="alergies" label="¿Presenta alguna alergia?">
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="bodylesson" label="¿Presenta alguna lesion en el cuerpo?">
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="instagram" label="Instagram">
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="facebook" label="Facebook">
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item name="medium" label="¿Cómo nos conociste?">
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                  </Row>{" "}
                </>
              )}
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
};
export default withRouter(ActivateApp);
