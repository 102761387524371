import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Switch,
  InputNumber,
  Checkbox,
  Select,
  Steps,
  Modal,
  Space,
  Result,
  DatePicker,
  Alert,
  TimePicker,
} from "antd";
import { savePack, getPack } from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";
import { constantsPack } from "../constants";
import { setPackRedux } from "../../../redux/pack";
import { durationFormat, packClasification } from "../../../constants/globals";

import "react-quill/dist/quill.snow.css"; // ES6

const moment = require("moment");

const { Step } = Steps;
const { TextArea } = Input;

const defaultValues = {
  name: "",
  duration: "1",
  durationMode: "DAYS",
  category: "",
  minLimit: 1,
  observation: "",
  active: true,
  departurePlace: "",
  featureImage: "",
  tag: "",
  price: "0",
  avatar: "",
  public: false,
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  type: constantsPack.TYPE,
  position: "0",
  multiple: false,
  umlimited: false,
  paymentLink: "https://",
  description: "",
  excerpt: "",
  itineraries: "",
  exclude: "",
  include: "",
  departureTime: "",
  recomendations: "",
  parentalControl: false,
  backgroundForm: "#000",
  cupon: false,
  webhookCancel: "",
  webhookClose: "",
  webhookInwait: "",
  webhookPayment: "",
  webhookOpen: "",
  emailWelcome: "",
  emailRegistration: "",
  single: false,
  isDocumentRequired: false,
  isVaccine: true,
  isStrictMode: false,
  defaultInstance: true,
  isTicketsNumber: false,
  maxLimit: 50,
  isRecurrent: false,
  isMultipleTimes: false,
  isTemporal: false,
};
const CheckboxGroup = Checkbox.Group;

const defaultCheckedList = ["Apple", "Orange"];

const ManagePackModal = ({ setPackId, packId, openModal, closeModal, refetch, t }) => {
  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [form] = Form.useForm();
  const [selectedPack, setSelectedPack] = useState(defaultValues);
  const [loaderForm, setLoaderForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [reload, setReload] = useState(0);
  const [alert, setAlert] = useState({ text: "", value: false });
  const [status, setStatus] = useState("progress");

  const handleReset = () => {
    setSelectedPack(defaultValues);
    setReload(Math.floor(Math.random() * 100000));
  };

  useEffect(() => {
    setAlert(false);
    if (openModal) {
      if (packId) {
        setMode("edit");
      } else {
        setMode("new");
      }
    } else {
      handleReset();
      setMode(null);
      setPackId(null);
    }
  }, [openModal]);

  useEffect(() => {
    if (mode === "new") {
      handleReset();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      setLoaderForm(true);
      getPack(packId, {
        onSuccess: (response) => {
          setPackRedux(response.result)(dispatch);
          setSelectedPack({
            ...defaultValues,
            ...response.result,
          });
          setLoaderForm(false);
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  }, [mode]);

  useEffect(() => {
    form.setFieldsValue(selectedPack);
  }, [form, selectedPack]);

  const [current, setCurrent] = useState(0);

  const handleCloseModal = () => {
    refetch();
    handleReset();
    closeModal();
  };

  const onFinish = (values) => {
    const data = {
      ...selectedPack,
      ...values,
      kind: "subscription",
    };

    savePack(
      {
        ...data,
        packId,
        rootId: sessionStoreRedux.currentSession_id,
      },
      {
        onSuccess: (response) => {
          setPackRedux(response.result)(dispatch);
          setCurrent(0);
          setSelectedPack(defaultValues);
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => handleCloseModal(),
      }
    );
  };

  const onValuesChange = (values) => {
    setSelectedPack({ ...selectedPack, ...values });
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  const steps = [
    {
      title: "Datos Generales",
      content: (
        <Row gutter={24}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item name="clasification" label="Clasificación">
              <Select className="select-form-modal">
                <Select.Option value={packClasification.SUBSCRIPTION.LANDINGPAGE}>
                  {packClasification.SUBSCRIPTION.LANDINGPAGE}
                </Select.Option>

                <Select.Option value={packClasification.SUBSCRIPTION.COWORK}>
                  {packClasification.SUBSCRIPTION.COWORK}
                </Select.Option>

                <Select.Option value={packClasification.SUBSCRIPTION.SPORT}>
                  {packClasification.SUBSCRIPTION.SPORT}
                </Select.Option>

                <Select.Option value={packClasification.SUBSCRIPTION.GYM}>
                  {packClasification.SUBSCRIPTION.GYM}
                </Select.Option>

                <Select.Option value={packClasification.SUBSCRIPTION.YOGA}>
                  {packClasification.SUBSCRIPTION.YOGA}
                </Select.Option>

                {selectedPack.kind === "subscription" && (
                  <Select.Option value={packClasification.SUBSCRIPTION.LANGUAGE}>
                    {packClasification.SUBSCRIPTION.LANGUAGE}
                  </Select.Option>
                )}

                <Select.Option value={packClasification.SUBSCRIPTION.MEDITATION}>
                  {packClasification.SUBSCRIPTION.MEDITATION}
                </Select.Option>

                <Select.Option value={packClasification.SUBSCRIPTION.FORM}>
                  {packClasification.SUBSCRIPTION.FORM}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <Form.Item name="name" tooltip="Nombre" label={t("name")}>
              <Input placeholder="" value={selectedPack?.name} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="excerpt" label={t("excerpt")}>
              <TextArea style={{ minHeight: 70 }} placeholder="" value={selectedPack?.excerpt} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item initialValue={checkedList} name="categorization" label="Categoría">
              <CheckboxGroup style={{ width: "100%" }} defaultValue={checkedList}>
                <Row gutter={15}>
                  <Col span={4}>
                    <Checkbox value="Naturaleza">Naturaleza</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Cultural">Cultural</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Extremo">Extremo</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Romantico">Romantico</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Walking">Walking</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Espiritual">Espiritual</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Fitness">Fitness</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Academico">Academico</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Artistico">Artistico</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Salud">Salud</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Belleza">Belleza</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Ciencia">Ciencia</Checkbox>
                  </Col>
                </Row>
              </CheckboxGroup>
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      title: "Confirmación",
      content: (
        <Result
          status="success"
          title=""
          subTitle=""
          extra={[
            <Button
              className="ant-btn secondary primary large"
              size="large"
              type="primary"
              key="console"
              onClick={() => {
                handleSubmit();
              }}
            >
              Guardar Evento
            </Button>,
          ]}
        />
      ),
    },
  ];

  const next = () => {
    setAlert(false);
    setStatus("progress");

    if (current === 0) {
      if (String(selectedPack.name).length < 3) {
        setAlert({
          text: "Por favor valide el nombre del evento",
          value: true,
        });
        setStatus("error");
        return false;
      }

      if (String(selectedPack.excerpt).length < 10) {
        setAlert({
          text: "Por favor ingrese una descripcion valida del Evento",
          value: true,
        });
        setStatus("error");
        return false;
      }
    }

    if (current === 1) {
      console.log("");
    }

    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <Modal
      closable
      title="Registrar una Subscripción"
      visible={openModal}
      maskClosable="true"
      footer={null}
      onCancel={handleCloseModal}
      width="800px"
    >
      <Form
        form={form}
        name="manage-category-form-modal"
        initialValues={defaultValues}
        className="manage-modal"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        onValuesChange={onValuesChange}
      >
        <>
          <Steps current={current} status={status}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>

          <div className="steps-content">{steps[current].content}</div>
          <div className="steps-action">
            <Space>
              {current > 0 && (
                <Button className="ant-btn secondary error" style={{ margin: "0 8px" }} onClick={() => prev()}>
                  Anterior
                </Button>
              )}

              {current < steps.length - 1 && (
                <Button className="ant-btn secondary primary" type="primary" onClick={() => next()}>
                  Siguiente
                </Button>
              )}
            </Space>
          </div>
          {alert.value && <Alert message={alert.text} type="error" style={{ marginTop: 10 }} />}
        </>
      </Form>
    </Modal>
  );
};

ManagePackModal.defaultProps = {
  setPackId: () => {},
};

export default ManagePackModal;
