import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Menu, Avatar } from "antd";

import { ReloadOutlined, ExportOutlined } from "@ant-design/icons";

import SubscriberReport from "../features/subscribersReport";
import { validateSessionRoot } from "../features/subscribersUsers/requests";
import logo from "../assets/robot.png";

import { setSession } from "../redux/session";

const { Header, Content, Footer } = Layout;

const Analist = () => {
  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);
  const instanceStoreRedux = useSelector((store) => store.instance);
  const [reload, setReload] = useState(0);

  const handleLogout = () => {
    document.location = `/login/${instanceStoreRedux.currentInstance.alias}`;
  };

  const handleAdminView = () => {
    document.location = ` /home/29`;
  };

  const handleReload = () => {
    setReload(Math.floor(Math.random() * 100000));
  };

  useEffect(() => {
    validateSessionRoot({
      onSuccess: (data) => {
        if (data.ok) {
          setSession(data.result)(dispatch);
        }
      },
    });
  }, []);
  return (
    <Layout className="layout analist-layout">
      <Header>
        <div className="logo" style={{ float: "left" }}>
          <img src={logo} width="45px" style={{ marginRight: 10, position: "relative", top: -5 }} alt="logo" />
        </div>
        <h1 style={{ float: "left", color: "#fff", fontSize: 32 }}>{instanceStoreRedux.currentInstance.alias}</h1>

        <Menu theme="dark" mode="horizontal" style={{ float: "right", width: "40%" }}>
          {sessionStoreRedux.currentSession.role === "SUPERADMIN" && (
            <Menu.Item icon={<ExportOutlined />} key="3" onClick={handleAdminView}>
              Vista Admin
            </Menu.Item>
          )}

          <Menu.Item icon={<ReloadOutlined spin />} key="3" onClick={handleReload}>
            Refrescar Apps
          </Menu.Item>

          <Menu.Item icon={<ExportOutlined />} key="2" onClick={handleLogout}>
            Cerrar Sesión
          </Menu.Item>

          <Menu.Item key="1">
            <Avatar src={sessionStoreRedux.currentSession.avatar} size="large" />
            {"  "}
            {sessionStoreRedux.currentSession.login}
          </Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: "0 50px", background: "#EFF4FB" }}>
        <SubscriberReport mainReload={reload} />;
      </Content>
    </Layout>
  );
};

export default withRouter(Analist);
