import ReactExport from "react-export-excel";
import { Button, Tooltip } from "antd";
import excelicon from "../../../assets/excel-icon-admin.png";
import { excelReportApp } from "../requests";
import {
  formaterStatusApp,
  formaterStatusTrans,
  formaterFreePlan,
  formaterModeTrans,
} from "../../../constants/globals";

const Swal = require("sweetalert2");

const ReactExcel = ({ apps }) => {
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  const registrar = () => {
    excelReportApp(
      { name: "Reporte de las Aplicaciones" },
      {
        onSuccess: () => {
          Swal.fire({
            icon: "success",
            text: "Se ha descargado el reporte exitosamente",
          });
        },
        onFinally: () => {},
        onError: () => {},
      }
    );
  };

  return (
    <>
      <ExcelFile
        filename="Aplicaciones cluuf"
        element={
          <Tooltip title="Exportar la información COMPLETA de la seleccion actual a un archivo en excel">
            <Button className="secondary" onClick={() => registrar()}>
              <img width="20" alt="excel" src={excelicon} />
            </Button>
          </Tooltip>
        }
      >
        <ExcelSheet data={apps} name="Simple">
          <ExcelColumn label="Servicio" value={(col) => col.packId.name} />
          <ExcelColumn label="Actividad" value={(col) => col.planId.name} />
          <ExcelColumn label="Nombres" value={(col) => col.userId.firstname} />
          <ExcelColumn label="Apellidos" value={(col) => col.userId?.lastname} />
          <ExcelColumn label="Email" value={(col) => col.userId.email} />
          <ExcelColumn label="Tipo de Documento" value={(col) => col.userId?.documentType} />
          <ExcelColumn label="Documento" value={(col) => col.userId?.document} />
          <ExcelColumn label="Modalidad de pago" value={(col) => formaterModeTrans(col.transId?.mode) || "---"} />
        </ExcelSheet>
        <ExcelSheet data={apps} name="Completa">
          <ExcelColumn label="Codigo" value="code" />
          <ExcelColumn label="Estado" value={(col) => formaterStatusApp(col)} />
          <ExcelColumn label="Servicio" value={(col) => col.packId.name} />
          <ExcelColumn label="Actividad" value={(col) => col.planId.name} />
          <ExcelColumn label="Nombres" value={(col) => col.userId.firstname} />
          <ExcelColumn label="Apellidos" value={(col) => col.userId?.lastname} />
          <ExcelColumn label="Email" value={(col) => col.userId.email} />
          <ExcelColumn label="Tipo de Documento" value={(col) => col.userId?.documentType} />
          <ExcelColumn label="Documento" value={(col) => col.userId?.document} />
          <ExcelColumn label="Inicio de la actividad" value="startDate" />
          <ExcelColumn label="Finalización de la actividad" value="closureDate" />
          <ExcelColumn label="Gratis?" value={(col) => formaterFreePlan(col.planId.isFree)} />
          <ExcelColumn label="Costo" value={(col) => String(col.transId?.amount || "0").trim()} />
          <ExcelColumn label="Modalidad de pago" value={(col) => formaterModeTrans(col.transId?.mode) || "---"} />
          <ExcelColumn label="Referencía del pago" value={(col) => col.transId?.reference} />
          <ExcelColumn label="Estado del pago" value={(col) => formaterStatusTrans(col.transId?.isPending)} />
          <ExcelColumn label="Codigo pago" value={(col) => col.transId?.code} />
          <ExcelColumn
            label="Referido"
            value={(col) => `${col.refererRootId?.firstname || ""} ${col.refererRootId?.lastname || ""}`}
          />
          <ExcelColumn label="Asistencía" value={(col) => (col.insitu ? "SI" : "NO")} />
          <ExcelColumn label="Tickets" value={(col) => col.quantity} />
          <ExcelColumn label="Fecha de pago" value={(col) => col.transId?.fecha} />
          <ExcelColumn label="Hora de pago" value={(col) => col.transId?.hora} />
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};

export default ReactExcel;
