import { confirmAlert } from "react-confirm-alert";

import {
  archivedService,
  activeService,
  exportExcelServices,
  getService,
  updateService,
  searchServices,
} from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";

const Swal = require("sweetalert2");

export const handleActiveConfirm = ({ service, t, refetch }) => {
  activeService(
    {
      service,
    },
    {
      onSuccess: () => {
        refetch();
        showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleActive = ({ service, t, refetch }) => {
  confirmAlert({
    title: service.active ? `${t("desactivate")} ${t("service")}` : `${t("activate")} ${t("service")}`,
    message: t("AreYouSureWantToContinue"),
    buttons: [
      {
        label: t("yes"),
        onClick: () => handleActiveConfirm({ service, t, refetch }),
      },
      {
        label: t("no"),
        onClick: () => {},
      },
    ],
  });
};

export const handleExportExcelServices = (services) => {
  exportExcelServices(services);
};

export const handleArchivedConfirm = ({ service, t, refetch }) => {
  archivedService(
    {
      service,
    },
    {
      onSuccess: () => {
        refetch();
        showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleArchived = ({ service, t, refetch }) => {
  confirmAlert({
    title: service.active ? `${t("desactivate")} ${t("service")}` : `${t("activate")} ${t("service")}`,
    message: t("AreYouSureWantToContinue"),
    buttons: [
      {
        label: t("yes"),
        onClick: () => handleArchivedConfirm({ service, t, refetch }),
      },
      {
        label: t("no"),
        onClick: () => {},
      },
    ],
  });
};

export const handleDetails = ({ service }, { onSuccess = {} }) => {
  getService(service._id, {
    onSuccess: (response) => {
      onSuccess(response.result);
    },
    onError: () => showGlobalNotification("error", "Error", "error"),
  });
};

export const handleUpdateService = ({ serviceId, params, t }, { onSuccess = {}, onFinally = {} }) => {
  updateService(
    {
      ...params,
      serviceId,
    },
    {
      onSuccess: (result) => {
        onSuccess(result);
      },
      onError: () => showGlobalNotification("error", "errorTitle", t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleSearchServices = ({ text, t }, { onSuccess = {}, onFinally = {} }) => {
  searchServices(text, {
    onSuccess: (result) => {
      onSuccess(result);
    },
    onError: () => showGlobalNotification("error", "errorTitle", "errorDescription"),
    onFinally: () => {},
  });
};
