import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";

import { withRouter } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import ActionBar from "../../shared/components/ActionBar";
import ManageUserModal from "../../subscribersUsers/components/ManageUserModal";

import SearchAppsForm from "../components/SearchAppsForm";
import ActionsAppsProduct from "../components/ActionsAppsProduct";
import AppsDetails from "../components/AppsDetails";
import ExcelExport from "../components/AppsExcelExport";

import AppHeader from "../components/AppHeader";
import AppsHeader from "../components/AppsHeader";
import InfoPack from "../components/InfoPack";
import AppMoreDetails from "../components/AppMoreDetails";
import SelectTypeApp from "../components/SelectTypeApp";
import TabMenu from "../components/Tabs";

// HELPERS
import showGlobalNotification from "../../../helpers/showGlobalNotification";

// REDUX
import { setAppRedux } from "../../../redux/app";
import { setPlanRedux } from "../../../redux/plan";
import { setTransRedux } from "../../../redux/trans";

// REQUEST
import { getApp, archivedApp, activeApp } from "../requests";

import { getPlan } from "../../subscribersPlans/requests";
import { searchUsers } from "../../subscribersUsers/requests";

// HOOKS
import useApps from "../hooks/useApps";
import useModal from "../../shared/hooks/useModal";

// CONTAINERS
import TransContainer from "../../subscribersTrans/containers/Trans";
import EventsContainer from "../../events/containers/Event";

// CONSTANTS
import { globals, numbers, packs, icons } from "../../../constants/globals";

import { handleCheckActionsArchived } from "../functions";
import AppsRentalTable from "../components/AppsRentalTable";

const Apps = ({
  isEmbed = false,
  userId = null,
  reload,
  planId = null,
  AppId = null,
  history,
  type = packs.SUBSCRIPTION,
  isMinimal = false,
  handleUploadPlanFile = null,
  handleClosePlanByGuide = null,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userStoreRedux = useSelector((store) => store.user);

  const { apps, filters, setFilters, pagination, setPagination, loading, refetch } = useApps(userId, planId, type, {
    kind: "rental",
  });

  const [initialValues] = useState({
    firstname: "",
    lastname: "",
    DocumentType: "",
    document: "",
    email: "",
    phone: "",
    medium: "",
  });

  const commonProps = { filters, setFilters };
  const [page, setPage] = useState(globals.PAGE_MAIN);
  const [subpage, setSubpage] = useState(numbers.ONE);
  const [selectedApp, setSelectedApp] = useState({});
  const [goback, setGoback] = useState(false);

  const [appId, setAppId] = useState(null);

  const [isOpenManageUsersModal, openManageUsersModal, closeManageUsersModal] = useModal();
  const [selectedUser, setSelectedUser] = useState({});
  const [userId_, setUserId_] = useState(userId);
  const [details, setDetails] = useState(false);

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setUserId_(user._id);
    openManageUsersModal();
  };

  const handleGoto = ({ number = 6, app }) => {
    history.push(`/home/${number}/${app._id}`);
  };

  const refreshPlan = (planIdTemp, { onSuccess = {}, onError = {} }) => {
    setTimeout(() => {
      getPlan(planIdTemp, {
        onSuccess: (response) => {
          setPlanRedux(response.result)(dispatch);
          if (response.result) onSuccess(response.result);
          else onError();
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }, 4000);
  };

  const handleDetails = (app) => {
    setSelectedApp({});
    getApp(app._id, {
      onSuccess: (response) => {
        setAppId(app._id);
        setSelectedApp(response.result);
        setAppRedux(response.result)(dispatch);
        setPage(globals.PAGE_ONE);
        setSubpage(numbers.ONE);
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
    //
  };

  const modeDetails = (app) => {
    if (isEmbed) {
      handleGoto({ number: 29, app });
    } else {
      handleDetails(app);
    }
  };

  const handleArchivedConfirm = (app) => {
    archivedApp(
      {
        app,
      },
      {
        onSuccess: () => {
          refreshPlan(app.planId._id, {
            onSuccess: () => {
              refetch();
            },
            onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
          });
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleResume = (app) => {
    setSelectedApp(app);
    setAppRedux(app)(dispatch);
    setDetails(true);
  };

  const handleArchived = (app) => {
    setAppId(app._id);
    confirmAlert({
      title: `${t("delete")} ${app.name}`,
      message: `${t("AreYouWantDelete")} ${app.name}?`,
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleArchivedConfirm(app),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleActiveConfirm = (app) => {
    activeApp(
      {
        app,
      },
      {
        onSuccess: (result) => {
          refetch();

          refreshPlan(app.planId._id, {
            onSuccess: () => {},
            onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
          });

          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleActive = (app) => {
    setAppId(app._id);
    confirmAlert({
      title: app.active ? `${t("desactivate")} ${t("application")}` : `${t("activate")} ${t("application")}`,
      message: t("AreYouSureWantToContinue"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleActiveConfirm(app),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const [initialExecute, setInitialExecute] = useState(false);
  useEffect(() => setTimeout(() => setInitialExecute(true), 2000), []);

  useEffect(() => {
    if (page !== globals.PAGE_MAIN) setAppRedux(selectedApp)(dispatch);
  }, [selectedApp]);

  useEffect(() => {
    // debounce(() => refetch(), 500);
  }, [reload]);

  useEffect(() => {
    debounce(() => refetch(), 500);
  }, [filters]);

  useEffect(() => {
    if (AppId) {
      handleDetails({ _id: AppId });
      setGoback(true);
    }
  }, []);

  useEffect(() => {
    if (page === globals.PAGE_MAIN) {
      setAppRedux({ ok: false })(dispatch);
      setTransRedux({ ok: false })(dispatch);
    }
  }, [page]);

  useEffect(() => {
    refetch();
  }, [filters]);

  useEffect(() => {
    if (initialExecute && page === globals.PAGE_ONE) handleDetails(selectedApp);
  }, [userStoreRedux.reload]);

  return (
    <>
      {page === globals.PAGE_MAIN && (
        <div>
          {!isEmbed && (
            <AppsHeader
              t={t}
              title={
                <h4 className="bold special-font big-font">
                  {icons.icon_rental} {t("rental_sellProducts")}
                </h4>
              }
              primaryAction={<></>}
            />
          )}
          <div className={isEmbed ? "" : "container-subpages"}>
            <ActionBar
              isEmbed={isEmbed}
              isMinimal={isMinimal}
              t={t}
              {...commonProps}
              SelectTypeApp={SelectTypeApp}
              refetch={refetch}
              setFilters={setFilters}
              options={{ actions: ActionsAppsProduct }}
              handleExportExcel
              ExcelButton={<>{apps.length > 0 && <ExcelExport apps={apps} />}</>}
            >
              <SearchAppsForm {...commonProps} refetch={refetch} t={t} searchUsers={searchUsers} planId={planId} />
            </ActionBar>

            <AppsRentalTable
              {...commonProps}
              apps={apps}
              pagination={pagination}
              setPagination={setPagination}
              loading={loading}
              refetch={refetch}
              setPage={setPage}
              setSelectedApp={setSelectedApp}
              selectedApp={selectedApp}
              handleDetails={handleDetails}
              details={modeDetails}
              handleArchived={handleArchived}
              handleActive={handleActive}
              handleGoto={handleGoto}
              isEmbed={isEmbed}
              modeDetails={modeDetails}
              refreshPlan={refreshPlan}
              handleResume={handleResume}
              handleUploadPlanFile={handleUploadPlanFile}
              handleClosePlanByGuide={handleClosePlanByGuide}
              isMinimal={isMinimal}
              handleCheckActionsArchived={handleCheckActionsArchived}
              t={t}
            />

            {details && <AppsDetails t={t} open={details} setOpen={setDetails} />}
          </div>
        </div>
      )}
      {page === globals.PAGE_ONE && (
        <>
          <AppHeader
            setPage={setPage}
            selectedApp={selectedApp}
            handleDetails={handleDetails}
            setSubpage={setSubpage}
            handleArchived={handleArchived}
            handleEditUser={handleEditUser}
            isGoback={goback}
            handleGoto={handleGoto}
            t={t}
          />

          <div className="container-subpages">
            <TabMenu setSubpage={setSubpage} t={t} numbers={numbers} />

            {subpage === numbers.ONE && appId && (
              <div className="subcontainer-wrapper">
                <TransContainer isEmbed="true" appId={appId} />
              </div>
            )}
            {subpage === numbers.TWO && appId && (
              <div className="subcontainer-wrapper">
                <EventsContainer isEmbed="true" appId={appId} />
              </div>
            )}
            {subpage === numbers.THREE && selectedApp._id && (
              <div className="subcontainer-wrapper">
                <InfoPack selectedApp={selectedApp} />
              </div>
            )}
            {subpage === numbers.FOUR && selectedApp._id && (
              <div className="subcontainer-wrapper">
                <AppMoreDetails selectedApp={selectedApp} t={t} />
              </div>
            )}
          </div>
        </>
      )}

      <ManageUserModal
        refetch={refetch}
        userId={userId_}
        setUserId={setUserId_}
        closeModal={closeManageUsersModal}
        openModal={isOpenManageUsersModal}
        initialValues={initialValues}
        selectedUser={selectedUser}
        setSelectedUserMain={setSelectedUser}
        page={page}
        t={t}
      />
    </>
  );
};

export default withRouter(Apps);
