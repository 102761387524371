import { Descriptions, Tooltip, Spin, Divider } from "antd";

import { useSelector } from "react-redux";

import { globals, dateFormat4, priceFormat, icons, urlLandingPage } from "../../../constants/globals";

const PlanMoreDetails = ({ selectedPlan, t, planHeader }) => {
  const sessionStoreRedux = useSelector((store) => store.session);
  const instanceStoreRedux = useSelector((store) => store.instance);

  const renderContent = (column = 3) => (
    <div className=" margin-bottom-10">
      {planHeader}

      <Divider orientation="left">About Plan</Divider>

      <Descriptions size="small" column={column}>
        {selectedPlan?.isTemporal && (
          <Descriptions.Item label={t("startdate")}>{selectedPlan?.startdate}</Descriptions.Item>
        )}
        {selectedPlan?.isTemporal && (
          <Descriptions.Item label={t("departureTime")}>{selectedPlan?.departureTime}</Descriptions.Item>
        )}
        {selectedPlan?.isTemporal && (
          <Descriptions.Item label={t("Limits")}>
            Min: {selectedPlan?.minLimit} | Max: {selectedPlan?.maxLimit}
          </Descriptions.Item>
        )}

        <Descriptions.Item label={t("pack")}>{selectedPlan?.pack?.name || ""}</Descriptions.Item>
        <Descriptions.Item label={t("price")}>
          {!selectedPlan?.isFree ? priceFormat(selectedPlan?.price) : t("free")}
        </Descriptions.Item>
        <Descriptions.Item label={t("duration")}>
          {selectedPlan?.duration} {selectedPlan?.durationMode}{" "}
        </Descriptions.Item>

        <Descriptions.Item label={t("status")}>{selectedPlan?.status}</Descriptions.Item>

        <Descriptions.Item label={t("goToLandingPage")}>
          <a
            href={urlLandingPage({
              alias: instanceStoreRedux?.currentInstance.alias,
              packId: selectedPlan?.pack?._id,
              planId: selectedPlan?._id,
              rootEmail: sessionStoreRedux?.currentSession.email,
              campaign: "Local",
              refererAppId: null,
              refererUserId: null,
            })}
            target="blank"
          >
            {icons.link} {t("goToLandingPage")}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label={t("lastUpdatedAt")}>{dateFormat4(selectedPlan?.lastUpdatedAt)}</Descriptions.Item>
      </Descriptions>

      <Divider orientation="left">About Apps</Divider>

      <Descriptions size="small" column={column}>
        <Descriptions.Item label={t("plan_availability")}>{selectedPlan?.availability || "-"}</Descriptions.Item>
        <Descriptions.Item label={t("plan_isProgress")}>{selectedPlan?.progress || "-"}</Descriptions.Item>
        <Descriptions.Item label={t("plan_isPending")}>{selectedPlan?.pending || "-"}</Descriptions.Item>
        <Descriptions.Item label={t("plan_isStarting")}>{selectedPlan?.starting || "-"}</Descriptions.Item>
        <Descriptions.Item label={t("plan_isEnding")}>{selectedPlan?.ending || "-"}</Descriptions.Item>
        <Descriptions.Item label={t("plan_isClosed")}>{selectedPlan?.closed || "-"}</Descriptions.Item>
      </Descriptions>

      <Divider orientation="left">Configuracion del Plan</Divider>

      <Descriptions size="small" column={column}>
        <Descriptions.Item label={t("isPortalWeb")}>
          {selectedPlan?.isPortalWeb ? icons.check_enabled : icons.close}
        </Descriptions.Item>
        <Descriptions.Item label={t("isCarnet")}>
          {selectedPlan?.isCarnet ? icons.check_enabled : icons.close}
        </Descriptions.Item>
        <Descriptions.Item label={t("isFree")}>
          {selectedPlan?.isFree ? icons.check_enabled : icons.close}
        </Descriptions.Item>
        <Descriptions.Item label={t("isPublic")}>
          {selectedPlan?.isPublic ? icons.check_enabled : icons.close}
        </Descriptions.Item>

        <Descriptions.Item label={t("isTemporal")}>
          {selectedPlan?.isTemporal ? icons.check_enabled : icons.close}
        </Descriptions.Item>

        <Descriptions.Item label={t("unlimited")}>
          {selectedPlan?.unlimited ? icons.check_enabled : icons.close}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );

  const Content = ({ children }) => (
    <div className="content">
      <div className="main">{children}</div>
    </div>
  );

  return <>{selectedPlan ? <Content>{renderContent()}</Content> : <Spin />}</>;
};

export default PlanMoreDetails;
