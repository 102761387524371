import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from "react-quill";

import { Form, Row, Col, Input, Spin, Switch, InputNumber, Checkbox, Tabs } from "antd";
import { savePack, getPack } from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";
import BaseModal from "../../shared/components/Modal/BaseModal";
import { constantsPack } from "../constants";
import { setPackRedux } from "../../../redux/pack";

import "react-quill/dist/quill.snow.css"; // ES6

const moment = require("moment");

const { TabPane } = Tabs;
const { TextArea } = Input;

const defaultValues = {
  name: "",
  duration: "",
  category: "",
  minLimit: 1,
  observation: "",
  active: true,
  departurePlace: "",
  featureImage: "",
  tag: "",
  avatar: "",
  public: false,
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  type: constantsPack.TYPE,
  position: "0",
  multiple: false,
  umlimited: false,
  paymentLink: "https://",
  precode: "",
  airtableSync: false,
  color: "",
};

const ManagePackModal = ({ currentInstance, setPackId, packId, openModal, closeModal, refetch, t, masterInstance }) => {
  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);
  const [form] = Form.useForm();
  const [selectedPack, setSelectedPack] = useState(defaultValues);
  const [loaderForm, setLoaderForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [reload, setReload] = useState(0);

  const handleReset = () => {
    setSelectedPack(defaultValues);
    setReload(Math.floor(Math.random() * 100000));
  };

  useEffect(() => {
    if (openModal) {
      if (packId) {
        setMode("edit");
      } else {
        setMode("new");
      }
    } else {
      handleReset();
      setMode(null);
      setPackId(null);
    }
  }, [openModal]);

  useEffect(() => {
    if (mode === "new") {
      handleReset();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      setLoaderForm(true);
      getPack(packId, {
        onSuccess: (response) => {
          setPackRedux(response.result)(dispatch);
          setSelectedPack({
            ...response.result,
            startdate: moment(response.result.startdate, "YYYY/MM/DD") || moment("2020/12/12", "YYYY/MM/DD"),
            closuredate: moment(response.result.closuredate, "YYYY/MM/DD") || moment("2020/12/12", "YYYY/MM/DD"),
          });
          setLoaderForm(false);
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  }, [mode]);

  useEffect(() => {
    form.setFieldsValue(selectedPack);
  }, [form, selectedPack]);

  const handleCloseModal = () => {
    refetch();
    closeModal();
  };

  const onFinish = (values) => {
    const data = { ...selectedPack, ...values };
    savePack(
      {
        ...data,
        packId,
        mode: "content",
        rootId: sessionStoreRedux.currentSession_id,
      },
      {
        onSuccess: (response) => {
          setPackRedux(response.result)(dispatch);

          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => handleCloseModal(),
      }
    );
  };

  const condicionalType = (value) => {
    if (value?.type === constantsPack.CONTENT_TYPE.FORM) {
      setSelectedPack({ ...selectedPack, multiple: true });
    } else {
      setSelectedPack({ ...selectedPack, multiple: false });
    }
  };

  const convertCSVToArray = (value) => {
    if (value?.include) {
      setSelectedPack({ ...selectedPack, include: value.include.split(",") });
    }
    if (value?.exclude) {
      setSelectedPack({ ...selectedPack, exclude: value.exclude.split(",") });
    }
    if (value?.itineraries) {
      setSelectedPack({ ...selectedPack, itineraries: value.itineraries.split(",") });
    }
    if (value?.departureTime) {
      setSelectedPack({ ...selectedPack, departureTime: value.departureTime.split(",") });
    }

    if (value?.recomendations) {
      setSelectedPack({ ...selectedPack, recomendations: value.recomendations.split(",") });
    }
  };

  const onValuesChange = (values) => {
    if (values?.include || values?.exclude || values?.itineraries || values?.departureTime || values?.recomendations) {
      convertCSVToArray(values);
    } else {
      if (values?.type) {
        condicionalType(values);
      }
      setSelectedPack({ ...selectedPack, ...values });
    }
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  const handleActive = (checked) => {
    setSelectedPack({ ...selectedPack, active: checked });
  };

  const handlePublic = (checked) => {
    setSelectedPack({ ...selectedPack, public: checked });
  };

  const handleAirtableSync = (checked) => {
    setSelectedPack({ ...selectedPack, airtableSync: checked });
  };

  return (
    <BaseModal
      isOpenModal={openModal}
      closeModal={handleCloseModal}
      onCancel={handleCloseModal}
      onConfirm={() => handleSubmit()}
      title={packId ? `${t("edit")} ${t("tour")}` : `${t("new")} ${t("page")}`}
      width={1000}
      confirmText={t("save")}
      isCloseModal
      top={10}
    >
      <Form
        form={form}
        name="manage-category-form-modal"
        initialValues={defaultValues}
        className="manage-modal"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        onValuesChange={onValuesChange}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={t("general")} key="1">
            <div className="layout-form-modal entity-350 entity-small">
              {loaderForm ? (
                <>
                  <Spin tip={t("loading")} />
                </>
              ) : (
                <>
                  <Row gutter={24} className="box-info">
                    <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item name="name" tooltip="Nombre" label={t("name")}>
                        <Input placeholder="" value={selectedPack?.name} />
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={5} lg={5} xl={5}>
                      <Form.Item name="category" label={t("category")}>
                        <Input value={selectedPack?.category} />
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={9} lg={9} xl={9}>
                      <Form.Item name="precode" label="Codigo">
                        <Input value={selectedPack?.precode} />
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item name="color" label={t("color")}>
                        <Input type="color" value={selectedPack?.color} />
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item name="position" label={t("position")}>
                        <InputNumber min="0" max="20" value={selectedPack?.position} />
                      </Form.Item>
                    </Col>

                    {masterInstance === currentInstance && (
                      <Col span={24}>
                        <Form.Item name="instanceId" label={t("instanceId")}>
                          <Input />
                        </Form.Item>
                      </Col>
                    )}

                    <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item name="excerpt" label={t("excerpt")}>
                        <TextArea style={{ minHeight: 40 }} placeholder="" value={selectedPack?.excerpt} />
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item
                        name="active"
                        labelAlign="right"
                        label={t("active")}
                        tooltip="Activa el contenido para que se visualice en el website"
                      >
                        <Switch checked={!!selectedPack?.active} onChange={handleActive} />
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item
                        name="public"
                        labelAlign="right"
                        label={t("public")}
                        tooltip="El contenido sera visualizado en el website"
                      >
                        <Switch checked={!!selectedPack?.public} onChange={handlePublic} />
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item
                        name="airtableSync"
                        labelAlign="right"
                        label="Sincronizacion con Airtable"
                        tooltip="El contenido sera visualizado en el website"
                      >
                        <Switch checked={!!selectedPack?.airtableSync} onChange={handleAirtableSync} />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </TabPane>
        </Tabs>
      </Form>
    </BaseModal>
  );
};

ManagePackModal.defaultProps = {
  setPackId: () => {},
};

export default ManagePackModal;
