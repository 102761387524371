import { getPlans } from "../../subscribersPlans/requests";
import { getApps } from "../../subscribersApps/requests";
import { getAppMetrics, getEventMetrics, getCurrentAppActives, getUsersMetrics2 } from "../request";

export const handleGetPlans = ({ onSuccess = {}, onError = {} }) => {
  getPlans("SUBSCRIPTION", {
    onSuccess: (result) => {
      onSuccess(result);
    },
    onError: (err) => {
      onError(err);
    },
  });
};

export const handleGetUsers2 = ({ onSuccess = {}, onError = {} }) => {
  getUsersMetrics2("SUBSCRIPTION", {
    onSuccess: (result) => {
      onSuccess(result);
    },
    onError: (err) => {
      console.log(err);
      // onError(err);
    },
  });
};

export const handleGetApps = ({ onSuccess = {}, onError = {} }) => {
  getApps("SUBSCRIPTION", {
    onSuccess: (result) => {
      onSuccess(result);
    },
    onError: (err) => {
      onError(err);
    },
  });
};

export const handleGetAppMetrics = (filter, { onSuccess = {} }) => {
  getAppMetrics(
    { ...filter },
    {
      onSuccess: (result) => {
        onSuccess(result);
      },
      onError: () => {},
    }
  );
};

export const handleCurrentAppActives = (filter, { onSuccess = {} }) => {
  getCurrentAppActives(
    { ...filter },
    {
      onSuccess: (result) => {
        onSuccess(result);
      },
      onError: () => {},
    }
  );
};

export const handleGetEventMetrics = (filter, { onSuccess = {} }) => {
  getEventMetrics(
    { ...filter },
    {
      onSuccess: (result) => {
        onSuccess(result);
      },
      onError: () => {},
    }
  );
};

export const handleGetUsersInfo = (filter, { onSuccess = {} }) => {
  getEventMetrics(
    { ...filter },
    {
      onSuccess: (result) => {
        onSuccess(result);
      },
      onError: () => {},
    }
  );
};
