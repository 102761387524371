import { useEffect, useState } from "react";
import { Select, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { CheckCircleTwoTone } from "@ant-design/icons";

import { getPlansByPack } from "../requests";
import { priceFormat } from "../../../constants/globals";

const { Option } = Select;

const moment = require("moment");

const SelectPlans = ({
  defaultValue,
  returnData,
  currentValue,
  reload,
  type = "CONTENT",
  packId = "",
  handleSelected = {},
  mode = "complete",
  title = "Seleccione un plan",
  isPast = "true",
  disabled = false,
}) => {
  const [data, setData] = useState(null);
  const [value, setValue] = useState(currentValue);
  const { currentPlan: PlanRedux } = useSelector((store) => store.plan);

  const filterPlans = (plans_) => {
    const data1 = [];
    plans_.forEach((element) => {
      if (moment().diff(moment(element.closuredate, "YYYY-MM-DD"), "days") <= 0) data1.push(element);
    });

    return data1;
  };

  useEffect(() => {
    console.log("aqui");
    getPlansByPack(
      { type, packId },
      {
        onSuccess: (res) => {
          // let data_ = res.result;
          // if (isPast === "false") data_ = filterPlans(res.result);
          //  setData(data_);
          console.log(res.result);
          setData(res.result);
        },
        onError: () => {},
      }
    );
  }, [packId]);

  useEffect(() => {
    setValue(null);
  }, [reload]);

  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  useEffect(() => {
    if (PlanRedux?._id) {
      setValue(PlanRedux._id);
    }
  }, [PlanRedux]);

  const handleChange = (value__) => {
    if (value__ === "null" || value__ === null) {
      setValue(null);
      returnData(null);
      handleSelected({});
    } else {
      data.forEach((item) => {
        if (item._id === value__) {
          setValue(value__);
          returnData(value__);
          handleSelected(item);
        }
      });
    }
  };

  useEffect(() => {
    if (currentValue === null) setValue("null");
  }, []);

  return (
    <Select
      showSearch
      onChange={handleChange}
      value={value}
      defaultValue={defaultValue}
      style={{ width: "100%" }}
      placeholder="Search to Select"
      optionFilterProp="children"
      disabled={disabled}
    >
      <Option key="0" value="null">
        Seleccione
      </Option>
      {data &&
        data?.map((item) => (
          <Option key={item._id} value={item._id}>
            {mode === "complete" && (
              <Tooltip title={item.observation}>
                {" "}
                {item.name}{" "}
                {item.duration && (
                  <small>
                    - {item.duration || ""} {item.durationMode || ""}
                  </small>
                )}
                {item.price && (
                  <small>
                    {"  "}- {priceFormat(item.price) || ""}
                  </small>
                )}
              </Tooltip>
            )}
            {mode === "single" && (
              <>
                {item?.packId?.name} * {item?.name}
              </>
            )}
          </Option>
        ))}
    </Select>
  );
};

export default SelectPlans;
