const DEFAULT_MENU = "defaultMenu";

export const getDefaultMenu = (defaultValue = "3") => {
  if (localStorage.getItem(DEFAULT_MENU)) {
    return localStorage.getItem(DEFAULT_MENU);
  }
  return parseInt(defaultValue);
};

export const setDefaultMenu = (number) => localStorage.setItem(DEFAULT_MENU, number);
