import { confirmAlert } from "react-confirm-alert";

import {
  getApp,
  duplicateApp,
  recalculateApp,
  evaluateAppsByInstance,
  getAppCountPending,
  archivedApp,
  closedApp,
  closeWebApp,
} from "../requests";

import showGlobalNotification from "../../../helpers/showGlobalNotification";

import { getPlan } from "../../subscribersPlans/requests";

export const handleConfirmDuplicateApp = (params, { onSuccess = {}, onError = {} }) => {
  duplicateApp(params, {
    onSuccess: (result) => onSuccess(result),
    onError: (err) => onError(err),
    onFinally: () => {},
  });
};

export const handleGetApp = (appId, { onSuccess = {}, onError = {} }) => {
  getApp(appId, {
    onSuccess: (result) => onSuccess(result),
    onError: (err) => onError(err),
    onFinally: () => {},
  });
};

export const handleRefreshPlan = ({ planIdTemp, t }, { onSuccess = {}, onError = {} }) => {
  setTimeout(() => {
    getPlan(planIdTemp, {
      onSuccess: (response) => {
        // setPlanRedux(response.result)(dispatch);
        if (response.result) onSuccess(response.result);
        else onError();
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
  }, 4000);
};

export const handleDuplicateApp = ({ params, t }, { onSuccess = {}, onError = {} }) => {
  confirmAlert({
    title: t("duplicateApp"),
    message: t("AreYouSureWantToContinue"),
    buttons: [
      {
        label: t("yes"),
        onClick: () =>
          handleConfirmDuplicateApp(params, { onSuccess: () => onSuccess(), onError: (err) => onError(err) }),
      },
      {
        label: t("no"),
        onClick: () => {},
      },
    ],
  });
};

export const handleGetAppDetails = ({ params }, { onSuccess = {}, onError = {} }) => {
  duplicateApp(
    { params },
    {
      onSuccess: (result) => {
        onSuccess(result);
      },
      onError: (err) => {
        onError(err);
      },
    }
  );
};

export const handleGetAppCountPending = ({ onSuccess = {}, onError = {} }) => {
  getAppCountPending({
    onSuccess: (result) => {
      onSuccess(result);
    },
    onError: (err) => {
      onError(err);
    },
  });
};

export const handleRecalculateApps = ({ onSuccess = {} }) => {
  evaluateAppsByInstance({
    onSuccess: () => onSuccess(),
  });
};

export const handleClosedApp = (params, { onSuccess = {}, onError = {}, onFinally = {} }) => {
  closedApp(params, {
    onSuccess: () => onSuccess(),
    onError: () => onError(),
    onFinally: () => onFinally(),
  });
};

export const handleRecalculateApp = ({ params }, { onSuccess = {}, onError = {} }) => {
  recalculateApp(
    { params },
    {
      onSuccess: () => onSuccess(),
      onError: () => onError(),
    }
  );
};

export const handleArchivedConfirm = ({ app, t, refetch }, { onSuccess = {} }) => {
  archivedApp(
    {
      app,
    },
    {
      onSuccess: () => {
        handleRefreshPlan(app.planId._id, {
          onSuccess: () => {
            refetch();
          },
          onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        });
        showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        if (onSuccess) onSuccess();
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleCheckActionsArchivedConfirm = ({ selectedRowKeys, planId_, archived_ }) => {
  selectedRowKeys.forEach((item) => {
    if (archived_) {
      handleArchivedConfirm({
        _id: item,
        planId: {
          _id: planId_,
        },
      });
    }
  });
};

export const handleCheckActionsArchived = ({ selectedRowKeys, planId_, archived_ = false, t }, { onSuccess = {} }) => {
  confirmAlert({
    title: t("Confirmación"),
    message: t("Esta seguro que desea Eliminar"),
    buttons: [
      {
        label: t("yes"),
        onClick: () => {
          onSuccess(true);
          handleCheckActionsArchivedConfirm({ selectedRowKeys, planId_, archived_ });
        },
      },
      {
        label: t("no"),
        onClick: () => onSuccess(false),
      },
    ],
  });
};

export const handleCloseWebAppConfirm = ({ params, t }, { onSuccess = {} }) => {
  closeWebApp(params, {
    onSuccess: () => {
      showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
      if (onSuccess) onSuccess();
    },
    onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    onFinally: () => {},
  });
};

export const handleCloseWebApp = ({ params, t }, { onSuccess = {} }) => {
  console.log(params);
  confirmAlert({
    title: t("Confirmación"),
    message: t("Esta seguro que desea Cerrar este Ticket"),
    buttons: [
      {
        label: t("yes"),
        onClick: () => {
          onSuccess(true);
          handleCloseWebAppConfirm(
            { params, t },
            {
              onSuccess: () => {
                onSuccess();
              },
            }
          );
        },
      },
      {
        label: t("no"),
        onClick: () => onSuccess(false),
      },
    ],
  });
};
