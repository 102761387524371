import { useState, useEffect } from "react";
import { Form, Row, Col, Input, InputNumber, Button } from "antd";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { confirmAlert } from "react-confirm-alert";

import { withRouter } from "react-router-dom";
import { icons } from "../../../constants/globals";

import { saveSingleAppByUser } from "../requests";

import SelectPacks from "../../subscribers/components/SelectPacks";
import SelectPlans from "../../subscribersPlans/components/SelectPlans";
import SelectUsers from "../../subscribersUsers/components/SelectUsers";

const moment = require("moment");
const Swal = require("sweetalert2");

const defaultValues = {
  firstname: "",
  lastname: "",
  document: "",
  documentType: "",
  paymentMode: "none",
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  paymentReference: "",
  phone: "+57",
  alergies: "",
  bodylesson: "",
  address: "",
  profession: "",
  medium: "",
  city: "",
  description: "",
  packId: null,
  planId: null,
};

const CreateSingleApp = ({ selectedUser, onClose, refetch }) => {
  const { t } = useTranslation();
  const instanceStoreRedux = useSelector((store) => store.instance);
  const [userId, setUserId] = useState(null);

  const [setting, setSetting] = useState({});
  const [user, setUser] = useState(selectedUser);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [form] = Form.useForm();

  const [packId, setPackId] = useState(null);
  const [planId, setPlanId] = useState(null);

  const [plan, setPlan] = useState({});
  const [reload, setReload] = useState(0);

  const onValuesChange = (values) => {
    setData({ ...data, ...values });
  };

  const onFinish = () => {
    setData({ ...data, app: selectedUser, active: true });

    if (data.packId === undefined || data.planId === undefined) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor validé Paquete y Plan",
      });
      return;
    }

    saveSingleAppByUser(
      { ...data, user: selectedUser, active: true },
      {
        onSuccess: () => {
          Swal.fire({
            icon: "success",
            title: "Operación Exitosa",
          });

          setData(defaultValues);
          refetch();
          onClose();
        },
        onError: () => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        },
        onFinally: () => {},
      }
    );
  };

  const handleSubmit = () => {
    confirmAlert({
      title: "Confirmación",
      message: `Esta seguro que desea continuar?`,
      buttons: [
        {
          label: "Continuar",
          onClick: () => form.submit(),
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const handlePack = (value) => {
    setPackId(value);
    setPlanId(null);
    setData({
      ...data,
      packId: value,
      startDate: moment().format("YYYY/MM/DD"),
      closureDate: moment().format("YYYY/MM/DD"),
    });
  };

  const handleUser = (value) => {
    setUserId(value);
    setData({
      ...data,
      userId: value,
    });
  };

  const handlePlan = (value) => {
    setPlanId(value);
    setData({
      ...data,
      planId: value,
      startDate: null,
      closureDate: null,
    });
  };

  useEffect(() => {
    setPlan({});
    setData({
      ...data,
      packId,
    });
  }, [packId]);

  useEffect(() => {
    setData({
      ...data,
      plan,
      startDate: null,
      closureDate: null,
    });
  }, [plan]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  useEffect(() => {
    setReload(Math.floor(Math.random() * 100000));
    setSetting(instanceStoreRedux.currentInstance);
    setUser(selectedUser);
    setUserId(selectedUser?._id || null);
    setLoading(false);
  }, []);

  return (
    <>
      {!loading && (
        <>
          <Row>
            <Col span={22} offset={1}>
              <Form
                form={form}
                name="manage-category-form-modal "
                className="manage-modal minimal-form"
                onFinish={onFinish}
                labelCol={{ span: 24 }}
                onValuesChange={onValuesChange}
                initialValues={defaultValues}
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="packId3" tooltip="" label={`${t("selectA")} ${t("user")}`}>
                      <SelectUsers
                        reload={reload}
                        currentValue={userId}
                        defaultValue={userId}
                        returnData={handleUser}
                        typePack="SUBSCRIPTION"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="packId2" tooltip="" label={`${t("selectA")} ${t("pack")}`}>
                      <SelectPacks
                        reload={reload}
                        currentValue={packId}
                        defaultValue={packId}
                        returnData={handlePack}
                        typePack="SUBSCRIPTION"
                        single
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="planId" tooltip="" label={`${t("selectA")} ${t("plan")}`}>
                      {packId && packId?.length > 5 && (
                        <SelectPlans
                          handleSelected={setPlan}
                          reload={reload}
                          currentValue={planId}
                          defaultValue={planId}
                          returnData={handlePlan}
                          type="SUBSCRIPTION"
                          packId={packId}
                        />
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item name="quantity" label="Cantidad">
                      <InputNumber />
                    </Form.Item>
                  </Col>

                  <Col span={24} xs={24} sm={24} md={20} lg={20} xl={20}>
                    <Form.Item name="description" label="Alguna observación importante">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Button key="1" onClick={handleSubmit} size="large" style={{ float: "right" }}>
                      Registrar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default withRouter(CreateSingleApp);
