import { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { Row, Col, Button, Space, Select, Affix } from "antd";
import { CalendarOutlined, DollarCircleOutlined, RocketOutlined } from "@ant-design/icons";
import { icons, datesRangesFormat, colorsSystem } from "../../../constants/globals";

import DateSelector from "../components/dateSelector";
import BoxTable from "../components/boxTable";

import BoxTrans from "../components/boxTrans";
import BoxApp from "../components/boxApp";

import BoxModalTable from "../components/boxModalTable";
import BoxGraphCheckins from "../components/boxGraphCheckins";

import BoxGraphRegistro from "../components/boxGraphRegistro";

import { getTransMetrics, getEventMetrics, getAppMetrics } from "../request";

import useModal from "../../shared/hooks/useModal";

import Loading from "../../../assets/loading.gif";

const Dashboards = () => {
  const { t } = useTranslation();

  const [transMetrics, setTransMetrics] = useState({});
  const [eventMetrics, setEventMetrics] = useState({});
  const [appMetrics, setAppMetrics] = useState({});
  const [loading, setLoading] = useState(true);

  const [isOpenModalTableActivateApps, openModalTableActivateApps, closeModalTableActivateApps] = useModal();
  const [isOpenModalTableClosureApps, openModalTableClosureApps, closeModalTableClosureApps] = useModal();
  const [isOpenModalTableRenovateApps, openModalTableRenovateApps, closeModalTableRenovateApps] = useModal();
  const [isOpenModalTableCreateApps, openModalTableCreateApps, closeModalTableCreateApps] = useModal();
  const [isOpenModalTableCreateTrans, openModalTableCreateTrans, closeModalTableCreateTrans] = useModal();

  const [modeSearch, setModeSearch] = useState(null);

  const [filter, setFilter] = useState({
    hasta: moment().format("YYYY-MM-DD"),
    desde: moment().subtract(7, "days").format("YYYY-MM-DD"),
    mode: null,
  });

  useEffect(() => {
    setLoading(true);

    getTransMetrics(
      { ...filter },
      {
        onSuccess: (result) => {
          setTransMetrics(result);
        },
        onError: () => {},
      }
    );

    getAppMetrics(
      { ...filter },
      {
        onSuccess: (result) => {
          setAppMetrics(result);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        },
        onError: () => {},
      }
    );

    getEventMetrics(
      { ...filter },
      {
        onSuccess: (result) => {
          setEventMetrics(result);
        },
        onError: () => {},
      }
    );
  }, [filter]);

  const filterPredeterminado = (mode) => {
    setModeSearch(mode);
    setFilter({ mode });
  };

  const handleChangeRangeDateSelect = (value) => {
    filterPredeterminado(value);
  };

  console.log(filter.mode);

  return (
    <>
      <Affix offsetTop="0">
        <Row gutter={[16, 16]} className="dashboard">
          <Col xs={24} sm={24} md={24} lg={12} xl={2}>
            <div className="container-subpages">
              {loading && (
                <img
                  style={{ margin: "auto", display: "block" }}
                  src="https://4.bp.blogspot.com/-9y6cnZSLsIs/WKh_Djf0ZYI/AAAAAAAABGE/PHReoExj9eUarX8TNllC-jC93JFxe4rzQCLcB/s1600/loading%2Bgif%2B4.gif
              "
                  alt=""
                  width="60px"
                />
              )}
              {!loading && (
                <img
                  style={{ margin: "auto", display: "block" }}
                  src="https://cluuftracks.herokuapp.com/robot.png"
                  alt=""
                  width="60px"
                />
              )}
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={6}>
            <div className="container-subpages" style={{ textAlign: "center" }}>
              <p style={{ textAlign: "center" }}>
                <b>
                  <CalendarOutlined /> Seleccione un rango de fechas
                </b>
              </p>
              <DateSelector filter={filter} setFilter={setFilter} setModeSearch={setModeSearch} />
            </div>
          </Col>
          <Col xs={0} sm={0} md={6} lg={2} xl={16}>
            <div className="container-subpages center-buttons">
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p style={{ textAlign: "center" }}>
                    <b>
                      <CalendarOutlined /> Seleccione un rango de fechas predeterminado
                    </b>
                  </p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                  <Space>
                    <Button
                      className="tag-button-table"
                      type={modeSearch === "today" ? "primary" : "default"}
                      size="small"
                      onClick={() => filterPredeterminado("today")}
                    >
                      Hoy
                    </Button>
                    <Button
                      className="tag-button-table"
                      type={modeSearch === "yesterday" ? "primary" : "default"}
                      size="small"
                      onClick={() => filterPredeterminado("yesterday")}
                    >
                      Ayer
                    </Button>
                    <Button
                      className="tag-button-table"
                      type={modeSearch === "thisweek" ? "primary" : "default"}
                      size="small"
                      onClick={() => filterPredeterminado("thisweek")}
                    >
                      Esta Semana
                    </Button>
                    <Button
                      className="tag-button-table"
                      type={modeSearch === "thismonth" ? "primary" : "default"}
                      size="small"
                      onClick={() => filterPredeterminado("thismonth")}
                    >
                      Mes actual
                    </Button>
                    <Button
                      className="tag-button-table"
                      type={modeSearch === "lastmonth" ? "primary" : "default"}
                      size="small"
                      onClick={() => filterPredeterminado("lastmonth")}
                    >
                      Mes anterior
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={24} sm={0} md={0} lg={0} xl={0}>
            <div className="container-subpages center-buttons" style={{ textAlign: "center" }}>
              <Row>
                <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                  <p style={{ position: "relative", top: 6, textAlign: "center" }}>
                    <b>
                      <CalendarOutlined /> Seleccione un rango de fechas predeterminado
                    </b>
                  </p>
                </Col>
                <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                  <Select defaultValue="today" style={{ width: 120 }} onChange={handleChangeRangeDateSelect}>
                    <Select.Option value="today">Hoy</Select.Option>
                    <Select.Option value="yesterday">Ayer</Select.Option>
                    <Select.Option value="thisweek">Esta semana</Select.Option>
                    <Select.Option value="lastweek">Semana pasada</Select.Option>
                    <Select.Option value="thismonth">Este mes</Select.Option>
                    <Select.Option value="lastmonth">Mes pasado</Select.Option>
                  </Select>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <small
              style={{
                textAlign: "center",
                fontWeight: 600,
                padding: 5,
                background: colorsSystem.red,
                color: colorsSystem.white,
                maxWidth: 600,
                margin: "auto",
                borderRadius: 7,
                position: "relative",
                top: "-7px",
              }}
            >
              {icons.clock} Fechas de busqueda: &nbsp;&nbsp;
              {filter.mode
                ? datesRangesFormat(filter.mode)
                : `${moment(filter.desde).format("L")} hasta ${moment(filter.hasta, "YYYY-MM-DD").format("L")}`}
            </small>
          </Col>
        </Row>
      </Affix>
      {!loading ? (
        <>
          <Row gutter={[16, 16]} className="dashboard">
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              {appMetrics?.ok && <BoxApp icon={<RocketOutlined />} title="Aplicaciones" metrics={appMetrics} />}
            </Col>

            <Col xs={24} sm={24} md={13} lg={13} xl={13}>
              {transMetrics?.ok && (
                <BoxTrans icon={<DollarCircleOutlined />} title="Transacciones" metrics={transMetrics} />
              )}
            </Col>
          </Row>
          <Row gutter={24}>
            {appMetrics?.ok &&
              appMetrics?.createApps?.data?.length > 0 &&
              filter.mode !== "today" &&
              filter.mode !== "yesterday" &&
              filter.mode !== null && (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div className="container-subpages">
                    <h2 style={{ textAlign: "center" }}>Gráfico de aplicaciones</h2>
                    <BoxGraphRegistro
                      json={appMetrics?.createApps?.details?.apps}
                      json2={appMetrics?.renovateApps?.details?.apps}
                      json3={appMetrics?.closureApps?.details?.apps}
                    />
                  </div>
                </Col>
              )}

            {appMetrics?.ok && appMetrics?.createApps?.data?.length > 0 && (
              <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                {appMetrics?.ok && (
                  <BoxTable
                    openModalTable={openModalTableCreateApps}
                    total={<>{appMetrics?.createApps?.data?.length} Registros</>}
                    title="por planes"
                    data={appMetrics?.createApps?.details?.plans_}
                  />
                )}
              </Col>
            )}
            {appMetrics?.ok && appMetrics?.activateApps?.data?.length > 0 && (
              <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                {appMetrics?.ok && (
                  <BoxTable
                    total={<>{appMetrics?.activateApps?.data?.length} Activaciones</>}
                    openModalTable={openModalTableActivateApps}
                    title="por planes"
                    data={appMetrics?.activateApps?.details?.plans_}
                  />
                )}
              </Col>
            )}
            {appMetrics?.ok && appMetrics?.closureApps?.data?.length > 0 && (
              <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                {appMetrics?.ok && (
                  <BoxTable
                    total={<>{appMetrics?.closureApps?.data?.length} Finalizaciones</>}
                    openModalTable={openModalTableClosureApps}
                    title="por planes"
                    data={appMetrics?.closureApps?.details?.plans_}
                  />
                )}
              </Col>
            )}
            {appMetrics?.ok && appMetrics?.renovateApps?.details?.plans_.length > 0 && (
              <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                <BoxTable
                  total={<>{appMetrics?.renovateApps?.data?.length} Renovaciones</>}
                  openModalTable={openModalTableRenovateApps}
                  title="por planes"
                  data={appMetrics?.renovateApps?.details?.plans_}
                />
              </Col>
            )}
            {transMetrics?.ok && transMetrics?.createTrans?.details?.plans_.length > 0 && (
              <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                <BoxTable
                  total={<>{transMetrics?.createTrans?.data?.length} Transacciones</>}
                  openModalTable={openModalTableCreateTrans}
                  title="por planes"
                  data={transMetrics?.createTrans?.details?.plans_}
                />
              </Col>
            )}

            {eventMetrics?.ok && eventMetrics?.checkins?.data?.length > 0 && (
              <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                <BoxTable
                  total={<>{eventMetrics?.checkins?.data?.length} Asistencias</>}
                  title="asistencías"
                  data={eventMetrics?.checkins?.details}
                />
              </Col>
            )}
            {eventMetrics?.ok && eventMetrics?.logins?.data?.length > 0 && (
              <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                <BoxTable
                  total={<>{eventMetrics?.logins?.data?.length} Sesiones</>}
                  title=" en el Sistema"
                  data={eventMetrics?.logins?.details}
                />
              </Col>
            )}

            {eventMetrics?.ok &&
              eventMetrics?.checkins?.data?.length > 0 &&
              filter.mode !== "today" &&
              filter.mode !== "yesterday" &&
              filter.mode !== null && (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div className="container-subpages">
                    <h2 style={{ textAlign: "center" }}>Gráfico de las Asistencias</h2>
                    <BoxGraphCheckins json={eventMetrics?.checkins?.data} />
                  </div>
                </Col>
              )}

            {eventMetrics?.ok && eventMetrics?.sendInvoice?.data?.length > 0 && (
              <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                <BoxTable
                  total={<>{eventMetrics?.sendInvoice?.data?.length} Envios comprobantes</>}
                  title=" a clientes"
                  data={eventMetrics?.sendInvoice?.details}
                />
              </Col>
            )}

            {eventMetrics?.ok && eventMetrics?.excelDownload?.data?.length > 0 && (
              <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                <BoxTable
                  total={<>{eventMetrics?.excelDownload?.data?.length} Descarga reportes</>}
                  title="en Excel"
                  data={eventMetrics?.excelDownload?.details}
                />
              </Col>
            )}
          </Row>

          {appMetrics?.ok && appMetrics?.activateApps?.data.length > 0 && (
            <BoxModalTable
              data={appMetrics?.activateApps?.details?.apps}
              closeModal={closeModalTableActivateApps}
              openModal={isOpenModalTableActivateApps}
              title="Aplicaciones activadas"
              t={t}
            />
          )}

          {appMetrics?.ok && appMetrics?.closureApps?.data.length > 0 && (
            <BoxModalTable
              data={appMetrics?.closureApps?.details?.apps}
              closeModal={closeModalTableClosureApps}
              openModal={isOpenModalTableClosureApps}
              title="Aplicaciones Finalizadas"
              t={t}
            />
          )}

          {appMetrics?.ok && appMetrics?.renovateApps?.data.length > 0 && (
            <BoxModalTable
              data={appMetrics?.renovateApps?.details?.apps}
              closeModal={closeModalTableRenovateApps}
              openModal={isOpenModalTableRenovateApps}
              title="Aplicaciones renovadas"
              t={t}
            />
          )}

          {appMetrics?.ok && appMetrics?.createApps?.data.length > 0 && (
            <BoxModalTable
              data={appMetrics?.createApps?.details?.apps}
              closeModal={closeModalTableCreateApps}
              openModal={isOpenModalTableCreateApps}
              title="Aplicaciones renovadas"
              t={t}
            />
          )}

          {transMetrics?.ok && transMetrics?.createTrans?.data.length > 0 && (
            <BoxModalTable
              data={transMetrics?.createTrans?.details?.trans}
              closeModal={closeModalTableCreateTrans}
              openModal={isOpenModalTableCreateTrans}
              title="Aplicaciones renovadas"
              App={false}
              Events={false}
              Trans
              t={t}
            />
          )}
        </>
      ) : (
        <h2 style={{ textAlign: "center" }}>
          <img alt="loading" src={Loading} width="40px" style={{ margin: "auto", marginTop: 200 }} />
        </h2>
      )}
    </>
  );
};

export default Dashboards;
