import { useState, useEffect } from "react";
import { Row, Col, message, Card, Spin } from "antd";
import { useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";
import Loginform from "../components/loginForm";
import showGlobalNotification from "../../../helpers/showGlobalNotification";

import "../login.css";

import { login, loadBranding } from "../request";
import { setInstanceRedux } from "../../../redux/instance";

const Login = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const [alias, setAlias] = useState(props.match.params.alias);

  // eslint-disable-next-line react/destructuring-assignment
  const [goto, setGoto] = useState(props.match.params.goto);

  const [setting, setSetting] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem("keypublic");
    localStorage.removeItem("cluuf-settings");
    localStorage.removeItem("instanceId");
    localStorage.removeItem("alias");

    loadBranding(alias, {
      onSuccess: (response) => {
        localStorage.setItem("alias", alias);
        setSetting(response.result);
        if (response.result.keypublic) {
          localStorage.setItem("keypublic", response.result.keypublic);
          setInstanceRedux(response.result)(dispatch);
        }
      },
      onError: () => showGlobalNotification("error", "Error", "error"),
    });
  }, [alias]);

  useEffect(() => {
    localStorage.setItem("goto", goto);
  }, [goto]);

  return (
    <>
      {setting?._id ? (
        <Row>
          {/*  <Col span={24} style={{ backgroundImage: `url(${setting.background})`, backgroundSize: "cover" }}> */}
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div className="containerLogin">
              <Card
                cover={
                  <img
                    alt="example"
                    style={{ width: "220px", margin: "auto", textAlign: "center" }}
                    src={setting.logo}
                  />
                }
                className="item"
                bordered={false}
                style={{ maxWidth: "90%", paddingTop: 25 }}
              >
                <Loginform setting={setting} login={login} router={props} message={message} />
              </Card>
            </div>
            <h2 className="special-font bold " style={{ color: "#fff", width: "100%", textAlign: "center" }}>
              By Cluuf
            </h2>
          </Col>
          <Col xs={24} sm={24} md={8} lg={16} xl={16}>
            <img src={setting.background} style={{ position: "relative", top: "-30px", width: "100%" }} alt="login" />
          </Col>
        </Row>
      ) : (
        <p style={{ margin: "auto", maxWidth: 100, paddingTop: 200 }}>
          <Spin tip="Cargando ..." />
        </p>
      )}
    </>
  );
};

export default withRouter(Login);
