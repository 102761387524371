import { Button, Tooltip } from "antd";
import { PlusOutlined, TableOutlined, IdcardOutlined, AmazonOutlined } from "@ant-design/icons";
import PrimaryButton from "../../shared/components/PrimaryButton";
import useShortcut from "../../shared/hooks/useShortcut";

const AddPackButton = ({
  setModePack,
  isOpenManagePacksModal,
  handleNew,
  t,
  containerMode,
  setContainerMode,
  handleUploadJsonPlanToS3,
}) => {
  useShortcut(["n"], handleNew, [isOpenManagePacksModal]);

  return (
    <>
      <Tooltip placement="topLeft" title="Actualizar informacion en el Website">
        <Button
          className="secondary"
          onClick={() => handleUploadJsonPlanToS3()}
          shape="square"
          icon={<AmazonOutlined />}
          size="large"
          type="primary"
        >
          {t("RefreshJSONS3")}
        </Button>
      </Tooltip>

      <PrimaryButton onClick={handleNew} type="primary" size="large" icon={<PlusOutlined />}>
        {t("new")} {t("page")}
      </PrimaryButton>

      {containerMode === "table" && (
        <Button
          onClick={() => {
            setContainerMode("cards");
            setModePack("cards");
          }}
          shape="square"
          icon={<IdcardOutlined />}
          size="large"
        />
      )}
      {containerMode === "cards" && (
        <Button
          onClick={() => {
            setContainerMode("table");
            setModePack("table");
          }}
          shape="square"
          icon={<TableOutlined />}
          size="large"
        />
      )}
    </>
  );
};

export default AddPackButton;
