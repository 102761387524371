import { CloseCircleOutlined, SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Tooltip } from "antd";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import globalStyles from "../../css/global.module.css";
import useShortcut from "../../hooks/useShortcut";
import styles from "./ActionBar.module.css";

const ActionBar = ({
  isEmbed,
  isMinimal = false,
  children,
  options,
  filters,
  setFilters,
  handleExportExcel = null,
  ExcelButton = null,
}) => {
  const { t } = useTranslation();
  const [searching, setSearching] = useState(false);
  const handleSearch = () => setSearching(!searching);

  useShortcut(["s"], () => setSearching(true), [searching]);

  const reset = () => {
    setFilters({ ...filters, all: "", searchByUsers: false });
  };

  return (
    <>
      {searching && children ? (
        <div className={`${globalStyles["padding-xs"]} ${styles["action-bar-content"]}`}>
          <Row justify="space-between" align="middle">
            <Col span={24}>{children}</Col>
          </Row>
          <CloseCircleOutlined onClick={handleSearch} className={styles["action-bar-close-close-button"]} />
        </div>
      ) : null}
      <Row className={`${globalStyles["padding-xs"]} ${styles["action-bar"]}`} justify="space-around" align="middle">
        <Col span={21}>
          {options && options?.actions && (
            <options.actions setFilters={setFilters} filters={filters} isEmbed={isEmbed} isMinimal={isMinimal} />
          )}
        </Col>
        {!isMinimal && (
          <Col span={3} className={globalStyles["text-align-right"]}>
            <Space>
              {children && !searching ? (
                <>
                  <Button className="btn-icon-only" icon={<SearchOutlined />} size="large" onClick={handleSearch}>
                    {t["common.search"]}
                  </Button>
                  <Button className="btn-icon-only" icon={<ReloadOutlined />} size="large" onClick={reset}>
                    {t["common.search"]}
                  </Button>
                </>
              ) : null}

              {handleExportExcel && <Tooltip title="Exportar a excel">{ExcelButton}</Tooltip>}
            </Space>
          </Col>
        )}
      </Row>
    </>
  );
};

ActionBar.propTypes = {
  isEmbed: PropTypes.bool,
  children: PropTypes.node,
  options: PropTypes.object,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

export default ActionBar;
