import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      customers: "Customers",
      customer: "Customer",
      add: "Add",
      phone: "Phone",
      documentType: "Document Type",
      document: "Document",
      email: "Email",
      birthdate: "Birthdate",
      category: "Category",
      address: "Address",
      city: "City",
      region: "Region",
      country: "Country",
      startdate: "Start Date",
      enddate: "End Date",
      "Referer By": "Referer By",
      guest: "Guest",
      createdAt: "Created at",
      lastUpdatedBy: "Last Updated By",
      lastUpdatedAt: "Last Updated At",
      resourcesWeb: "Resources Web",
      open: "Abiertas",
      OPEN: "ABIERTAS",
      apps: "Apps",
    },
  },
  es: {
    translation: {
      customers: "Clientes",
      customer: "Cliente",
      add: "Agregar",
      phone: "Teléfono",
      documentType: "Tipo de documento",
      document: "Documento",
      email: "Email",
      birthdate: "Fecha de Nacimiento",
      category: "Categoria",
      address: "Dirección",
      city: "Ciudad",
      region: "Región",
      country: "Pais",
      startdate: "Fecha de inicio",
      enddate: "Fecha de salida",
      "Referer By": "Referido por",
      guest: "Invitado",
      guests: "Invitados",
      referer: "Referido",
      referers: "Referidos",
      application: "Aplicación",
      payment: "Pago",
      payments: "Pagos",
      applications: "Aplicaciones",
      new: "Nuevo",
      edit: "Editar",
      delete: "Eliminar",
      currentStatus: "Estado actual",
      plan: "Actividad",
      basic: "Básico",
      type: "Tipo",
      "Monthly fee": " Mensualidad",
      MonthlyFee: " Mensualidad",
      lastVisit: "Ultima Visita",
      fullname: "Nombre Completo",
      active: "Activo",
      inactive: "Inactivo",
      role: "Role",
      actions: "Acciones",
      save: "Guardar",
      maximum: "Maximo",
      minimum: "Minimo",
      price: "Precio",
      location: "Locación",
      public: "Público",
      private: "Privado",
      featureImage: "Imagen Portada",
      tag: "Etiqueta",
      tags: "Etiquetas",
      advanced: "Avanzado",
      general: "General",
      duration: "Duración",
      successfulProcess: "Proceso Exitoso",
      successfulProcessPayment: "Pago Exitoso",
      inHorabuena: "Enhorabuena",
      errorTitle: "Upps",
      errorDescription: "Ha ocurrido un Error",
      description: "Descripción",
      loading: "Cargando",
      observations: "Observaciones",
      mode: "Modo",
      amountToPay: "Monto a pagar",
      isPending: "Queda debiendo",
      totalToPay: "Total a Pagar",
      reference: "Referencia",
      status: "Estado",
      name: "Nombre",
      yes: "Si",
      no: "No",
      privacy: "Privacidad",
      calendar: "Calendario",
      observation: "Observación",
      pack: "Paquete",
      packs: "Paquetes",
      user: "Usuario",
      users: "Usuarios",
      total: "Total",
      pending: "Pendiente",
      completed: "Completada",
      outflowMoney: "Egreso",
      incomeMoney: "Ingreso",
      transactions: "Transacciones",
      transaction: "Transacción",
      setting: "Configuración",
      youtube: "Youtube",
      instagram: "Instagram",
      facebook: "Facebook",
      website: "Pagina Web",
      background: "Imagen de Fondo",
      color: "Color",
      logo: "Logotipo",
      profile: "Perfil",
      firstname: "Nombres",
      lastname: "Apellidos",
      avatar: "Imagen de Perfil",
      login: "Login",
      password: "Contraseña",
      link: "Enlace",
      text: "Texto",
      createdAt: "Creado el",
      lastUpdatedBy: "Actualizado por",
      lastUpdatedAt: "Actualizado el",
      resourcesWeb: "Recursos Web",
      contacts: "Contactos",
      code: "Código",
      clickTo: "Haga clic para ",
      moreDetails: "Más detalles",
      deactivate: "desactivar",
      activate: "activar",
      all: "Todos",
      AreYouWantDelete: "Estas seguro que deseas eliminar",
      AreYouSureWantToContinue: "Esta seguro que desea continuar?",
      userOnHold: "Contacto en Espera",
      userCompleted: "Contacto Atendido",
      clickToChange: "Haga click para cambiar a",
      allContacts: "Todos los contactos",
      onHoldContacts: "Contactos en espera",
      completedContacts: "Contactos atendidos",
      date: "Fecha",
      message: "Mensaje",
      contact: "contacto",
      webResource: "Recurso Web",
      unlimitedDate: "Tiempo ilimitado",
      multiple: "Multiple",
      form: "Formulario",
      image: "Imagen",
      uploadImage: "Cargar Imagen",
      uploadImageS3: "Cargar Imagen S3",
      body: "Texto principal",
      bodyResponsive: "Texto para dispositivos moviles",
      advancedOptions: "Opciones avanzadas",
      basicOptions: "Opciones básicas",
      updatedlAt: "Actualizado el",
      car: "carro",
      service: "Servicio",
      additionalInformation: "Información adicional",
      imageGallery: "Galería de Imagen",
      amount: "Monto",
      webMetrics: "Métricas Webs",
      inventory: "Inventario",
      units: "Unidades",
      measuringUnit: "Unidad de Medida",
      minLimit: "Cantidad Minima",
      maxLimit: "Cantidad Maxima",
      stock: "Cantidad Actual",
      paymentLink: "Link de pago",
      subscription: "Servicio",
      SERVICE: "servicio",
      SUBSCRIPTION: "Servicio",
      EVENT: "Evento",
      event: "Evento",
      root: "Raiz",
      OPEN: "ABIERTAS",
      apps: "Apps",
      page: "Pagina",
      content: "Contenido",
      Content: "Contenido",
      language: "Idioma",
      subscriptions: "Servicios",
      excerpt: "Resumen",
      availableDays: "Días de actividad",
      departureTime: "Horas de actividad",
      free: "Gratis",
      moreEspecific: "Más especificaciones",
      allPacks: "Todos los paquetes",
      actives: "Activos",
      inactives: "Inactivos",
      updatedAt: "Actualizado el",
      updateInformation: "Actualizar Informacion",
      tickets: "Tickets",
      time: "Horario",
      tour: "Tour",
      debe: "Deuda",
      pagado: "",
      notifiedAt: "Notificado el",
      subject: "Asunto",
      package: "Paquete",
      files: "Archivos",
      uploadAvatar: "Subir Avtar",
      linkExternalApp: "Enlace Externo",
      RefreshJSONS3: "Actualizar Website",
      quantityguest: "Invitados",
      durationMode: "Modo duración",
      onlyActiveApps: "Filtrar Apps por Estado",
      onlyActiveAppsByActive: "Apps Activas e Inactivas",
      inwait: "En Espera",
      courtesy: "Cortesía",
      links: "Enlaces",
      sureToDesactivate: "Desea desactivar",
      sureToActivate: "Desea activar",
      sureToDelete: "Desea eliminar",
      planPrice: "Costo del plan",
      quantityCourtesy: "Pases de Cortesías",
      metrics: "Métricas",
      paymentTotal: "Total a Pagar",
      amountPaid: "Total Pagado",
      maximumCapacity: "Capacidad Maxima",
      minimumCapacity: "Capacidad Minima",
      generalData: "Datos Generales",
      filterByAllPlans: "Mostrar todos los planes",
      filterByActivePlans: "Mostrar solo planes activos",
      filterByInactivePlans: "Mostrar solo planes inactivos",
      goToLandingPage: "Abrir Website",
      goToLandingPageExpress: "Registrar Aplicación",
      goToQrPage: "Abrir QR Page",
      plansSubscription: "Actividades",
      attachedFiles: "Archivos adjuntos",
      yearsold: "Edad",
      from: "Desde",
      to: "Hasta",
      plans: "Planes",
      photo: "Foto",
      uploadFiles: "Subir archivo",
      inbound: "Ingresos",
      outbound: "Salidas",
      open: "Abiertas",
      selectA: "Seleccione un",
      selectAn: "Seleccione una",
      activeApp: "Activar Apps",
      inactiveApp: "Inactivar Apps",
      pack_title: "Titulo",
      pack_labelBotonSubmit: "Texto del boton de submit",
      pack_urlVideo: "Url del Video",
      pack_selectPlan: "Seleccione un plan",
      pack_colorForm: "Color del  fondo del Formulario",
      pack_colorBtnForm: "Color del boton del Formulario",
      pack_primaryColor: "Color primario",
      pack_recomendations: "Recomendaciones",
      pack_emailClosure: "Texto email de Finalización",
      pack_include: "Que incluye el servicio (Separado por commas)",
      pack_exclude: "Que NO incluye el servicio (Separado por commas)",
      pack_itineraries: "Itinerario (Separado por commas)",
      pack_departureTime: "Horas de salida (Separado por commas)",
      pack_description: "Descripción del Servicio",
      pack_iframeMap: "Ubicacion del lugar del evento",
      pack_isTemporal: "Temporal?",
      pack_isVaccine: "Esquema de vacunación?",
      pack_isCarnet: "Carnet de ingreso?",
      pack_isPortal: "Acceso a un portal web",
      pack_single: "Registro Simple",
      pack_parentalControl: "Control parental",
      pack_unlimitedDate: "Fecha Ilimitada",
      pack_cupon: "Acepta cupones",
      pack_privacyPolicyUrl: "URL de Términos y politicas de privacidad",
      pack_emailWelcome: "Texto Email de la solicitud",
      pack_emailRegistration: "Texto email de Confirmación",
      pack_webhooks: "Slack",
      pack_meetingPoint: "Embed de Google Map del punto de Encuentro",
      pack_service: "Servicio",
      pack_isDefaultColor: "Preservar los colores globales",
      app_ckeckin: "Ingreso",
      pack_isDocumentRequired: "Require N Documento",
      pack_isTicketsNumber: "Agregar tickets",
      instance_cancellationPolicy: "Politicas de Cancelación",
      instance_termsConditions: "Terminos y Condiciones",
      instance_paymentConditions: "Metodologias de Pago",
      plan_isEnding: "Por finalizar",
      plan_availability: "Disponibilidad",
      plan_isProgress: "En progreso",
      plan_isPending: "En espera",
      plan_isClosed: "Finalizadas",
      plan_isStarting: "Proximas",
      plan_isFree: "Gratuito?",
      plan_isTemporal: "Temporal?",
      plan_status: "Estado",
      plan_all: "Todo",
      plan_limitCheckin: "Limite de Ingresos",
      plan_close: "Finalizar",
      plan_allClosedSuccess: "planes han sido cerrados.",
      plan_activitiesInactives: "Mostrar las finalizadas",
      plan_activitiesActives: "Mostrar las activas",
      plan_new: "Registrar actividad",
      app_FilterbyActivity: "Filtro por actividad",
      app_refererBy: "Referenciado por otro cliente",
      app_dateFrom: "Fecha de inicio desde",
      app_dateTo: "hasta",
      trans_cash: "Efectivo",
      trans_coupon: "Codigo de cupon",
      trans_card: "Tarjeta",
      trans_pendingPayment: "Pago pendiente",
      trans_completedPayment: "Pago completado",
      trans_partialPayment: "Pago parcial",
      trans_paymentMode: "Método de pago",
      trans_referenceNumber: "Número de referencía",
      trans_paymentDescription: "Descripción del pago",
      trans_paymentRegister: "Registrar pago",
      trans_amountTopay: "Monto a pagar",
      trans_discount: "Descuento",
      trans_doPayment: "Realizar pago",
      trans_visualize_invoice: "Visualizar Comprobante",
      trans_sendTo: "Enviar a",
      payment_cash: "Efectivo",
      payment_debitcard: "Tarjeta de débito",
      payment_creditcard: "Tarjeta de Crédito",
      payment_transfer: "Transferencía",
      payment_deposit: "Deposito",
      payment_none: "Sin pago",
      payment_method: "Método de pago",
      contact_address: "Dirección de habitación",
      contact_genre: "Género",
      contact_birthday: "Fecha de nacimiento",
      contact_phone: "Teléfono",
      contact_document: "Documento",
      contact_documentType: "Tipo de documento",
      contact_email: "Correo electrónico",
      contact_firstname: "Nombres",
      contact_ckeckProcessing: "Procesando la asistencia del contacto",
      contact_lastname: "Apellidos",
      document_passport: "Pasaporte",
      document_cedulaCiudadania: "Cédula de Ciudadanía",
      document_identificationCard: "Tarjeta de Identificación",
      document_cedulaExtranjeria: "Cédula de extranjería",
      document_pep: "Permiso especial de permanencia",
      genre_female: "Femenino",
      genre_male: "Masculino",
      genre_none: "No especifica",
      plan_openIt: "Abrir",
      plan_closeIt: "Finalizar",
      common_cantRevert: "Esta acción no es capaz de revertirse!",
      common_areYouSure: "Esta seguro?",
      common_yesIWantContinueDelete: "Si, quiero eliminarlo!",
      common_yesIWantContinue: "Si, deseo continuar",
      common_cancel: "Cancelar",
      common_trans_confirmed: "Tu transacción ha sido eliminada",
      common_done: "Hecho!",
      common_clients: "Clientes",
      common_services: "Servicios",
      common_plans: "Subscripciones",
      common_events: "Actividades",
      common_rental: "Alquileres",
      common_products: "Venta de productos",
      common_quantity: "Cantidad",
      common_event_confirmed: "Proceso exitoso!",
      common_yesIWantContinueClosed: "Si, quiero Finalizarlos!",
      common_yesIWantContinueCheckin: "Si, confirmo asistencía!",
      common_thisFieldIsRequired: "Este campo es requerido",
      products_sellProducts: "Ventas",
      products_listProducts: "Lista de productos",
      events_sellEvents: "Eventos vendidos",
      subscriptions_sellSubscriptions: "Subscripciones",
      rental_sellProducts: "Alquileres",
      rental_departureDate: "Fecha de alquiler",
      rental_departureTime: "Hora de alquiler",
      rental_duration: "Duración",
      rental_service: "Servicio",
      activity_services: "Actividades",
    },
  },
};

i18n
  .use(initReactI18next) //  bvcpasses i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.changeLanguage("es");

export default i18n;
