/* eslint-disable no-unused-expressions */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";

import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import { handleActive, handleArchived, handleExportExcelUsers, handleDetails } from "../functions/index";

// COMPONENTS
import ActionBar from "../../shared/components/ActionBar";
import UsersTable from "../components/usersTable";
import SearchUsersForm from "../components/SearchUsersForm";
import ActionsUsers from "../components/ActionsUsers";
import ManageUserModal from "../components/ManageUserModal";
import PagesViewTable from "../components/pageViewsTable";
import TracksTable from "../components/tracksTable";
import AddUserButton from "../components/AddUserButton";
import UserHeader from "../components/userHeader";
import UsersHeader from "../components/usersHeader";
import AppContainer from "../../subscribersApps/containers/Apps";
import TransContainer from "../../subscribersTrans/containers/Trans";
import TabMenu from "../components/Tabs";
import ManageBucketModal from "../../bucket/components/ManageBucketModal";
import BucketContainer from "../../bucket/containers/Buckets";
import EventsContainer from "../../events/containers/Event";

import {
  getViewsByContact,
  getActionsByContact,
  getResumePageViewsByContact,
  getResumeTracksByContact,
} from "../../metrics/requests";

import MetricsCard from "../../metrics/components/MetricsCard";

// HELPERS

// REDUX
import { setUser } from "../../../redux/user";

// REQUEST
import { getUser } from "../requests";

// HOOKS
import useUsers from "../hooks/useUsers";
import useModal from "../../shared/hooks/useModal";

// CONSTANTS
import { globals, numbers, packs, icons } from "../../../constants/globals";

const Users = ({ match }) => {
  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);
  const instanceStoreRedux = useSelector((store) => store.instance);
  const { t } = useTranslation();

  const { users, filters, setFilters, pagination, setPagination, loading, refetch } = useUsers();
  const [isOpenManageBucketModal, openManageBucketModal, closeManageBucketModal] = useModal();

  const commonProps = { filters, setFilters };
  const [page, setPage] = useState(globals.PAGE_MAIN);
  const [pagesViewData, setPagesViewData] = useState([]);
  const [tracksWebData, setTracksWebData] = useState([]);
  const [resumePageView, setResumePageView] = useState({});
  const [resumeDevice, setResumeDevice] = useState({});
  const [resumeCity, setResumeCity] = useState({});
  const [resumeRegion, setResumeRegion] = useState({});
  const [resumeCountry, setResumeCountry] = useState({});

  const [resumeEvent, setResumeEvent] = useState({});
  const [resumeCode, setResumeCode] = useState({});
  const [resumeHref, setResumeHref] = useState({});

  const [refresh, setRefresh] = useState(true);

  const [subpage, setSubpage] = useState(numbers.FIVE);
  const [selectedUser, setSelectedUser] = useState({});

  const [userId, setUserId] = useState(null);
  const [packType, setPackType] = useState(null);

  const [userType, setUserType] = useState("Gallery");

  const [isOpenManageUsersModal, openManageUsersModal, closeManageUsersModal] = useModal();

  const [isOpenGeneralModal, openGeneralModal, closeGeneralModal] = useModal();

  const [initialValues, setInitialValues] = useState({
    firstname: "",
    lastname: "",
    role: "USER",
    address: "",
    city: "",
    region: "",
    country: "",
    document: "",
    documentType: "",
    observation: "",
    birthdate: "1990/01/01",
    category: "cliente",
    type: "Normal",
    email: "",
    phone: "",
    reference: "",
    active: "",
    startdate: "1990/01/01",
    closuredate: "1990/01/01",
    refererId: null,
  });

  const [extra, setExtra] = useState({});

  const eventTranslate = (name) => {
    if (name === "s") return "Scroll";
    if (name === "c") return "Click";
    if (name === "f") return "Form Submit";
    if (name === "d") return "Download Resource";
    return name;
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setUserId(user._id);
    openManageUsersModal();
  };

  const handleGeneralModal = ({ user, type }) => {
    setSelectedUser(user);
    setUserId(user._id);
    setPackType(type);
    openGeneralModal();
  };

  const userDetails = (user) => {
    setUserId(user._id);
    setPage(globals.PAGE_ONE);
    handleDetails(
      { user, dispatch },
      {
        onSuccess: (result) => {
          setSelectedUser(result);
        },
      }
    );
  };

  const handleExportExcel = () => {
    handleExportExcelUsers(users);
  };

  const handleUploadImage = (typeUsr = "Gallery") => {
    setUserType(typeUsr);
    openManageBucketModal();
  };

  const resetFilters = () => {
    setFilters({ ...filters, all: "" });
    refetch();
  };

  useEffect(() => {
    if (match.params.id) {
      handleDetails({ _id: match.params.id });
    }
    setRefresh(true);
  }, []);

  useEffect(() => {
    refetch();
  }, [filters]);

  useEffect(() => {
    setUser(selectedUser)(dispatch);

    if (selectedUser._id) {
      setUserId(selectedUser._id);
      setSubpage(numbers.FIVE);
    }
  }, [selectedUser]);

  useEffect(() => {
    debounce(() => refetch(), 500);
  }, [filters]);

  useEffect(() => {
    if (instanceStoreRedux.currentInstance.hasAnalytics) {
      if (subpage !== numbers.FIVE || subpage !== numbers.SIX || subpage !== numbers.SEVEN) {
        getViewsByContact(
          { user: selectedUser.code },
          {
            onSuccess: (result) => {
              setPagesViewData(result.data);
              setExtra({ ...result.data[result.data.length - 1], views: result.data.length - 1 });
            },
            onError: () => {},
          }
        );

        getActionsByContact(
          { contact: selectedUser.code },
          {
            onSuccess: (result) => {
              setTracksWebData(result.action);
            },
            onError: () => {},
          }
        );

        getResumePageViewsByContact(
          { user: selectedUser.code },
          {
            onSuccess: (result) => {
              const urlArray = [];
              Object.entries(result.url).forEach(([key, value]) => {
                urlArray.push({ name: key, count: value });
              });

              urlArray.sort(function (a, b) {
                if (a.count < b.count) {
                  return 1;
                }
                if (a.count > b.count) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              setResumePageView(urlArray);

              const cityArray = [];
              Object.entries(result.city).forEach(([key, value]) => {
                cityArray.push({ name: key, count: value });
              });

              cityArray.sort(function (a, b) {
                if (a.count < b.count) {
                  return 1;
                }
                if (a.count > b.count) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              setResumeCity(cityArray);

              const regionArray = [];
              Object.entries(result.region).forEach(([key, value]) => {
                regionArray.push({ name: key, count: value });
              });

              regionArray.sort(function (a, b) {
                if (a.count < b.count) {
                  return 1;
                }
                if (a.count > b.count) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              setResumeRegion(regionArray);

              const countryArray = [];
              Object.entries(result.country).forEach(([key, value]) => {
                countryArray.push({ name: key, count: value });
              });

              countryArray.sort(function (a, b) {
                if (a.count < b.count) {
                  return 1;
                }
                if (a.count > b.count) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              setResumeCountry(countryArray);

              const deviceArray = [];
              Object.entries(result.device).forEach(([key, value]) => {
                deviceArray.push({ name: key, count: value });
              });

              deviceArray.sort(function (a, b) {
                if (a.count < b.count) {
                  return 1;
                }
                if (a.count > b.count) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              setResumeDevice(deviceArray);
            },
            onError: () => {},
          }
        );

        getResumeTracksByContact(
          { user: selectedUser.code },
          {
            onSuccess: (result) => {
              const codeArray = [];
              Object.entries(result.codigo).forEach(([key, value]) => {
                codeArray.push({ name: key, count: value });
              });

              codeArray.sort(function (a, b) {
                if (a.count < b.count) {
                  return 1;
                }
                if (a.count > b.count) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              setResumeCode(codeArray);

              const eventArray = [];
              Object.entries(result.event).forEach(([key, value]) => {
                eventArray.push({ name: eventTranslate(key), count: value });
              });

              eventArray.sort(function (a, b) {
                if (a.count < b.count) {
                  return 1;
                }
                if (a.count > b.count) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              setResumeEvent(eventArray);

              const hrefArray = [];
              Object.entries(result.href).forEach(([key, value]) => {
                hrefArray.push({ name: key, count: value });
              });

              hrefArray.sort(function (a, b) {
                if (a.count < b.count) {
                  return 1;
                }
                if (a.count > b.count) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              setResumeHref(hrefArray);
            },
            onError: () => {},
          }
        );
      }
    }
  }, [subpage]);

  return (
    <>
      {page === globals.PAGE_MAIN && (
        <div>
          <UsersHeader
            title={
              <h4 className="bold special-font big-font">
                {icons.guest} {t("common_clients")}
              </h4>
            }
            primaryAction={<AddUserButton refetch={refetch} initialValues={initialValues} t={t} />}
          />
          <div className="container-subpages">
            <ActionBar
              setFilters={setFilters}
              filters={filters}
              refetch={refetch}
              handleExportExcel={handleExportExcel}
              options={{ actions: ActionsUsers }}
            >
              <SearchUsersForm
                resetFilters={resetFilters}
                refetch={refetch}
                t={t}
                setFilters={setFilters}
                filters={filters}
              />
            </ActionBar>
            <UsersTable
              {...commonProps}
              users={users}
              pagination={pagination}
              setPagination={setPagination}
              loading={loading}
              refetch={refetch}
              initialValues={initialValues}
              setPage={setPage}
              setSelectedUser={setSelectedUser}
              selectedUser={selectedUser}
              handleDetails={userDetails}
              handleEdit={handleEdit}
              handleArchived={handleArchived}
              handleInactive={handleActive}
              details={userDetails}
              t={t}
            />
          </div>
        </div>
      )}
      {page === globals.PAGE_ONE && (
        <div>
          <UserHeader
            subpage={subpage}
            setPage={setPage}
            selectedUser={selectedUser}
            handleDetails={userDetails}
            handleEdit={handleEdit}
            openDeleteModal={handleArchived}
            handleGeneralModal={handleGeneralModal}
            setSubpage={setSubpage}
            extradata={extra}
            tracksWebData={tracksWebData}
            pagesViewData={pagesViewData}
            handleUploadImage={handleUploadImage}
            t={t}
          />

          <div className="container-subpages">
            <TabMenu
              setSubpage={setSubpage}
              t={t}
              numbers={numbers}
              hasAnalytics={instanceStoreRedux.currentInstance.hasAnalytics}
            />

            {subpage === numbers.SEVEN && (
              <div className="subcontainer-wrapper">{userId && <EventsContainer isEmbed="true" userId={userId} />}</div>
            )}

            {subpage === numbers.TWO && instanceStoreRedux.currentInstance.hasAnalytics && (
              <div className="subcontainer-wrapper">
                <PagesViewTable pagesViewData={pagesViewData} />
              </div>
            )}

            {subpage === numbers.THREE && instanceStoreRedux.currentInstance.hasAnalytics && (
              <div className="subcontainer-wrapper">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={6} lg={12} xl={12}>
                    <MetricsCard title="Url" items={resumePageView} />
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={12} xl={12}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <MetricsCard title="Device" items={resumeDevice} styleClass="purple" />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <MetricsCard title="City" items={resumeCity} />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <MetricsCard title="Region" items={resumeRegion} styleClass="purple" />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <MetricsCard title="Country" items={resumeCountry} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )}

            {subpage === numbers.FOUR && instanceStoreRedux.currentInstance.hasAnalytics && (
              <div className="subcontainer-wrapper">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={6} lg={12} xl={12}>
                    <MetricsCard title="Clicks to CTA" items={resumeCode} />
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={12} xl={12}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <MetricsCard title="Href" items={resumeHref} styleClass="purple" />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <MetricsCard title="Events" items={resumeEvent} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )}

            {subpage === numbers.ONE && instanceStoreRedux.currentInstance.hasAnalytics && (
              <div className="subcontainer-wrapper">
                <TracksTable tracksWebData={tracksWebData} />
              </div>
            )}

            {subpage === numbers.FIVE && (
              <>
                <br />
                {userId && <AppContainer isEmbed="true" userId={userId || null} type={packs.SUBSCRIPTION} />}
              </>
            )}

            {subpage === numbers.SIX && (
              <>
                <br />
                {userId && <TransContainer isEmbed="true" userId={userId || null} type={packs.SUBSCRIPTION} />}
              </>
            )}
            {subpage === numbers.EIGHT && (
              <>
                <br />
                {refresh && userId && (
                  <BucketContainer viewType="table" isEmbed="true" userId={userId} entityId={userId} entity="user" />
                )}
              </>
            )}
          </div>
        </div>
      )}

      <ManageUserModal
        refetch={refetch}
        userId={userId}
        setUserId={setUserId}
        closeModal={closeManageUsersModal}
        openModal={isOpenManageUsersModal}
        initialValues={initialValues}
        users={users}
        selectedUser={selectedUser}
        setSelectedUserMain={setSelectedUser}
        setUser={setUser}
        getUser={getUser}
        page={page}
        t={t}
      />

      <ManageBucketModal
        entityProp="User"
        typeProp={userType}
        entityId={userId}
        openModal={isOpenManageBucketModal}
        closeModal={closeManageBucketModal}
      />
    </>
  );
};

export default withRouter(Users);
