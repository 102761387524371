import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Row, Col, Input, Select, Spin } from "antd";
import { saveSupplier, getSupplier } from "../requests";
import { globals, icons } from "../../../constants/globals";

import showGlobalNotification from "../../../helpers/showGlobalNotification";
import BaseModal from "../../shared/components/Modal/BaseModal";
import { setSupplierRedux, reloadRedux as reloadSupplierRedux } from "../../../redux/supplier";

const { Option } = Select;

const defaultValues = {
  fullname: "",
  email: "",
  phone: "",
  address: "",
  category: "",
  active: true,
  public: false,
  price: 0,
  planId: null,
  refererId: null,
  medium: "",
};

const ManageSupplierModal = ({
  supplierId,
  openModal,
  closeModal,
  refetch,
  t,
  setSupplierId,
  page,
  setReload_ = null,
}) => {
  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);
  const [form] = Form.useForm();
  const [selectedSupplier, setSelectedSupplier] = useState(defaultValues);
  const [loaderForm, setLoaderForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [reload, setReload] = useState(0);

  const handleReset = () => {
    setSelectedSupplier(defaultValues);
    setReload(Math.floor(Math.random() * 100000));
  };

  useEffect(() => {
    if (openModal) {
      if (supplierId) {
        setMode("edit");
      } else {
        setMode("new");
      }
    } else {
      handleReset();
      setMode(null);
      if (page !== globals.PAGE_ONE) setSupplierId(null);
    }
  }, [openModal]);

  useEffect(() => {
    if (mode === "new") {
      handleReset();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      setLoaderForm(true);
      getSupplier(supplierId, {
        onSuccess: (response) => {
          setSupplierRedux(response.result)(dispatch);
          setSelectedSupplier({
            ...response.result,
          });
          setLoaderForm(false);
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  }, [mode]);

  useEffect(() => {
    form.setFieldsValue(selectedSupplier);
  }, [form, selectedSupplier]);

  const handleCloseModal = () => {
    refetch();
    closeModal();
  };

  const onFinish = (values) => {
    const data = { ...selectedSupplier, ...values };
    saveSupplier(
      {
        ...data,
        supplierId,
        rootId: sessionStoreRedux.currentSession_id,
      },
      {
        onSuccess: (response) => {
          reloadSupplierRedux()(dispatch);
          setSupplierRedux(response.usuario)(dispatch);
          if (setReload_) setReload_(Math.floor(Math.random() * 100000));
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => handleCloseModal(),
      }
    );
  };

  const callback = (key) => {
    console.log(key);
  };

  const onValuesChange = (values) => {
    setSelectedSupplier({ ...selectedSupplier, ...values });
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  return (
    <BaseModal
      isOpenModal={openModal}
      closeModal={handleCloseModal}
      onCancel={handleCloseModal}
      onConfirm={() => handleSubmit()}
      title={supplierId ? `${t("edit")} ${t("supplier")}` : `${t("new")} ${t("supplier")}`}
      width={1000}
      confirmText={t("save")}
      isCloseModal
      top={10}
      icon={icons.edit}
    >
      <Form
        form={form}
        name="manage-category-form-modal"
        initialValues={defaultValues}
        className="manage-modal"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        onValuesChange={onValuesChange}
      >
        <div className="layout-form-modal entity-450 entity-small">
          {loaderForm ? (
            <>
              <Spin tip={t("loading")} />
            </>
          ) : (
            <>
              <Row gutter={16}>
                <Col span={24} xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="fullname"
                    tooltip="Nombre"
                    label="Nombre"
                    rules={[
                      {
                        required: true,
                        message: `Por favor ingrese el nombre completo`,
                      },
                    ]}
                  >
                    <Input placeholder="" value={selectedSupplier.fullname} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item
                    name="email"
                    label={t("email")}
                    rules={[{ required: true, message: `Por favor ingrese el email` }]}
                  >
                    <Input value={selectedSupplier.email} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item
                    tooltip="Es importante mantener el formato Ejemplo: +573186855543, para el correcto envio de los mensajes de texto"
                    help="Formato: +573186855543"
                    name="phone"
                    label={t("phone")}
                  >
                    <Input style={{ width: "100%" }} value={selectedSupplier.phone} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="secondaryphone" label="Teléfono secundario">
                    <Input autocomplete="off" placeholder="" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                  <Form.Item name="documentType" label={t("documentType")}>
                    <Select className="select-form-modal">
                      <Option value="Cédula de Ciudadanía">Cédula de Ciudadanía</Option>
                      <Option value="Tarjeta de Identidad">Tarjeta de Identidad</Option>
                      <Option value="Cédula de Extranjería">Cédula de Extranjería</Option>
                      <Option value="Pasaporte">Pasaporte</Option>
                      <Option value="Permiso Especial (PEP)">Permiso Especial (PEP)</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item name="document" label={t("document")}>
                    <Input value={selectedSupplier.document} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                  <Form.Item help="Amistad, Red Social, Tv, Radio" name="medium" label="Como nos conoció?">
                    <Select className="select-form-modal">
                      <Option value="Instagram">Instagram</Option>
                      <Option value="Facebook">Facebook</Option>
                      <Option value="Blog">Blog</Option>
                      <Option value="Amistad">Amistad</Option>
                      <Option value="Google">Google</Option>
                      <Option value="Youtube">Youtube</Option>
                      <Option value="Fachada">Fachada</Option>
                      <Option value="Folleto Publicitario">Folleto Publicitario</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="address" label={t("address")}>
                    <Input value={selectedSupplier.address} />
                  </Form.Item>
                </Col>
                <Col span={4} xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item name="city" label={t("city")}>
                    <Input value={selectedSupplier.city} />
                  </Form.Item>
                </Col>
                <Col span={4} xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item name="region" label={t("region")}>
                    <Input value={selectedSupplier.region} />
                  </Form.Item>
                </Col>
                <Col span={4} xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item name="country" label={t("country")}>
                    <Input value={selectedSupplier.country} />
                  </Form.Item>
                </Col>
              </Row>{" "}
              <Row gutter={16}>
                <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="observation" label={t("observation")}>
                    <Input value={selectedSupplier.observation} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Form>
    </BaseModal>
  );
};

ManageSupplierModal.defaultProps = {
  setSupplierId: () => {},
};

export default ManageSupplierModal;
