import { useState } from "react";
import { Typography, Space } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import {
  LandingPageValidadoCompleto,
  LandingPageValidadoCompletoConPlanes,
  LandingPageNoValidadoCompleto,
  QRLandingPageValidadoCompleto,
  QRLandingPageValidadoCompletoConPlanes,
} from "../../../constants/globals";

const { Link } = Typography;

const ListPlans = ({ packInfo, currentInstance, currentSession }) => {
  const [selected] = useState(0);

  const styleCard = (id = "") => {
    if (id === selected) return { border: "1px solid #c5c5c5", padding: 10, borderRadius: 7, background: "#caf6b5" };
    return {
      border: "1px solid #c5c5c5",
      marginTop: 20,
      fontSize: 18,
      padding: 10,
      borderRadius: 7,
      background: "#f1f1f1",
    };
  };

  return (
    <>
      <div style={styleCard()}>
        {packInfo.kind === "event" && (
          <>
            <h2>{packInfo.name}</h2>
            <b>Formulario VALIDADO con CALENDARIO </b>
            <br />
            <Link
              style={{ fontSize: 18, paddingBottom: 15 }}
              onClick={() =>
                window.open(
                  LandingPageValidadoCompleto({
                    packId: packInfo._id,
                    alias: currentInstance.alias,
                    refererId: currentSession._id,
                  })
                )
              }
            >
              Ir al landing page
            </Link>
            &nbsp; &nbsp;| &nbsp; &nbsp;
            <Link
              onClick={() =>
                window.open(
                  QRLandingPageValidadoCompleto({
                    packId: packInfo._id,
                    alias: currentInstance.alias,
                    refererId: currentSession._id,
                    logo: currentInstance.logo,
                    avatar: currentSession.avatar,
                  })
                )
              }
              style={{ fontSize: 18, paddingBottom: 15 }}
            >
              Generar QR
            </Link>
            <ul>
              <li>
                <CheckCircleOutlined style={{ color: "green" }} /> Selección de fechas por calendario
              </li>
              <li>
                <CheckCircleOutlined style={{ color: "green" }} /> SI registra de pago
              </li>
              <li>
                <CheckCircleOutlined style={{ color: "green" }} /> SI esta asociada a un referido
              </li>
              <li>
                <CheckCircleOutlined style={{ color: "green" }} /> SI confirma la aplicación
              </li>
            </ul>{" "}
            <b> Formulario VALIDADO con PLANES </b> <br />
            <Space>
              <Link
                onClick={() =>
                  window.open(
                    LandingPageValidadoCompletoConPlanes({
                      packId: packInfo._id,
                      alias: currentInstance.alias,
                      refererId: currentSession._id,
                    })
                  )
                }
                style={{ fontSize: 18, paddingBottom: 15 }}
              >
                Ir al landing page
              </Link>
              &nbsp; &nbsp;| &nbsp; &nbsp;
              <Link
                onClick={() =>
                  window.open(
                    QRLandingPageValidadoCompletoConPlanes({
                      packId: packInfo._id,
                      alias: currentInstance.alias,
                      refererId: currentSession._id,
                      logo: currentInstance.logo,
                      avatar: currentSession.avatar,
                    })
                  )
                }
                style={{ fontSize: 18, paddingBottom: 15 }}
              >
                Generar QR
              </Link>
            </Space>
            <ul>
              <li>
                <CheckCircleOutlined style={{ color: "green" }} /> Selección de eventos ya definidos
              </li>
              <li>
                <CheckCircleOutlined style={{ color: "green" }} /> SI registra de pago
              </li>
              <li>
                <CheckCircleOutlined style={{ color: "green" }} /> SI esta asociada a un referido
              </li>
              <li>
                <CheckCircleOutlined style={{ color: "green" }} /> SI confirma la aplicación
              </li>
            </ul>
            <Link
              target="blank"
              style={{ fontSize: 18, paddingBottom: 15 }}
              onClick={() =>
                window.open(
                  LandingPageNoValidadoCompleto({
                    packId: packInfo._id,
                    alias: currentInstance.alias,
                    refererId: currentSession._id,
                  })
                )
              }
            >
              WEBSITE NO VALIDADO con Calendario
            </Link>
            <ul>
              <li>
                <CheckCircleOutlined style={{ color: "green" }} /> Selección de fechas por calendario
              </li>
              <li>
                <CheckCircleOutlined style={{ color: "red" }} /> NO registra un pago
              </li>
              <li>
                <CheckCircleOutlined style={{ color: "red" }} /> NO esta asociada referido
              </li>
              <li>
                <CheckCircleOutlined style={{ color: "red" }} /> NO confirma la aplicación
              </li>
            </ul>
          </>
        )}
        <br />
      </div>
    </>
  );
};

export default ListPlans;
