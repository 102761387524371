/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from "react";
import { Line } from "@ant-design/charts";

import moment from "moment";

const DemoLine = ({ json, json2, json3 }) => {
  const [data, setData] = useState([]);

  const config = {
    data,
    xField: "type",
    yField: "value",
    seriesField: "name",
    legend: {
      position: "top",
    },
    // smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };

  useEffect(() => {
    const login = [];
    json.forEach(function (item) {
      login[item.fecha] = (login[item.fecha] || 0) + 1;
    });

    const login_ = [];
    for (const prop in login) {
      login_.push({ type: prop, value: login[prop], name: "Registros" });
    }

    if (json2 && json2.length > 0) {
      const login2 = [];
      let fecha = "";
      json2.forEach(function (item) {
        fecha = moment(item.creartedAt).format("DD/MM/YYYY");
        login2[fecha] = (login2[fecha] || 0) + 1;
      });

      for (const prop in login2) {
        login_.push({ type: prop, value: login2[prop], name: "Renovaciones" });
      }
    }

    if (json3 && json3.length > 0) {
      const login3 = [];
      let fecha2 = "";
      json3.forEach(function (item) {
        fecha2 = moment(item.creartedAt).format("DD/MM/YYYY");
        login3[fecha2] = (login3[fecha2] || 0) + 1;
      });

      for (const prop2 in login3) {
        login_.push({ type: prop2, value: login3[prop2], name: "Finalizaciones" });
      }
    }

    setData(login_.sort((a, b) => moment(b.type, "DD/MM/YYYY") - moment(a.type, "DD/MM/YYYY")));

    console.log(login_.sort((a, b) => moment(a.type, "DD/MM/YYYY") - moment(b.type, "DD/MM/YYYY")));
  }, [json, json2, json3]);

  return <Line {...config} />;
};

export default DemoLine;
