import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Row, Col, Input, Select, Spin, DatePicker, Tabs, InputNumber } from "antd";
import { saveUserExtra, getUser } from "../requests";
import { saveApp } from "../../subscribersApps/requests";
import { globals, icons } from "../../../constants/globals";

import showGlobalNotification from "../../../helpers/showGlobalNotification";
import BaseModal from "../../shared/components/Modal/BaseModal";
import { setUserRedux, reloadRedux as reloadUserRedux } from "../../../redux/user";

const moment = require("moment");

const { TabPane } = Tabs;

const { Option } = Select;

const defaultValues = {
  chest: "0",
  arm: "0",
  forearm: "0",
  leg: "0",
  calf: "0",
  waist: "0",
  neck: "0",
  hips: "0",
  weight: "0",
  height: "0",
  date: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
};

const config = {
  rules: [
    {
      type: "object",
      required: true,
      message: "Please select time!",
    },
  ],
};

const ManageUserModal = ({
  userId,
  userExtraId,
  openModal,
  closeModal,
  refetch,
  t,
  setUserId,
  setUserExtraId,
  page,
  setReload_ = null,
}) => {
  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);
  const [form] = Form.useForm();
  const [selectedUser, setSelectedUser] = useState(defaultValues);
  const [loaderForm, setLoaderForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [reload, setReload] = useState(0);

  const handleReset = () => {
    setSelectedUser(defaultValues);
    setReload(Math.floor(Math.random() * 100000));
  };

  useEffect(() => {
    if (openModal) {
      if (userExtraId) {
        setMode("edit");
      } else {
        setMode("new");
      }
    } else {
      handleReset();
      setMode(null);
      if (page !== globals.PAGE_ONE) setUserExtraId(null);
    }
  }, [openModal]);

  useEffect(() => {
    if (mode === "new") {
      handleReset();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      setLoaderForm(true);
      getUser(userId, {
        onSuccess: (response) => {
          setUserRedux(response.user)(dispatch);
          setSelectedUser({
            ...response.user,
            date: moment(response.user.startdate, "YYYY/MM/DD") || moment("2020/12/12", "YYYY/MM/DD"),
            password: "",
          });
          setLoaderForm(false);
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  }, [mode]);

  useEffect(() => {
    form.setFieldsValue(selectedUser);
  }, [form, selectedUser]);

  const handleCloseModal = () => {
    refetch();
    closeModal();
  };

  const onFinish = (values) => {
    const data = { ...selectedUser, ...values };
    saveUserExtra(
      {
        ...data,
        userId,
        userExtraId,
        rootId: sessionStoreRedux.currentSession_id,
      },
      {
        onSuccess: (response) => {
          reloadUserRedux()(dispatch);
          setUserRedux(response.usuario)(dispatch);
          if (setReload_) setReload_(Math.floor(Math.random() * 100000));

          if (data.planId) {
            saveApp(
              {
                ...data,
                userId,
                rootId: sessionStoreRedux.currentSession_id,
              },
              {
                onSuccess: () => {
                  showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
                },
                onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
                onFinally: () => {
                  handleCloseModal();
                },
              }
            );
          }
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => handleCloseModal(),
      }
    );
  };

  const callback = (key) => {
    console.log(key);
  };

  const onValuesChange = (values) => {
    setSelectedUser({ ...selectedUser, ...values });
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  return (
    <BaseModal
      isOpenModal={openModal}
      closeModal={handleCloseModal}
      onCancel={handleCloseModal}
      onConfirm={() => handleSubmit()}
      title={userId ? `${t("edit")} ${t("contact")}` : `${t("new")} ${t("contact")}`}
      width={1000}
      confirmText={t("save")}
      isCloseModal
      top={10}
      icon={icons.edit}
    >
      <Form
        form={form}
        name="manage-category-form-modal"
        initialValues={defaultValues}
        className="manage-modal"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        onValuesChange={onValuesChange}
      >
        <div className="layout-form-modal entity-450 entity-small">
          {loaderForm ? (
            <>
              <Spin tip={t("loading")} />
            </>
          ) : (
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="Datos Generales" key="1">
                <>
                  <Row gutter={16}>
                    <Col span={12} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item name="date" label={t("date")} {...config}>
                        <DatePicker format="YYYY/MM/DD" />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item label="Peso" name="weight">
                        <InputNumber />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item label="Altura" name="height">
                        <InputNumber />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item label="Pecho" name="chest">
                        <InputNumber />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item label="Brazo" name="arm">
                        <InputNumber />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item label="Antebrazo" name="forearm">
                        <InputNumber />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item label="Pierna" name="leg">
                        <InputNumber />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item label="Pantorrilla" name="calf">
                        <InputNumber />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item label="Cintura" name="waist">
                        <InputNumber />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item label="Cuello" name="neck">
                        <InputNumber />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item label="Cadera" name="hips">
                        <InputNumber />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              </TabPane>

              {/* } <TabPane tab="Informacion adicional" key="2">

chest
arm
forearm
leg
calf
waist
neck
hips

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="bodylesson" label="Alguna lesión en el cuerpo">
                      <Input autocomplete="off" placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="alergies" label="Alguna alergía">
                      <Input autocomplete="off" placeholder="" />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane> */}
            </Tabs>
          )}
        </div>
      </Form>
    </BaseModal>
  );
};

ManageUserModal.defaultProps = {
  setUserId: () => {},
};

export default ManageUserModal;
