import { useState } from "react";

import { Row, Col, Card, Tag, Space, Button, Tooltip, Radio, Badge } from "antd";
import { withRouter } from "react-router-dom";

import moment from "moment";

import {
  dateFormat6,
  dateFormat5,
  STATUS_APP,
  icons,
  colorsSystem,
  cutWords,
  priceFormat,
  clasificationFormat,
  dateFormatYYYYMMDD,
  colorKind,
  validStateApp,
  STATUS_APP_BY_DATE,
  modules,
  colorNetoKind,
} from "../../../constants/globals";

const Cards = ({
  tab,
  apps,
  handleCheckin,
  handleOpenUser,
  handleActivateApp,
  handleDesactivateApp,
  handleActivateExpressApp,
  height = "310px",
  isPending = false,
}) => {
  const [filterApp, setFilterApp] = useState("open");

  const style_ = (item) => {
    let vars__ = { width: 260, height, marginTop: 30, marginBottom: 15 };

    if (item?.packId?.clasification === "form") {
      vars__ = { ...vars__, height: 450 };
    }

    if (item.status === "cancel") return { ...vars__, border: `7px solid ${colorsSystem.black}` };
    // if (item.isPending) return { ...vars__, border: `1px solid ${colorsSystem.red}` };

    return vars__;
  };

  const cards = [];

  const checkinToday = (item) => {
    let result = "";
    if (item.checkins.length > 0) {
      if (
        moment().diff(moment(String(item.checkins[item.checkins.length - 1]).substr(0, 10), "DD/MM/YYYY"), "days") === 0
      ) {
        result = (
          <span
            style={{
              background: colorsSystem.red,
              color: colorsSystem.white,
              borderRadius: 7,
              padding: 1,
              fontWeight: 600,
            }}
          >
            . HOY ASISTIO .
          </span>
        );
      }
    }

    return result;
  };

  const onChangeFilterTab = (e) => e.target && e.target.value && setFilterApp(e.target.value);

  const checkinToday2 = (item) => {
    if (item.checkins.length > 0) {
      if (
        moment().diff(moment(String(item.checkins[item.checkins.length - 1]).substr(0, 10), "DD/MM/YYYY"), "days") === 0
      ) {
        return true;
      }
    }
    return false;
  };

  const limitCheckin = (item) => {
    if (item.planId?.limitCheckin && item.planId?.limitCheckin < 1000) {
      if (item.planId?.limitCheckin < item.checkins.length) {
        return (
          <>
            <br />
            <span style={{ background: "red", color: "#fff", fontSize: 14, padding: 4, borderRadius: 5 }}>
              {" "}
              {`Limite excedido! (${item.checkins.length}/${item.planId?.limitCheckin})`}
            </span>
          </>
        );
      }
      return (
        <>
          <br />
          {`Limite de asistencias: ${item.planId?.limitCheckin}`}
        </>
      );
    }
    return "";
  };

  const isLimitCheckin = (item) => {
    if (item.planId?.limitCheckin && item.planId?.limitCheckin < 1000) {
      if (item.planId?.limitCheckin < item.checkins.length) {
        return false;
      }
      return true;
    }
    return true;
  };

  const isPastDate = (item) => {
    const SpecialTo = moment(item.closureDate, "YYYY-MM-DD");
    if (moment().diff(SpecialTo, "days") > 0) {
      return false;
    }
    return true;
  };

  const status__ = (app) => {
    const result = validStateApp(app);
    return (
      <div style={{ textAlign: "center", marginTop: 10 }}>
        {result === STATUS_APP_BY_DATE.ISCLOSED && (
          <Tag style={{ fontSize: 14, fontWeight: 800 }} color={colorsSystem.blue}>
            Finalizada
          </Tag>
        )}

        {result === STATUS_APP_BY_DATE.ISPENDING && (
          <Tag style={{ fontSize: 14, fontWeight: 800 }} color="volcano">
            {icons.clock} En Espera
          </Tag>
        )}

        {result === STATUS_APP_BY_DATE.ISPROGRESS && (
          <Tag style={{ fontSize: 12, fontWeight: 800 }} color="green">
            En progreso
          </Tag>
        )}

        {result === STATUS_APP_BY_DATE.ISSTARTING && (
          <Tag style={{ fontSize: 12, fontWeight: 800 }} color="green">
            Comienza a futuro
          </Tag>
        )}

        {result === STATUS_APP_BY_DATE.ISENDING && (
          <Tag style={{ fontSize: 12, fontWeight: 800 }} color={colorsSystem.red}>
            Por culminar
          </Tag>
        )}

        {result === STATUS_APP_BY_DATE.ISCANCELED && <Tag color="error">Cancelada</Tag>}

        <div style={{ textAlign: "left", marginTop: "-7px", marginLeft: 10 }}>
          <Space>
            {app.packId?.kind === "subscription" &&
              app?.status === STATUS_APP.OPEN &&
              isPastDate(app) &&
              isLimitCheckin(app) && (
                <Button
                  className="ant-btn secondary primary fill"
                  size="small"
                  style={{ marginLeft: 10 }}
                  onClick={() => handleCheckin(app, checkinToday2(app))}
                >
                  ASISTENCIA
                </Button>
              )}

            {app?.status !== STATUS_APP.INWAIT && (
              <Button className="ant-btn secondary primary" size="small" onClick={() => handleOpenUser(app)}>
                ABRIR
              </Button>
            )}
          </Space>
        </div>
      </div>
    );
  };

  const filterApps = (data) => {
    const res = [];

    if (isPending) {
      return data;
    }
    if (filterApp === "close") {
      data?.forEach((element) => {
        if (element.status === "close" && element.isClosed === true) {
          res.push(element);
        }
      });
    } else if (filterApp === "cancel") {
      data?.forEach((element) => {
        if (element.status === "cancel" && element.isCanceled === true) {
          res.push(element);
        }
      });
    } else if (filterApp === "open") {
      data?.forEach((element) => {
        if ((element.status === "open" || element.status === "inwait") && element.isProgress === true) {
          res.push(element);
        }
      });
    } else if (filterApp === "all") {
      return data;
    }

    return res;
  };

  const borderColor = (item) => (item?.planId?.isMarker ? item.planId.color : colorNetoKind(item.packId.kind));
  const cartas = () =>
    apps &&
    apps.length > 0 &&
    filterApps(apps).map((item, index) => (
      <Col className="gutter-row" span={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Badge offset={[0, 0]} count={item.userId.activeApps > 1 ? item.userId.activeApps : 0}>
          <Card className="usermagico shadow" hoverable style={style_(item)}>
            <sup style={{ position: "absolute", top: "-14px", color: "gray", left: "0px", fontSize: 10 }}>
              {item?.code} | {item?.correlative}
            </sup>
            <h3
              style={{
                background: "#1b203e",
                padding: 2,
                lineHeight: "16px",
                fontSize: 20,
                fontWeight: "600",
                color: "#fff",
                paddingTop: 7,
                paddingLeft: 10,
                paddingBottom: 7,
                textAlign: "left",
                borderLeft: `20px solid ${borderColor(item)}`,
              }}
            >
              <small style={{ fontSize: 14, fontWeight: "600" }}>
                {clasificationFormat(item?.packId?.clasification)}
              </small>
              <br />
              <small style={{ fontSize: 18, letterSpacing: 1, fontWeight: "600" }}>{item?.packId?.name}</small>

              <br />
              {(item?.kind === "subscription" || item?.kind === "event") && (
                <small className="special-font" style={{ fontSize: 14, fontWeight: "600" }}>
                  {cutWords(item?.planId?.name)}
                  <br />
                </small>
              )}
              {item?.kind === "product" && `${item.quantity} x `}
            </h3>
            <p
              style={{
                fontSize: 13,
                paddingLeft: 10,
                color: "#3a3a3a",
                margin: 0,
                marginTop: -5,
                marginBottom: 5,
                textTransform: "uppercase",
              }}
            >
              {item?.kind === modules.EVENT && (
                <>
                  <div style={{ height: 50, textAlign: "center" }}>
                    <span className="special-font apps-card-username">
                      {cutWords(`${item?.userId.firstname} ${item?.userId.lastname}`, 35)}
                    </span>
                  </div>
                  <hr />
                  <small>
                    {item?.refererRootId?._id
                      ? `by ${item.refererRootId?.firstname} ${item.refererRootId?.lastname || ""}`
                      : "by Website"}{" "}
                  </small>
                  <br />
                  {!item?.planId?.isFree && (
                    <>
                      Costo: {priceFormat(item.total)} <br />
                      {item.isPayment ? (
                        <b>{icons.check_enabled} Pagada</b>
                      ) : (
                        <>
                          <Tag color={colorsSystem.red}>En Deuda: {priceFormat(item.amount)}</Tag>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {item?.kind === modules.SUBSCRIPTION && (
                <>
                  <div style={{ height: 50, textAlign: "center" }}>
                    <span className="special-font apps-card-username">
                      {cutWords(`${item?.userId.firstname} ${item?.userId.lastname}`, 35)}
                    </span>
                  </div>
                  <hr />
                  {icons.calendar} {dateFormat6(item.startDate)} - {dateFormat6(item.closureDate)}
                  {!item.planId.isTemporal && item?.status === STATUS_APP.OPEN && (
                    <>
                      <br />
                      <span>
                        {item?.checkins?.length} Asistencias {checkinToday(item)}{" "}
                      </span>
                      <span style={{ color: colorsSystem.purple, fontWeight: "bold" }}>{limitCheckin(item)}</span>
                    </>
                  )}
                </>
              )}

              {item?.kind === modules.PRODUCT && (
                <>
                  <div style={{ height: 50, textAlign: "center" }}>
                    <span className="special-font apps-card-username">
                      {cutWords(`${item?.userId.firstname} ${item?.userId.lastname}`, 25)}
                    </span>
                  </div>
                  <hr />
                  {icons.dolar}&nbsp; {item.quantity} x {priceFormat(item.planId.price)}
                  <br /> {icons.calendar}&nbsp; {dateFormatYYYYMMDD(item.planId.startdate)}
                </>
              )}

              {item?.kind === modules.RENTAL && (
                <>
                  <div style={{ height: 50, textAlign: "center" }}>
                    <span className="special-font apps-card-username">
                      {cutWords(`${item?.userId.firstname} ${item?.userId.lastname}`, 25)}
                    </span>
                  </div>
                  <hr />
                  {icons.dolar}&nbsp; {item.quantity} x {priceFormat(item.planId.price)} ={" "}
                  <span style={{ fontSize: 16, color: colorsSystem.yellow }}>{priceFormat(item.transId.total)}</span>
                  <br /> {icons.calendar}&nbsp;{item.planId.startdate} &nbsp;&nbsp;&nbsp;
                  {icons.clock} &nbsp;{item.planId.departureTime}
                </>
              )}
            </p>

            {status__(item)}

            {item?.status === STATUS_APP.INWAIT && (
              <div style={{ textAlign: "center", marginTop: 10 }}>
                {item?.packId?.clasification === "form" && (
                  <p className="box-warning" style={{ lineHeight: "14px", fontSize: "11px" }}>
                    <b>
                      {icons.clock} {item?.hora}:
                    </b>{" "}
                    {item?.observation}
                  </p>
                )}
                <Space>
                  <Button className="ant-btn secondary info" size="small" onClick={() => handleOpenUser(item)}>
                    ABRIR
                  </Button>
                  <Button
                    className="ant-btn secondary primary "
                    size="small"
                    onClick={() => handleActivateExpressApp(item)}
                  >
                    CONFIRMAR
                  </Button>

                  <Tooltip title="Cancelar aplicación">
                    <Button className="ant-btn secondary error" size="small" onClick={() => handleDesactivateApp(item)}>
                      {icons.close}
                    </Button>
                  </Tooltip>
                </Space>
                <br />
              </div>
            )}
          </Card>
        </Badge>
      </Col>
    ));

  return (
    <>
      <Row gutter={32}>
        {!isPending && (
          <Col span={24}>
            <Radio.Group defaultValue={filterApp} onChange={onChangeFilterTab}>
              <Radio.Button style={{ background: "#fff", color: "#000" }} value="all">
                Ver todo
              </Radio.Button>
              <Radio.Button style={{ background: "#fff", color: "#000" }} value="open">
                Activas
              </Radio.Button>
              <Radio.Button style={{ background: "#fff", color: "#000" }} value="close">
                Finalizadas
              </Radio.Button>
              <Radio.Button style={{ background: "#fff", color: "#000" }} value="cancel">
                Canceladas
              </Radio.Button>
            </Radio.Group>
          </Col>
        )}
        {cartas()}
        {apps && apps?.length === 0 && (
          <p style={{ textAlign: "center", marginTop: 30, width: "100%" }}>No se encontraron usuarios</p>
        )}
      </Row>
    </>
  );
};

export default withRouter(Cards);
