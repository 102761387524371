import { archivedEvents, duplicateEvents } from "../requests";

import { colorsSystem } from "../../../constants/globals";

const Swal = require("sweetalert2");

export const handleArchivedConfirm = ({ event, t, refetch }) => {
  archivedEvents(
    {
      event,
    },
    {
      onSuccess: () => {
        refetch();
        Swal.fire(t("common_done"), t("common_event_confirmed"), "success");
      },
      onError: () =>
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        }),
      onFinally: () => {},
    }
  );
};

export const handleArchivedEvent = ({ event, t, refetch }) => {
  Swal.fire({
    title: t("common_areYouSure"),
    text: t("common_cantRevert"),
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: colorsSystem.red,
    cancelButtonColor: colorsSystem.blue,
    confirmButtonText: t("common_yesIWantContinueDelete"),
    cancelButtonText: t("common_cancel"),
  }).then((result) => {
    if (result.isConfirmed) {
      handleArchivedConfirm({ event, t, refetch });
    }
  });
};

export const handleDuplicateConfirm = ({ event, t, refetch }) => {
  duplicateEvents(
    {
      event,
    },
    {
      onSuccess: () => {
        refetch();
        Swal.fire(t("common_done"), t("common_event_confirmed"), "success");
      },
      onError: () =>
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        }),
      onFinally: () => {},
    }
  );
};

export const handleDuplicateEvent = ({ event, t, refetch }) => {
  Swal.fire({
    title: t("common_areYouSure"),
    text: "Esta seguro que desea duplicar el registro?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: colorsSystem.red,
    cancelButtonColor: colorsSystem.blue,
    confirmButtonText: "Lo confirmo",
    cancelButtonText: t("common_cancel"),
  }).then((result) => {
    if (result.isConfirmed) {
      handleDuplicateConfirm({ event, t, refetch });
    }
  });
};
