import { Row, Col, Tag, Card, Badge, Button, Space, Spin, Tooltip } from "antd";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

import { dateFormat4, icons } from "../../../constants/globals";

import { handleSendClosureEmailPlan } from "../functions";

const PlansCard = ({ plans, handleArchivedPlan, handleClosePlanByGuide, filters, refetch, history }) => {
  const { t } = useTranslation();
  const instanceStoreRedux = useSelector((store) => store.instance);

  const cartas = () => {
    if (filters.status === "progress") {
      return plans?.map(
        (item) =>
          moment().diff(moment(item.closuredate, "YYYY/MM/DD"), "days") <= 20 &&
          item.packId.kind === "event" &&
          item.status === "open" && (
            <Col className="gutter-row" xs={24} sm={12} md={12} lg={6} xl={6}>
              <a href="#!">
                <Card hoverable className="shadow plans-card-open">
                  <>
                    <span style={{ fontSize: 22, color: "#343a79" }}>
                      <span className="big-font special-font bold">{item?.totalApps} </span>x {item?.packId?.name}{" "}
                    </span>
                    <br />
                    <span style={{ color: "#343a79" }}>
                      {icons.calendar} {item?.name}
                    </span>
                    <br />
                    <span style={{ color: "#343a79" }}>
                      {icons.guest} Capacidad: {item?.maxLimit}
                    </span>

                    <div style={{ marginTop: 20 }}>
                      <Space>
                        <Badge style={{ color: "#fff" }} count={item.pending}>
                          <Button
                            style={{ color: "#fff" }}
                            size="small"
                            className="ant-btn secondary primary "
                            onClick={() => {
                              history.push(`/event/${item._id}`);
                            }}
                          >
                            Abrir
                          </Button>
                        </Badge>

                        {item.images > 0 && (
                          <Badge style={{ color: "#fff" }} count={item.images}>
                            <Button
                              style={{ color: "#fff" }}
                              size="small"
                              className="ant-btn secondary info fill"
                              onClick={() =>
                                window.open(`https://d28850bg6enx1h.cloudfront.net/app_s4.html?u=60dbe7e51a9233bd8b0a9dc0&q=${item.packId._id}&agency=${instanceStoreRedux.currentInstance.alias}&type=suscriber&p=${item._id}&fclt=web&utmc=web&raId=null&ruId=null#
                            `)
                              }
                            >
                              {icons.foto}
                            </Button>
                          </Badge>
                        )}

                        <Tooltip
                          title={`Eliminar este evento ${item.name}, !importante. Esta eliminación no se recupera`}
                        >
                          <Button
                            size="small"
                            className="ant-btn secondary error fill"
                            onClick={() => handleArchivedPlan({ plan: item, t, refetch, planInfo: item })}
                          >
                            {icons.delete}
                          </Button>
                        </Tooltip>
                      </Space>
                    </div>
                  </>
                </Card>
              </a>
            </Col>
          )
      );
    }
    if (filters.status === "close") {
      return plans?.map(
        (item) =>
          item.status === "close" && (
            <Col className="gutter-row" xs={24} sm={12} md={12} lg={6} xl={6}>
              <a href="#!">
                <Card hoverable className="shadow plans-card-close">
                  <>
                    <span style={{ fontSize: 22, color: "#343a79" }}>
                      <span className="big-font special-font bold"> {item?.closed} </span>x {item?.packId?.name}{" "}
                    </span>
                    <br /> {icons.calendar} {item?.name}
                    <div style={{ marginTop: 10 }}>
                      {item.status === "close" && (
                        <Tooltip title={`Finalizado por ${item.closeBy}  ${dateFormat4(item.closeAt)}  `}>
                          <Tag color="volcano">Finalizado</Tag>
                        </Tooltip>
                      )}
                      {item.closureEmailDate && (
                        <Tag color="green">
                          <Tooltip title={dateFormat4(item.closureEmailDate)}>
                            {icons.email} &nbsp; Fotos enviadas
                          </Tooltip>
                        </Tag>
                      )}
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <Space>
                        <Tooltip title="Ver más detalles">
                          <Badge style={{ color: "#fff" }} count={item.pending}>
                            <Button
                              style={{ color: "#fff" }}
                              size="small"
                              className="ant-btn secondary primary "
                              onClick={() => {
                                history.push(`/event/${item._id}`);
                              }}
                            >
                              Abrir2
                            </Button>
                          </Badge>
                        </Tooltip>

                        {item.images > 0 && (
                          <Tooltip title="Ver las fotografías">
                            <Badge style={{ color: "#fff" }} count={item.images}>
                              <Button
                                style={{ color: "#fff" }}
                                size="small"
                                className="ant-btn secondary info fill"
                                onClick={() =>
                                  window.open(`https://d28850bg6enx1h.cloudfront.net/app_s4.html?u=60dbe7e51a9233bd8b0a9dc0&q=${item.packId._id}&agency=${instanceStoreRedux.currentInstance.alias}&type=suscriber&p=${item._id}&fclt=web&utmc=web&raId=null&ruId=null#
                            `)
                                }
                              >
                                {icons.foto}
                              </Button>
                            </Badge>
                          </Tooltip>
                        )}

                        <Tooltip title="Enviar fotografías a los asistentes">
                          <Button
                            size="small"
                            className="ant-btn secondary info fill"
                            onClick={() =>
                              handleSendClosureEmailPlan(
                                { plan: item, t, refetch, planInfo: item },
                                { onSuccess: () => {} }
                              )
                            }
                          >
                            {icons.email}
                          </Button>
                        </Tooltip>
                        <Tooltip title={`Eliminar este plan ${item.name}`}>
                          <Button
                            size="small"
                            className="ant-btn secondary error fill"
                            onClick={() => handleArchivedPlan({ plan: item, t, refetch, planInfo: item })}
                          >
                            {icons.delete}
                          </Button>
                        </Tooltip>
                      </Space>
                    </div>
                  </>
                </Card>
              </a>
            </Col>
          )
      );
    }
  };

  return (
    <Row gutter={32}>
      {cartas()}
      {plans.length === 0 && (
        <p style={{ textAlign: "center", marginTop: 30, width: "100%" }}>
          <Spin />
        </p>
      )}
    </Row>
  );
};

export default withRouter(PlansCard);
