import { Typography, Card } from "antd";

const { Link } = Typography;

const CardMain = ({ title, description }) => {
  console.log("");
  return (
    <Card title={title} style={{ width: 300 }}>
      {description}
    </Card>
  );
};

export default CardMain;
