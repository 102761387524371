import { useState } from "react";
import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";

const FiltersTable = ({ setFilters, filters, handleNew }) => {
  const { t } = useTranslation();

  const [active, setActive] = useState(true);

  const handleActiveSelected = (activeSelected) => {
    setActive(activeSelected);
    setFilters({ ...filters, active: activeSelected });
  };

  return (
    <>
      <span className="fieldset-consolidate-filters">
        <Space>
          <Button
            className="tag-button-table"
            size="small"
            type={active === "" ? "primary" : "default"}
            onClick={() => handleActiveSelected("")}
          >
            {t("all")}
          </Button>
          <Button
            className="tag-button-table"
            size="small"
            type={active === true ? "primary" : "default"}
            onClick={() => handleActiveSelected(true)}
          >
            {t("active")}
          </Button>
          <Button
            className="tag-button-table"
            size="small"
            type={active !== true && active !== "" ? "primary" : "default"}
            onClick={() => handleActiveSelected(false)}
          >
            {t("inactive")}
          </Button>
        </Space>
      </span>
    </>
  );
};

export default FiltersTable;
