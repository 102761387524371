/* eslint-disable no-unused-expressions */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";

import { useTranslation } from "react-i18next";

import { withRouter } from "react-router-dom";
import { handleActive, handleArchived, handleExportExcelSuppliers, handleDetails } from "../functions/index";

// COMPONENTS
import ActionBar from "../../shared/components/ActionBar";
import SupplierTable from "../components/SuppliersTable";
import SearchSuppliersForm from "../components/SearchSupplierForm";
import ActionsSuppliers from "../components/ActionsSupplier";
import ManageUserModal from "../components/ManageSupplierModal";

import AddSupplierButton from "../components/AddSupplierButton";
import SupplierHeader from "../components/supplierHeader";
import SuppliersHeader from "../components/suppliersHeader";
import AppContainer from "../../subscribersApps/containers/Apps";
import TransContainer from "../../subscribersTrans/containers/Trans";
import TabMenu from "../components/Tabs";
import ManageBucketModal from "../../bucket/components/ManageBucketModal";
import BucketContainer from "../../bucket/containers/Buckets";
import EventsContainer from "../../events/containers/Event";

// HELPERS

// REDUX
import { setSupplier } from "../../../redux/supplier";

// REQUEST
import { getSupplier } from "../requests";

// HOOKS
import useSuppliers from "../hooks/useSuppliers";
import useModal from "../../shared/hooks/useModal";

// CONSTANTS
import { globals, numbers, packs, icons } from "../../../constants/globals";

const Suppliers = ({ match }) => {
  const dispatch = useDispatch();
  const instanceStoreRedux = useSelector((store) => store.instance);
  const { t } = useTranslation();

  const { suppliers, filters, setFilters, pagination, setPagination, loading, refetch } = useSuppliers();
  const [isOpenManageBucketModal, openManageBucketModal, closeManageBucketModal] = useModal();

  const commonProps = { filters, setFilters };
  const [page, setPage] = useState(globals.PAGE_MAIN);

  const [refresh, setRefresh] = useState(true);

  const [subpage, setSubpage] = useState(numbers.FIVE);
  const [selectedSupplier, setSelectedSupplier] = useState({});

  const [supplierId, setUserId] = useState(null);
  const [packType, setPackType] = useState(null);

  const [supplierType, setUserType] = useState("Gallery");

  const [isOpenManageSuppliersModal, openManageSuppliersModal, closeManageSuppliersModal] = useModal();

  const [isOpenGeneralModal, openGeneralModal, closeGeneralModal] = useModal();

  const [initialValues, setInitialValues] = useState({
    fullname: "",
    role: "USER",
    address: "",
    city: "",
    region: "",
    country: "",
    document: "",
    documentType: "",
    observation: "",
    birthdate: "1990/01/01",
    category: "cliente",
    type: "Normal",
    email: "",
    phone: "",
    reference: "",
    active: "",
    refererId: null,
  });

  const [extra, setExtra] = useState({});

  const handleEdit = (supplier) => {
    setSelectedSupplier(supplier);
    setUserId(supplier._id);
    openManageSuppliersModal();
  };

  const handleGeneralModal = ({ supplier, type }) => {
    setSelectedSupplier(supplier);
    setUserId(supplier._id);
    setPackType(type);
    openGeneralModal();
  };

  const supplierDetails = (supplier) => {
    setUserId(supplier._id);
    setPage(globals.PAGE_ONE);
    handleDetails(
      { supplier },
      {
        onSuccess: (result) => {
          console.log(result);
          setSupplier(result)(dispatch);
          setSelectedSupplier(result);
        },
      }
    );
  };

  const handleExportExcel = () => {
    handleExportExcelSuppliers(suppliers);
  };

  const handleUploadImage = (typeUsr = "Gallery") => {
    setUserType(typeUsr);
    openManageBucketModal();
  };

  const resetFilters = () => {
    setFilters({ ...filters, all: "" });
    refetch();
  };

  useEffect(() => {
    if (match.params.id) {
      handleDetails({ _id: match.params.id });
    }
    setRefresh(true);
  }, []);

  useEffect(() => {
    refetch();
  }, [filters]);

  useEffect(() => {
    setSupplier(selectedSupplier)(dispatch);

    if (selectedSupplier._id) {
      setUserId(selectedSupplier._id);
      setSubpage(numbers.FIVE);
    }
  }, [selectedSupplier]);

  useEffect(() => {
    debounce(() => refetch(), 500);
  }, [filters]);

  return (
    <>
      {page === globals.PAGE_MAIN && (
        <div>
          <SuppliersHeader
            title={<h4 className="bold special-font big-font">{icons.guest} Proveedores</h4>}
            primaryAction={<AddSupplierButton refetch={refetch} initialValues={initialValues} t={t} />}
          />
          <div className="container-subpages">
            <ActionBar
              setFilters={setFilters}
              filters={filters}
              refetch={refetch}
              handleExportExcel={handleExportExcel}
              options={{ actions: ActionsSuppliers }}
            >
              <SearchSuppliersForm
                resetFilters={resetFilters}
                refetch={refetch}
                t={t}
                setFilters={setFilters}
                filters={filters}
              />
            </ActionBar>
            <SupplierTable
              {...commonProps}
              suppliers={suppliers}
              pagination={pagination}
              setPagination={setPagination}
              loading={loading}
              refetch={refetch}
              initialValues={initialValues}
              setPage={setPage}
              setSelectedSupplier={setSelectedSupplier}
              selectedSupplier={selectedSupplier}
              handleDetails={supplierDetails}
              handleEdit={handleEdit}
              handleArchived={handleArchived}
              handleInactive={handleActive}
              details={supplierDetails}
              t={t}
            />
          </div>
        </div>
      )}
      {page === globals.PAGE_ONE && (
        <div>
          <SupplierHeader
            subpage={subpage}
            setPage={setPage}
            selectedSupplier={selectedSupplier}
            handleDetails={supplierDetails}
            handleEdit={handleEdit}
            openDeleteModal={handleArchived}
            handleGeneralModal={handleGeneralModal}
            setSubpage={setSubpage}
            extradata={extra}
            handleUploadImage={handleUploadImage}
            t={t}
          />

          <div className="container-subpages">
            <TabMenu
              setSubpage={setSubpage}
              t={t}
              numbers={numbers}
              hasAnalytics={instanceStoreRedux.currentInstance.hasAnalytics}
            />

            {subpage === numbers.SEVEN && (
              <div className="subcontainer-wrapper">
                {supplierId && <EventsContainer isEmbed="true" supplierId={supplierId} />}
              </div>
            )}

            {subpage === numbers.FIVE && (
              <>
                <br />
                {supplierId && (
                  <AppContainer isEmbed="true" supplierId={supplierId || null} type={packs.SUBSCRIPTION} />
                )}
              </>
            )}

            {subpage === numbers.SIX && (
              <>
                <br />
                {supplierId && (
                  <TransContainer isEmbed="true" supplierId={supplierId || null} type={packs.SUBSCRIPTION} />
                )}
              </>
            )}
            {subpage === numbers.EIGHT && (
              <>
                <br />
                {refresh && supplierId && (
                  <BucketContainer
                    viewType="table"
                    isEmbed="true"
                    supplierId={supplierId}
                    entityId={supplierId}
                    entity="supplier"
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}

      <ManageUserModal
        refetch={refetch}
        supplierId={supplierId}
        setUserId={setUserId}
        closeModal={closeManageSuppliersModal}
        openModal={isOpenManageSuppliersModal}
        initialValues={initialValues}
        suppliers={suppliers}
        selectedSupplier={selectedSupplier}
        setSelectedSupplierMain={setSelectedSupplier}
        setSupplier={setSupplier}
        getUser={getSupplier}
        page={page}
        t={t}
      />

      <ManageBucketModal
        entityProp="Supplier"
        typeProp={supplierType}
        entityId={supplierId}
        openModal={isOpenManageBucketModal}
        closeModal={closeManageBucketModal}
      />
    </>
  );
};

export default withRouter(Suppliers);
