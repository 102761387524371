import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import { confirmAlert } from "react-confirm-alert";

import { useTranslation } from "react-i18next";

import { Form, Row, Col, Input, Select, Spin, Button, message } from "antd";
import { savePlan, getPlan } from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";

import { setPlanRedux } from "../../../redux/plan";
import { packs } from "../../../constants/globals";
import SelectPacks from "../../subscribers/components/SelectPacks";
import RichTextComponent from "./RichTextComponent";

const moment = require("moment");

const { Option } = Select;

const defaultValues = {
  name: "",
  departurePlace: "",
  minLimit: 1,
  maxLimit: 1,
  category: "",
  duration: "",
  active: true,
  code: "",
  public: false,
  price: 0,
  packId: null,
  type: packs.CONTENT,
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
};

const ManagePlanForm = ({ planId, openModal, closeModal, refetch, setPlanId, typePack }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);

  const [selectedPlan, setSelectedPlan] = useState(defaultValues);
  const [form] = Form.useForm();
  const [loaderForm, setLoaderForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [reload, setReload] = useState(0);

  const handleReset = () => {
    setSelectedPlan(defaultValues);
    setReload(Math.floor(Math.random() * 100000));
  };

  useEffect(() => {
    if (openModal) {
      if (planId) {
        setMode("edit");
      } else {
        setMode("new");
      }
    } else {
      handleReset();
      setMode(null);
      setPlanId(null);
    }
  }, [openModal]);

  useEffect(() => {
    if (mode === "new") {
      handleReset();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      setLoaderForm(true);
      getPlan(planId, {
        onSuccess: (response) => {
          setPlanRedux(response.result)(dispatch);
          setSelectedPlan({
            ...response.result,
          });
          setLoaderForm(false);
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  }, [mode]);

  useEffect(() => {
    form.setFieldsValue(selectedPlan);
  }, [form, selectedPlan]);

  const handleCloseModal = () => {
    refetch();
    closeModal();
  };

  const getHTML = debounce((values) => {
    setSelectedPlan({ ...selectedPlan, body: values });
  }, 500);

  const onFinish = (values) => {
    const data = { ...selectedPlan, ...values };
    if (!data.packId) {
      message.error("This is an error message");
    } else {
      savePlan(
        {
          ...data,
          planId,
          rootId: sessionStoreRedux.currentSession_id,
        },
        {
          onSuccess: (response) => {
            setPlanRedux(response.result)(dispatch);
            showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
          },
          onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
          onFinally: () => handleCloseModal(),
        }
      );
    }
  };

  const onValuesChange = debounce((values) => {
    setSelectedPlan({ ...selectedPlan, ...values });
  }, 500);

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  const handlePack = (value) => {
    setSelectedPlan({ ...selectedPlan, packId: value });
  };

  const handleFinishConfirm = (values) => {
    confirmAlert({
      title: t("update"),
      message: `Esta seguro que desea continuar ?`,
      buttons: [
        {
          label: t("yes"),
          onClick: () => onFinish(values),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <Form
      form={form}
      name="manage-category-form-modal"
      initialValues={defaultValues}
      className="manage-modal"
      onFinish={handleFinishConfirm}
      labelCol={{ span: 24 }}
      onValuesChange={onValuesChange}
    >
      <div className="layout-form-modal entity-300 entity-small">
        {loaderForm ? (
          <>
            <Spin tip={t("loading")} />
          </>
        ) : (
          <>
            <h3>Website Information</h3>
            <hr />
            <Row gutter={15} style={{ paddingBottom: 10 }}>
              <Col span={8}>
                <Form.Item name="packId2" tooltip="" label={t("pack")}>
                  <SelectPacks
                    reload={reload}
                    currentValue={selectedPlan?.packId}
                    defaultValue={selectedPlan?.packId}
                    returnData={handlePack}
                    typePack={typePack}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item name="name" label={t("name")}>
                  <Input maxLength={50} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="code" label={t("code")}>
                  <Input maxLength={50} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="language" label={t("language")}>
                  <Select className="select-form-modal">
                    <Option value="ES">Espanol</Option>
                    <Option value="EN">Ingles</Option>
                    <Option value="FR">Frances</Option>
                  </Select>{" "}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="body" label={t("body")}>
                  <RichTextComponent setHTML={selectedPlan?.body} getHTML={getHTML} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item>
                  <Button style={{ float: "right" }} type="primary" onClick={handleSubmit}>
                    {t("update")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Form>
  );
};

ManagePlanForm.defaultProps = {
  // () => {},
};

export default ManagePlanForm;
