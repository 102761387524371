import { Drawer, Divider, Statistic, Card, Row, Col } from "antd";
import { useSelector } from "react-redux";
import { moneyFormat, dateFormat6, icons, colors, consolidateApp } from "../../../constants/globals";

const PlansDetails = ({ t, refetch, open, setOpen }) => {
  const { currentPlan } = useSelector((store) => store.plan);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      {" "}
      <Drawer title="Detalles del plan" width={720} onClose={onClose} visible={open} bodyStyle={{ paddingBottom: 80 }}>
        <h2>{currentPlan?.name}</h2>
        <p>{currentPlan?.observation}</p>

        <Divider orientation="left">{t("generalData")}</Divider>
        <div className="site-statistic-demo-card">
          <Row gutter={16}>
            <Col span={8}>
              <Card>
                <Statistic
                  title={t("planPrice")}
                  value={moneyFormat(currentPlan?.price)}
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                  suffix=""
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title={t("quantityCourtesy")}
                  value={currentPlan?.quantityguest}
                  precision={0}
                  valueStyle={{ color: colors.success }}
                  suffix=""
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title={t("duration")}
                  value={`${currentPlan?.duration} ${currentPlan?.durationMode} `}
                  precision={0}
                  valueStyle={{ color: colors.success }}
                  prefix={icons.clock}
                  suffix=""
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Card>
                <Statistic title={t("minimumCapacity")} value={currentPlan?.min} precision={0} suffix="" />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic title={t("maximumCapacity")} value={currentPlan?.max} precision={0} suffix="" />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  prefix={icons.calendar}
                  title={t("createdAt")}
                  value={dateFormat6(currentPlan?.creartedAt)}
                  precision={0}
                />
              </Card>
            </Col>
          </Row>
        </div>

        <Divider orientation="left">{t("metrics")}</Divider>
        <div className="site-statistic-demo-card">
          <Row gutter={16}>
            <Col span={8}>
              <Card>
                <Statistic
                  title={`${t("apps")} ${t("active")}`}
                  value={consolidateApp(currentPlan).active_true}
                  precision={0}
                  suffix=""
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title={`${t("apps")} ${t("inactive")}`}
                  value={consolidateApp(currentPlan).active_false}
                  precision={0}
                  suffix=""
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title={`${t("total")} ${t("apps")}`}
                  value={consolidateApp(currentPlan).quantity}
                  precision={0}
                  suffix=""
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Card>
                <Statistic
                  title={t("amount")}
                  value={moneyFormat(consolidateApp(currentPlan).amount)}
                  precision={0}
                  valueStyle={{ color: colors.error }}
                  suffix=""
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title={t("amountPaid")}
                  value={moneyFormat(consolidateApp(currentPlan).pagado)}
                  precision={0}
                  valueStyle={{ color: colors.success }}
                  suffix=""
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title={t("paymentTotal")}
                  value={moneyFormat(consolidateApp(currentPlan).total)}
                  precision={0}
                  suffix=""
                />
              </Card>
            </Col>
          </Row>
        </div>
      </Drawer>
    </>
  );
};

export default PlansDetails;
