import { FetchJson } from "../../helpers/fetch";
import { apiRoutes } from "../../constants/routes";

const savePlan = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { planId } = props;
  const method = planId ? "PUT" : "POST";
  const path = planId ? `${apiRoutes.PLANS.SAVE_DATA}/${planId}` : `${apiRoutes.PLANS.SAVE_DATA}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const saveGuidePlan = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { planId } = props;
  const method = "PUT";
  const path = `${apiRoutes.PLANS.SAVE_GUIDE}/${planId}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const savePlanContent = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { planId } = props;
  const method = planId ? "PUT" : "POST";
  const path = planId ? `${apiRoutes.PLANS.SAVE_DATA_CONTENT}/${planId}` : `${apiRoutes.PACKS.SAVE_DATA_CONTENT}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const getPlan = async (planId = 0, { onSuccess, onError } = {}) => {
  let url = "-----";
  url = `${apiRoutes.PLANS.GET_DATA}/${planId}`;
  console.log(url);
  try {
    const request = await FetchJson(url, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getPlanAllPending = async ({ onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(apiRoutes.PLANS.GET_DATA_PENDING, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const newEventPlan = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  try {
    const request = await FetchJson(apiRoutes.PLANS.NEW_EVENT_PLAN, "PUT", props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const editEventPlan = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  try {
    const request = await FetchJson(apiRoutes.PLANS.EDIT_EVENT_PLAN, "PUT", props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const archivedPlan = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id } = params.plan;
  const method = "PUT";
  const path = `${apiRoutes.PLANS.ARCHIVED}/${_id}`;

  try {
    const request = await FetchJson(path, method, { archived: true });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const consolidatePlan = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id } = params;
  const method = "PUT";
  const path = `${apiRoutes.PLANS.CONSOLIDATE}/${_id}`;

  try {
    if (_id) {
      const request = await FetchJson(path, method, {});
      const response = await request.json();
      onSuccess(response);
    } else {
      onFinally();
    }
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const sendClosureEmailPlan = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  console.log(params);
  const { _id } = params;
  const method = "PUT";
  const path = `${apiRoutes.PLANS.SEND_CLOSURE_EMAIL}/${_id}`;

  try {
    if (_id) {
      const request = await FetchJson(path, method, {});
      const response = await request.json();
      onSuccess(response);
    } else {
      onFinally();
    }
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const activePlan = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id, active } = params.plan;
  const method = "PUT";
  const path = `${apiRoutes.PLANS.ACTIVE}/${_id}`;

  try {
    const request = await FetchJson(path, method, { active: !active });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const closePlan = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id } = params.plan;
  const method = "PUT";
  const path = `${apiRoutes.PLANS.CLOSE}/${_id}`;

  try {
    const request = await FetchJson(path, method, params);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const getPlans = async (type = "", { onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.PLANS.ALL}/${type}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getPlansByPack = async ({ type = "", packId = null }, { onSuccess, onError } = {}) => {
  try {
    let path = `${apiRoutes.PLANS.ALL}/${packId}/${type}`;
    if (!packId) {
      path = `${apiRoutes.PLANS.ALL}/${type}`;
    }

    const request = await FetchJson(path, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

export {
  getPlan,
  closePlan,
  savePlanContent,
  savePlan,
  archivedPlan,
  activePlan,
  getPlans,
  consolidatePlan,
  getPlansByPack,
  getPlanAllPending,
  newEventPlan,
  sendClosureEmailPlan,
  editEventPlan,
  saveGuidePlan,
};
