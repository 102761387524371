import { Tabs } from "antd";
import { SolutionOutlined, DollarCircleOutlined, PaperClipOutlined, CloudUploadOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

const TabMenu = ({ setSubpage, t, numbers, hasAnalytics = false }) => {
  const callback = (key) => {
    setSubpage(key);
  };

  return (
    <Tabs defaultActiveKey={numbers.ONE} onChange={callback}>
      <TabPane
        tab={
          <>
            <PaperClipOutlined />
            {t("apps")}
          </>
        }
        key={numbers.FIVE}
      />

      <TabPane
        tab={
          <>
            <SolutionOutlined />
            {t("event")}
          </>
        }
        key={numbers.SEVEN}
      />
      <TabPane
        tab={
          <>
            <DollarCircleOutlined />
            {t("payment")}
          </>
        }
        key={numbers.SIX}
      />

      <TabPane
        tab={
          <>
            <CloudUploadOutlined />
            {t("files")}
          </>
        }
        key={numbers.EIGHT}
      />

      {hasAnalytics && (
        <TabPane
          tab={
            <>
              <SolutionOutlined />
              Acciones Web
            </>
          }
          key={numbers.ONE}
        />
      )}
      {hasAnalytics && (
        <TabPane
          tab={
            <>
              <SolutionOutlined />
              Paginas Vistas
            </>
          }
          key={numbers.TWO}
        />
      )}

      {hasAnalytics && (
        <TabPane
          tab={
            <>
              <SolutionOutlined />
              Resumen Web
            </>
          }
          key={numbers.THREE}
        />
      )}

      {hasAnalytics && (
        <TabPane
          tab={
            <>
              <SolutionOutlined />
              Resumen Acciones
            </>
          }
          key={numbers.FOUR}
        />
      )}
    </Tabs>
  );
};

export default TabMenu;
