import { Table, Space, Avatar, Tooltip, Typography, Tag } from "antd";

import {
  CheckCircleTwoTone,
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import BaseTable from "../../shared/components/BasicTable";
import { constantsColor } from "../../../constants/colors";
import {
  openLandingPagePackByReferers,
  icons,
  openQRPackByReferers,
  openLandingPagePackByReferersWithoutConfirmation,
} from "../../../constants/globals";

const moment = require("moment");

const { Link } = Typography;

const UsersTable = ({
  selectedUser,
  setSelectedUser,
  setPage,
  history,
  users,
  refetch,
  initialValues,
  handleDetails,
  handleEdit,
  userId,
  handleArchived,
  t,
  handleInactive,
  instanceStoreRedux,
  ...restProps
}) => (
  <>
    <BaseTable {...restProps} dataSource={users} refetch={refetch} scroll={{ y: 600 }}>
      <Table.Column
        fixed="left"
        width="160px"
        align="center"
        title={t("actions")}
        dataIndex="actions"
        key="actions"
        render={(_, data) => (
          <div className="">
            <Space size="small">
              <a href="#!" onClick={() => handleDetails(data)} role="button" tabIndex={0}>
                <Tooltip placement="top" title={`${t("clickTo")} ${t("moreDetails")}`}>
                  <UserOutlined />
                </Tooltip>
              </a>
              <a href="#!" onClick={() => handleEdit(data)} role="button" tabIndex={0}>
                <Tooltip placement="top" title={`${t("clickTo")} ${t("edit")}`}>
                  <EditOutlined />{" "}
                </Tooltip>
              </a>
              <a href="#!" onClick={() => handleArchived({ user: data, t, refetch })} role="button" tabIndex={0}>
                <Tooltip placement="top" title={`${t("clickTo")} ${t("delete")}`}>
                  <DeleteOutlined />
                </Tooltip>
              </a>
              <a href="#!" onClick={() => handleInactive({ user: data, t, refetch })} role="button" tabIndex={0}>
                {data.active ? (
                  <Tooltip placement="top" title={`${t("clickToChange")} ${t("userOnHold")}`}>
                    <CheckCircleTwoTone twoToneColor={constantsColor.success} />
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title={`${t("clickToChange")} ${t("userCompleted")}`}>
                    <ClockCircleOutlined className="danger" style={{ color: constantsColor.warning }} />
                  </Tooltip>
                )}
              </a>

              {data.role === "REFERER" && (
                <a
                  href={openQRPackByReferers({
                    currentInstance: instanceStoreRedux?.currentInstance,
                    refererUserId: data._id,
                    referer: data.firstname,
                    avatar: data.avatar,
                    title: "Check our experiences",
                    alias: instanceStoreRedux?.currentInstance?.alias,
                    logo: instanceStoreRedux?.currentInstance?.logo,
                  })}
                  target="blank"
                >
                  <Tooltip placement="top" title="Abrir landing page con el listado de paquetes">
                    {icons.qr}
                  </Tooltip>
                </a>
              )}
            </Space>
          </div>
        )}
      />

      <Table.Column
        title={t("Foto")}
        dataIndex="avatar"
        key="avatar"
        sorter
        width="50px"
        render={(text, row) => <Avatar src={row.avatar} icon={<UserOutlined />} />}
      />

      <Table.Column
        title={t("role")}
        width="150px"
        dataIndex="role"
        key="role"
        sorter
        render={(text, row) => (
          <>
            {text === "REFERER" && <Tag color="green">{row.role}</Tag>}
            {text === "TOURGUIDE" && <Tag color="blue">{row.role}</Tag>}
            {text === "ADMIN" && <Tag color="volcano">{row.role}</Tag>}
            {text === "SUPERADMIN" && <Tag color="purple">{row.role}</Tag>}
          </>
        )}
      />

      <Table.Column
        title={t("fullname")}
        dataIndex="lastname"
        key="lastname"
        sorter
        fixed="left"
        width="350px"
        render={(text, row) => (
          <>
            <h5 style={{ textTransform: "uppercase", fontWeight: 800, fontSize: 14 }}>{`${row.firstname} ${
              row.lastname || ""
            }`}</h5>

            {row.role === "REFERER" && (
              <>
                <Link
                  href={openLandingPagePackByReferers({
                    refererUserId: row._id,
                    currentInstance: instanceStoreRedux?.currentInstance,
                  })}
                  style={{ fontWeight: 800, color: "#6d6d6d" }}
                  target="blank"
                >
                  - Click para abrir catalogo web{" "}
                  <span style={{ color: "green", fontWeight: 800 }}>CON confirmación</span>
                </Link>
                <br />
                <Link
                  style={{ fontWeight: 800, color: "#6d6d6d" }}
                  href={openLandingPagePackByReferersWithoutConfirmation({
                    refererUserId: row._id,
                    currentInstance: instanceStoreRedux?.currentInstance,
                  })}
                  target="blank"
                >
                  - Click para abrir catalogo web{" "}
                  <span style={{ color: "#e75a00", fontWeight: 800 }}>SIN confirmación</span>
                </Link>
              </>
            )}
          </>
        )}
      />

      <Table.Column
        title={t("email")}
        dataIndex="email"
        key="email"
        sorter
        width="250px"
        render={(text, row) => <>{row.email}</>}
      />

      <Table.Column title={t("login")} dataIndex="login" key="login" sorter render={(text, row) => <>{row.login}</>} />
    </BaseTable>
  </>
);

export default withRouter(UsersTable);
