import { confirmAlert } from "react-confirm-alert";

import {
  consolidatePlan,
  getPlansByPack,
  archivedPlan,
  activePlan,
  closePlan,
  getPlanAllPending,
  sendClosureEmailPlan,
} from "../requests";

import { getAppsByPlanId } from "../../subscribersApps/requests";

import showGlobalNotification from "../../../helpers/showGlobalNotification";
import { colorsSystem } from "../../../constants/globals";

const Swal = require("sweetalert2");

export const handleConsolidatePlan = ({ plan, t }, { onSuccess = {}, onError = {} }) => {
  consolidatePlan(plan, {
    onSuccess: (result) => onSuccess(result),
    onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    onFinally: () => {},
  });
};

export const handleDetailsPlan = ({ planId, t }, { onSuccess = {} }) => {
  consolidatePlan(
    { _id: planId },
    {
      onSuccess: (planInfo) => onSuccess(planInfo),
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleGetPlansByPackId = ({ t, packId }, { onSuccess = {} }) => {
  getPlansByPack(
    { type: "SUBSCRIPTION", packId },
    {
      onSuccess: (planInfo) => onSuccess(planInfo),
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleArchivedConfirm = ({ plan, t, refetch }) => {
  archivedPlan(
    {
      plan,
    },
    {
      onSuccess: () => {
        refetch();
        Swal.fire(t("common_done"), "El plan ha sido archivado", "success");
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleArchivedPlan = ({ plan, t, refetch }) => {
  confirmAlert({
    title: `${t("sureToDelete")} ${plan.name} `,
    message: t("AreYouSureWantToContinue"),
    buttons: [
      {
        label: t("yes"),
        onClick: () => handleArchivedConfirm({ plan, t, refetch }),
      },
      {
        label: t("no"),
        onClick: () => {},
      },
    ],
  });
};

export const handleActiveConfirm = ({ plan, t, refetch }) => {
  activePlan(
    {
      plan,
    },
    {
      onSuccess: () => {
        refetch();
        showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleActivePlan = ({ plan, t, refetch }) => {
  confirmAlert({
    title: plan.active ? `${t("sureToDesactivate")}  ${plan.name}` : `${t("sureToActivate")} ${plan.name}`,
    message: t("AreYouSureWantToContinue"),
    buttons: [
      {
        label: t("yes"),
        onClick: () => handleActiveConfirm({ plan, t, refetch }),
      },
      {
        label: t("no"),
        onClick: () => {},
      },
    ],
  });
};

/*
      const { value: text2 } = await Swal.fire({
        title: "Desea enviar el correo de cierre a los participantes?",
        input: "radio",
        inputOptions: {
          yes: "Si",
          no: "No",
        },
        inputValidator: (value) => {
          if (!value) {
            return "Necesitas escojer una opcion!";
          }
        },
      });
      */
export const handleSendClosureEmailPlan = ({ plan, t, refetch }, { onSuccess = {} }) => {
  getAppsByPlanId(plan._id, {
    onSuccess: async (result2) => {
      let fotos;

      if (result2?.images < 1) {
        fotos = `Este evento NO tiene fotografias registradas.`;
      } else {
        fotos = `Se enviaran ${result2?.images} Fotografías`;
      }

      if (result2?.insitu?.length > 0) {
        Swal.fire({
          title: `Confirma que desea enviar el correo con las fotografias del tour?`,
          html: `
                <p style="text-align:left">
                  <h3>${fotos}</h3>
                  <b> ${result2?.insitu?.length} usuarios para enviar correos de cierre:</b><br /> ${
            result2?.emailsInsitu?.join(", ") || "----"
          } 
               
                  </p>`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Enviar correo",
        }).then((result) => {
          if (result.isConfirmed) {
            sendClosureEmailPlan(plan, {
              onSuccess: () => {
                refetch();
                Swal.fire(t("common_done"), `Envio realizado con exito!`, "success");
                onSuccess();
              },
              onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
              onFinally: () => {},
            });
          }
        });
      } else {
        Swal.fire("No hubo asistentes", "No se envio ningun correo porque no hubo asistentes", "warning");
      }
    },
    onError: () => {},
    onFinally: () => {},
  });
};

export const handleClosePlanConfirm = ({ plan, t, refetch }, { onSuccess = {} }) => {
  closePlan(
    {
      plan,
    },
    {
      onSuccess: () => {
        Swal.fire(t("common_done"), `Finalizado con exito!`, "success");

        handleSendClosureEmailPlan(
          { plan, t, refetch },
          {
            onSuccess: () => {
              Swal.fire(t("common_done"), `Correos enviado con exito!`, "success");
            },
            onError: () => {
              Swal.fire("Envio fallido!", "Envio fallido de correos", "warning");
            },
          }
        );
        refetch();
        onSuccess();
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleClosePlan = async ({ plan, t, refetch }, { onSuccess = {} }) => {
  getAppsByPlanId(plan._id, {
    onSuccess: async (result2) => {
      let payment;
      if (!result2.plan.isFree) {
        payment = `<b>Tickets en deuda($):</b> ${result2?.noPayment?.length || 0} `;
      }

      Swal.fire({
        title: `Esta seguro que desea finalizar ${result2?.planName}?`,
        html: `
        <div>
          <h4>Usuarios asistentes: ${result2?.insitu?.length || 0}/${result2?.plan.totalApps || "---"}</h4>
          <h4>Cantidad de fotografías:</b> ${result2?.images || 0}</h4>
          <h4>${result2?.insitu?.length} Correos a enviar:</b><br /> ${
          result2?.emailsInsitu?.join(", ") || "----"
        } </h4>
        <h4> ${payment} </h4>
        </div>
          `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si quiero finalizarlo!",
      }).then((result) => {
        if (result.isConfirmed) {
          handleClosePlanConfirm({ plan, t, refetch }, { onSuccess: () => onSuccess() });
        }
      });
    },
    onError: () => {},
    onFinally: () => {},
  });
};

export const handleClosePlan2 = async ({ plan, t, refetch }, { onSuccess = {} }) => {
  const { value: text2 } = await Swal.fire({
    title: "Desea enviar un correo de cierre a los participantes?",
    input: "radio",
    inputOptions: {
      yes: "Si",
      no: "No",
    },
  });

  let message = "";
  if (text2 === "yes") {
    const { value: text } = await Swal.fire({
      input: "textarea",
      inputLabel: "Algún mensaje para el correo de cierre?",
      inputPlaceholder: "",
      showCancelButton: true,
    });
    message = text;
  }

  confirmAlert({
    title: "Cerrar este Evento",
    message: t("AreYouSureWantToContinue"),
    buttons: [
      {
        label: t("yes"),
        onClick: () =>
          handleClosePlanConfirm({ plan, t, refetch, text: message, text2 }, { onSuccess: () => onSuccess() }),
      },
      {
        label: t("no"),
        onClick: () => {},
      },
    ],
  });
};

export const handleCloseAllPlan = ({ t, refetch }) => {
  Swal.fire({
    title: t("common_areYouSure"),
    html: ``,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: colorsSystem.red,
    cancelButtonColor: colorsSystem.blue,
    confirmButtonText: t("common_yesIWantContinueClosed"),
    cancelButtonText: t("common_cancel"),
  }).then((result) => {
    if (result.isConfirmed) {
      getPlanAllPending({
        onSuccess: (result2) => {
          refetch();
          Swal.fire(t("common_done"), `${result2.total} ${t("plan_allClosedSuccess")}`, "success");
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      });
    }
  });
};
