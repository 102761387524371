import { FetchJson } from "../../helpers/fetch";
import { apiRoutes } from "../../constants/routes";

const getEvent = async (eventId = 0, { onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.EVENT.GET_DATA}/${eventId}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const archivedEvents = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id } = params.event;
  const method = "PUT";
  const path = `${apiRoutes.EVENT.ARCHIVED}/${_id}`;

  try {
    const request = await FetchJson(path, method, { archived: true });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const duplicateEvents = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id } = params.event;
  const method = "PUT";
  const path = `${apiRoutes.EVENT.DUPLICATE}/${_id}`;

  try {
    const request = await FetchJson(path, method, { archived: true });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

export { getEvent, archivedEvents, duplicateEvents };
