import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import ActionBar from "../../shared/components/ActionBar";
import EventsTable from "../components/EventsTable";

import SearchBucketsForm from "../components/SearchBucketForm";

// HELPERS
import showGlobalNotification from "../../../helpers/showGlobalNotification";

// REDUX
import { setBucketRedux } from "../../../redux/bucket";

// REQUEST
import { getEvent } from "../requests";

// HOOKS
import useEvents from "../hooks/useEvents";

// CONSTANTS
import { globals } from "../../../constants/globals";

const Events = ({
  isEmbed = false,
  packId = null,
  planId = null,
  userId = null,
  appId = null,
  page_ = globals.PAGE_MAIN,
}) => {
  const { t } = useTranslation();

  const { events, filters, setFilters, pagination, setPagination, loading, refetch } = useEvents(
    packId,
    planId,
    userId,
    appId
  );

  const commonProps = { filters, setFilters };
  const [page, setPage] = useState(page_);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [eventId, setEventId] = useState(null);

  const [initialValues] = useState({
    mode: "Efectivo",
    fullname: "",
    email: "",
    phone: "",
    amount: 0,
    pending: 0,
    total: 0,
    appId: "",
  });

  const handleDetails = (event) => {
    getEvent(event._id, {
      onSuccess: (response) => {
        setBucketRedux(response.result);
        //  openDetailsBucketModal();
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
  };

  const handleArchived = (event) => {
    console.log(event);
    /*    setEventId(event._id);
    handleArchivedEvent({ event, t, refetch });
    */
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <>
      {page === globals.PAGE_MAIN && (
        <div>
          <ActionBar t={t} {...commonProps}>
            <SearchBucketsForm filters={filters} setFilters={setFilters} refetch={refetch} t={t} />
          </ActionBar>
          <EventsTable
            events={events}
            pagination={pagination}
            setPagination={setPagination}
            loading={loading}
            refetch={refetch}
            initialValues={initialValues}
            setPage={setPage}
            setSelectedEvent={setSelectedEvent}
            selectedEvent={selectedEvent}
            handleDetails={handleDetails}
            details={handleDetails}
            handleArchived={handleArchived}
            t={t}
            {...commonProps}
          />
        </div>
      )}
    </>
  );
};

export default Events;
