import { useTranslation } from "react-i18next";
import { Button, Tooltip } from "antd";
import { AmazonOutlined, PlusOutlined } from "@ant-design/icons";

import PrimaryButton from "../../shared/components/PrimaryButton";
import useShortcut from "../../shared/hooks/useShortcut";

const AddCategoryButton = ({
  isOpenManagePlanModal,
  openManagePlanModal,
  SelectPacks,
  handleDuplicateCategoryPack,
  uploadJsonPlanToS3,
  packId,
  refreshByPacks,
  filters,
}) => {
  useShortcut(["n"], openManagePlanModal, [isOpenManagePlanModal]);
  const { t } = useTranslation();

  return (
    <>
      <Tooltip placement="topLeft" title="Crear un nuevo contenido">
        <SelectPacks
          reload=""
          currentValue={packId}
          defaultValue={packId}
          returnData={refreshByPacks}
          typePack="CONTENT"
        />
      </Tooltip>
      <Tooltip placement="topLeft" title="Actualizar informacion en el Website">
        <Button
          className="ant-btn secondary info large"
          onClick={() => uploadJsonPlanToS3()}
          size="large"
          type="primary"
        >
          {t("RefreshJSONS3")}
        </Button>
      </Tooltip>
      {String(filters.categoryPack).length > 4 && (
        <Tooltip placement="topLeft" title="Actualizar informacion en el Website">
          <Button
            className="ant-btn secondary info large"
            onClick={() => handleDuplicateCategoryPack()}
            size="large"
            type="primary"
          >
            Duplicar Categoria
          </Button>
        </Tooltip>
      )}

      <Tooltip placement="topLeft" title="Crear un nuevo contenido">
        <PrimaryButton onClick={openManagePlanModal} type="primary" size="large" icon={<PlusOutlined />}>
          {t("new")} {t("content")}
        </PrimaryButton>
      </Tooltip>
    </>
  );
};

export default AddCategoryButton;
