import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { ClockCircleOutlined, TrophyOutlined, UserOutlined, TagOutlined } from "@ant-design/icons";

import { withRouter } from "react-router-dom";
import { dateFormat4 } from "../constants/globals";
import { loadBranding, loadUserBranding } from "../features/login/request";

const Carnet = ({ match }) => {
  const [setting, setSetting] = useState({});
  const [user, setUser] = useState({});
  const [active, setActive] = useState(false);
  const [app, setApp] = useState({});

  useEffect(() => {
    loadBranding(match.params.alias, {
      onSuccess: (response) => {
        setSetting(response.result);
        loadUserBranding(match.params.id, {
          onSuccess: (response2) => {
            if (response2.apps[0]) {
              setUser(response2.result);
              setApp(response2.apps[0]);
              setActive(true);
            }
          },
          onError: () => {},
        });
      },
      onError: () => {},
    });
  }, [match.params.alias]);

  return (
    <>
      {" "}
      <>
        <div className="qr-section-external" style={{ backgroundColor: setting.color, height: "800px" }}>
          {active && (
            <div className="wrapper" style={{ width: 350 }}>
              <Row>
                <Col span={24}>
                  <img style={{ width: 160, height: 160 }} src={user.avatar} alt="avatar" className="avatar-user" />
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <h1>
                    {user.firstname} {user.lastname} <br />
                  </h1>
                  <h3> {app.code}</h3>
                  <p style={{ textAlign: "center" }}>
                    {user.email} <br /> <b> {app.planId.name} </b>
                  </p>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <div className="qr-wrapper">....</div>
                </Col>
                <Col span={12} style={{ paddingLeft: 20 }}>
                  <img src={setting.logo} alt="logo" className="carnet-fototo" />
                  <p>{app.lifecicle}</p>
                  <p>
                    Vence: {dateFormat4(app.closureDate)} <br />
                    Ingresa a nuestro Portal web{"  "}
                    <a href={`https://cluuf-web.herokuapp.com/user/${setting.alias}/cluufnet`}>AQUÍ</a>
                  </p>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </>
    </>
  );
};
export default withRouter(Carnet);
