import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Drawer, Col, Row, Button, Select, Spin, Badge } from "antd";
import moment from "moment";

import { withRouter } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";

import AppsTable from "../components/AppsTable";

import ManageAppModal2 from "../components/ManageAppModal2";
import AppsDetails from "../components/AppsDetails";
import ExcelExport from "../components/AppsExcelExport";
import ExcelExportSimple from "../components/AppsExcelExportSimple";
import DateSelector from "../../subscribersDashboard/components/dateSelector";

import CreateAppEvent from "../components/CreateAppEvent";

import ManageUserModal from "../../subscribersUsers/components/ManageUserModal";
import AppHeader from "../components/AppHeader";
import AppsHeader from "../components/AppsHeader";
import InfoPack from "../components/InfoPack";
import AppMoreDetails from "../components/AppMoreDetails";
import TabMenu from "../components/Tabs";
import ManageBucketModal from "../../bucket/components/ManageBucketModal";

// HELPERS
import showGlobalNotification from "../../../helpers/showGlobalNotification";

// REDUX
import { setAppRedux } from "../../../redux/app";
import { setPlanRedux } from "../../../redux/plan";
import { setTransRedux } from "../../../redux/trans";

// REQUEST
import { getApp, archivedApp, activeApp, insituApp, getAppsByDate } from "../requests";

import { getPlan } from "../../subscribersPlans/requests";

import useModal from "../../shared/hooks/useModal";

// CONTAINERS
import TransContainer from "../../subscribersTrans/containers/Trans";
import EventsContainer from "../../events/containers/Event";
import SelectPacks from "../../subscribers/components/SelectPacks";

// CONSTANTS
import { globals, numbers, packs } from "../../../constants/globals";

import { handleCheckActionsArchived, handleGetAppCountPending } from "../functions";

const Apps = ({
  isEmbed = false,
  userId = null,
  reload,
  planId = null,
  AppId = null,
  history,
  resume = false,
  isMinimal = false,
  handleUploadPlanFile = null,
  handleClosePlanByGuide = null,
  handleCreateAppEditEvent = null,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userStoreRedux = useSelector((store) => store.user);

  const [page, setPage] = useState(globals.PAGE_MAIN);
  const [subpage, setSubpage] = useState(numbers.ONE);
  const [selectedApp, setSelectedApp] = useState({});
  const [goback, setGoback] = useState(false);
  const [visible, setVisible] = useState(false);
  const [openCreateAppEvent, setOpenCreateAppEvent] = useState(false);
  const [packInfo, setPackInfo] = useState({});
  const [packId, setPackId] = useState(null);
  const [appId, setAppId] = useState(null);
  const [isOpenManageAppsModal, openManageAppsModal, closeManageAppsModal] = useModal();
  const [isOpenManageBucketModal, openManageBucketModal, closeManageBucketModal] = useModal();

  const [isOpenManageUsersModal, openManageUsersModal, closeManageUsersModal] = useModal();
  const [selectedUser, setSelectedUser] = useState({});
  const [userId_, setUserId_] = useState(userId);
  const [details, setDetails] = useState(false);
  const [modeSearch, setModeSearch] = useState("null");
  const [filters, setFilters] = useState({
    hasta: moment().format("YYYY-MM-DD"),
    desde: moment().subtract(7, "days").format("YYYY-MM-DD"),
  });
  const [apps, setApps] = useState({});
  const [pagination, setPagination] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pendingApps, setPendingApps] = useState(0);

  const commonProps = { filters, setFilters };

  const refetch = () => {};

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setUserId_(user._id);
    openManageUsersModal();
  };

  const searchApps = () => {
    getAppsByDate(
      { desde: "2022-03-01", hasta: " 2022-03-09" },
      {
        onSuccess: (res) => {
          console.log(res);
        },
        onError: () => {},
      }
    );
  };

  const [initialValues] = useState({
    name: "",
    duration: "",
    category: "",
    maxLimit: 1,
    minLimit: 1,
    observation: "",
    active: true,
    tag: "",
    avatar: "",
    public: false,
    startdate: "2020/01/01",
    closuredate: "2020/01/01",
  });

  const handleEdit = (app) => {
    setSelectedApp(app);
    setAppId(app._id);
    openManageAppsModal();
  };

  const handleGoto = ({ number = 6, app }) => {
    history.push(`/home/${number}/${app._id}`);
  };

  const refreshPlan = (planIdTemp, { onSuccess = {}, onError = {} }) => {
    setTimeout(() => {
      getPlan(planIdTemp, {
        onSuccess: (response) => {
          setPlanRedux(response.result)(dispatch);
          if (response.result) onSuccess(response.result);
          else onError();
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }, 4000);
  };

  const handleDetails = (app) => {
    setSelectedApp({});
    getApp(app._id, {
      onSuccess: (response) => {
        setAppId(app._id);
        setSelectedApp(response.result);
        setAppRedux(response.result)(dispatch);
        setPage(globals.PAGE_ONE);
        setSubpage(numbers.ONE);
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
    //
  };

  const modeDetails = (app) => {
    if (isEmbed) {
      handleGoto({ number: 29, app });
    } else {
      handleDetails(app);
    }
  };

  const handleArchivedConfirm = (app) => {
    console.log(app.planId);
    archivedApp(
      {
        app,
      },
      {
        onSuccess: () => {
          refreshPlan(app.planId._id, {
            onSuccess: () => {
              refetch();
            },
            onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
          });
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleResume = (app) => {
    setSelectedApp(app);
    setAppRedux(app)(dispatch);
    setDetails(true);
  };

  const handleArchived = (app) => {
    setAppId(app._id);
    confirmAlert({
      title: `${t("delete")} ${app.name}`,
      message: `${t("AreYouWantDelete")} ${app.name}?`,
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleArchivedConfirm(app),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleActiveConfirm = (app) => {
    activeApp(
      {
        app,
      },
      {
        onSuccess: (result) => {
          refetch();

          refreshPlan(app.planId._id, {
            onSuccess: () => {},
            onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
          });

          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleActive = (app) => {
    setAppId(app._id);
    confirmAlert({
      title: app.active ? `${t("desactivate")} ${t("application")}` : `${t("activate")} ${t("application")}`,
      message: t("AreYouSureWantToContinue"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleActiveConfirm(app),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleInsituConfirm = (app) => {
    insituApp(
      {
        app,
      },
      {
        onSuccess: (result) => {
          refetch();

          refreshPlan(app.planId._id, {
            onSuccess: () => {},
            onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
          });

          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleInsitu = (app) => {
    setAppId(app._id);
    confirmAlert({
      title: app.insitu ? "Desea QUITAR la asistencia" : "Desea ASIGNAR la asistencia",
      message: t("AreYouSureWantToContinue"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleInsituConfirm(app),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleCheckConfirm = ({ selectedRowKeys, planId_, active_, archived_, insitu_ }) => {
    selectedRowKeys.forEach((item) => {
      if (active_ !== null) {
        handleActiveConfirm({
          _id: item,
          active: active_,
          planId: {
            _id: planId_,
          },
        });
      }

      if (insitu_ !== null) {
        handleInsituConfirm({
          _id: item,
          insitu: insitu_,
          planId: {
            _id: planId_,
          },
        });
      }

      if (archived_) {
        handleArchivedConfirm({
          _id: item,
          active: active_,
          planId: {
            _id: planId_,
          },
        });
      }
    });
  };

  const handleCheckActions = ({ title, selectedRowKeys, planId_, active_, archived_ = false }, { onSuccess = {} }) => {
    confirmAlert({
      title,
      message: t("AreYouSureWantToContinue"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => {
            onSuccess(true);
            handleCheckConfirm({ selectedRowKeys, planId_, active_, archived_ });
          },
        },
        {
          label: t("no"),
          onClick: () => onSuccess(false),
        },
      ],
    });
  };

  const handlePack = (packId_) => {
    setPackId(packId_);
    setFilters({ ...filters, packId: packId_ });
  };

  const searchAppByDate = () => {
    setLoading(true);
    getAppsByDate(filters, {
      onSuccess: (response) => {
        setLoading(false);
        setApps(response.result);
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
  };

  const searchAppInWait = () => {
    setLoading(true);
    handleGetAppCountPending({
      onSuccess: (pendings) => {
        setPendingApps(pendings.result);
        setLoading(false);
        setApps(pendings.data);
      },
      onError: () => {},
    });
  };

  const onClose = () => {
    setVisible(false);
    setOpenCreateAppEvent(false);
  };

  const handleMoveAppEvent = (app) => {
    setSelectedApp(app);
    setOpenCreateAppEvent(true);
  };

  const handleChange = (value) => {
    setFilters({ ...filters, status: value });
  };

  const [initialExecute, setInitialExecute] = useState(false);
  useEffect(() => setTimeout(() => setInitialExecute(true), 2000), []);

  useEffect(() => {
    if (page !== globals.PAGE_MAIN) setAppRedux(selectedApp)(dispatch);
  }, [selectedApp]);

  useEffect(() => {
    // debounce(() => refetch(), 500);
  }, [reload]);

  useEffect(() => {
    searchAppInWait();
  }, []);

  useEffect(() => {
    if (page === globals.PAGE_MAIN) {
      setAppRedux({ ok: false })(dispatch);
      setTransRedux({ ok: false })(dispatch);
    }
  }, [page]);

  useEffect(() => {
    refetch();
  }, [filters]);

  useEffect(() => {
    if (initialExecute && page === globals.PAGE_ONE) handleDetails(selectedApp);
  }, [userStoreRedux.reload]);

  return (
    <>
      {page === globals.PAGE_MAIN && !resume && (
        <div>
          {!isEmbed && <AppsHeader t={t} primaryAction={<></>} title="Actividades" />}
          <div className={isEmbed ? "" : "container-subpages"}>
            <Row gutter={16} className="box-info">
              <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                <DateSelector
                  reload={modeSearch}
                  filter={filters}
                  setFilter={setFilters}
                  setModeSearch={setModeSearch}
                />
              </Col>
              <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                Seleccione un Programa:{" "}
                <SelectPacks
                  type="SUBSCRIPTION"
                  reload={reload}
                  currentValue={packId}
                  defaultValue={packId}
                  handleSelected={handlePack}
                  returnData={handlePack}
                  setPackInfo={setPackInfo}
                  kind="event"
                  title="VER TODOS"
                />{" "}
              </Col>
              <Col span={24} xs={24} sm={24} md={3} lg={3} xl={3}>
                Seleccione un Status: <br />
                <Select defaultValue="all" style={{ width: "90%" }} onChange={handleChange}>
                  <Select.Option value="all">Todos</Select.Option>
                  <Select.Option value="isProgress">Abiertas</Select.Option>
                  <Select.Option value="isPending">En espera</Select.Option>
                  <Select.Option value="isClosed">Finalizadas</Select.Option>
                </Select>
              </Col>
              <Col span={24} xs={24} sm={24} md={2} lg={2} xl={2}>
                <>
                  &nbsp;
                  <br />
                  <Button className="ant-btn secondary primary " onClick={() => searchAppByDate()}>
                    {" "}
                    Buscar{" "}
                  </Button>
                </>
              </Col>
              <Col span={24} xs={24} sm={24} md={2} lg={2} xl={2}>
                <>
                  &nbsp;
                  <br />
                  <Badge count={pendingApps}>
                    <Button className="ant-btn secondary error fill" onClick={() => searchAppInWait()}>
                      {" "}
                      En Espera{" "}
                    </Button>
                  </Badge>
                </>
              </Col>
              <Col span={24} xs={24} sm={24} md={1} lg={1} xl={1}>
                &nbsp;
                <br />
                <ExcelExport apps={apps} />
              </Col>
            </Row>

            {!loading ? (
              <AppsTable
                {...commonProps}
                apps={apps}
                pagination={pagination}
                setPagination={setPagination}
                refetch={refetch}
                setPage={setPage}
                setSelectedApp={setSelectedApp}
                selectedApp={selectedApp}
                handleDetails={handleDetails}
                handleEdit={handleEdit}
                details={modeDetails}
                handleArchived={handleArchived}
                handleActive={handleActive}
                handleGoto={handleGoto}
                isEmbed={isEmbed}
                modeDetails={modeDetails}
                refreshPlan={refreshPlan}
                handleCheckActions={handleCheckActions}
                handleResume={handleResume}
                handleUploadPlanFile={handleUploadPlanFile}
                handleInsitu={handleInsitu}
                handleClosePlanByGuide={handleClosePlanByGuide}
                isMinimal={isMinimal}
                handleCheckActionsArchived={handleCheckActionsArchived}
                setVisible={setVisible}
                handleCreateAppEditEvent={handleCreateAppEditEvent}
                handleMoveAppEvent={handleMoveAppEvent}
                handleEditUser={handleEditUser}
                t={t}
              />
            ) : (
              <Spin tip="Cargando aplicaciones" />
            )}

            {details && <AppsDetails t={t} open={details} setOpen={setDetails} />}
          </div>
        </div>
      )}
      {page === globals.PAGE_ONE && !resume && (
        <>
          <AppHeader
            setPage={setPage}
            selectedApp={selectedApp}
            handleDetails={handleDetails}
            handleEdit={handleEdit}
            setSubpage={setSubpage}
            handleArchived={handleArchived}
            handleEditUser={handleEditUser}
            isGoback={goback}
            handleGoto={handleGoto}
            t={t}
          />

          <div className="container-subpages">
            <TabMenu setSubpage={setSubpage} t={t} numbers={numbers} />

            {subpage === numbers.ONE && appId && (
              <div className="subcontainer-wrapper">
                <TransContainer isEmbed="true" appId={appId} />
              </div>
            )}
            {subpage === numbers.TWO && appId && (
              <div className="subcontainer-wrapper">
                <EventsContainer isEmbed="true" appId={appId} />
              </div>
            )}
            {subpage === numbers.THREE && selectedApp._id && (
              <div className="subcontainer-wrapper">
                <InfoPack selectedApp={selectedApp} />
              </div>
            )}
            {subpage === numbers.FOUR && selectedApp._id && (
              <div className="subcontainer-wrapper">
                <AppMoreDetails selectedApp={selectedApp} t={t} />
              </div>
            )}
          </div>
        </>
      )}
      <ManageAppModal2
        refetch={refetch}
        appId={appId}
        setAppId={setAppId}
        closeModal={closeManageAppsModal}
        openModal={isOpenManageAppsModal}
        t={t}
      />

      <ManageUserModal
        refetch={refetch}
        userId={userId_}
        setUserId={setUserId_}
        closeModal={closeManageUsersModal}
        openModal={isOpenManageUsersModal}
        initialValues={initialValues}
        selectedUser={selectedUser}
        setSelectedUserMain={setSelectedUser}
        page={page}
        t={t}
      />
      <ManageBucketModal
        entityProp="User"
        typeProp="Gallery"
        entityId={appId}
        openModal={isOpenManageBucketModal}
        closeModal={closeManageBucketModal}
      />

      <Drawer
        title="Agregar contacto"
        width="90%"
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <CreateAppEvent
          onClose={onClose}
          refetch={refetch}
          planId_={planId}
          selectedApp={{ userId: "123456789", startDate: null, closureDate: null }}
        />
      </Drawer>

      <Drawer title="Mover contacto a otro evento" width="90%" onClose={onClose} visible={openCreateAppEvent}>
        <CreateAppEvent
          onClose={onClose}
          refetch={refetch}
          userSelected={selectedApp.userId}
          selectedApp={selectedApp}
        />
      </Drawer>
    </>
  );
};

export default withRouter(Apps);
