import { confirmAlert } from "react-confirm-alert";

import {
  archivedExpense,
  activeExpense,
  exportExcelExpenses,
  getExpense,
  updateExpense,
  searchExpenses,
} from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";

const Swal = require("sweetalert2");

export const handleActiveConfirm = ({ expense, t, refetch }) => {
  activeExpense(
    {
      expense,
    },
    {
      onSuccess: () => {
        refetch();
        showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleActive = ({ expense, t, refetch }) => {
  confirmAlert({
    title: expense.active ? `${t("desactivate")} ${t("expense")}` : `${t("activate")} ${t("expense")}`,
    message: t("AreYouSureWantToContinue"),
    buttons: [
      {
        label: t("yes"),
        onClick: () => handleActiveConfirm({ expense, t, refetch }),
      },
      {
        label: t("no"),
        onClick: () => {},
      },
    ],
  });
};

export const handleExportExcelExpenses = (expenses) => {
  exportExcelExpenses(expenses);
};

export const handleArchivedConfirm = ({ expense, t, refetch }) => {
  archivedExpense(
    {
      expense,
    },
    {
      onSuccess: () => {
        refetch();
        showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleArchived = ({ expense, t, refetch }) => {
  confirmAlert({
    title: expense.active ? `${t("desactivate")} ${t("expense")}` : `${t("activate")} ${t("expense")}`,
    message: t("AreYouSureWantToContinue"),
    buttons: [
      {
        label: t("yes"),
        onClick: () => handleArchivedConfirm({ expense, t, refetch }),
      },
      {
        label: t("no"),
        onClick: () => {},
      },
    ],
  });
};

export const handleDetails = ({ expense }, { onSuccess = {} }) => {
  getExpense(expense._id, {
    onSuccess: (response) => {
      onSuccess(response.result);
    },
    onError: () => showGlobalNotification("error", "Error", "error"),
  });
};

export const handleUpdateExpense = ({ expenseId, params, t }, { onSuccess = {}, onFinally = {} }) => {
  updateExpense(
    {
      ...params,
      expenseId,
    },
    {
      onSuccess: (result) => {
        onSuccess(result);
      },
      onError: () => showGlobalNotification("error", "errorTitle", t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleSearchExpenses = ({ text, t }, { onSuccess = {}, onFinally = {} }) => {
  searchExpenses(text, {
    onSuccess: (result) => {
      onSuccess(result);
    },
    onError: () => showGlobalNotification("error", "errorTitle", "errorDescription"),
    onFinally: () => {},
  });
};
