import { PageHeader, Button, Tooltip, Descriptions } from "antd";
import { DeleteOutlined, EditOutlined, AmazonOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";
import { globals, priceFormat, dateFormat4 } from "../../../constants/globals";

const Header = ({ setPage, handleEdit, handleArchived, handleNewLinkModal, t }) => {
  const { currentPack } = useSelector((store) => store.pack);

  const renderContent = (column = 3) => {};
  const extraContent = <></>;

  const Content = ({ children, extra }) => (
    <div className="content">
      <div className="main">{children}</div>
      <div className="extra">{extra}</div>
    </div>
  );

  return (
    <>
      {currentPack._id ? (
        <PageHeader
          avatar={{ src: "https://avatars1.githubusercontent.com/u/8186664?s=460&v=4" }}
          className="site-page-header-responsive"
          onBack={() => setPage(globals.PAGE_MAIN)}
          title={`${currentPack.name}`}
          subTitle={currentPack?.tag}
          extra={[
            <Tooltip placement="topLeft" title="Prompt Text">
              <Button className="secondary" key="3" size="large" onClick={() => handleArchived(currentPack)}>
                <DeleteOutlined /> {t("delete")}
              </Button>{" "}
            </Tooltip>,
            <Tooltip placement="topLeft" title="Prompt Text">
              <Button
                className="secondary"
                onClick={() => handleNewLinkModal()}
                shape="square"
                icon={<AmazonOutlined />}
                size="large"
                type="primary"
              >
                {t("uploadImageS3")}
              </Button>
            </Tooltip>,
            <Tooltip placement="topLeft" title="Prompt Text">
              <Button key="1" size="large" onClick={() => handleEdit(currentPack)}>
                <EditOutlined /> {t("edit")}
              </Button>{" "}
            </Tooltip>,
          ]}
        >
          <Content extra={extraContent}>{renderContent()}</Content>
        </PageHeader>
      ) : (
        t("loading")
      )}
    </>
  );
};

export default Header;
