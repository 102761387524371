import { PageHeader, Button, Descriptions, Typography, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, AmazonOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";
import { globals, numbers } from "../../../constants/globals";

const { Link } = Typography;

const Header = ({
  setPage,
  handleEdit,
  openDeleteModal,
  handleDetails,
  handleUploadImage,
  setSubpage,
  extradata,
  t,
}) => {
  const { currentUser } = useSelector((store) => store.user);

  const renderContent = (column = 3) => (
    <div className="container-subpages">
      <Descriptions size="small" column={column}>
        <Descriptions.Item label={t("documentType")}>{currentUser.documentType}</Descriptions.Item>
        <Descriptions.Item label={t("document")}>{currentUser.document}</Descriptions.Item>
        <Descriptions.Item label={t("email")}>{currentUser.email}</Descriptions.Item>
        <Descriptions.Item label={t("phone")}>{currentUser.phone}</Descriptions.Item>
        <Descriptions.Item label={t("category")}>{currentUser.category}</Descriptions.Item>
        <Descriptions.Item label={t("address")}>{currentUser.address}</Descriptions.Item>
        <Descriptions.Item label={t("city")}>{currentUser.city || extradata.city}</Descriptions.Item>
        <Descriptions.Item label={t("region")}>{currentUser.region || extradata.region}</Descriptions.Item>
        <Descriptions.Item label={t("country")}>{currentUser.country || extradata.country}</Descriptions.Item>
        <Descriptions.Item label={t("startdate")}>{currentUser.startdate}</Descriptions.Item>
        <Descriptions.Item label={t("enddate")}>{currentUser.closuredate}</Descriptions.Item>
        <Descriptions.Item label={t("Referer By")}>
          {currentUser.refererId ? (
            <Link href="#!" onClick={() => handleDetails({ _id: currentUser.refererId._id })}>
              {currentUser.refererId.firstname} {currentUser.refererId.lastname}
            </Link>
          ) : (
            "--"
          )}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );

  const extraContent = <></>;

  const Content = ({ children, extra }) => (
    <div className="content">
      <div className="main">{children}</div>
      <div className="extra">{extra}</div>
    </div>
  );

  const callback = (key) => {
    setSubpage(key);
  };

  return (
    <>
      {currentUser._id ? (
        <PageHeader
          avatar={{ src: currentUser.avatar || "https://cluuftracks.herokuapp.com/bot.gif" }}
          className="site-page-header-responsive"
          onBack={() => setPage(globals.PAGE_MAIN)}
          title={`${currentUser.firstname} ${currentUser.lastname || ""}`}
          subTitle="..."
          extra={[
            <Tooltip placement="topLeft" title={t("delete")}>
              <Button
                className="secondary"
                size="large"
                key={numbers.THREE}
                onClick={() => openDeleteModal(currentUser)}
              >
                <DeleteOutlined /> {t("delete")}
              </Button>{" "}
            </Tooltip>,
            <Tooltip placement="topLeft" title="Prompt Text">
              <Button
                className="secondary"
                onClick={() => handleUploadImage("Gallery")}
                shape="square"
                icon={<AmazonOutlined />}
                size="large"
                type="primary"
              >
                {t("uploadFile")}
              </Button>
            </Tooltip>,
            <Tooltip placement="topLeft" title="Prompt Text">
              <Button
                className="secondary"
                onClick={() => handleUploadImage("Avatar")}
                shape="square"
                icon={<AmazonOutlined />}
                size="large"
                type="primary"
              >
                {t("uploadAvatar")}
              </Button>
            </Tooltip>,
            <Tooltip placement="topLeft" title={t("edit")}>
              <Button size="large" key={numbers.ONE} onClick={() => handleEdit(currentUser)}>
                <EditOutlined /> {t("edit")}
              </Button>{" "}
            </Tooltip>,
          ]}
        >
          <Content extra={extraContent}>{renderContent()}</Content>
        </PageHeader>
      ) : (
        t("loading")
      )}
    </>
  );
};

export default Header;
