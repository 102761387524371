import { setLocalStorage } from "../utils/localStorage";

const dataInicial = {
  request: false,
  reloadPlan: Math.floor(Math.random() * 100000000),
  reload: Math.floor(Math.random() * 100000000),
  currentPlan: {},
};

const CURRENT_PLAN = "CURRENT_PLAN";
const RELOAD_PLAN = "RELOAD_PLAN";

export default function planReducer(state = dataInicial, action) {
  switch (action.type) {
    case CURRENT_PLAN:
      return { ...state, currentPlan: action.payload };

    case RELOAD_PLAN:
      return { ...state, reloadPlan: Math.floor(Math.random() * 100000000) };

    default:
      return { ...state };
  }
}

export const setPlan = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: CURRENT_PLAN,
    payload,
  });
};

export const setPlanRedux = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: CURRENT_PLAN,
    payload,
  });
};

export const reloadPlanRedux = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: RELOAD_PLAN,
    payload,
  });
};
