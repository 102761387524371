export const setCookies = (name, value) => {
  document.cookie = `${name}=${value}`;
};
export const getCookies = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};
export const removeCookie = (name) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const setPasswordCookie = (name, value) => {
  document.cookie = `${name}=%8be-967d-GA1.2.379707925.1623725533f622188be-967d-GA1.2.379707925.1623725533f622188be-967d-GA1.2.379707925.1623725533f622188be-967d-GA1.2.379707925.1623725533f622188be-967d-GA1.222e-967d-43232d-8d-834f-28be-967d-GA1.2.379707925.1623725533f622188be-967d-GA1.2.379707925.1623725533f62218291f6%22%34f-2291f622189cf69e-934f-201a%22%969%${value}%969%clflsd6791f69cf69e-9GA1.2.379707925.16237255338be-967d-GA1.2.379707925.1623725533f622188be-967d-GA1.2.379707925.1623725533f62218`;
};
