import { useState } from "react";
import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";

const ActionsPacks = ({ setFilters, filters }) => {
  const { t } = useTranslation();

  const [active, setActive] = useState("");

  const handleSelected = (typeSelected) => {
    setActive(typeSelected);
    setFilters({ ...filters, active: typeSelected });
  };

  return (
    <Space>
      <Button
        className="tag-button-table"
        size="small"
        type={active === "" ? "primary" : "default"}
        onClick={() => handleSelected("")}
      >
        {t("all")}
      </Button>
      <Button
        className="tag-button-table"
        size="small"
        type={active === true ? "primary" : "default"}
        onClick={() => handleSelected(true)}
      >
        {t("active")}
      </Button>
      <Button
        className="tag-button-table"
        size="small"
        type={active === false ? "primary" : "default"}
        onClick={() => handleSelected(false)}
      >
        {t("inactive")}
      </Button>
    </Space>
  );
};

export default ActionsPacks;
