import ReactExport from "react-export-excel";
import { Button, Tooltip } from "antd";
import excelicon from "../../../assets/excel-icon.png";
import { excelReportApp } from "../requests";

const Swal = require("sweetalert2");

const ReactExcel = ({ apps, guide = {} }) => {
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  const registrar = () => {
    excelReportApp(
      { name: "Reporte de las Aplicaciones" },
      {
        onSuccess: () => {
          Swal.fire({
            icon: "success",
            text: "Se ha descargado el reporte exitosamente",
          });
        },
        onFinally: () => {},
        onError: () => {},
      }
    );
  };

  const addTourGuide = (data) => {
    const result = [];
    result.push({
      userId: {
        firstname: `Guia: ${guide.firstname || ""}`,
        lastname: `${guide.lastname || ""}`,
        document: `${guide.document || ""}`,
        phone: `${guide.phone || ""}`,
      },
    });
    data.forEach((element) => {
      result.push(element);
    });
    return result;
  };

  return (
    <>
      <ExcelFile
        filename="Aplicaciones cluuf"
        element={
          <Tooltip title="Exportar la seleccion actual a un archivo en excel">
            <Button className="secondary" onClick={() => registrar()}>
              <img width="20" alt="excel" src={excelicon} />
            </Button>
          </Tooltip>
        }
      >
        <ExcelSheet data={addTourGuide(apps)} name="Aplicaciones">
          <ExcelColumn label="Nombres" value={(col) => col.userId.firstname} />
          <ExcelColumn label="Apellidos" value={(col) => col.userId?.lastname} />
          <ExcelColumn label="Documento" value={(col) => col.userId?.document} />
          <ExcelColumn label="Teléfono" value={(col) => col.userId?.phone} />
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};

export default ReactExcel;
