import ReactExport from "react-export-excel";
import { Button, Tooltip } from "antd";
import excelicon from "../../../assets/excel-icon.png";
import {
  eventStatusFormatWord,
  formaterModeTrans,
  formaterFreePlan,
  formaterStatusTrans,
} from "../../../constants/globals";

const ReactExcel = ({ transs }) => {
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  return (
    <>
      <ExcelFile
        filename="Transacciones cluuf"
        element={
          <Tooltip title="Exportar la seleccion actual a un archivo en excel">
            <Button className="secondary">
              <img width="20" alt="excel" src={excelicon} />
            </Button>
          </Tooltip>
        }
      >
        <ExcelSheet data={transs} name="Completa">
          <ExcelColumn label="Codigo" value="code" />
          <ExcelColumn label="Estado" value={(col) => eventStatusFormatWord(col.status)} />
          <ExcelColumn label="Servicio" value={(col) => col.packId.name} />
          <ExcelColumn label="Actividad" value={(col) => col.planId.name} />
          <ExcelColumn label="Nombres" value={(col) => col.userId.firstname} />
          <ExcelColumn label="Apellidos" value={(col) => col.userId?.lastname} />
          <ExcelColumn label="Email" value={(col) => col.userId.email} />
          <ExcelColumn label="Tipo de Documento" value={(col) => col.userId?.documentType} />
          <ExcelColumn label="Documento" value={(col) => col.userId?.document} />
          <ExcelColumn label="Gratis?" value={(col) => formaterFreePlan(col.planId.isFree)} />
          <ExcelColumn label="Costo" value={(col) => String(col.amount || "0").trim()} />
          <ExcelColumn label="Modalidad de pago" value={(col) => formaterModeTrans(col.mode) || "---"} />
          <ExcelColumn label="Referencía del pago" value={(col) => col.reference} />
          <ExcelColumn label="Estado del pago" value={(col) => formaterStatusTrans(col.isPending)} />
          <ExcelColumn label="Codigo pago" value={(col) => col.code} />
          <ExcelColumn label="Fecha de pago" value={(col) => col.createAt} />
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};

export default ReactExcel;
