import { useState, useEffect } from "react";
import { apiRoutes } from "../../../constants/routes";
import useFetch from "../../shared/hooks/useFetch";
import { getUserId } from "../../../utils/localStorage";

const useApps = (userId = null, planId = null, type, extras = {}) => {
  const [apps, setApps] = useState([]);
  const [filters, setFilters] = useState({
    all: "", // busqueda general
    usersId: ["60e2df255e7f988f6fb6c89b", "60e0e231403f685eaecc578f"],
    searchByUsers: false,
    description: "",
    active: "",
    name: "",
    isProgress: true,
    isClosed: false,
    isPending: true,
    isStarting: extras.isStarting || true,
    isEnding: extras.isEnding || "",
    status: "",
    kind: extras.kind || "",
    isForm: extras.isForm || "",
    sord: "desc",
    sidx: "_id",
    userId,
    type,
    planId,
    rootId: getUserId(),
  });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 30, total: 0 });
  const { data, loading, refetch } = useFetch(
    apiRoutes.APPS.LOAD_GRID,
    "POST",
    new URLSearchParams({ ...filters, page: pagination.current, rows: pagination.pageSize })
  );

  useEffect(() => {
    if (data) {
      setApps(data?.rows);
      setPagination({ ...pagination, current: parseInt(data?.page), total: parseInt(data?.records) });
    }
  }, [data]);

  return { apps, filters, setFilters, pagination, setPagination, loading, refetch };
};

export default useApps;
