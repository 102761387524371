import { useState, useEffect } from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import ManageInstanceForm from "../components/ManageInstanceForm";
import ManageInstanceFormAdvanced from "../components/ManageInstanceFormAdvanced";
import InstanceHeader from "../components/InstanceHeader";
import ManageBucketModal from "../../bucket/components/ManageBucketModal";
import useModal from "../../shared/hooks/useModal";

const { TabPane } = Tabs;

// COMPONENTS

// HELPERS

// REDUX

// REQUEST

// CONSTANTS

const Instance = () => {
  const { t } = useTranslation();
  const [isOpenManageBucketModalGallery, openManageBucketModalGallery, closeManageBucketModalGallery] = useModal();
  const [reload, setReload] = useState(0);
  const [imageName, setImageName] = useState(true);
  const [entityId, setEntityId] = useState(null);

  const callback = (key) => console.log(key);

  const handleUploadGallery = (name) => {
    setImageName(name);
    openManageBucketModalGallery();
  };

  return (
    <>
      <InstanceHeader t={t} />
      <Tabs defaultActiveKey="1" onChange={callback} style={{ background: "#fff" }}>
        <TabPane tab="General" key="1">
          <ManageInstanceForm setEntityId={setEntityId} handleUploadGallery={handleUploadGallery} reload={reload} />
        </TabPane>
        <TabPane tab="Avanzada" key="2">
          <ManageInstanceFormAdvanced />
        </TabPane>
      </Tabs>
      <ManageBucketModal
        entityProp="Instance"
        typeProp="Gallery"
        nameProp={imageName}
        entityId={entityId}
        openModal={isOpenManageBucketModalGallery}
        closeModal={closeManageBucketModalGallery}
        setReload_={setReload}
      />
    </>
  );
};

export default Instance;
