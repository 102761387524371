import { useState } from "react";
import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";

const FiltersTable = ({ setFilters, filters }) => {
  const { t } = useTranslation();

  const [close, setClose] = useState(false);
  const [progress, setProgress] = useState(false);

  const handleCloseSelected = () => {
    setClose(true);
    setProgress(false);
    setFilters({ ...filters, status: "close" });
  };

  const handleProgress = () => {
    setProgress(true);
    setClose(false);
    setFilters({ ...filters, status: "progress" });
  };

  return (
    <>
      <span className="fieldset-consolidate-filters">
        {/* <span className="label-consolidate-button-filter"> {t("general")}</span> */}
        <Space>
          <Button
            className="tag-button-table"
            size="small"
            type={progress === true ? "primary" : "default"}
            onClick={() => handleProgress("")}
          >
            {t("plan_activitiesActives")}
          </Button>

          <Button
            className="tag-button-table"
            size="small"
            type={close === true ? "primary" : "default"}
            onClick={() => handleCloseSelected()}
          >
            {t("plan_activitiesInactives")}
          </Button>
        </Space>
      </span>
    </>
  );
};

export default FiltersTable;
