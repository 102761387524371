import { useState, useEffect } from "react";
import { Form, Row, Col, Input, Divider, Space, Button, Select } from "antd";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { confirmAlert } from "react-confirm-alert";

import { withRouter } from "react-router-dom";
import { icons, STATUS_APP, durationFormat } from "../../../constants/globals";
import { saveUserUpdate } from "../../login/request";

import SelectPacks from "../../subscribers/components/SelectPacks";
import SelectPlans from "../../subscribersPlans/components/SelectPlans";

const moment = require("moment");
const Swal = require("sweetalert2");

const defaultValues = {
  firstname: "",
  lastname: "",
  document: "",
  documentType: "",
  paymentMode: "none",
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  paymentReference: "",
  phone: "+57",
  alergies: "",
  bodylesson: "",
  address: "",
  profession: "",
  medium: "",
  city: "",
  description: "",
};

const ActivateApp = ({ selectedApp, refetch, onClose, isDesactivate = false }) => {
  const { t } = useTranslation();
  const instanceStoreRedux = useSelector((store) => store.instance);

  const [setting, setSetting] = useState({});
  const [app, setApp] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [form] = Form.useForm();

  const [packId, setPackId] = useState(selectedApp.packId._id);
  const [plan, setPlan] = useState({});
  const [reload, setReload] = useState(0);
  const [submmit, setSummit] = useState(false);

  const onValuesChange = (values) => {
    setData({ ...data, ...values });
  };

  const onFinish = () => {
    setData({ ...data, app: selectedApp });

    if (data.active) setData({ ...data, status: STATUS_APP.OPEN });
    else setData({ ...data, status: STATUS_APP.CANCEL });
    setSummit(true);

    saveUserUpdate(data, {
      onSuccess: () => {
        Swal.fire({
          icon: "success",
          title: "Operación Exitosa",
        });
        refetch();
        onClose();
      },
      onError: () => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      },
      onFinally: () => {
        setSummit(false);
      },
    });
  };

  const handleSubmit = () => {
    confirmAlert({
      title: "Confirmación",
      message: `Esta seguro que desea continuar?`,
      buttons: [
        {
          label: "Continuar",
          onClick: () => form.submit(),
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const handleDesactivate = () => {
    console.log("Aqui");
    confirmAlert({
      title: "Desactivar aplicación",
      message: `Esta seguro que desea continuar?`,
      buttons: [
        {
          label: "Continuar",
          onClick: () => {
            setData({ ...data, active: false, status: STATUS_APP.CANCEL });
            form.submit();
          },
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    setPlan({});
    setData({
      ...data,
      packId,
    });
  }, [packId]);

  useEffect(() => {
    setData({
      ...data,
      startDate: moment(selectedApp?.startDate),
      closureDate: moment(selectedApp?.startDate).add(plan.duration, durationFormat(plan.durationMode)),
      startdate: moment(selectedApp?.startDate).format("YYYY-MM-DD"),
      closuredate: moment(selectedApp?.startDate)
        .add(plan.duration, durationFormat(plan.durationMode))
        .format("YYYY-MM-DD"),
    });
  }, [plan]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  useEffect(() => {
    setReload(Math.floor(Math.random() * 100000));
    setSetting(instanceStoreRedux.currentInstance);
    setApp(selectedApp);
    if (selectedApp?.planId) {
      setData({
        appId: selectedApp?._id,
        userId: selectedApp?.userId._id,
        firstname: selectedApp?.userId.firstname,
        lastname: selectedApp?.userId.lastname,
        address: selectedApp?.userId.address,
        document: selectedApp?.userId.document,
        documentType: selectedApp?.userId.documentType,
        city: selectedApp?.userId.city,
        phone: selectedApp?.userId.phone,
        email: selectedApp?.userId.email,
        packId: selectedApp?.packId._id,
        planId: selectedApp?.planId._id,
        active: true,
        paymentMode: "none",
        startDate: moment(selectedApp?.startDate, "YYYY/MM/DD"),
        closureDate: moment(selectedApp?.startDate).add(
          selectedApp.planId.duration,
          durationFormat(selectedApp.planId.durationMode)
        ),
      });
      setPlan(selectedApp?.planId);
      setLoading(false);
    }
  }, []);
  // selectedApp?.planId?.duration

  return (
    <>
      {!loading && (
        <Row>
          <Col span={22} offset={1}>
            <Form
              form={form}
              name="manage-category-form-modal "
              className="manage-modal minimal-form"
              onFinish={onFinish}
              labelCol={{ span: 24 }}
              onValuesChange={onValuesChange}
              initialValues={defaultValues}
            >
              <>
                <Row gutter={15} style={{ paddingBottom: 10 }}>
                  <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                    <h3>{`${app?.packId.name} ${app?.planId.name}`}</h3>
                  </Col>
                  <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                    <h5> {`Duración:${app.planId.duration} ${app.planId.durationMode}`} </h5>
                  </Col>
                </Row>
              </>

              {!isDesactivate && !app?.planId?.isFree && (
                <>
                  <Divider orientation="left">Acerca del Pago</Divider>
                  <Row gutter={16}>
                    <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item name="paymentMode" label={<>{icons.dolar}&nbsp; Método de pago</>}>
                        <Select className="select-form-modal">
                          <Select.Option value="none">Sin pago</Select.Option>
                          <Select.Option value="cash">Efectivo</Select.Option>
                          <Select.Option value="debitcard">Tarjeta de Débito</Select.Option>
                          <Select.Option value="creditcard">Tarjeta de Crédito</Select.Option>
                          <Select.Option value="transfer">Transferencía</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item name="paymentReference" label="Número de referencia">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              <Row gutter={[24, 24]}>
                <Col span={24} style={{ paddingTop: 30 }}>
                  <hr />
                  <Space>
                    {isDesactivate && (
                      <Button
                        loading={submmit}
                        key="1"
                        size="large"
                        className="ant-btn secondary error large"
                        onClick={handleDesactivate}
                      >
                        Confirmación de la Cancelación
                      </Button>
                    )}

                    {!isDesactivate && (
                      <Button
                        loading={submmit}
                        key="2"
                        size="large"
                        className="ant-btn secondary primary large"
                        onClick={handleSubmit}
                      >
                        Confirmar y activar la aplicación
                      </Button>
                    )}
                    <Button
                      key="2"
                      onClick={onClose}
                      className="ant-btn secondary error large"
                      size="large"
                      style={{ float: "left" }}
                    >
                      Cerrar
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
};
export default withRouter(ActivateApp);
