import { useState, useEffect } from "react";

import { Form, Button, Col, Row, Input, Space, Avatar } from "antd";

import { handleCheckinUser } from "../functions/index";
import showGlobalNotification from "../../../helpers/showGlobalNotification";

const defaultValues = { message: "" };

const FormCheck = ({ onClose, user, t, appId, refetch = {}, reload }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState({});

  useEffect(() => {
    form.setFieldsValue(data);
  }, [form, setData]);

  const onFinish = (values) => {
    const params = { ...data, ...values };
    handleCheckinUser(
      { userId: user._id, message: params.message, t, appId },
      {
        onSuccess: () => {
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
          refetch();
          onClose();
        },
      }
    );
  };

  const onValuesChange = (values) => {
    setData({ ...setData, ...values });
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [reload]);

  return (
    <Form
      layout="vertical"
      form={form}
      name="manage-category-form-modal"
      initialValues={defaultValues}
      className="manage-modal"
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      onValuesChange={onValuesChange}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="message" label="Alguna observación importante">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Space>
            <Button className="secondary" onClick={onClose}>
              Cancelar
            </Button>
            <Button onClick={handleSubmit} type="primary">
              Registrar asistencia
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default FormCheck;
