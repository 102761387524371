import { Table, Space, Tooltip, Tag } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  TagTwoTone,
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckCircleTwoTone,
  CopyOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import BaseTable from "../../shared/components/BasicTable";
import { dateFormat4, iconCategory, icons } from "../../../constants/globals";

const PlansTable = ({
  selectedUser,
  setSelectedUser,
  setPage,
  history,
  plans,
  refetch,
  initialValues,
  handleDetails,
  handleEdit,
  userId,
  handleArchived,
  handleAddImage,
  t,
  handleActive,
  packData,
  handleDuplicate,
  isEmbed,
  handleBucketkModal,
  formatBody,
  ...restProps
}) => (
  <>
    <BaseTable {...restProps} dataSource={plans} refetch={refetch} scroll={{ x: 1200 }}>
      <Table.Column
        fixed="left"
        width="50px"
        render={(value, row, key) => (
          <h5 style={{ textAlign: "center" }}>
            <b>{key + 1}</b>
          </h5>
        )}
      />
      <Table.Column
        width="150px"
        fixed="left"
        title={t("actions")}
        dataIndex="actions"
        key="actions"
        render={(_, data) => (
          <div>
            <Space size="small">
              <Tooltip title="Editar">
                <a href="#!" onClick={() => handleEdit(data)} role="button" tabIndex={0}>
                  <EditOutlined />
                </a>
              </Tooltip>

              <Tooltip title="Upload Image">
                <a href="#!" onClick={() => handleBucketkModal(data)} role="button" tabIndex={0}>
                  {icons.foto}
                </a>
              </Tooltip>

              <Tooltip title="Duplicar">
                <a href="#!" onClick={() => handleDuplicate(data)} role="button" tabIndex={0}>
                  <CopyOutlined />
                </a>
              </Tooltip>

              <a href="#!" onClick={() => handleArchived(data)} role="button" tabIndex={0}>
                <DeleteOutlined />
              </a>

              <a href="#!" onClick={() => handleActive(data)} role="button" tabIndex={0}>
                {data.active ? (
                  <Tooltip placement="top" title="Haga click para DESACTIVAR">
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title="Haga click para ACTIVAR">
                    <CheckCircleTwoTone twoToneColor="#FF0A50" />
                  </Tooltip>
                )}
              </a>
            </Space>
          </div>
        )}
      />

      <Table.Column
        fixed="left"
        title={t("tagcode")}
        dataIndex="code"
        key="code"
        ordering
        width="120px"
        render={(text, row) => (
          <>
            <span style={{ fontSize: 16, fontWeight: 600 }}>{row.packId.category}</span>
          </>
        )}
      />

      <Table.Column
        fixed="left"
        title={t("tagcode")}
        dataIndex="code"
        key="code"
        ordering
        width="250px"
        render={(text, row) => (
          <>
            <Tag color="volcano">
              <span style={{ fontSize: 16, fontWeight: 600 }}>
                {row.packId.precode}_{row.code}
              </span>
            </Tag>
          </>
        )}
      />

      <Table.Column
        title={t("content")}
        dataIndex="body"
        key="body"
        width="500px"
        render={(text, row) => (
          <Tooltip title={row.body}>
            <h3>
              <Space>{formatBody({ category: row.category, content: row.body })}</Space>
            </h3>
          </Tooltip>
        )}
      />

      <Table.Column
        title={t("content")}
        dataIndex="body"
        key="body"
        width="130px"
        render={(text, row) => (
          <Tooltip title={row.body}>
            <h3>
              <Space>
                {iconCategory(row.category)} {row.category}
              </Space>
            </h3>
          </Tooltip>
        )}
      />
    </BaseTable>
  </>
);

export default withRouter(PlansTable);
