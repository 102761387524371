import { Table, Button } from "antd";
import { ZoomInOutlined } from "@ant-design/icons";

import { withRouter } from "react-router-dom";
import BaseTable from "../../shared/components/BasicTable";

const PlansTable = ({ data, title, openModalTable, total }) => {
  console.log("");
  return (
    <>
      <div className="container-subpages" style={{ minHeight: 220 }}>
        <BaseTable dataSource={data} showPagination={false} scroll={{ y: 150 }}>
          <Table.Column
            title={
              <>
                <span style={{ fontSize: 18, color: "yellow" }}>{total}</span> {title}{" "}
                {openModalTable && (
                  <Button
                    size="small"
                    className="ant-btn secondary primary"
                    style={{ float: "right" }}
                    onClick={openModalTable}
                  >
                    <ZoomInOutlined />
                  </Button>
                )}
              </>
            }
            dataIndex="type"
            key="type"
            render={(value) => <span style={{ textTransform: "uppercase" }}>{value}</span>}
          />
          <Table.Column
            dataIndex="value"
            key="value"
            width="60px"
            render={(value) => (
              <h5 style={{ textAlign: "center", fontSize: 14 }}>
                <b>{value}</b>
              </h5>
            )}
          />
        </BaseTable>
      </div>
    </>
  );
};

export default withRouter(PlansTable);
