import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import user from "./user";
import supplier from "./supplier";
import service from "./service";
import root from "./root";
import master from "./master";
import session from "./session";
import pack from "./pack";
import plan from "./plan";
import app from "./app";
import trans from "./trans";
import guest from "./guest";
import calendar from "./calendar";
import instance from "./instance";
import profile from "./profile";
import bucket from "./bucket";
import group from "./group";
import expense from "./expense";

const rootReducer = combineReducers({
  user,
  supplier,
  service,
  root,
  master,
  session,
  pack,
  plan,
  app,
  trans,
  guest,
  calendar,
  instance,
  profile,
  bucket,
  group,
  expense,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
  return store;
}
