import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Switch,
  InputNumber,
  Checkbox,
  Select,
  Steps,
  Modal,
  Space,
  Result,
  DatePicker,
  Alert,
  TimePicker,
} from "antd";
import { savePack, getPack } from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";
import { constantsPack } from "../constants";
import { setPackRedux } from "../../../redux/pack";
import { icons, durationFormat } from "../../../constants/globals";

import "react-quill/dist/quill.snow.css"; // ES6

const moment = require("moment");

const { Step } = Steps;
const { TextArea } = Input;

const defaultValues = {
  name: "",
  duration: "1",
  durationMode: "DAYS",
  category: "",
  minLimit: 1,
  observation: "",
  active: true,
  departurePlace: "",
  featureImage: "",
  tag: "",
  price: "0",
  avatar: "",
  public: false,
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  type: constantsPack.TYPE,
  position: "0",
  multiple: false,
  umlimited: false,
  paymentLink: "https://",
  description: "",
  excerpt: "",
  itineraries: "",
  exclude: "",
  include: "",
  departureTime: "",
  recomendations: "",
  parentalControl: false,
  backgroundForm: "#000",
  cupon: false,
  webhookCancel: "",
  webhookClose: "",
  webhookInwait: "",
  webhookPayment: "",
  webhookOpen: "",
  emailWelcome: "",
  emailRegistration: "",
  isTemporal: true,
  single: false,
  isDocumentRequired: false,
  isVaccine: true,
  isStrictMode: false,
  defaultInstance: true,
  isTicketsNumber: false,
  maxLimit: 50,
};
const CheckboxGroup = Checkbox.Group;

const defaultCheckedList = ["Apple", "Orange"];

const ManagePackModal = ({ setPackId, packId, openModal, closeModal, refetch, t, type = defaultValues.type }) => {
  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [form] = Form.useForm();
  const [selectedPack, setSelectedPack] = useState(defaultValues);
  const [loaderForm, setLoaderForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [reload, setReload] = useState(0);
  const [alert, setAlert] = useState({ text: "", value: false });
  const [status, setStatus] = useState("progress");

  const handleReset = () => {
    setSelectedPack(defaultValues);
    setReload(Math.floor(Math.random() * 100000));
  };

  const handleStartdate = (values) => {
    setSelectedPack({
      ...selectedPack,
      startdate: moment(values).format("YYYY-MM-DD"),
    });
  };

  const handleDurationMode = (values) => {
    console.log(values);
    setSelectedPack({
      ...selectedPack,
      durationMode: values,
    });
  };

  const convertCSVToArray = (value) => {
    if (value?.departureTime) {
      setSelectedPack({ ...selectedPack, departureTime: value.departureTime.split(",") });
    }
  };

  useEffect(() => {
    console.log(packId, openModal);
    setAlert(false);
    if (openModal) {
      if (packId) {
        setMode("edit");
      } else {
        setMode("new");
      }
    } else {
      handleReset();
      setMode(null);
      setPackId(null);
    }
  }, [openModal]);

  useEffect(() => {
    if (mode === "new") {
      handleReset();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      setLoaderForm(true);
      getPack(packId, {
        onSuccess: (response) => {
          setPackRedux(response.result)(dispatch);
          setSelectedPack({
            ...defaultValues,
            ...response.result,
            departureTime: moment(response.result.departureTime[0], "HH:mm"),
            startdate: moment(response.result.startdate),
            closuredate: moment(response.result.closuredate),
            departureTime2: response.result.departureTime,
          });
          setLoaderForm(false);
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  }, [mode]);

  useEffect(() => {
    form.setFieldsValue(selectedPack);
  }, [form, selectedPack]);

  const [current, setCurrent] = useState(0);

  const handleCloseModal = () => {
    refetch();
    handleReset();
    closeModal();
  };

  const onFinish = (values) => {
    let departureTime;
    let startdate;
    let closuredate;

    if (selectedPack.isMultipleTimes) {
      departureTime = selectedPack.departureTime2;
    } else {
      departureTime = moment(selectedPack.departureTime).format("HH:mm");
    }

    if (selectedPack.isRecurrent) {
      startdate = moment().format("YYYY-MM-DD");
      closuredate = moment().format("YYYY-MM-DD");
    } else {
      startdate = moment(selectedPack.startdate).format("YYYY-MM-DD");
      closuredate = moment(selectedPack.startdate)
        .add(selectedPack.duration, durationFormat(selectedPack.durationMode))
        .format("YYYY-MM-DD");
    }

    const data = {
      ...selectedPack,
      ...values,
      startdate,
      closuredate,
      departureTime,
    };

    savePack(
      {
        ...data,
        packId,
        rootId: sessionStoreRedux.currentSession_id,
      },
      {
        onSuccess: (response) => {
          setPackRedux(response.result)(dispatch);
          setCurrent(0);
          setSelectedPack(defaultValues);
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => handleCloseModal(),
      }
    );
  };

  const onValuesChange = (values) => {
    if (values?.departureTime2) {
      convertCSVToArray(values);
      setSelectedPack({ ...selectedPack, departureTime: values?.departureTime2, ...values });
    } else {
      setSelectedPack({ ...selectedPack, ...values });
    }
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  const handleIsfree = (checked) => {
    setSelectedPack({ ...selectedPack, isFree: checked });
  };

  const handleIsMultipleTimes = (checked) => {
    setSelectedPack({ ...selectedPack, isMultipleTimes: checked });
  };

  const handleIsRecurrent = (checked) => {
    setSelectedPack({ ...selectedPack, isRecurrent: checked });
  };

  const steps = [
    {
      title: "Datos Generales",
      content: (
        <Row gutter={24}>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <Form.Item
              name="name"
              tooltip="Nombre"
              label={t("name")}
              rules={[
                {
                  required: true,
                  message: `Por favor ingrese el nombre`,
                },
              ]}
            >
              <Input placeholder="" value={selectedPack?.name} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item name="maxLimit" label={t("maxLimit")}>
              <InputNumber value={selectedPack?.maxLimit} max="1000" min="1" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="isRecurrent"
              labelAlign="center"
              label="Evento Recurrente"
              tooltip="Solo aplica para cuando seran varios contenidos"
            >
              <Switch checked={!!selectedPack?.isRecurrent} onChange={handleIsRecurrent} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="isMultipleTimes"
              labelAlign="center"
              label="Más de una vez al dia?"
              tooltip="Solo aplica para cuando seran varios contenidos"
            >
              <Switch checked={!!selectedPack?.isMultipleTimes} onChange={handleIsMultipleTimes} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="isFree"
              labelAlign="center"
              label="El evento es gratuito?"
              tooltip="Solo aplica para cuando seran varios contenidos"
            >
              <Switch checked={!!selectedPack?.isFree} onChange={handleIsfree} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="excerpt" label={t("excerpt")}>
              <TextArea style={{ minHeight: 70 }} placeholder="" value={selectedPack?.excerpt} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item initialValue={checkedList} name="categorization" label="Categoría">
              <CheckboxGroup style={{ width: "100%" }} defaultValue={checkedList}>
                <Row gutter={15}>
                  <Col span={4}>
                    <Checkbox value="Naturaleza">Naturaleza</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Cultural">Cultural</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Extremo">Extremo</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Romantico">Romantico</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Walking">Walking</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Espiritual">Espiritual</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Fitness">Fitness</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Academico">Academico</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Artistico">Artistico</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Salud">Salud</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Belleza">Belleza</Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Ciencia">Ciencia</Checkbox>
                  </Col>
                </Row>
              </CheckboxGroup>
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      title: "Fecha y hora",
      content: (
        <Row gutter={16}>
          {!selectedPack?.isRecurrent && (
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="startDate"
                label={
                  <>
                    {icons.calendar} &nbsp; {t("app_dateFrom")}
                  </>
                }
              >
                <DatePicker format="YYYY/MM/DD" onChange={handleStartdate} />
              </Form.Item>
            </Col>
          )}

          {selectedPack?.isRecurrent && (
            <Col span={24}>
              <Form.Item initialValue={checkedList} name="availableDays" label={t("availableDays")}>
                <CheckboxGroup style={{ width: "100%" }} defaultValue={checkedList}>
                  <Row gutter={15}>
                    <Col span={3}>
                      <Checkbox value="0">Sunday</Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value="1">Monday</Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value="2">Tuesday</Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value="3">Wednesday</Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value="4">Thursday</Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value="5">Friday</Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value="6">Saturday</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
          )}

          {!selectedPack?.isMultipleTimes && (
            <Col span={6}>
              <Form.Item name="departureTime" label={t("departureTime")}>
                <TimePicker minuteStep={15} secondStep={60} />
              </Form.Item>
            </Col>
          )}

          {selectedPack?.isMultipleTimes && (
            <Col span={12}>
              <Form.Item name="departureTime2" label={t("departureTime")}>
                <Input value={selectedPack?.departureTime} />
              </Form.Item>
            </Col>
          )}
          <Col span={6}>
            <Form.Item name="durationMode" label={t("durationMode")}>
              <Select className="select-form-modal" onChange={handleDurationMode}>
                <Select.Option value="HOURS">Horas</Select.Option>
                <Select.Option value="DAYS">días</Select.Option>
                <Select.Option value="MONTHS">Meses</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item name="duration" label={t("duration")}>
              <InputNumber value={selectedPack?.duration} max="360" min="1" />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      title: "Costo del Evento",
      content: (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="price" label={t("price")}>
              <InputNumber
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                value={selectedPack?.price}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="paymentLink" label={t("paymentLink")}>
              <Input value={selectedPack?.paymentLink} />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      title: "Cierre del Evento",
      content: (
        <Result
          status="success"
          title=""
          subTitle=""
          extra={[
            <Button
              className="ant-btn secondary primary large"
              size="large"
              type="primary"
              key="console"
              onClick={() => {
                handleSubmit();
              }}
            >
              Guardar Evento
            </Button>,
          ]}
        />
      ),
    },
  ];

  const next = () => {
    setAlert(false);
    setStatus("progress");

    if (current === 0) {
      if (String(selectedPack.name).length < 3) {
        setAlert({
          text: "Por favor valide el nombre del evento",
          value: true,
        });
        setStatus("error");
        return false;
      }
      if (String(selectedPack.duration) < 1) {
        setAlert({
          text: "Por favor valide la duración del evento",
          value: true,
        });
        setStatus("error");
        return false;
      }
      if (String(selectedPack.maxLimit) < 1) {
        setAlert({
          text: "Por favor valide la capacidad maxima de invitados",
          value: true,
        });
        setStatus("error");
        return false;
      }

      if (moment().diff(selectedPack.startdate, "days") > 1 || !moment(selectedPack.startdate).isValid()) {
        setAlert({
          text: "Por favor valide la fecha de inicio",
          value: true,
        });
        setStatus("error");
        return false;
      }

      if (String(selectedPack.excerpt).length < 10) {
        setAlert({
          text: "Por favor ingrese una descripcion valida del Evento",
          value: true,
        });
        setStatus("error");
        return false;
      }

      if (String(selectedPack.durationMode).length < 3) {
        setAlert({
          text: "Por favor seleccion un tipo de duracion del evento",
          value: true,
        });
        setStatus("error");
        return false;
      }

      console.log("");
    }

    if (current === 1) {
      console.log("");
    }

    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <Modal
      closable
      title="Registrar un nuevo evento"
      visible={openModal}
      maskClosable="true"
      footer={null}
      onCancel={handleCloseModal}
      width="800px"
    >
      <Form
        form={form}
        name="manage-category-form-modal"
        initialValues={defaultValues}
        className="manage-modal"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        onValuesChange={onValuesChange}
      >
        <>
          <Steps current={current} status={status}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>

          <div className="steps-content">{steps[current].content}</div>
          <div className="steps-action">
            <Space>
              {current > 0 && (
                <Button className="ant-btn secondary error" style={{ margin: "0 8px" }} onClick={() => prev()}>
                  Anterior
                </Button>
              )}

              {current < steps.length - 1 && (
                <Button className="ant-btn secondary primary" type="primary" onClick={() => next()}>
                  Siguiente
                </Button>
              )}
            </Space>
          </div>
          {alert.value && <Alert message={alert.text} type="error" style={{ marginTop: 10 }} />}
        </>
      </Form>
    </Modal>
  );
};

ManagePackModal.defaultProps = {
  setPackId: () => {},
};

export default ManagePackModal;
