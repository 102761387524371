/* eslint-disable no-sparse-arrays */
/* eslint-disable no-unused-expressions */
import { useState, useEffect } from "react";
import { Drawer, Affix, Col, Row, Tabs, Layout, Spin, Badge, Space, Button } from "antd";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert";
import useApps from "../subscribersApps/hooks/useApps";
import useModal from "../shared/hooks/useModal";

import AppsCardsAnalistWeb from "../subscribersApps/components/AppsCardsAnalistWeb";
import HeaderUserWeb from "./components/headerUserWeb";
import GeneralUser from "./components/generalUser";

import AddUserCheckin from "../subscribersUsers/components/addUserCheckin";
import AddUserGuest from "../subscribersUsers/components/addUserGuest";
import CreateApp from "../subscribersApps/components/CreateApp";
import ManageAppModalSubscription from "../subscribersApps/components/ManageAppModalSubscription";

import CreateAppProduct from "../subscribersApps/components/CreateAppProduct";
import CreateAppEvent from "../subscribersApps/components/CreateAppEvent";
import CreateAppRental from "../subscribersApps/components/CreateAppRental";
import CreateAppNewEvent from "../subscribersApps/components/CreateAppNewEvent";
import EditEvent from "../subscribersApps/components/EditEvent";

import CreateSingleApp from "../subscribersApps/components/CreateSingleApp";

import ActivateApp from "../subscribersApps/components/ActivateApp";
import CloseAppWeb from "../subscribersApps/components/CloseAppWeb";

import RenovateApp from "../subscribersApps/components/RenovateApp";

import ManageUserModal from "../subscribersUsers/components/ManageUserModal";
import ManageUserExtraModal from "../subscribersUsers/components/ManageUserExtraModal";

import ManageBucketModal from "../bucket/components/ManageBucketModal";
import SearchUserAnalistWeb from "../subscribersUsers/components/searchUserAnalistWeb";

import EventsContainer from "../events/containers/EventAnalist";

import AppsContainer from "../subscribersApps/containers/Apps";
import AddPaymentAnalist from "../subscribersTrans/components/addPaymentAnalist";

import { handleCheckinUser } from "../subscribersUsers/functions/index";

import DashboardSubscriptions from "../subscribersDashboard/containers/DashboardSubscriptions";
import DashboardEvents from "../subscribersDashboard/containers/DashboardEvents";

import {
  handleRecalculateApps,
  handleGetAppCountPending,
  handleGetApp,
  handleClosedApp,
  handleArchivedConfirm,
} from "../subscribersApps/functions";
import { handleGetPlansByPackId } from "../subscribersPlans/functions";
import { handleGetAppMetrics, handleGetEventMetrics, handleCurrentAppActives } from "../subscribersDashboard/functions";

import CardServices from "./components/cardServices";
import CardLinkPlans from "./components/cardLinkPlans";

import { getSettings } from "../../helpers/settings";

import {
  numbers,
  actionsUsers,
  uploadType,
  icons,
  openListinPagesPack,
  colorsSystem,
  isModuleValid,
  globals,
  dateFormat4,
  handleGoToWebsite,
} from "../../constants/globals";

import logo from "../../assets/robot.png";

import { spaRoutes } from "../../constants/routes";

const moment = require("moment");

const Swal = require("sweetalert2");

const { Content } = Layout;

const { TabPane } = Tabs;

const initialValues = {
  firstname: "",
  lastname: "",
  role: "USER",
  address: "",
  city: "",
  region: "",
  country: "",
  document: "",
  documentType: "",
  observation: "",
  birthdate: "1990/01/01",
  category: "cliente",
  type: "Normal",
  email: "",
  phone: "",
  reference: "",
  active: "",
  startdate: "1990/01/01",
  closuredate: "1990/01/01",
  refererId: null,
};

const Analist = ({ mainReload, currentPage, match, history }) => {
  const { t } = useTranslation();

  const [isOpenManageBucketModal, openManageBucketModal, closeManageBucketModal] = useModal();
  const [isOpenManagePlanBucketModal, openManagePlanBucketModal, closeManagePlanBucketModal] = useModal();

  const sessionStoreRedux = useSelector((store) => store.session);
  const instanceStoreRedux = useSelector((store) => store.instance);
  const planStoreRedux = useSelector((store) => store.plan);

  const { apps, filters, setFilters, pagination, setPagination, loading, refetch } = useApps(
    null,
    null,
    "SUBSCRIPTION"
  );

  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState(false);
  const [appSelected, setAppSelected] = useState({});
  const [userSelected, setUserSelected] = useState({});

  const [planSelected, setPlanSelected] = useState({});

  const [planId, setPlanId] = useState(null);
  const [showApps, setShowApps] = useState(false);

  const [plans, setPlans] = useState({});

  const [option, setOption] = useState({});
  const [reload, setReload] = useState(0);
  const [filters_, setFilters_] = useState({});
  const [userSection, setUserSection] = useState(false);
  const [userId, setUserId] = useState(false);
  const [userExtraId, setUserExtraId] = useState(false);

  const [packId, setPackId] = useState(null);
  const [isMobile, setMobile] = useState(false);

  const [tab, setTab] = useState(currentPage);
  const [dashboardTab, setDashboardTab] = useState(0);

  const [activeTab, setActiveTab] = useState(0);
  const [titleDraw, setTitleDraw] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerMiniOpen, setIsDrawerMiniOpen] = useState(false);
  const [desactivate, setDesactivate] = useState(false);

  const [reloadPlans, setReloadPlans] = useState(false);
  const [reloadApps, setReloadApps] = useState(false);
  const [pendingApps, setPendingApps] = useState(0);
  const [typeEvent, setTypeEvent] = useState("");
  const [blockActions, setBlockActions] = useState(false);
  const [packInfo, setPackInfo] = useState({});
  const [appsByClose, setAppsByClose] = useState({});
  const [lastCheckins, setLastCheckins] = useState({});
  const [appsByRegisters, setAppsByRegisters] = useState({});
  const [refresh, setRefresh] = useState("null");
  const [loadingReload, setLoadingReload] = useState(false);

  const [typeUploadUser, setTypeUploadUser] = useState(uploadType.AVATAR);
  const [isOpenManageUsersModal, openManageUsersModal, closeManageUsersModal] = useModal();
  const [isOpenManageUserExtraModal, openManageUserExtraModal, closeManageUserExtraModal] = useModal();

  const handleLogout = () => {
    document.location = `/logout/${instanceStoreRedux.currentInstance.alias}`;
  };

  const closeUserSection = () => {
    setUserSection(false);
    setAppSelected({});
    setVisible(false);
    setUser({});
    setUserId("");
    setAppSelected({});
    setActiveTab(numbers.ONE);
    setTab(numbers.ONE);
    history.push(`${spaRoutes.ANALISTWEB}`);
  };

  const handleAdminView = () => {
    document.location = `/home/31`;
  };

  const onClose = () => {
    setVisible(false);
    setIsDrawerMiniOpen(false);
    setIsDrawerOpen(false);
    setDesactivate(false);
  };

  const handleCheckin = (app, checkinToday = false) => {
    if (!blockActions) {
      // setTimeout(() => setBlockActions(false), 2000);
      let message = `Registrar asistencia para ${app.userId.firstname}`;
      if (checkinToday) message = `Hoy se realizo una asistencia para ${app.userId.firstname}`;
      if (app?.checkins && app?.planId) {
        console.log("validando", app.checkins.length, app.planId.limitCheckin);

        if (app.planId.limitCheckin !== null && parseInt(app.checkins.length) + 1 >= app.planId.limitCheckin) {
          Swal.fire({
            title: `Esta aplicación cumplira el limite de asistencias permitidas  ${
              parseInt(app.checkins.length) + 1
            }/${app.planId.limitCheckin}, \n si decide continuar la aplicación será FINALIZADA`,
            html: "<b>Desea continuar y finalizar la aplicación?</b>",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: colorsSystem.red,
            cancelButtonColor: colorsSystem.blue,
            confirmButtonText: t("common_yesIWantContinue"),
            cancelButtonText: t("common_cancel"),
          }).then((result) => {
            if (result.isConfirmed) {
              setBlockActions(true);
              handleCheckinUser(
                { userId: app.userId._id, message, t, appId: app._id, setBlockActions },
                {
                  onSuccess: () => {
                    setBlockActions(false);
                    Swal.fire({
                      icon: "success",
                      title: `${app.userId.firstname} ${app.userId.lastname || ""}`,
                      text: "Se ha registrado la asistencía exitosamente",
                    });

                    handleClosedApp(
                      {
                        appId: app._id,
                        planId: app.planId._id,
                        instanceId: app.instanceId._id,
                        observation: "Limite de asistencias",
                      },
                      {
                        onSuccess: () => {
                          refetch();
                          onClose();
                        },
                        onError: () => {},
                        onFinally: () => {},
                      }
                    );
                  },
                }
              );
            }
          });
        } else {
          setBlockActions(true);
          handleCheckinUser(
            { userId: app.userId._id, message, t, appId: app._id, setBlockActions },
            {
              onSuccess: () => {
                setBlockActions(false);
                Swal.fire({
                  icon: "success",
                  title: `${app.userId.firstname} ${app.userId.lastname || ""}`,
                  text: "Se ha registrado la asistencía exitosamente",
                });
                refetch();
                onClose();
              },
            }
          );
        }
      }
    }
  };

  const handleGuest = (app) => {
    setReload(Math.floor(Math.random() * 100000));
    setUser(app.userId || null);
    setAppSelected(app);
    setOption(actionsUsers.GUEST.number);
    setTitleDraw(actionsUsers.GUEST.title);
    setIsDrawerMiniOpen(true);
    setVisible(true);
  };

  const handleAddPayment = (app) => {
    setReload(Math.floor(Math.random() * 100000));
    setUser(app.userId || null);
    setAppSelected(app);
    setOption(actionsUsers.PAYMENT.number);
    setTitleDraw(actionsUsers.PAYMENT.title);
    setIsDrawerMiniOpen(true);
    setVisible(true);
  };

  const handleRenovateApp_ = (app) => {
    setAppSelected(app);
    setReload(Math.floor(Math.random() * 100000));
    setOption(actionsUsers.RENOVATE.number);
    setTitleDraw(actionsUsers.RENOVATE.title);
    setIsDrawerOpen(true);
    setVisible(true);
  };

  const handleRecalculateApps_ = () => {
    handleRecalculateApps({ onSuccess: () => {}, onError: () => {}, onFinally: () => {} });
  };

  const handleUpdateUser = (app) => {
    setUser(app.userId || null);
    setUserId(app.userId._id || null);
    setAppSelected(app);
    openManageUsersModal();
  };

  const handleUpdateUserExtra = (app) => {
    setUser(app.userId || null);
    setUserId(app.userId._id || null);
    setAppSelected(app);
    openManageUserExtraModal();
  };

  const handleActivateApp = (app) => {
    setAppSelected(app);
    setReload(Math.floor(Math.random() * 100000));
    setOption(actionsUsers.ACTIVATE_APP.number);
    setTitleDraw(actionsUsers.ACTIVATE_APP.title);
    setIsDrawerOpen(true);
    setVisible(true);
  };

  const handleCloseAppWeb = (app) => {
    setAppSelected(app);
    setReload(Math.floor(Math.random() * 100000));
    setOption(actionsUsers.CLOSE_APP_WEB.number);
    setTitleDraw(actionsUsers.CLOSE_APP_WEB.title);
    setIsDrawerOpen(true);
    setVisible(true);
  };

  const handleSingleApp = (app) => {
    setUserId(app.userId._id);
    setUser(app.userId);
    setReload(Math.floor(Math.random() * 100000));
    setOption(actionsUsers.SINGLE_APP.number);
    setTitleDraw(actionsUsers.SINGLE_APP.title);
    setIsDrawerOpen(true);
    setVisible(true);
  };

  const handleArchivedConfirm_ = (app) => {
    confirmAlert({
      title: t("Confirmación"),
      message: t("Esta seguro que desea Eliminar"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => {
            handleArchivedConfirm(
              { app, t, refetch },
              {
                onSuccess: () => {
                  closeUserSection();
                },
              }
            );
          },
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const handleDesactivateApp = (app) => {
    setAppSelected(app);
    setDesactivate(true);
    setReload(Math.floor(Math.random() * 100000));
    setOption(actionsUsers.CLOSE_APP_WEB.number);
    setTitleDraw(actionsUsers.CLOSE_APP_WEB.title);
    setIsDrawerOpen(true);
    setVisible(true);
  };

  const handleUpdateSubscriptionApp = (app) => {
    setAppSelected(app);
    setDesactivate(true);
    setReload(Math.floor(Math.random() * 100000));
    setOption(actionsUsers.UPDATE_APP_SUBSCRIPTION.number);
    setTitleDraw(actionsUsers.UPDATE_APP_SUBSCRIPTION.title);
    setIsDrawerOpen(true);
    setVisible(true);
  };

  const handleUpdateUserFile = ({ app, type }) => {
    setTypeUploadUser(type);
    setReload(Math.floor(Math.random() * 100000));
    setUser(app.userId || "");
    setAppSelected(app);
    openManageBucketModal();
  };

  const handleReload = () => setReload(Math.floor(Math.random() * 100000));

  const handleOpenUser = (app) => {
    handleReload();
    setUser(app.userId || "");
    setUserId(app.userId._id);
    setAppSelected(app);
    setFilters_({ ...filters_, userId: app.userId._id });
    setUserSection(true);
    setActiveTab(numbers.EIGHT);
    setTab(numbers.EIGHT);
  };

  const handleOpenApp = (appId) => {
    console.log("handleOpenApp", appId);
    // history.push(`${spaRoutes.ANALIST}/${appId}`);
  };

  const handlePack = (res) => {
    setPackId(res);
    setShowApps(false);
  };

  const handleChangeTab = (val) => {
    if (val === numbers.ONE) refetch();
    setTab(val);
    setActiveTab(val);
  };

  const handleChangeDashboardTab = (val) => {
    setDashboardTab(val);
  };

  const handleTabs = (tab_) => {
    setShowApps(false);

    if (tab_ === numbers.TWELVE || tab_ === numbers.SIX) {
      setReloadPlans(true);
      handleGetPlansByPackId(
        { packId, t },
        {
          onSuccess: (result) => {
            setReloadPlans(false);
            setPlans(result.result || {});
          },
        }
      );
    }

    if (tab_ === numbers.THIRTEEN) {
      console.log("necesito recargar");
    }

    if (tab_ === numbers.NINE) {
      setFilters({
        ...filters,
        searchByUsers: false,
        status: null,
        planId: null,
        isProgress: "",
        isStarting: "",
        isPending: "",
        isClosed: "",
        kind: "subscription",
        sord: "desc",
        sidx: "_id",
      });
      refetch();
    }

    if (tab_ === numbers.THREE) {
      setFilters({
        ...filters,
        status: "cancel",
        isCanceled: true,
        isProgress: false,
        isStarting: false,
        isPending: false,
        kind: "",
      });
      refetch();
    }

    if (tab_ === numbers.ONE) {
      handleGetAppMetrics(
        {
          desde: moment().format("YYYY-MM-DD"),
          hasta: moment().add("7", "days").format("YYYY-MM-DD"),
        },
        {
          onSuccess: (metricas) => {
            setAppsByClose(metricas.byclosureApps.details.apps);
            setAppsByRegisters(metricas.createApps.details.apps);
          },
        }
      );

      handleGetEventMetrics(
        {
          desde: moment().format("YYYY-MM-DD"),
          hasta: moment().format("YYYY-MM-DD"),
        },
        {
          onSuccess: (metricas) => setLastCheckins(metricas.checkins.data),
        }
      );

      handleCurrentAppActives(
        {
          desde: moment().format("YYYY-MM-DD"),
          hasta: moment().format("YYYY-MM-DD"),
        },
        {
          onSuccess: (metricas) => console.log(metricas),
        }
      );

      setFilters({
        ...filters,
        searchByUsers: false,
        status: null,
        planId: null,
        isProgress: true,
        isStarting: true,
        isPending: false,
        kind: typeEvent,
      });
      refetch();
    }

    if (tab_) {
      setActiveTab(tab_);
      setTab(tab_);
    }
  };

  useEffect(() => {
    // refetch();
  }, [mainReload]);

  useEffect(() => {
    if (match?.params && match?.params?.appId) {
      handleGetApp(match.params.appId, {
        onSuccess: (result) => {
          if (result.ok) handleOpenUser(result.result);
        },
        onError: () => {},
      });
    }

    if (match?.params && match?.params?.plan) {
      setActiveTab(numbers.THIRTEEN);
      setTab(numbers.THIRTEEN);
    }
  }, [match]);

  useEffect(() => {
    console.log(reload);
  }, [reload]);

  useEffect(() => {
    handleChangeTab(localStorage.getItem("cluuf_last_session") || numbers.ONE);
  }, [mainReload]);

  useEffect(() => {
    if (planSelected._id) {
      setReloadApps(true);
      setPlanId(planSelected._id);
      setShowApps(false);
      setTimeout(() => {
        setShowApps(true);
        setReloadApps(false);
      }, 1000);
    }
  }, [planSelected]);

  useEffect(() => {
    console.log("plans", plans);
  }, [plans]);

  useEffect(() => {
    localStorage.setItem("cluuf_last_tabs", tab);

    handleGetAppCountPending({
      onSuccess: (pendings) => setPendingApps(pendings.result),
      onError: () => {},
    });

    handleTabs(tab);
  }, [tab, packId, planStoreRedux.reloadPlan]);

  useEffect(() => {
    if (localStorage.getItem("cluuf_last_tabs")) handleTabs(localStorage.getItem("cluuf_last_tabs"));
    if (window.innerWidth < 769) setMobile(true);
  }, []);

  useEffect(() => {
    if (typeEvent === "event") {
      setFilters({
        ...filters,
        searchByUsers: false,
        status: null,
        planId: null,
        isProgress: true,
        isStarting: true,
        isPending: true,
        isClosed: false,
        kind: typeEvent,
      });
    }

    if (typeEvent === "subscription") {
      setFilters({
        ...filters,
        searchByUsers: false,
        status: null,
        planId: null,
        isProgress: true,
        isStarting: true,
        isClosed: false,
        isPending: false,
        kind: typeEvent,
      });
    }

    if (typeEvent === "product") {
      setFilters({
        ...filters,
        searchByUsers: false,
        status: null,
        planId: null,
        isProgress: "",
        isStarting: "",
        isPending: "",
        isClosed: "",
        kind: typeEvent,
      });
    }

    if (typeEvent === "rental") {
      setFilters({
        ...filters,
        searchByUsers: false,
        status: null,
        planId: null,
        isProgress: "",
        isStarting: "",
        isPending: "",
        isClosed: "",
        kind: typeEvent,
      });
    }

    refetch();
  }, [typeEvent]);

  const reloadInfo = () => {
    if (tab === numbers.THIRTEEN) {
      setLoadingReload(true);
      setRefresh(Math.floor(Math.random() * 1000000000));
      setTimeout(() => setLoadingReload(false), 1000);
    }

    if (tab === numbers.ONE) {
      setLoadingReload(true);
      refetch();
      setTimeout(() => setLoadingReload(false), 2000);
    }
  };

  return (
    <Layout className="layout analist-layout">
      <Affix offsetTop={-5}>
        <div className="main-header" style={{ padding: 10, background: "#1b203e", height: 60 }}>
          <Row>
            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
              <h1 style={{ color: "#fff", paddingLeft: 50, marginTop: "-5px" }} className="special-font big-font bold">
                <img src={logo} alt="logo" style={{ width: 30, position: "absolute" }} />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{instanceStoreRedux.currentInstance.alias}
              </h1>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
              <Space style={{ float: "right", paddingRight: 50 }}>
                <Button
                  loading={loadingReload}
                  className="ant secondary info "
                  key="4"
                  onClick={() => handleGoToWebsite({ instanceStoreRedux, getSettings })}
                >
                  {icons.edit} Editar website
                </Button>
                <Button loading={loadingReload} className="ant secondary info" key="4" onClick={reloadInfo}>
                  {icons.reload} Recargar data
                </Button>
                {sessionStoreRedux.currentSession.role === "SUPERADMIN" && (
                  <Button className="ant secondary error" key="3" onClick={handleAdminView}>
                    {icons.guest} Ir al panel admin
                  </Button>
                )}

                <Button className="ant secondary error" key="2" onClick={handleLogout}>
                  {icons.close} Cerrar sesión
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      </Affix>
      <Content style={{ background: "#EFF4FB", marginTop: 20 }}>
        <Layout className="layout">
          <Content className="container-interno">
            <div className="site-layout-content box-white">
              {userSection && (
                <HeaderUserWeb
                  app={appSelected}
                  user={user}
                  closeUserSection={closeUserSection}
                  handleCheckin={handleCheckin}
                  handleArchivedConfirm={handleArchivedConfirm_}
                  handleGuest={handleGuest}
                  handleUpdateUser={handleUpdateUser}
                  handleUpdateUserFile={handleUpdateUserFile}
                  handleOpenUser={handleOpenUser}
                  handleAddPayment={handleAddPayment}
                  instanceStoreRedux={instanceStoreRedux}
                  handleSingleApp={handleSingleApp}
                  handleActivateApp={handleActivateApp}
                  handleDesactivateApp={handleDesactivateApp}
                  handleRenovateApp={handleRenovateApp_}
                  isModuleValid={isModuleValid}
                  handleUpdateUserExtra={handleUpdateUserExtra}
                  handleUpdateSubscriptionApp={handleUpdateSubscriptionApp}
                />
              )}
              {blockActions && (
                <Row>
                  <Col span={24} style={{ margin: "auto" }}>
                    <p style={{ textAlign: "center" }}>
                      <Spin tip={t("contact_ckeckProcessing")} />{" "}
                    </p>
                  </Col>
                </Row>
              )}

              <div className="card-container" style={{ marginTop: 0 }}>
                <Tabs activeKey={activeTab} type="card" onChange={handleChangeTab}>
                  {/*
                  {!userSection && (
                    <TabPane tab={<>Inicio</>} key={numbers.SIX}>
                      <div className="site-card-wrapper">
                        <Row gutter={(24, 24)}>
                          <Col span={12} xs={24} sm={24} md={24} lg={9} xl={9}>
                            pedrooo
                          </Col>
                        </Row>
                      </div>
                    </TabPane>

                      {!userSection && sessionStoreRedux.currentSession.role === "SUPERADMIN" && (
                    <TabPane tab={<>Dashboards</>} key={numbers.FIVETEEN}>
                      <Tabs defaultActiveKey="3" centered onChange={handleChangeDashboardTab}>
                        <TabPane tab="Seleccione:" key="3">
                          .....
                        </TabPane>

                        {!userSection && isModuleValid("plan", instanceStoreRedux) && (
                          <TabPane tab="Subscripciones" key="1">
                            <DashboardSubscriptions
                              tab={tab}
                              handleOpenUser={handleOpenUser}
                              handleUpdateUser={handleUpdateUser}
                              dashboardTab={dashboardTab}
                            />
                          </TabPane>
                        )}
                        {!userSection && isModuleValid("event", instanceStoreRedux) && (
                          <TabPane tab="Eventos" key="2">
                            <DashboardEvents
                              tab={tab}
                              handleOpenUser={handleOpenUser}
                              handleUpdateUser={handleUpdateUser}
                              dashboardTab={dashboardTab}
                            />
                          </TabPane>
                        )}
                      </Tabs>
                    </TabPane>
                  )}


                  )} */}

                  {userSection && appSelected._id && (
                    <TabPane tab={<>Resumen {appSelected.code}</>} key={numbers.EIGHT}>
                      <div className="box-white">
                        <GeneralUser reload={reload} app={appSelected} user={user} />
                      </div>
                    </TabPane>
                  )}

                  {!userSection && (
                    <TabPane tab={<>Aplicaciones</>} key={numbers.ONE}>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                          <SearchUserAnalistWeb
                            setTypeEvent={setTypeEvent}
                            setFilters={setFilters}
                            filters={filters}
                            refetch={refetch}
                            t={t}
                            handleGuest={handleGuest}
                            handleUpdateUser={handleUpdateUser}
                            handleUpdateUserFile={handleUpdateUserFile}
                            handleOpenUser={handleOpenUser}
                            handleAddPayment={handleAddPayment}
                            instanceStoreRedux={instanceStoreRedux}
                            handleRecalculateApps={handleRecalculateApps_}
                          />

                          {!loading ? (
                            <AppsCardsAnalistWeb
                              apps={apps}
                              pagination={pagination}
                              setPagination={setPagination}
                              loading={loading}
                              refetch={refetch}
                              t={t}
                              actionsUsers={actionsUsers}
                              handleCheckin={handleCheckin}
                              handleGuest={handleGuest}
                              handleUpdateUser={handleUpdateUser}
                              handleUpdateUserFile={handleUpdateUserFile}
                              handleOpenUser={handleOpenUser}
                              instanceStoreRedux={instanceStoreRedux}
                              handleSingleApp={handleSingleApp}
                              handleActivateApp={handleActivateApp}
                              handleDesactivateApp={handleDesactivateApp}
                              handleRenovateApp={handleRenovateApp_}
                              handleAddPayment={handleAddPayment}
                              handleCloseAppWeb={handleCloseAppWeb}
                              height="320px"
                              tab={tab}
                            />
                          ) : (
                            <p className="center-spin">
                              <Spin />
                            </p>
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                  )}

                  {!userSection && (
                    <TabPane tab={<Badge count={pendingApps}>En Espera</Badge>} key={numbers.NINE}>
                      {!loading ? (
                        <AppsCardsAnalistWeb
                          tab={tab}
                          apps={apps}
                          pagination={pagination}
                          setPagination={setPagination}
                          loading={loading}
                          refetch={refetch}
                          t={t}
                          actionsUsers={actionsUsers}
                          handleCheckin={handleCheckin}
                          handleGuest={handleGuest}
                          handleUpdateUser={handleUpdateUser}
                          handleUpdateUserFile={handleUpdateUserFile}
                          handleOpenUser={handleOpenUser}
                          instanceStoreRedux={instanceStoreRedux}
                          handleSingleApp={handleSingleApp}
                          handleActivateApp={handleActivateApp}
                          handleDesactivateApp={handleDesactivateApp}
                          handleRenovateApp={handleRenovateApp_}
                          handleAddPayment={handleAddPayment}
                          handleCloseAppWeb={handleCloseAppWeb}
                          height="320px"
                        />
                      ) : (
                        <p className="center-spin">
                          <Spin />
                        </p>
                      )}
                    </TabPane>
                  )}

                  {userSection && (
                    <TabPane disabled={isMobile} tab="Todas las aplicaciones" key={numbers.TEN}>
                      {tab === numbers.TEN && <AppsContainer isEmbed userId={userId || null} />}
                    </TabPane>
                  )}

                  {!userSection && (
                    <TabPane disabled={isMobile} tab={<>Historial de aplicaciones</>} key={numbers.ELEVEN}>
                      {tab === numbers.ELEVEN && <AppsContainer isEmbed isForm />}
                    </TabPane>
                  )}

                  <TabPane disabled={isMobile} tab="Todos los Logs" key={numbers.FIVE}>
                    {tab === numbers.FIVE && <EventsContainer userId={userId || null} tab={tab} />}
                  </TabPane>
                </Tabs>
              </div>
            </div>

            {appSelected?.userId && isDrawerMiniOpen && (
              <Drawer
                title={titleDraw}
                width="900px"
                onClose={onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
              >
                {option === actionsUsers.CHECKIN.number && (
                  <AddUserCheckin
                    refetch={refetch}
                    reload={reload}
                    onClose={onClose}
                    t={t}
                    user={user}
                    appId={appSelected._id}
                  />
                )}
                {option === actionsUsers.GUEST.number && (
                  <AddUserGuest
                    reload={reload}
                    onClose={onClose}
                    t={t}
                    user={user}
                    appId={appSelected._id}
                    refetch={refetch}
                  />
                )}

                {option === actionsUsers.PAYMENT.number && (
                  <AddPaymentAnalist
                    handleOpenApp={handleOpenApp}
                    reload={reload}
                    onClose={onClose}
                    t={t}
                    user={user}
                    refetch={refetch}
                  />
                )}
              </Drawer>
            )}

            {isDrawerOpen && (
              <Drawer
                title={titleDraw}
                width="90%"
                onClose={onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
              >
                {option === actionsUsers.CREATE_APP.number && (
                  <CreateApp
                    onClose={onClose}
                    refetch={refetch}
                    selectedApp={{ userId: "123456789", startDate: null, closureDate: null }}
                  />
                )}

                {option === actionsUsers.UPDATE_APP_SUBSCRIPTION.number && (
                  <ManageAppModalSubscription onClose={onClose} refetch={refetch} selectedApp={appSelected} />
                )}

                {option === actionsUsers.CREATE_APP_PRODUCT.number && (
                  <CreateAppProduct
                    onClose={onClose}
                    refetch={refetch}
                    selectedApp={{ userId: "123456789", startDate: null, closureDate: null }}
                  />
                )}

                {option === actionsUsers.CREATE_APP_RENTAL.number && (
                  <CreateAppRental
                    onClose={onClose}
                    refetch={refetch}
                    selectedApp={{ userId: "123456789", startDate: null, closureDate: null }}
                  />
                )}

                {option === actionsUsers.CREATE_APP_EVENT.number && (
                  <CreateAppEvent
                    onClose={onClose}
                    refetch={refetch}
                    userSelected={userSelected}
                    selectedApp={{ userId: "123456789", startDate: null, closureDate: null }}
                  />
                )}

                {option === actionsUsers.CREATE_APP_NEW_EVENT.number && (
                  <CreateAppNewEvent
                    onClose={onClose}
                    refetch={refetch}
                    selectedApp={{ userId: "123456789", startDate: null, closureDate: null }}
                  />
                )}

                {option === actionsUsers.EDIT_EVENT.number && (
                  <EditEvent onClose={onClose} refetch={refetch} planId_={planId} />
                )}

                {option === actionsUsers.ACTIVATE_APP.number && (
                  <ActivateApp
                    onClose={onClose}
                    refetch={refetch}
                    selectedApp={appSelected}
                    isDesactivate={desactivate}
                  />
                )}

                {option === actionsUsers.CLOSE_APP_WEB.number && (
                  <CloseAppWeb
                    onClose={onClose}
                    refetch={refetch}
                    selectedApp={appSelected}
                    isDesactivate={desactivate}
                  />
                )}

                {option === actionsUsers.SINGLE_APP.number && userId && (
                  <CreateSingleApp onClose={onClose} refetch={refetch} selectedUser={user} />
                )}

                {option === actionsUsers.RENOVATE.number && appSelected.packId && (
                  <>
                    <RenovateApp
                      onClose={onClose}
                      refetch={refetch}
                      selectedApp={appSelected}
                      isDesactivate={desactivate}
                      packId_={appSelected?.packId._id || null}
                      planId_={appSelected?.planId._id || null}
                    />
                  </>
                )}
              </Drawer>
            )}
          </Content>
          {user?._id && (
            <ManageBucketModal
              entityProp="User"
              typeProp={typeUploadUser}
              entityId={user?._id}
              openModal={isOpenManageBucketModal}
              closeModal={closeManageBucketModal}
            />
          )}

          {planId && (
            <ManageBucketModal
              entityProp="Plan"
              typeProp="Gallery"
              entityId={planId}
              openModal={isOpenManagePlanBucketModal}
              closeModal={closeManagePlanBucketModal}
            />
          )}

          <ManageUserModal
            refetch={refetch}
            userId={userId}
            setUserId={setUserId}
            closeModal={closeManageUsersModal}
            openModal={isOpenManageUsersModal}
            initialValues={initialValues}
            setReload_={setReload}
            selectedUser={user}
            setUser={setUser}
            getUser={user}
            t={t}
          />

          <ManageUserExtraModal
            refetch={refetch}
            userId={userId}
            setUserId={setUserId}
            closeModal={closeManageUserExtraModal}
            openModal={isOpenManageUserExtraModal}
            initialValues={initialValues}
            setReload_={setReload}
            selectedUser={user}
            setUserExtraId={setUserExtraId}
            userExtraId={userExtraId}
            setUser={setUser}
            getUser={user}
            t={t}
          />
        </Layout>
      </Content>
    </Layout>
  );
};
export default withRouter(Analist);
