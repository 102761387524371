/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from "react";

import { Line } from "@ant-design/charts";

const DemoLine = ({ json }) => {
  const [data, setData] = useState([]);

  const config = {
    data,
    xField: "type",
    yField: "value",
    seriesField: "name",
    legend: {
      position: "top",
    },
    // smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };

  useEffect(() => {
    const login = [];
    json.forEach(function (item) {
      login[item.fecha] = (login[item.fecha] || 0) + 1;
    });

    const login_ = [];
    for (const prop in login) {
      login_.push({ type: prop, value: login[prop], name: "Asistencias" });
    }
    setData(login_);
  }, [json]);

  return <Line {...config} />;
};

export default DemoLine;
