import { useState, useEffect } from "react";
import { Form, Row, Col, Space, Button, Input } from "antd";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { confirmAlert } from "react-confirm-alert";

import { withRouter } from "react-router-dom";
import { icons, STATUS_APP } from "../../../constants/globals";

import { handleCloseWebApp } from "../functions";

const moment = require("moment");
const Swal = require("sweetalert2");

const defaultValues = {
  description: "",
  commentsBy: "",
  comments: "",
};

const CloseAppWeb = ({ selectedApp, refetch, onClose, isDesactivate = false }) => {
  const { t } = useTranslation();
  const instanceStoreRedux = useSelector((store) => store.instance);

  const [setting, setSetting] = useState({});
  const [app, setApp] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [form] = Form.useForm();

  const [packId, setPackId] = useState(selectedApp.packId._id);
  const [plan, setPlan] = useState({});
  const [reload, setReload] = useState(0);
  const [submmit, setSummit] = useState(false);

  const onValuesChange = (values) => {
    setData({ ...data, ...values });
  };

  const onFinish = () => {
    setData({ ...data, app: selectedApp, appId: selectedApp._id });
    setSummit(true);

    handleCloseWebApp(
      { params: data, t, refetch },
      {
        onSuccess: () => {
          Swal.fire({
            icon: "success",
            title: "Operación Exitosa",
          });
          refetch();
          onClose();
        },
        onError: () => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        },
        onFinally: () => {
          setSummit(false);
        },
      }
    );
  };

  const handleSubmit = () => {
    confirmAlert({
      title: "Confirmación",
      message: `Esta seguro que desea continuar?`,
      buttons: [
        {
          label: "Continuar",
          onClick: () => form.submit(),
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const handleDesactivate = () => {
    confirmAlert({
      title: "Cancelación de ticket",
      message: `Esta seguro que desea continuar?`,
      buttons: [
        {
          label: "Continuar",
          onClick: () => {
            setData({ ...data, active: false, status: STATUS_APP.CANCEL });
            form.submit();
          },
        },
        {
          label: "Cerrar",
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    setPlan({});
    setData({
      ...data,
      packId,
    });
  }, [packId]);

  useEffect(() => {
    setData({
      ...data,
    });
  }, [plan]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  useEffect(() => {
    setReload(Math.floor(Math.random() * 100000));
    setSetting(instanceStoreRedux.currentInstance);
    setApp(selectedApp);
    if (selectedApp?.planId) {
      setData({
        appId: selectedApp?._id,
        userId: selectedApp?.userId._id,
        firstname: selectedApp?.userId.firstname,
        lastname: selectedApp?.userId.lastname,
        address: selectedApp?.userId.address,
        document: selectedApp?.userId.document,
        documentType: selectedApp?.userId.documentType,
        phone: selectedApp?.userId.phone,
        email: selectedApp?.userId.email,
        packId: selectedApp?.packId._id,
        planId: selectedApp?.planId._id,
        active: true,
        paymentMode: "none",
        startDate: moment(selectedApp?.startDate, "YYYY/MM/DD"),
        closureDate: moment(selectedApp?.startDate, "YYYY/MM/DD"),
      });
      setPlan(selectedApp?.planId);
      setLoading(false);
    }
  }, []);

  return (
    <>
      {!loading && (
        <Row>
          <Col span={22} offset={1}>
            <Form
              form={form}
              name="manage-category-form-modal "
              className="manage-modal minimal-form"
              onFinish={onFinish}
              labelCol={{ span: 24 }}
              onValuesChange={onValuesChange}
              initialValues={defaultValues}
            >
              <Row gutter={[24, 24]} className="box-primary">
                <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                  <b>Nombres:</b> {selectedApp?.userId.firstname} {selectedApp?.userId.lastname || ""}
                </Col>
                <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                  <b>Email:</b> {selectedApp?.userId.email}
                </Col>
                <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                  <b>Teléfono:</b> {selectedApp?.userId.phone || "----"}
                </Col>

                <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                  <b>Fecha y Hora:</b> {selectedApp?.fecha} {selectedApp?.hora}
                </Col>

                <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                  <b>Formulario:</b> {selectedApp?.campaign}
                </Col>

                <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                  <b>Mensaje:</b> {selectedApp?.observation}
                </Col>
              </Row>

              <Row gutter={[24, 24]} className="box-info">
                <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="commentsBy" label="Atendido por">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="comments" label="Comentario">
                    <Input.TextArea />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <hr />
                  <Space>
                    {isDesactivate && (
                      <Button
                        loading={submmit}
                        key="1"
                        size="large"
                        className="ant-btn secondary primary large"
                        onClick={handleDesactivate}
                      >
                        Cancelar Ticket
                      </Button>
                    )}

                    {!isDesactivate && (
                      <Button
                        loading={submmit}
                        key="2"
                        size="large"
                        className="ant-btn secondary primary large"
                        onClick={handleSubmit}
                      >
                        {icons.check} Cierre del Ticket
                      </Button>
                    )}
                    <Button
                      key="2"
                      onClick={onClose}
                      className="ant-btn secondary error large"
                      size="large"
                      style={{ float: "left" }}
                    >
                      Cerrar
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
};
export default withRouter(CloseAppWeb);
