import { PageHeader, Button, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined, AmazonOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";
import { globals } from "../../../constants/globals";

const Header = ({ setPage, handleEdit, openDeleteModal, t, refetch, isGoback, handleGoto, handleBucketkModal }) => {
  const { currentPlan } = useSelector((store) => store.plan);

  // validate extract Json Data

  const renderContent = (column = 3) => {};

  const goBack = (plan) => {
    if (isGoback) {
      handleGoto({ number: 21, id: plan.packId });
    } else {
      setPage(globals.PAGE_MAIN);
    }
  };

  const extraContent = <></>;

  const Content = ({ children, extra }) => (
    <div className="content">
      <div className="main">{children}</div>
      <div className="extra">{extra}</div>
    </div>
  );

  return (
    <>
      <PageHeader
        avatar={{ src: "https://cluuftracks.herokuapp.com/bot.gif" }}
        className="site-page-header-responsive"
        onBack={() => {
          refetch();
          goBack(currentPlan);
          setPage(globals.PAGE_MAIN);
        }}
        title={`${currentPlan.name}`}
        subTitle="..."
        extra={[
          <Tooltip placement="topLeft" title="Prompt Text">
            <Button icon={<DeleteOutlined />} key="3" size="large" onClick={() => openDeleteModal(currentPlan)}>
              {t("delete")}
            </Button>
          </Tooltip>,
          <Tooltip placement="topLeft" title="Prompt Text">
            <Button key="1" icon={<EditOutlined />} size="large" onClick={() => handleEdit(currentPlan)}>
              {t("edit")}
            </Button>
          </Tooltip>,
          <Tooltip placement="topLeft" title="Prompt Text">
            <Button
              className="secondary"
              onClick={() => handleBucketkModal()}
              shape="square"
              icon={<AmazonOutlined />}
              size="large"
              type="primary"
            >
              {t("uploadImageS3")}
            </Button>
          </Tooltip>,
        ]}
      >
        <Content extra={extraContent}>{renderContent()}</Content>
      </PageHeader>
    </>
  );
};

export default Header;
