/* eslint-disable no-unused-expressions */
import { useState, useEffect } from "react";
import { Layout } from "antd";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { setCookies } from "../helpers/cookies";

const Logout = (props) => {
  const instanceStoreRedux = useSelector((store) => store.instance);
  // eslint-disable-next-line react/destructuring-assignment
  const [alias, setAlias] = useState(props.match.params.alias);

  useEffect(() => {
    setCookies("pclf", null);
    setCookies("uclf", null);
    setCookies("iclf", null);
    localStorage.removeItem("keypublic");
    localStorage.removeItem("cluuf-settings");
    localStorage.removeItem("instanceId");
    localStorage.removeItem("goto");
    localStorage.setItem("logout", true);
    document.location = `/login/${alias}`;
  }, []);

  return <Layout> Cerrando Sesión</Layout>;
};

export default withRouter(Logout);
