import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from "react-quill";

import { Form, Row, Col, Input, Spin, Switch, InputNumber, Checkbox, Tabs, Select } from "antd";
import { savePack, getPack } from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";
import BaseModal from "../../shared/components/Modal/BaseModal";
import { constantsPack } from "../constants";
import { setPackRedux } from "../../../redux/pack";
import { packClasification } from "../../../constants/globals";
import SelectRoots from "../../subscribersRoots/components/SelectUsers";

import "react-quill/dist/quill.snow.css"; // ES6

const moment = require("moment");

const { TabPane } = Tabs;
const { TextArea } = Input;

const defaultValues = {
  name: "",
  duration: "",
  category: "",
  minLimit: 1,
  observation: "",
  active: true,
  departurePlace: "",
  featureImage: "",
  tag: "",
  price: "0",
  avatar: "",
  public: false,
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  type: constantsPack.TYPE,
  position: "0",
  multiple: false,
  umlimited: false,
  paymentLink: "https://",
  description: "",
  excerpt: "",
  itineraries: "",
  exclude: "",
  include: "",
  departureTime: "",
  recomendations: "",
  parentalControl: true,
  backgroundForm: "#000",
  cupon: false,
  webhookCancel: "",
  webhookClose: "",
  webhookInwait: "",
  webhookPayment: "",
  webhookOpen: "",
  emailWelcome: "",
  emailRegistration: "",
  emailClosure: "",
  isTemporal: false,
  isExternal: false,
  single: false,
  isDocumentRequired: false,
  isVaccine: false,
  kind: "event",
  clasification: "",
};
const CheckboxGroup = Checkbox.Group;

const defaultCheckedList = ["Apple", "Orange"];

const ManagePackModal = ({ setPackId, packId, openModal, closeModal, refetch, t, type = defaultValues.type }) => {
  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [form] = Form.useForm();
  const [selectedPack, setSelectedPack] = useState(defaultValues);
  const [loaderForm, setLoaderForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [reload, setReload] = useState(0);
  const [tab, setTab] = useState(0);
  const [guideId, setGuideId] = useState(null);

  const handleReset = () => {
    setSelectedPack(defaultValues);
    setReload(Math.floor(Math.random() * 100000));
  };

  useEffect(() => {
    if (openModal) {
      if (packId) {
        setMode("edit");
      } else {
        setMode("new");
      }
    } else {
      handleReset();
      setMode(null);
      // setPackId(null);
    }
  }, [openModal]);

  useEffect(() => {
    if (mode === "new") {
      handleReset();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      setLoaderForm(true);
      getPack(packId, {
        onSuccess: (response) => {
          setPackRedux(response.result)(dispatch);
          setSelectedPack({
            ...response.result,
            startdate: moment(response.result.startdate, "YYYY/MM/DD") || moment("2020/12/12", "YYYY/MM/DD"),
            closuredate: moment(response.result.closuredate, "YYYY/MM/DD") || moment("2020/12/12", "YYYY/MM/DD"),
          });
          setLoaderForm(false);
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  }, [mode]);

  useEffect(() => {
    form.setFieldsValue(selectedPack);
  }, [form, selectedPack]);

  const handleCloseModal = () => {
    refetch();
    handleReset();
    closeModal();
  };

  const onFinish = (values) => {
    const data = { ...selectedPack, ...values };
    savePack(
      {
        ...data,
        packId,
        rootId: sessionStoreRedux.currentSession_id,
      },
      {
        onSuccess: (response) => {
          setPackRedux(response.result)(dispatch);

          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => handleCloseModal(),
      }
    );
  };

  const condicionalType = (value) => {
    if (value?.type === constantsPack.CONTENT_TYPE.FORM) {
      setSelectedPack({ ...selectedPack, multiple: true });
    } else {
      setSelectedPack({ ...selectedPack, multiple: false });
    }
  };

  const convertCSVToArray = (value) => {
    if (value?.include) {
      setSelectedPack({ ...selectedPack, include: value.include.split(",") });
    }
    if (value?.exclude) {
      setSelectedPack({ ...selectedPack, exclude: value.exclude.split(",") });
    }
    if (value?.itineraries) {
      setSelectedPack({ ...selectedPack, itineraries: value.itineraries.split(",") });
    }
    if (value?.departureTime) {
      setSelectedPack({ ...selectedPack, departureTime: value.departureTime.split(",") });
    }

    if (value?.recomendations) {
      setSelectedPack({ ...selectedPack, recomendations: value.recomendations.split(",") });
    }
  };

  const onValuesChange = (values) => {
    if (values?.include || values?.exclude || values?.itineraries || values?.departureTime || values?.recomendations) {
      convertCSVToArray(values);
    } else {
      if (values?.type) {
        condicionalType(values);
      }
      setSelectedPack({ ...selectedPack, ...values });
    }
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  const handleActive = (checked) => {
    setSelectedPack({ ...selectedPack, active: checked });
  };

  const handleSingle = (checked) => {
    setSelectedPack({ ...selectedPack, single: checked });
  };

  const handlePublic = (checked) => {
    setSelectedPack({ ...selectedPack, public: checked });
  };

  const handleStrictMode = (checked) => {
    setSelectedPack({ ...selectedPack, isStrictMode: checked });
  };

  const handleIsfree = (checked) => {
    setSelectedPack({ ...selectedPack, isFree: checked });
  };

  const handleParentalControl = (checked) => {
    setSelectedPack({ ...selectedPack, parentalControl: checked });
  };

  const handleCupon = (checked) => {
    setSelectedPack({ ...selectedPack, cupon: checked });
  };

  const handleIsTemporal = (checked) => {
    setSelectedPack({ ...selectedPack, isTemporal: checked });
  };

  const handleExternal = (checked) => {
    setSelectedPack({ ...selectedPack, isExternal: checked });
  };

  const handleIsVaccine = (checked) => {
    setSelectedPack({ ...selectedPack, isVaccine: checked });
  };

  const handleDocumentRequired = (checked) => {
    setSelectedPack({ ...selectedPack, isDocumentRequired: checked });
  };

  const handleTicketsNumber = (checked) => {
    setSelectedPack({ ...selectedPack, isTicketsNumber: checked });
  };

  const handleInstanceDefault = (checked) => {
    setSelectedPack({ ...selectedPack, defaultInstance: checked });
  };

  const handleTabChange = (number) => setTab(number);

  const handleGuide = (value) => {
    setGuideId(value);
    setSelectedPack({
      ...selectedPack,
      guideId: value,
    });
  };

  return (
    <BaseModal
      isOpenModal={openModal}
      closeModal={handleCloseModal}
      onCancel={handleCloseModal}
      onConfirm={() => handleSubmit()}
      title={packId ? `${t("edit")} ${t("pack_service")}` : `${t("new")} ${t("pack_service")}`}
      width={1200}
      confirmText={t("save")}
      isCloseModal
      top={5}
    >
      <Form
        form={form}
        name="manage-category-form-modal"
        initialValues={defaultValues}
        className="manage-modal"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        onValuesChange={onValuesChange}
      >
        <Tabs defaultActiveKey="1" onChange={handleTabChange}>
          <TabPane tab={t("general")} key="1">
            <div className="layout-form-modal entity-500 entity-small">
              {loaderForm ? (
                <>
                  <Spin tip={t("loading")} />
                </>
              ) : (
                <>
                  <Row gutter={24}>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item name="kind" label="Tipo">
                        <Select className="select-form-modal">
                          <Select.Option value="action">Acción</Select.Option>
                          <Select.Option value="event">Evento</Select.Option>
                          <Select.Option value="product">Producto</Select.Option>
                          <Select.Option value="subscription">Subscripción</Select.Option>
                          <Select.Option value="rental">Rental</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                      <Form.Item name="clasification" label="Clasificación">
                        <Select className="select-form-modal">
                          {selectedPack.kind === "event" && (
                            <Select.Option value={packClasification.EVENT.TOUR}>
                              {packClasification.EVENT.TOUR}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "event" && (
                            <Select.Option value={packClasification.EVENT.WORKSHOP}>
                              {packClasification.EVENT.WORKSHOP}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "event" && (
                            <Select.Option value={packClasification.EVENT.CONFERENCE}>
                              {packClasification.EVENT.CONFERENCE}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "event" && (
                            <Select.Option value={packClasification.EVENT.PARTY}>
                              {packClasification.EVENT.PARTY}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "rental" && (
                            <Select.Option value={packClasification.RENTAL.SPACERENTAL}>
                              {packClasification.RENTAL.SPACERENTAL}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "rental" && (
                            <Select.Option value={packClasification.RENTAL.CARRENTAL}>
                              {packClasification.RENTAL.CARRENTAL}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "rental" && (
                            <Select.Option value={packClasification.RENTAL.CLOTHESRENTAL}>
                              {packClasification.RENTAL.CLOTHESRENTAL}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "product" && (
                            <Select.Option value={packClasification.PRODUCT.ACCESSORY}>
                              {packClasification.PRODUCT.ACCESSORY}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "product" && (
                            <Select.Option value={packClasification.PRODUCT.CLOTHES}>
                              {packClasification.PRODUCT.CLOTHES}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "product" && (
                            <Select.Option value={packClasification.PRODUCT.DRINKS}>
                              {packClasification.PRODUCT.DRINKS}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "product" && (
                            <Select.Option value={packClasification.PRODUCT.FOOD}>
                              {packClasification.PRODUCT.FOOD}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "subscription" && (
                            <Select.Option value={packClasification.SUBSCRIPTION.COWORK}>
                              {packClasification.SUBSCRIPTION.COWORK}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "subscription" && (
                            <Select.Option value={packClasification.SUBSCRIPTION.SPORT}>
                              {packClasification.SUBSCRIPTION.SPORT}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "subscription" && (
                            <Select.Option value={packClasification.SUBSCRIPTION.GYM}>
                              {packClasification.SUBSCRIPTION.GYM}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "subscription" && (
                            <Select.Option value={packClasification.SUBSCRIPTION.YOGA}>
                              {packClasification.SUBSCRIPTION.YOGA}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "subscription" && (
                            <Select.Option value={packClasification.SUBSCRIPTION.LANGUAGE}>
                              {packClasification.SUBSCRIPTION.LANGUAGE}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "subscription" && (
                            <Select.Option value={packClasification.SUBSCRIPTION.MEDITATION}>
                              {packClasification.SUBSCRIPTION.MEDITATION}
                            </Select.Option>
                          )}

                          {selectedPack.kind === "subscription" && (
                            <Select.Option value={packClasification.SUBSCRIPTION.FORM}>
                              {packClasification.SUBSCRIPTION.FORM}
                            </Select.Option>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                      <Form.Item
                        name="name"
                        tooltip="Nombre"
                        label={t("name")}
                        rules={[
                          {
                            required: true,
                            message: `Por favor ingrese el nombre`,
                          },
                        ]}
                      >
                        <Input placeholder="" value={selectedPack?.name} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                      <Form.Item name="price" label={t("price")}>
                        <InputNumber
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          value={selectedPack?.price}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="active"
                        labelAlign="right"
                        label="Proveedor externo"
                        tooltip="Este evento es proporcionado por otra agencia"
                        extra="Este evento es proporcionado por otra agencia"
                      >
                        <Switch checked={!!selectedPack?.isExternal} onChange={handleExternal} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="active"
                        labelAlign="right"
                        label={t("active")}
                        tooltip="Activa el contenido para que se visualice en el website"
                      >
                        <Switch checked={!!selectedPack?.active} onChange={handleActive} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="isFree"
                        labelAlign="center"
                        label={t("free")}
                        tooltip="Solo aplica para cuando seran varios contenidos"
                        extra="Este evento es gratuito."
                      >
                        <Switch checked={!!selectedPack?.isFree} onChange={handleIsfree} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="instanceDefault"
                        labelAlign="center"
                        label="Instancia por defecto"
                        tooltip="Mantiene la configuracion inicial"
                        extra="Mantiene los valores generales de la instacia por defecto, como webhooks"
                      >
                        <Switch checked={!!selectedPack?.instanceDefault} onChange={handleInstanceDefault} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="parentalControl"
                        labelAlign="right"
                        label={t("pack_parentalControl")}
                        tooltip="Si el control parental esta activo, solo admitira registros a las aplicaciones a personas mayores de 18 años"
                        extra="Registra solo adultos."
                      >
                        <Switch checked={!!selectedPack?.parentalControl} onChange={handleParentalControl} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="public"
                        labelAlign="right"
                        label={t("public")}
                        tooltip="El contenido sera visualizado en el website"
                        extra="Visibilidad en la web y reportes"
                      >
                        <Switch checked={!!selectedPack?.public} onChange={handlePublic} />
                      </Form.Item>
                    </Col>
                    {/* <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="single"
                        labelAlign="right"
                        label={t("pack_single")}
                        tooltip="Paquete orientado a acciones simples de los contactos"
                        extra="Orientdo solo a acciones del contacto"
                      >
                        <Switch checked={!!selectedPack?.single} onChange={handleSingle} />
                      </Form.Item>
                      </Col> */}
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="isDocumentRequired"
                        labelAlign="right"
                        label={t("pack_isDocumentRequired")}
                        tooltip="Este paquete requiere el registro de documentacion"
                        extra="Importante el documento de identificacion del contacto"
                      >
                        <Switch checked={!!selectedPack?.isDocumentRequired} onChange={handleDocumentRequired} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="cupon"
                        labelAlign="right"
                        label={t("pack_cupon")}
                        tooltip="Maneja cupones de descuentos"
                      >
                        <Switch checked={!!selectedPack?.cupon} onChange={handleCupon} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="isTemporal"
                        labelAlign="right"
                        label={t("pack_isTemporal")}
                        tooltip="Los planes son de tiempo limitado, por ejemplo un tour, un evento, una asesoria"
                        extra="Servicio cortos con fecha de caducidad  (Eventos, tours, conferencias)"
                      >
                        <Switch checked={!!selectedPack?.isTemporal} onChange={handleIsTemporal} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="isVaccine"
                        labelAlign="right"
                        label={t("pack_isVaccine")}
                        tooltip="Se require esta vacunado contra el Covid19"
                        extra="Esquema de vacunación requerido"
                      >
                        <Switch checked={!!selectedPack?.isVaccine} onChange={handleIsVaccine} />
                      </Form.Item>
                    </Col>

                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="isTicketsNumbers"
                        labelAlign="right"
                        label={t("pack_isTicketsNumber")}
                        tooltip="Activación de Tickets"
                        extra="Permite agregar cantidad de tickets"
                      >
                        <Switch checked={!!selectedPack?.isTicketsNumber} onChange={handleTicketsNumber} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="isStrictMode"
                        labelAlign="right"
                        label="Email campo Unico"
                        extra="Dos contactos no pueden tener el mismo email"
                      >
                        <Switch checked={!!selectedPack?.isStrictMode} onChange={handleStrictMode} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={15} style={{ paddingBottom: 10 }}>
                    <Col span={24} xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item name="packId3" tooltip="" label={<>Seleccione un Guia</>}>
                        <SelectRoots
                          reload={reload}
                          currentValue={selectedPack?.guideId?._id || null}
                          defaultValue={selectedPack?.guideId?._id || null}
                          returnData={handleGuide}
                          typePack="SUBSCRIPTION"
                          title="Seleccione"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </TabPane>

          <TabPane tab={t("moreEspecific")} key="2">
            <div className="layout-form-modal entity-400 entity-small">
              {loaderForm ? (
                <>
                  <Spin tip={t("loading")} />
                </>
              ) : (
                <>
                  <Row gutter={16}>
                    <Col span={6}>
                      <Form.Item name="departureTime" label={t("departureTime")}>
                        <Input value={selectedPack?.departureTime} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="minLimit" label={t("minLimit")}>
                        <InputNumber min="1" max="1000" value={selectedPack?.minLimit} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="maxLimit" label={t("maxLimit")}>
                        <InputNumber min="1" max="1000" value={selectedPack?.maxLimit} />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item name="durationMode" label={t("durationMode")}>
                        <Select className="select-form-modal">
                          <Select.Option value="HOURS">Horas</Select.Option>
                          <Select.Option value="DAYS">días</Select.Option>
                          <Select.Option value="MONTHS">Meses</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item name="duration" label={t("duration")}>
                        <InputNumber value={selectedPack?.duration} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="limitCheckin" label={t("limitCheckin")}>
                        <InputNumber min="1" max="1000" value={selectedPack?.limitCheckin} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="color" label={t("color")}>
                        <Input type="color" value={selectedPack?.color} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="tag" label={t("tag")}>
                        <Input value={selectedPack?.tag} />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item name="paymentLink" label={t("paymentLink")}>
                        <Input value={selectedPack?.paymentLink} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item initialValue={checkedList} name="availableDays" label={t("availableDays")}>
                        <CheckboxGroup style={{ width: "100%" }} defaultValue={checkedList}>
                          <Row gutter={15}>
                            <Col span={3}>
                              <Checkbox value="0">Sunday</Checkbox>
                            </Col>
                            <Col span={3}>
                              <Checkbox value="1">Monday</Checkbox>
                            </Col>
                            <Col span={3}>
                              <Checkbox value="2">Tuesday</Checkbox>
                            </Col>
                            <Col span={3}>
                              <Checkbox value="3">Wednesday</Checkbox>
                            </Col>
                            <Col span={3}>
                              <Checkbox value="4">Thursday</Checkbox>
                            </Col>
                            <Col span={3}>
                              <Checkbox value="5">Friday</Checkbox>
                            </Col>
                            <Col span={3}>
                              <Checkbox value="6">Saturday</Checkbox>
                            </Col>
                          </Row>
                        </CheckboxGroup>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item initialValue={checkedList} name="categorization" label="Categoría">
                        <CheckboxGroup style={{ width: "100%" }} defaultValue={checkedList}>
                          <Row gutter={15}>
                            <Col span={3}>
                              <Checkbox value="1">Naturaleza</Checkbox>
                            </Col>
                            <Col span={3}>
                              <Checkbox value="2">Cultural</Checkbox>
                            </Col>
                            <Col span={3}>
                              <Checkbox value="3">Extremo</Checkbox>
                            </Col>
                            <Col span={3}>
                              <Checkbox value="4">Romantico</Checkbox>
                            </Col>
                            <Col span={3}>
                              <Checkbox value="5">Walking</Checkbox>
                            </Col>
                          </Row>
                        </CheckboxGroup>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </TabPane>

          <TabPane tab={t("description")} key="4">
            <div className="layout-form-modal entity-400 entity-small">
              {loaderForm ? (
                <>
                  <Spin tip={t("loading")} />
                </>
              ) : (
                <>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item name="description" tooltip="Descripción" label={t("description")}>
                        <ReactQuill id="receipt" theme="snow" value={selectedPack?.description} />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </TabPane>

          <TabPane tab={t("location")} key="5">
            <div className="layout-form-modal entity-400 entity-small">
              {loaderForm ? (
                <>
                  <Spin tip={t("loading")} />
                </>
              ) : (
                <>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="meetingPoint"
                        tooltip="Ingresar el codigo <Iframe> proporcionado por google map"
                        label={t("pack_meetingPoint")}
                      >
                        <TextArea style={{ minHeight: 70 }} placeholder="" value={selectedPack?.meetingPoint} />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </TabPane>

          <TabPane tab={t("pack_emailWelcome")} key="6">
            <div className="layout-form-modal entity-400 entity-small">
              {loaderForm ? (
                <>
                  <Spin tip={t("loading")} />
                </>
              ) : (
                <>
                  {tab === "6" && (
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item name="emailWelcome" tooltip="emailWelcome" label={t("pack_emailWelcome")}>
                          <ReactQuill id="receipt" theme="snow" value={selectedPack?.emailWelcome} />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </div>
          </TabPane>

          <TabPane tab={t("pack_emailRegistration")} key="7">
            <div className="layout-form-modal entity-400 entity-small">
              {loaderForm ? (
                <>
                  <Spin tip={t("loading")} />
                </>
              ) : (
                <>
                  {tab === "7" && (
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          name="emailRegistration"
                          tooltip="emailRegistration"
                          label={t("pack_emailRegistration")}
                        >
                          <ReactQuill id="receipt2" theme="snow" value={selectedPack?.emailRegistration} />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </div>
          </TabPane>

          <TabPane tab={t("pack_emailClosure")} key="9">
            <div className="layout-form-modal entity-400 entity-small">
              {loaderForm ? (
                <>
                  <Spin tip={t("loading")} />
                </>
              ) : (
                <>
                  {tab === "9" && (
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item name="emailClosure" tooltip="emailClosure" label={t("pack_emailClosure")}>
                          <ReactQuill id="receipt3" theme="snow" value={selectedPack?.emailClosure} />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </div>
          </TabPane>

          <TabPane tab={t("pack_webhooks")} key="8">
            <div className="layout-form-modal entity-400 entity-small">
              {loaderForm ? (
                <>
                  <Spin tip={t("loading")} />
                </>
              ) : (
                <>
                  {tab === "8" && (
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item name="webhookInwait" label={t("webhookInwait")}>
                          <Input value={selectedPack?.webhookInwait} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="webhookOpen" label={t("webhookOpen")}>
                          <Input value={selectedPack?.webhookOpen} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="webhookClose" label={t("webhookClose")}>
                          <Input value={selectedPack?.webhookClose} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="webhookCancel" label={t("webhookCancel")}>
                          <Input value={selectedPack?.webhookCancel} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="webhookPayment" label={t("webhookPayment")}>
                          <Input value={selectedPack?.webhookPayment} />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </div>
          </TabPane>
        </Tabs>
      </Form>
    </BaseModal>
  );
};

ManagePackModal.defaultProps = {
  setPackId: () => {},
};

export default ManagePackModal;
