import { Row, Col, Tag, Card } from "antd";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { validateImage, dateFormat } from "../../../constants/globals";
import { formatTypePackGlobal } from "../../../helpers/formatTypePack";

const { Meta } = Card;

const BucketCards = ({
  selectedUser,
  setSelectedUser,
  setPage,
  history,
  packs,
  refetch,
  handleDetails,
  handleEdit,
  userId,
  handleArchived,
  ...restProps
}) => {
  const { t } = useTranslation();

  const cartas = () =>
    packs?.map((item) => (
      <Col className="gutter-row" span={5}>
        <a href="#!" onClick={() => handleDetails(item)}>
          <Card
            hoverable
            style={{ width: 200, margin: 10 }}
            cover={
              <img
                alt="example"
                src={
                  item?.avatar
                    ? validateImage(item?.avatar)
                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1AEMjICP5XjyfgI9AqRYBfGuqH4sBHeluJovbtluPOHbqUgQLu2r9N6vp62Ep380yAnk&usqp=CAU"
                }
              />
            }
          >
            <Meta
              title={
                <>
                  {/*
                <a href="#!" style={{ fontSize: 16 }} onClick={() => handleEdit(item)}>
                  <EditOutlined style={{ color: "#fa541c" }} />
                </a>{" "}
                <a href="#!" style={{ fontSize: 16 }} onClick={() => handleArchived(item)}>
                  <DeleteOutlined style={{ color: "#fa541c" }} />
             </a>{" "} */}
                  <br />
                  <small>
                    {" "}
                    {formatTypePackGlobal(item.type)} {item.name}
                  </small>
                  <p style={{ paddingTop: 10 }}>
                    {item.public ? <Tag color="green">{t("public")}</Tag> : <Tag color="red">{t("private")}</Tag>}
                  </p>
                </>
              }
              description={<p>{dateFormat(item.createdAt)}</p>}
            />
          </Card>
        </a>
      </Col>
    ));

  // cartas();

  return (
    <Row gutter={32}>
      {cartas()}
      {packs.length === 0 && (
        <p style={{ textAlign: "center", marginTop: 30, width: "100%" }}>No hay imagenes para mostrar </p>
      )}
    </Row>
  );
};

export default withRouter(BucketCards);
