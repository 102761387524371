import { setLocalStorage } from "../utils/localStorage";

const dataInicial = {
  request: false,
  reload: Math.floor(Math.random() * 100000000),
  currentSupplier: {},
};

const CURRENT_SUPPLIER = "CURRENT_SUPPLIER";

const RELOAD = "RELOAD";

export default function supplierReducer(state = dataInicial, action) {
  switch (action.type) {
    case CURRENT_SUPPLIER:
      return { ...state, currentSupplier: action.payload };

    case RELOAD:
      return { ...state, reload: Math.floor(Math.random() * 100000000) };

    default:
      return { ...state };
  }
}

export const setSupplier = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: CURRENT_SUPPLIER,
    payload,
  });
};

export const setSupplierRedux = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: CURRENT_SUPPLIER,
    payload,
  });
};

export const reloadRedux = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: RELOAD,
    payload,
  });
};
