import { useState, useEffect } from "react";
import { Col, Row, Spin } from "antd";

import { withRouter } from "react-router-dom";

import SelectPacks from "../../subscribers/components/SelectPacks";
import SelectPlans from "../../subscribersPlans/components/SelectPlans";

import BoxGraphCurrentPlans from "../components/boxGraphCurrentPlans";
import BoxTableCurrentApps from "../components/boxTableCurrentApps";

import { handleCurrentAppActives } from "../functions";

import { numbers } from "../../../constants/globals";

import rocketLoading from "../../../assets/loading.gif";

const moment = require("moment");

const Dashboard = ({ tab, handleOpenUser, handleUpdateUser, dashboardTab }) => {
  const [currentAppActives, setCurrentAppActives] = useState({});
  const [packInfo, setPackInfo] = useState({});
  const [planInfo, setPlanInfo] = useState({});
  const [loading, setLoading] = useState(true);

  const [packId, setPackId] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [reload, setReload] = useState(0);
  const renderActiveApps = (result) =>
    result.map((item) => (
      <p className="special-font">
        <span className="big-font">{item.value}</span> {item.type}
      </p>
    ));

  const handlePack = (res) => {
    setPackId(res);
    setPlanId(null);
  };

  const handlePlan = (res) => {
    setPlanId(res);
  };

  useEffect(() => {
    if (tab === numbers.FIVETEEN && dashboardTab === numbers.ONE) {
      setLoading(true);
      handleCurrentAppActives(
        {
          desde: moment().format("YYYY-MM-DD"),
          hasta: moment().format("YYYY-MM-DD"),
          kind: "subscription",
          packId: packId || null,
          planId: planId || null,
        },
        {
          onSuccess: (metricas) => {
            setCurrentAppActives(metricas.currentApps);
            setLoading(false);
          },
        }
      );
    }
  }, [packId, planId]);

  useEffect(() => {
    setLoading(true);
    if (tab === numbers.FIVETEEN && dashboardTab === numbers.ONE) {
      setPackId(null);
      setPlanId(null);
      handleCurrentAppActives(
        {
          desde: moment().format("YYYY-MM-DD"),
          hasta: moment().format("YYYY-MM-DD"),
          kind: "subscription",
          packId: packId || null,
          planId: planId || null,
        },
        {
          onSuccess: (metricas) => {
            setCurrentAppActives(metricas.currentApps);
            setLoading(false);
          },
        }
      );
    }
  }, [tab, dashboardTab]);

  return (
    <div className="site-card-wrapper">
      <Row gutter={(24, 24)}>
        <Col span={12} xs={24} sm={24} md={12} lg={6} xl={6}>
          {" "}
        </Col>
        <Col span={12} xs={24} sm={24} md={12} lg={6} xl={6}>
          <div className="box-white">
            <h2 className="special-font bold medium-font">
              Seleccione un Programa:{" "}
              <SelectPacks
                type="SUBSCRIPTION"
                reload={reload}
                currentValue={packId}
                defaultValue={packId}
                handleSelected={handlePack}
                returnData={handlePack}
                setPackInfo={setPackInfo}
                kind="subscription"
                title="VER TODOS"
              />{" "}
            </h2>
          </div>
        </Col>
        <Col span={12} xs={24} sm={24} md={12} lg={6} xl={6}>
          {packId && packId?.length > 5 && (
            <div className="box-white">
              <h2 className="special-font bold medium-font">
                Seleccione un plan:{" "}
                <SelectPlans
                  handleSelected={setPlanInfo}
                  reload={reload}
                  currentValue={planId}
                  defaultValue={planId}
                  returnData={handlePlan}
                  type="SUBSCRIPTION"
                  packId={packId}
                  title="VER TODOS"
                />
              </h2>
            </div>
          )}
        </Col>
        <Col span={12} xs={24} sm={24} md={12} lg={6} xl={6}>
          {" "}
        </Col>
      </Row>
      {!loading ? (
        <>
          <Row gutter={(24, 24)}>
            <Col span={12} xs={24} sm={24} md={24} lg={16} xl={16}>
              {currentAppActives.plans && currentAppActives.plans.length > 0 && (
                <div className="box-white">
                  <h3 className="special-font bold">Resumen actual de las subscripciones</h3>
                  <BoxGraphCurrentPlans data={currentAppActives.plans} />
                </div>
              )}
            </Col>
            <Col span={12} xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="box-white">
                {currentAppActives.data && currentAppActives.data.length > 0 && (
                  <h3 className="special-font bold">
                    <span className="big-font">{currentAppActives.data.length}</span>
                    {"  "}SUBSCRIPCIONES EN TOTAL
                    <hr />
                    {currentAppActives.plans &&
                      currentAppActives.plans.length > 0 &&
                      renderActiveApps(currentAppActives.plans)}
                  </h3>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24}>
              {currentAppActives?.details && currentAppActives?.details?.apps?.length > 0 && (
                <div className="box-white">
                  <h3 className="special-font bold">Listado de Subscriptores</h3>
                  <BoxTableCurrentApps
                    data={currentAppActives.details.apps}
                    handleOpenUser={handleOpenUser}
                    handleUpdateUser={handleUpdateUser}
                  />
                </div>
              )}
            </Col>
          </Row>
        </>
      ) : (
        <p style={{ textAlign: "center", paddingTop: 120 }}>
          <Spin tip="Consultando información... " /> <br />
        </p>
      )}
    </div>
  );
};

export default withRouter(Dashboard);
