import { useState, useEffect } from "react";
import { Space, Button, Input } from "antd";

import { useTranslation } from "react-i18next";

import debounce from "lodash/debounce";

import { resetPassword } from "../../login/request";

const Swal = require("sweetalert2");

const ResetPasswordUser = ({ currentInstance }) => {
  const { t } = useTranslation();

  const [setting, setSetting] = useState({});
  const [emailPassword, setEmailPassword] = useState("");
  const [active, setActive] = useState(false);

  const handlePassword = () => {
    if (String(emailPassword).length > 10) {
      resetPassword(
        {
          email: emailPassword,
          instanceId: setting._id,
        },
        {
          onSuccess: (result) => {
            console.log(result.result.isExist);
            if (result.result.isExist) {
              Swal.fire({
                icon: "success",
                title: `Hola ${result.result.user.firstname}!`,
                text: "Se ha enviado un correo electronico con la nueva contraseña",
              });
              setTimeout(() => window.close(), 3000);
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: '<a href="">Why do I have this issue?</a>',
              });
            }
          },
          onError: () => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          },
        }
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Por favor ingrese un correo electronico valido",
      });
    }
  };

  const handleEmailPassword = debounce((values) => {
    setEmailPassword(values.target.defaultValue);
  }, 500);

  useEffect(() => {
    setSetting(currentInstance);
    setActive(true);
  }, [setting]);
  return (
    <>
      <div className="qr-section-external" style={{ backgroundColor: setting.color, height: "800px" }}>
        {active && (
          <div className="wrapper" style={{ maxWidth: 350, width: 350, margin: "auto" }}>
            <img src={setting.logo} alt="logo" width="220px" style={{ padding: "20px 0px" }} />
            <hr />
            <Space direction="vertical">
              <h3>Ingresa tu correo electronico</h3>
              <Input placeholder="user@mail.com" name="emailPassword" onChange={handleEmailPassword} />
              <Button key="1" size="large" onClick={handlePassword}>
                Restablecer contraseña
              </Button>
            </Space>
          </div>
        )}
      </div>
    </>
  );
};
export default ResetPasswordUser;
