import { Table, Space, Tooltip, Tag } from "antd";
import { DeleteOutlined, EditOutlined, BranchesOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";

import BaseTable from "../../shared/components/BasicTable";
import { setPackRedux } from "../../../redux/pack";
import { dateFormat2 } from "../../../constants/globals";

const PlansTable = ({
  selectedUser,
  setSelectedUser,
  setPage,
  history,
  buckets,
  refetch,
  initialValues,
  handleDetails,
  handleEdit,
  formatFile,
  userId,
  handleArchived,
  handleReverse,
  openDetailsBucketModal,
  t,
  handleOverwrite,
  ...restProps
}) => (
  <>
    <BaseTable {...restProps} dataSource={buckets} refetch={refetch}>
      <Table.Column
        width="120px"
        title={t("actions")}
        dataIndex="actions"
        align="center"
        key="actions"
        render={(_, data) => (
          <Space>
            <Tooltip title="Cambiar la imagen">
              <a href="#!" style={{ fontSize: 16 }} onClick={() => handleOverwrite(data)}>
                <BranchesOutlined style={{ color: "blue" }} />
              </a>{" "}
            </Tooltip>
            <Tooltip title="Actualizar información ">
              <a href="#!" style={{ fontSize: 16 }} onClick={() => handleEdit(data)}>
                <EditOutlined style={{ color: "#fa541c" }} />
              </a>{" "}
            </Tooltip>
            <Tooltip title="Eliminar imagen">
              <a href="#!" style={{ fontSize: 16 }} onClick={() => handleArchived(data)}>
                <DeleteOutlined style={{ color: "#fa541c" }} />
              </a>{" "}
            </Tooltip>
          </Space>
        )}
      />
      <Table.Column align="left" title="Content Type" dataIndex="contentType" key="contentType" sorter />
      <Table.Column
        align="left"
        title="Title"
        dataIndex="title"
        key="title"
        sorter
        render={(_, data) => (
          <>
            <a target="blank" href={`${data.image}`}>
              <CloudDownloadOutlined /> {"  "}
              Descargar archivo
            </a>
          </>
        )}
      />
      <Table.Column align="left" title="Type" dataIndex="type" key="type" sorter />
      <Table.Column
        align="left"
        title="Imagen"
        dataIndex="image"
        key="image"
        sorter
        render={(_, data) => <>{formatFile(data)}</>}
      />
      <Table.Column
        title={t("createdAt")}
        dataIndex="createdAt"
        key="createdAt"
        sorter
        render={(text, row) => (
          <Tooltip title={row.public ? "Publico" : "Privado"}>
            {row.public ? <Tag color="green">Publica</Tag> : <Tag color="red">Privada</Tag>}
          </Tooltip>
        )}
      />{" "}
      <Table.Column
        title={t("createdAt")}
        dataIndex="createdAt"
        key="createdAt"
        sorter
        render={(text, row) => `${dateFormat2(row.createdAt)}`}
      />{" "}
    </BaseTable>
  </>
);

export default withRouter(PlansTable);
