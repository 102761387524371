import { Row, Col, Tag, Card, Tooltip, Image } from "antd";
import { withRouter } from "react-router-dom";
import { DeleteOutlined, EditOutlined, BranchesOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Meta } = Card;

const BucketCards = ({ buckets, handleEdit, handleArchived, handleOverwrite }) => {
  const { t } = useTranslation();

  const cartas = () =>
    buckets?.map((item) => (
      <Col className="gutter-row" span={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Card
          hoverable
          style={{ width: 400, margin: 10, paddingTop: 20 }}
          cover={<Image width={400} src={item.image} />}
        >
          <Meta
            title={
              <>
                <Tooltip title="Cambiar la imagen">
                  <a href="#!" style={{ fontSize: 16 }} onClick={() => handleOverwrite(item)}>
                    <BranchesOutlined style={{ color: "blue" }} />
                  </a>{" "}
                </Tooltip>
                <Tooltip title="Actualizar información ">
                  <a href="#!" style={{ fontSize: 16 }} onClick={() => handleEdit(item)}>
                    <EditOutlined style={{ color: "#fa541c" }} />
                  </a>{" "}
                </Tooltip>
                <Tooltip title="Eliminar imagen">
                  <a href="#!" style={{ fontSize: 16 }} onClick={() => handleArchived(item)}>
                    <DeleteOutlined style={{ color: "#fa541c" }} />
                  </a>{" "}
                </Tooltip>
                <Tooltip title={item.type}>
                  {item.type === "Avatar" && <Tag color="geekblue">A</Tag>}
                  {item.type === "Cover" && <Tag color="geekblue">C</Tag>}
                  {item.type === "Gallery" && <Tag color="geekblue">G</Tag>}
                </Tooltip>
                <Tooltip title={item.public ? "Publico" : "Privado"}>
                  {item.public ? <Tag color="green">P</Tag> : <Tag color="red">V</Tag>}
                </Tooltip>
              </>
            }
          />
        </Card>
      </Col>
    ));

  // cartas();

  return (
    <Row gutter={32}>
      {cartas()}
      {buckets.length === 0 && (
        <p style={{ textAlign: "center", marginTop: 30, width: "100%" }}>No hay imagenes para mostrar </p>
      )}
    </Row>
  );
};

export default withRouter(BucketCards);
