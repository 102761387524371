import { useState, useEffect } from "react";

import { Table } from "antd";
import { withRouter } from "react-router-dom";
import BaseTable from "../../shared/components/BasicTable";

import { getUserExtra } from "../requests";

import { dateFormat4 } from "../../../constants/globals";

const UsersExtraTable = ({ users, handleEdit, userId, t, usersExtra, ...restProps }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    getUserExtra(userId, {
      onSuccess: (result) => {
        setData(result.result);
      },
      onError: () => {
        console.log("error");
      },
    });
  }, [userId]);

  return (
    <>
      {data.length > 0 && (
        <BaseTable {...restProps} dataSource={data}>
          <Table.Column
            title={t("date")}
            dataIndex="date"
            key="date"
            align="center"
            render={(text, row) => <>{dateFormat4(row.date)}</>}
            responsive={["lg"]}
          />

          <Table.Column title={t("peso")} dataIndex="weight" key="weight" sorter responsive={["lg"]} />
          <Table.Column title={t("altura")} dataIndex="weight" key="weight" responsive={["lg"]} />
          <Table.Column title={t("espalda")} dataIndex="chest" key="chest" responsive={["lg"]} />
          <Table.Column title={t("brazos")} dataIndex="arm" key="arm" responsive={["lg"]} />
          <Table.Column title={t("cuello")} dataIndex="neck" key="neck" responsive={["lg"]} />
          <Table.Column title={t("pierna")} dataIndex="leg" key="leg" responsive={["lg"]} />
        </BaseTable>
      )}
    </>
  );
};

export default withRouter(UsersExtraTable);
