import { Drawer, Divider, Descriptions, Typography } from "antd";
import { useSelector } from "react-redux";
import { dateFormat6, priceFormat, icons } from "../../../constants/globals";
import TransContainer from "../../subscribersTrans/containers/Trans";

const AppsDetails = ({ t, refetch, open, setOpen }) => {
  const { currentApp } = useSelector((store) => store.app);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      {" "}
      <Drawer
        title="Detalles de la Aplicación"
        width={1200}
        onClose={onClose}
        visible={open}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Descriptions size="small" column={2}>
          <Descriptions.Item label="Paquete">{currentApp?.packId?.name}</Descriptions.Item>
          <Descriptions.Item label="Plan">{currentApp?.planId?.name}</Descriptions.Item>
          <Descriptions.Item label="Codigo">{currentApp?.code}</Descriptions.Item>
          <Descriptions.Item label="Tickets">{currentApp?.quantity}</Descriptions.Item>

          <Descriptions.Item label="Fecha de inicio">{dateFormat6(currentApp?.startDate)}</Descriptions.Item>
          <Descriptions.Item label="Invitaciones">
            {currentApp?.guestQuantity} / {currentApp?.guestLimit}
          </Descriptions.Item>
          <Descriptions.Item label="Campaña">{currentApp?.campaign}</Descriptions.Item>
          <Descriptions.Item label="Facilitador">{currentApp?.facilitator}</Descriptions.Item>
        </Descriptions>
        <Divider orientation="left">
          {icons.dolar} {"  "}Acerca del Pago
        </Divider>
        <TransContainer isEmbed="true" appId={currentApp._id} />
        <Divider orientation="left">
          {icons.guest} {"  "} Invitados
        </Divider>

        <Divider orientation="left">
          {icons.rayo} {"  "} Eventos
        </Divider>
      </Drawer>
    </>
  );
};

export default AppsDetails;
