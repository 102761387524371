import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { handleCheckinUser } from "../functions";

const Swal = require("sweetalert2");

const CheckinUser = ({ selectedApp }) => {
  const { t } = useTranslation();
  const instanceStoreRedux = useSelector((store) => store.instance);
  const [setting, setSetting] = useState({});

  const handleCheckin = () => {
    let timerInterval;
    Swal.fire({
      title: "Validando Información",
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {}, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then(() => {
      handleCheckinUser(
        {
          appId: selectedApp?._id || null,
          userId: selectedApp?.userId?._id || null,
          message: "Ingreso por Carnet",
          t,
        },
        {
          onSuccess: (result) => {
            Swal.fire({
              icon: "success",
              title: `Asistencia Ok!`,
            });
            setTimeout(() => window.close(), 3000);
          },
          onError: () => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          },
        }
      );
    });
  };

  useEffect(() => {
    setSetting(instanceStoreRedux.currentInstance);
    handleCheckin();
  }, [selectedApp]);

  return (
    <>
      <div className="qr-section-external" style={{ backgroundColor: setting.color, height: "800px" }}>
        <div className="wrapper" style={{ maxWidth: 600, width: 600, margin: "auto" }}>
          <img src={setting.logo} alt="logo" width="220px" style={{ padding: "20px 0px" }} />
          <hr />
        </div>
      </div>
    </>
  );
};
export default CheckinUser;
