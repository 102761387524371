import moment from "moment";
import { Table } from "antd";
import { withRouter } from "react-router-dom";

const columns = [
  {
    title: "Url",
    dataIndex: "url",
    key: "url",
  },
  {
    title: "Device",
    dataIndex: "device",
    key: "device",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (date) => moment(date).format("LLLL"),
  },
  {
    title: "Width",
    dataIndex: "width",
    key: "width",
  },
  {
    title: "Language",
    dataIndex: "language",
    key: "language",
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",
  },
  {
    title: "Speed",
    dataIndex: "downlink",
    key: "downlink",
  },
];

const PageViewsTable = ({ pagesViewData }) => (
  <>
    <Table pagination={false} columns={columns} dataSource={pagesViewData} />
  </>
);

export default withRouter(PageViewsTable);
