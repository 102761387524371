import { useState, useEffect } from "react";

import { Table, Space, Tooltip, Button } from "antd";
import { CheckCircleTwoTone, EditOutlined, DeleteOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { forEach } from "lodash";
import BaseTable from "../../shared/components/BasicTable";
import { constantsColor } from "../../../constants/colors";
import { priceFormat } from "../../../constants/globals";

const ExpensesTable = ({
  selectedExpense,
  setSelectedExpense,
  setPage,
  history,
  expenses,
  refetch,
  initialValues,
  handleDetails,
  handleEdit,
  expenseId,
  handleArchived,
  t,
  handleInactive,
  ...restProps
}) => {
  const [total, setTotal] = useState(0);
  const [totalApps, setTotalApps] = useState(0);

  const filterData = (datax) => {
    setTotal(0);
    let ttt = 0;
    datax.forEach((item) => {
      setTotalApps(item.planId.openApps);
      if (item.active) {
        if (item.isByApps) {
          ttt += parseFloat(item.planId.openApps) * parseFloat(item.price);
        } else {
          ttt += item.price;
        }
      }
    });

    setTotal(ttt);
    return datax;
  };

  useEffect(() => {
    filterData(expenses);
  }, [refetch]);

  return (
    <>
      <h2>
        Total: {priceFormat(total)} &nbsp;&nbsp;&nbsp; Apps: {totalApps}{" "}
      </h2>
      <BaseTable {...restProps} dataSource={expenses} refetch={refetch} scroll={{ y: 450 }}>
        <Table.Column
          fixed="left"
          width="150px"
          align="center"
          title={t("actions")}
          dataIndex="actions"
          key="actions"
          render={(_, data) => (
            <div className="">
              <Space size="small">
                <a href="#!" onClick={() => handleDetails(data)} role="button" tabIndex={0}>
                  <Tooltip placement="top" title={`${t("clickTo")} ${t("moreDetails")}`}>
                    <userOutlined />
                  </Tooltip>
                </a>
                <a href="#!" onClick={() => handleEdit(data)} role="button" tabIndex={0}>
                  <Tooltip placement="top" title={`${t("clickTo")} ${t("edit")}`}>
                    <EditOutlined />{" "}
                  </Tooltip>
                </a>
                <a href="#!" onClick={() => handleArchived({ expense: data, t, refetch })} role="button" tabIndex={0}>
                  <Tooltip placement="top" title={`${t("clickTo")} ${t("delete")}`}>
                    <DeleteOutlined />
                  </Tooltip>
                </a>
                <a href="#!" onClick={() => handleInactive({ expense: data, t, refetch })} role="button" tabIndex={0}>
                  {data.active ? (
                    <Tooltip placement="top" title={`${t("clickToChange")} ${t("expenseOnHold")}`}>
                      <CheckCircleTwoTone twoToneColor={constantsColor.success} />
                    </Tooltip>
                  ) : (
                    <Tooltip placement="top" title={`${t("clickToChange")} ${t("expenseCompleted")}`}>
                      <ClockCircleOutlined className="danger" style={{ color: constantsColor.warning }} />
                    </Tooltip>
                  )}
                </a>
              </Space>
            </div>
          )}
        />

        <Table.Column
          title="Proveedor"
          dataIndex="supplier"
          key="supplier"
          render={(text, row) => <>{row.supplierId?.fullname}</>}
        />

        <Table.Column
          title={t("service")}
          dataIndex="service"
          key="service"
          render={(text, row) => <>{row.serviceId?.description}</>}
        />

        <Table.Column
          title={t("price")}
          dataIndex="price"
          key="price"
          render={(text, row) => <>{priceFormat(row.price)}</>}
        />

        <Table.Column
          title="Tipo de costo"
          dataIndex="openApps"
          key="openApps"
          render={(text, row) => <>{row.isByApps ? " Por aplicación " : "  General"}</>}
        />

        <Table.Column
          title="Total"
          dataIndex="openApps"
          key="openApps"
          render={(text, row) => (
            <>
              {row.isByApps
                ? priceFormat(parseFloat(row.planId.openApps) * parseFloat(row.price))
                : priceFormat(row.price)}
            </>
          )}
        />
      </BaseTable>
    </>
  );
};
export default withRouter(ExpensesTable);
