import { FetchJson } from "../../helpers/fetch";
import { apiRoutes } from "../../constants/routes";

const savePlan = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { planId } = props;
  const method = planId ? "PUT" : "POST";
  const path = planId ? `${apiRoutes.PLANSCONTENT.SAVE_DATA}/${planId}` : `${apiRoutes.PLANSCONTENT.SAVE_DATA}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const savePlanContent = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { planId } = props;
  const method = planId ? "PUT" : "POST";
  const path = planId
    ? `${apiRoutes.PLANSCONTENT.SAVE_DATA_CONTENT}/${planId}`
    : `${apiRoutes.PACKS.SAVE_DATA_CONTENT}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const getPlan = async (planId = 0, { onSuccess, onError } = {}) => {
  console.log("aqui 2");
  try {
    const request = await FetchJson(`${apiRoutes.PLANSCONTENT.GET_DATA}/${planId}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getPlanByCode = async (planId = 0, { onSuccess, onError } = {}) => {
  console.log("aqui 2");
  try {
    const request = await FetchJson(`${apiRoutes.PLANSCONTENT.GET_DATA_BY_CODE}/${planId}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const archivedPlan = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id } = params.plan;
  const method = "PUT";
  const path = `${apiRoutes.PLANSCONTENT.ARCHIVED}/${_id}`;

  try {
    const request = await FetchJson(path, method, { archived: true });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const refreshJsonPlanToS3 = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const method = "GET";
  const path = `${apiRoutes.PLANSCONTENT.REFRESH_JSON_S3_WEBSITE}`;

  try {
    const request = await FetchJson(path, method, { archived: true });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const activePlan = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id, active } = params.plan;
  const method = "PUT";
  const path = `${apiRoutes.PLANSCONTENT.ACTIVE}/${_id}`;

  try {
    const request = await FetchJson(path, method, { active: !active });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const duplicatePlansByCategoryPack = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const method = "POST";
  const path = `${apiRoutes.PLANSCONTENT.DUPLICATE_ALL_BY_CATEGORY_PACK}`;

  try {
    const request = await FetchJson(path, method, params);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const getPlans = async ({ onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.PLANSCONTENT.ALL}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};
export {
  getPlan,
  duplicatePlansByCategoryPack,
  savePlanContent,
  savePlan,
  archivedPlan,
  activePlan,
  getPlans,
  refreshJsonPlanToS3,
  getPlanByCode,
};
