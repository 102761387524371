import { useState } from "react";
import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";

const FiltersTable = ({ setFilters, filters }) => {
  const { t } = useTranslation();

  const [close, setClose] = useState(false);
  const [progress, setProgress] = useState(false);
  const [all, setAll] = useState(false);

  const handleCloseSelected = () => {
    setClose(true);
    setProgress(false);
    setAll(false);
    setFilters({ ...filters, status: "close", orderDate: "desc" });
  };

  const handleProgress = () => {
    setProgress(true);
    setClose(false);
    setAll(false);
    setFilters({ ...filters, status: "progress", orderDate: "desc" });
  };

  const handleAll = () => {
    setProgress(false);
    setClose(false);
    setAll(true);
    setFilters({ ...filters, status: "", orderDate: "desc" });
  };

  return (
    <>
      <span className="fieldset-consolidate-filters">
        {/* <span className="label-consolidate-button-filter"> {t("general")}</span> */}
        <Space>
          <Button
            className="tag-button-table"
            size="small"
            type={all === true ? "primary" : "default"}
            onClick={() => handleAll()}
          >
            {t("all")}
          </Button>

          <Button
            className="tag-button-table"
            size="small"
            type={progress === true ? "primary" : "default"}
            onClick={() => handleProgress()}
          >
            Mostrar NO finalizadas
          </Button>

          <Button
            className="tag-button-table"
            size="small"
            type={close === true ? "primary" : "default"}
            onClick={() => handleCloseSelected()}
          >
            Mostrar las finalizadas
          </Button>
        </Space>
      </span>
    </>
  );
};

export default FiltersTable;
