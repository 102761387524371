import { useState, useEffect } from "react";
import { Col, Row, Spin } from "antd";

import { withRouter } from "react-router-dom";

import BoxGraphCurrentPlans from "../components/boxGraphCurrentPlans";
import BoxTableCurrentEventApps from "../components/boxTableCurrentEventApps";

import { handleCurrentAppActives } from "../functions";

import { numbers } from "../../../constants/globals";

import SelectPacks from "../../subscribers/components/SelectPacks";
import SelectPlans from "../../subscribersPlans/components/SelectPlans";

import rocketLoading from "../../../assets/loading.gif";

const moment = require("moment");

const Dashboard = ({ tab, handleOpenUser, handleUpdateUser, dashboardTab }) => {
  const [currentAppActives, setCurrentAppActives] = useState({});
  const [packInfo, setPackInfo] = useState({});

  const [loading, setLoading] = useState(true);
  const [planInfo, setPlanInfo] = useState({});
  const [packId, setPackId] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [reload, setReload] = useState(0);

  const renderActiveApps = (result) =>
    result.map((item) => (
      <p className="special-font">
        <span className="big-font">{item.value}</span> {item.type}
      </p>
    ));

  const handlePack = (res) => {
    setPackId(res);
    setPlanId(null);
  };

  const handlePlan = (res) => {
    setPlanId(res);
  };

  useEffect(() => {
    if (tab === numbers.FIVETEEN && dashboardTab === numbers.TWO) {
      setLoading(true);
      handleCurrentAppActives(
        {
          desde: moment().format("YYYY-MM-DD"),
          hasta: moment().format("YYYY-MM-DD"),
          kind: "event",
          packId: packId || null,
          planId: planId || null,
        },
        {
          onSuccess: (metricas) => {
            setCurrentAppActives(metricas.currentApps);
            setLoading(false);
          },
        }
      );
    }
  }, [planId, packId]);

  useEffect(() => {
    if (tab === numbers.FIVETEEN && dashboardTab === numbers.TWO) {
      setLoading(true);
      setPackId(null);
      setPlanId(null);
      setReload(Math.floor(Math.random() * 100000));

      handleCurrentAppActives(
        {
          desde: moment().format("YYYY-MM-DD"),
          hasta: moment().format("YYYY-MM-DD"),
          kind: "event",
          packId: packId || null,
          planId: planId || null,
        },
        {
          onSuccess: (metricas) => {
            setCurrentAppActives(metricas.currentApps);
            setLoading(false);
          },
        }
      );
    }
  }, [dashboardTab, tab]);

  return (
    tab === numbers.FIVETEEN &&
    dashboardTab === numbers.TWO && (
      <div className="site-card-wrapper">
        <Row gutter={(24, 24)}>
          <Col span={12} xs={24} sm={24} md={12} lg={6} xl={6}>
            {" "}
          </Col>
          <Col span={12} xs={24} sm={24} md={12} lg={6} xl={6}>
            <div className="box-white">
              <h2 className="special-font bold medium-font">
                Seleccione un Programa:{" "}
                <SelectPacks
                  type="SUBSCRIPTION"
                  reload={reload}
                  currentValue={packId}
                  defaultValue={packId}
                  handleSelected={handlePack}
                  returnData={handlePack}
                  setPackInfo={setPackInfo}
                  kind="event"
                  title="VER TODOS"
                />{" "}
              </h2>
            </div>
          </Col>
          <Col span={12} xs={24} sm={24} md={12} lg={6} xl={6}>
            <div className="box-white">
              {packId && packId?.length > 5 && (
                <h2 className="special-font bold medium-font">
                  Seleccione un plan:{" "}
                  <SelectPlans
                    handleSelected={setPlanInfo}
                    reload={reload}
                    currentValue={planId}
                    defaultValue={planId}
                    returnData={handlePlan}
                    type="SUBSCRIPTION"
                    packId={packId}
                    title="VER TODOS"
                  />
                </h2>
              )}
            </div>
          </Col>
          <Col span={12} xs={24} sm={24} md={12} lg={6} xl={6}>
            {" "}
          </Col>
        </Row>

        {!loading ? (
          <>
            <Row gutter={(24, 24)}>
              <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24}>
                {currentAppActives.plans && currentAppActives.plans.length > 0 && (
                  <div className="box-white">
                    <h3 className="special-font bold">Resumen actual de las subscripciones</h3>
                    <BoxGraphCurrentPlans data={currentAppActives.plans} />
                  </div>
                )}
              </Col>
              <Col span={12} xs={24} sm={24} md={12} lg={8} xl={8}>
                {currentAppActives.data && currentAppActives.data.length > 0 && (
                  <div className="box-white">
                    <h3 className="special-font bold">
                      <span className="big-font">{currentAppActives.data.length}</span>
                      {"  "}SUBSCRIPCIONES EN TOTAL
                      <hr />
                      {currentAppActives.plans &&
                        currentAppActives.plans.length > 0 &&
                        renderActiveApps(currentAppActives.plans)}
                    </h3>
                  </div>
                )}
              </Col>
              <Col span={12} xs={24} sm={24} md={12} lg={8} xl={8}>
                <div className="box-white">
                  {currentAppActives.data && currentAppActives.data.length > 0 && (
                    <>
                      <h3 className="special-font bold">
                        <span className="big-font">{currentAppActives.data.length}</span>
                        {"  "}ASISTENCIAS
                      </h3>

                      <hr />
                      {currentAppActives.insitu &&
                        currentAppActives.insitu.length > 0 &&
                        renderActiveApps(currentAppActives.insitu)}
                      <hr />
                      {currentAppActives.insitu && currentAppActives.insitu.length > 0 && (
                        <BoxGraphCurrentPlans data={currentAppActives.insitu} />
                      )}
                    </>
                  )}
                </div>
              </Col>
              <Col span={12} xs={24} sm={24} md={12} lg={8} xl={8}>
                <div className="box-white">
                  {currentAppActives.data && currentAppActives.data.length > 0 && (
                    <>
                      <h3 className="special-font bold">
                        <span className="big-font">{currentAppActives.referer?.length}</span>
                        {"  "}REFERIDOS POR:
                      </h3>

                      <hr />
                      {currentAppActives.referer &&
                        currentAppActives.referer?.length > 0 &&
                        renderActiveApps(currentAppActives.referer)}
                      <hr />
                      {currentAppActives.referer && currentAppActives.referer?.length > 0 && (
                        <BoxGraphCurrentPlans data={currentAppActives.referer} />
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24}>
                {currentAppActives?.details && currentAppActives?.details?.apps?.length > 0 && (
                  <>
                    <h3 className="special-font bold">Listado de Subscriptores</h3>
                    <BoxTableCurrentEventApps
                      data={currentAppActives.details.apps}
                      handleOpenUser={handleOpenUser}
                      handleUpdateUser={handleUpdateUser}
                    />
                  </>
                )}
              </Col>
            </Row>
          </>
        ) : (
          <p style={{ textAlign: "center", paddingTop: 120 }}>
            <Spin tip="Consultando información... " /> <br />
          </p>
        )}
      </div>
    )
  );
};

export default withRouter(Dashboard);
