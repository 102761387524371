import { Table, Tooltip } from "antd";

import { withRouter } from "react-router-dom";
import BaseTable from "../../shared/components/BasicTable";
import { priceFormat, paymentFormat, icons } from "../../../constants/globals";

const PlansTable = ({ data }) => {
  console.log(data);
  return (
    <>
      <BaseTable pagination="false" dataSource={data}>
        <Table.Column
          fixed="left"
          width="50px"
          render={(value, row, key) => (
            <h5 style={{ textAlign: "center" }}>
              <b>{key + 1}</b>
            </h5>
          )}
        />
        <Table.Column
          title="Contacto"
          dataIndex="value"
          key="value"
          render={(text, row) => (
            <p style={{ textTransform: "uppercase", marginBottom: 0 }}>
              {`${row?.userId?.firstname} ${row?.userId?.lastname || ""}`}
            </p>
          )}
        />
        <Table.Column
          title="Plan"
          dataIndex="planId"
          key="planId"
          render={(text, row) => <small>{`${row?.packId?.name} ${row?.planId?.name}`}</small>}
        />
        <Table.Column
          title="monto"
          dataIndex="amount"
          key="startDate"
          render={(text, row) => priceFormat(row?.amount)}
        />
        <Table.Column title="mode" dataIndex="mode" key="mode" render={(text, row) => paymentFormat(row?.mode)} />
      </BaseTable>
    </>
  );
};

export default withRouter(PlansTable);
