import { customFetch } from "../../helpers/fetch";
import { apiRoutes } from "../../constants/routes";
import { getKeypublic } from "../../utils/localStorage";

const getViewPage = async (filter, { onSuccess, onError } = {}) => {
  try {
    const params = new URLSearchParams({ ...filter, keypublic: getKeypublic() }).toString();

    const request = await customFetch(`${apiRoutes.TRACKS.GET_PAGES_VIEW}?${params}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getActions = async (filter, { onSuccess, onError } = {}) => {
  try {
    const params = new URLSearchParams({ ...filter, keypublic: getKeypublic() }).toString();

    const request = await customFetch(`${apiRoutes.TRACKS.GET_ACTIONS}?${params}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getViewsByContact = async (filter, { onSuccess, onError } = {}) => {
  try {
    const params = new URLSearchParams({ ...filter, keypublic: getKeypublic() }).toString();

    const request = await customFetch(`${apiRoutes.TRACKS.GET_PAGES_VIEW_BY_CONTACT}?${params}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getActionsByContact = async (filter, { onSuccess, onError } = {}) => {
  try {
    const params = new URLSearchParams({ ...filter, keypublic: getKeypublic() }).toString();

    const request = await customFetch(`${apiRoutes.TRACKS.GET_ACTIONS_BY_CONTACT}?${params}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getResumePageViewsByContact = async (filter, { onSuccess, onError } = {}) => {
  try {
    const params = new URLSearchParams({ ...filter, keypublic: getKeypublic() }).toString();

    const request = await customFetch(`${apiRoutes.TRACKS.GET_RESUME_PAGEVIEWS_BY_CONTACT}?${params}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getResumeTracksByContact = async (filter, { onSuccess, onError } = {}) => {
  try {
    const params = new URLSearchParams({ ...filter, keypublic: getKeypublic() }).toString();

    const request = await customFetch(`${apiRoutes.TRACKS.GET_RESUME_TRACKS_BY_CONTACT}?${params}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

export {
  getViewPage,
  getActions,
  getViewsByContact,
  getActionsByContact,
  getResumePageViewsByContact,
  getResumeTracksByContact,
};
