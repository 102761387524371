/* eslint-disable no-sparse-arrays */
/* eslint-disable no-unused-expressions */
import { useState, useEffect } from "react";
import { Drawer, Affix, Col, Row, Tabs, Layout, Spin, Space, Button } from "antd";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert";
import useModal from "../shared/hooks/useModal";

import HeaderUser from "./components/headerUser";

import AddUserCheckin from "../subscribersUsers/components/addUserCheckin";
import AddUserGuest from "../subscribersUsers/components/addUserGuest";
import CreateApp from "../subscribersApps/components/CreateApp";
import ManageAppModalSubscription from "../subscribersApps/components/ManageAppModalSubscription";

import CreateAppProduct from "../subscribersApps/components/CreateAppProduct";
import CreateAppEvent from "../subscribersApps/components/CreateAppEvent";
import CreateAppRental from "../subscribersApps/components/CreateAppRental";
import CreateAppNewEvent from "../subscribersApps/components/CreateAppNewEvent";
import EditEvent from "../subscribersApps/components/EditEvent";
import { reloadPlanRedux } from "../../redux/plan";

import CreateSingleApp from "../subscribersApps/components/CreateSingleApp";

import ActivateApp from "../subscribersApps/components/ActivateApp";
import ActivateExpressApp from "../subscribersApps/components/ActivateExpressApp";

import RenovateApp from "../subscribersApps/components/RenovateApp";

import ManageBucketModal from "../bucket/components/ManageBucketModal";

import AppsContainer from "../subscribersApps/containers/Apps2";

import AddPaymentAnalist from "../subscribersTrans/components/addPaymentAnalist";

import { handleCheckinUser } from "../subscribersUsers/functions/index";

import { handleGetAppCountPending, handleClosedApp, handleArchivedConfirm } from "../subscribersApps/functions";
import { handleClosePlan } from "../subscribersPlans/functions";

import { numbers, actionsUsers, icons, colorsSystem, isModuleValid, uploadType } from "../../constants/globals";

import logo from "../../assets/robot.png";

import { spaRoutes } from "../../constants/routes";

const Swal = require("sweetalert2");

const { Content } = Layout;

const Analist = ({ mainReload, currentPage, match, history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isOpenManageBucketModal, openManageBucketModal, closeManageBucketModal] = useModal();
  const [isOpenManagePlanBucketModal, openManagePlanBucketModal, closeManagePlanBucketModal] = useModal();

  const sessionStoreRedux = useSelector((store) => store.session);
  const instanceStoreRedux = useSelector((store) => store.instance);
  const planStoreRedux = useSelector((store) => store.plan);
  const [typeUploadplan, setTypeUploadPlan] = useState(uploadType.GALLERY);

  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState(false);
  const [appSelected, setAppSelected] = useState({});
  const [userSelected, setUserSelected] = useState({});

  const [planSelected, setPlanSelected] = useState({});

  const [planId, setPlanId] = useState(null);
  const [showApps, setShowApps] = useState(false);

  const [option, setOption] = useState({});
  const [reload, setReload] = useState(0);

  const [userId, setUserId] = useState(false);

  const [packId, setPackId] = useState(null);
  const [isMobile, setMobile] = useState(false);

  const [tab, setTab] = useState(currentPage);

  const [activeTab, setActiveTab] = useState(0);
  const [titleDraw, setTitleDraw] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerMiniOpen, setIsDrawerMiniOpen] = useState(false);
  const [desactivate, setDesactivate] = useState(false);

  const [reloadApps, setReloadApps] = useState(false);
  const [pendingApps, setPendingApps] = useState(0);

  const [blockActions, setBlockActions] = useState(false);

  const refetch = () => {};

  const onClose = () => {
    setVisible(false);
    setIsDrawerMiniOpen(false);
    setIsDrawerOpen(false);
    setDesactivate(false);
    setReload(Math.floor(Math.random() * 100000));
  };

  const handleEditEvent = (planId__) => {
    setReload(Math.floor(Math.random() * 100000));
    setPlanId(planId__);
    setOption(actionsUsers.EDIT_EVENT.number);
    setTitleDraw(actionsUsers.EDIT_EVENT.title);
    setIsDrawerOpen(true);
    setVisible(true);
  };

  const handleClosePlanByGuide = (plan) => {
    setReload(Math.floor(Math.random() * 100000));
    if (plan._id) setPlanId(plan._id);
    else setPlanId(planId);

    handleClosePlan(
      { plan: { _id: plan._id || planId }, t, refetch, planInfo: plan },
      {
        onSuccess: () => reloadPlanRedux()(dispatch),
      }
    );
  };

  const handleChangeTab = (val) => {
    if (val === numbers.ONE) refetch();
    setTab(val);
    setActiveTab(val);
  };

  const handleTabs = (tab_) => {
    setShowApps(false);

    if (tab_ === numbers.THIRTEEN) {
      console.log("necesito recargar");
    }

    if (tab_) {
      setActiveTab(tab_);
      setTab(tab_);
    }
  };

  const handleUploadPlanFile = () => {
    setReload(Math.floor(Math.random() * 100000));
    setPlanId(planId);
    openManageBucketModal();
  };

  useEffect(() => {
    setBlockActions(true);
    if (match?.params.planId) {
      console.log(match?.params.planId);
      setPlanId(match?.params.planId);
      setBlockActions(false);
    }
  }, [match]);

  useEffect(() => {
    console.log(reload);
  }, [reload]);

  useEffect(() => {
    handleChangeTab(localStorage.getItem("cluuf_last_session") || numbers.ONE);
  }, [mainReload]);

  useEffect(() => {
    if (planSelected._id) {
      setReloadApps(true);
      setPlanId(planSelected._id);
      setShowApps(false);
      setTimeout(() => {
        setShowApps(true);
        setReloadApps(false);
      }, 1000);
    }
  }, [planSelected]);

  useEffect(() => {
    localStorage.setItem("cluuf_last_tabs", tab);

    handleGetAppCountPending({
      onSuccess: (pendings) => setPendingApps(pendings.result),
      onError: () => {},
    });

    handleTabs(tab);
  }, [tab, packId, planStoreRedux.reloadPlan]);

  useEffect(() => {
    if (localStorage.getItem("cluuf_last_tabs")) handleTabs(localStorage.getItem("cluuf_last_tabs"));
    if (window.innerWidth < 769) setMobile(true);
  }, []);

  return (
    <Layout className="layout analist-layout">
      <Affix offsetTop={-5}>
        <div className="main-header" style={{ padding: 10, background: "#1b203e", height: 60 }}>
          <Row>
            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
              <h1 style={{ color: "#fff", paddingLeft: 50, marginTop: "-5px" }} className="special-font big-font bold">
                <img src={logo} alt="logo" style={{ width: 30, position: "absolute" }} />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{instanceStoreRedux.currentInstance.alias}
              </h1>
            </Col>
          </Row>
        </div>
      </Affix>
      <Content style={{ background: "#EFF4FB", marginTop: 20 }}>
        <Layout className="layout">
          <Content className="container-interno">
            <div className="site-layout-content box-white">
              {blockActions && (
                <Row>
                  <Col span={24} style={{ margin: "auto" }}>
                    <p style={{ textAlign: "center" }}>
                      <Spin tip={t("contact_ckeckProcessing")} />{" "}
                    </p>
                  </Col>
                </Row>
              )}

              {planId && (
                <div className="subcontainer-wrapper">
                  <AppsContainer
                    isEmbed
                    reload={reload}
                    planId={planId}
                    handleUploadPlanFile={handleUploadPlanFile}
                    handleClosePlanByGuide={handleClosePlanByGuide}
                    isMinimal
                    handleCreateAppEditEvent={handleEditEvent}
                  />
                </div>
              )}
            </div>

            <ManageBucketModal
              entityProp="Plan"
              typeProp={typeUploadplan}
              entityId={planId}
              openModal={isOpenManageBucketModal}
              closeModal={closeManageBucketModal}
            />

            {isDrawerOpen && (
              <Drawer
                title={titleDraw}
                width="90%"
                onClose={onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
              >
                {option === actionsUsers.CREATE_APP.number && (
                  <CreateApp
                    onClose={onClose}
                    refetch={refetch}
                    selectedApp={{ userId: "123456789", startDate: null, closureDate: null }}
                  />
                )}

                {option === actionsUsers.UPDATE_APP_SUBSCRIPTION.number && (
                  <ManageAppModalSubscription onClose={onClose} refetch={refetch} selectedApp={appSelected} />
                )}

                {option === actionsUsers.CREATE_APP_EVENT.number && (
                  <CreateAppEvent
                    onClose={onClose}
                    refetch={refetch}
                    userSelected={userSelected}
                    selectedApp={{ userId: "123456789", startDate: null, closureDate: null }}
                  />
                )}

                {option === actionsUsers.CREATE_APP_NEW_EVENT.number && (
                  <CreateAppNewEvent
                    onClose={onClose}
                    refetch={refetch}
                    selectedApp={{ userId: "123456789", startDate: null, closureDate: null }}
                  />
                )}

                {option === actionsUsers.EDIT_EVENT.number && (
                  <EditEvent onClose={onClose} refetch={refetch} planId_={planId} />
                )}

                {option === actionsUsers.SINGLE_APP.number && userId && (
                  <CreateSingleApp onClose={onClose} refetch={refetch} selectedUser={user} />
                )}
              </Drawer>
            )}
          </Content>

          {planId && (
            <ManageBucketModal
              entityProp="Plan"
              typeProp="Gallery"
              entityId={planId}
              openModal={isOpenManagePlanBucketModal}
              closeModal={closeManagePlanBucketModal}
            />
          )}
        </Layout>
      </Content>
    </Layout>
  );
};
export default withRouter(Analist);
