export const constantsApp = Object.freeze({
  CATEGORIES: {
    "CATEGORIA 01": "CATEGORIA 01",
    "CATEGORIA 02": "CATEGORIA 02",
    "CATEGORIA 03": "CATEGORIA 03",
  },
  STATUS: {
    CONFIRMADA: "Completadas",
    POR_CONFIRMAR: "En espera",
    CANCELADA: "Canceladas",
    OPEN: "open",
    CANCEL: "cancel",
    CLOSE: "close",
    INWAIT: "inwait",
  },
  DURATION: {
    "DURATION 01": "DURATION 01",
    "DURATION 02": "DURATION 02",
    "DURATION 03": "DURATION 03",
  },
});
