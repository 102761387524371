import { useState } from "react";
import { Table, Tag, Tooltip, Avatar } from "antd";
import { CommentOutlined, MailOutlined, CalendarOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import BaseTable from "../../shared/components/BasicTable";

import { dateFormat6, calculateExpirationPlan } from "../../../constants/globals";

const AppsTable = ({
  isEmbed,
  selectedUser,
  setSelectedUser,
  setPage,
  history,
  apps,
  refetch,
  handleDetails,
  handleGoto,
  handleEdit,
  userId,
  handleArchived,
  handleActive,
  handleAddTrans,
  modeDetails,
  t,
  handleCheckActions,
  ...restProps
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState(false);

  const onSelectChange = (selectedRowKeys1) => {
    setSelectedRowKeys(selectedRowKeys1);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const commentOnRow = (comment = "") => {
    if (comment?.length > 0) {
      return (
        <Tooltip title={comment}>
          <CommentOutlined />
        </Tooltip>
      );
    }
  };

  return (
    <>
      <BaseTable {...restProps} dataSource={apps} refetch={refetch} scroll={{ x: 500 }}>
        <Table.Column
          fixed="left"
          title={t("contacts")}
          dataIndex="userId"
          width="250px"
          key="userId"
          sorter
          render={(value, row) => (
            <>
              <h5>
                <Avatar size="small" src={row?.userId?.avatar} /> <b>{value?.firstname} </b>
                {commentOnRow(row?.observation)}
              </h5>
              <MailOutlined /> {value?.email}
            </>
          )}
        />
        <Table.Column
          title={t("code")}
          dataIndex="code"
          key="code"
          sorter
          render={(value, row) =>
            (
              <>
                <h3>
                  {row.isPayment ? <Tag color="green">Pagada </Tag> : <Tag color="volcano">Sin pago</Tag>}
                  {"  "} {row.planId.name} [{row.code}]
                </h3>{" "}
                <hr style={{ borderColor: "#4a299029" }} />{" "}
                <b>
                  {" "}
                  <CalendarOutlined /> {dateFormat6(row.startDate)} - Vence {dateFormat6(row.closureDate)}{" "}
                  {calculateExpirationPlan(row.closureDate)}
                </b>
              </>
            ) || ""
          }
        />
      </BaseTable>
    </>
  );
};

export default withRouter(AppsTable);
