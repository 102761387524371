import { useEffect, useState } from "react";
import { Table, Space, Tooltip, Button, Row, Col } from "antd";
import { useSelector } from "react-redux";

import { DeleteOutlined, TagTwoTone } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import BaseTable from "../../shared/components/BasicTable";

import ActionsEventTable from "./ActionsEventTable";
import SelectRoots from "../../subscribersRoots/components/SelectUsers";
import { saveGuidePlan } from "../../subscribersPlans/requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";

import {
  dateFormat6,
  isRoleAdmin,
  icons,
  formatProgressApp,
  formatEndingApp,
  isRoleAnalist,
  eventStatusFormatWord,
} from "../../../constants/globals";

const Swal = require("sweetalert2");

const AppsTable = ({
  isEmbed,
  selectedUser,
  setSelectedUser,
  setPage,
  history,
  apps,
  refetch,
  handleDetails,
  handleGoto,
  handleEditUser,
  handleEdit,
  userId,
  handleArchived,
  handleActive,
  handleAddTrans,
  modeDetails,
  refreshPlan,
  handleResume,
  t,
  handleCheckActions,
  handleUploadPlanFile,
  isMinimal = false,
  handleClosePlanByGuide,
  handleInsitu,
  handleCheckActionsArchived,
  setVisible,
  handleCreateAppEditEvent,
  handleMoveAppEvent,
  ...restProps
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState(false);

  const sessionStoreRedux = useSelector((store) => store.session);
  const planStoreRedux = useSelector((store) => store.plan);
  const [guideId, setGuideId] = useState(null);
  const [tourguide, setTourguide] = useState("");
  const { role } = sessionStoreRedux.currentSession;

  const onSelectChange = (selectedRowKeys1) => {
    setSelectedRowKeys(selectedRowKeys1);
  };

  const [loadingDetails, setLoadingDetails] = useState(null);
  const handleLoading = (data) => setLoadingDetails(data._id);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleGuide = (value) => {
    setGuideId(value);

    if (planStoreRedux?.currentPlan?._id) {
      saveGuidePlan(
        {
          planId: planStoreRedux?.currentPlan?._id,
          guideId: value,
        },
        {
          onSuccess: (result) => {
            if (result.result.guideId._id) {
              setTourguide(`${result.result.guideId.firstname} ${result.result.guideId.lastname || ""}`);
            }
            Swal.fire(t("common_done"), "El Guide ha sido actualizado", "success");
          },
          onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
          onFinally: () => {},
        }
      );
    }
  };

  const filterOnlyActive = (data) => {
    const rows = [];
    data.forEach((element) => {
      if (element.status !== "close" && element.status !== "cancel") {
        rows.push(element);
      }
    });
    return rows;
  };

  useEffect(() => {
    if (planStoreRedux.currentPlan) {
      setTourguide(`${planStoreRedux?.currentPlan?.guideId?.firstname}
      ${planStoreRedux?.currentPlan?.guideId?.lastname || ""}`);
    }
  }, [planStoreRedux]);

  return (
    <>
      {selectedRowKeys.length > 0 && isRoleAdmin(role) && (
        <div className="section-check-options">
          <Space>
            <Button
              size="large"
              key="1"
              onClick={() => {
                handleCheckActions(
                  {
                    selectedRowKeys,
                    planId_: apps[0].planId._id,
                    active_: false,
                    archived_: false,
                    insitu_: true,
                  },
                  {
                    onSuccess: () => {
                      setSelectedRowKeys([]);
                    },
                  }
                );
              }}
            >
              {t("insituApp")}
            </Button>

            <Button
              size="large"
              key="1"
              onClick={() => {
                handleCheckActions(
                  {
                    selectedRowKeys,
                    planId_: apps[0].planId._id,
                    active_: false, // para que las convierta en true
                    archived_: false,
                    insitu_: false,
                  },
                  {
                    onSuccess: () => {
                      setSelectedRowKeys([]);
                    },
                  }
                );
              }}
            >
              {t("NoInsituApp")}
            </Button>

            {!isMinimal && (
              <>
                <Button
                  size="large"
                  key="3"
                  className="ant-btn secondary error"
                  onClick={() => {
                    handleCheckActions(
                      {
                        archived_: true,
                        selectedRowKeys,
                        planId_: apps[0].planId._id,
                        active_: null, // para que las convierta en false
                      },
                      {
                        onSuccess: () => {
                          setSelectedRowKeys([]);
                        },
                      }
                    );
                  }}
                >
                  {icons.delete} {t("delete")}
                </Button>

                <Button
                  size="large"
                  key="3"
                  className="ant-btn secondary error"
                  onClick={() => {
                    handleCheckActionsArchived(
                      {
                        archived_: true,
                        selectedRowKeys,
                        planId_: apps[0].planId._id,
                      },
                      {
                        onSuccess: () => {
                          setSelectedRowKeys([]);
                        },
                      }
                    );
                  }}
                >
                  {icons.delete} {t("delete")}
                </Button>
              </>
            )}
          </Space>
        </div>
      )}
      {!isMinimal && (
        <BaseTable {...restProps} rowSelection={rowSelection} dataSource={apps} refetch={refetch} scroll={{ y: 450 }}>
          <Table.Column
            dataIndex="_id"
            key="actions"
            align="center"
            width="100px"
            render={(_, data) => (
              <div>
                <Space size="small">
                  {isRoleAdmin(role) && (
                    <Button
                      size="small"
                      className="btn-ant secondary primary"
                      onClick={() => {
                        handleLoading(data);
                        handleDetails(data);
                      }}
                    >
                      {loadingDetails === data._id ? <img alt="" src={icons.loading} width="15" /> : t("plan_openIt")}
                    </Button>
                  )}
                </Space>
              </div>
            )}
          />

          <Table.Column
            title={t("status")}
            dataIndex="isProgress"
            width="80px"
            key="isProgress"
            sorter
            align="center"
            render={(value, row) => (
              <>
                {formatProgressApp(row)}
                <br />
                <small>{row.code}</small>
              </>
            )}
          />

          <Table.Column
            title={t("plan")}
            dataIndex="_id"
            key="name"
            width="200px"
            render={(_, row) => (
              <>
                <span style={{ fontSize: 16, fontWeight: 800, position: "relative", top: -3, left: 20 }}>
                  {row?.packId?.name}{" "}
                  {row.kind !== "product" && !row.isTemporal && `[${row.checkins.length} asistencias]`}
                </span>

                <h3>
                  <TagTwoTone twoToneColor={row?.packId?.color} /> {"  "} {row?.planId?.name}
                </h3>
              </>
            )}
          />

          <Table.Column
            title={t("contacts")}
            dataIndex="userId"
            width="280px"
            key="name"
            sorter
            render={(_, row) => (
              <>
                <span style={{ textTransform: "uppercase", fontSize: 16, fontWeight: 800 }}>
                  <b>
                    {_?.firstname} {_?.lastname}
                  </b>
                  <br />
                </span>
                <small>
                  {dateFormat6(row?.startDate)} - {dateFormat6(row?.closureDate)}
                </small>{" "}
                {formatEndingApp(row)}
              </>
            )}
          />

          <Table.Column
            title={t("actions")}
            dataIndex="actions"
            width="50px"
            key="actions"
            align="center"
            render={(_, data) => (
              <div className="">
                <Space size="small">
                  {isRoleAdmin(role) && (
                    <a href="#!" onClick={() => handleArchived(data)} role="button" tabIndex={0}>
                      <Tooltip placement="top" title={`${t("clickTo")} ${t("delete")}`}>
                        <DeleteOutlined />
                      </Tooltip>{" "}
                    </a>
                  )}
                </Space>
              </div>
            )}
          />
        </BaseTable>
      )}

      {isMinimal && isEmbed && (
        <div style={{ padding: "20px 0px" }}>
          <Row>
            {!isRoleAdmin(role) && (
              <Col span={12} xs={24} sm={12} md={3} lg={4} xl={3}>
                {" "}
                <a
                  style={{
                    fontSize: 32,
                    height: 56,
                    fontWeight: "bold",
                    marginBottom: 20,
                    background: "green",
                    marginLeft: 30,
                  }}
                  className="ant-btn secondary  big-mobile"
                  href="/TourGuide"
                >
                  <span style={{ padding: 10 }}> {icons.back} </span>
                </a>
              </Col>
            )}

            {planStoreRedux?.currentPlan?.guideId && planStoreRedux?.currentPlan?.guideId?._id && (
              <Col span={12} xs={24} sm={12} md={12} lg={5} xl={5}>
                <p className="box-info">
                  <b>Tour Guide: {tourguide}</b>
                  <SelectRoots
                    reload={() => {}}
                    currentValue={guideId}
                    defaultValue={guideId}
                    returnData={handleGuide}
                    typePack="SUBSCRIPTION"
                    title="Cambiar a"
                  />
                </p>
              </Col>
            )}

            {apps.length > 0 && isRoleAdmin(role) && (
              <Col span={12} xs={24} sm={12} md={12} lg={4} xl={3} className="center">
                <Button
                  className="ant-btn secondary primary large big-mobile "
                  style={{ width: "90%" }}
                  onClick={() => history.push(`/analist/plan/${apps[0].planId._id}`)}
                >
                  {icons.reload} Refrescar
                </Button>
              </Col>
            )}
            {planStoreRedux?.currentPlan && (
              <Col span={12} xs={24} sm={12} md={12} lg={5} xl={5} className="center">
                <Button
                  style={{ width: "90%" }}
                  className="ant-btn secondary primary  large big-mobile "
                  onClick={() => handleCreateAppEditEvent(planStoreRedux?.currentPlan?._id)}
                >
                  {icons.calendar} Editar evento3
                </Button>
              </Col>
            )}
            <Col span={12} xs={24} sm={12} md={12} lg={5} xl={5} className="center">
              {" "}
              <Button
                className="ant-btn secondary primary large big-mobile"
                style={{ width: "90%" }}
                onClick={() => setVisible(true)}
              >
                {icons.plus_circle} Agregar contacto
              </Button>{" "}
            </Col>

            {apps.length > 0 && (
              <Col span={12} xs={24} sm={12} md={12} lg={5} xl={5} className="center">
                <Button
                  className="ant-btn secondary info large big-mobile "
                  style={{ width: "90%" }}
                  onClick={() => handleUploadPlanFile()}
                >
                  {icons.foto} Subir foto
                </Button>
              </Col>
            )}
            {apps.length > 0 && apps[0].planId.status === "open" && (
              <Col span={12} xs={24} sm={12} md={12} lg={5} xl={5} className="center">
                <Button
                  style={{ width: "90%" }}
                  className="ant-btn secondary error large big-mobile"
                  onClick={() => handleClosePlanByGuide(apps[0].planId)}
                >
                  {icons.close} {"  "} Finalizar{" "}
                </Button>
              </Col>
            )}
          </Row>
        </div>
      )}

      {isMinimal && (
        <div className="isMinimal-mobile">
          <BaseTable {...restProps} dataSource={apps} refetch={refetch}>
            <Table.Column
              title={t("contacts")}
              dataIndex="userId"
              width="350px"
              key="name"
              sorter
              render={(_, row) => (
                <>
                  <h2 style={{ textTransform: "uppercase" }}>
                    <Space>
                      {isRoleAdmin(role) && (
                        <Button
                          style={{ width: "100%" }}
                          onClick={() => handleMoveAppEvent(row)}
                          className="ant-btn secondary info"
                        >
                          {icons.goto}
                        </Button>
                      )}

                      {row.packId.isFree && (
                        <Button
                          style={{ width: "100%" }}
                          onClick={() => handleEdit(row)}
                          className="ant-btn secondary info"
                        >
                          {icons.plus_circle}
                        </Button>
                      )}

                      <Button
                        style={{ width: "100%" }}
                        onClick={() => handleArchived(row)}
                        className="ant-btn secondary error"
                      >
                        {icons.delete}
                      </Button>

                      {isRoleAdmin(role) && (
                        <Button
                          style={{ width: "100%" }}
                          onClick={() => handleEditUser(row.userId)}
                          className="ant-btn secondary info"
                        >
                          {icons.edit}
                        </Button>
                      )}

                      {row.insitu ? (
                        <Button className="ant-btn secondary primary big-mobile " onClick={() => handleInsitu(row)}>
                          <Tooltip placement="top" title="Quitar asistencia">
                            {icons.check}
                          </Tooltip>
                        </Button>
                      ) : (
                        <Button className="ant-btn secondary error big-mobile " onClick={() => handleInsitu(row)}>
                          <Tooltip placement="top" title="Asignar asistencia">
                            {icons.check}
                          </Tooltip>
                        </Button>
                      )}
                    </Space>
                    {"  "}
                    <span className="medium-font  bold">
                      {_?.firstname} {_?.lastname}{" "}
                    </span>{" "}
                    x <span style={{ fontSize: 42, fontWeight: "bold" }}>{row?.quantity || ""}</span>
                    <span style={{ fontSize: 26, position: "relative", top: "-10px" }}>
                      {eventStatusFormatWord(row?.status)}
                    </span>
                  </h2>
                </>
              )}
            />
          </BaseTable>
        </div>
      )}
    </>
  );
};

export default withRouter(AppsTable);
