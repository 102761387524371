export const constantsPack = Object.freeze({
  CATEGORIES: {
    "CATEGORIA 01": "CATEGORIA 01",
    "CATEGORIA 02": "CATEGORIA 02",
    "CATEGORIA 03": "CATEGORIA 03",
  },
  DURATION: {
    "DURATION 01": "DUR 01",
    "DURATION 02": "DUR 02",
    "DURATION 03": "DUR 03",
  },
  CONTENT_TYPE: {
    LINK: "LINK",
    TEXT: "TEXT",
    IMAGE: "IMAGE",
    HTML: "HTML",
    FORM: "FORM",
  },
  TYPE: "CONTENT",
});
