export const constantsApp = Object.freeze({
  CATEGORIES: {
    "CATEGORIA 01": "CATEGORIA 01",
    "CATEGORIA 02": "CATEGORIA 02",
    "CATEGORIA 03": "CATEGORIA 03",
  },
  STATUS: {
    COMPLETED: "Completada",
    INWAIT: "En Espera",
    PENDING: "open",
    CANCELED: "cancel",
    CLOSED: "completed",
  },
  STATUS_ALIAS: {
    COMPLETED: "Completada",
    INWAIT: "En Espera",
    CANCEL: "Anulada",
  },
  TYPE: {
    OUTBOUND: "outbound",
    INBOUND: "inbound",
  },
  DURATION: {
    "DURATION 01": "DURATION 01",
    "DURATION 02": "DURATION 02",
    "DURATION 03": "DURATION 03",
  },
});
