import { customFetch, FetchJsonWithouToken, FetchJson } from "../../helpers/fetch";
import { apiRoutes } from "../../constants/routes";

const login = async (params = {}, { onSuccess, onError, onFinish, onFail } = {}) => {
  try {
    // global.loader.showLoading(loadingMode.FAST);
    localStorage.removeItem("instanceId");
    const response = await customFetch(apiRoutes.LOGIN.ROOT, {
      method: "POST",
      body: new URLSearchParams({ ...params }),
    });

    const result = await response.json();

    if (response.status === 400) {
      onFail(result);
    } else if (response.status === 200) {
      onSuccess(result);
      if (result.user) {
        localStorage.setItem("instanceId", result.user.instanceId);
      }
    }
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    // global.loader.hideLoading();
    onFinish();
  }
};

const loginuser = async (params = {}, { onSuccess, onError, onFinish, onFail } = {}) => {
  try {
    // global.loader.showLoading(loadingMode.FAST);
    // localStorage.removeItem("instanceId");
    const response = await customFetch(apiRoutes.LOGIN.USER, {
      method: "POST",
      body: new URLSearchParams({ ...params }),
    });

    const result = await response.json();

    if (response.status === 400) {
      onFail(result);
    } else if (response.status === 200) {
      onSuccess(result);
      if (result.user) {
        localStorage.setItem("instanceId", result.user.instanceId);
      }
    }
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    // global.loader.hideLoading();
    onFinish();
  }
};

const loadBranding = async (alias = "", { onSuccess, onError } = {}) => {
  try {
    const request = await FetchJsonWithouToken(`${apiRoutes.INSTANCE.BY_ALIAS}/${alias}`, "GET");
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    // onError();
  }
};

const loadUserBranding = async (alias = "", { onSuccess, onError } = {}) => {
  try {
    // La busqueda es por Id, los usuarios no tienen alias
    const request = await FetchJsonWithouToken(`${apiRoutes.USERS.BY_ALIAS}/${alias}`, "GET");
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    // onError();
  }
};

const loadPlanBranding = async (alias = "", { onSuccess, onError } = {}) => {
  try {
    const request = await FetchJsonWithouToken(`${apiRoutes.PLANS.BY_ALIAS}/${alias}`, "GET");
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    // onError();
  }
};

const getApp = async (params, { onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.APPS.GET_DATA_SUSCRIBER_EX}/${params.appId}`);
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    onError();
  }
};

const saveUserUpdate = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const method = "PUT";
  const path = `${apiRoutes.USERS.SAVE_USER_WELCOME}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const loadInstanceInfo = async ({ onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.INSTANCE.GET_DATA}`, "GET");
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    // onError();
  }
};

const resetPassword = async (params = {}, { onSuccess = {}, onError = {} }) => {
  const method = "PUT";
  const path = `${apiRoutes.USERS.RESET_PASSWORD}`;

  try {
    const request = await FetchJsonWithouToken(path, method, params);
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    onError();
  }
};

const addCheckinUser = async (params = {}, { onSuccess = {}, onError = {} }) => {
  const method = "PUT";
  const path = `${apiRoutes.USERS.RESET_PASSWORD}`;

  try {
    const request = await FetchJsonWithouToken(path, method, params);
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    onError();
  }
};

export {
  login,
  loadBranding,
  loadInstanceInfo,
  loginuser,
  loadUserBranding,
  getApp,
  saveUserUpdate,
  resetPassword,
  addCheckinUser,
  loadPlanBranding,
};
