const KometLogo = (props) => (
  <svg viewBox="0 0 301.6 378.74" {...props}>
    <defs>
      <style>{".prefix__cls-2{fill:#717175}.prefix__cls-3{opacity:.7}"}</style>
    </defs>
    <g id="prefix__Capa_2" data-name="Capa 2">
      <g id="prefix__Capa_1-2" data-name="Capa 1">
        <path
          className="prefix__cls-2"
          d="M265.46 159c-25.2-44.55-69.1-110.79-93.9-147.44-10.45-15.44-26.55-15.39-37 .11C110 48.24 66.5 114.16 40.85 158.57a144.42 144.42 0 00-23.4 79.06c-.07 77.83 60.55 141 135.4 141.07s135.62-63 135.7-140.85A144.13 144.13 0 00265.46 159z"
          opacity={0.65}
        />
        <g className="prefix__cls-3">
          <path
            className="prefix__cls-2"
            d="M204.15 125.56C160.6 95.1 90 52.29 50.51 28.81c-16.65-9.89-31.18-3.57-33.39 14.52C11.9 86 3.29 162.6.77 212.77c-2.83 26.14 2.05 54.13 15.67 80.65 36.2 70.44 120.48 104 188.22 74.81s93.34-109.9 57.12-180.34a158.55 158.55 0 00-57.63-62.33z"
          />
        </g>
        <g className="prefix__cls-3">
          <path
            className="prefix__cls-2"
            d="M97.45 125.56C141 95.1 211.57 52.29 251.09 28.81c16.64-9.89 31.19-3.57 33.4 14.52 5.2 42.69 13.82 119.27 16.33 169.44 2.84 26.14-2 54.13-15.66 80.65-36.21 70.44-120.48 104-188.22 74.81S3.62 258.33 39.82 187.89a158.48 158.48 0 0157.63-62.33z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default KometLogo;
