import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { withRouter } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

// COMPONENTS
import PlansCard from "../components/PlansCard";

import ActionBar from "../../shared/components/ActionBar";
import FiltersTable from "../components/FiltersCardEvent";
import SearchPlansForm from "../components/SearchPlansForm";

// HELPERS

import { handleDetailsPlan, handleArchivedPlan, handleActivePlan, handleClosePlan } from "../functions";

// REDUX
import { setPlan, setPlanRedux, reloadPlanRedux } from "../../../redux/plan";

// REQUEST

// HOOKS
import usePlans from "../hooks/usePlans";
import useModal from "../../shared/hooks/useModal";

// CONSTANTS
import { globals, packs } from "../../../constants/globals";

const typePack = packs.SUBSCRIPTION;

const Plans = ({
  PlanId = null,
  isEmbed = false,
  appId = null,
  packId = null,
  userId = null,
  packData = {},
  isMinimal = false,
  history,
  refresh = "null",
  view = globals.TABLE,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const planStoreRedux = useSelector((store) => store.plan);
  const sessionStoreRedux = useSelector((store) => store.session);
  const [reload, setReload] = useState(0);

  const [isOpenManagePlanEventModal, openManagePlanEventModal, closeManagePlanEventModal] = useModal();

  const { plans, filters, setFilters, pagination, setPagination, loading, refetch } = usePlans(
    appId,
    packId,
    userId,
    typePack
  );

  const commonProps = { filters, setFilters };
  const [page, setPage] = useState(globals.PAGE_MAIN);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [containerMode, setContainerMode] = useState(view);
  const [type, setType] = useState(typePack);
  const [details, setDetails] = useState(false);

  const [planId, setPlanId] = useState(null);
  const [isOpenManagePlanModal, openManagePlanModal, closeManagePlanModal] = useModal();

  const [initialValues] = useState({
    name: "",
    duration: "",
    category: "",
    maxLimit: 1,
    minLimit: 1,
    observation: "",
    active: true,
    tag: "",
    price: "",
    avatar: "",
    public: false,
    startdate: "2020/01/01",
    closuredate: "2020/01/01",
  });

  const [initialExecute, setInitialExecute] = useState(false);

  const handleEdit = (plan) => {
    setSelectedPlan(plan);
    setPlanId(plan._id);
    setType(plan.type);
    setPlanRedux(plan)(dispatch);
    openManagePlanModal();
  };

  const handleEditEvent = (plan) => {
    setSelectedPlan(plan);
    setPlanId(plan._id);
    setType(plan.type);
    setPlanRedux(plan)(dispatch);
    openManagePlanEventModal();
  };

  const handleClosePlanByGuide = (plan) => {
    setReload(Math.floor(Math.random() * 100000));
    if (plan._id) setPlanId(plan._id);
    else setPlanId(planId);

    handleClosePlan(
      { plan: { _id: plan._id || planId }, t, refetch, planInfo: plan },
      {
        onSuccess: () => reloadPlanRedux()(dispatch),
      }
    );
  };

  const handleGoto = ({ number, plan, id = null }) => {
    if (id) history.push(`/home/${number}/${id}`);
    else history.push(`/home/${number}/${plan._id}`);
  };

  // double click for more details
  const handleDetails = (plan) => {
    if (plan._id) {
      handleDetailsPlan(
        { planId: plan._id, t },
        {
          onSuccess: (response) => {
            setPlanId(plan._id);
            setPage(globals.PAGE_ONE);
            // setSubpage(numbers.TWO);
            setSelectedPlan(response.result);
            setPlanRedux(response.result)(dispatch);
          },
        }
      );
    }
  };

  const modeDetails = (plan) => {
    if (isEmbed) {
      handleGoto({ number: 22, plan });
    } else {
      console.log("181");
      handleDetails(plan);
    }
  };

  const handleResume = (plan) => {
    setSelectedPlan(plan);
    setDetails(true);
  };

  const resetFilters = () => {
    setFilters({ ...filters, status: "progress", kind: "event" });
    refetch();
  };

  useEffect(() => {
    resetFilters();
    setContainerMode(view || globals.TABLE);
    if (selectedPlan) {
      setPlan(selectedPlan)(dispatch);
      setPlanId(selectedPlan._id);
    }
  }, []);

  useEffect(() => {
    if (refresh && refresh !== "null") {
      refetch();
    }
  }, [refresh]);

  useEffect(() => {
    if (initialExecute) {
      refetch();
    }
  }, [filters]);

  useEffect(() => setTimeout(() => setInitialExecute(true), 3000), []);

  useEffect(() => {
    if (PlanId) handleDetails({ _id: PlanId });
  }, [PlanId]);

  useEffect(() => {}, [planStoreRedux.reloadPlan]);

  return (
    <>
      <ActionBar
        containerMode={containerMode}
        setContainerMode={setContainerMode}
        isMinimal={isMinimal}
        t={t}
        {...commonProps}
        options={{ actions: FiltersTable }}
      >
        <SearchPlansForm
          resetFilters={resetFilters}
          t={t}
          refetch={refetch}
          filters={filters}
          setFilters={setFilters}
        />
      </ActionBar>

      <PlansCard
        filters={filters}
        plans={plans}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
        refetch={refetch}
        initialValues={initialValues}
        setPage={setPage}
        setSelectedPlan={setSelectedPlan}
        selectedPlan={selectedPlan}
        handleDetails={handleDetails}
        handleEdit={handleEdit}
        details={!isEmbed ? modeDetails : () => {}}
        handleArchivedPlan={handleArchivedPlan}
        handleActivePlan={handleActivePlan}
        packData={packData}
        isEmbed={isEmbed}
        handleResume={handleResume}
        root={sessionStoreRedux.currentSession}
        isMinimal={isMinimal}
        handleClosePlanByGuide={handleClosePlanByGuide}
        handleEditEvent={handleEditEvent}
        t={t}
      />
    </>
  );
};

export default withRouter(Plans);
