/* eslint-disable no-unused-expressions */
import { useState, useEffect } from "react";
import { Drawer, Avatar, Col, Row, Tabs, Layout, Menu } from "antd";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import useApps from "../subscribersApps/hooks/useApps";
import useModal from "../shared/hooks/useModal";

import PlansContainer from "../subscribersPlans/containers/Plans";

import { numbers, uploadType, icons } from "../../constants/globals";

import logo from "../../assets/robot.png";

const { Header, Content } = Layout;

const Analist = ({ mainReload, currentPage }) => {
  const { t } = useTranslation();

  const sessionStoreRedux = useSelector((store) => store.session);
  const instanceStoreRedux = useSelector((store) => store.instance);

  const { apps, filters, setFilters, pagination, setPagination, loading, refetch } = useApps();

  const [user, setUser] = useState(false);
  const [appSelected, setAppSelected] = useState({});
  const [planId, setPlanId] = useState(null);

  const [reload, setReload] = useState(0);
  const [tab, setTab] = useState(currentPage);
  const [activeTab, setActiveTab] = useState(0);

  const handleLogout = () => {
    document.location = `/login/${instanceStoreRedux.currentInstance.alias}`;
  };

  const handleAdminView = () => {
    document.location = ` /home/29`;
  };

  const handleChangeTab = (val) => {
    if (val === numbers.ONE) refetch();
    setTab(val);
    setActiveTab(val);
  };

  useEffect(() => {
    refetch();
  }, [mainReload]);

  useEffect(() => {
    handleChangeTab(numbers.ONE);
  }, [mainReload]);

  return (
    <Layout className="layout analist-layout">
      <Header>
        <div className="logo" style={{ float: "left" }}>
          <img src={logo} width="45px" style={{ marginRight: 10, position: "relative", top: -5 }} alt="logo" />
        </div>
        <h1 style={{ float: "left", color: "#fff", fontSize: 32, marginRight: 100 }}>
          {instanceStoreRedux.currentInstance.alias}
        </h1>

        <Menu theme="dark" mode="horizontal" style={{ paddingTop: 7 }}>
          <Menu.Item key="1">
            <Avatar src={sessionStoreRedux.currentSession.avatar} size="medium" />
            {"  "}
            {sessionStoreRedux.currentSession.login}
          </Menu.Item>

          {sessionStoreRedux.currentSession.role === "SUPERADMIN" && (
            <Menu.Item icon={icons.apps} key="4" onClick={handleAdminView}>
              Vista Admin
            </Menu.Item>
          )}

          <Menu.Item icon={icons.close} key="2" onClick={handleLogout}>
            Cerrar Sesión
          </Menu.Item>
        </Menu>
      </Header>
      <Content style={{ background: "#EFF4FB" }}>
        <Layout className="layout">
          <Content className="container-interno">
            <PlansContainer isEmbed isMinimal isExternalUser />
          </Content>
        </Layout>
      </Content>
    </Layout>
  );
};
export default withRouter(Analist);
