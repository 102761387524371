import { useTranslation } from "react-i18next";

import PrimaryButton from "../../shared/components/PrimaryButton";
import useShortcut from "../../shared/hooks/useShortcut";

import { icons } from "../../../constants/globals";

const AddCategoryButton = ({ isOpenManagePlanModal, openManagePlanModal }) => {
  useShortcut(["n"], openManagePlanModal, [isOpenManagePlanModal]);
  const { t } = useTranslation();

  return (
    <>
      <PrimaryButton onClick={openManagePlanModal} type="primary" size="small" icon={icons.plus_circle}>
        {t("plan_new")}
      </PrimaryButton>
    </>
  );
};

export default AddCategoryButton;
