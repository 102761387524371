import { useState, useEffect } from "react";
import { DatePicker, Space } from "antd";

import moment from "moment";

const { RangePicker } = DatePicker;

const DateSelector = ({ setFilter, filter, setModeSearch, reload }) => {
  const [defaulValue, setDefaultValue] = useState([null, null]);
  const [loading, setLoading] = useState(false);

  const onChange = (res) => {
    if (res !== null) {
      setLoading(true);
      setModeSearch(null);
      setFilter({
        ...filter,
        desde: moment(res[0]).format("YYYY-MM-DD"),
        hasta: moment(res[1]).format("YYYY-MM-DD"),
        mode: null,
      });

      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } else {
      setLoading(true);
      setModeSearch(null);
      setFilter({
        ...filter,
        hasta: moment().format("YYYY-MM-DD"),
        desde: moment().subtract(7, "days").format("YYYY-MM-DD"),
        mode: null,
      });
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (!filter.desde) {
      setFilter({
        ...filter,
        hasta: moment().subtract(3, "days").format("YYYY-MM-DD"),
        desde: moment().add(3, "days").format("YYYY-MM-DD"),
      });
    }
  }, [filter]);

  useEffect(() => {
    if (reload === "ok") setDefaultValue([null, null]);
  }, [reload]);

  return (
    <>
      <div>Seleccione un rango de fecha:</div>
      <RangePicker
        loading={loading}
        onChange={onChange}
        value={[moment(filter.desde, "YYYY-MM-DD"), moment(filter.hasta, "YYYY-MM-DD")]}
        defaultValue={[moment(filter.desde, "YYYY-MM-DD"), moment(filter.hasta, "YYYY-MM-DD")]}
      />
    </>
  );
};

export default DateSelector;
