/* eslint-disable no-unused-expressions */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";

import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { handleActive, handleArchived, handleExportExcel, handleDetails } from "../functions/index";

// COMPONENTS
import ActionBar from "../../shared/components/ActionBar";
import UsersTable from "../components/usersTable";
import SearchUsersForm from "../components/SearchUsersForm";
import ActionsUsers from "../components/ActionsUsers";
import ManageUserModal from "../components/ManageUserModal";

import AddUserButton from "../components/AddUserButton";
import UserHeader from "../components/userHeader";
import UsersHeader from "../components/usersHeader";
import AppContainer from "../../subscribersApps/containers/Apps";
import TransContainer from "../../subscribersTrans/containers/Trans";
import TabMenu from "../components/Tabs";
import ManageBucketModal from "../../bucket/components/ManageBucketModal";
import BucketContainer from "../../bucket/containers/Buckets";
import EventsContainer from "../../events/containers/Event";

// REDUX
import { setUser, setUserRedux } from "../../../redux/user";

// REQUEST
import { getUser } from "../requests";

// HOOKS
import useUsers from "../hooks/useUsers";
import useModal from "../../shared/hooks/useModal";

// CONSTANTS
import { globals, numbers, packs } from "../../../constants/globals";

const Users = ({ match }) => {
  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);
  const instanceStoreRedux = useSelector((store) => store.instance);
  const { t } = useTranslation();

  const { users, filters, setFilters, pagination, setPagination, loading, refetch } = useUsers();
  const [isOpenManageBucketModal, openManageBucketModal, closeManageBucketModal] = useModal();

  const commonProps = { filters, setFilters };
  const [page, setPage] = useState(globals.PAGE_MAIN);
  const [pagesViewData, setPagesViewData] = useState([]);
  const [tracksWebData, setTracksWebData] = useState([]);

  const [refresh, setRefresh] = useState(true);

  const [subpage, setSubpage] = useState(numbers.SEVEN);
  const [selectedUser, setSelectedUser] = useState({});

  const [userId, setUserId] = useState(null);
  const [packType, setPackType] = useState(null);

  const [userType, setUserType] = useState("Gallery");

  const [isOpenManageUsersModal, openManageUsersModal, closeManageUsersModal] = useModal();

  const [isOpenGeneralModal, openGeneralModal, closeGeneralModal] = useModal();

  const [initialValues, setInitialValues] = useState({
    firstname: "",
    lastname: "",
    role: "USER",
    address: "",
    city: "",
    region: "",
    country: "",
    document: "",
    documentType: "",
    observation: "",
    birthdate: "1990/01/01",
    category: "cliente",
    type: "Normal",
    email: "",
    phone: "",
    reference: "",
    active: "",
    startdate: "1990/01/01",
    closuredate: "1990/01/01",
    refererId: null,
  });

  const [extra, setExtra] = useState({});

  useEffect(() => {
    setUser(selectedUser)(dispatch);

    if (selectedUser._id) {
      setUserId(selectedUser._id);
      setSubpage(numbers.SEVEN);
    }
  }, [selectedUser]);

  useEffect(() => {
    debounce(() => refetch(), 500);
  }, [filters]);

  const handleEdit = (user) => {
    setSelectedUser(user);
    setUserId(user._id);
    openManageUsersModal();
  };

  const handleGeneralModal = ({ user, type }) => {
    setSelectedUser(user);
    setUserId(user._id);
    setPackType(type);
    openGeneralModal();
  };

  const userDetails = (user) => {
    setPage(globals.PAGE_ONE);
    setSubpage(numbers.PAGE_ONE);
    handleDetails(
      { user, dispatch },
      {
        onSuccess: (result) => {
          setSelectedUser(result);
        },
      }
    );
  };

  const handleUploadImage = (typeUsr = "Gallery") => {
    setUserType(typeUsr);
    openManageBucketModal();
  };

  const resetFilters = () => {
    setFilters({ ...filters, all: "" });
    refetch();
  };

  useEffect(() => {
    if (match.params.id) {
      handleDetails({ _id: match.params.id });
    }
    setRefresh(true);
  }, []);

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <>
      {page === globals.PAGE_MAIN && (
        <div>
          <UsersHeader t={t} primaryAction={<AddUserButton refetch={refetch} initialValues={initialValues} t={t} />} />
          <div className="container-subpages">
            <ActionBar
              setFilters={setFilters}
              filters={filters}
              refetch={refetch}
              handleExportExcel={handleExportExcel}
              options={{ actions: ActionsUsers }}
            >
              <SearchUsersForm
                resetFilters={resetFilters}
                refetch={refetch}
                t={t}
                setFilters={setFilters}
                filters={filters}
              />
            </ActionBar>
            <UsersTable
              {...commonProps}
              users={users}
              pagination={pagination}
              setPagination={setPagination}
              loading={loading}
              refetch={refetch}
              initialValues={initialValues}
              setPage={setPage}
              setSelectedUser={setSelectedUser}
              selectedUser={selectedUser}
              handleDetails={userDetails}
              handleEdit={handleEdit}
              handleArchived={handleArchived}
              handleInactive={handleActive}
              details={userDetails}
              instanceStoreRedux={instanceStoreRedux}
              t={t}
            />
          </div>
        </div>
      )}
      {page === globals.PAGE_ONE && (
        <div>
          <UserHeader
            subpage={subpage}
            setPage={setPage}
            selectedUser={selectedUser}
            handleDetails={userDetails}
            handleEdit={handleEdit}
            openDeleteModal={handleArchived}
            handleGeneralModal={handleGeneralModal}
            setSubpage={setSubpage}
            extradata={extra}
            tracksWebData={tracksWebData}
            pagesViewData={pagesViewData}
            handleUploadImage={handleUploadImage}
            t={t}
          />

          <div className="container-subpages">
            <TabMenu
              setSubpage={setSubpage}
              t={t}
              numbers={numbers}
              hasAnalytics={instanceStoreRedux.currentInstance.hasAnalytics}
            />

            {subpage === numbers.SEVEN && (
              <div className="subcontainer-wrapper">
                <EventsContainer isEmbed="true" userId={userId} />
              </div>
            )}

            {subpage === numbers.FIVE && (
              <>
                <br />
                <AppContainer isEmbed="true" userId={selectedUser?._id || null} type={packs.TOUR} />
              </>
            )}

            {subpage === numbers.SIX && (
              <>
                <br />
                <TransContainer isEmbed="true" userId={selectedUser?._id || null} type="" />
              </>
            )}
            {subpage === numbers.EIGHT && (
              <>
                <br />
                {refresh && (
                  <BucketContainer viewType="table" isEmbed="true" root2Id={userId} entityId={userId} entity="root" />
                )}
              </>
            )}
          </div>
        </div>
      )}

      <ManageUserModal
        refetch={refetch}
        userId={userId}
        setUserId={setUserId}
        closeModal={closeManageUsersModal}
        openModal={isOpenManageUsersModal}
        initialValues={initialValues}
        users={users}
        selectedUser={selectedUser}
        setSelectedUserMain={setSelectedUser}
        setUser={setUser}
        getUser={getUser}
        t={t}
      />

      <ManageBucketModal
        entityProp="Root"
        typeProp={userType}
        entityId={userId}
        openModal={isOpenManageBucketModal}
        closeModal={closeManageBucketModal}
      />
    </>
  );
};

export default withRouter(Users);
