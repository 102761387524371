import { Table, Typography } from "antd";
import PropTypes from "prop-types";
import "./BaseTable.css";

const { Text } = Typography;

const BaseTable = ({
  children,
  dataSource,
  pagination,
  showPagination = true,
  setPagination,
  filters,
  setFilters,
  loading,
  refetch,
  details,
  scroll,
  rowSelection,
}) => {
  const handleTableChange = (paginationValues, _, sorter) => {
    setFilters({
      ...filters,
      sidx: sorter.columnKey || filters.sidx,
      sord: sorter.order === "ascend" ? "asc" : "desc",
    });
    setPagination({ ...pagination, current: paginationValues.current });

    if (typeof refetch === "function") {
      refetch();
    }
  };

  return (
    <>
      {loading || dataSource?.length > 0 ? (
        <Table
          rowSelection={rowSelection}
          scroll={scroll}
          rowClassName="row-table"
          showSorterTooltip={false}
          sortDirections={["ascend", "descend", "ascend"]}
          bordered
          size="small"
          rowKey={(record) => record._id}
          dataSource={dataSource}
          pagination={
            showPagination
              ? {
                  ...pagination,
                  size: "small",
                  showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} registros`,
                  position: ["bottomCenter"],
                  showSizeChanger: false,
                  pageSize: 200,
                }
              : false
          }
          onChange={handleTableChange}
          style={{ cursor: "pointer" }}
          loading={loading}
          onRow={(record, rowIndex) => ({
            onDoubleClick: (event) => details(record), // double click row
          })}
        >
          {children}
        </Table>
      ) : (
        <div className="no-results">
          <div className="content">
            <Text>No hay resultados</Text>
          </div>
        </div>
      )}
    </>
  );
};

BaseTable.propTypes = {
  children: PropTypes.node.isRequired,
  dataSource: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  details: PropTypes.func,
  scroll: PropTypes.object,
  rowSelection: PropTypes.object,
};

BaseTable.defaultProps = {
  loading: false,
  rowSelection: null,
  details: (data) => data,
  scroll: {},
};

export default BaseTable;
