import { Table, Tag, Space, Button, Typography } from "antd";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import BaseTable from "../../shared/components/BasicTable";
import {
  priceFormat,
  paymentFormat,
  icons,
  openInvoiceWeb,
  formaterModeTrans,
  tagTransStatus,
  isRoleAdmin,
} from "../../../constants/globals";

const { Link } = Typography;

const TransTable = ({
  selectedUser,
  setSelectedUser,
  setPage,
  history,
  transs,
  refetch,
  initialValues,
  handleDetails,
  handleEdit,
  userId,
  handleArchived,
  handleReverse,
  openDetailsTransModal,
  handleUpdateTrans,
  handleSendInvoice,
  t,
  isEmbed,
  inMinimal = false,
  ...restProps
}) => {
  const sessionStoreRedux = useSelector((store) => store.session);
  const instanceStoreRedux = useSelector((store) => store.instance);

  const { role } = sessionStoreRedux.currentSession;
  const { alias } = instanceStoreRedux.currentInstance;

  const statusFormat = (text) => {
    if (text === "open") {
      return (
        <Tag color="volcano" key={t("inwait")}>
          {t("inwait")}
        </Tag>
      );
    }

    if (text === "close") {
      return (
        <Tag color="green" key={t("completed")}>
          {t("completed")}
        </Tag>
      );
    }

    if (text === "inwait") {
      return (
        <Tag color="green" key={t("inwait")}>
          {t("inwait")}
        </Tag>
      );
    }
  };

  const isAboutApp = (transdata) => {
    if (transdata.appId) {
      return (
        <>
          {transdata?.packId?.name} {transdata?.planId?.name} <br />{" "}
          <b>
            <Tag color="gold" key={t("code")}>
              <span style={{ fontSize: 9 }}>
                {transdata?.appId?.code} / {transdata?.code}
              </span>
            </Tag>
          </b>
        </>
      );
    }
  };

  return (
    <>
      {!inMinimal && (
        <BaseTable {...restProps} dataSource={transs} refetch={refetch} scroll={{ y: 450 }}>
          <Table.Column
            align="center"
            title={t("actions")}
            dataIndex="actions"
            key="actions"
            width="140px"
            render={(_, data) => (
              <div>
                <Space size="small">
                  <Button
                    size="small"
                    className="btn-ant secondary primary"
                    onClick={() => {
                      handleDetails(data);
                    }}
                  >
                    {t("plan_openIt")}
                  </Button>
                  {data.isPending && (
                    <Button size="small" className="ant-btn secondary error" onClick={() => handleUpdateTrans(data)}>
                      Pagar
                    </Button>
                  )}
                </Space>
              </div>
            )}
          />

          <Table.Column
            title={t("user")}
            dataIndex="user"
            key="user"
            render={(text, row) => (
              <>
                <span style={{ textTransform: "uppercase" }}>
                  <b>
                    {row.firstname || ""} {row.lastname || ""}{" "}
                  </b>
                </span>
                <br />
                {!row.isPending ? (
                  <>
                    <Link onClick={() => window.open(openInvoiceWeb({ trans: row, alias }))}>
                      {t("trans_visualize_invoice")}
                    </Link>
                  </>
                ) : (
                  <Tag color="volcano" key={t("inwait")}>
                    {t("trans_pendingPayment")}
                  </Tag>
                )}
              </>
            )}
          />

          <Table.Column
            align="center"
            title={t("description")}
            dataIndex="_id"
            key="_id"
            width="200px"
            sorter
            render={(text, row) => <>{isAboutApp(row)}</>}
          />

          <Table.Column
            title={t("trans_paymentMode")}
            align="center"
            dataIndex="status"
            key="status"
            render={(text, row) => (
              <>
                <b>
                  <small>{formaterModeTrans(row.mode)}</small>
                </b>
                <br />
                {text === "close" && row.pending < 1 && (
                  <Tag color="green" key={t("completed")}>
                    {t("trans_completedPayment")}
                  </Tag>
                )}
                {text === "close" && row.pending > 1 && (
                  <Tag color="blue" key={t("completed")}>
                    {t("trans_partialPayment")}
                  </Tag>
                )}
                <b>{tagTransStatus(row.status)}</b>
              </>
            )}
          />

          <Table.Column
            align="right"
            title={t("total")}
            dataIndex="total"
            key="total"
            sorter
            width="130px"
            render={(text) => <Tag key={t("total")}>{priceFormat(text)}</Tag>}
          />

          <Table.Column
            align="right"
            title="Pagado"
            dataIndex="amount"
            key="amount"
            width="130px"
            sorter
            render={(text, row) => <>{priceFormat(text)}</>}
          />

          <Table.Column
            align="right"
            title={t("pending")}
            dataIndex="pending"
            key="pending"
            width="130px"
            render={(text, row) =>
              row.isPending ? (
                <Tag color="volcano" key={t("pending")}>
                  {priceFormat(text)}
                </Tag>
              ) : (
                <Tag key={t("pending")}>{priceFormat(text)}</Tag>
              )
            }
          />
          <Table.Column
            width="60px"
            title={t("actions")}
            dataIndex="actions"
            key="actions"
            align="center"
            render={(_, data) => (
              <Space size="small">
                {/*     {isRoleAdmin(role) && data.isPending && (
                  <a href="#!" onClick={() => handleEdit(data)} role="button" tabIndex={0}>
                    {icons.edit}
                  </a>
                )}
            */}
                {isRoleAdmin(role) && (
                  <a href="#!" onClick={() => handleArchived(data)} role="button" tabIndex={0}>
                    {icons.delete}
                  </a>
                )}
              </Space>
            )}
          />
        </BaseTable>
      )}

      {inMinimal && (
        <BaseTable {...restProps} dataSource={transs} refetch={refetch} scroll={{ x: 600 }}>
          <Table.Column
            fixed="left"
            width="50px"
            render={(value, row, key) => (
              <h5 style={{ textAlign: "center" }}>
                <b>{key + 1}</b>
              </h5>
            )}
          />

          <Table.Column
            title={t("user")}
            dataIndex="user"
            style={{ textTransform: "uppercase" }}
            key="user"
            sorter
            width="300px"
            render={(text, row) => (
              <span style={{ textTransform: "uppercase" }}>
                {row.firstname || ""} {row.lastname || ""}
                <br />
                {icons.dolar} {paymentFormat(row.mode)}
                <br />
                {statusFormat(row.status)}
                <div>
                  <Space size="small">
                    {row.isPending && row.status === "open" && (
                      <a href="#!" onClick={() => handleUpdateTrans(row)} role="button" tabIndex={0}>
                        Pagar
                      </a>
                    )}
                  </Space>
                </div>
              </span>
            )}
          />
        </BaseTable>
      )}
    </>
  );
};

export default withRouter(TransTable);
