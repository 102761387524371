import { useTranslation } from "react-i18next";
import { Modal } from "antd";

import BoxTableApps from "./boxTableApps";
import BoxTableTrans from "./boxTableTrans";
import BoxTableEvents from "./boxTableEvents";

const ManagePlanModal = ({ data, title, openModal, closeModal, App = true, Trans = false, Events = false }) => {
  const { t } = useTranslation();

  return (
    <Modal onOk={closeModal} title={title} visible={openModal} onCancel={closeModal} maskClosable="true" width="1000px">
      {App && <BoxTableApps data={data} />}
      {Trans && <BoxTableTrans data={data} />}
      {Events && <BoxTableEvents data={data} />}
    </Modal>
  );
};

export default ManagePlanModal;
