/* eslint-disable react/display-name */
import { useState, useEffect } from "react";

import { Table, Tag, Space } from "antd";
import { DownOutlined, FrownOutlined, SmileOutlined, MehOutlined, FrownFilled } from "@ant-design/icons";

const TreePack = ({ data, name = "Nombre" }) => {
  console.log("");

  const [columns, setColumns] = useState([
    {
      title: name,
      dataIndex: "name",
      key: "name",
      render: (text) => <>{text}</>,
    },
    {
      title: "",
      dataIndex: "value",
      width: "70px",
      key: "value",
      sorter: true,
      render: (text) => <b>{text}</b>,
    },
  ]);

  return <> {data[0] && <Table pagination={false} columns={columns} dataSource={data} />} </>;
};

export default TreePack;
