const MODE_PACK = "modePack";

export const getModePack = (defaultValue = "table") => {
  if (localStorage.getItem(MODE_PACK)) {
    return localStorage.getItem(MODE_PACK);
  }
  localStorage.setItem(MODE_PACK, defaultValue);
  return defaultValue;
};

export const setModePack = (mode) => localStorage.setItem(MODE_PACK, mode);
