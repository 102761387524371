import { setLocalStorage } from "../utils/localStorage";

const dataInicial = {
  request: false,
  currentGroup: {},
};

const CURRENT_GROUP = "CURRENT_GROUP";

export default function groupReducer(state = dataInicial, action) {
  switch (action.type) {
    case CURRENT_GROUP:
      return { ...state, currentGroup: action.payload };

    default:
      return { ...state };
  }
}

export const setGroup = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: CURRENT_GROUP,
    payload,
  });
};

export const setGroupRedux = (payload) => async (dispatch, getState) => {
  setLocalStorage(payload);
  dispatch({
    type: CURRENT_GROUP,
    payload,
  });
};
