import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import { confirmAlert } from "react-confirm-alert";

import { Form, Row, Col, Input, Spin, Button, Image } from "antd";
import { saveInstance, getInstance } from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";
import { setInstanceRedux } from "../../../redux/instance";
import RichTextComponent from "../../contentsPlansv2/components/RichTextComponent";

import "react-quill/dist/quill.snow.css"; // ES6

const defaultValues = {
  name: "",
  email: "",
  phone: "",
  facebook: "",
  instagram: "",
  youtube: "",
  active: true,
  color: "",
  cover: "",
  background: "",
  imageEmail: "",
  cancellationPolicy: "",
  paymentConditions: "",
  termsConditions: "",
};
const { TextArea } = Input;

const ManageInstanceForm = ({ setEntityId, handleUploadGallery }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const sessionStoreRedux = useSelector((store) => store.session);

  const [selectedInstance, setSelectedInstance] = useState(defaultValues);
  const [form] = Form.useForm();
  const [loaderForm, setLoaderForm] = useState(false);

  const [reload, setReload] = useState(0);

  const hanfleRefresh = () => {
    setLoaderForm(true);
    getInstance(true, {
      onSuccess: (response) => {
        setInstanceRedux(response.result)(dispatch);
        setEntityId(response.result._id);
        setSelectedInstance({
          ...response.result,
        });
        setLoaderForm(false);
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
  };

  useEffect(() => {
    hanfleRefresh();
  }, []);

  useEffect(() => {
    form.setFieldsValue(selectedInstance);
  }, [form, selectedInstance]);

  const onFinish = (values) => {
    const data = { ...selectedInstance, ...values };

    saveInstance(
      {
        ...data,
        rootId: sessionStoreRedux.currentSession_id,
      },
      {
        onSuccess: (response) => {
          hanfleRefresh();
          setInstanceRedux(response.result)(dispatch);
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleFinishConfirm = (values) => {
    confirmAlert({
      title: t("update"),
      message: `Esta seguro que desea continuar ?`,
      buttons: [
        {
          label: t("yes"),
          onClick: () => onFinish(values),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  /*
  const onValuesChange = debounce((values) => {
    setSelectedInstance({ ...selectedInstance, ...values });
  }, 500);
  */

  const onValuesChange = (values) => {
    setSelectedInstance({ ...selectedInstance, ...values });
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  const getHTMLTermsConditions = debounce((values) => {
    setSelectedInstance({ ...selectedInstance, termsConditions: values });
  }, 500);

  const getHTMLPaymentConditions = debounce((values) => {
    setSelectedInstance({ ...selectedInstance, paymentConditions: values });
  }, 500);

  const getHTMLCancellationPolicy = debounce((values) => {
    setSelectedInstance({ ...selectedInstance, cancellationPolicy: values });
  }, 500);

  return (
    <Form
      form={form}
      name="manage-category-form-modal"
      initialValues={defaultValues}
      className="manage-modal"
      onFinish={handleFinishConfirm}
      labelCol={{ span: 24 }}
      onValuesChange={onValuesChange}
    >
      <div className="layout-form-modal entity-550 entity-small">
        {loaderForm ? (
          <>
            <Spin tip={t("loading")} />
          </>
        ) : (
          <>
            <Row>
              <Col span={24}>
                <Form.Item>
                  <Button style={{ float: "right" }} type="primary" size="large" onClick={handleSubmit}>
                    {t("updateInformation")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={8}>
                <Form.Item
                  name="name"
                  tooltip="Firstname here pleaseio"
                  label={t("name")}
                  rules={[
                    {
                      required: true,
                      message: `Por favor ingrese el nombre`,
                    },
                  ]}
                >
                  <Input placeholder="" value={selectedInstance?.name} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="color" label={t("color")}>
                  <Input type="color" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="primary" label={t("primary")}>
                  <Input type="color" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="pin" label={t("pin")}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="email" label={t("email")}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="phone" label={t("phone")}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <h3>Website Information</h3>
            <hr />
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="address" label={t("address")}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="emailpublic" label={t("emailpublic")}>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={5}>
                <Form.Item name="phonepublic" label={t("phonepublic")}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item name="whatsapp" label={t("whatsapp")}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="facebook" label={t("facebook")}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="instagram" label={t("instagram")}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="avatar" label={t("urlAboutUsImage")}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="iframeMap" label={t("iframeMap")}>
                  <TextArea />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="video" label={t("video")}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <h3>Términos y Politicas</h3>
            <hr />
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="cancellationPolicy" label={t("instance_cancellationPolicy")}>
                  <RichTextComponent
                    setHTML={selectedInstance?.cancellationPolicy}
                    getHTML={getHTMLCancellationPolicy}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="termsConditions" label={t("instance_termsConditions")}>
                  <RichTextComponent setHTML={selectedInstance?.termsConditions} getHTML={getHTMLTermsConditions} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="paymentConditions" label={t("instance_paymentConditions")}>
                  <RichTextComponent setHTML={selectedInstance?.paymentConditions} getHTML={getHTMLPaymentConditions} />
                </Form.Item>
              </Col>
            </Row>
            <h3>Imagenes</h3>
            <hr />
            <Row gutter={16}>
              <Col span={6}>
                <Button style={{ float: "left" }} type="primary" onClick={() => handleUploadGallery("imageEmail")}>
                  Cargar Image Email
                </Button>
                <br />
                <Image
                  width={200}
                  src={
                    selectedInstance.imageEmail ||
                    "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  }
                />{" "}
                <br />
              </Col>
              <Col span={6}>
                <Button style={{ float: "left" }} type="primary" onClick={() => handleUploadGallery("logo")}>
                  Cargar logo
                </Button>
                <br />
                <Image
                  width={200}
                  src={
                    selectedInstance.logo ||
                    "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  }
                />{" "}
                <br />
              </Col>
              <Col span={6}>
                <Button style={{ float: "left" }} type="primary" onClick={() => handleUploadGallery("logowhite")}>
                  Cargar logo Fondo blanco
                </Button>
                <br />
                <Image
                  width={200}
                  src={
                    selectedInstance.logowhite ||
                    "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  }
                />{" "}
                <br />
              </Col>
              <Col span={6}>
                <Button style={{ float: "left" }} type="primary" onClick={() => handleUploadGallery("background")}>
                  Cargar Background
                </Button>{" "}
                <br />
                <Image
                  width={200}
                  src={
                    selectedInstance.background ||
                    "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  }
                />{" "}
                <br />
              </Col>
              <Col span={6}>
                <Button style={{ float: "left" }} type="primary" onClick={() => handleUploadGallery("cover")}>
                  Cargar Cover
                </Button>{" "}
                <br />
                <Image
                  width={200}
                  src={
                    selectedInstance.cover ||
                    "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  }
                />{" "}
                <br />
              </Col>
              <Col span={6}>
                <Button style={{ float: "left" }} type="primary" onClick={() => handleUploadGallery("cover2")}>
                  Cargar Cover 2
                </Button>{" "}
                <br />
                <Image
                  width={200}
                  src={
                    selectedInstance.cover2 ||
                    "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  }
                />{" "}
                <br />
              </Col>
              <Col span={6}>
                <Button style={{ float: "left" }} type="primary" onClick={() => handleUploadGallery("cover3")}>
                  Cargar Cover 3
                </Button>{" "}
                <br />
                <Image
                  width={200}
                  src={
                    selectedInstance.cover3 ||
                    "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  }
                />{" "}
                <br />
              </Col>
            </Row>
          </>
        )}
      </div>
    </Form>
  );
};

ManageInstanceForm.defaultProps = {
  setInstanceId: () => {},
};

export default ManageInstanceForm;
