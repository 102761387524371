import { useState, useEffect } from "react";
import { Form, Row, Col, Input, Checkbox, DatePicker, Space, Button, Select, InputNumber } from "antd";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { confirmAlert } from "react-confirm-alert";

import { withRouter } from "react-router-dom";
import { icons, durationFormat } from "../../../constants/globals";

import { saveAppByUser } from "../requests";

import SelectPacks from "../../subscribers/components/SelectPacks";
import SelectPlans from "../../subscribersPlans/components/SelectPlans";
import SelectUsers from "../../subscribersUsers/components/SelectUsers";

const moment = require("moment");
const Swal = require("sweetalert2");

const defaultValues = {
  firstname: "",
  lastname: "",
  document: "",
  documentType: "",
  paymentMode: "none",
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  paymentReference: "",
  phone: "+57",
  alergies: "",
  bodylesson: "",
  address: "",
  profession: "",
  medium: "",
  city: "",
  description: "",
  packId: null,
  planId: null,
};

const CreateApp = ({ selectedApp, onClose, refetch }) => {
  const { t } = useTranslation();
  const instanceStoreRedux = useSelector((store) => store.instance);

  const [setting, setSetting] = useState({});
  const [user, setUser] = useState({});
  const [app, setApp] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [form] = Form.useForm();

  const [userId, setUserId] = useState(null);
  const [packId, setPackId] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [refererId, setRefererId] = useState(null);

  const [plan, setPlan] = useState({});
  const [reload, setReload] = useState(0);
  const [userExist, setUserExist] = useState(false);
  const [userCheckin, setUserCheckin] = useState(true);
  const [userVaccine, setUserVaccine] = useState(true);
  const [userGift, setUserGift] = useState(false);
  const [userPrivacy, setUserPrivacy] = useState(true);
  const [packInfo, setPackInfo] = useState({});
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onValuesChange = (values) => {
    setData({ ...data, ...values });
  };

  const onChangeUserExist = (e) => {
    setUserExist(e.target.checked);
    if (!e.target.checked) setUserId(null);
  };

  const onChangeCheckin = (e) => {
    setUserCheckin(e.target.checked);
  };

  const onChangeVaccine = (e) => {
    setUserVaccine(e.target.checked);
  };

  const onChangeGift = (e) => {
    setUserGift(e.target.checked);
  };

  const onChangePrivacy = (e) => {
    setUserPrivacy(e.target.checked);
  };

  const handleStartdate = (values) => {
    console.log(plan);
    setData({
      ...data,
      closureDate: moment(values).add(plan.duration, durationFormat(plan.durationMode)),
      startDate: values,
      startdate: moment(values).format("YYYY-MM-DD"),
      closuredate: moment(values).add(plan.duration, durationFormat(plan.durationMode)).format("YYYY-MM-DD"),
    });
  };

  const onFinish = () => {
    setData({ ...data, app: selectedApp, active: true, userExist, userCheckin, userVaccine, userGift, userPrivacy });

    if (!userExist) {
      if (
        data.firstname === undefined ||
        data.firstname === "" ||
        data.lastname === "" ||
        data.lastname === undefined
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Por favor validé los Nombres y Apellidos",
        });
        return;
      }
      if (data.phone === undefined || data.email === undefined || data.email === "" || data.phone === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Por favor validé Email y Teléfono",
        });
        return;
      }

      if (
        data.documentType === undefined ||
        data.document === undefined ||
        data.document === "" ||
        data.documentType === ""
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Por favor validé el Documento",
        });
        return;
      }
    }

    if (data.packId === undefined || data.planId === undefined) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor validé Paquete y Plan",
      });
      return;
    }

    if (data.startDate === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor validé la fecha de inicio",
      });
      return;
    }

    if (!userPrivacy) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor acepte los términos y condiciones de uso!",
      });
      return;
    }

    if (!userVaccine) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor valide el esquema de vacunación!",
      });
      return;
    }

    setLoadingSubmit(true);
    saveAppByUser(
      {
        ...data,
        app: selectedApp,
        active: true,
        isUserExist: userExist,
        isUserCheckin: userCheckin,
        isVaccine: userVaccine,
        isWelcomeGift: userGift,
        isPrivacyPolicy: userPrivacy,
      },
      {
        onSuccess: () => {
          Swal.fire({
            icon: "success",
            title: "Operación Exitosa",
          });

          setData(defaultValues);
          refetch();
          onClose();
        },
        onError: () => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        },
        onFinally: () => {
          setLoadingSubmit(false);
        },
      }
    );
  };

  const handleSubmit = () => {
    confirmAlert({
      title: "Confirmación",
      message: `Esta seguro que desea continuar?`,
      buttons: [
        {
          label: "Continuar",
          onClick: () => form.submit(),
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const handlePack = (value) => {
    setPackId(value);
    setPlanId(null);
    setData({
      ...data,
      packId: value,
      startDate: moment(selectedApp?.startDate, "YYYY/MM/DD"),
      closureDate: null,
    });
  };

  const handleReferer = (value) => {
    setRefererId(value);
    setData({
      ...data,
      refererId: value,
    });
  };

  const handlePlan = (value) => {
    setPlanId(value);
    setData({
      ...data,
      planId: value,
      startDate: null,
      closureDate: null,
    });
  };

  const handleUser = (value) => {
    setUserId(value);
    setData({
      ...data,
      userId: value,
      startDate: moment(selectedApp?.startDate, "YYYY/MM/DD"),
      closureDate: moment(plan?.startDate).add(plan.duration, durationFormat(plan.durationMode)),
    });
  };

  useEffect(() => {
    setPlan({});
    setData({
      ...data,
      packId,
    });
  }, [packId]);

  useEffect(() => {
    setData({
      ...data,
      plan,
      startDate: null,
      closureDate: null,
    });
  }, [plan]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  useEffect(() => {
    console.log(packInfo);
  }, [packInfo]);

  useEffect(() => {
    setReload(Math.floor(Math.random() * 100000));
    setSetting(instanceStoreRedux.currentInstance);
    setApp(selectedApp);
    setLoading(false);
  }, []);

  // selectedApp?.planId?.duration

  return (
    <>
      {!loading && (
        <>
          <Row>
            <Col span={22} offset={1}>
              <Form
                form={form}
                name="manage-category-form-modal "
                className="manage-modal minimal-form"
                onFinish={onFinish}
                labelCol={{ span: 24 }}
                onValuesChange={onValuesChange}
                initialValues={defaultValues}
              >
                <Row gutter={15} className="box-warning">
                  <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Checkbox onChange={onChangeUserExist}>
                      <b>{icons.guest} El contacto ya se encuentra registrado.</b>
                    </Checkbox>
                  </Col>
                </Row>
                {!userExist && (
                  <Row gutter={15} className="box-primary" style={{ paddingBottom: 10 }}>
                    <Col span={24} xs={24} sm={24} md={5} lg={5} xl={5}>
                      <Form.Item
                        name="firstname"
                        label={t("contact_firstname")}
                        rules={[{ required: true, message: "Es obligatorio el nombre" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={5} lg={5} xl={5}>
                      <Form.Item name="lastname" label={t("contact_lastname")}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item
                        name="email"
                        label={t("contact_email")}
                        rules={[{ required: true, message: "Es obligatorio el correo electronico!" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item name="documentType" label={t("contact_documentType")}>
                        <Select placeholder={t("contact_documentType")}>
                          <Select.Option value="Pasaporte">{t("document_passport")}</Select.Option>
                          <Select.Option value="Tarjeta de Identidad">Tarjeta de Identidad</Select.Option>
                          <Select.Option value="Cédula de Ciudadanía">Cédula de Ciudadanía</Select.Option>
                          <Select.Option value="Cédula de Extranjería">Cédula de Extranjería</Select.Option>
                          <Select.Option value="Permiso Especial (PEP)">Permiso Especial (PEP)</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item name="document" label={t("contact_document")}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item name="phone" label={t("contact_phone")} extra="Ejemplo: +573187366637">
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item name="birthday" label={t("contact_birthday")} extra="Ejemplo: 2000/12/25">
                        <DatePicker format="YYYY/MM/DD" />
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item name="genre" label={<>{t("contact_genre")}</>}>
                        <Select className="select-form-modal">
                          <Select.Option value="Femenino">{t("genre_female")}</Select.Option>
                          <Select.Option value="Masculino">{t("genre_male")}</Select.Option>
                          <Select.Option value="No Especifica">{t("genre_none")}</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item name="address" label={t("contact_address")}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item help="Amistad, Red Social, Tv, Radio" name="medium" label="Como nos conoció?">
                        <Select className="select-form-modal">
                          <Select.Option value="Instagram">Instagram</Select.Option>
                          <Select.Option value="Facebook">Facebook</Select.Option>
                          <Select.Option value="Blog">Blog</Select.Option>
                          <Select.Option value="Amistad">Amistad</Select.Option>
                          <Select.Option value="Google">Google</Select.Option>
                          <Select.Option value="Youtube">Youtube</Select.Option>
                          <Select.Option value="Fachada">Fachada</Select.Option>
                          <Select.Option value="Folleto Publicitario">Folleto Publicitario</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                {userExist && (
                  <Row gutter={15} className="box-info" style={{ paddingBottom: 10 }}>
                    <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item name="packId3" tooltip="" label="Seleccione un contacto">
                        <SelectUsers
                          reload={reload}
                          currentValue={userId}
                          defaultValue={userId}
                          returnData={handleUser}
                          typePack="SUBSCRIPTION"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Row gutter={16} className="box-info">
                  <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="packId2" tooltip="" label="Seleccione un programa">
                      <SelectPacks
                        reload={reload}
                        currentValue={packId}
                        defaultValue={packId}
                        returnData={handlePack}
                        typePack="SUBSCRIPTION"
                        setPackInfo={setPackInfo}
                        kind="subscription"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="planId" tooltip="" label="Seleccione un plan">
                      {packId && packId?.length > 5 && (
                        <SelectPlans
                          handleSelected={setPlan}
                          reload={reload}
                          currentValue={planId}
                          defaultValue={planId}
                          returnData={handlePlan}
                          type="SUBSCRIPTION"
                          packId={packId}
                        />
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item name="startDate" label={<>{icons.calendar} &nbsp; Desde</>}>
                      <DatePicker format="YYYY/MM/DD" onChange={handleStartdate} />
                    </Form.Item>
                  </Col>

                  <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item name="closureDate" label={<>{icons.calendar} &nbsp; Hasta</>}>
                      <DatePicker className="color-red" format="YYYY/MM/DD" disabled />
                    </Form.Item>
                  </Col>

                  {/*   <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item name="refererId" tooltip="" label={t("app_refererBy")}>
                      <SelectUsers
                        reload={reload}
                        currentValue={refererId}
                        defaultValue={refererId}
                        returnData={handleReferer}
                        typePack="SUBSCRIPTION"
                      />
                    </Form.Item>
                      </Col> */}

                  <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item
                      name="paymentMode"
                      label={
                        <>
                          {icons.dolar}&nbsp;{t("payment_method")}
                        </>
                      }
                    >
                      <Select className="select-form-modal">
                        <Select.Option value="none">{t("payment_none")}</Select.Option>
                        <Select.Option value="cash">{t("payment_cash")}</Select.Option>
                        <Select.Option value="debitcard">{t("payment_debitcard")}</Select.Option>
                        <Select.Option value="creditcard">{t("payment_creditcard")}</Select.Option>
                        <Select.Option value="transfer">{t("payment_transfer")}</Select.Option>
                        <Select.Option value="deposit">{t("payment_deposit")}</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  {data.paymentMode === "deposit" && (
                    <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item name="amount" label={t("price")}>
                        <InputNumber
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={24} xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item name="paymentReference" label="Número de referencia">
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={24} xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item name="description" label="Alguna observación importante">
                      <Input value={app.description} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} className="box-warning">
                  <Col span={24} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Checkbox checked={userCheckin} onChange={onChangeCheckin}>
                      <b>Registrar asistencía</b> automaticamente despues del registro
                    </Checkbox>
                  </Col>
                  <Col span={24} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Checkbox checked={userGift} onChange={onChangeGift}>
                      Ha recibido el <b>Kit de bienvenida</b>
                    </Checkbox>
                  </Col>
                  <Col span={24} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Checkbox checked={userVaccine} onChange={onChangeVaccine}>
                      Sí,completó su esquema de vacunación.
                    </Checkbox>
                  </Col>
                  <Col span={24} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Checkbox checked={userPrivacy} onChange={onChangePrivacy}>
                      Acepta los <b>Terminos y Condiciones</b>
                    </Checkbox>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24} style={{ paddingTop: 30 }}>
                    <hr />
                    <Space>
                      <Button
                        className="ant-btn secondary primary large"
                        loading={loadingSubmit}
                        key="1"
                        onClick={handleSubmit}
                        size="large"
                        style={{ float: "left" }}
                      >
                        Registrar
                      </Button>
                      <Button
                        key="2"
                        onClick={onClose}
                        className="ant-btn secondary error large"
                        size="large"
                        style={{ float: "left" }}
                      >
                        Cerrar
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default withRouter(CreateApp);
