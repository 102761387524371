import { Table, Space, Tooltip, Button } from "antd";
import { CheckCircleTwoTone, EditOutlined, DeleteOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import BaseTable from "../../shared/components/BasicTable";
import { constantsColor } from "../../../constants/colors";

const SuppliersTable = ({
  selectedSupplier,
  setSelectedSupplier,
  setPage,
  history,
  suppliers,
  refetch,
  initialValues,
  handleDetails,
  handleEdit,
  supplierId,
  handleArchived,
  t,
  handleInactive,
  ...restProps
}) => (
  <>
    <BaseTable {...restProps} dataSource={suppliers} refetch={refetch} scroll={{ y: 450 }}>
      <Table.Column
        fixed="left"
        width="150px"
        align="center"
        title={t("actions")}
        dataIndex="actions"
        key="actions"
        render={(_, data) => (
          <div className="">
            <Space size="small">
              <Button
                size="small"
                className="btn-ant secondary primary"
                onClick={() => {
                  handleDetails(data);
                }}
              >
                Abrir
              </Button>
              <a href="#!" onClick={() => handleDetails(data)} role="button" tabIndex={0}>
                <Tooltip placement="top" title={`${t("clickTo")} ${t("moreDetails")}`}>
                  <userOutlined />
                </Tooltip>
              </a>
              <a href="#!" onClick={() => handleEdit(data)} role="button" tabIndex={0}>
                <Tooltip placement="top" title={`${t("clickTo")} ${t("edit")}`}>
                  <EditOutlined />{" "}
                </Tooltip>
              </a>
              <a href="#!" onClick={() => handleArchived({ supplier: data, t, refetch })} role="button" tabIndex={0}>
                <Tooltip placement="top" title={`${t("clickTo")} ${t("delete")}`}>
                  <DeleteOutlined />
                </Tooltip>
              </a>
              <a href="#!" onClick={() => handleInactive({ supplier: data, t, refetch })} role="button" tabIndex={0}>
                {data.active ? (
                  <Tooltip placement="top" title={`${t("clickToChange")} ${t("supplierOnHold")}`}>
                    <CheckCircleTwoTone twoToneColor={constantsColor.success} />
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title={`${t("clickToChange")} ${t("supplierCompleted")}`}>
                    <ClockCircleOutlined className="danger" style={{ color: constantsColor.warning }} />
                  </Tooltip>
                )}
              </a>
            </Space>
          </div>
        )}
      />

      <Table.Column title={t("fullname")} dataIndex="fullname" key="fullname" sorter fixed="left" width="300px" />

      <Table.Column
        title={t("documentType")}
        dataIndex="documentType"
        key="documentType"
        width="150px"
        render={(text, row) => <>{row.documentType}</>}
        responsive={["lg"]}
      />

      <Table.Column
        title={t("document")}
        dataIndex="document"
        key="document"
        width="120px"
        render={(text, row) => <>{row.document}</>}
        responsive={["lg"]}
      />

      <Table.Column
        title={t("email")}
        dataIndex="email"
        key="email"
        sorter
        render={(text, row) => <>{row.email}</>}
        responsive={["lg"]}
      />
      <Table.Column
        title={t("phone")}
        dataIndex="phone"
        key="phone"
        render={(text, row) => <>{row.phone}</>}
        responsive={["lg"]}
      />
    </BaseTable>
  </>
);

export default withRouter(SuppliersTable);
