import { PageHeader, Button, Space, Descriptions, Row, Col, Checkbox } from "antd";
import { DeleteOutlined, EditOutlined, AmazonOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";
import { globals, priceFormat, dateFormat4, icons } from "../../../constants/globals";

const CheckboxGroup = Checkbox.Group;

const Header = ({
  setPage,
  handleEdit,
  handleArchived,
  t,
  handleNewPlanSubscription,
  handleEditEvent,
  handleEditSubscription,
}) => {
  const { currentPack } = useSelector((store) => store.pack);

  const renderContent = (column = 4) => (
    <>
      {currentPack?.kind === "event" && (
        <div className="container-subpages">
          <Descriptions size="small" column={column}>
            {!currentPack?.isRecurrent && (
              <Descriptions.Item label={t("startdate")}>{dateFormat4(currentPack?.startdate)}</Descriptions.Item>
            )}
            <Descriptions.Item label={t("departureTime")}>{currentPack?.departureTime}</Descriptions.Item>
            <Descriptions.Item label={t("duration")}>
              {currentPack?.duration} {currentPack?.durationMode}
            </Descriptions.Item>
            <Descriptions.Item label={t("maximum")}>{currentPack?.maxLimit}</Descriptions.Item>
            <Descriptions.Item label={t("price")}>{priceFormat(currentPack?.price)}</Descriptions.Item>
            <Descriptions.Item label="Precio al público">{priceFormat(currentPack?.externalPrice)}</Descriptions.Item>
            <Descriptions.Item label="Redirección?">{currentPack?.redirectTo}</Descriptions.Item>
            {String(currentPack?.whatsapRedirect).length > 5 && (
              <Descriptions.Item label="Whatsapp">{currentPack?.whatsappRedirect}</Descriptions.Item>
            )}

            <Descriptions.Item label="Guide">
              {currentPack?.guideId?.firstname} {currentPack?.guideId?.lastname || ""}
            </Descriptions.Item>

            <Descriptions.Item label={t("createdAt")}>{dateFormat4(currentPack?.createdAt)}</Descriptions.Item>
          </Descriptions>
          <Descriptions size="small" column={1}>
            <Descriptions.Item label={t("excerpt")}>{currentPack?.excerpt}</Descriptions.Item>
          </Descriptions>
          <Descriptions size="small" column={1}>
            <Descriptions.Item label="Dias de actividad">
              {currentPack?.isRecurrent && (
                <CheckboxGroup
                  style={{ width: "100%", position: "relative", top: "-7px" }}
                  defaultValue={currentPack?.availableDays}
                >
                  <Row gutter={15}>
                    <Col span={3}>
                      <Checkbox value="0" disabled>
                        Sunday
                      </Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value="1" disabled>
                        Monday
                      </Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value="2" disabled>
                        Tuesday
                      </Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value="3" disabled>
                        Wednesday
                      </Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value="4" disabled>
                        Thursday
                      </Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value="5" disabled>
                        Friday
                      </Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value="6" disabled>
                        Saturday
                      </Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions size="small" column={1}>
            <Descriptions.Item label="Categorización">
              <CheckboxGroup style={{ width: "100%" }} defaultValue={currentPack.categorization}>
                <Row gutter={15}>
                  <Col span={4}>
                    <Checkbox value="Naturaleza" disabled>
                      Naturaleza
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Cultural" disabled>
                      Cultural
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Extremo" disabled>
                      Extremo
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Romantico" disabled>
                      Romantico
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Walking" disabled>
                      Walking
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Espiritual" disabled>
                      Espiritual
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Fitness" disabled>
                      Fitness
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Academico" disabled>
                      Academico
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Artistico" disabled>
                      Artistico
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Salud" disabled>
                      Salud
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Belleza" disabled>
                      Belleza
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Ciencia" disabled>
                      Ciencia
                    </Checkbox>
                  </Col>
                </Row>
              </CheckboxGroup>
            </Descriptions.Item>
          </Descriptions>
        </div>
      )}

      {currentPack?.kind === "subscription" && (
        <div className="container-subpages">
          <Descriptions size="small" column={2}>
            {currentPack.kind === "subscription" && (
              <Descriptions.Item>
                <Space>
                  <Button
                    className="ant-btn secondary primary"
                    key="2"
                    size="small"
                    onClick={() => handleNewPlanSubscription(currentPack)}
                  >
                    {icons.plus_circle} Nuevo plan
                  </Button>
                </Space>
              </Descriptions.Item>
            )}

            <Descriptions.Item label={t("createdAt")}>{dateFormat4(currentPack?.createdAt)}</Descriptions.Item>
          </Descriptions>
          <Descriptions size="small" column={1}>
            <Descriptions.Item label={t("excerpt")}>{currentPack?.excerpt}</Descriptions.Item>
          </Descriptions>

          <Descriptions size="small" column={1}>
            <Descriptions.Item label="Categorización">
              <CheckboxGroup style={{ width: "100%" }} defaultValue={currentPack.categorization}>
                <Row gutter={15}>
                  <Col span={4}>
                    <Checkbox value="Naturaleza" disabled>
                      Naturaleza
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Cultural" disabled>
                      Cultural
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Extremo" disabled>
                      Extremo
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Romantico" disabled>
                      Romantico
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Walking" disabled>
                      Walking
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Espiritual" disabled>
                      Espiritual
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Fitness" disabled>
                      Fitness
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Academico" disabled>
                      Academico
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Artistico" disabled>
                      Artistico
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Salud" disabled>
                      Salud
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Belleza" disabled>
                      Belleza
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Checkbox value="Ciencia" disabled>
                      Ciencia
                    </Checkbox>
                  </Col>
                </Row>
              </CheckboxGroup>
            </Descriptions.Item>
          </Descriptions>
        </div>
      )}
    </>
  );
  const extraContent = <></>;

  const Content = ({ children, extra }) => (
    <div className="content">
      <div className="main">{children}</div>
      <div className="extra">{extra}</div>
    </div>
  );

  return (
    <>
      {currentPack._id ? (
        <PageHeader
          className="site-page-header-responsive"
          onBack={() => setPage(globals.PAGE_MAIN)}
          title={<h2 className="special-font bold big-font">{currentPack.name}</h2>}
          subTitle={currentPack?.clasification}
          extra={
            <>
              <Button
                className="ant-btn secondary primary large"
                key="3"
                size="large"
                onClick={() => handleEdit(currentPack)}
              >
                {icons.setting}
              </Button>
              <Button
                className="ant-btn secondary error large"
                key="4"
                size="large"
                onClick={() => handleArchived(currentPack)}
              >
                <DeleteOutlined />
              </Button>
            </>
          }
        >
          <Content extra={extraContent}>{renderContent()}</Content>
        </PageHeader>
      ) : (
        t("loading")
      )}
    </>
  );
};

export default Header;
