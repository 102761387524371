/* eslint-disable no-unused-expressions */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";

import { useTranslation } from "react-i18next";

import { withRouter } from "react-router-dom";
import { handleActive, handleArchived, handleExportExcelServices, handleDetails } from "../functions/index";

// COMPONENTS
import ActionBar from "../../shared/components/ActionBar";
import ServiceTable from "../components/ServicesTable";
import SearchServicesForm from "../components/SearchServiceForm";
import ActionsServices from "../components/ActionsService";
import ManageUserModal from "../components/ManageServiceModal";

import AddServiceButton from "../components/AddServiceButton";
import ServiceHeader from "../components/ServiceHeader";
import ServicesHeader from "../components/ServicesHeader";
import AppContainer from "../../subscribersApps/containers/Apps";
import TransContainer from "../../subscribersTrans/containers/Trans";
import TabMenu from "../components/Tabs";
import ManageBucketModal from "../../bucket/components/ManageBucketModal";
import BucketContainer from "../../bucket/containers/Buckets";
import EventsContainer from "../../events/containers/Event";

// HELPERS

// REDUX
import { setService } from "../../../redux/service";

// REQUEST
import { getService } from "../requests";

// HOOKS
import useServices from "../hooks/useService";
import useModal from "../../shared/hooks/useModal";

// CONSTANTS
import { globals, numbers, packs, icons } from "../../../constants/globals";

const Services = ({ match }) => {
  const dispatch = useDispatch();
  const instanceStoreRedux = useSelector((store) => store.instance);
  const { t } = useTranslation();

  const { services, filters, setFilters, pagination, setPagination, loading, refetch } = useServices();
  const [isOpenManageBucketModal, openManageBucketModal, closeManageBucketModal] = useModal();

  const commonProps = { filters, setFilters };
  const [page, setPage] = useState(globals.PAGE_MAIN);

  const [refresh, setRefresh] = useState(true);

  const [subpage, setSubpage] = useState(numbers.FIVE);
  const [selectedService, setSelectedService] = useState({});

  const [serviceId, setUserId] = useState(null);
  const [packType, setPackType] = useState(null);

  const [serviceType, setUserType] = useState("Gallery");

  const [isOpenManageServicesModal, openManageServicesModal, closeManageServicesModal] = useModal();

  const [isOpenGeneralModal, openGeneralModal, closeGeneralModal] = useModal();

  const [initialValues, setInitialValues] = useState({
    observation: "",
    description: "",
    category: "",
    type: "Normal",
    active: "",
    supplier: null,
  });

  const [extra, setExtra] = useState({});

  const handleEdit = (service) => {
    setSelectedService(service);
    setUserId(service._id);
    openManageServicesModal();
  };

  const handleGeneralModal = ({ service, type }) => {
    setSelectedService(service);
    setUserId(service._id);
    setPackType(type);
    openGeneralModal();
  };

  const serviceDetails = (service) => {
    setUserId(service._id);
    setPage(globals.PAGE_ONE);
    handleDetails(
      { service },
      {
        onSuccess: (result) => {
          console.log(result);
          setService(result)(dispatch);
          setSelectedService(result);
        },
      }
    );
  };

  const handleExportExcel = () => {
    handleExportExcelServices(services);
  };

  const handleUploadImage = (typeUsr = "Gallery") => {
    setUserType(typeUsr);
    openManageBucketModal();
  };

  const resetFilters = () => {
    setFilters({ ...filters, all: "" });
    refetch();
  };

  useEffect(() => {
    if (match.params.id) {
      handleDetails({ _id: match.params.id });
    }
    setRefresh(true);
  }, []);

  useEffect(() => {
    refetch();
  }, [filters]);

  useEffect(() => {
    setService(selectedService)(dispatch);

    if (selectedService._id) {
      setUserId(selectedService._id);
      setSubpage(numbers.FIVE);
    }
  }, [selectedService]);

  useEffect(() => {
    debounce(() => refetch(), 500);
  }, [filters]);

  return (
    <>
      {page === globals.PAGE_MAIN && (
        <div>
          <ServicesHeader
            title={<h4 className="bold special-font big-font">{icons.guest} Servicios</h4>}
            primaryAction={<AddServiceButton refetch={refetch} initialValues={initialValues} t={t} />}
          />
          <div className="container-subpages">
            <ActionBar
              setFilters={setFilters}
              filters={filters}
              refetch={refetch}
              handleExportExcel={handleExportExcel}
              options={{ actions: ActionsServices }}
            >
              <SearchServicesForm
                resetFilters={resetFilters}
                refetch={refetch}
                t={t}
                setFilters={setFilters}
                filters={filters}
              />
            </ActionBar>
            <ServiceTable
              {...commonProps}
              services={services}
              pagination={pagination}
              setPagination={setPagination}
              loading={loading}
              refetch={refetch}
              initialValues={initialValues}
              setPage={setPage}
              setSelectedService={setSelectedService}
              selectedService={selectedService}
              handleDetails={serviceDetails}
              handleEdit={handleEdit}
              handleArchived={handleArchived}
              handleInactive={handleActive}
              details={serviceDetails}
              t={t}
            />
          </div>
        </div>
      )}
      {page === globals.PAGE_ONE && (
        <div>
          <ServiceHeader
            subpage={subpage}
            setPage={setPage}
            selectedService={selectedService}
            handleDetails={serviceDetails}
            handleEdit={handleEdit}
            openDeleteModal={handleArchived}
            handleGeneralModal={handleGeneralModal}
            setSubpage={setSubpage}
            extradata={extra}
            handleUploadImage={handleUploadImage}
            t={t}
          />

          <div className="container-subpages">
            <TabMenu
              setSubpage={setSubpage}
              t={t}
              numbers={numbers}
              hasAnalytics={instanceStoreRedux.currentInstance.hasAnalytics}
            />

            {subpage === numbers.SEVEN && (
              <div className="subcontainer-wrapper">
                {serviceId && <EventsContainer isEmbed="true" serviceId={serviceId} />}
              </div>
            )}

            {subpage === numbers.FIVE && (
              <>
                <br />
                {serviceId && <AppContainer isEmbed="true" serviceId={serviceId || null} type={packs.SUBSCRIPTION} />}
              </>
            )}

            {subpage === numbers.SIX && (
              <>
                <br />
                {serviceId && <TransContainer isEmbed="true" serviceId={serviceId || null} type={packs.SUBSCRIPTION} />}
              </>
            )}
            {subpage === numbers.EIGHT && (
              <>
                <br />
                {refresh && serviceId && (
                  <BucketContainer
                    viewType="table"
                    isEmbed="true"
                    serviceId={serviceId}
                    entityId={serviceId}
                    entity="service"
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}

      <ManageUserModal
        refetch={refetch}
        serviceId={serviceId}
        setUserId={setUserId}
        closeModal={closeManageServicesModal}
        openModal={isOpenManageServicesModal}
        initialValues={initialValues}
        services={services}
        selectedService={selectedService}
        setSelectedServiceMain={setSelectedService}
        setService={setService}
        getUser={getService}
        page={page}
        t={t}
      />

      <ManageBucketModal
        entityProp="Service"
        typeProp={serviceType}
        entityId={serviceId}
        openModal={isOpenManageBucketModal}
        closeModal={closeManageBucketModal}
      />
    </>
  );
};

export default withRouter(Services);
