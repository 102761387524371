import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { confirmAlert } from "react-confirm-alert";
import ActionBar from "../../shared/components/ActionBar";
import PacksTable from "../components/PacksTable";
import SearchPacksForm from "../components/SearchPacksForm";
import ActionsUsers from "../components/ActionsPacks";
import ManagePackModal from "../components/ManagePackModal";

import AddUserButton from "../components/AddPackButton";
import PackHeader from "../components/PackHeader";
import PacksHeader from "../components/PacksHeader";
import PacksCard from "../components/PacksCard";
import TabMenu from "../components/Tabs";
import SelectCategoryPack from "../components/SelectCategoryPack";

import ManageBucketModal from "../../bucket/components/ManageBucketModal";

// HELPERS
import showGlobalNotification from "../../../helpers/showGlobalNotification";

// REQUEST
import {
  getPack,
  syncAIPlansByPack,
  archivedPack,
  activePack,
  duplicatePack,
  reloadPack,
  airtableSyncPack,
} from "../requests";

import { refreshJsonPlanToS3 } from "../../contentsPlansv2/requests";

// REDUX
import { setPackRedux } from "../../../redux/pack";

// HOOKS
import useModal from "../../shared/hooks/useModal";
import usePacks from "../hooks/usePacks";

// CONSTANTS
import { globals, numbers } from "../../../constants/globals";

// CONTAINER
import BucketContainer from "../../bucket/containers/Buckets";
import PlansContainer from "../../contentsPlansv2/containers/Plans";

import { setModePack, getModePack } from "../../../helpers/defaultOptions";

const Packs = ({ isEmbed = false, history, PackId = null, pageNum = null }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { packs, filters, setFilters, pagination, setPagination, loading, refetch } = usePacks();

  const commonProps = { filters, setFilters };
  const [page, setPage] = useState(globals.PAGE_MAIN);
  const [subpage, setSubpage] = useState(numbers.ONE);
  const [selectedPack, setSelectedPack] = useState({});
  const [packId, setPackId] = useState(null);
  const [newPlan, setNewPlan] = useState(false);
  const [isOpenManagePacksModal, openManagePacksModal, closeManagePacksModal] = useModal();

  const [reloadApp] = useState(0);

  const [isOpenManagePlansModal, openNewPlanModal] = useModal();
  const [containerMode, setContainerMode] = useState("table");
  const [isOpenManageBucketModal, openManageBucketModal, closeManageBucketModal] = useModal();
  const [goback, setGoback] = useState(false);
  const sessionStoreRedux = useSelector((store) => store.session);
  const instanceStoreRedux = useSelector((store) => store.instance);

  useEffect(() => {
    setPackRedux(selectedPack)(dispatch);
  }, [selectedPack]);
  useEffect(() => {
    setContainerMode(getModePack());
  }, []);

  const refreshEntity = (_id, { onSuccess = {}, onError = {} }) => {
    getPack(_id, {
      onSuccess: (response) => {
        setPackRedux(response.result)(dispatch);
        setSelectedPack(response.result);
        if (response.result) onSuccess(response.result);
        else onError();
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
  };

  const handleNew = () => {
    setPackId(null);
    openManagePacksModal();
  };

  const handleEdit = (pack) => {
    setSelectedPack(pack);
    setPackId(pack._id);
    openManagePacksModal();
  };

  const handleUploadJsonPlanToS3 = () => {
    refreshJsonPlanToS3(
      {},
      {
        onSuccess: () => {
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleDetails = (pack) => {
    setPage(globals.PAGE_ONE);
    setSubpage(numbers.THREE);
    setPackId(pack._id);
    refreshEntity(pack._id, {
      onSuccess: () => {},
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
    });
  };

  const handleDuplicate = (pack) => {
    if (pack._id) {
      setSelectedPack(pack);
      refreshEntity(pack._id, {
        onSuccess: (result) => {
          confirmAlert({
            title: `${t("duplicate")} ${pack.name}`,
            message: `Esta seguro que desea Duplicar este registro?`,
            buttons: [
              {
                label: t("yes"),
                onClick: () => {
                  let nameTemp = "";

                  if (String(pack.name).substr(-2) === "10") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 2)}11`;
                  } else if (String(pack.name).substr(-2) === "11") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 2)}12`;
                  } else if (String(pack.name).substr(-2) === "12") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 2)}13`;
                  } else if (String(pack.name).substr(-2) === "13") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 2)}14`;
                  } else if (String(pack.name).substr(-2) === "14") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 2)}15`;
                  } else if (String(pack.name).substr(-2) === "15") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 2)}16`;
                  } else if (String(pack.name).substr(-2) === "16") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 2)}17`;
                  } else if (String(pack.name).substr(-2) === "17") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 2)}18`;
                  } else if (String(pack.name).substr(-2) === "18") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 2)}19`;
                  } else if (String(pack.name).substr(-1) === "1") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 1)}2`;
                  } else if (String(pack.name).substr(-1) === "2") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 1)}3`;
                  } else if (String(pack.name).substr(-1) === "3") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 1)}4`;
                  } else if (String(pack.name).substr(-1) === "4") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 1)}5`;
                  } else if (String(pack.name).substr(-1) === "5") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 1)}6`;
                  } else if (String(pack.name).substr(-1) === "6") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 1)}7`;
                  } else if (String(pack.name).substr(-1) === "7") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 1)}8`;
                  } else if (String(pack.name).substr(-1) === "8") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 1)}9`;
                  } else if (String(pack.name).substr(-1) === "9") {
                    nameTemp = `${String(pack.name).substr(0, parseInt(String(pack.name).length) - 1)}10`;
                  } else {
                    nameTemp = `${pack.name}-duplicate-${Math.floor(Math.random() * 100)}`;
                  }

                  duplicatePack(
                    {
                      ...result,
                      packId: result._id,
                      plansDuplicate: true,
                      name: nameTemp,
                    },
                    {
                      onSuccess: () => {
                        showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
                      },
                      onError: () => {
                        showGlobalNotification("error", t("error"), t("error"));
                      },
                      onFinally: () => {
                        refetch();
                      },
                    }
                  );
                },
              },
              {
                label: t("no"),
                onClick: () => {},
              },
            ],
          });
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  };

  const handleArchivedConfirm = (pack) => {
    archivedPack(
      {
        pack,
      },
      {
        onSuccess: () => {
          refetch();
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleArchived = (pack) => {
    setPackId(pack._id);
    confirmAlert({
      title: `Eliminar ${pack.name}`,
      message: `Esta seguro que desea eliminar la sección: ${pack.name}?`,
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleArchivedConfirm(pack),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleActiveConfirm = (pack) => {
    activePack(
      {
        pack,
      },
      {
        onSuccess: () => {
          refetch();
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleAirtableSyncConfirm = (pack) => {
    airtableSyncPack(
      {
        pack,
      },
      {
        onSuccess: () => {
          refetch();
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleSyncPlans = (pack) => {
    setPackId(pack._id);
    syncAIPlansByPack(
      {
        pack,
      },
      {
        onSuccess: () => {
          refetch();
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleReload = (pack) => {
    reloadPack(
      {
        pack,
      },
      {
        onSuccess: () => {
          refetch();
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => {},
      }
    );
  };

  const handleActive = (pack) => {
    setPackId(pack._id);
    confirmAlert({
      title: pack.active ? "Desactivar esta sección" : "Activar la sección",
      message: "Esta seguro que desea continuar?",
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleActiveConfirm(pack),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleAirTable = (pack) => {
    setPackId(pack._id);
    confirmAlert({
      title: pack.active ? "Desincronizar la seccion" : "Sincronizar la seccion",
      message: "Esta seguro que desea continuar?",
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleAirtableSyncConfirm(pack),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleNewPlan = (pack) => {
    setPackId(pack._id);
    setPackRedux(pack);
    setNewPlan(true);
  };

  const handleNewLinkModal = () => {
    openManageBucketModal();
  };

  useEffect(() => {
    // debounce(() => refetch(), 500);
    refetch();
  }, [filters]);

  const handleGoto = ({ number, entity }) => {
    history.push(`/home/${number}/${entity.id}`);
  };

  const modeDetails = (entity) => {
    if (isEmbed) {
      handleGoto({ number: pageNum, entity });
    } else {
      handleDetails(entity);
    }
  };

  const resetFilters = () => {
    setFilters({ ...filters, all: "" });
    refetch();
  };

  const handleCategory = (res) => console.log(res);
  const handleCategoryInfo = (res) => {
    if (String(res.name).length > 4) setFilters({ ...filters, category: res.name });
  };

  useEffect(() => {
    if (PackId) {
      handleDetails({ _id: PackId });
      setGoback(true);
    }
  }, []);

  return (
    <>
      {page === globals.PAGE_MAIN && (
        <div>
          <PacksHeader
            t={t}
            primaryAction={
              <>
                <SelectCategoryPack
                  setPackInfo={handleCategoryInfo}
                  returnData={handleCategory}
                  title="Seleccione Categoria"
                />
                <AddUserButton
                  refetch={refetch}
                  handleUploadJsonPlanToS3={handleUploadJsonPlanToS3}
                  isOpenManagePacksModal={isOpenManagePacksModal}
                  handleNew={handleNew}
                  setContainerMode={setContainerMode}
                  containerMode={containerMode}
                  setModePack={setModePack}
                  t={t}
                />
              </>
            }
          />
          <section className="body-page">
            <div className="container-subpages">
              <ActionBar t={t} {...commonProps} options={{ actions: ActionsUsers }}>
                <SearchPacksForm
                  resetFilters={resetFilters}
                  t={t}
                  refetch={refetch}
                  filters={filters}
                  setFilters={setFilters}
                />
              </ActionBar>
              {containerMode === "table" && (
                <PacksTable
                  {...commonProps}
                  packs={packs}
                  pagination={pagination}
                  setPagination={setPagination}
                  loading={loading}
                  refetch={refetch}
                  setPage={setPage}
                  setSelectedPack={setSelectedPack}
                  selectedPack={selectedPack}
                  handleDetails={handleDetails}
                  handleEdit={handleEdit}
                  details={modeDetails}
                  handleArchived={handleArchived}
                  handleActive={handleActive}
                  handleDuplicate={handleDuplicate}
                  handleReload={handleReload}
                  handleAirTable={handleAirTable}
                  handleSyncPlans={handleSyncPlans}
                  t={t}
                />
              )}
              {containerMode === "cards" && (
                <PacksCard
                  {...commonProps}
                  packs={packs}
                  pagination={pagination}
                  setPagination={setPagination}
                  loading={loading}
                  refetch={refetch}
                  // initialValues={initialValues}
                  setPage={setPage}
                  setSelectedPack={setSelectedPack}
                  selectedBucket={selectedPack}
                  handleDetails={handleDetails}
                  handleEdit={handleEdit}
                  details={modeDetails}
                  handleArchived={handleArchived}
                />
              )}
            </div>
          </section>
        </div>
      )}
      {page === globals.PAGE_ONE && (
        <>
          <PackHeader
            setPage={setPage}
            selectedPack={selectedPack}
            handleDetails={handleDetails}
            handleEdit={handleEdit}
            setSubpage={setSubpage}
            handleArchived={handleArchived}
            openNewPlanModal={openNewPlanModal}
            handleNewPlan={handleNewPlan}
            handleNewLinkModal={handleNewLinkModal}
            t={t}
          />

          <div className="container-subpages">
            <TabMenu setSubpage={setSubpage} t={t} numbers={numbers} />

            {subpage === numbers.TWO && (
              <div className="subcontainer-wrapper">
                <BucketContainer isEmbed="true" packId={packId} />
              </div>
            )}
            {subpage === numbers.THREE && (
              <>
                <div className="subcontainer-wrapper">
                  <PlansContainer packData={selectedPack} isEmbed="true" packId={packId} reload={reloadApp} />
                </div>
              </>
            )}
          </div>
        </>
      )}
      <ManagePackModal
        t={t}
        masterInstance={globals.MASTER_INSTANCE_ID}
        currentInstance={instanceStoreRedux.currentInstance._id}
        refetch={refetch}
        packId={packId}
        setPackId={setPackId}
        closeModal={closeManagePacksModal}
        openModal={isOpenManagePacksModal}
      />
      <ManageBucketModal
        entityProp="Pack"
        typeProp="Gallery"
        entityId={packId}
        openModal={isOpenManageBucketModal}
        closeModal={closeManageBucketModal}
      />
    </>
  );
};

export default withRouter(Packs);
