import { Card, List, Spin } from "antd";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../metrics.css";

const BucketCards = ({ items, title, limit = "10", consultar = null, styleClass = "blue" }) => {
  const { t } = useTranslation();

  const handleClick = (value) => {
    if (consultar !== null) {
      consultar(value);
    }
  };

  return (
    <Card title={title} bordered style={{ width: "100%" }} className={styleClass}>
      {items.length > 0 ? (
        <list>
          {items.map((xx, index) => {
            if (index < limit) {
              return (
                <List.Item key={index}>
                  <List.Item.Meta title={xx.name} onClick={() => handleClick(xx.name)} />
                  <div>{xx.count}</div>
                </List.Item>
              );
            }
            return "";
          })}
        </list>
      ) : (
        <Spin />
      )}
    </Card>
  );
};

export default withRouter(BucketCards);
