import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";
import { constantsApp } from "../constants";
import { jsonFormatConsolidateTrans } from "../../../constants/globals";

const FiltersTable = ({ setFilters, filters, isEmbed, isMinimal = false }) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState("");
  const [type, setType] = useState("inbound");
  const { currentPlan } = useSelector((store) => store.plan);
  const { currentApp } = useSelector((store) => store.app);

  const handleSelected = (statusSelected) => {
    setStatus(statusSelected);
    setFilters({ ...filters, status: statusSelected });
  };

  const handleTypeSelected = (optionSelected) => {
    setType(optionSelected);
    setFilters({ ...filters, type: optionSelected });
  };

  return (
    <>
      {!isMinimal && (
        <>
          <span className="fieldset-consolidate-filters">
            <span className="label-consolidate-button-filter"> {t("general")}</span>
            <Space>
              <Button
                className="tag-button-table"
                size="small"
                type={type === "inbound" ? "primary" : "default"}
                onClick={() => handleTypeSelected("inbound")}
              >
                {jsonFormatConsolidateTrans({ data: currentPlan, isEmbed })
                  ? jsonFormatConsolidateTrans({ data: currentPlan, isEmbed, appData: currentApp }).inbound_trans
                  : ""}{" "}
                {t("inbound")}
              </Button>
              <Button
                className="tag-button-table"
                size="small"
                type={type === "outbound" ? "primary" : "default"}
                onClick={() => handleTypeSelected("outbound")}
              >
                {jsonFormatConsolidateTrans({ data: currentPlan, isEmbed })
                  ? jsonFormatConsolidateTrans({ data: currentPlan, isEmbed, appData: currentApp }).outbound_trans
                  : ""}{" "}
                {t("outbound")}
              </Button>
            </Space>
          </span>

          {!(currentApp && currentApp?._id) && (
            <span className="fieldset-consolidate-filters">
              <span className="label-consolidate-button-filter"> {t("statusTrans")}</span>
              <Space>
                <Button
                  className="tag-button-table"
                  size="small"
                  type={status === "" ? "primary" : "default"}
                  onClick={() => handleSelected("")}
                >
                  Todas
                </Button>
                <Button
                  className="tag-button-table"
                  size="small"
                  type={status === constantsApp.STATUS.CLOSED ? "primary" : "default"}
                  onClick={() => handleSelected(constantsApp.STATUS.COMPLETED)}
                >
                  {jsonFormatConsolidateTrans({ data: currentPlan, isEmbed })
                    ? jsonFormatConsolidateTrans({ data: currentPlan, isEmbed }).status_trans_Completada
                    : "0"}{" "}
                  {constantsApp.STATUS_ALIAS.COMPLETED}
                </Button>

                <Button
                  className="tag-button-table"
                  size="small"
                  type={status === constantsApp.STATUS.PENDING ? "primary" : "default"}
                  onClick={() => handleSelected(constantsApp.STATUS.PENDING)}
                >
                  {jsonFormatConsolidateTrans({ data: currentPlan, isEmbed })
                    ? jsonFormatConsolidateTrans({ data: currentPlan, isEmbed }).status_trans_Cancelada
                    : "0"}{" "}
                  {constantsApp.STATUS_ALIAS.INWAIT}
                </Button>
              </Space>
            </span>
          )}
        </>
      )}
    </>
  );
};

export default FiltersTable;
