import { customFetch, FetchJson } from "../../helpers/fetch";
import { apiRoutes } from "../../constants/routes";
import { Excel } from "../../helpers/excelExport";
import { dateFormat4, excelFilename, birthdate } from "../../constants/globals";

const moment = require("moment");

const saveUser = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { userId } = props;
  const method = userId ? "PUT" : "POST";
  const path = userId ? `${apiRoutes.USERS.SAVE_DATA}/${userId}` : `${apiRoutes.USERS.SAVE_DATA}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const saveUserExtra = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { userId, userExtraId } = props;
  const method = userExtraId ? "PUT" : "POST";
  const path = userExtraId ? `${apiRoutes.USERS.SAVE_EXTRA}/${userExtraId}` : `${apiRoutes.USERS.SAVE_EXTRA}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const getUser = async (userId = 0, { onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.USERS.GET_DATA}/${userId}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const getUserExtra = async (userId = 0, { onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.USERS.GET_USERSEXTRA}/${userId}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const deleteUser = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  try {
    const request = await customFetch(apiRoutes.CATEGORIES.DELETE_CATEGORY, {
      method: "POST",
      body: new URLSearchParams(params),
    });

    const response = await request.json();

    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const archivedUser = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id } = params.user;
  const method = "PUT";
  const path = `${apiRoutes.USERS.ARCHIVED}/${_id}`;

  try {
    const request = await FetchJson(path, method, { archived: true });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const activeUser = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const { _id, active } = params.user;
  const method = "PUT";
  const path = `${apiRoutes.USERS.ACTIVE}/${_id}`;

  try {
    const request = await FetchJson(path, method, { active: !active });

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const checkinUser = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const method = "PUT";
  const path = `${apiRoutes.USERS.CHECKIN}`;

  try {
    const request = await FetchJson(path, method, params);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const guestUser = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const method = "PUT";
  const path = `${apiRoutes.USERS.GUEST}`;

  try {
    const request = await FetchJson(path, method, params);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const updateUser = async (params = {}, { onSuccess, onError, onFinally } = {}) => {
  const method = "PUT";
  const path = `${apiRoutes.USERS.UPDATE_BY_ANALIST}`;

  try {
    const request = await FetchJson(path, method, params);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const saveAssist = async (props = {}, { onSuccess, onError, onFinally } = {}) => {
  const { userId } = props;
  const method = "POST";
  const path = `${apiRoutes.ASSIST.SAVE}/${userId}`;

  try {
    const request = await FetchJson(path, method, props);

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  } finally {
    onFinally();
  }
};

const validateSessionUser = async ({ onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.USERS.VALIDATE}`, "GET");
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    // onError();
  }
};

const validateSessionRoot = async ({ onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.ROOT.VALIDATE}`, "GET");
    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    // onError();
  }
};

const getUsers = async ({ onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.USERS.ALL}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const searchUsers = async (text, { onSuccess, onError } = {}) => {
  try {
    const request = await FetchJson(`${apiRoutes.USERS.SEARCH}?text=${text}`, "GET");

    const response = await request.json();
    onSuccess(response);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }

    onError();
  }
};

const exportExcelUsers = (data) => {
  getUsers({
    onSuccess: (result) => {
      let row = "";
      let title = "";
      const filename = `Listado de Contactos ${excelFilename(moment.now())}`;
      // eslint-disable-next-line prefer-const
      let contador = 0;

      const head = `
      <tr>
      <td colspan="5"><h3>${title}</h3></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td style="width:250px" ><b>Nombre Completo</b></td>
      <td><b>Email</b></td>
      <td><b>Tipo de Documento</b></td>
      <td><b>Documento</b></td>
      <td><b>Teléfono</b></td>
      <td><b>Recomendado por</b></td>
      <td><b>Fecha de Nacimiento</b></td>
      <td><b>Edad</b></td>
      <td><b>Direcció de Habitación</b></td>
      <td><b>Interes</b></td>
      <td><b>Fecha Registro</b></td>
      </tr>`;

      result.result.forEach((item) => {
        title = `${parseInt(contador) + 1} Contactos`;
        row = `${row} <tr>
        <td>${item.firstname} ${item.lastname || ""}</td>
        <td>${item.email}</td>
        <td>${item.documentType || ""}</td>
        <td style="text-align:left">${item.document || ""}</td>
        <td>${item.phone || ""}</td>
        <td>${item.medium || ""}</td>
        <td>${item.birthdate || ""}</td>
        <td>${birthdate(item.birthdate) || ""}</td>
        <td>${item.address || ""}</td>
        <td>${item.interests[0] || ""} ${item.interests[1] || ""}</td>
        <td>${dateFormat4(item.creartedAt)}</td>
       </tr>`;
      });
      Excel(`${head}${row}`, filename);
    },
    onError: (err) => console.log(err),
  });

  /*
  

  const head = `
  <tr>
  <td colspan="5"><h3>${title}</h3></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  </tr>
  <tr>
  <td style="width:250px" ><b>Nombre Completo</b></td>
  <td><b>Email</b></td>
  <td><b>Tipo de Documento</b></td>
  <td><b>Documento</b></td>
  <td><b>Observación</b></td>
  <td><b>Teléfono</b></td>
  <td><b>Fecha registro</b></td>
  <td><b>Modalidad de pago</b></td>
  <td><b>Fecha de nacimiento</b></td>
  <td><b>Activo?</b></td>
  <td><b>Interes</b></td>
  <td><b>Rol</b></td>
  <td><b>Referido por</b></td>
  </tr>`;

  Excel(`${head}${row}`, filename);
  */
};

export {
  getUser,
  getUsers,
  saveUser,
  deleteUser,
  archivedUser,
  activeUser,
  saveAssist,
  validateSessionUser,
  validateSessionRoot,
  searchUsers,
  exportExcelUsers,
  checkinUser,
  guestUser,
  saveUserExtra,
  updateUser,
  getUserExtra,
};
