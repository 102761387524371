import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import { confirmAlert } from "react-confirm-alert";

import { useTranslation } from "react-i18next";

import { Form, Row, Col, Input, Spin, Button, message } from "antd";
import { savePlan } from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";
import RichTextComponent from "./RichTextComponent";

import { setPlanRedux } from "../../../redux/plan";
import { packs } from "../../../constants/globals";

const moment = require("moment");
const Swal = require("sweetalert2");

const defaultValues = {
  name: "",
  departurePlace: "",
  minLimit: 1,
  maxLimit: 1,
  category: "",
  duration: "",
  active: true,
  public: false,
  price: 0,
  packId: null,
  type: packs.CONTENT,
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
};
const { TextArea } = Input;

const ManagePlanForm = ({ planId, selectedPlan, setSelectedPlan }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);

  const [form] = Form.useForm();
  const [loaderForm, setLoaderForm] = useState(false);
  const [isCluuf, setCluuf] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fix, setFix] = useState(false);
  const [html_, setHtml_] = useState("");

  useEffect(() => {
    setPlanRedux(selectedPlan)(dispatch);
  }, []);

  useEffect(() => {
    form.setFieldsValue(selectedPlan);
  }, [form, selectedPlan]);

  const getHTML = debounce((values) => {
    console.log(values);
    setSelectedPlan({ ...selectedPlan, body: values });
  }, 500);

  const onFinish = (values) => {
    const data = { ...selectedPlan, ...values };
    if (!data.packId) {
      message.error("This is an error message");
    } else {
      setLoading(true);
      savePlan(
        {
          ...data,
          planId,
          express: true,
          rootId: sessionStoreRedux.currentSession_id,
        },
        {
          onSuccess: (response) => {
            setLoading(false);
            setPlanRedux(response.result)(dispatch);
            setTimeout(() => window.close(), 5000);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Los cambios fueron efectuados",
              text: "Esta ventana se cerrara pronto!",
              showConfirmButton: false,
              timer: 3000,
            });
          },
          onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
          onFinally: () => setTimeout(() => window.close(), 6000),
        }
      );
    }
  };

  const onValuesChange = debounce((values) => {
    setSelectedPlan({ ...selectedPlan, ...values });
  }, 500);

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  const handleFinishConfirm = (values) => {
    confirmAlert({
      title: "Confirmación de cambios",
      message: `Esta seguro que desea continuar?`,
      buttons: [
        {
          label: t("yes"),
          onClick: () => onFinish(values),
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <Form
      form={form}
      name="manage-category-form-modal"
      initialValues={defaultValues}
      className={isCluuf ? "manage-modal external-form extern-form-cluuf" : "manage-modal"}
      onFinish={handleFinishConfirm}
      labelCol={{ span: 24 }}
      onValuesChange={onValuesChange}
    >
      <div className="layout-form-modal entity-600 entity-small">
        {loaderForm ? (
          <>
            <Spin tip={t("loading")} />
          </>
        ) : (
          <>
            <Row gutter={16}>
              <Col span={24}>
                <p style={{ textAlign: "center", padding: "20px 4px 10px" }}>
                  <img
                    alt="bot"
                    src="https://cluuftracks.herokuapp.com/bot2.gif"
                    width="140px"
                    style={{ marginTop: "10px" }}
                  />

                  <h2 style={{ paddingTop: 20 }}>CLUUF CONTENT MANAGER</h2>
                </p>
              </Col>
            </Row>

            {selectedPlan.category === "TEXT" && (
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="body" label={t("body")}>
                    <TextArea style={{ fontSize: 24 }} showCount="true" onResize="false" value={selectedPlan?.body} />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {selectedPlan.category === "LIST" && (
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="body" label={t("body")}>
                    <TextArea style={{ fontSize: 24 }} showCount="true" onResize="false" value={selectedPlan?.body} />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {selectedPlan.category === "HTML" && (
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="body">
                    <RichTextComponent setHTML={selectedPlan?.body} getHTML={getHTML} />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row>
              <Col span={24}>
                <Form.Item>
                  <Button
                    loading={loading}
                    className="ant-btn secondary primary large"
                    size="large"
                    style={{ float: "right" }}
                    type="primary"
                    onClick={handleSubmit}
                  >
                    Guardar cambios
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Form>
  );
};

ManagePlanForm.defaultProps = {
  // () => {},
};

export default ManagePlanForm;
