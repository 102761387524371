import { Pie } from "@ant-design/charts";

const AppsPieStatus = ({ data, legend = false }) => {
  const config = {
    appendPadding: 0,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    legend,
    label: {
      type: "spider",
      labelHeight: 28,
      content: "{name}\n{percentage}",
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
  };
  return <Pie {...config} />;
};

export default AppsPieStatus;
