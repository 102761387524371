import { PictureOutlined, FontSizeOutlined, FormOutlined } from "@ant-design/icons";

const typePack = Object.freeze({
  IMAGE: "IMAGE",
  FORM: "FORM",
  TEXT: "TEXT",
  HTML: "HTML",
  SERVICE: "SERVICE",
  INVENTORY: "INVENTORY",
  SUBSCRIPTION: "SUBSCRIPTION",
  EVENT: "EVENT",
  TOUR: "TOUR",
});

export const formatTypePack = (type) => {
  switch (type) {
    case typePack.HTML:
      return <span className="type-pack image">Html</span>;
    case typePack.IMAGE:
      return (
        <span className="type-pack image">
          <PictureOutlined />
        </span>
      );
    case typePack.TEXT:
      return (
        <span className="type-pack text">
          <FontSizeOutlined />
        </span>
      );
    case typePack.FORM:
      return (
        <span className="type-pack form">
          <FormOutlined />
        </span>
      );
    case typePack.SERVICE:
      return (
        <span className="type-pack form">
          <FormOutlined />
        </span>
      );
    case typePack.INVENTORY:
      return (
        <span className="type-pack form">
          <FormOutlined />
        </span>
      );
    case typePack.SUBSCRIPTION:
      return (
        <span className="type-pack form">
          <FormOutlined />
        </span>
      );
    case typePack.EVENT:
      return (
        <span className="type-pack form">
          <FormOutlined />
        </span>
      );
    case typePack.TOUR:
      return (
        <span className="type-pack form">
          <FormOutlined />
        </span>
      );
    default:
      return "common.print.bol.product.default.description";
  }
};

export const formatTypePackGlobal = (type) => {
  switch (type) {
    case typePack.HTML:
      return <span className="type-pack-global">Html</span>;
    case typePack.IMAGE:
      return (
        <span className="type-pack-global">
          <PictureOutlined />
        </span>
      );
    case typePack.TEXT:
      return (
        <span className="type-pack-global">
          <FontSizeOutlined />
        </span>
      );
    case typePack.FORM:
      return (
        <span className="type-pack-global">
          <FormOutlined />
        </span>
      );
    default:
      return "";
  }
};
