import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Form, Row, Spin, Divider, Descriptions, Tag, Typography, Space, Button } from "antd";
import { getApp } from "../../subscribersApps/requests";

import showGlobalNotification from "../../../helpers/showGlobalNotification";
import BaseModal from "../../shared/components/Modal/BaseModal";
import {
  dateFormat4,
  priceFormat,
  paymentFormat,
  eventStatusFormat,
  openInvoiceWeb,
  formatTypePayment,
  icons,
} from "../../../constants/globals";

const { Link } = Typography;

const ManageTransModal = ({
  getTrans,
  transId,
  openModal,
  closeModal,
  refetch,
  initialValues,
  selectedApp,
  selectedTransMain,
  handleSendInvoice,
  t,
}) => {
  const [selectedTrans, setSelectedTrans] = useState(initialValues);
  const [form] = Form.useForm();
  const [requestData] = useState({});
  const [loaderForm, setLoaderForm] = useState(false);
  const instanceStoreRedux = useSelector((store) => store.instance);
  const { alias } = instanceStoreRedux.currentInstance;
  useEffect(() => {
    if (transId) {
      setLoaderForm(true);
      // eslint-disable-next-line no-unused-expressions
      !selectedApp &&
        getTrans(transId, {
          onSuccess: (response) => {
            setSelectedTrans(response.result);
            setLoaderForm(false);
          },
          onError: () => showGlobalNotification("error", "Error", "error"),
        });
    } else {
      setSelectedTrans(initialValues);
    }
  }, [transId]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
    if (selectedApp && selectedApp?._id) {
      setLoaderForm(true);
      getApp(selectedApp._id, {
        onSuccess: (response) => {
          setSelectedTrans({
            ...selectedTrans,
            appId: selectedApp._id,
            app: response.result,
            total: response.result.amount,
            amount: response.result.amount,
            fullname: `${response.result.userId.firstname} ${response.result.userId.lastname}`,
            email: `${response.result.userId.email}`,
            phone: `${response.result.userId.phone}`,
            pending: 0,
          });
          setLoaderForm(false);
        },
        onError: () => showGlobalNotification("error", "Error", "error"),
      });
    }
  }, [openModal]);

  useEffect(() => {
    if (requestData.message) {
      closeModal();
      showGlobalNotification("success", "Exitooo", requestData.message);
      if (!transId) setSelectedTrans(initialValues);
      refetch();
    }
  }, [requestData]);

  useEffect(() => {
    setSelectedTrans(selectedTransMain);
  }, [selectedTransMain]);

  const handleCloseModal = () => {
    refetch();
    closeModal();
  };

  useEffect(() => {
    console.log(selectedApp);
  }, [selectedApp]);

  useEffect(() => {
    console.log(selectedTrans);
  }, [selectedTrans]);

  return (
    <BaseModal
      isOpenModal={openModal}
      closeModal={handleCloseModal}
      onCancel={handleCloseModal}
      onConfirm={handleCloseModal}
      title="Más detalles de la transacción"
      width={1000}
      confirmText=""
      isCloseModal
      top={70}
    >
      <div className="layout-form-modal entity-300 entity-small">
        {loaderForm ? (
          <>
            <Spin tip="Cargando" />
          </>
        ) : (
          <>
            <Row gutter={15}>
              <Divider orientation="left">Acerca de la transacción</Divider>
              {!selectedTrans?.isPending && selectedTrans?.appId?._id ? (
                <Space>
                  <Button
                    size="small"
                    className="ant-btn secondary primary"
                    onClick={() => handleSendInvoice(selectedTrans)}
                  >
                    {icons.email} {t("trans_sendTo")} {selectedTrans.email}
                  </Button>
                  <Link
                    className="ant-btn secondary primary large"
                    onClick={() => window.open(openInvoiceWeb({ trans: selectedTrans, alias }))}
                  >
                    {t("trans_visualize_invoice")}
                  </Link>
                </Space>
              ) : (
                <Tag color="volcano" key={t("inwait")}>
                  {t("trans_pendingPayment")}
                </Tag>
              )}
              <br /> <br /> <br /> <br />
              <Descriptions size="small" column={1} style={{ marginBottom: 20 }}>
                <Descriptions.Item label="Descripcion">{selectedTrans?.description}</Descriptions.Item>
              </Descriptions>
              <Descriptions size="small" column={3} style={{ marginBottom: 20 }}>
                <Descriptions.Item label="Fecha">{dateFormat4(selectedTrans?.createAt)}</Descriptions.Item>
                <Descriptions.Item label="Codigo">{selectedTrans?.code}</Descriptions.Item>
                <Descriptions.Item label="Cliente">
                  {selectedTrans?.firstname || ""} {selectedTrans?.lastname || ""}
                </Descriptions.Item>
                <Descriptions.Item label="Email">{selectedTrans?.email}</Descriptions.Item>
                <Descriptions.Item label="Teléfono">{selectedTrans?.phone}</Descriptions.Item>
                <Descriptions.Item label="Tipo">{formatTypePayment(selectedTrans?.type)}</Descriptions.Item>
                <Descriptions.Item label="Estatus">{eventStatusFormat(selectedTrans?.status)}</Descriptions.Item>
                <Descriptions.Item label="Realizada por">{selectedTrans.rootId?.email}</Descriptions.Item>
              </Descriptions>
              <Descriptions size="small" column={3} style={{ marginBottom: 20 }}>
                <Descriptions.Item label="Monto">{priceFormat(selectedTrans?.amount)}</Descriptions.Item>
                <Descriptions.Item label="Pendiente">{priceFormat(selectedTrans?.pending)}</Descriptions.Item>
                <Descriptions.Item label="Total">{priceFormat(selectedTrans?.total)}</Descriptions.Item>
                <Descriptions.Item label="Modo">{paymentFormat(selectedTrans?.mode)}</Descriptions.Item>
                <Descriptions.Item label="Referencía">{selectedTrans?.reference}</Descriptions.Item>
                <Descriptions.Item label="Cupón">{selectedTrans?.cupon}</Descriptions.Item>
              </Descriptions>
            </Row>

            {selectedTrans.appId && (
              <Row gutter={15}>
                <Divider orientation="left">Acerca de la Aplicación</Divider>
                <Descriptions size="small" column={3} style={{ marginBottom: 20 }}>
                  <Descriptions.Item label="Codigo Aplicación">{selectedTrans.appId?.code}</Descriptions.Item>
                  <Descriptions.Item label="Plan">{selectedTrans.planId?.name}</Descriptions.Item>
                  <Descriptions.Item label="Costo">{priceFormat(selectedTrans.planId?.price)}</Descriptions.Item>
                  <Descriptions.Item label="Duración">
                    {selectedTrans.planId?.duration} {selectedTrans.planId?.durationMode}
                  </Descriptions.Item>
                </Descriptions>
              </Row>
            )}

            {selectedTrans.userId && (
              <Row gutter={15}>
                <Divider orientation="left">Acerca del Usuario</Divider>
                <Descriptions size="small" column={4} style={{ marginBottom: 20 }}>
                  <Descriptions.Item label="Nombres">{selectedTrans.userId?.firstname}</Descriptions.Item>
                  <Descriptions.Item label="Apellidos">{selectedTrans.userId?.lastname}</Descriptions.Item>
                  <Descriptions.Item label="Tipo Documento">{selectedTrans.userId?.documentType}</Descriptions.Item>
                  <Descriptions.Item label="Documento">{selectedTrans.userId?.document}</Descriptions.Item>
                  <Descriptions.Item label="Email">{selectedTrans.userId?.email}</Descriptions.Item>
                  <Descriptions.Item label="Telefono">{selectedTrans.userId?.phone}</Descriptions.Item>
                </Descriptions>
              </Row>
            )}
          </>
        )}
      </div>
    </BaseModal>
  );
};

ManageTransModal.defaultProps = {
  setTransId: () => {},
};

export default ManageTransModal;
