import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";
import { constantsApp } from "../constants";

const FiltersTable = ({ setFilters, filters, isMinimal }) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState("");
  const [progress, setProgress] = useState(true);
  const [ending, setEnding] = useState(false);
  const [starting, setStarting] = useState(false);
  const [closed, setClosed] = useState(false);
  const [pending, setPending] = useState(false);
  const [canceled, setCanceled] = useState(false);

  const [all, setAll] = useState(false);

  const { currentPlan } = useSelector((store) => store.plan);

  const resetStyle = () => {
    setPending(false);
    setStarting(false);
    setProgress("*");
    setEnding(false);
    setCanceled(false);
    setClosed(false);
    setAll(false);
    setStatus("");
  };

  const handleAllSelected = () => {
    resetStyle();
    setAll(true);
    setFilters({
      ...filters,
      isProgress: "",
      status: "",
      isEnding: "",
      isStarting: "",
      isPending: "",
      isClosed: "",
      isCanceled: "",
    });
  };

  const handleProgressSelected = (progressSelected) => {
    resetStyle();
    setProgress(progressSelected);
    setFilters({
      ...filters,
      isCanceled: "",
      isPending: "",
      isProgress: progressSelected,
      status: "",
      isEnding: "",
      isStarting: "",
      isClosed: "",
    });
  };

  const handleEndingSelected = (endingSelected) => {
    resetStyle();
    setEnding(endingSelected);
    setFilters({
      ...filters,
      isPending: "",
      isStarting: "",
      isProgress: "",
      isEnding: endingSelected,
      isCanceled: "",
      isClosed: "",
      status: "",
    });
  };

  const handleStartingSelected = (startingSelected) => {
    resetStyle();
    setStarting(startingSelected);
    setFilters({
      ...filters,
      isStarting: startingSelected,
      isProgress: "",
      status: "",
      isEnding: "",
      isClosed: "",
      isCanceled: "",
      isPending: "",
    });
  };

  const handleClosedSelected = (closedSelected) => {
    resetStyle();
    setClosed(true);
    setFilters({
      ...filters,
      isProgress: "",
      status: "",
      isEnding: "",
      isStarting: "",
      isClosed: closedSelected,
      isCanceled: "",
      isPending: "",
    });
  };

  const handleCanceledSelected = (canceledSelected) => {
    resetStyle();
    setCanceled(canceledSelected);

    setFilters({
      ...filters,
      isCanceled: canceledSelected,
      isStarting: "",
      isPending: "",
      isProgress: "",
      isEnding: "",
      isClosed: "",
      status: "",
    });
  };

  const handlePendingSelected = (pendingSelected) => {
    resetStyle();
    setPending(pendingSelected);

    setFilters({
      ...filters,
      isPending: pendingSelected,
      isCanceled: "",
      isStarting: "",
      isProgress: "",
      isEnding: "",
      isClosed: "",
      status: "",
    });
  };

  useEffect(() => {
    handleAllSelected(true);
  }, []);

  return (
    <>
      {!isMinimal && (
        <span className="fieldset-consolidate-filters">
          <span className="label-consolidate-button-filter"> {t("app_FilterbyActivity")}</span>
          <Space>
            <Button
              className="tag-button-table"
              size="small"
              type={all === true ? "primary" : "default"}
              onClick={() => handleAllSelected(true)}
              style={{ fontSize: 10 }}
            >
              <span style={{ fontSize: 18, fontWeight: "bold" }}> {currentPlan?.totalApps} </span> &nbsp;{t("plan_all")}
            </Button>
            <Button
              className="tag-button-table"
              size="small"
              type={progress === true ? "primary" : "default"}
              onClick={() => handleProgressSelected(true)}
              style={{ fontSize: 10 }}
            >
              <span style={{ fontSize: 18, fontWeight: "bold" }}>
                {currentPlan?.progress}
                {"  "}
              </span>
              &nbsp;
              {t("plan_isProgress")}
            </Button>

            <Button
              className="tag-button-table"
              size="small"
              type={closed === true ? "primary" : "default"}
              onClick={() => handleClosedSelected(true)}
              style={{ fontSize: 10 }}
            >
              <span style={{ fontSize: 18, fontWeight: "bold" }}>
                {currentPlan?.closed}
                {"  "}
              </span>
              &nbsp;
              {t("plan_isClosed")}
            </Button>

            <Button
              className="tag-button-table"
              size="small"
              style={{ fontSize: 10 }}
              type={pending === true ? "primary" : "default"}
              onClick={() => handlePendingSelected(true)}
            >
              <span style={{ fontSize: 18, fontWeight: "bold" }}>{currentPlan?.pending} </span>&nbsp;
              {constantsApp.STATUS.POR_CONFIRMAR}
            </Button>

            <Button
              className="tag-button-table"
              size="small"
              style={{ fontSize: 10 }}
              type={canceled === true ? "primary" : "default"}
              onClick={() => handleCanceledSelected(true)}
            >
              <span style={{ fontSize: 18, fontWeight: "bold" }}>{currentPlan?.canceled} </span>&nbsp;
              {constantsApp.STATUS.CANCELADA}
            </Button>
          </Space>
        </span>
      )}
    </>
  );
};

export default FiltersTable;
