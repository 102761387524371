import { confirmAlert } from "react-confirm-alert";

import {
  archivedUser,
  activeUser,
  exportExcelUsers,
  getUser,
  checkinUser,
  guestUser,
  updateUser,
  searchUsers,
} from "../requests";
import showGlobalNotification from "../../../helpers/showGlobalNotification";
import { setUserRedux } from "../../../redux/user";

import { colorsSystem } from "../../../constants/globals";

const Swal = require("sweetalert2");

export const handleActiveConfirm = ({ user, t, refetch }) => {
  activeUser(
    {
      user,
    },
    {
      onSuccess: () => {
        refetch();
        showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleActive = ({ user, t, refetch }) => {
  confirmAlert({
    title: user.active ? `${t("desactivate")} ${t("user")}` : `${t("activate")} ${t("user")}`,
    message: t("AreYouSureWantToContinue"),
    buttons: [
      {
        label: t("yes"),
        onClick: () => handleActiveConfirm({ user, t, refetch }),
      },
      {
        label: t("no"),
        onClick: () => {},
      },
    ],
  });
};

export const handleExportExcelUsers = (users) => {
  exportExcelUsers(users);
};

export const handleArchivedConfirm = ({ user, t, refetch }) => {
  archivedUser(
    {
      user,
    },
    {
      onSuccess: () => {
        refetch();
        showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
      },
      onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleArchived = ({ user, t, refetch }) => {
  confirmAlert({
    title: user.active ? `${t("desactivate")} ${t("user")}` : `${t("activate")} ${t("user")}`,
    message: t("AreYouSureWantToContinue"),
    buttons: [
      {
        label: t("yes"),
        onClick: () => handleArchivedConfirm({ user, t, refetch }),
      },
      {
        label: t("no"),
        onClick: () => {},
      },
    ],
  });
};

export const handleDetails = ({ dispatch, user }, { onSuccess = {} }) => {
  getUser(user._id, {
    onSuccess: (response) => {
      setUserRedux(response.user)(dispatch);
      onSuccess(response.user);
    },
    onError: () => showGlobalNotification("error", "Error", "error"),
  });
};

export const handleCheckinUser = (
  { userId, message, t, appId, setBlockActions = null },
  { onSuccess = {}, onFinally = {} }
) => {
  Swal.fire({
    title: message,
    html: "<b>Desea continuar?</b>",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: colorsSystem.red,
    cancelButtonColor: colorsSystem.blue,
    confirmButtonText: t("common_yesIWantContinueCheckin"),
    cancelButtonText: t("common_cancel"),
  }).then((result) => {
    if (result.isConfirmed) {
      checkinUser(
        {
          message,
          appId,
          userId,
        },
        {
          onSuccess: (result2) => {
            onSuccess(result2);
          },
          onError: () => showGlobalNotification("error", "errorTitle", t("errorDescription")),
          onFinally: () => {},
        }
      );
    } else {
      setBlockActions(false);
    }
  });
};

export const handleGuestUser = ({ userId, params, t, appId }, { onSuccess = {}, onFinally = {} }) => {
  guestUser(
    {
      ...params,
      userId,
      appId,
    },
    {
      onSuccess: (result) => {
        onSuccess(result);
      },
      onError: () => showGlobalNotification("error", "errorTitle", t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleUpdateUser = ({ userId, params, t }, { onSuccess = {}, onFinally = {} }) => {
  updateUser(
    {
      ...params,
      userId,
    },
    {
      onSuccess: (result) => {
        onSuccess(result);
      },
      onError: () => showGlobalNotification("error", "errorTitle", t("errorDescription")),
      onFinally: () => {},
    }
  );
};

export const handleSearchUsers = ({ text, t }, { onSuccess = {}, onFinally = {} }) => {
  searchUsers(text, {
    onSuccess: (result) => {
      onSuccess(result);
    },
    onError: () => showGlobalNotification("error", "errorTitle", "errorDescription"),
    onFinally: () => {},
  });
};
