import { Table, Space, Button, Tooltip, Tag } from "antd";
import { withRouter } from "react-router-dom";
import { CheckCircleTwoTone } from "@ant-design/icons";
import BaseTable from "../../shared/components/BasicTable";

import { dateFormat2, clasificationFormat, kindFormat, colorNetoKind } from "../../../constants/globals";

const PacksTable = ({
  selectedUser,
  setSelectedUser,
  setPage,
  history,
  packs,
  refetch,
  handleDetails,
  handleEdit,
  userId,
  handleArchived,
  handleActive,
  handleDuplicate,
  t,
  ...restProps
}) => (
  <>
    <BaseTable {...restProps} dataSource={packs} refetch={refetch} scroll={{ x: 1200 }}>
      <Table.Column
        width="80px"
        title={t("actions")}
        dataIndex="actions"
        key="actions"
        render={(_, data) => (
          <Space size="small">
            {/*
                        <a href="#!" onClick={() => handleDuplicate(data)} role="button" tabIndex={0}>
              <Tooltip placement="top" title="Haga click para DUPLICAR">
                <BlockOutlined />
              </Tooltip>
            </a>

           
              */}
            <Button className="ant-btn secondary primary" size="medium" onClick={() => handleDetails(data)}>
              Abrir
            </Button>
          </Space>
        )}
      />

      <Table.Column
        title={t("pack")}
        dataIndex="name"
        key="name"
        sorter
        render={(text, row) => (
          <h3 style={{ textTransform: "uppercase", fontSize: 18 }} className="special-font bold">
            <a href="#!" onClick={() => handleActive(row)} role="button" tabIndex={0}>
              {row.active ? (
                <Tooltip placement="top" title="Haga click para DESACTIVAR">
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="Haga click para ACTIVAR">
                  <CheckCircleTwoTone twoToneColor="#FF0A50" />
                </Tooltip>
              )}
            </a>{" "}
            {row.name}
          </h3>
        )}
      />
      <Table.Column
        title={t("kind")}
        dataIndex="kind"
        key="kind"
        render={(text, row) => (
          <Tag
            className="special-font"
            style={{
              fontSize: 16,
              fontWeight: 600,
              padding: 5,
              borderColor: colorNetoKind(row.kind),
              border: "1px solid",
              color: colorNetoKind(row.kind),
            }}
          >
            {" "}
            {kindFormat(row.kind)}
          </Tag>
        )}
      />
      <Table.Column
        title={t("clasification")}
        dataIndex="clasification"
        key="clasification"
        render={(text, row) => <b>{clasificationFormat(row.clasification)}</b>}
      />

      <Table.Column
        title={t("price")}
        dataIndex="price"
        key="price"
        render={(text) => `$ ${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      />

      <Table.Column
        title={t("updatedAt")}
        dataIndex="updatedAt"
        key="updatedAt"
        render={(text, row) => dateFormat2(row.lastUpdatedAt)}
      />
      <Table.Column
        title={t("createdAt")}
        dataIndex="createdAt"
        key="createdAt"
        render={(text, row) => dateFormat2(row.creartedAt)}
      />
      <Table.Column title={t("id")} dataIndex="_id" key="id" />
    </BaseTable>
  </>
);

export default withRouter(PacksTable);
