/* eslint-disable no-unused-expressions */
import { useState, useEffect } from "react";
import { Drawer, Avatar, Col, Row, Tabs, Layout } from "antd";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import useApps from "../subscribersApps/hooks/useApps";
import useModal from "../shared/hooks/useModal";

import HeaderUser from "./components/headerUser";

import EventsContainer from "../events/containers/EventAnalist";

import AppsReportLine from "../subscribersApps/graphs/AppsLine";
import AppsPie from "../subscribersApps/graphs/Pie";

import { reportPack } from "../subscribers/requests";
import TreePack from "../subscribersDashboard/components/TreePack";
import SelectPacks from "../subscribers/components/SelectPacks";

import PlansContainer from "../subscribersPlans/containers/Plans";
import TransContainer from "../subscribersTrans/containers/Trans";
import AppsContainer from "../subscribersApps/containers/Apps";

import { numbers, actionsUsers } from "../../constants/globals";

const { Content } = Layout;

const { TabPane } = Tabs;

const data1 = [
  {
    type: "分类一",
    value: 27,
  },
  {
    type: "分类二",
    value: 25,
  },
  {
    type: "分类三",
    value: 18,
  },
  {
    type: "分类四",
    value: 15,
  },
  {
    type: "分类五",
    value: 10,
  },
  {
    type: "其他",
    value: 5,
  },
];

const Analist = ({ match, history, mainReload }) => {
  const { t } = useTranslation();
  const [isOpenManageBucketModal, openManageBucketModal, closeManageBucketModal] = useModal();

  const sessionStoreRedux = useSelector((store) => store.session);
  const instanceStoreRedux = useSelector((store) => store.instance);

  const { apps, filters, setFilters, pagination, setPagination, loading, refetch } = useApps();

  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState(false);
  const [appSelected, setAppSelected] = useState({});

  const [option, setOption] = useState({});
  const [reload, setReload] = useState(0);
  const [filters_, setFilters_] = useState({});
  const [userSection, setUserSection] = useState(false);
  const [userId, setUserId] = useState(false);
  const [tab, setTab] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [titleDraw, setTitleDraw] = useState("");

  const [dataTotalPlans, setDataTotalPlans] = useState([]);
  const [dataTotalStatusApps, setDataTotalStatusApps] = useState([]);
  const [dataTotalStatusTableApps, setDataTotalStatusTableApps] = useState([]);

  const [dataGenre, setDataGenre] = useState([]);
  const [dataEdad, setDataEdad] = useState([]);
  const [dataMedium, setDataMedium] = useState([]);
  const [dataMediumTable, setDataMediumTable] = useState([]);
  const [dataEdadTable, setDataEdadTable] = useState([]);

  const [dataDocumentType, setDataDocumentType] = useState([]);
  const [dataDocumentTypeTable, setDataDocumentTypeTable] = useState([]);
  const [dataTotalPlansTable, setDataTotalPlansTable] = useState([]);

  const [dataPaymentMode, setDataPaymentMode] = useState([]);
  const [dataPaymentModeTable, setDataPaymentModeTable] = useState([]);

  const [dataPaymentStatus, setDataPaymentStatus] = useState([]);
  const [dataPaymentStatusTable, setDataPaymentStatusTable] = useState([]);

  const [dataPaymentType, setDataPaymentType] = useState([]);
  const [dataPaymentTypeTable, setDataPaymentTypeTable] = useState([]);
  const [packId, setPackId] = useState(null);

  const onClose = () => {
    setVisible(false);
  };

  const handleCheckin = (app) => {
    setReload(Math.floor(Math.random() * 100000));
    setUser(app.userId || "");
    setAppSelected(app);
    setOption(actionsUsers.CHECKIN.number);
    setTitleDraw(actionsUsers.CHECKIN.title);
    setVisible(true);
  };

  const handleGuest = (app) => {
    setReload(Math.floor(Math.random() * 100000));
    setUser(app.userId || null);
    setAppSelected(app);
    setOption(actionsUsers.GUEST.number);
    setTitleDraw(actionsUsers.GUEST.title);
    setVisible(true);
  };

  const handleUpdateUser = (app) => {
    setReload(Math.floor(Math.random() * 100000));
    setUser(app.userId || null);
    setAppSelected(app);
    setOption(actionsUsers.UPDATEUSER.number);
    setTitleDraw(actionsUsers.UPDATEUSER.title);
    setVisible(true);
  };

  const handleUpdateAvatar = (app) => {
    setReload(Math.floor(Math.random() * 100000));
    setUser(app.userId || "");
    setAppSelected(app);
    openManageBucketModal();
  };

  const handleReload = () => setReload(Math.floor(Math.random() * 100000));

  const handleOpenUser = (app) => {
    handleReload();
    setUser(app.userId || "");
    setUserId(app.userId._id);
    setAppSelected(app);
    console.log(app);
    setFilters_({ ...filters_, userId: app.userId._id });
    setUserSection(true);
    setActiveTab(numbers.EIGHT);
    setTab(numbers.EIGHT);
  };

  const closeUserSection = () => {
    setUserSection(false);
    setAppSelected({});
    setVisible(false);
    setUser({});
    setUserId("");
    setAppSelected({});
    setActiveTab(numbers.ONE);
    setTab(numbers.ONE);
  };

  const handleChangeTab = (val) => {
    if (val === numbers.ONE) refetch();
    setTab(val);
    setActiveTab(val);
  };

  const handlePack = (value) => {
    setPackId(value);
    console.log(value);
  };

  useEffect(() => {
    refetch();
  }, [mainReload]);

  useEffect(() => {
    if (packId) {
      reportPack(packId, {
        onSuccess: (resultPack) => {
          console.log(resultPack);
          setDataTotalPlans(resultPack.totalPlans);
          setDataTotalStatusApps(resultPack.statusApp);
          setDataTotalStatusTableApps(resultPack.statusAppTable);
          setDataGenre(resultPack.genre);
          setDataMedium(resultPack.medium);
          setDataMediumTable(resultPack.mediumTable);
          setDataDocumentTypeTable(resultPack.documentTypeTable);
          setDataDocumentType(resultPack.documentType);
          setDataEdad(resultPack.edad);
          setDataEdadTable(resultPack.edadTable);
          setDataTotalPlansTable(resultPack.totalPlansTable);
          setDataPaymentStatusTable(resultPack.paymentStatusTable);
          setDataPaymentTypeTable(resultPack.paymentTypeTable);
          setDataPaymentModeTable(resultPack.paymentModeTable);
          setDataPaymentStatus(resultPack.paymentStatus);
          setDataPaymentType(resultPack.paymentType);
          setDataPaymentMode(resultPack.paymentMode);
        },
        onError: () => {},
      });
    }
  }, [packId]);

  return (
    <Layout className="layout">
      <Content className="container-interno">
        <div className="site-layout-content">
          {userSection && (
            <HeaderUser
              app={appSelected}
              user={user}
              closeUserSection={closeUserSection}
              handleCheckin={handleCheckin}
              handleGuest={handleGuest}
              handleUpdateUser={handleUpdateUser}
              handleUpdateAvatar={handleUpdateAvatar}
              handleOpenUser={handleOpenUser}
              instanceStoreRedux={instanceStoreRedux}
            />
          )}
          <Row>
            <Col span={8}>
              <SelectPacks
                reload={reload}
                currentValue={null}
                defaultValue={null}
                returnData={handlePack}
                typePack="SUBSCRIPTION"
              />
            </Col>
          </Row>
          <div className="card-container" style={{ marginTop: 0 }}>
            <Tabs activeKey={activeTab} type="card" onChange={handleChangeTab}>
              <TabPane tab="RESUMEN" key={numbers.EIGHT}>
                <AppsReportLine />
              </TabPane>
              <TabPane tab="PLANES" key={numbers.TEN}>
                <Row gutter={24}>
                  <Col span={18}>
                    <hr />
                    <h2>APLICACIONES POR CADA PLAN</h2>
                    <div style={{ border: "1px solid gray", padding: 20, borderRadius: 7 }}>
                      {dataTotalPlans[0] && <AppsPie data={dataTotalPlans} />}
                    </div>
                  </Col>
                  <Col span={6}>
                    <hr />
                    <h2>DATA</h2>
                    <TreePack data={dataTotalPlansTable} />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={18}>
                    <hr />
                    <h2>APLICACIONES POR ESTADO</h2>
                    <div style={{ border: "1px solid gray", padding: 20, borderRadius: 7 }}>
                      {dataTotalStatusApps[0] && <AppsPie data={dataTotalStatusApps} />}
                    </div>
                  </Col>
                  <Col span={6}>
                    <hr />
                    <h2>DATA</h2>
                    <TreePack data={dataTotalStatusTableApps} />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={18}>
                    <hr />
                    <h2>APLICACIONES POR TIPO DE DOCUMENTO</h2>
                    <div style={{ border: "1px solid gray", padding: 20, borderRadius: 7 }}>
                      {dataDocumentType[0] && <AppsPie data={dataDocumentType} />}
                    </div>
                  </Col>
                  <Col span={6}>
                    <hr />
                    <h2>DATA</h2>
                    <TreePack data={dataDocumentTypeTable} />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={18}>
                    {" "}
                    <hr />
                    <h2>APLICACIONES POR RANGO DE EDADES</h2>
                    <div style={{ border: "1px solid gray", padding: 20, borderRadius: 7 }}>
                      {dataEdad[0] && <AppsPie data={dataEdad} />}
                    </div>
                  </Col>
                  <Col span={6}>
                    <hr />
                    <h2>DATA</h2>
                    <TreePack data={dataEdadTable} />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={18}>
                    <hr />
                    <h2>NOS CONOCIERON POR?</h2>
                    <div style={{ border: "1px solid gray", padding: 20, borderRadius: 7 }}>
                      {dataMedium[0] && <AppsPie data={dataMedium} />}
                    </div>
                  </Col>
                  <Col span={6}>
                    <hr />
                    <h2>DATA</h2>
                    <TreePack data={dataMediumTable} />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={18}>
                    <hr />
                    <h2>APLICACIONES POR GENERO </h2>
                    <div style={{ border: "1px solid gray", padding: 20, borderRadius: 7 }}>
                      {dataGenre[0] && <AppsPie data={dataGenre} />}
                    </div>
                  </Col>
                  <Col span={6}> </Col>
                </Row>
              </TabPane>

              <TabPane tab="TABLA PLANES" key={numbers.ONE}>
                {packId && <PlansContainer isEmbed packId={packId} />}
              </TabPane>

              <TabPane tab="TABLA PLANES" key={numbers.TWO}>
                {packId && <AppsContainer isEmbed packId={packId} />}
              </TabPane>

              <TabPane tab="TRANSACCIONES" key={numbers.ELEVEN}>
                <Row gutter={24}>
                  <Col span={24}>{packId && <TransContainer isEmbed packId={packId} />}</Col>
                </Row>
                <Row gutter={24}>
                  <Col span={18}>
                    <hr />
                    <h2>TRANSACCIONES POR MODALIDAD</h2>
                    <div style={{ border: "1px solid gray", padding: 20, borderRadius: 7 }}>
                      {dataPaymentMode[0] && <AppsPie data={dataPaymentMode} />}
                    </div>
                  </Col>
                  <Col span={6}>
                    <hr />
                    <h2>DATA</h2>
                    <TreePack data={dataPaymentModeTable} />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={18}>
                    <hr />
                    <h2>TRANSACCIONES POR ESTATUS</h2>
                    <div style={{ border: "1px solid gray", padding: 20, borderRadius: 7 }}>
                      {dataPaymentStatus[0] && <AppsPie data={dataPaymentStatus} />}
                    </div>
                  </Col>
                  <Col span={6}>
                    <hr />
                    <h2>DATA</h2>
                    <TreePack data={dataPaymentStatusTable} />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={18}>
                    <hr />
                    <h2>TRANSACCIONES POR TIPO</h2>
                    <div style={{ border: "1px solid gray", padding: 20, borderRadius: 7 }}>
                      {dataPaymentType[0] && <AppsPie data={dataPaymentType} />}
                    </div>
                  </Col>
                  <Col span={6}>
                    <hr />
                    <h2>DATA</h2>
                    <TreePack data={dataPaymentTypeTable} />
                  </Col>
                </Row>
              </TabPane>

              <TabPane tab="APPS" key={numbers.TWO}>
                {tab === numbers.TWO && <EventsContainer userId={userId || null} tab={tab} category="USER_CHECKIN" />}
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Content>
    </Layout>
  );
};
export default withRouter(Analist);
