import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { useDispatch } from "react-redux";

import SubscriberAnalist from "../features/subscribersAnalist/index";
import { validateSessionRoot } from "../features/subscribersUsers/requests";

import { setSession } from "../redux/session";

const Analist = ({ match }) => {
  const dispatch = useDispatch();
  const [newApp, setNewApp] = useState(false);

  useEffect(() => {
    validateSessionRoot({
      onSuccess: (data) => {
        if (data.ok) {
          setSession(data.result)(dispatch);
        }
      },
    });
  }, []);

  return <SubscriberAnalist currentPage="1" setNewApp={setNewApp} newApp={newApp} />;
};
// comentario extra

export default withRouter(Analist);
