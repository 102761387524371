import { useState, useEffect } from "react";

import { Form, Button, Col, Row, Input, Space, Select } from "antd";

import { handleGuestUser } from "../functions/index";
import showGlobalNotification from "../../../helpers/showGlobalNotification";

const defaultValues = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  genre: "",
  message: "",
};
const { Option } = Select;

const FormGuest = ({ reload, onClose, user, t, appId, refetch = {} }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState({});

  useEffect(() => {
    form.setFieldsValue(data);
  }, [form, setData]);

  const onFinish = (values) => {
    const params = { ...data, ...values };
    handleGuestUser(
      { userId: user._id, params, t, appId },
      {
        onSuccess: () => {
          showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
          refetch();
          onClose();
        },
      }
    );
  };

  const onValuesChange = (values) => {
    setData({ ...setData, ...values });
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [reload]);

  return (
    <Form
      layout="vertical"
      form={form}
      name="manage-category-form-modal"
      initialValues={defaultValues}
      className="manage-modal"
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      onValuesChange={onValuesChange}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="firstname" label="Nombre" rules={[{ required: true, message: "Por favor ingrese  Nombre" }]}>
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lastname"
            label="Apellido"
            rules={[{ required: true, message: "Por favor ingrese  Apellidos" }]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="email" label="Email" rules={[{ required: true, message: "Por favor ingrese  Email" }]}>
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="phone" label="Teléfono" rules={[{ required: true, message: "Por favor ingrese Teléfono" }]}>
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="genre" label="Genero" rules={[{ required: true, message: "Por favor ingrese  Genero" }]}>
            <Select placeholder="Please select an Genero">
              <Option value="Masculino">Masculino</Option>
              <Option value="Femenino">Femenino</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="message" label="Alguna observación importante">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Space>
            <Button className="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} type="primary">
              Guardar
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default FormGuest;
