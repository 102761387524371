import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Row, Col, Input, Select, Spin, DatePicker, Typography, Tabs } from "antd";
import { saveUser, getUser } from "../requests";
import { saveApp } from "../../subscribersApps/requests";

import showGlobalNotification from "../../../helpers/showGlobalNotification";
import BaseModal from "../../shared/components/Modal/BaseModal";
import { setUserRedux } from "../../../redux/user";
import SelectPlans from "../../subscribersPlans/components/SelectPlans";

import SelectUsers from "./SelectUsers";

const moment = require("moment");

const { TabPane } = Tabs;

const { Title } = Typography;

const { Option } = Select;

const defaultValues = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  address: "",
  city: "",
  region: "",
  country: "",
  birthdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD"),
  departurePlace: "",
  category: "",
  duration: "",
  active: true,
  public: false,
  price: 0,
  startdate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD HH:mm"),
  closuredate: moment(moment().format("YYYY-MM-DD"), "YYYY/MM/DD HH:mm"),
  planId: null,
  refererId: null,
  document: "",
  DocumentType: "",
  observation: "",
  type: "",
};

const config = {
  rules: [
    {
      type: "object",
      required: true,
      message: "Please select time!",
    },
  ],
};

const ManageUserModal = ({ userId, openModal, closeModal, refetch, t, setUserId }) => {
  const dispatch = useDispatch();
  const sessionStoreRedux = useSelector((store) => store.session);
  const [form] = Form.useForm();
  const [selectedUser, setSelectedUser] = useState(defaultValues);
  const [loaderForm, setLoaderForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [reload, setReload] = useState(0);

  const handleReset = () => {
    setSelectedUser(defaultValues);
    setReload(Math.floor(Math.random() * 100000));
  };

  useEffect(() => {
    if (openModal) {
      if (userId) {
        setMode("edit");
      } else {
        setMode("new");
      }
    } else {
      handleReset();
      setMode(null);
      setUserId(null);
    }
  }, [openModal]);

  useEffect(() => {
    if (mode === "new") {
      handleReset();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      setLoaderForm(true);
      getUser(userId, {
        onSuccess: (response) => {
          setUserRedux(response.user)(dispatch);
          setSelectedUser({
            ...response.user,
            startdate: moment(response.user.startdate, "YYYY/MM/DD") || moment("2020/12/12", "YYYY/MM/DD"),
            closuredate: moment(response.user.closuredate, "YYYY/MM/DD") || moment("2020/12/12", "YYYY/MM/DD"),
            birthdate: moment(response.user.birthdate, "YYYY/MM/DD") || moment("2020/12/12", "YYYY/MM/DD"),
            password: "",
          });
          setLoaderForm(false);
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
      });
    }
  }, [mode]);

  useEffect(() => {
    form.setFieldsValue(selectedUser);
  }, [form, selectedUser]);

  const handleCloseModal = () => {
    refetch();
    closeModal();
  };

  const onFinish = (values) => {
    const data = { ...selectedUser, ...values };

    saveUser(
      {
        ...data,
        userId,
        rootId: sessionStoreRedux.currentSession_id,
      },
      {
        onSuccess: (response) => {
          setUserRedux(response.usuario)(dispatch);
          if (data.planId) {
            saveApp(
              {
                ...data,
                userId,
                rootId: sessionStoreRedux.currentSession_id,
              },
              {
                onSuccess: () => {
                  showGlobalNotification("success", t("inHorabuena"), t("successfulProcess"));
                },
                onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
                onFinally: () => handleCloseModal(),
              }
            );
          }
        },
        onError: () => showGlobalNotification("error", t("errorTitle"), t("errorDescription")),
        onFinally: () => handleCloseModal(),
      }
    );
  };

  const callback = (key) => {
    console.log(key);
  };

  const onValuesChange = (values) => {
    setSelectedUser({ ...selectedUser, ...values });
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  const handleActive = (checked) => {
    setSelectedUser({ ...selectedUser, active: checked });
  };

  const handleReferer = (value) => {
    setSelectedUser({ ...selectedUser, refererId: value });
  };

  const handlePlan = (value) => {
    setSelectedUser({ ...selectedUser, planId: value });
  };

  return (
    <BaseModal
      isOpenModal={openModal}
      closeModal={handleCloseModal}
      onCancel={handleCloseModal}
      onConfirm={() => handleSubmit()}
      title={userId ? `${t("edit")} ${t("customer")}` : `${t("new")} ${t("customer")}`}
      width={1000}
      confirmText={t("save")}
      isCloseModal
      top={10}
    >
      <Form
        form={form}
        name="manage-category-form-modal"
        initialValues={defaultValues}
        className="manage-modal"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        onValuesChange={onValuesChange}
      >
        <div className="layout-form-modal entity-400 entity-small">
          {loaderForm ? (
            <>
              <Spin tip={t("loading")} />
            </>
          ) : (
            <>
              <Row gutter={16} className="box-info">
                <Col span={12} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="firstname"
                    tooltip="Firstname here pleaseio"
                    label="Nombre"
                    rules={[
                      {
                        required: true,
                        message: `Por favor ingrese el nombre`,
                      },
                    ]}
                  >
                    <Input placeholder="" value={selectedUser.firstname} />
                  </Form.Item>
                </Col>
                <Col span={12} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="lastname" label="Apellidos">
                    <Input value={selectedUser.lastname} />
                  </Form.Item>
                </Col>
                <Col span={12} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="email"
                    label={t("email")}
                    rules={[{ required: true, message: `Por favor ingrese el email` }]}
                  >
                    <Input value={selectedUser.email} />
                  </Form.Item>
                </Col>
                <Col span={12} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="phone" label={t("phone")}>
                    <Input value={selectedUser.phone} />
                  </Form.Item>
                </Col>

                <Col span={12} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="documentType" label={t("documentType")}>
                    <Select className="select-form-modal">
                      <Option value="Cédula de Ciudadanía">Cédula de Ciudadanía</Option>
                      <Option value="Tarjeta de Identidad">Tarjeta de Identidad</Option>
                      <Option value="Cédula de Extranjería">Cédula de Extranjería</Option>
                      <Option value="Pasaporte">Pasaporte</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="document" label={t("document")}>
                    <Input value={selectedUser.document} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="box-primary">
                <Col span={12} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="login" label={t("login")}>
                    <Input value={selectedUser.login} />
                  </Form.Item>
                </Col>
                <Col span={12} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="password" label={t("password")}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Form>
    </BaseModal>
  );
};

ManageUserModal.defaultProps = {
  setUserId: () => {},
};

export default ManageUserModal;
